import React, { useEffect, useMemo, useRef, useState } from 'react'

import * as SC from './styles'
import { getHsvColorH, getHsvColorS, getHsvColorV, hsvaToHslaString } from './utils'

export interface ColorAlphaBarCanvasProps {
    h: number
    s: number
    v: number
    a: number
    onAlphaChange: (x: number, confirmed?: boolean) => void
}

export const ColorAlphaBarCanvas: React.FC<ColorAlphaBarCanvasProps> = ({ h, s, v, a, onAlphaChange }) => {
    const [isMoving, setIsMoving] = useState(false)
    const newXy = useRef({ x: 0, y: 0 })

    const colorPickerAlphaCanvasStyles = useMemo(() => {
        const hh = getHsvColorH(h)
        const ss = getHsvColorS(s)
        const vv = getHsvColorV(v)
        const from = hsvaToHslaString({ h: hh, s: ss, v: vv, a: 0 })
        const to = hsvaToHslaString({ h: hh, s: ss, v: vv, a: 1 })
        const gradient = `linear-gradient(to right, ${from}, ${to})`
        return {
            backgroundImage: gradient
        }
    }, [h, v, s])

    const alphaSliderTranslateXStyle = useMemo(() => ({ transform: `translate(${a - 2}px)` }), [a])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onAlphaChange(e.nativeEvent.offsetX, true)
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsMoving(true)
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isMoving) {
            onAlphaChange(e.nativeEvent.offsetX)
            newXy.current = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        }
    }

    useEffect(() => {
        const mouseUpHandler = () => {
            if (isMoving) {
                setIsMoving(false)
                onAlphaChange(newXy.current.x, true)
            }
        }
        document.addEventListener('mouseup', mouseUpHandler)

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler)
        }
    }, [isMoving, onAlphaChange])

    return (
        <SC.ColorPickerAlphaSlider>
            <SC.ColorPickerAlphaCanvas
                style={colorPickerAlphaCanvasStyles}
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
            />
            <SC.ColorPickerAlphaBar style={alphaSliderTranslateXStyle} />
        </SC.ColorPickerAlphaSlider>
    )
}
