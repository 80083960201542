import { type PageAbstract } from '@lighthouse/core'
import React, { useRef } from 'react'
import styled from 'styled-components'

import type { CreatePagePayload, MovePageAtomPayload } from '@/atoms/page/types'

import type { PageItemTree } from '../../types'
import { PageGroupList } from './GroupList'

interface PageTreeListProps {
    list: PageItemTree[]
    level: number
    focusPage?: string
    activeId?: string
    onUpdatePage?: (param: Pick<PageAbstract, 'name' | 'id' | 'open' | 'isHome'>) => Promise<boolean>
    onSetHomePage?: (param: Pick<PageAbstract, 'id' | 'isHome'>) => Promise<boolean>
    onRemovePage?: (id: string) => void
    onToLink?: (page: PageAbstract) => void
    onChangeEdit: (id: string) => void
    onCopyPage: (id: string, lang: string) => Promise<string>
    onMovePage: (params: MovePageAtomPayload) => void
    onAdd: (v: CreatePagePayload) => void
}

const SCxPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`


export const PageTreeList: React.FunctionComponent<PageTreeListProps> = ({ list, ...rest }) => {
    return (
        <SCxPageContainer>
            {list?.map((item, index) => {
                return <PageGroupList key={item.id} tree={item} {...rest} />
            })}
        </SCxPageContainer>
    )
}
