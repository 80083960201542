import { getAssetUrl } from '@lighthouse/assets'
import { BlockThumbImageMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type {
    BlockTree,
    BlockTreeItem
} from '../type'
import { BlockKind } from '../type'

export const blockButton: BlockTreeItem = {
    name: '按钮',
    icon: 'BlockButton',
    describeImage: BlockThumbImageMap['buttonGroup'],
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    color: '#FFB74A',
    type: BlockType.buttonGroup
}

export const blockIconButton: BlockTreeItem = {
    name: '图标按钮',
    icon: 'BlockButton',
    describeImage:  getAssetUrl('blocks', 'icon_button.svg'),
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    color: '#FFB74A',
    type: 'iconButton'
}
export const blockCapsuleButton: BlockTreeItem = {
    name: '胶囊按钮',
    icon: 'BlockButton',
    describeImage:  getAssetUrl('blocks', 'capsule_button.svg'),
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    color: '#FFB74A',
    type: 'capsuleButton'
}

export const allButton: BlockTree = {
    id: BlockKind.button,
    name: '按钮',
    // icon: 'Button',
    color: '',
    items: [blockButton, blockIconButton, blockCapsuleButton]
}
