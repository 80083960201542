import type { FlowNode } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleRefreshPage =  (node: FlowNode, excParams: AnyObject) => {
    const { actionEvents, clickTriggerNodeParams } = excParams
    actionEvents.refreshRootPage()

    return {
        success: true
    };
}
