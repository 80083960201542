import { backgroundTransitionOnClick, Button, IconFont, pointer, Select } from '@byecode/ui'
import styled from 'styled-components'

export const Header = styled.div`
    height: 51px;
    padding: 0 41px;
    position: relative;
`

export const HeaderTitle = styled.div``

export const HeaderClose = styled.div`
    position: absolute;
    right: 13px;
    top: 12px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #f2f4f7;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    ${pointer}
    ${backgroundTransitionOnClick}
`

export const Close = styled(IconFont)``

export const Content = styled.div`
    padding: 5px 41px;
`

export const IconGroup = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 24px;
`

export const Icon = styled(IconFont)``

export const ArrowIcon = styled(IconFont)`
    margin: 0 9px;
`

export const Title = styled.div`
    font-weight: 600;
    font-size: var(--h2-font-size);
    color: var(--color-black);

`


export const FormItem = styled.div`
    margin-top: 25px;
`

export const Label = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    margin-bottom: 13px;
`

export const ApplicationSelect = styled(Select)``

export const Footer = styled.div`
    margin-top: 100px;
    padding: 0 20px;
    height: 72px;
    background-color: var(--color-gray-50);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const ConfirmBtn = styled(Button)``
