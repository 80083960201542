import { Button, Flex, Group, IconFont, Text } from '@byecode/ui'
import type { SpaceVersion } from '@lighthouse/core'
import { DEFAULT_SPACE_VERSION, defaultZhDateFormat, spaceGradeNameMap, spaceVersionEnum } from '@lighthouse/shared'
import { getFileSizeAccordanceBaseUnit } from '@lighthouse/tools'
import {} from '@mantine/core'
import { lightFormat } from 'date-fns'
import { groupBy } from 'rambda'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { openSpaceGrade } from '@/components/SpaceGrade'
import type { SpaceQuota } from '@/services/types'

import { SpaceQuotaSlider } from '../Slider'
import { SliderLabel } from '../Slider/SliderLabel'

interface SpaceQuotaFlowProps {
    version: SpaceVersion
    data: SpaceQuota['traffic']
}

const SCxContainer = styled.div`
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);
`

const SCxTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    gap: 8px;
`

const SCxContent = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color-gray-50);
`

const SCxDescribe = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-gray-400);
`

export const SpaceQuotaFlow: React.FunctionComponent<SpaceQuotaFlowProps> = ({ data, version }) => {
    const [opened, setOpened] = useState(true)

    const { quota, usage, packs } = data

    const purchaseTraffic = useMemo(() => groupBy(v => v.packType, packs), [packs])

    return (
        <SCxContainer>
            <SCxTitle>
                <Text weight={600} color="var(--color-black)" size={16}>
                    流量
                </Text>
                <Button type="default" onClick={() => openSpaceGrade('purchase')}>
                    购买流量包
                </Button>
            </SCxTitle>
            <SCxDescribe>
                访问应用页面、文件、图片、音频、视频等过程中产生的流量。当增购流量和版本流量都有余量时，会优先使用版本流量。当有效流量超额，将无法正常访问应用。
            </SCxDescribe>
            <Group
                styles={{
                    label: {
                        width: '100%'
                    },
                    wrapper: {
                        padding: '0!important'
                    },
                    collapse: {
                        padding: '0!important'
                    }
                }}
                opened={opened}
                label={
                    <SpaceQuotaSlider
                        style={{ fontWeight: 400 }}
                        data={{
                            icon: 'Earth',
                            label: (
                                <SliderLabel
                                    label="流量总额"
                                    usageLabel={getFileSizeAccordanceBaseUnit(usage, 'MB').replace('.0', '')}
                                    primaryColor={usage < quota ? 'var(--color-gray-600)' : 'var(--color-red-500)'}
                                    quotaLabel={getFileSizeAccordanceBaseUnit(quota, 'MB').replace('.0', '')}
                                    extra={
                                        <Flex alignItems="center" style={{ marginLeft: 8 }} onClick={() => setOpened(v => !v)}>
                                            <Text color="var(--color-main)" weight={400} size={12}>
                                                流量详情
                                            </Text>
                                            <IconFont
                                                type="ArrowDownSmall"
                                                size={16}
                                                color="var(--color-main)"
                                                style={{ transform: opened ? 'rotate(-180deg)' : undefined, transition: 'transform 0.2s' }}
                                            />
                                        </Flex>
                                    }
                                />
                            ),
                            usage,
                            quota,
                            primaryColor: 'var(--color-gray-500)'
                        }}
                    />
                }
                renderRightIcon={() => null}
            >
                <SCxContent>
                    {purchaseTraffic?.INCLUDE?.map(({ quota, usage, expiredAt }, i) => {
                        const isExpired = expiredAt && expiredAt < Date.now()
                        const validity = expiredAt
                            ? isExpired
                                ? '已过期'
                                : `有效期至：${lightFormat(expiredAt, 'yyyy-MM-dd')} (剩余 ${Math.round(
                                      (expiredAt - Date.now()) / 1000 / 3600 / 24
                                  )}天)`
                            : '永久'
                        return (
                            <SpaceQuotaSlider
                                key={i}
                                style={{ padding: 0, opacity: isExpired ? 0.5 : 1 }}
                                data={{
                                    label: (
                                        <SliderLabel
                                            label={
                                                <Flex gap={8}>
                                                    <Text color="var(--color-gray-600)">版本流量-{spaceGradeNameMap[version]}</Text>
                                                    <Text size={12} color="var(--color-gray-400)">
                                                        {validity}
                                                    </Text>
                                                </Flex>
                                            }
                                            usageLabel={getFileSizeAccordanceBaseUnit(usage, 'MB').replace('.0', '')}
                                            primaryColor={usage < quota ? 'var(--color-gray-600)' : 'var(--color-red-500)'}
                                            quotaLabel={getFileSizeAccordanceBaseUnit(quota, 'MB').replace('.0', '')}
                                        />
                                    ),
                                    usage,
                                    quota,
                                    primaryColor: 'var(--color-gray-500)'
                                }}
                            />
                        )
                    })}
                    {purchaseTraffic?.PURCHASED?.map(({ quota, usage, expiredAt }, i) => {
                        const isExpired = expiredAt && expiredAt < Date.now()
                        const validity = expiredAt
                        ? isExpired
                            ? '已过期'
                            : `有效期至：${lightFormat(expiredAt, 'yyyy-MM-dd')} (剩余 ${Math.round(
                                  (expiredAt - Date.now()) / 1000 / 3600 / 24
                              )}天)`
                        : '永久'
                        return (
                            <SpaceQuotaSlider
                                key={i}
                                style={{ padding: 0, opacity: isExpired ? 0.5 : 1 }}
                                data={{
                                    label: (
                                        <SliderLabel
                                            label={
                                                <Flex gap={8}>
                                                    <Text color="var(--color-gray-600)">
                                                        增购{getFileSizeAccordanceBaseUnit(quota, 'MB').replace('.0', '')}流量
                                                    </Text>
                                                    <Text size={12} color="var(--color-gray-400)">
                                                        {validity}
                                                    </Text>
                                                </Flex>
                                            }
                                            usageLabel={getFileSizeAccordanceBaseUnit(usage, 'MB').replace('.0', '')}
                                            primaryColor={usage < quota ? 'var(--color-gray-600)' : 'var(--color-red-500)'}
                                            quotaLabel={getFileSizeAccordanceBaseUnit(quota, 'MB').replace('.0', '')}
                                        />
                                    ),
                                    usage,
                                    quota,
                                    primaryColor: 'var(--color-gray-500)'
                                }}
                            />
                        )
                    })}
                </SCxContent>
            </Group>
        </SCxContainer>
    )
}
