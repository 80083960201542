import { Button, IconFont, Popover, Toast } from '@byecode/ui'
import { type VariableADTvalue, VariableType } from '@lighthouse/core'
import type {
    EmailParticipant,
    SendEmailActionNode,
    VariableOptions
} from '@lighthouse/shared'
import { emailRegex, FieldIconTypeMap, useAtomData, VariableSourceType } from '@lighthouse/shared'
import { Divider, Input } from '@mantine/core'
import { find, isEmpty } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { dataSourcePoolAtom } from '@/atoms/dataSource/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import * as CM from '../styles'
import { NodeFieldVariable } from '.'
import { TagPreview } from './TagPreview'

interface SelectEmailProps {
    options: VariableOptions
    name: 'config.recipients' | 'config.cc'
}

const SCxAddButton = styled(Button)`
    margin: 6px 0;
`

const SCxContent = styled.div`
    padding: 0 16px;
    display: flex;
`

const SCxTargetContainer = styled.div``

const SCxList = styled.div``

const SCxItem = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
`

export const SelectEmail: React.FC<SelectEmailProps> = ({ options, name }) => {
    const [inputValue, setInputValue] = useState('')
    const [opened, setOpened] = useState(false)
    const dropDownRef = useRef<HTMLDivElement>(null)
    const targetRef = useRef<HTMLDivElement>(null)
    const appId = useCurrentAppID()
    const allDataSources = useAtomData(dataSourcePoolAtom)
    const disabledWithVersion = useIsDisabledWithVersion()
    const dataSources = useMemo(() => allDataSources.filter(item => item.appId === appId), [allDataSources, appId])
    const { control, watch } = useFormContext<Pick<SendEmailActionNode, 'config'>>()

    const { fields, remove, append } = useFieldArray({
        control,
        name
    })

    const previewItemRender = useCallback(
        (item: EmailParticipant, index: number) => {
            switch (item.type) {
                case 'UPSTREAM': {
                    const nodeList = find(option => option.type === VariableSourceType.parentNode, options)
                    const node = find(node => node.id === item?.ref?.nodeId, nodeList?.list || [])
                    const dsId = node?.dsId
                    const ds = find(item => item.id === dsId && item.appId === appId, dataSources ?? [])
                    const field = ds?.schema[item.ref?.fieldId ?? '']
                    return (
                        field && (
                            <TagPreview
                                onClick={() => remove(index)}
                                label={
                                    <SCxItem>
                                        <CM.Text size={12}>{node?.title}</CM.Text>
                                        <CM.Icon type={FieldIconTypeMap[field.type]} style={{ margin: '0 4px' }} />
                                        <CM.Text size={12}>{field?.name}</CM.Text>
                                    </SCxItem>
                                }
                            />
                        )
                    )
                }
                case 'ARG_VARIABLE': {
                    const nodeList = find(option => option.type === VariableSourceType.parentNode, options)
                    const node = find(node => node.id === item?.ref?.nodeId, nodeList?.list || [])
                    const arg = find(arg => arg.id === item.ref?.argId, node?.children ?? [])
                    // const field = ds?.schema[item.ref?.fieldId ?? '']
                    if (!node || node.type !== 'ARG_VARIABLE' || !arg) {
                        return
                    }
                    return (
                        <TagPreview
                            onClick={() => remove(index)}
                            label={
                                <SCxItem>
                                    <CM.Text size={12}>{node?.title}</CM.Text>
                                    <CM.Icon type={node.icon} style={{ margin: '0 4px' }} />
                                    <CM.Text size={12}>{arg?.title}</CM.Text>
                                </SCxItem>
                            }
                        />
                    )
                }
                case 'MANUAL_INPUT': {
                    return <TagPreview onClick={() => remove(index)} icon="PropertyEmail" label={item.input} />
                }
                case 'COMMITTER': {
                    return null
                }
                case 'USER_IDENTIFIER': {
                    return null
                }
                default: {
                    return null
                }
            }
        },
        [appId, dataSources, options, remove]
    )

    const handleAdd = useCallback(() => {
        if (isEmpty(inputValue)) {
            setOpened(false)
            return
        }
        if (!emailRegex.test(inputValue)) {
            return Toast.warning('不符合邮箱格式')
        }
        append({ type: 'MANUAL_INPUT', input: inputValue })
        setInputValue('')
        setOpened(false)
    }, [append, inputValue])

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
                handleAdd()
            }
        },
        [handleAdd]
    )

    const handleVariableChange = useCallback(
        (value: VariableADTvalue) => {
            if (value.type === VariableType.UPSTREAM) {
                const { upstreamVariable } = value
                if (!upstreamVariable) {
                    return
                }
                const { nodeId, fieldId } = upstreamVariable
                if (!nodeId || !fieldId) {
                    return
                }
                append({ type: 'UPSTREAM', ref: { nodeId, fieldId } })
                setOpened(false)
            }
            if (value.type === VariableType.ARG_VARIABLE) {
                const { argVariable } = value
                if (!argVariable) {
                    return
                }
                const { nodeId, argId } = argVariable
                if (!nodeId || !argId) {
                    return
                }
                append({ type: 'ARG_VARIABLE', ref: { nodeId, argId } })
                setOpened(false)
            }
        },
        [append]
    )

    return (
        <>
            <Popover position="bottom-start" disabled={disabledWithVersion} opened={opened} width={280} onClose={() => setOpened(false)}>
                <Popover.Target>
                    <SCxTargetContainer ref={targetRef}>
                        <SCxList>
                            {fields.map(({ id, ...item }, index: number) => (
                                <SCxItem key={id}>{previewItemRender(item, index)}</SCxItem>
                            ))}
                        </SCxList>
                        <SCxAddButton
                            disabled={disabledWithVersion}
                            radius={100}
                            onClick={() => setOpened(true)}
                            icon={<IconFont type="Add" size={16} fill="var(--color-gray-500)" />}
                        >
                            添加
                        </SCxAddButton>
                    </SCxTargetContainer>
                </Popover.Target>
                <Popover.Dropdown>
                    <div ref={dropDownRef}>
                        <SCxContent>
                            <Input
                                value={inputValue}
                                icon={<IconFont type="PropertyEmail" size={16} fill="var(--color-gray-500)" />}
                                onChange={e => setInputValue(e.target.value)}
                                styles={{
                                    wrapper: {
                                        flex: 1
                                    },
                                    input: {
                                        borderStyle: 'none',
                                        fontSize: 'var(--font-size-normal)',
                                        paddingLeft: '24px!important'
                                    },
                                    icon: {
                                        width: 16
                                    }
                                }}
                                placeholder="请输入邮箱按回车添加"
                                onKeyDown={handleKeyDown}
                            />
                            <CM.Icon
                                type="ArrowElbowDownLeft"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleAdd()}
                                color="var(--color-gray-400)"
                            />
                        </SCxContent>
                        <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                        <SCxContent>
                            {/* <Group style={{ height: 36 }} spacing={8}>
                            <IconFont type="UserRole" size={16} fill="var(--color-gray-500)" />
                            <Text size={14}> 流程触发人 </Text>
                        </Group> */}
                            <NodeFieldVariable options={options} onSelect={handleVariableChange} />
                        </SCxContent>
                    </div>
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
