import { Empty, Select } from '@byecode/ui'
import type { FlowNode, SendMsgDingTalkRobotRootNode } from '@lighthouse/shared'
import {
    CollapseBox,
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    getDefaultValueOptionsByInnerType,
    pureTextFieldTypes,
    VariableSourceType
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'

import type { DingTalkRobotMode } from '@/components/OtherPlatFormModal'
import { AddDingTalkRobot, DingTalkRobotModal } from '@/components/OtherPlatFormModal'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useOtherPlatformList } from '@/shared/reusable'

import { SEND_MSG_DINGTALK_OPTIONS } from '../../constant'
import * as CM from '../../styles'
import { LinkMsgConfig } from './LinkMsgConfig'
import { RichTextMsgConfig } from './RichTextMsgConfig'
import * as SC from './styles'
import { TextMsgConfig } from './TextMsgConfig'

interface DingTalkRobotActionConfigureProps {
    allParentNodes: FlowNode[]
}

interface State {
    mode: DingTalkRobotMode
    opened: boolean
    id?: string
}

export const DingTalkRobotActionConfigure: React.FC<DingTalkRobotActionConfigureProps> = ({ allParentNodes }) => {
    const { control, register, watch, setValue } = useFormContext<Pick<SendMsgDingTalkRobotRootNode, 'config'>>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { data: otherPlatforms } = useOtherPlatformList()
    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })

    const [msgType] = watch(['config.msgtype'])

    const robotList = useMemo(() => otherPlatforms?.filter(item => item.type === 'DING_TALK_ROBOT') ?? [], [otherPlatforms])

    const options = useMemo(
        () =>
            getDefaultValueOptionsByInnerType({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: getAllCanBeUpstreamNodes(allParentNodes) || []
                    }
                ],
                validateInnerType: innerType => innerType === 'TEXT'
            }),
        [allParentNodes, dataSourceList]
    )

    const handleUpdate = useCallback(
        (value: string) => {
            const data = find(item => item.id === value, otherPlatforms ?? [])
            setState({ opened: true, mode: 'update', id: data?.id })
        },
        [otherPlatforms, setState]
    )

    const handleAdd = useCallback(() => {
        setState({ opened: true, mode: 'add', id: undefined })
    }, [setState])

    const robotListOptions = useMemo(
        () =>
            robotList.map((item, index) => ({
                label: item.name,
                value: item.id,
                icon: <SC.Icon size={16} type="BrandLogoDingding" />,
                extra: (
                    <SC.RightFill
                        onMouseDown={ev => {
                            ev.stopPropagation()
                            handleUpdate(item.id)
                        }}
                    >
                        <CM.Icon color="var(--color-gray-400)" type="PencilSimple" />
                    </SC.RightFill>
                )
            })),
        [handleUpdate, robotList]
    )

    const differentConfigEle = useMemo(() => {
        switch (msgType) {
            case 'text': {
                return <TextMsgConfig options={options} />
            }

            case 'markdown': {
                return <RichTextMsgConfig />
            }

            case 'link': {
                return <LinkMsgConfig options={options} />
            }

            default: {
                return null
            }
        }
    }, [options, msgType])

    return (
        <>
            <CM.Container>
                <CollapseBox label="选择账号" required>
                    <Controller
                        control={control}
                        name="config.id"
                        render={({ field }) => (
                            <SC.SelectContainer>
                                <Select
                                    hiddenEmpty
                                    placeholder="请选择"
                                    styles={{
                                        root: {
                                            margin: '0 16px'
                                        },
                                        item: {
                                            '&:hover>div>*:last-child': {
                                                display: 'flex'
                                            }
                                        }
                                    }}
                                    options={robotListOptions}
                                    prefix={
                                        find(item => item.value === field.value, robotListOptions) && <CM.Icon type="BrandLogoDingding" />
                                    }
                                    {...field}
                                    dropdownProps={{
                                        extra: (
                                            <>
                                                {robotListOptions.length === 0 && (
                                                    <Empty
                                                        icon="BrandLogoDingding"
                                                        styles={{
                                                            root: {
                                                                minHeight: '75px'
                                                            }
                                                        }}
                                                        description="您还没添加钉钉机器人账号"
                                                    />
                                                )}
                                                <Divider color="var(--color-gray-200)" />
                                                <AddDingTalkRobot onClick={() => handleAdd()} />
                                            </>
                                        )
                                    }}
                                />
                            </SC.SelectContainer>
                        )}
                    />
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="执行操作" required>
                    <Controller
                        control={control}
                        name="config.msgtype"
                        render={({ field }) => (
                            <SC.SelectContainer>
                                <Select
                                    styles={{
                                        root: {
                                            margin: '0 16px'
                                        }
                                    }}
                                    options={SEND_MSG_DINGTALK_OPTIONS}
                                    {...field}
                                />
                            </SC.SelectContainer>
                        )}
                    />
                </CollapseBox>
                {msgType && (
                    <>
                        <Divider color="var(--color-gray-200)" />
                        <CollapseBox label="配置参数">{differentConfigEle}</CollapseBox>
                    </>
                )}
                <DingTalkRobotModal style={{ zIndex: 220 }} open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
            </CM.Container>
        </>
    )
}
