import type { GroupProps } from '@byecode/ui'
import { Checkbox, Divider, Flex, Group, IconFont } from '@byecode/ui'
import type { RoleProtocols } from '@lighthouse/core';
import { PageOpenType } from '@lighthouse/core'
import type { AppDepartment } from '@lighthouse/shared'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { Box, Text } from '@mantine/core'
import { find, reduce } from 'rambda'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { addPageRolesAtom, removePageRolesAtom, updatePageAtom } from '@/atoms/page/action'
import { pageAtomFamily } from '@/atoms/page/state'
import { useDataSourceDepartments, useDataSourceRoles } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDepartments, usePageGroups } from '@/hooks/usePageGroups'

import { type TagData,AddMember } from './AddMember';
import { GroupItem } from './GroupItem'

interface PageVisibilityProps {
    pageId: string
    mode: GroupProps['mode']
    addMemberWithinPortal?: boolean
    style?: React.CSSProperties
}

const SCxItem = styled.div`
    /* padding: 0 16px; */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    color: var(--color-black);
`
export const PageVisibility: FC<PageVisibilityProps> = ({ pageId, style, addMemberWithinPortal, mode = 'default' }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const allRoles = useDataSourceRoles()
    const allDepartments = useDataSourceDepartments()
    const pageRoleIds = usePageGroups(pageId)
    const pageDepartmentIds = usePageDepartments(pageId)

    const pageContent = useAtomData(pageAtomFamily(pageId))
    const { open = PageOpenType.all } = pageContent ?? {}

    const { run: removePageRoles } = useAtomAction(removePageRolesAtom)
    const { run: addPageRoles } = useAtomAction(addPageRolesAtom)
    const { run: updatePage } = useAtomAction(updatePageAtom)

    const pageRoles = useMemo(
        () =>
            pageRoleIds.reduce<RoleProtocols[]>((prev, cur) => {
                const role = find(item => item.id === cur, allRoles)
                if (role) {
                    prev.push(role)
                }
                return prev
            }, []),
        [allRoles, pageRoleIds]
    )
    const pageDepartments = useMemo(
        () =>
            pageDepartmentIds.reduce<AppDepartment[]>((prev, cur) => {
                const department = find(item => item.id === cur, allDepartments)
                if (department) {
                    prev.push(department)
                }
                return prev
            }, []),
        [allDepartments, pageDepartmentIds]
    )

    const value = useMemo(() => {
        const groupTag: TagData[] = pageRoles.map(({ id, name }) => ({ id, name, type: 'role' }))
        const departmentTag: TagData[] = pageDepartments.map(({ id, name }) => ({
            id,
            name,
            type: 'department'
        }))
        return [...groupTag, ...departmentTag]
    }, [pageDepartments, pageRoles])

    // 切换可见性设置
    const onVisibilityChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!pageContent) {
                return null
            }
            await updatePage({ id: pageId, open: Number(e.target.value) as PageOpenType })
        },
        [pageContent, updatePage, pageId]
    )
    // 添加用组件可见
    const onAddGroup = useCallback(
        async (data: TagData[]) => {
            if (data.length === 0) {
                return
            }
            const addParams = reduce<TagData, { roleIds: string[]; departmentIds: string[] }>(
                (preVal, curVal) => {
                    const role = find(p => p.id === curVal.id, allRoles)
                    const department = find(p => p.id === curVal.id, allDepartments)

                    if (curVal.type === 'department' && department) {
                        preVal.departmentIds = [...preVal.departmentIds, department.id]
                    }
                    if (curVal.type === 'role' && role) {
                        preVal.roleIds = [...preVal.roleIds, role.id]
                    }
                    return preVal
                },
                { roleIds: [], departmentIds: [] },
                data
            )
            await addPageRoles({ pageId, ...addParams })
        },
        [addPageRoles, allDepartments, allRoles, pageId]
    )

    return (
        <Group
            label="页面可见性"
            mode={mode}
            styles={{
                root: {
                    padding: mode === 'none' ? 0 : undefined
                }
            }}
            style={style}
        >
            <SCxItem>
                <Flex alignItems="center" gap="8px">
                    <IconFont color="var(--color-gray-400)" size={16} type="ActiveNode-Website" />
                    <Text>所有人可见</Text>
                </Flex>
                <Checkbox
                    radius="100%"
                    value={PageOpenType.all}
                    checked={open === PageOpenType.all}
                    size="xs"
                    onChange={onVisibilityChange}
                />
            </SCxItem>
            <SCxItem>
                <Flex alignItems="center" gap="8px">
                    <IconFont color="var(--color-gray-400)" size={16} type="Lock" />
                    <Text>登录后可见</Text>
                </Flex>
                <Checkbox
                    radius="100%"
                    value={PageOpenType.login}
                    checked={open === PageOpenType.login}
                    size="xs"
                    onChange={onVisibilityChange}
                />
            </SCxItem>
            <SCxItem>
                <Flex alignItems="center" gap="8px">
                    <IconFont color="var(--color-gray-400)" size={16} type="User" />
                    <Text>指定角色或部门</Text>
                </Flex>
                <Checkbox
                    radius="100%"
                    value={PageOpenType.part}
                    checked={open === PageOpenType.part}
                    size="xs"
                    onChange={onVisibilityChange}
                />
            </SCxItem>

            {open === PageOpenType.part && (
                <>
                    <Divider style={{ margin: '12px 0' }} />
                    <Box>
                        <Text color="var(--color-gray-400)" size={12} mb={8}>
                            以下角色或部门的用户登录后可见
                        </Text>
                        {pageRoles.map(item => (
                            <GroupItem
                                key={item.id}
                                name={item.name}
                                icon="SpaceTeam"
                                disabled={disabledWithVersion}
                                onRemove={() => removePageRoles({ pageId, id: item.id, type: 'role' })}
                            />
                        ))}
                        {pageDepartments.map(item => (
                            <GroupItem
                                key={item.id}
                                name={item.name}
                                icon="Departments"
                                disabled={disabledWithVersion}
                                onRemove={() => removePageRoles({ pageId, id: item.id, type: 'department' })}
                            />
                        ))}
                        <AddMember
                            disabled={disabledWithVersion}
                            color="var(--color-main)"
                            withinPortal={addMemberWithinPortal}
                            name="添加"
                            value={value}
                            onAddUsers={onAddGroup}
                        />
                    </Box>
                </>
            )}
        </Group>
    )
}
