import { Divider } from '@byecode/ui'
import type { BaseFlowNode, FlowNode, SubProcessArg, VariableTriggerConfig } from '@lighthouse/shared'
import { CollapseBox } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSWR from 'swr'

import * as srv from '@/services'

import * as SC from '../../styles'
import { SettingSubProcessContent } from './SettingSubProcessContent'
import { SubProcessSelector } from './SubProcessSelector'

interface RecordsConfigProps {
    allParentNodes: FlowNode[]
}

export const RecordsConfig: React.FC<RecordsConfigProps> = ({ allParentNodes }) => {
    const { control, setValue, getValues } = useFormContext()
    const { data: workflows = [], mutate } = useSWR(`allFlowNodes`, () => srv.listFlowNodes(), {
        revalidateOnFocus: false
    })
    const subProcessId = useWatch({ control, name: 'config.recordsConfig.subProcessId' })
    const nodeId = useWatch({ control, name: 'config.recordsConfig.nodeId' })
    const parentNodes = useMemo(() => {
        const node = find(item => item.id === nodeId, allParentNodes)
        if (!node) {
            return []
        }
        return [node]
    }, [allParentNodes, nodeId])

    const handleFetchWorkflows = useCallback(() => {
        return mutate()
    }, [mutate])

    useEffect(() => {
        const workflow = find(item => item.id === subProcessId && item.type === 'subProcess', workflows)
        if (workflow) {
            const nodeList = workflow.content?.nodes || []
            const variableNode = find(item => item.data.nodeType === 'ARG_TRIGGER', nodeList) as BaseFlowNode<VariableTriggerConfig>
            if (!variableNode) {
                return
            }
            const { config } = variableNode.data
            if (!config || !config?.args) {
                return
            }
            const data: SubProcessArg[] = getValues('config.recordsConfig.args')
            const newParameters = config.args.map(item => {
                const dataItem = find(d => d.id === item.id, data || [])
                return {
                    ...item,
                    value: dataItem?.value
                }
            })
            setValue('config.recordsConfig.args', newParameters)
        }
    }, [getValues, setValue, subProcessId, workflows])

    return (
        <>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="循环的子流程">
                <SC.Content>
                    <Controller
                        name="config.recordsConfig.subProcessId"
                        control={control}
                        render={({ field }) => (
                            <SubProcessSelector
                                workflows={workflows}
                                value={field.value}
                                onFetchWorkflows={handleFetchWorkflows}
                                onChange={val => {
                                    field.onChange?.(val)
                                }}
                            />
                        )}
                    />
                </SC.Content>
            </CollapseBox>

            {subProcessId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="子流程参数值设置">
                        <SC.Content>
                            <SC.Tip>设置子流程的参数值，参数值将会传递到子流程中。</SC.Tip>
                            <Controller
                                name="config.recordsConfig.args"
                                control={control}
                                render={({ field }) => (
                                    <SettingSubProcessContent
                                        data={field.value}
                                        allParentNodes={allParentNodes}
                                        parentNodes={parentNodes}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </SC.Content>
                    </CollapseBox>
                </>
            )}
        </>
    )
}
