import { Divider, Flex, Group } from '@byecode/ui'
import { Card, Grid, Title } from '@mantine/core'
import React from 'react'

interface DividerComponentsDemoProps {}

export const DividerComponentsDemo: React.FunctionComponent<DividerComponentsDemoProps> = props => {
    return (
        <Card withBorder>
            <Group label={<Title order={2}>分割线</Title>} renderRightIcon={() => null}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Divider label="分割线" />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Divider label="分割线" color="red" />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Divider label="分割线" labelPosition="left" />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Divider label="分割线" labelPosition="right" />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Divider direction="horizontal" />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Flex style={{ height: 30 }} justifyContent='space-around'>
                            <Divider direction="vertical" solidWidth={1} />
                            <Divider direction="vertical" solidWidth={1} label='分割线' />
                            <Divider direction="vertical" solidWidth={2} color='red' />
                            <Divider direction="vertical" solidWidth={4} />
                        </Flex>
                    </Grid.Col>
                </Grid>
            </Group>
        </Card>
    )
}
