import styled, { css } from 'styled-components'

export const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-gray-100);
`

export const Progress = styled.div`
    position: relative;
    width: 100%;
    height: 6px;
    background-color: #f2f4f3;
    border-radius: 16px;
`
export const ProgressBar = styled.div`
    position: absolute;
    inset: 0;
    z-index: 1;
    border-radius: inherit;
    background-color: var(--color-red-500);
`

export const Button = styled.button<{ theme?: 'dark' | 'light' }>`
    all: unset;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;
    &:hover {
        opacity: 0.8;
    }
    ${({ theme }) =>
        theme === 'dark'
            ? css`
                  color: var(--color-white);
                  background-color: var(--color-black);
              `
            : css`
                  color: var(--color-black);
                  background-color: var(--color-gray-100);
              `}
`

export const CloseButton = styled.button`
    appearance: none;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: #26415a0f;
    border-radius: 34px;
    font-size: 16px;
    color: var(--color-gray-500);
    &:hover {
        opacity: 0.8;
    }
`
