import { Button, IconFont } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { SubProcessVariable } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { omit, reduce } from 'rambda'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { VariableItem } from './VariableItem'

interface VariableListProps {
    prefixName?: string
}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`

export const VariableList: React.FunctionComponent<VariableListProps> = ({ prefixName = 'config' }) => {
    const { control } = useFormContext()
    const [activeId, setActiveId] = useState('')

    const {
        fields: f,
        remove,
        append,
        move
    } = useFieldArray({
        control,
        name: `${prefixName}.args`,
        keyName: 'key'
    })

    const fields = f as unknown as SubProcessVariable[]

    const [openedMap, setOpenMap] = useImmer(
        reduce<SubProcessVariable, Record<string, boolean>>(
            (preVal, { id }) => {
                preVal[id] = true
                return preVal
            },
            {},
            fields
        )
    )

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))
    // SubProcessVariable
    const getInitParam = useCallback(() => {
        const id = nanoid(12)
        return {
            id,
            name: '未命名参数',
            innerType: 'TEXT'
        }
    }, [])

    const handleDragStart = useCallback((event: DragStartEvent) => {
        const { active } = event
        setActiveId(active?.id.toString())
    }, [])

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.id === active.id)
                const newIndex = fields.findIndex(item => item.id === over?.id)
                move?.(oldIndex, newIndex)
            }
            setActiveId('')
        },
        [fields, move]
    )

    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragMove={handDragMove}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={fields.map(d => d.id)} strategy={verticalListSortingStrategy}>
                    {fields.map((item, index) => (
                        <VariableItem
                            key={item.id}
                            prefixName={`${prefixName}.args.${index}`}
                            id={item.id}
                            opened={openedMap[item.id] && !activeId}
                            onChangeOpen={opened => {
                                setOpenMap(draft => {
                                    draft[item.id] = opened
                                })
                            }}
                            onDelete={() => {
                                remove(index)
                                setOpenMap(draft => {
                                    draft = omit([item.id], draft)
                                })
                            }}
                        />
                    ))}
                </SortableContext>
                <Button
                    type="tag"
                    style={{ justifyContent: 'flex-start', borderRadius: 100, marginTop: 8 }}
                    onClick={() => {
                        const newItem = getInitParam()
                        setOpenMap(draft => {
                            draft[newItem.id] = true
                        })
                        append(newItem)
                    }}
                    icon={<IconFont size={16} color="var(--color-gray-500)" type="Add" />}
                >
                    <Text>添加</Text>
                </Button>
            </DndContext>
        </SCxContainer>
    )
}
