import { Input } from '@byecode/ui'
import { ErrorMessage, getImageFullUrlInCommon } from '@lighthouse/shared'
import Uploady from '@rpldy/uploady'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { UploadSpaceAvatar } from '@/components/UploadSpaceAvatar'
import { uploadPublicParams } from '@/utils/auth'

import type { Field } from './types'

const SCxInputWrapper = styled.div`
    margin-bottom: 24px;
`

const SCxLabel = styled.div`
    display: inline-block;
    color: var(--color-black);
    word-break: break-word;
    cursor: default;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: normal;
`

const SCxRequired = styled.span`
    color: var(--color-red-500);
`

interface FormItemRenderProps {
    name: string
    field: Field
}

export const FormItemRender: React.FC<FormItemRenderProps> = ({ name, field }) => {
    const form = useFormContext()
    const isOtherField = name.includes('other')
    const depField = isOtherField ? name.slice(5).toLowerCase() : ''
    const depFieldValue = form.watch(depField)
    const { label, description, config } = field

    return useMemo(() => {
        if (isOtherField && !(depFieldValue === '其他' || depFieldValue?.includes('其他'))) {
            return null
        }

        const { type, required, rules, errorTip } = config

        // const errorMessage = form.formState.errors?.[name]?.message?.toString()

        switch (type) {
            case 'text':
            case 'password': {
                return (
                    <SCxInputWrapper>
                        <SCxLabel>
                            {label}
                            {required && <SCxRequired> *</SCxRequired>}
                        </SCxLabel>
                        <ErrorMessage name={name} errors={form.formState.errors}>
                            <Input
                                placeholder="请输入"
                                type={type}
                                size="lg"
                                autoComplete="off"
                                radius="md"
                                {...form.register(name, { required: required && (errorTip || `请输入${label}`), ...rules })}
                            />
                        </ErrorMessage>
                    </SCxInputWrapper>
                )
            }
            case 'img': {
                return (
                    <SCxInputWrapper>
                        <SCxLabel>
                            {label}
                            {required && <SCxRequired>*</SCxRequired>}
                        </SCxLabel>
                        <Controller
                            name={name}
                            rules={{ required }}
                            render={({ field: { value, onChange } }) => (
                                <Uploady accept="image/*" {...uploadPublicParams()}>
                                    <UploadSpaceAvatar
                                        background="var(--color-gray-100)"
                                        fontSize={22}
                                        type={value ? 'img' : 'icon'}
                                        size="xLarge"
                                        img={getImageFullUrlInCommon(value) || 'Add'}
                                        onUpdateImg={onChange}
                                    />
                                </Uploady>
                            )}
                        />
                    </SCxInputWrapper>
                )
            }
            default: {
                return null
            }
        }
    }, [isOtherField, depFieldValue, config, form, name, label])
}
