import {
    Button,
    Image,
    Popover,
    Text
} from '@byecode/ui'
import { TagIcon } from '@lighthouse/shared'
import type { PopoverBaseProps } from '@mantine/core'
import React, {
    useMemo,
    useRef,
    useState
} from 'react'
import styled from 'styled-components'

import { tooltipDocumentMap } from './constants'
import type { TooltipDocumentType } from './types'

interface PopoverQuestionProps extends Pick<PopoverBaseProps, 'position' | 'arrowOffset'> {
    type: TooltipDocumentType
    mainAxis?: number
    renderTarget: (iconEle: React.ReactNode) => React.ReactNode
}

interface QuestionDropDownProps {
    type: TooltipDocumentType
    onPointerEnter?: () => void
    onPointerLeave?: () => void
}

const SCxDropDown = styled.div`
    width: 100%;
    padding: 12px;
    color: var(---color-white);
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const PopoverQuestion: React.FunctionComponent<PopoverQuestionProps> = ({
    type,
    position = 'left-start',
    arrowOffset = 2,
    mainAxis,
    renderTarget
}) => {
    const ref = useRef(null)
    const [opened, setOpened] = useState(false)

    const isEnterDropDownRef = useRef(false)

    const popoverMainAxis = useMemo(() => {
        if (mainAxis) {
            return mainAxis
        }
        switch (type) {
            case 'orderId':
            case 'orderMoney':
            case 'refoundId':
            case 'refoundMoney':
            case 'shopDescribe': {
                return 30
            }

            default: {
                return 22
            }
        }
    }, [mainAxis, type])

    return (
        <>
            <Popover
                trigger="hover"
                arrowColor="var(--color-black)"
                withArrow
                arrowOffset={arrowOffset}
                opened={opened}
                withinPortal
                position={position}
                width={206}
                onChange={setOpened}
                offsetOptions={{
                    mainAxis: popoverMainAxis
                }}
            >
                <Popover.AnchorEl anchorEl={ref.current} />
                <div ref={ref}>
                    {renderTarget(
                        <TagIcon
                            iconSize={16}
                            size={16}
                            icon="Question"
                            iconColor="var(--color-gray-400)"
                            enableHover
                            onMouseEnter={() => {
                                setOpened(true)
                            }}
                            onPointerLeave={() =>
                                setTimeout(() => {
                                    if (!isEnterDropDownRef.current) {
                                        setOpened(false)
                                    }
                                }, 300)
                            }
                        />
                    )}
                </div>
                <Popover.Dropdown
                    compact
                    styles={{
                        dropdown: {
                            backgroundColor: 'var(--color-black)',
                            borderStyle: 'none'
                        }
                    }}
                >
                    <QuestionDropDown
                        type={type}
                        onPointerEnter={() => {
                            isEnterDropDownRef.current = true
                        }}
                        onPointerLeave={() => {
                            setOpened(false)
                            isEnterDropDownRef.current = false
                        }}
                    />
                </Popover.Dropdown>
            </Popover>
        </>
    )
}

export const QuestionDropDown: React.FC<QuestionDropDownProps> = ({ type, onPointerEnter, onPointerLeave }) => {
    const data = tooltipDocumentMap[type]
    return (
        <SCxDropDown onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
            {data.image && <Image src={data.image} radius={4} height={98} />}
            <Text size={14} whiteSpace="pre-wrap" lineHeight="22px" color="var(--color-white)">
                {data.title}
            </Text>
            {data.link && (
                <Button
                    style={{ background: '#FFFFFF38', color: 'var(--color-white)', borderColor: 'transparent' }}
                    block
                    onClick={() => window.open(data.link, '_target')}
                >
                    {data.linkText}
                </Button>
            )}
        </SCxDropDown>
    )
}
