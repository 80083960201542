import { type SpaceVersion, spaceIClickVersion } from '@lighthouse/core'
import { getBrand, getBrandInfo, spaceGradeCodeMap, spaceGradeNameMap } from '@lighthouse/shared'
import { add, isAfter } from 'date-fns'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useSpaceQuota } from '@/shared/reusable'

interface SpaceGradeTagProps {
    value: number
    isLimitHidden?: boolean
}

const SCxContainer = styled.span`
    padding: 2px 6px;
    /* height: 20px; */
    line-height: 18px;
    font-size: var(--font-size-sm);
    color: var(--color-theme-7);
    background-color: var(--color-theme-3);
    border-radius: 4px;
    flex-shrink: 0;
    font-weight: 400;
`
export const SpaceGradeTag: React.FunctionComponent<SpaceGradeTagProps> = ({ value, isLimitHidden = true }) => {
    const { data: quota } = useSpaceQuota()
    const name = useMemo(() => {
        const INFO = getBrandInfo()
        const version = INFO.space.gradeVersions[value]
        return spaceGradeNameMap[version]
    }, [value])

    const isExpiringSoon = useMemo(() => {
        if (!quota || quota.currentVersionCode === 0) {
            return
        }

        return isAfter(add(Date.now(), { days: 7 }), quota.expiredAt)
    }, [quota])

    if (quota && isLimitHidden && quota.currentVersionCode >= value) {
        return null
    }

    return <SCxContainer>{name + (isExpiringSoon ? ' | 即将到期' : '')}</SCxContainer>
}
