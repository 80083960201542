import { IconFont } from '@byecode/ui'
import { Checkbox, Group } from '@mantine/core'
import styled, { css } from 'styled-components'

export const Space = styled.div`
    width: 100%;
    height: 8px;
`

export const Container = styled.div`
    width: 100%;
    padding-bottom: 50px;
`

export const Content = styled.div`
    padding: 0 16px 0 16px;
`

export const FormItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    padding: 4px 0;
`

export const FormItemLabelWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 4px;
`

export const FormItemLabel = styled.span<{ required?: boolean }>`
    position: relative;
    ${({ required }) =>
        required &&
        css`
            &::after {
                content: '*';
                position: absolute;
                top: 0;
                right: -12px;
                color: var(--color-red-500);
            }
        `}
`

export const FormItemContent = styled.div`
    flex-shrink: 0;
    width: 180px;
`

export const ListItem = styled.div`
    margin-bottom: 8px;
`

export const Header = styled.div`
    height: 40px;
    line-height: 40px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
`

export const Require = styled.span`
    color: var(--color-red-500);
`

export const Wrapper = styled.div`
    padding: 14px 16px 10px 16px;
`

export const FlexBetween = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
`

export const FlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
`

export const CheckItem = styled(Checkbox)`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    background-color: var(--color-gray-100);
    padding: 12px 14px;
`

export const Title = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
`

export const Icon = styled(IconFont)`
    margin-right: 6px;
`

export const Check = styled(Checkbox)`
    display: flex;
    align-items: center;
    color: var(--color-black);
    font-weight: var(--font-weight-normal);
`

export const CheckboxGroup = styled(Checkbox.Group)`
    & .mantine-InputWrapper-label {
        width: 100%;
    }
`

export const Description = styled.div`
    margin-top: 8px;
    color: var(--color-gray-500);
    line-height: 20px;
    font-size: var(--font-size-sm);
`

export const BasicListItem = styled(Group)`
    padding: 0 16px;
    min-height: 36px;
    font-size: 14px;
`

export const Tip = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin: 4px 0;
    line-height: 20px;
`
