import { ErrorFallback } from '@lighthouse/block'
import { Tabs } from '@mantine/core'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'

import { PanelRender } from '../PanelRender'
import * as CM from '../styles'
import type { ApplicationSettingTabs, ModuleType } from '../type'
import { ModuleTypeMap } from '../type'

interface SettingTabsProps {
    appId: string
    currentTab: ModuleType
    options: ApplicationSettingTabs
    onChange?: (tab: ModuleType) => void
}

export const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 0 12px 4px 8px;
    color: var(--color-black);
`

export const Tab = styled(Tabs.Tab)`
    border-style: none !important;
    &[data-active] {
        background-color: var(--color-gray-100);
        /* font-weight: bold; */
    }

    :hover {
        background-color: var(--color-gray-100);
    }
`

const SettingTabs: React.FC<SettingTabsProps> = ({ appId, currentTab, options, onChange }) => {
    return (
        <Tabs
            value={currentTab.toString()}
            onTabChange={val => val && onChange?.(Number(val) as ModuleType)}
            orientation="vertical"
            styles={{
                root: {
                    width: '100%',
                    height: '100%',
                    borderLeft: '1px solid var(--color-gray-200)'
                },
                tabsList: {
                    width: 254,
                    padding: '12px',
                    backgroundColor: 'var(--color-white)',
                    borderRight: '1px solid var(--color-gray-200)',
                    flexShrink: 0
                },
                tabLabel: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                },
                tab: {
                    padding: '8px',
                    marginTop: 8,
                    borderRadius: 8
                },
                panel: {
                    width: '100%',
                    height: '100%',
                    padding: 16,
                    backgroundColor: 'var(--color-gray-50)',
                    overflow: 'hidden'
                }
            }}
        >
            <Tabs.List>
                {/* <Title>设置</Title> */}
                {options.map(({ label, value, iconColor, icon }) => (
                    <Tab value={value.toString()} key={value}>
                        <CM.SquareIcon type={icon} color="var(--color-white)" bgColor={iconColor} />
                        <CM.Text> {label}</CM.Text>
                    </Tab>
                ))}
            </Tabs.List>
            {options.map(({ label, value }) => {
                const ModuleName = ModuleTypeMap.get(value)
                if (!ModuleName) {
                    return null
                }
                return (
                    <Tabs.Panel key={value} value={value.toString()}>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <PanelRender key={value} moduleName={ModuleName} />
                        </ErrorBoundary>
                    </Tabs.Panel>
                )
            })}
        </Tabs>
    )
}

export default SettingTabs
