import type { FieldType, RichTextContentProtocol } from '@lighthouse/core'

export const getDefaultFiledValue: (type: FieldType) => RichTextContentProtocol = (type: FieldType) => {
    return {
        type: 'doc',
        content: [
            {
                type: 'paragraph',
                content: []
            }
        ]
    }
}
