import { Flex, IconFont, Text } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import { openSpaceGrade } from '@/components/SpaceGrade/event'
import { useSpaceQuota } from '@/shared/reusable'

const StyledContent = styled.div`
    margin-top: 12px;
    padding: 8px 4px 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(38, 65, 90, 0.06);
`

const StyledButton = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    color: var(--color-main);
    line-height: 16px;
    &:hover {
        background-color: rgba(38, 65, 90, 0.06);
    }
`

export const SystemSms = () => {
    const { data } = useSpaceQuota()
    const rest = data ? data.sms.quota - data.sms.usage : 0

    return (
        <>
            <Text color="var(--color-gray-400)" size={12} lineHeight="20px" ellipsis={false} whiteSpace="normal">
                Byecode提供的短信验证码签名是 Byecode 验证码托管服务，节省您的时间和精力，提高您的搭建效率。
            </Text>
            <StyledContent>
                <Flex gap={4}>
                    <IconFont type="Plain" color={rest === 0 ? 'var(--color-red-500)' : 'var(--color-gray-400)'} />
                    <Text color={rest === 0 ? 'var(--color-red-500)' : undefined}>剩余短信：{rest}</Text>
                </Flex>
                <StyledButton
                    type="button"
                    onClick={() => {
                        openSpaceGrade('purchase')
                    }}
                >
                    <IconFont size={16} type="Publish2" color="var(--color-main)" />
                    <Text>购买增购包</Text>
                </StyledButton>
            </StyledContent>
        </>
    )
}
