import { backgroundTransitionOnClick, Button, DesktopModal, Empty, IconFont, Input, Popover, Text, Tooltip, usePopoverHeight } from '@byecode/ui'
import type { ButtonAction, ButtonHandleEvent, DataSourceAbstract, ViewType } from '@lighthouse/core'
import { DataSourceType, RecordOpenType } from '@lighthouse/core'
import type { actionTypeInfos, BeActionFlowTypes, FlowActionTypes, WorkflowType } from '@lighthouse/shared'
import { buttonActionsMap, flowActionTypes, getIsIncludesDisableDataSourceInWorkFlow, useAtomAction } from '@lighthouse/shared'
import { useUncontrolled } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import { useAtomCallback } from 'jotai/utils'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { updateWorkflowListAtom } from '@/atoms/workflow/action'
import { workflowLabelMap } from '@/constants/flow'
import { FlowDetail } from '@/containers/FlowDetail'
import { addNewTriggerNodeWithEdges } from '@/containers/FlowDetail/utils/addNode'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import * as srv from '@/services'

interface ActionTypeSelectorProps {
    value?: ButtonAction
    actionTypeInfos: typeof actionTypeInfos
    viewType?: ViewType
    onUpdate?: (value: string) => void
    onChange?: (value: ButtonAction) => void
}

const SCxActionTypeSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SCxListItemWrapper = styled.div`
    overflow-x: hidden;
    overflow-y: auto;

    cursor: pointer;
`

const SCxListItem = styled.div`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--font-size-normal);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not([data-disabled='true']) {
        ${backgroundTransitionOnClick}
    }
`

const SCxListTip = styled.div`
    padding: 8px 16px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

const SCxListItemLabel = styled.div`
    flex: 1;
`

const SCxEditButton = styled(Button)`
    flex-shrink: 0;
    margin-left: 8px;
`

type ActionTypeSelectorItem = {
    value: string
    icon: string
    label: string
    children: Omit<ActionTypeSelectorItem, 'children'>[]
    divider?: boolean
    color?: string
}

interface ListMenuProps {
    item: ActionTypeSelectorItem
    typeName?: string
    disabled?: boolean
    getDsName: () => string
    onCreate?: () => void
    onClick: (value: string) => void
}

const ListMenu: React.FC<ListMenuProps> = ({ item, typeName = '动作流', disabled, getDsName, onCreate, onClick }) => {
    const [open, setOpen] = useState(false)

    return (
        <Popover
            disabled={disabled}
            withinPortal
            width={240}
            trigger="hover"
            position="left-start"
            opened={open}
            onChange={opened => setOpen(opened)}
        >
            <Popover.Target>
                <SCxListItem data-disabled={disabled} style={disabled ? { cursor: 'not-allowed' } : undefined}>
                    <IconFont type={item.icon} color={item.color} size={18} />
                    <SCxListItemLabel>{item.label}</SCxListItemLabel>
                    <IconFont type="ArrowRightSmall" color="var(--color-gray-500)" />
                </SCxListItem>
            </Popover.Target>
            <Popover.Dropdown>
                {item.children.length > 0 ? (
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>
                        {item.children.map((child, childIndex) => (
                            <SCxListItemWrapper key={child.value}>
                                <SCxListItem
                                    onClick={() => {
                                        setOpen(true)
                                        onClick(child.value)
                                    }}
                                >
                                    <IconFont type={child.icon} color="var(--color-gray-400)" size={18} />
                                    {child.label}
                                </SCxListItem>
                            </SCxListItemWrapper>
                        ))}
                    </div>
                ) : (
                    <Empty
                        icon={<IconFont type="MouseClick" color="var(--color-gray-400)" size={18} />}
                        description="暂无数据"
                        styles={{ root: { height: 100 } }}
                    />
                )}
                <SCxListTip>
                    仅支持触发节点中数据表配置选择的是「{getDsName()}」的{typeName}
                </SCxListTip>
                <Divider color="var(--color-gray-200)" />
                <SCxListItemWrapper>
                    <SCxListItem onClick={onCreate}>
                        <IconFont type="Add" color="var(--color-gray-400)" size={18} />
                        创建{typeName}
                    </SCxListItem>
                </SCxListItemWrapper>
            </Popover.Dropdown>
        </Popover>
    )
}

const disabledDs = (ds?: DataSourceAbstract, type?: WorkflowType) => {
    if (!ds) {
        return true
    }
    switch (type) {
        case 'action': {
            return false
        }
        case 'automation': {
            return ds.type === DataSourceType.joinDataSource || ds.sync
        }
        default: {
            return true
        }
    }
}

export const ActionTypeSelector: React.FC<ActionTypeSelectorProps> = ({ value, actionTypeInfos, viewType, onChange, onUpdate }) => {
    const [level1Open, setLevel1Open] = useState(false)
    const { type, data } = value ?? {}
    const [_value, handleChange] = useUncontrolled({ value, onChange })
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { dsId, isForm } = useActionAdderDepParams()
    const dataSource = useDataSource(currentAppId, envId, dsId)
    const { run: updateWorkflowList } = useAtomAction(updateWorkflowListAtom)
    const { height: maxHeight, ref } = usePopoverHeight(level1Open)

    const flowId = type === 'flow' ? data?.flow?.flowId : type === 'automation' ? data?.automation?.flowId : ''

    useEffect(() => {
        updateWorkflowList()
    }, [updateWorkflowList])

    const assembleActionParams = useCallback((value: string, type?: FlowActionTypes): ButtonAction => {
        if (type && !buttonActionsMap.has(value as ButtonHandleEvent)) {
            return {
                type,
                // params: {
                //     flowId: value
                // },
                trigger: 'click',
                data: {
                    [type]: {
                        flowId: value
                    }
                }
            } as ButtonAction
        }

        const defaultParams = buttonActionsMap.get(value as ButtonHandleEvent)

        return defaultParams
            ? clone(defaultParams)
            : {
                  type: 'openPage',
                  //   params: { openPageUrl: '', pageOpenType: RecordOpenType.modal },
                  trigger: 'click',
                  data: {
                      openPage: {
                          openPageUrl: '',
                          pageOpenType: RecordOpenType.modal
                      }
                  }
              }
    }, [])

    const [currentFlowId, setCurrentFlowId] = useState('')
    const [flowModalOpen, setFlowModalOpen] = useState(false)
    const closeModalHandler = useRef<() => void>()

    const handleOpenFlowModal = useCallback(
        (id?: string, onClose?: () => void) => {
            const comingFlowId = type === 'flow' ? data?.flow?.flowId : type === 'automation' ? data?.automation?.flowId : ''
            const flowId = id ?? comingFlowId ?? ''
            setCurrentFlowId(flowId)
            setFlowModalOpen(true)

            // return
            // if (!flowId) {
            //     return
            // }
            // const modalId = nanoid()

            // openModal({
            //     id: modalId,
            //     component: DesktopModal,
            //     withCloseIcon: false,
            //     styles: {
            //         modal: {
            //             width: 1200,
            //             height: 800
            //         },
            //         body: {
            //             padding: 0
            //         }
            //     },
            //     children: (
            //         <ActionAdderProvider isForm={isForm} dsId={dsId} viewType={viewType}>
            //             <FlowDetail
            //                 id={flowId}
            //                 showBack={false}
            //                 showLogTab={false}
            //                 showClose
            //                 onClose={() => {
            //                     closeModal(modalId)
            //                     onUpdate?.(flowId)
            //                     onClose?.()
            //                 }}
            //             />
            //         </ActionAdderProvider>
            //     )
            // })
        },
        [data, type]
    )

    const handleFlowCreate = useCallback(
        async (type: BeActionFlowTypes = 'action') => {
            // 用户表不能作为触发节点(去掉限制 2024.11.1)
            const createActionDepParams =
                !dataSource || getIsIncludesDisableDataSourceInWorkFlow(dataSource) ? undefined : { dataSourceId: dataSource.id }
            const flowInitInfo = addNewTriggerNodeWithEdges('CLICK_TRIGGER', createActionDepParams)
            // eslint-disable-next-line no-return-await
            return await srv.createFlow({
                name: `未命名${workflowLabelMap[type].label}`,
                type,
                description: `未命名${workflowLabelMap[type].label}的描述`,
                ...flowInitInfo
            })
        },
        [dataSource]
    )

    const handleLevel1ItemClick = useCallback(
        async (value: string) => {
            if (flowActionTypes.includes(value as FlowActionTypes)) {
                const workflowId = await handleFlowCreate(value === 'automation' ? 'automation' : 'action')
                handleOpenFlowModal(workflowId /* , () => handleChange(assembleActionParams(workflowId, value as FlowActionTypes)) */)
                closeModalHandler.current = () => handleChange(assembleActionParams(workflowId, value as FlowActionTypes))
                setLevel1Open(false)
                return
            }
            handleChange(assembleActionParams(value))
            setLevel1Open(false)
        },
        [assembleActionParams, handleChange, handleFlowCreate, handleOpenFlowModal]
    )

    const handleLevel2ItemClick = useCallback(
        (value: string, type?: string) => {
            handleChange(assembleActionParams(value, type as FlowActionTypes))
            setLevel1Open(false)
        },
        [assembleActionParams, handleChange]
    )

    const getDsName = useAtomCallback(
        useCallback(
            get => {
                const dsInfo = get(dataSourceAtomFamily({ dsId, envId, appId: currentAppId }))
                return dsInfo?.name ?? ''
            },
            [currentAppId, dsId, envId]
        )
    )

    const currentOption = useMemo(() => {
        const flattenActionTypeInfos = actionTypeInfos.flatMap(item => {
            if (item.children) {
                return item.children
            }
            return item
        })
        if (_value.type === 'flow' || _value.type === 'automation') {
            const comingFlowId =
                _value.type === 'flow' ? _value.data?.flow?.flowId : _value.type === 'automation' ? _value.data?.automation?.flowId : ''
            return flattenActionTypeInfos.find(item => item.value === comingFlowId)
        }
        return flattenActionTypeInfos.find(item => item.value === _value.type)
    }, [_value, actionTypeInfos])

    return (
        <SCxActionTypeSelectorWrapper ref={ref}>
            <Popover withinPortal width={240} opened={level1Open} onChange={setLevel1Open}>
                <Popover.Target>
                    <div style={{ width: 180 }}>
                        <Input
                            readOnly
                            styles={{
                                input: { cursor: 'pointer' }
                            }}
                            prefix={<IconFont type={currentOption?.icon ?? ''} />}
                            value={currentOption?.label}
                            suffix={<IconFont type="ArrowDownSmall" />}
                        />
                    </div>
                </Popover.Target>
                <Popover.Dropdown style={{ maxHeight, overflowY: 'auto' }}>
                    {actionTypeInfos.map((item, index) => (
                        <SCxListItemWrapper key={item.value}>
                            {item.children ? (
                                <div
                                    style={{
                                        marginBottom: item.divider ? 8 : 0,
                                        marginTop: actionTypeInfos[index - 1]?.divider ? 8 : 0
                                    }}
                                >
                                    <Tooltip
                                        disabled={!item.disabledTooltipText}
                                        placement="left"
                                        title={<Text size={12} lineHeight='20px' whiteSpace='pre-wrap' style={{width: 200}}>{item.disabledTooltipText}</Text>}
                                    >
                                        <div>
                                            <ListMenu
                                                disabled={disabledDs(dataSource, item.flowType as WorkflowType)}
                                                typeName={item.flowType === 'automation' ? '自动化' : '动作流'}
                                                item={item}
                                                getDsName={getDsName}
                                                onClick={v => handleLevel2ItemClick(v, item.value)}
                                                onCreate={() => handleLevel1ItemClick(item.value)}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : (
                                <SCxListItem
                                    style={{
                                        marginBottom: item.divider ? 8 : 0,
                                        marginTop: actionTypeInfos[index - 1]?.divider ? 8 : 0
                                    }}
                                    onClick={() => handleLevel1ItemClick(item.value)}
                                >
                                    <IconFont type={item.icon} color={item.color} size={18} />
                                    {item.label}
                                </SCxListItem>
                            )}
                            {item.divider && <Divider color="var(--color-gray-200)" />}
                        </SCxListItemWrapper>
                    ))}
                </Popover.Dropdown>
            </Popover>
            {(value?.type === 'flow' || value?.type === 'automation') && (
                <SCxEditButton
                    icon={<IconFont type="PencilSimple" color="var(--color-gray-400)" />}
                    onClick={() => handleOpenFlowModal(flowId)}
                >
                    编辑
                </SCxEditButton>
            )}
            <DesktopModal
                open={flowModalOpen}
                withCloseIcon={false}
                styles={{
                    modal: {
                        width: 1200,
                        height: 800
                    },
                    body: {
                        padding: 0
                    }
                }}
                onClose={() => setFlowModalOpen(false)}
            >
                <FlowDetail
                    id={currentFlowId}
                    showBack={false}
                    showLogTab={false}
                    showClose
                    onClose={() => {
                        onUpdate?.(currentFlowId)
                        setFlowModalOpen(false)
                        closeModalHandler.current?.()
                    }}
                />
            </DesktopModal>
        </SCxActionTypeSelectorWrapper>
    )
}
