import { Button, closeModal, DesktopModal, Divider, Empty, Flex, IconFont, openModal, Select, Tag } from '@byecode/ui'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'
import { ReactFlowProvider } from 'reactflow'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { workflowLabelMap } from '@/constants/flow'
import { FlowDetail } from '@/containers/FlowDetail'
import { addNewTriggerNodeWithEdges } from '@/containers/FlowDetail/utils/addNode'
import * as srv from '@/services'
import type { WorkflowResult } from '@/services/types'

import { FlowEditor } from '../../../FlowEditor'
import * as SC from '../../styles'

const SCxSubProcessSelectorWrapper = styled.div``

const SCxApprovalFlowWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: 6px;
    overflow: hidden;
`

const SCxApprovalFlowContainer = styled.div`
    width: 100%;
    border-radius: 6px;
    background-color: var(--color-gray-100);
`

const SCxApprovalFlowContent = styled.div`
    position: relative;
    height: 245px;
    margin-left: 85px;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const SCxApprovalFlowEdit = styled(Button)`
    margin-top: 12px;
`

const SCxApprovalCreatorButton = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 16px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

interface SubProcessSelectorProps {
    value: string
    workflows: WorkflowResult
    onFetchWorkflows: () => Promise<WorkflowResult | undefined>
    onChange?: (value: string) => void
}

export const SubProcessSelector: React.FC<SubProcessSelectorProps> = ({ value, workflows, onChange, onFetchWorkflows }) => {
    const flowNodesOptions = useMemo(
        () =>
            workflows
                .filter(workflow => workflow.type === 'subProcess')
                .map(item => ({
                    label: item.name,
                    value: item.id,
                    // icon: <IconFont type="Reorder" fill="var(--color-green-500)" size={16} />
                    icon: 'Reorder'
                })),
        [workflows]
    )

    const flow = useMemo(() => {
        return workflows.find(item => item.id === value)
    }, [value, workflows])

    const handleOpenFlowModal = useCallback(
        (id?: string, onClose?: () => void) => {
            const flowId = id ?? value
            if (!flowId) {
                return
            }
            const modalId = nanoid()
            openModal({
                id: modalId,
                component: DesktopModal,
                width: 1200,
                styles: {
                    header: { display: 'none' },
                    modal: {
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        height: '80%'
                    },
                    body: {
                        padding: 0,
                        flex: 1,
                        position: 'relative',
                        overflow: 'hidden'
                    }
                },
                withCloseIcon: false,
                children: (
                    <FlowDetail
                        id={flowId}
                        showBack={false}
                        showLogTab={false}
                        showClose
                        onClose={() => {
                            closeModal(modalId)
                            onClose?.()
                            onFetchWorkflows()
                        }}
                    />
                )
            })
        },
        [onFetchWorkflows, value]
    )

    const handleSubProcessCreate = useCallback(async () => {
        // window.open(workflowUrl, '_blank')
        const flowInitInfo = addNewTriggerNodeWithEdges('ARG_TRIGGER', {
            args: []
        })

        const { label } = workflowLabelMap['subProcess']

        const workflowId = await srv.createFlow({
            name: `未命名${label}`,
            type: 'subProcess',
            description: `未命名${label}的描述`,
            ...flowInitInfo
        })

        if (workflowId) {
            onChange?.(workflowId)
            await onFetchWorkflows()
            handleOpenFlowModal(workflowId)
        }
    }, [handleOpenFlowModal, onChange, onFetchWorkflows])

    return (
        <SCxSubProcessSelectorWrapper>
            <SC.FormItem>
                <SC.FormItemLabelWrapper>
                    <SC.FormItemLabel>选择子流程</SC.FormItemLabel>
                </SC.FormItemLabelWrapper>
                <SC.FormItemContent>
                    <Select
                        searchable
                        placeholder="请选择子流程"
                        value={value}
                        options={flowNodesOptions}
                        onChange={onChange}
                        emptyComponent={
                            <Empty
                                icon={<IconFont type="NoData" color="var(--color-gray-400)" size={20} />}
                                description="没有子流程"
                                styles={{ root: { height: 100, padding: '25px 0' } }}
                            />
                        }
                        dropdownProps={{
                            extra: (
                                <>
                                    <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                                    <SCxApprovalCreatorButton onClick={handleSubProcessCreate}>
                                        <IconFont type="Add" style={{ marginRight: 6, color: 'var(--color-gray-400)' }} />
                                        创建子流程
                                    </SCxApprovalCreatorButton>
                                </>
                            )
                        }}
                    />
                </SC.FormItemContent>
            </SC.FormItem>

            {flow && (
                <>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>子流程状态</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <Flex style={{ width: 180, flexShrink: 0 }} justifyContent="flex-end">
                            <Tag
                                radius={6}
                                background={flow.enabled ? 'var(--color-green-100)' : 'var(--color-red-100)'}
                                color={flow.enabled ? 'var(--color-green-500)' : 'var(--color-red-500)'}
                            >
                                {flow.enabled ? '已启用' : '未启用'}
                            </Tag>
                        </Flex>
                    </SC.FormItem>
                    <SCxApprovalFlowWrapper>
                        <SCxApprovalFlowContainer>
                            <SCxApprovalFlowContent>
                                {flow && flow?.content && (
                                    <ReactFlowProvider>
                                        <FlowEditor silent type={flow.type} nodes={flow.content.nodes} edges={flow.content.edges} />
                                    </ReactFlowProvider>
                                )}
                            </SCxApprovalFlowContent>
                        </SCxApprovalFlowContainer>
                        <SCxApprovalFlowEdit
                            size="lg"
                            block
                            icon={<IconFont type="PencilSimple" />}
                            onClick={() => handleOpenFlowModal(value)}
                        >
                            编辑子流程
                        </SCxApprovalFlowEdit>
                    </SCxApprovalFlowWrapper>
                </>
            )}

            {/* <Modal>
            <FlowDetail
                        id={flowId}
                        showBack={false}
                        showLogTab={false}
                        showClose
                        onClose={() => {
                            closeModal(modalId)
                            onClose?.()
                        }}
                    />
            </Modal> */}
        </SCxSubProcessSelectorWrapper>
    )
}
