import { clone } from "rambda";

import type { AppDepartment, AppDepartmentWidthChild } from "../../types";

export function getDepartmentTree(list: AppDepartment[]): AppDepartmentWidthChild[] {
    // 创建一个id到对象的映射
    const newList = clone(list)
    const map = new Map();
    const arr: AppDepartmentWidthChild[] = [];
    // 初始化映射
    newList.forEach(item => {
        map.set(item.id, item);
    });
    // 构建树
    list.forEach(item => {
        if (item.parentId?.[0]) {
            const parent = map.get(item.parentId[0]);
            if (parent) {
                if (parent.children) {
                    parent.children.push(map.get(item.id));
                }
                if (!parent.children) {
                    parent.children = [map.get(item.id)]
                }
            }
            return
        }
        arr.push(map.get(item.id));
    })

    return arr
}
