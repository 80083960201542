import { find } from 'rambda'

import type { FlowEdge, FlowNode, NodeTypes } from '../types'

export const getParentNodeIds = (currentNodeId: string, edges: FlowEdge[], parentNodes: string[]) => {
    const parents = edges.filter(item => item.target === currentNodeId)
    if (parents.length === 0) {
        return
    }
    parents.forEach(item => {
        if (parentNodes.includes(item.source)) {
            return
        }
        parentNodes.push(item.source)
        getParentNodeIds(item.source, edges, parentNodes)
    })

    return parentNodes
}

export const getAllParentNodes = (node: FlowNode, nodes: FlowNode[], edges: FlowEdge[]) => {
    const nodeIds = getParentNodeIds(node.id, edges, [])
    if (!nodeIds) {
        return []
    }
    return nodeIds.reverse().reduce<FlowNode[]>((prev, cur) => {
        const node = find(node => node.id === cur, nodes)
        if (node) {
            prev.push(node)
        }
        return prev
    }, [])
}

const canBeUpstreamNodeTypes = new Set<NodeTypes>([
    /** 动作流 */
    'CLICK_TRIGGER',
    /** 自动化 */
    'CREATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'ARG_TRIGGER',
    'FIND_RECORD_ACTION',
    'CREATE_RECORD_ACTION',
    /** 共有 */
    'CREATE_SINGLE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION',
    'FIND_SINGLE_RECORD_ACTION'
])
export const getAllCanBeUpstreamNodes = (allParentNodes: FlowNode[]) => {
    return allParentNodes.filter(node => {
        return canBeUpstreamNodeTypes.has(node.data.nodeType)
    })
}
