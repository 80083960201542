import type { ApplicationSettingAuthentication, ApplicationSettingAuthenticationCommon } from '@lighthouse/core'
import type { AppRole } from '@lighthouse/shared'
import { useApplicationContext } from '@lighthouse/shared'
import { reduce } from 'rambda'
import * as React from 'react'
import { useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import PerfectFieldCollapse from '../PerfectFieldCollapse'

interface PerfectGroupSettingProps {}

const PerfectGroupSetting: React.FunctionComponent<PerfectGroupSettingProps> = props => {
    const { control } = useFormContext<ApplicationSettingAuthentication>()

    const { fields: roleSetting, update } = useFieldArray({
        control,
        name: 'perfect.roleSetting'
    })

    return (
        <>
            {roleSetting.map(({ roleId }, index) => {
                return <PerfectFieldCollapse prefix={`perfect.roleSetting.${index}`} key={roleId} roleId={roleId ?? ''} />
            })}
        </>
    )
}

export default PerfectGroupSetting
