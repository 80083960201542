import { IconFont } from '@byecode/ui'
import type { SchemaProtocol, ViewField, ViewType } from '@lighthouse/core'
import { FieldTypeColorMap, FieldTypeTag, getFieldIcon, getIsAppointField, signFieldIconColor, signFieldId } from '@lighthouse/shared'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import type { ViewFieldSettingState } from '../ViewFieldSetting'

const IconBox = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    width: 32px;
    padding: 0 4px;
`
const SCxEditIcon = styled(IconFont)`
    font-size: 16px;
    display: none;
    margin-left: 8px;
    color: var(--color-gray-400);
`

const SCxViewFieldSettingItemWrapper = styled.div<{ isFieldHandle?: boolean }>`
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    /* height: 28px; */
    overflow: hidden;
    /* gap: 8px; */

    &:hover .field-visible {
        display: flex;
    }

    ${({ isFieldHandle }) =>
        isFieldHandle &&
        css`
            &:hover .field-tag {
                display: none;
            }
        `}

    &:hover {
        ${SCxEditIcon} {
            display: flex;
        }
    }
`

const SCxInput = styled.input`
    flex: 1;
    overflow: hidden;
    padding: 0 4px;
`

const SCxInfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    overflow: hidden;
`

const SCxDescribe = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    padding-left: 24px;
    width: fit-content;
`

const SCxViewFieldInfo = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
`

const SCxViewFieldTitle = styled.div`
    max-width: 100%;
    color: var(--color-gray-900);
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxIcon = styled(IconFont)`
    margin: 0 4px;
`

const SCxEye = styled(IconFont)<{ disable?: boolean }>`
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`

const PaddingBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4px;
    flex: 1;
    overflow: hidden;
`

const SCxViewFieldHandle = styled.div<{ width?: number }>`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
`

export interface ViewFieldSettingItemProps {
    viewType: ViewType
    config: ViewField
    index: number
    disabled?: boolean
    disableSwitch?: boolean
    isShowDsName?: boolean
    schema?: SchemaProtocol['schema']
    onChangeMode: (val: ViewFieldSettingState) => void
    onChange: (val: ViewField) => void
    onChangeSwitch: (val: ViewField) => void
    onRemove: (id: string) => void
}

export const ViewFieldSettingItem: React.FC<ViewFieldSettingItemProps> = ({
    viewType,
    index,
    config,
    disabled,
    schema,
    disableSwitch = false,
    isShowDsName,
    onChangeMode,
    onChange,
    onChangeSwitch,
    onRemove
}) => {
    const isSignField = getIsAppointField(config.fieldId, signFieldId)
    const icon = getFieldIcon(config.fieldId, config.type, config.innerType)
    const isFieldHandle = ['advancedTable', 'table', 'list', 'kanban', 'calendar'].includes(viewType)

    const [isEdit, setIsEdit] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const field = schema?.[config.fieldId]
    // const handleChangeMode = useCallback(() => {
    //     onChangeMode({
    //         mode: 'detail',
    //         index
    //     })
    // }, [index, onChangeMode])

    // const handleRemove = useCallback(
    //     (e: React.MouseEvent<HTMLDivElement>) => {
    //         e.stopPropagation()
    //         onRemove(config.fieldId)
    //     },
    //     [config.fieldId, onRemove]
    // )

    useEffect(() => {
        isEdit && inputRef.current?.select()
    },[isEdit])

    const handleChangeSwitch = useCallback(
        (e: React.MouseEvent<HTMLElement>, visible: boolean) => {
            e.stopPropagation()

            !disableSwitch &&
                onChangeSwitch({
                    ...config,
                    visible
                })
        },
        [config, disableSwitch, onChangeSwitch]
    )

    return (
        <>
            <SCxViewFieldSettingItemWrapper isFieldHandle={!disabled && isFieldHandle} /* onClick={handleChangeMode} */>
                <SCxInfoContainer>
                    <SCxViewFieldInfo>
                        <SCxIcon fill={isSignField ? signFieldIconColor : FieldTypeColorMap[config.type]} type={icon} size={16} />
                        {isEdit ? (
                            <SCxInput
                                ref={inputRef}
                                autoFocus

                                placeholder={field?.name}
                                defaultValue={config.title}
                                onBlur={e => {
                                    onChange({ ...config, title: e.target.value })
                                    setIsEdit(false)
                                }}
                                onKeyDown={e => {
                                    if (e.code === 'Enter') {
                                        inputRef.current?.blur()
                                        setIsEdit(false)
                                    }
                                }}
                            />
                        ) : (
                            <PaddingBox>
                                <SCxViewFieldTitle>
                                    {config.title || field?.name}
                                    {config.title && <span style={{ color: 'var(--color-gray-400)' }}> {`(${field?.name})`}</span>}
                                </SCxViewFieldTitle>
                                <SCxEditIcon type="NotePencil" onClick={() => setIsEdit(true)} />
                            </PaddingBox>
                        )}
                    </SCxViewFieldInfo>
                    {isShowDsName && <SCxDescribe>{config.dsName}</SCxDescribe>}
                </SCxInfoContainer>
                <SCxViewFieldHandle>
                    {!disabled && isFieldHandle && (
                        <IconBox className="field-visible">
                            {config.visible ? (
                                <SCxEye type="Hide" size={16} disable={disableSwitch} onClick={e => handleChangeSwitch(e, false)} />
                            ) : (
                                <IconFont type="Eye" size={16} onClick={e => handleChangeSwitch(e, true)} />
                            )}
                        </IconBox>
                    )}
                    <FieldTypeTag className="field-tag" type={config.type} innerType={config.innerType} />
                </SCxViewFieldHandle>
            </SCxViewFieldSettingItemWrapper>
        </>
    )
}
