import { IconFont } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import { dataDrawerStateAtom } from '@/atoms/application/state'

const SCxContent = styled.div`
    width: 100%;
    height: 40px;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
    border-top: 1px solid var(--color-gray-200);

    cursor: pointer;
`

const SCxTitle = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-600);
`

export const PageFooterToolBar: React.FC = () => {
    const { run: onVisibleDataDrawer } = useAtomAction(dataDrawerStateAtom)
    return (
        <SCxContent data-ignore-click-away onClick={() => onVisibleDataDrawer?.(true)}>
            <IconFont fill="var(--color-main)" type="SearchData2" size={20} />
            <SCxTitle>数据查看器</SCxTitle>
            <IconFont type="ArrowUpDouble" size={16} fill="var(--color-gray-400" />
        </SCxContent>
    )
}
