import { type ActionFlowNode, type WechatTemplateMsgConfig, generateAdtValue, generateVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleWechatTemplateMsg = async (node: ActionFlowNode<WechatTemplateMsgConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const { templateId, personList, values, url } = config

    const personListValues = personList.map(person => generateAdtValue('ARRAY', person, excParams))
    const userIds = [...new Set(personListValues)]
    const valuesValues = values.map(value => ({
        variableName: value.variableName,
        value: generateVariableValue({  innerType: 'TEXT', jsonContent: value.value, extraParams: excParams, isFormat: true }) as string
    }))
    const urlValue = generateVariableValue({  innerType: 'TEXT', jsonContent: url, extraParams: excParams }) as string

    await srv.wxTemplateMessageAction({
        userIds,
        templateId,
        url: urlValue,
        values: valuesValues
    })

    return {
        userIds,
        templateId,
        url: urlValue,
        values: valuesValues
    }
}
