import { IconFont } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationType } from '@lighthouse/core'
import { ApplicationSettingThemeNavBarMode, NavigationShowMode } from '@lighthouse/core'
import { domainReg1, domainReg2, getColors, getRandomColor, IconPicker, PRESET_PALETTES, ThemeColorPicker } from '@lighthouse/shared'
import { generateRandomString, getRandomIcon } from '@lighthouse/tools'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { RoundAvatar } from '@/components/RoundAvatar'
import { useAppMainDomain } from '@/hooks/useApplication'
import { useUser } from '@/hooks/useUser'
import * as srv from '@/services'
import type { CreateApplicationPayload } from '@/services/types'

import * as SC from '../styles'
import { Website } from '../Website'
import { AppStyle } from './AppStyle'
import { UsedMode, ValidateInput } from './ValidateInput'

export type CreateApplicationFormValue = Omit<CreateApplicationPayload, 'spaceId' | 'theme'> & {
    theme: {
        activeId: string
        colors: string[]
    }
}

type AppSetProps = {
    appType: ApplicationType
    onConfirm?: (value: Omit<CreateApplicationPayload, 'spaceId'>) => void
    onClose?: () => void
}

const AppSet: React.FunctionComponent<AppSetProps> = ({ appType, onClose, onConfirm }) => {
    const userData = useUser()
    const [usedMode, setUsedMode] = useState(UsedMode.notValidate)
    const [initActiveId, initColor] = getRandomColor()
    const mainDomain = useAppMainDomain()
    const initIcon = getRandomIcon()
    // generateRandomString
    const methods = useForm<CreateApplicationFormValue>({
        mode: 'onChange',
        defaultValues: {
            icon: initIcon,
            name: `${userData.username}的应用`,
            domainPrefix: '',
            theme: {
                activeId: initActiveId,
                colors: getColors(initColor)
            },
            themeMode: ApplicationSettingThemeNavBarMode.light,
            navigationShowMode: NavigationShowMode.horizontal
        }
    })

    const {
        control,
        watch,
        getValues,
        setValue,
        formState: { errors }
    } = methods
    const appIcon = watch('icon')
    const appName = watch('name')
    const appDomainPrefix = watch('domainPrefix')
    const activeId = watch('theme.activeId')
    const colors = watch('theme.colors')
    const mode = watch('themeMode')
    const navigationShowMode = watch('navigationShowMode')
    const activeColor = useMemo(() => {
        if (activeId === 'custom') {
            return colors[0]
        }
        if (activeId) {
            return PRESET_PALETTES[activeId]
        }
        return ''
    }, [activeId, colors])

    const handleConfirm = useCallback(() => {
        const values = getValues()
        const params = {
            ...values,
            theme: {
                palettes: [
                    {
                        id: values.theme.activeId,
                        label: '主题色',
                        value: activeColor
                    }
                ],
                textPresetList: [],
                primaryPointer: values.theme.activeId
            }
        }
        onConfirm?.(params)
    }, [activeColor, getValues, onConfirm])

    // const handleKeyDown = useCallback(
    //     (ev: React.KeyboardEvent) => {
    //         if (valueState && ev.key === 'Enter') {
    //             handleConfirm()
    //         }
    //     },
    //     [handleConfirm, valueState]
    // )
    // const a = navbarModeList
    // const b = navbarModes
    const checkDomainStartAndEnd = useCallback((val: string) => {
        if (!domainReg1.test(val)) {
            return '不能以连接符开头或结尾'
        }
        return true
    }, [])

    const checkDomainChars = useCallback((val: string) => {
        if (!domainReg2.test(val)) {
            return '使用英文字母（a-z）、数字（0-9）和连字符（-）'
        }
        return true
    }, [])

    const disableCreateApp = useMemo(
        () => usedMode !== UsedMode.used || !appName || !appDomainPrefix || !!errors?.domainPrefix,
        [appDomainPrefix, appName, errors?.domainPrefix, usedMode]
    )

    const webSiteTheme = useMemo(() => {
        if (mode === ApplicationSettingThemeNavBarMode.custom) {
            return {
                // background: activeColor,
                navBackgroundColor: activeColor,
                appName,
                appIcon,
                appDomainPrefix,
                iconColor: activeColor,
                color: 'var(--color-white)',
                layout: navigationShowMode,
                bandColor: 'var(--color-white)',
                bandOpacity: 0.4
            }
        }
        if (mode === ApplicationSettingThemeNavBarMode.dark) {
            return {
                // background: activeColor,
                navBackgroundColor: 'var(--color-black)',
                appName,
                appIcon,
                appDomainPrefix,
                iconColor: 'var(--color-black)',
                color: 'var(--color-white)',
                iconBackground: 'var(--color-white)',
                layout: navigationShowMode,
                bandColor: 'var(--color-white)',
                bandOpacity: 0.4
            }
        }
        {
            return {
                activeBandColor: activeColor,
                appName,
                appIcon,
                navBackgroundColor: 'var(--color-white)',
                iconBackground: activeColor,
                iconColor: 'var(--color-white)',
                appDomainPrefix,
                layout: navigationShowMode,
                bandColor: 'var(--color-gray-300)',
                bandOpacity: 1
            }
        }
    }, [activeColor, appDomainPrefix, appIcon, appName, mode, navigationShowMode])

    return (
        <SC.Container>
            <SC.Close onClick={onClose}>
                <IconFont type="Close" />
            </SC.Close>
            <FormProvider {...methods}>
                <SC.Form>
                    <SC.Title>创建应用</SC.Title>
                    <SC.FormContainer>
                        <SC.Group>
                            <SC.Label>标题</SC.Label>
                            <SC.InputWrapper>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => {
                                        return (
                                            <SC.Input
                                                styles={{
                                                    wrapper: {
                                                        width: '100%'
                                                    }
                                                }}
                                                size="lg"
                                                data-autofocus
                                                type="text"
                                                value={field.value}
                                                onChange={(event: { target: { value: string } }) => {
                                                    field.onChange(event.target.value.trim())
                                                }}
                                            />
                                        )
                                    }}
                                />
                                <SC.SuffixIconWrapper>
                                    <Controller
                                        control={control}
                                        name="icon"
                                        render={({ field }) => {
                                            return (
                                                <IconPicker
                                                    value={{ type: 'icon', value: field.value }}
                                                    // onChange={val => {}}
                                                    onChange={val => {
                                                        field.onChange(val?.value)
                                                    }}
                                                    targetComponent={
                                                        <RoundAvatar
                                                            iconSize={24}
                                                            size={36}
                                                            type="icon"
                                                            radius="8px"
                                                            bordered={false}
                                                            iconColor={activeColor ? 'var(--color-white)' : 'var(--color-gray-500)'}
                                                            icon={field.value}
                                                            background={activeColor || 'var(--color-white)'}
                                                            // background={theme?.colors?.[0]}
                                                        />
                                                    }
                                                />
                                            )
                                        }}
                                    />
                                </SC.SuffixIconWrapper>
                            </SC.InputWrapper>
                        </SC.Group>
                        <SC.Group>
                            <SC.Label>域名</SC.Label>
                            <Controller
                                control={control}
                                name="domainPrefix"
                                rules={{
                                    maxLength: {
                                        value: 32,
                                        message: '最长长度为32个字符'
                                    },
                                    minLength: {
                                        value: 8,
                                        message: '最短长度为8个字符'
                                    },
                                    validate: {
                                        checkDomainStartAndEnd,
                                        checkDomainChars
                                    }
                                }}
                                render={({ field }) => {
                                    return (
                                        <ValidateInput
                                            name="domainPrefix"
                                            styles={{
                                                wrapper: {
                                                    width: '100%',
                                                    paddingRight: 8
                                                }
                                            }}
                                            usedMode={usedMode}
                                            onUsedMode={setUsedMode}
                                            onCheck={srv.checkDomain}
                                            errors={errors}
                                            size="lg"
                                            data-autofocus
                                            type="text"
                                            suffix={<span style={{ color: 'var(--color-black)' }}>.{mainDomain}</span>}
                                            value={field.value}
                                            onChange={(event: { target: { value: string } }) => {
                                                field.onChange(event.target.value.trim())
                                            }}
                                        />
                                    )
                                }}
                            />
                        </SC.Group>
                        <SC.Group>
                            <SC.Label>选择主题</SC.Label>
                            <SC.ThemeColorWrapper>
                                <ThemeColorPicker
                                    size={18}
                                    withinPortal
                                    value={{
                                        color: colors?.[0],
                                        colorName: activeId
                                    }}
                                    onChange={val => {
                                        const { color, colorName } = val
                                        const colors = getColors(color)
                                        setValue('theme.colors', colors)
                                        setValue('theme.activeId', colorName)
                                    }}
                                />
                            </SC.ThemeColorWrapper>
                        </SC.Group>
                        <SC.Group>
                            <SC.Label>导航栏</SC.Label>
                            <Controller
                                name="navigationShowMode"
                                render={({ field }) => {
                                    return (
                                        <SC.Row>
                                            <SC.Card width={160} onClick={() => field.onChange(NavigationShowMode.horizontal)}>
                                                <SC.CardImgWrapper active={field.value === NavigationShowMode.horizontal}>
                                                    <SC.CardImg src={getAssetUrl('application', 'horizontal_layout.png')} />
                                                </SC.CardImgWrapper>
                                                <SC.CardText active={field.value === NavigationShowMode.horizontal}>水平</SC.CardText>
                                            </SC.Card>
                                            <SC.Card width={160} onClick={() => field.onChange(NavigationShowMode.verticalWide)}>
                                                <SC.CardImgWrapper active={field.value === NavigationShowMode.verticalWide}>
                                                    <SC.CardImg src={getAssetUrl('application', 'vertical_layout.png')} />
                                                </SC.CardImgWrapper>
                                                <SC.CardText active={field.value === NavigationShowMode.verticalWide}>垂直</SC.CardText>
                                            </SC.Card>
                                        </SC.Row>
                                    )
                                }}
                            />
                        </SC.Group>
                        <SC.Group>
                            <SC.Label>导航栏风格</SC.Label>

                            <Controller
                                control={control}
                                name="themeMode"
                                render={({ field }) => {
                                    return (
                                        <SC.Row>
                                            <SC.Card width={72} onClick={() => field.onChange(ApplicationSettingThemeNavBarMode.custom)}>
                                                <AppStyle
                                                    navigationShowMode={navigationShowMode}
                                                    navBarColor={activeColor}
                                                    active={field.value === ApplicationSettingThemeNavBarMode.custom}
                                                />
                                                <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.custom}>
                                                    主题色
                                                </SC.CardText>
                                            </SC.Card>
                                            <SC.Card width={72} onClick={() => field.onChange(ApplicationSettingThemeNavBarMode.dark)}>
                                                <AppStyle
                                                    navigationShowMode={navigationShowMode}
                                                    navBarColor="var(--color-black)"
                                                    active={field.value === ApplicationSettingThemeNavBarMode.dark}
                                                />
                                                <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.dark}>
                                                    深色
                                                </SC.CardText>
                                            </SC.Card>
                                            <SC.Card width={72} onClick={() => field.onChange(ApplicationSettingThemeNavBarMode.light)}>
                                                <AppStyle
                                                    navigationShowMode={navigationShowMode}
                                                    iconColor={activeColor}
                                                    navBarColor="var(--color-white)"
                                                    contentColor="var(--color-gray-100)"
                                                    boxShadow="-1px -1px 0.7px 0px #8A8CB924"
                                                    active={field.value === ApplicationSettingThemeNavBarMode.light}
                                                />
                                                <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.light}>
                                                    浅色
                                                </SC.CardText>
                                            </SC.Card>
                                        </SC.Row>
                                    )
                                }}
                            />
                        </SC.Group>
                    </SC.FormContainer>
                    <SC.CreateContain>
                        <SC.CreateButton size="lg" block type="primary" onClick={handleConfirm} disabled={disableCreateApp}>
                            创建应用
                        </SC.CreateButton>
                    </SC.CreateContain>
                </SC.Form>
                <SC.Layout>
                    <Website {...webSiteTheme} />
                </SC.Layout>
            </FormProvider>
        </SC.Container>
    )
}

export default AppSet
