import { Divider, Flex, Group, IconFont, Input, Popover, SegmentedControl, Text } from '@byecode/ui'
import type { Field, TextBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import {
    DATE_SYSTEM,
    FilledInput,
    FillPickerPopoverDropdown,
    getIsFindUseInRichText,
    getPageDataOptions,
    getSystemOption,
    getUserDatasourceOptions,
    ListItem4ByecodeUi,
    PopoverDropdownWithClose,
    RichTextEditor,
    ShadowController,
    useAtomData,
    useFindUseObjectContext,
    USER_DATASOURCE
} from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import { VisibilityFilter } from '../Common/VisibilityFilter'
import type { SettingsTabEnum } from '../constants'
import { SETTINGS_TAB_ENUM, SETTINGS_TABS } from '../constants'
import { FontConfigureFormFragment } from './FontConfigureFormFragment'

const Container = styled.div<{ highlighting?: boolean }>`
    margin: 8px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    .ProseMirror {
        min-height: 120px;
    }

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
            overflow: hidden;
        `}
`

const filterField = (field: Field) => !['file', 'video', 'notes'].includes(field.type)
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

export const TextBlockSetting = ({ blockId }: { blockId: string }) => {
    const { control, setValue, getValues } = useFormContext<
        Omit<TextBlockConfig, 'content'> & { content: TextBlockConfig['content']['value'] }
    >()
    const [border] = useWatch({
        control,
        name: ['border']
    })

    const [currentTab, setCurrentTab] = useState<SettingsTabEnum>(SETTINGS_TAB_ENUM.normal)

    const findUseObject = useFindUseObjectContext()
    const disabledWithVersion = useIsDisabledWithVersion()

    /** ********************************* 富文本变量 start ********************************** */
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const {
        prev,
        curr: { variableSourceType, datasource },
        dataSourceList
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])
    const systemOption = useMemo(() => getSystemOption(DATE_SYSTEM), [])
    const userOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: filterField
            }),
        [dataSourceList]
    )
    const { customViewOption } = useVariableCustomViewOption()

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            heading: false,
            bulletList: false,
            orderedList: false,
            taskList: false,
            fontSize: false,
            variable: {
                options,
                userOption,
                systemOption,
                viewOption: customViewOption,
                dataSources: dataSourceList
            },
            image: false
        }),
        [customViewOption, dataSourceList, options, systemOption, userOption]
    )
    /** ********************************* 富文本变量 end ********************************** */

    const hasBorderValue = useMemo(() => {
        if (!border) {
            return false
        }

        if (border.type === BACKGROUND_TYPE.color) {
            return !!border.color
        }

        if (border.type === BACKGROUND_TYPE.gradient) {
            return !!border.gradient?.stops
        }
    }, [border])

    const [draftBorderWidth, setDraftBorderWidth] = useState(border?.all?.toString() || '')
    const handleConfirmChangeBorderWidth = (value: string) => {
        const number = Number(value)
        const min = 0
        const max = 100
        if (Number.isNaN(number) || min > number || number > max) {
            setDraftBorderWidth(border?.all?.toString() || '')
            return
        }

        setDraftBorderWidth(number.toString())
        setValue('border.all', number)
    }

    const colorSystemMethods = useColorSystemAction()

    return (
        <>
            <Flex grow mt={12} px={16}>
                <SegmentedControl
                    data={SETTINGS_TABS}
                    fullWidth
                    size="lg"
                    value={currentTab}
                    onChange={v => setCurrentTab(v as SettingsTabEnum)}
                />
            </Flex>

            {currentTab === SETTINGS_TAB_ENUM.normal && (
                <>
                    <Group label="内容">
                        <Controller
                            control={control}
                            name="content"
                            render={({ field }) => {
                                const isHighLight = getIsFindUseInRichText({
                                    doc: field.value,
                                    findUseObject,
                                    currentDsId: datasource?.id
                                })
                                return (
                                    <Container highlighting={isHighLight}>
                                        <RichTextEditor
                                            disabled={disabledWithVersion}
                                            config={config}
                                            value={field.value}
                                            onChange={field.onChange}
                                            styles={{
                                                editorContent: {
                                                    backgroundColor: 'var(--color-gray-50)'
                                                }
                                            }}
                                        />
                                    </Container>
                                )
                            }}
                        />
                    </Group>
                    <Divider />

                    <FontConfigureFormFragment label="样式" />

                    <Divider />

                    <Group label="设计">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>填充</Text>
                            <Controller
                                control={control}
                                name="color"
                                render={({ field }) => {
                                    return (
                                        <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                            <Popover.Target>
                                                <FilledInput
                                                    style={{ width: 180 }}
                                                    value={field.value}
                                                    onClear={() => field.onChange({ type: BACKGROUND_TYPE.color })}
                                                />
                                            </Popover.Target>
                                            {/* <PopoverDropdownWithClose title="填充">
                                                <FillPicker
                                                    {...field}
                                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                                />
                                            </PopoverDropdownWithClose> */}
                                            <FillPickerPopoverDropdown
                                                title="填充"
                                                value={field.value}
                                                onChange={field.onChange}
                                                enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                                {...colorSystemMethods}
                                            />
                                        </Popover>
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>

                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>描边</Text>
                            <Controller
                                control={control}
                                name="border"
                                render={({ field }) => {
                                    const value = field.value && {
                                        type: field.value.type,
                                        color: field.value.color,
                                        gradient: field.value.gradient
                                    }
                                    return (
                                        <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                            <Popover.Target>
                                                <FilledInput
                                                    style={{ width: 180 }}
                                                    value={value}
                                                    onClear={() =>
                                                        field.onChange({ ...field.value, type: BACKGROUND_TYPE.color, color: '' })
                                                    }
                                                />
                                            </Popover.Target>
                                            {/* <PopoverDropdownWithClose title="描边">
                                                <FillPicker
                                                    value={
                                                        field.value
                                                            ? {
                                                                  type: field.value.type,
                                                                  color: field.value.color,
                                                                  gradient: field.value.gradient
                                                              }
                                                            : undefined
                                                    }
                                                    onChange={v => {
                                                        const initBorderWidth = 1
                                                        field.onChange({ all: initBorderWidth, ...field.value, ...v })
                                                        setDraftBorderWidth(initBorderWidth.toString())
                                                    }}
                                                />
                                            </PopoverDropdownWithClose> */}
                                            <FillPickerPopoverDropdown
                                                title="描边"
                                                value={
                                                    field.value
                                                        ? {
                                                              type: field.value.type,
                                                              color: field.value.color,
                                                              gradient: field.value.gradient
                                                          }
                                                        : undefined
                                                }
                                                onChange={v => {
                                                    const initBorderWidth = 1
                                                    field.onChange({ all: initBorderWidth, ...field.value, ...v })
                                                    setDraftBorderWidth(initBorderWidth.toString())
                                                }}
                                                enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                                {...colorSystemMethods}
                                            />
                                        </Popover>
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                        {hasBorderValue && (
                            <ListItem4ByecodeUi justifyContent="flex-end" alignItems="center">
                                <Input
                                    value={draftBorderWidth}
                                    onChange={e => setDraftBorderWidth(e.target.value)}
                                    onBlur={e => handleConfirmChangeBorderWidth(e.currentTarget.value)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            handleConfirmChangeBorderWidth(e.currentTarget.value)
                                        }
                                    }}
                                    prefix={<IconFont type="LineSize" />}
                                    placeholder=""
                                    styles={{ wrapper: { width: 180 } }}
                                />
                            </ListItem4ByecodeUi>
                        )}

                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>阴影</Text>
                            <Controller
                                control={control}
                                name="shadow"
                                render={({ field }) => {
                                    return (
                                        <Popover
                                            width={300}
                                            withinPortal
                                            position="left-start"
                                            autoUpdate={false}
                                            offsetOptions={168}
                                            onOpen={() => {
                                                const shadow = getValues('shadow.color')
                                                if (!shadow) {
                                                    setValue('shadow', {
                                                        color: '#00000040',
                                                        x: 0,
                                                        y: 4,
                                                        blur: 4,
                                                        diffusion: 4
                                                    })
                                                }
                                            }}
                                        >
                                            <Popover.Target>
                                                <FilledInput
                                                    style={{ width: 180 }}
                                                    value={{ type: BACKGROUND_TYPE.color, color: field.value?.color }}
                                                    onClear={() => field.onChange({})}
                                                />
                                            </Popover.Target>
                                            <PopoverDropdownWithClose title="阴影">
                                                <ShadowController {...field} {...colorSystemMethods} />
                                            </PopoverDropdownWithClose>
                                        </Popover>
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                    </Group>
                </>
            )}

            {currentTab === SETTINGS_TAB_ENUM.visibility && <VisibilityFilter />}
        </>
    )
}
