import type { ApplicationSetting } from '@lighthouse/core'
import { ApplicationPreviewEnum, useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { useCallback } from 'react'

import {
    appAtom,
    appEnvListAtom,
    applicationModePageHistoryAtom,
    applicationPrivateTemplateAtom,
    appListAtom,
    currentAppIdAtom,
    currentAppVersionIdAtom,
    currentEnvIdAtom,
    languageAtom,
    previewTypeAtom,
    websiteApplicationSettingAtom
} from '@/atoms/application/state'

export const useCurrentAppID = () => {
    return useAtomValue(currentAppIdAtom)
}

export const useCurrentAppVersionId = () => {
    return useAtomValue(currentAppVersionIdAtom)
}

export const useCurrentEnvId = () => {
    return useAtomValue(currentEnvIdAtom)
}

export const useApplicationPrivateTemplate = () => {
    return useAtomValue(applicationPrivateTemplateAtom)
}

export const useApplicationList = () => {
    return useAtomValue(appListAtom)
}

export const useApplicationModePageHistory = () => {
    return useAtomValue(applicationModePageHistoryAtom)
}

export const usePreviewType = () => {
    return useAtomValue(previewTypeAtom)
}

export const useApplication = () => {
    return useAtomValue(appAtom)
}

export const useAppLanguageList = () => {
    return useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.language.list ?? [], [])
    )
}

export const useLanguage = () => {
    return useAtomData(languageAtom)
}

export const useAppEnvList = () => {
    return useAtomData(appEnvListAtom)
}

export const useAppMainDomain = () => {
    return useAtomData(
        appAtom,
        useCallback(s => s.mainDomain, [])
    )
}
