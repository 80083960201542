import { nanoid } from '@lighthouse/tools'
import { mergeDeepRight } from 'rambda'

import type { PageAbstract } from '../abstracts'
import { PageOpenType, PageType } from '../abstracts'

export function PageStruct(initial: Partial<PageAbstract> = {}): PageAbstract {
    const DEFAULT_VALUE: PageAbstract = {
        type: PageType.default,
        id: nanoid(32),
        name: '新页面',
        icon: 'default',
        appId: '',
        dsId: '',
        open: PageOpenType.all,
        isHome: false,
        showFooter: false,
        roleIds: [],
        departmentIds: [],
        language: ''
    }
    return mergeDeepRight(DEFAULT_VALUE, initial)
}
