import { Button, Divider, IconFont, Popover, SelectDropdown } from '@byecode/ui'
import { Order } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'

export enum AppSortType {
    RECENTLY = 'RECENTLY',
    CREATE_TIME = 'CREATE_TIME',
    APP_NAME = 'APP_NAME'
}

interface AppSortProps {
    sort: string
    order: string
    onSortChange?: (val: string) => void
    onOrderChange?: (val: string) => void
}

export const AppSort: React.FC<AppSortProps> = ({ sort, order, onSortChange, onOrderChange }) => {
    const sortOptions = useMemo(
        () => [
            {
                label: '最近查看',
                value: AppSortType.RECENTLY,
                extra: sort === AppSortType.RECENTLY && <IconFont fill="var(--color-main)" type="Tick" />
            },
            {
                label: '创建时间',
                value: AppSortType.CREATE_TIME,
                extra: sort === AppSortType.CREATE_TIME && <IconFont fill="var(--color-main)" type="Tick" />
            },
            {
                label: '应用名称',
                value: AppSortType.APP_NAME,
                extra: sort === AppSortType.APP_NAME && <IconFont fill="var(--color-main)" type="Tick" />
            }
        ],
        [sort]
    )

    const orderOptions = useMemo(
        () => [
            {
                label: '升序',
                value: Order.ASC,
                extra: order === Order.ASC && <IconFont fill="var(--color-main)" type="Tick" />
            },
            {
                label: '降序',
                value: Order.DESC,
                extra: order === Order.DESC && <IconFont fill="var(--color-main)" type="Tick" />
            }
        ],
        [order]
    )

    const sortLabel = useMemo(() => find(option => option.value === sort, sortOptions)?.label, [sortOptions, sort])

    const orderLabel = useMemo(() => find(option => option.value === order, orderOptions)?.label, [orderOptions, order])

    return (
        <Popover width={160}>
            <Popover.Target>
                <Button type="text" suffix={<IconFont size={16} fill="var(--color-gray-400)" type="ArrowDownSmall" />}>
                    {sortLabel} {orderLabel}
                </Button>
            </Popover.Target>
            <Popover.Dropdown>
                <SelectDropdown options={sortOptions} onSelect={onSortChange} />
                <Divider my={8} />
                <SelectDropdown options={orderOptions} onSelect={onOrderChange} />
            </Popover.Dropdown>
        </Popover>
    )
}
