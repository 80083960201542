import { Modal, Toast, Tooltip } from '@byecode/ui'
import type { ApplicationAbstract } from '@lighthouse/core'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import type { AppendParams, ImportParams, SynchronizeParams } from '@lighthouse/shared'
import { useAtomAction, useDataSourceContext } from '@lighthouse/shared'
import { getDefaultStore, useAtomValue } from 'jotai'
import { find, isEmpty } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useUpdateEffect } from 'react-use'

import { linkApplicationAtom, unLinkApplicationAtom } from '@/atoms/application/action'
import {
    createAggregationDataSourceAtom,
    createAsyncDataSourceAtom,
    createDataSourceAtom,
    deleteDataSourceAtom,
    duplicateDataSourceAtom,
    fetchDataSourceAtom,
    fetchDataSourceDepartmentAtom,
    fetchDataSourceListAtom,
    fetchDataSourceRoleAtom,
    fetchDataSourceUserAtom,
    fetchEnvAtom,
    importDataSourceAtom,
    moveDataSourceAtom,
    updateDataSourceAtom
} from '@/atoms/dataSource/action'
import { dataSourceEnvAtom } from '@/atoms/dataSource/state'
import { filterCacheAtomFamily, sortsCacheAtomFamily } from '@/atoms/storage/state'
import { useApplicationList, useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import DataSourceAdder from '../DataSourceAdder'
import { Env } from './Env'
import { LinkApplication } from './LinkApplication'
import { List } from './List'
import * as SC from './styles'

export type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface DataSourceListProps {
    activeId?: string
    // onUpdateDataSource?: (dsId: string, data: Partial<DataSourceAbstract>) => Promise<boolean>
    onSelectDataSource?: (id: string) => Promise<void> | void
    onNavigateToEr?: () => void
    onMoveDataSource?: (dragId: string) => Promise<void> | void
    onDataAppended?: (params: AppendParams) => Promise<void>
}

const getDatasourceGroup = function (dataSourceList: DataSourceAbstract[]) {
    return dataSourceList.reduce<
        [DataSourceAbstract[], DataSourceAbstract[], DataSourceAbstract[], DataSourceAbstract[], DataSourceAbstract[]]
    >(
        (prev, item) => {
            if (item.type === DataSourceType.systemDataSource) {
                prev[0].push(item)
                return prev
            }
            if (item.type === DataSourceType.joinDataSource) {
                prev[1].push(item)
                return prev
            }
            if (item.type === DataSourceType.aggregateDataSource) {
                prev[2].push(item)
                return prev
            }
            if (item.sync) {
                prev[3].push(item)
                return prev
            }
            prev[4].push(item)
            return prev
            // item.type === 5 ? [[...prev[0], item], prev[1]] : [prev[0], [...prev[1], item]]
        },
        [[], [], [], [], []]
    )
}

const getChildrenDataSource = (ordinaryDataSource: DataSourceAbstract, joinDataSource: DataSourceAbstract[]) => {
    const { id } = ordinaryDataSource
    return joinDataSource.reduce<DataSourceAbstract[]>((prev, cur) => {
        if (cur.viewOptions.joinConfig?.primaryDsId === id) {
            prev.push(cur)
        }
        return prev
    }, [])
}

const getDataSourceList = (dataSourceList: DataSourceAbstract[]) => {
    const [systemDataSources, joinDataSources, aggregateDataSources, syncDataSources, ordinaryDataSources] =
        getDatasourceGroup(dataSourceList)
    return [
        systemDataSources.map(item => ({
            ...item,
            children: getChildrenDataSource(item, joinDataSources)
        })),
        ordinaryDataSources.map(item => ({
            ...item,
            children: getChildrenDataSource(item, joinDataSources)
        })),
        aggregateDataSources,
        syncDataSources.map(item => ({
            ...item,
            children: getChildrenDataSource(item, joinDataSources)
        }))
    ]
}
// type DataSourceDataList = Pick<DataSourceAbstract, 'id' | 'name' | 'type'>[]

const DataSourceList: React.FC<DataSourceListProps> = ({ activeId, onNavigateToEr, onSelectDataSource, onDataAppended }) => {
    const store = getDefaultStore()
    const [open, setOpen] = useState(false)
    const applicationList = useApplicationList()
    const navigate = useNavigate()
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dsList = useDataSourceList(appId, envId)
    const dataSourceEnv = useAtomValue(dataSourceEnvAtom)
    const { onVisibleJoinConfigureChange } = useDataSourceContext()
    const [searchVal, setSearch] = useState('')
    const { run: fetchDataSource } = useAtomAction(fetchDataSourceAtom)
    const { run: createDataSource } = useAtomAction(createDataSourceAtom)
    const { run: duplicateDataSource } = useAtomAction(duplicateDataSourceAtom)
    const { run: createAsyncDataSource } = useAtomAction(createAsyncDataSourceAtom)
    const { run: createAggregationDataSource } = useAtomAction(createAggregationDataSourceAtom)
    const { run: importDataSource } = useAtomAction(importDataSourceAtom)
    const { run: fetchDataSourceList } = useAtomAction(fetchDataSourceListAtom)
    const { run: deleteDataSource } = useAtomAction(deleteDataSourceAtom)
    const { run: moveDataSource } = useAtomAction(moveDataSourceAtom)
    const { run: updateDataSource } = useAtomAction(updateDataSourceAtom)
    const { run: unLinkApplication } = useAtomAction(unLinkApplicationAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceRole } = useAtomAction(fetchDataSourceRoleAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    const { run: linkApplication } = useAtomAction(linkApplicationAtom)
    const listRef = useRef<HTMLDivElement>(null)
    // const application = useMemo(() => find(item => item.id === appId, applicationList), [appId, applicationList])
    const dataSourceList = useMemo(() => {
        return dsList.filter(item => item.name.includes(searchVal))
    }, [dsList, searchVal])

    // const [joinDataSource, ordinaryDataSources] = useMemo(() => getDatasourceGroup(dataSourceList), [dataSourceList])

    const [systemDataSources, listData, aggregateDataSources, syncDataSources] = getDataSourceList(dataSourceList)

    useUpdateEffect(() => {
        fetchDataSourceList({ appId, envId })
    }, [envId])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const reloadUserCenter = useCallback(async () => {
        fetchEnv()
        await Promise.all([fetchDataSourceDepartment(), fetchDataSourceRole()])
        fetchDataSourceUser()
    }, [fetchDataSourceDepartment, fetchDataSourceRole, fetchDataSourceUser, fetchEnv])

    const handleCancelLink = useCallback(
        async (app: ApplicationAbstract | undefined) => {
            if (!app) {
                return
            }
            const { name } = app
            const isConfirm = await Modal.confirm({
                title: '确认取消共享？',
                content: `取消后，当前应用将恢复原来的用户信息。“${name}”应用的用户无法免登录进入当前应用。`,
                okStatus: 'error',
                okText: '取消共享'
            })
            if (isConfirm) {
                await unLinkApplication(envId)
                await reloadUserCenter()
            }
        },
        [envId, reloadUserCenter, unLinkApplication]
    )

    const linkContent = useMemo(() => {
        if (!dataSourceEnv) {
            return null
        }
        const { link, linked } = dataSourceEnv

        const isLink = !!link?.appId
        const isLinked = linked && !isEmpty(linked)

        if (isLink) {
            const linkApplication = find(app => app.id === link.appId, applicationList || [])
            const toolTipLabel = (
                <SC.LinkInfo>共享其他应用的用户：已开启, “{linkApplication?.name || '找不到该应用'}” 的用户可免登录进入当前应用</SC.LinkInfo>
            )
            return (
                <Tooltip width={198} title={toolTipLabel}>
                    <SC.Link
                        type="primary"
                        radius={100}
                        size="xs"
                        icon={<SC.LinkIcon type="TransferData" size={12} fill="var(--color-white)" />}
                        onClick={() => handleCancelLink(linkApplication)}
                    >
                        共享
                    </SC.Link>
                </Tooltip>
            )
        }
        if (isLinked) {
            const toolTipLabel = (
                <>
                    <SC.LinkInfo>该应用的用户信息已与其他应用共享, 该应用用户可免登录进入以下应用：</SC.LinkInfo>
                    {linked.map(item => {
                        const linkApplication = find(app => app.id === item.appId, applicationList || [])
                        if (!linkApplication) {
                            return null
                        }
                        return <SC.LinkInfo key={item.appId}>- {linkApplication.name}</SC.LinkInfo>
                    })}
                </>
            )
            return (
                <Tooltip width={198} title={toolTipLabel}>
                    <SC.Link
                        disabled
                        type="default"
                        radius={100}
                        style={{
                            border: 'none',
                            background: 'var(--color-gray-100)',
                            color:"var(--color-gray-400)"
                        }}
                        size="xs"
                        icon={<SC.LinkIcon type="TransferData" size={12} fill="var(--color-gray-400)" />}
                    >
                        {linked.length}个共享应用
                    </SC.Link>
                </Tooltip>
            )
        }

        return (
            <Tooltip width={198} title="共享其他应用的用户：未开启，开启后其他应用的用户可免登录进入当前应用">
                <SC.Link
                    type="tag"
                    radius={100}
                    size="xs"
                    style={{
                        background: 'var(--color-gray-100)',
                    }}
                    onClick={handleOpen}
                    icon={<SC.LinkIcon type="TransferData" size={12} fill="var(--color-gray-400)" />}
                >
                    共享
                </SC.Link>
            </Tooltip>
        )
    }, [applicationList, dataSourceEnv, handleCancelLink, handleOpen])

    const handleSelectDataSource = useCallback(
        (id: string) => {
            onSelectDataSource?.(id)
            onVisibleJoinConfigureChange?.(false)
        },
        [onSelectDataSource, onVisibleJoinConfigureChange]
    )

    const handleUpdateDataSource = useCallback(
        (id: string, value: string) =>
            updateDataSource({
                envId,
                dsId: id,
                params: { name: value }
            }),
        [envId, updateDataSource]
    )

    const handleDeleteDataSource = useCallback(
        async (dsId: string, name: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除数据源「${name ?? '未命名数据源'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteDataSource({
                    envId,
                    dsId
                })
                const newDataSourceList = await fetchDataSourceList({ appId, envId })

                // closePageOverlay(modalId)

                if (isDelete && dsId === activeId) {
                    const newList = newDataSourceList.filter(item => item.id !== dsId)
                    // const [systemDataSource, ordinaryDataSources] = getDatasourceGroup(newList)
                    if (newList.length > 0) {
                        const ds = newList[0]
                        handleSelectDataSource?.(ds.id)
                        return isDelete
                    }
                    handleSelectDataSource?.('')
                }
                return !!isDelete
            }

            return false
        },
        [activeId, appId, deleteDataSource, envId, fetchDataSourceList, handleSelectDataSource]
    )

    const handleSwitchSyncDs = useCallback(
        async (envId: string, dsId: string, switchEnvId: string) => {
            const isSwitch = await srv.switchSyncDs({
                envId,
                dsId,
                switchEnvId
            })
            if (isSwitch) {
                const filter = store.get(filterCacheAtomFamily({ appId, envId, id: dsId }))
                const sorts = store.get(sortsCacheAtomFamily({ appId, envId, id: dsId }))
                fetchDataSource({
                    envId,
                    dsId,
                    pagination: { currentPage: 1, pageSize: 100 },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
        },
        [appId, fetchDataSource, searchVal, store]
    )

    const handleCreateDataSource = useCallback(
        async (type: number) => {
            const { dsId } = await createDataSource({
                appId,
                envId,
                type
            })
            onVisibleJoinConfigureChange?.(false)
            if (dsId) {
                onSelectDataSource?.(dsId)
            }
            return !!dsId
        },
        [createDataSource, appId, envId, onVisibleJoinConfigureChange, onSelectDataSource]
    )

    const handleDuplicateDataSource = useCallback(
        async (dsId: string) => {
            const newDataSourceId = await duplicateDataSource({
                envId,
                dsId
            })
            if (newDataSourceId) {
                onSelectDataSource?.(newDataSourceId)
            }
            return !!newDataSourceId
        },
        [duplicateDataSource, envId, onSelectDataSource]
    )

    const handleAsyncSynchronize = useCallback(
        async (params: SynchronizeParams) => {
            const { appId: syncAppId, envId: syncEnvId, dsId: syncDsId } = params
            // const newDataSourceId = nanoid(32)
            const data = await createAsyncDataSource({
                appId,
                envId,
                syncEnvId,
                syncAppId,
                syncDsId
            })
            const { dsId: newDataSourceId } = data
            onVisibleJoinConfigureChange?.(false)
            if (data) {
                onSelectDataSource?.(newDataSourceId)
            }
            return !!newDataSourceId
        },
        [appId, createAsyncDataSource, envId, onSelectDataSource, onVisibleJoinConfigureChange]
    )

    const handleCreateAggregationDataSource = useCallback(async () => {
        const data = await createAggregationDataSource({
            envId
        })
        onVisibleJoinConfigureChange?.(false)
        if (data) {
            onSelectDataSource?.(data.dsId)
        }
        navigate({ pathname: `/${appId}/aggregate/${data.dsId}` })

        return !!data
    }, [appId, createAggregationDataSource, envId, navigate, onSelectDataSource, onVisibleJoinConfigureChange])

    const handleImportDataSource = useCallback(
        async (params: ImportParams) => {
            const dsIds = await importDataSource(params)
            if (dsIds?.length) {
                Toast.success('导入成功')
                const [dsId] = dsIds
                onSelectDataSource?.(dsId)
            }
            return !!dsIds?.length
        },
        [importDataSource, onSelectDataSource]
    )

    const handleLinkApplication = useCallback(
        async (linkAppId: string, linkEnvId: string) => {
            await linkApplication({ linkAppId, envId, linkEnvId })
            await reloadUserCenter()
        },
        [envId, linkApplication, reloadUserCenter]
    )

    const handleAppendDataSource = useCallback((params: AppendParams) => onDataAppended?.(params), [onDataAppended])

    return (
        <SC.Container>
            <SC.Content>
                <SC.SearchWrapper>
                    <SC.Search
                        prefix={<SC.SearchIcon type="Search" color="var(--color-gray-400)" />}
                        value={searchVal}
                        placeholder="搜索"
                        size="md"
                        onChange={e => setSearch(e.currentTarget.value)}
                        styles={{
                            wrapper: {
                                borderRadius: 5
                            }
                        }}
                    />
                    <DataSourceAdder
                        onCreate={handleCreateDataSource}
                        onImport={handleImportDataSource}
                        onAppend={handleAppendDataSource}
                        onAsyncSynchronize={handleAsyncSynchronize}
                        onCreateAggregationDataSource={handleCreateAggregationDataSource}
                    />
                </SC.SearchWrapper>
                <SC.ListWrapper ref={listRef}>
                    <SC.Header>
                        <SC.GroupName>数据表</SC.GroupName>
                    </SC.Header>
                    <List
                        appId={appId}
                        envId={envId}
                        allDataSource={dataSourceList}
                        dataSourceList={listData}
                        applicationList={applicationList}
                        activeId={activeId}
                        onMoveDataSource={moveDataSource}
                        onSelectDataSource={handleSelectDataSource}
                        onUpdateDataSource={handleUpdateDataSource}
                        onDuplicateDataSource={handleDuplicateDataSource}
                        onDeleteDataSource={handleDeleteDataSource}
                    />
                    {aggregateDataSources.length > 0 && (
                        <>
                            <SC.Header>
                                <SC.GroupName>聚合表</SC.GroupName>
                            </SC.Header>
                            <List
                                appId={appId}
                                envId={envId}
                                allDataSource={dataSourceList}
                                dataSourceList={aggregateDataSources}
                                applicationList={applicationList}
                                activeId={activeId}
                                onMoveDataSource={moveDataSource}
                                onSelectDataSource={handleSelectDataSource}
                                onUpdateDataSource={handleUpdateDataSource}
                                onDuplicateDataSource={handleDuplicateDataSource}
                                onDeleteDataSource={handleDeleteDataSource}
                            />
                        </>
                    )}
                    {syncDataSources.length > 0 && (
                        <>
                            <SC.Header>
                                <SC.GroupName>从其他应用同步的表</SC.GroupName>
                            </SC.Header>
                            <List
                                appId={appId}
                                envId={envId}
                                allDataSource={dataSourceList}
                                dataSourceList={syncDataSources}
                                applicationList={applicationList}
                                activeId={activeId}
                                onMoveDataSource={moveDataSource}
                                onSelectDataSource={handleSelectDataSource}
                                onUpdateDataSource={handleUpdateDataSource}
                                onDuplicateDataSource={handleDuplicateDataSource}
                                onDeleteDataSource={handleDeleteDataSource}
                                onSwitchSyncDs={handleSwitchSyncDs}
                            />
                        </>
                    )}

                    <SC.Header>
                        <SC.GroupName>用户中心 </SC.GroupName>
                        {linkContent}
                    </SC.Header>
                    <List
                        appId={appId}
                        envId={envId}
                        allDataSource={dataSourceList}
                        dataSourceList={systemDataSources}
                        applicationList={applicationList}
                        activeId={activeId}
                        onMoveDataSource={moveDataSource}
                        onSelectDataSource={handleSelectDataSource}
                        onUpdateDataSource={handleUpdateDataSource}
                        onDuplicateDataSource={handleDuplicateDataSource}
                        onDeleteDataSource={handleDeleteDataSource}
                        onSwitchSyncDs={handleSwitchSyncDs}
                    />
                    <SC.Space />
                </SC.ListWrapper>
            </SC.Content>
            <Env />
            {open && <LinkApplication open={open} appId={appId} onLinkApplication={handleLinkApplication} onClose={handleClose} />}
        </SC.Container>
    )
}

export default DataSourceList
