import { CHART_BLOCK_NAME_MAP } from '@lighthouse/block'
import type {
    ApplicationAbstract,
    BlockAbstract,
    ChartType,
    DataSourceAbstract,
    Direction,
    ViewType
} from '@lighthouse/core'
import {
    type FieldInputType,
    BlockType,
    DIRECTION} from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { FieldInputNameMap } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

import { DEFAULT_CONTAINER_DESIGN } from '@/components/BlockSettings/constants'

import { generateButtonBlock } from './generate/button'
import { generateCardBlock } from './generate/card'
import { generateChartBlock } from './generate/chart'
import { generateContainerBlock } from './generate/container'
import { generateFieldBlock } from './generate/field'
import { generateFilterBlock } from './generate/filter'
import { generateFormContainerBlock } from './generate/formContainer'
import { generateImageBlock } from './generate/image'
import { generateQrBarcodeBlock } from './generate/qrBarcode'
import { generateTabsBlock } from './generate/tabs'
import { generateTextBlock } from './generate/text'
import { generateVideoBlock } from './generate/video'
import { generateViewBlock } from './generate/view'
import { COMMON_BLOCK_CONFIG } from './sharedStyle'
import type { DefaultBlockType } from './type'

type GetInitBlockParams =
    | {
          type: Exclude<BlockType, 'container' | 'field' | 'view' | 'chart'> | DefaultBlockType
      }
    | {
          type: 'container'
          subType: Direction
      }
    | {
          type: 'field'
          subType: FieldInputType
      }
    | {
          type: 'view'
          subType: ViewType
      }
    | {
          type: 'chart'
          subType: ChartType
      }

export type BlockInitConfigOption = {
    appId?: string
    dataSource?: DataSourceAbstract
    appList?: ApplicationAbstract[]
    containerOptions?: {
        direction?: Direction
    }
}

/** 获取block初始配置 */
export function getBlockInitConfig(item: GetInitBlockParams, option?: BlockInitConfigOption): BlockAbstract | undefined {
    const { appId, appList, dataSource, containerOptions: { direction = DIRECTION.horizontal } = {} } = option ?? {}
    const id = `${item.type}-${nanoid(12)}`

    switch (item.type) {
        case BlockType.appNav: {
            return {
                id,
                type: item.type,
                title: '应用导航',
                config: {
                    list: appList?.map(item => item.id) || [],
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.breadcrumb: {
            return {
                id,
                type: item.type,
                title: '面包屑',
                config: {
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.buttonGroup: {
            return {
                id,
                type: item.type,
                title: '按钮',
                config: {
                    ...generateButtonBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.card: {
            return {
                id,
                type: item.type,
                title: '图文导航',
                config: {
                    ...generateCardBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.chart: {
            return {
                id,
                type: item.type,
                title: CHART_BLOCK_NAME_MAP[item.subType],
                config: { ...generateChartBlock(item.subType), ...COMMON_BLOCK_CONFIG }
            }
        }

        case BlockType.collapse: {
            return {
                id,
                type: item.type,
                title: '折叠面板',
                config: {
                    list: [],
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.container: {
            return {
                id,
                type: item.type,
                title: '容器',
                config: {
                    ...generateContainerBlock(direction),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.divider: {
            return {
                id,
                type: item.type,
                title: '分割线',
                config: {
                    visible: true,
                    height: 3.75,
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.field: {
            return {
                id,
                type: item.type,
                title: FieldInputNameMap[item.subType],
                config: { ...generateFieldBlock(item.subType), ...COMMON_BLOCK_CONFIG }
            }
        }

        case BlockType.fieldGroup: {
            return {
                id,
                type: item.type,
                title: '字段组',
                config: {
                    viewFields: [],
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.file: {
            return {
                id,
                type: item.type,
                title: '文件预览',
                config: {
                    previewMode: 'preview',
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.formContainer: {
            return {
                id,
                type: item.type,
                title: '表单容器',
                config: {
                    ...generateFormContainerBlock(dataSource),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.iframe: {
            return {
                id,
                type: item.type,
                title: '嵌入网页',
                config: {
                    source: '',
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.image: {
            return {
                id,
                type: item.type,
                title: '图片',
                config: {
                    ...generateImageBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.tabs: {
            return {
                id,
                type: item.type,
                title: '标签栏',
                config: {
                    ...generateTabsBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }
        case BlockType.filter: {
            return {
                id,
                type: item.type,
                title: '筛选器',
                config: {
                    ...generateFilterBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }
        case BlockType.text: {
            return {
                id,
                type: item.type,
                title: '文本',
                config: {
                    ...generateTextBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }
        case BlockType.video: {
            return {
                id,
                type: item.type,
                title: '视频',
                config: {
                    ...generateVideoBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }
        case BlockType.view: {
            return generateViewBlock({ viewType: item.subType, id, appId, dataSource })
        }
        case BlockType.qrBarcode: {
            return {
                id,
                type: item.type,
                title: '条码',
                config: {
                    ...generateQrBarcodeBlock(),
                    ...COMMON_BLOCK_CONFIG
                }
            }
        }

        case BlockType.subForm: {
            return {
                id,
                type: item.type,
                title: '子表单',
                config: {
                    ...COMMON_BLOCK_CONFIG,
                    columns: []
                }
            }
        }

        default: {
            break
        }
    }
}

/** 获取栅格节点初始配置 */
export function getNodeInitConfig(block: BlockAbstract): FlowLayoutNode | undefined {
    switch (block.type) {
        case BlockType.appNav:
        case BlockType.field:
        case BlockType.fieldGroup:
        case BlockType.text: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                minWidth: 2
            }
        }
        case BlockType.breadcrumb:
        case BlockType.buttonGroup:
        case BlockType.collapse:
        case BlockType.filter:
        case BlockType.subForm:
        case BlockType.tabs: {
            return {
                type: 'block',
                id: block.id,
                width: 12
            }
        }

        case BlockType.card: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                minWidth: 4
            }
        }
        case BlockType.chart: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                minWidth: 3,
                minHeight: 1
            }
        }

        case BlockType.container: {
            return {
                type: 'container',
                id: block.id,
                data: { ...DEFAULT_CONTAINER_DESIGN },
                width: 12
            }
        }
        case BlockType.divider: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                // height: 1,
                maxHeight: 6
            }
        }
        case BlockType.file: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                height: 6
            }
        }
        case BlockType.formContainer: {
            return {
                type: 'container',
                id: block.id,
                data: { ...DEFAULT_CONTAINER_DESIGN, direction: DIRECTION.vertical },
                width: 12
            }
        }
        case BlockType.iframe: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                height: 14,
                minWidth: 2
            }
        }
        case BlockType.image:
        case BlockType.video: {
            return {
                type: 'block',
                id: block.id,
                width: 12,
                initialHeight: 6
            }
        }
        case BlockType.view: {
            if (block.config.viewType === 'custom') {
                return {
                    type: 'custom',
                    id: block.id,
                    width: 12,
                    minWidth: 4,
                    data: { ...DEFAULT_CONTAINER_DESIGN, direction: DIRECTION.vertical }
                }
            }
            return {
                type: 'block',
                id: block.id,
                width: 12,
                minWidth: 4
            }
        }
        case BlockType.qrBarcode: {
            return {
                type: 'block',
                id: block.id,
                width: 3,
                minWidth: 1
            }
        }

        default: {
            break
        }
    }
}
