import { Button } from '@byecode/ui/components/Button'
import type { SpaceVersion } from '@lighthouse/core'
import { getBrandInfo, spaceGradeCodeMap, spaceGradeNameMap, useAtomData } from '@lighthouse/shared'
import { getFileSizeAccordanceBaseUnit } from '@lighthouse/tools'
import { Anchor, Flex, Text } from '@mantine/core'
import { find } from 'rambda'
import React from 'react'
import useSWR from 'swr'

import { currentAppOfSpaceIdAtom } from '@/atoms/application/state'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import * as srv from '@/services'
import { useSpaceQuota } from '@/shared/reusable'

import { ALL_SPACE_VERSION } from '../constants'
import { openSaleSpaceGrade } from '../event'
import * as SC from './List.style'
import { formatSpaceFeaturesValue } from './utils'

interface Props {
    onWechatPaySpaceUpgrade: (level: SpaceVersion) => void
}

/** 升级空间版本 */
export const List: React.FC<Props> = ({ onWechatPaySpaceUpgrade }) => {
    const appOfSpaceId = useAtomData(currentAppOfSpaceIdAtom)
    const spaceId = useCurrentSpaceID()
    const currentSpaceId = spaceId || appOfSpaceId
    const { data: spaceQuota } = useSpaceQuota()

    const { data } = useSWR(
        `/space/getSpacePlans-${currentSpaceId}`,
        () => {
            if (!currentSpaceId) {
                return
            }
            return srv.getSpacePlans(currentSpaceId)
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )

    return (
        <>
            <SC.Title>升级你的版本</SC.Title>
            <SC.Describe>
                如有任何问题，请
                <Anchor target="_target" onClick={openSaleSpaceGrade}>
                    咨询工作人员
                </Anchor>
                ，服务时段（周一至周日 9:00~20:00）
            </SC.Describe>
            <SC.BlockContainer>
                {ALL_SPACE_VERSION.map((item, index) => {
                    const plan = find(p => p.spacePlan === item.version, data || [])
                    const isPromo = !!plan && plan.originalPrice !== plan.realPrice
                    return (
                        <SC.Block key={item.version}>
                            <SC.BlockWrapper data-space-version={item.version}>
                                {/* {spaceThemeMap[item.version].image && <SC.VersionImage src={spaceThemeMap[item.version].image} />} */}
                                <SC.BlockContent>
                                    <Flex gap={8} align="center">
                                        <SC.BlockTitle>{spaceGradeNameMap[item.version]}</SC.BlockTitle>
                                        {spaceGradeCodeMap[item.version] === spaceQuota?.currentVersionCode && (
                                            <SC.BlockCurrentTag>当前版本</SC.BlockCurrentTag>
                                        )}
                                    </Flex>
                                    <SC.BlockDescribe>{item.describe}</SC.BlockDescribe>
                                    <SC.BlockPrice>
                                        <Flex align="flex-end">
                                            <Text size={20} color="var(--color-theme-7)">
                                                ￥
                                            </Text>
                                            <Text size={40} weight={500} style={{ lineHeight: 1 }} color="var(--color-theme-7)">
                                                {isPromo
                                                    ? formatSpaceFeaturesValue(plan.realPrice).value
                                                    : formatSpaceFeaturesValue(item.price).value}
                                            </Text>
                                        </Flex>
                                        {isPromo ? (
                                            <Text
                                                size={12}
                                                lineClamp={1}
                                                style={isPromo ? { textDecoration: 'line-through' } : {}}
                                                color="var(--color-gray-400)"
                                            >
                                                {plan?.originalPrice}/每年
                                            </Text>
                                        ) : (
                                            <Text size={12} lineClamp={1} color="var(--color-gray-400)">
                                                /每年
                                            </Text>
                                        )}
                                    </SC.BlockPrice>
                                    {(spaceQuota?.currentVersionCode ?? 0) <= spaceGradeCodeMap[item.version] &&
                                        spaceGradeCodeMap[item.version] !== 0 &&
                                        index < 5 && (
                                            <Button
                                                style={{
                                                    height: 48,
                                                    fontSize: 20,
                                                    fontWeight: 500,
                                                    color: item.version === 'ADVANCED' ? '#fff' : 'var(--color-black)',
                                                    background:
                                                        item.version === 'ADVANCED'
                                                            ? 'linear-gradient(91deg, #5551FF 4%, #7E51FF 97.52%)'
                                                            : 'var(--color-gray-100)',
                                                    boxShadow:
                                                        item.version === 'ADVANCED' ? '0px 8px 16px 0px rgba(95, 82, 255, 0.32)' : undefined
                                                }}
                                                // icon={<SC.Icon type="WeChatLogo" size={24} color="#fff" />}
                                                onClick={() => onWechatPaySpaceUpgrade(item.version)}
                                                radius={12}
                                                type="primary"
                                                block
                                            >
                                                {spaceQuota?.currentVersionCode === index ? '续费' : '立即购买'}
                                            </Button>
                                        )}

                                    {spaceQuota?.currentVersionCode === index && index === 0 && (
                                        <Button style={{ height: 48, fontSize: 20, pointerEvents: 'none' }} radius={12} type="tag" block>
                                            当前版本
                                        </Button>
                                    )}

                                    {(spaceQuota?.currentVersionCode ?? 0) > index && (
                                        <Button style={{ height: 48, fontSize: 20, pointerEvents: 'none' }} radius={12} type="tag" block>
                                            已升更高版
                                        </Button>
                                    )}
                                    <SC.KindBlock>
                                        <SC.InfoList>
                                            <Text weight={600}>核心资源用量</Text>
                                            <Flex justify="space-between">
                                                <Text color="var(--color-gray-500)">登录用户数</Text>
                                                <Text weight={600} color="var(--color-gray-900)">
                                                    {item.externalUserLimit > 0 ? item.externalUserLimit : '不限'}
                                                </Text>
                                            </Flex>
                                            <Flex justify="space-between">
                                                <Text color="var(--color-gray-500)">访问流量</Text>
                                                <Text weight={600}>{item.accessFlow / 1024} GB / 年</Text>
                                            </Flex>
                                            <Flex justify="space-between">
                                                <Text color="var(--color-gray-500)">附件储存</Text>
                                                <Text weight={600}>{item.storageLimit / 1024} GB</Text>
                                            </Flex>
                                            <Flex justify="space-between">
                                                <Text color="var(--color-gray-500)">数据表总记录数</Text>
                                                <Text weight={600}>
                                                    {item.recordsLimit > 9999 ? item.recordsLimit?.toLocaleString() : item.recordsLimit}
                                                </Text>
                                            </Flex>

                                            <Flex justify="space-between">
                                                <Text color="var(--color-gray-500)">Byecode验证码短信数量</Text>
                                                <Text weight={600}>{item.smsLimit === 0 ? '-' : item.smsLimit}</Text>
                                            </Flex>

                                            <Flex justify="space-between">
                                                <Text color="var(--color-gray-500)">访客数量</Text>
                                                <Text weight={600}>
                                                    {item.accessUserLimit === -1 ? '不限' : `${item.accessUserLimit?.toLocaleString()}/月`}
                                                </Text>
                                            </Flex>
                                        </SC.InfoList>
                                    </SC.KindBlock>
                                    <SC.KindBlock>
                                        <SC.InfoList style={{ gap: 12 }}>
                                            <Text weight={600}>
                                                {item.coreVersion ? `${spaceGradeNameMap[item.coreVersion]}所有功能，以及：` : '核心功能'}
                                            </Text>
                                            {item.coreFunction.map(label => (
                                                <Flex key={label} gap={4}>
                                                    <SC.Icon type="Tick" color="var(--color-theme-7)" />
                                                    <Text color="var(--color-gray-500)">{label}</Text>
                                                </Flex>
                                            ))}
                                        </SC.InfoList>
                                    </SC.KindBlock>
                                </SC.BlockContent>
                            </SC.BlockWrapper>
                        </SC.Block>
                    )
                })}

                <SC.Block style={{ background: 'linear-gradient(180deg, #FDF0E6 0%, rgba(253, 240, 230, 0.08) 100%)' }}>
                    <SC.BlockWrapper>
                        {/* {spaceThemeMap[item.version].image && <SC.VersionImage src={spaceThemeMap[item.version].image} />} */}
                        <SC.BlockContent>
                            <SC.BlockTitle>旗舰版</SC.BlockTitle>
                            <SC.BlockDescribe>大规模数字化运营</SC.BlockDescribe>
                            <SC.BlockPrice>
                                <Text size={48} weight={500} style={{ lineHeight: 1 }} color="var(--color-theme-7)">
                                    咨询报价
                                </Text>
                            </SC.BlockPrice>
                            <Button
                                style={{
                                    height: 48,
                                    fontSize: 20,
                                    backgroundColor: '#000',
                                    boxShadow: '0px 8px 16px 0px rgba(16, 24, 40, 0.12)'
                                }}
                                icon={<SC.Icon type="PropertyPhone" size={24} color="#fff" />}
                                type="primary"
                                radius={12}
                                block
                                onClick={() => {
                                    srv.getUserConsult()
                                    openSaleSpaceGrade()
                                }}
                            >
                                联系我们
                            </Button>
                            <SC.KindBlock>
                                <SC.InfoList>
                                    <Text weight={600}>核心资源用量</Text>
                                    <Flex justify="space-between">
                                        <Text color="var(--color-gray-500)">登录用户数</Text>
                                        <Text weight={600}>不限</Text>
                                    </Flex>
                                    <Flex justify="space-between">
                                        <Text color="var(--color-gray-500)">访问流量</Text>
                                        <Text color="var(--color-gray-400)">咨询</Text>
                                    </Flex>
                                    <Flex justify="space-between">
                                        <Text color="var(--color-gray-500)">附件存储</Text>
                                        <Text color="var(--color-gray-400)">咨询</Text>
                                    </Flex>
                                    <Flex justify="space-between">
                                        <Text color="var(--color-gray-500)">数据表总记录数</Text>
                                        <Text weight={600}>不限</Text>
                                    </Flex>
                                    <Flex justify="space-between">
                                        <Text color="var(--color-gray-500)">Byecode验证码短信数量</Text>
                                        <Text color="var(--color-gray-400)">咨询</Text>
                                    </Flex>
                                    <Flex justify="space-between">
                                        <Text color="var(--color-gray-500)">访客数量</Text>
                                        <Text weight={600}>不限</Text>
                                    </Flex>
                                </SC.InfoList>
                            </SC.KindBlock>
                            <SC.KindBlock>
                                <SC.InfoList style={{ gap: 12 }}>
                                    <Text weight={600}>专业版中的所有功能，以及：</Text>
                                    <Flex gap={4}>
                                        <SC.Icon type="Tick" color="var(--color-theme-7)" />
                                        <Text color="var(--color-gray-500)">运行日志保留时长2年</Text>
                                    </Flex>
                                    <Flex gap={4}>
                                        <SC.Icon type="Tick" color="var(--color-theme-7)" />
                                        <Text color="var(--color-gray-500)">不限应用发布数</Text>
                                    </Flex>
                                    <Flex gap={4}>
                                        <SC.Icon type="Tick" color="var(--color-theme-7)" />
                                        <Text color="var(--color-gray-500)">不限单表数据量</Text>
                                    </Flex>
                                </SC.InfoList>
                            </SC.KindBlock>
                        </SC.BlockContent>
                    </SC.BlockWrapper>
                </SC.Block>
            </SC.BlockContainer>
        </>
    )
}
