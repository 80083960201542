import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { atomWithProxy } from 'jotai-valtio'
import cookies from 'js-cookie'
import { proxy, subscribe } from 'valtio/vanilla'

import { INITIAL_USER_DATA } from './constants'

export const userDataAtom = atomWithImmer(INITIAL_USER_DATA)

export const userIDAtom = atom(get => get(userDataAtom).userId)

export const signedAtom = atom<boolean>(get => !!get(userIDAtom))

export const refreshTokenProxy = proxy({ value: localStorage.getItem('refreshToken') || '' })
export const refreshTokenAtom = atomWithProxy(refreshTokenProxy)

export const authAtom = atom(get => ({
    userId: get(userIDAtom),
    accessToken: cookies.get('jwt'),
    refreshToken: get(refreshTokenAtom)
}))

subscribe(refreshTokenProxy, () => {
    localStorage.setItem('refreshToken', refreshTokenProxy.value)
})

// 邀请新人的弹窗是否手动关闭过
export const invitationActivityAtom = atomWithStorage('invitationActivity', false)
