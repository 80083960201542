import type { Option } from '@byecode/ui'
import { Button, IconFont, Popover, SelectDropdown } from '@byecode/ui'
import type { DataSourceAbstract, Field, FieldType, ViewFieldProtocol } from '@lighthouse/core'
import { reduce } from 'rambda'
import type { CSSProperties} from 'react';
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { getFieldIcon, getRealField } from '../../utils'
import { FieldTypeTag } from '../FieldTypeTag'

type ExtraField = {
    label: string
    type: string
}

export interface FieldItemAdderProps {
    text: string
    columns?: ViewFieldProtocol[]
    selectedFieldIds: string[]
    color?: string
    disablePortal?: boolean
    noSettingFields?: Set<FieldType>
    style?: CSSProperties
    onAdd?: (id: string) => void
    onAddExtraField?: (field: ExtraField) => void
}

const FieldItemAdderWrapper = styled.div`
    padding: 0 8px;
    margin-top: 4px;
`

export const FieldItemAdder: React.FC<FieldItemAdderProps> = ({
    text,
    columns: ViewFields = [],
    selectedFieldIds,
    color,
    style,
    noSettingFields,
    onAdd
}) => {
    const [open, setOpen] = useState(false)

    const dropDownOptions: Option[] = useMemo(
        () =>
            reduce<ViewFieldProtocol, Option[]>(
                (list, viewField) => {
                    const realField = getRealField({ ...viewField, id: viewField.fieldId, name: viewField.title } as Field)
                    if (!realField) {
                        return list
                    }
                    const { type, id, name, innerType } = realField
                    // trim formula
                    if (noSettingFields?.has(type) || selectedFieldIds.includes(id)) {
                        return list
                    }

                    const isExtraField = type === 'formula'

                    return [
                        ...list,
                        {
                            value: id,
                            label: name,
                            icon: (
                                <IconFont
                                    fill={isExtraField ? 'var(--color-green-500)' : 'var(--color-gray-500)'}
                                    type={getFieldIcon(id, type, innerType)}
                                />
                            ),
                            extra: <FieldTypeTag type={type} innerType={innerType} />
                        }
                    ]
                },
                [],
                ViewFields
            ),
        [ViewFields, noSettingFields, selectedFieldIds]
    )

    const isFieldItemsEmpty = dropDownOptions.length === 0

    const handleAdd = useCallback(
        (id?: string) => {
            setOpen(false)
            if (id) {
                onAdd?.(id)
            }
        },
        [onAdd]
    )

    return (
        <FieldItemAdderWrapper style={style}>
            <Popover width={224} opened={open} onChange={setOpen} withinPortal>
                {!isFieldItemsEmpty && (
                    <Popover.Target>
                        <Button
                            radius={100}
                            icon={<IconFont type="Add" fill={color ?? 'var(--color-gray-500)'} style={{ marginRight: 6 }} />}
                        >
                            {text}
                        </Button>
                    </Popover.Target>
                )}
                <Popover.Dropdown>
                    <SelectDropdown searchable onSelect={handleAdd} options={dropDownOptions} />
                </Popover.Dropdown>
            </Popover>
        </FieldItemAdderWrapper>
    )
}
