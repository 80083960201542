import { Toast } from '@byecode/ui'
import type {
    BarChartOption,
    BaseChartOption,
    BlockAbstract,
    CalendarViewOptions,
    ContainerBlockAbstract,
    GalleryViewOptions,
    IndicatorOptionProtocol,
    KanbanViewOptions,
    ViewOptions
} from '@lighthouse/core'
import { BlockType, ChartType, HEIGHT_RULE } from '@lighthouse/core'
import { chartTypeLevel, findNodeById, getViewColumns, transformNode2FlowLayoutNode, useAtomData } from '@lighthouse/shared'
import produce from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import {
    lastPageOfStackAtom,
    pageAtomFamily,
    pageBlocksAtom,
    pageNodesAtom,
    pageStackAtomFamily,
    selectedBlockAtomFamily
} from '@/atoms/page/state'
import type { BlockSettingsValue } from '@/components/BlockSettings'
import { BlockSettings } from '@/components/BlockSettings'
import { generateCalendarConfig, generateGalleryConfig } from '@/constants/Block/generate/view'
import { getInitChartBlockConfigurator } from '@/containers/PageDetail/PageContent/help'
import { findParentPageNodeById } from '@/containers/PageDetail/PageContentV2/utils'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useBlockActions, useViewRecords } from '@/hooks/useBlock'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useDefaultPageList } from '@/hooks/usePage'
import * as srv from '@/services'
import { BLOCK_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

export interface BlockSettingsControllerProps {
    loading?: boolean
    dataSourceID?: string
    enableFieldEdit?: boolean
}

const BlockSettingsController = React.forwardRef<HTMLDivElement, BlockSettingsControllerProps>(
    ({ loading, dataSourceID: parentDsId, enableFieldEdit }, ref) => {
        const appId = useCurrentAppID()
        const envId = useCurrentEnvId()

        const lastPageMeta = useAtomData(lastPageOfStackAtom)
        const { rootPageId = '', pageId = '', stackId = '' } = lastPageMeta ?? {}
        const selectedBlock = useAtomData(selectedBlockAtomFamily({ rootPageId, pageId, stackId }))

        const originValue = useMemo<BlockSettingsValue | undefined>(() => {
            if (!selectedBlock) {
                return
            }

            // // 如果是容器，则需要将高度注入到配置中
            // if (selectedBlock.type === BlockType.container) {
            //     if (!pageNodes) {
            //         return
            //     }

            //     const node = findPageNodeById(selectedBlock.id)(pageNodes)
            //     if (!node) {
            //         return
            //     }

            //     return { ...clone(selectedBlock), height: node.height }
            // }

            return clone(selectedBlock)
        }, [selectedBlock])

        const { onUpdateBlock } = useBlockActions(pageId, stackId)

        const pageDsId = useAtomData(
            pageAtomFamily(pageId),
            useCallback(page => page?.dsId, [])
        )

        const pointer = useMemo(() => {
            if (!originValue) {
                return ''
            }
            switch (originValue.type) {
                case 'view': {
                    return originValue.config.pointer || ''
                }
                case 'chart': {
                    if (originValue.config.chartType === ChartType.indicator) {
                        return ''
                    }
                    return originValue.config.pointer
                }
                case 'fieldGroup':
                case 'field': {
                    // if (selectModule.type === 'formModule') {
                    //     return selectModule?.config?.form.pointer
                    // }
                    return pageDsId ?? ''
                }
                default: {
                    return parentDsId || ''
                }
            }
        }, [originValue, pageDsId, parentDsId])

        const dataSourceList = useDataSourceList(appId, envId)

        const dataSource = useDataSource(appId, envId, pointer)
        // const allDataSource = useDataSourcePool()

        const allPages = useDefaultPageList()
        const records = useViewRecords(originValue?.id ?? '')

        const handleFetchAllViews = useCallback(() => srv.getAllViews(), [])

        const debounceAdd = useMemo(
            () =>
                debounce(
                    500,
                    (origin: BlockAbstract, current: BlockAbstract) => {
                        pageUndoRedoController.add({
                            action: BLOCK_UPDATE_ACTION,
                            payload: {
                                prev: origin,
                                next: current
                            }
                        })
                    },
                    { atBegin: true }
                ),
            []
        )

        const onChange = useAtomCallback<void, [BlockSettingsValue]>((get, set, value) => {
            if (!originValue) {
                return
            }

            if (value.type === BlockType.container && originValue.type === BlockType.container) {
                // 如果当前设置为auto，子级是fill，并且没有固定高度的子级容器 。将子级设置为fixed
                if (value.config.design.heightRule === HEIGHT_RULE.AUTO) {
                    const pageNodes = get(pageNodesAtom)[pageId]
                    if (!pageNodes) {
                        return
                    }
                    const pageBlocks = get(pageBlocksAtom)[pageId]
                    if (!pageBlocks) {
                        return
                    }

                    const blockRuntimeState = get(pageStackAtomFamily({ rootPageId, stackId }))?.blockRuntimeState
                    const flowNodes = transformNode2FlowLayoutNode(pageNodes, pageBlocks, blockRuntimeState)

                    const flowNode = findNodeById(value.id)(flowNodes)
                    if (flowNode && flowNode.type === 'container') {
                        const hasFixedHeightContainer = flowNode.children?.some(node => {
                            const block = pageBlocks.find(item => item.id === node.id)
                            if (!block) {
                                return false
                            }

                            return block.type === BlockType.container && block.config.design.heightRule === HEIGHT_RULE.FILL
                        })
                        if (!hasFixedHeightContainer) {
                            flowNode.children?.forEach(node => {
                                const block = pageBlocks.find(item => item.id === node.id)
                                if (!block) {
                                    return
                                }

                                if (block.type === BlockType.container && block.config.design.heightRule === HEIGHT_RULE.FILL) {
                                    onUpdateBlock(
                                        produce(block, draft => {
                                            draft.config.design.heightRule = HEIGHT_RULE.FIXED
                                        }),
                                        block
                                    )
                                    Toast.info(`容器${block.title} 高度规则设置为 固定高度`)
                                }
                            })
                        }
                    }
                }

                // 如果当前设置为fill，父级是auto，将父级设置为fixed
                if (value.config.design.heightRule === HEIGHT_RULE.FILL) {
                    const pageNodes = get(pageNodesAtom)[pageId]
                    if (!pageNodes) {
                        return
                    }
                    const pageBlocks = get(pageBlocksAtom)[pageId]
                    if (!pageBlocks) {
                        return
                    }
                    const parentNode = findParentPageNodeById(value.id)(pageNodes, pageBlocks)
                    if (parentNode) {
                        const hasFixedHeightContainer = parentNode.children?.some(node => {
                            const block = pageBlocks.find(item => item.id === node.id)
                            if (!block) {
                                return false
                            }

                            return block.type === BlockType.container && block.config.design.heightRule === HEIGHT_RULE.FILL
                        })
                        if (!hasFixedHeightContainer) {
                            const parentBlock = pageBlocks?.find(item => item.id === parentNode.id) as ContainerBlockAbstract
                            if (parentBlock && parentBlock.config.design.heightRule === HEIGHT_RULE.AUTO) {
                                onUpdateBlock(
                                    produce(parentBlock, draft => {
                                        draft.config.design.heightRule = HEIGHT_RULE.FIXED
                                    }),
                                    parentBlock
                                )
                                Toast.info(`容器${parentBlock.title} 高度规则设置为 固定高度`)
                            }
                        }
                    }
                }
            }

            if (value.type === BlockType.view && originValue.type === BlockType.view) {
                // 切换视图数据源时初始化配置
                if (value.config.pointer !== originValue.config.pointer) {
                    const newDatasource = dataSourceList.find(item => item.id === value.config.pointer)
                    if (!newDatasource) {
                        return
                    }

                    const { viewOptions, schema } = newDatasource

                    if (value.config.viewType === 'gallery') {
                        Object.assign(value.config, generateGalleryConfig(newDatasource))
                    }

                    if (value.config.viewType === 'calendar') {
                        Object.assign(value.config, generateCalendarConfig(newDatasource))
                    }

                    const viewFieldSettings = getViewColumns({
                        tableProps: viewOptions.tableProps,
                        disabledFieldName: true,
                        schema
                    })
                    Object.assign(value.config, {
                        viewFieldSettings,
                        quickFilter: { mode: 'normal', rules: [] },
                        groupByFieldId: undefined,
                        groupConfig: undefined,
                        linkFilterController: {
                            expression: {
                                where: 'AND',
                                conditions: []
                            }
                        }
                    })
                }

                // 切换视图类型时，清空其他类型视图配置
                if (value.config.viewType !== originValue.config.viewType) {
                    // 日历视图
                    const cleanCalendarConfig: CalendarViewOptions = {
                        scheduleTitleField: undefined,
                        scheduleStartDateField: undefined,
                        scheduleEndDateField: undefined,
                        scheduleColorMode: undefined,
                        scheduleColor: undefined
                    }
                    // 画廊视图
                    const cleanGalleryConfig: GalleryViewOptions = {
                        headCover: undefined,
                        headAvatar: undefined,
                        headTitle: undefined,
                        headTags: undefined,
                        contentTags: undefined,
                        footTags: undefined,
                        arrangement: undefined,
                        cols: undefined,
                        size: undefined,
                        style: undefined
                    }
                    // 看板
                    const cleanKanbanConfig: KanbanViewOptions = {
                        kanbanGroupConfigure: undefined,
                        canSetKanban: undefined,
                        canSortKanban: undefined
                    }

                    switch (value.config.viewType) {
                        case 'advancedTable':
                        case 'list':
                        case 'table': {
                            Object.assign(value.config, {
                                ...cleanGalleryConfig,
                                ...cleanCalendarConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'calendar': {
                            Object.assign(value.config, {
                                ...cleanGalleryConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'gallery': {
                            Object.assign(value.config, {
                                ...cleanCalendarConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'kanban': {
                            Object.assign(value.config, {
                                ...cleanCalendarConfig,
                                ...cleanGalleryConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        default: {
                            break
                        }
                    }
                }
            }

            if (value.type === BlockType.chart && originValue.type === BlockType.chart) {
                const cleanBarConfig: Partial<IndicatorOptionProtocol> = {
                    appId: '',
                    pointer: '',
                    showHeadTitle: true,
                    title: '指标卡',
                    showLabel: false,
                    showThousandthSplit: false,
                    showUnit: false,
                    fieldId: ''
                }

                const cleanStriationConfig: Partial<BarChartOption & IndicatorOptionProtocol> = {
                    showSecondaryAxis: undefined, // 显示次轴
                    secondaryAxis: [], // 次轴
                    secondaryMarkLines: [], // 次轴 辅助线
                    ySecondaryAxisTitle: undefined, // y次轴标题

                    ...cleanBarConfig
                }
                const cleanPieConfig: Partial<BarChartOption & IndicatorOptionProtocol> = {
                    mainMarkLines: [], // 主轴 辅助线
                    showAxisTitle: undefined, // 显示坐标轴标题
                    xAxisTitle: undefined, // x坐标轴标题
                    yMainAxisTitle: undefined, // y主轴标题
                    stacking: undefined, // 展示方式
                    showGridLine: undefined, // 显示网格线
                    showAbbreviationAxis: undefined, // 显示缩略轴
                    showLabel: undefined, // 显示数值标签

                    showSecondaryAxis: undefined, // 显示次轴
                    secondaryAxis: [], // 次轴
                    secondaryMarkLines: [], // 次轴 辅助线
                    ySecondaryAxisTitle: undefined, // y次轴标题

                    ...cleanBarConfig
                }

                const cleanIndicatorConfig: Partial<BaseChartOption & BarChartOption> = {
                    pointer: '',
                    appId: '',
                    dimensions: [], // 维度
                    mainAxis: [], // 主轴 原指标
                    aggregate: undefined, // 是否聚合
                    ruleFilter: undefined, // 筛选器
                    xyAxis: undefined, // 排序依据  横轴值/纵轴值
                    sortMode: undefined, // 排序规则 正序/倒序
                    // theme: '',
                    // colors: [],
                    showHeadTitle: undefined, // 显示标题
                    title: undefined, // 标题
                    showLegend: undefined, // 显示图例
                    legendTranspose: undefined, // 图例方向

                    mainMarkLines: [], // 主轴 辅助线
                    showAxisTitle: undefined, // 显示坐标轴标题
                    xAxisTitle: undefined, // x坐标轴标题
                    yMainAxisTitle: undefined, // y主轴标题
                    stacking: undefined, // 展示方式
                    showGridLine: undefined, // 显示网格线
                    showAbbreviationAxis: undefined, // 显示缩略轴
                    showLabel: undefined, // 显示数值标签

                    showSecondaryAxis: undefined, // 显示次轴
                    secondaryAxis: [], // 次轴
                    secondaryMarkLines: [], // 次轴 辅助线
                    ySecondaryAxisTitle: undefined // y次轴标题
                }
                if (chartTypeLevel[value.config.chartType] !== chartTypeLevel[originValue.config.chartType]) {
                    switch (value.config.chartType) {
                        case ChartType.bar:
                        case ChartType.line:
                        case ChartType.composite: {
                            Object.assign(value.config, cleanBarConfig)
                            break
                        }

                        case ChartType.striation: {
                            Object.assign(value.config, cleanStriationConfig)
                            break
                        }

                        case ChartType.pie:
                        case ChartType.funnel: {
                            Object.assign(value.config, cleanPieConfig)
                            break
                        }

                        case ChartType.indicator: {
                            Object.assign(value.config, cleanIndicatorConfig)
                            break
                        }

                        default: {
                            break
                        }
                    }
                    // Object.assign(value, getInitChartBlockConfigurator(value, newDatasource)
                    // )
                }

                const { config } = value
                const { config: selectConfig } = originValue
                if (config.pointer !== selectConfig.pointer) {
                    const newDatasource = dataSourceList.find(item => item.id === config.pointer)
                    if (newDatasource) {
                        Object.assign(value, getInitChartBlockConfigurator(value, newDatasource))
                    }
                }
            }

            onUpdateBlock(value, originValue)
            debounceAdd(originValue, value)
        })

        return originValue ? (
            <BlockSettings
                key={originValue.id}
                ref={ref}
                appId={appId}
                loading={loading}
                value={originValue}
                onChange={onChange}
                dataSource={dataSource}
                dataSourceList={dataSourceList}
                records={records}
                allDataSources={dataSourceList}
                allPages={allPages ?? []}
                // allViews={allViews}
                pointer={pointer}
                onFetchAllViews={handleFetchAllViews}
            />
        ) : null
    }
)

export default BlockSettingsController
