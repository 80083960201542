import { Anchor, Flex, Group, IconFont, Input, Text } from '@byecode/ui'
import type { ApplicationSettingSEO } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { searchEngineIconMap } from '@/components/SearchEngine/constants'
import { PageSubmissionSearchEngineEnum } from '@/services/types'

import { SettingCard } from '../SettingCard'
import { SettingItem } from '../SettingItem'

interface SettingSEOProps {}

const SCxContainer = styled.div`
    width: 100%;
    font-size: var(--font-size-normal);
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
`

const SCxItem = styled.div``

const SettingSEO: React.FC<SettingSEOProps> = () => {
    const seo = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.seo, [])
    )
    const { run: updateAppSetting } = useAtomAction(updateAppAtom)

    const methods = useForm<ApplicationSettingSEO>({
        mode: 'onChange',
        defaultValues: seo
    })
    const { watch, control, reset } = methods

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingSEO) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        seo: value
                    }
                })
            }),
        [updateAppSetting]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingSEO)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <SCxContainer>
            <SettingCard
                title="网站验证代码"
                describe={
                    <Text>
                        粘贴网站验证代码，搜索引擎能够确认该网站是由您或您的组织所拥有和管理。通过网站验证后才能将网站链接提交给搜索引擎，从而提高网站被搜索引擎收录的效率。
                        <Anchor target='_blank' to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zf6felx73xz207l8?singleDoc#">
                            如何获取网站验证代码？
                        </Anchor>
                    </Text>
                }
            >
                <Flex style={{ marginTop: 20, marginBottom: 8 }} direction="column" gap={12}>
                    <Flex direction="column" style={{ border: '1px solid var(--color-gray-200)', borderRadius: 8 }}>
                        <Controller
                            name="baidu.verificationInfo"
                            control={control}
                            render={({ field }) => (
                                <Group
                                    defaultOpen
                                    label={
                                        <Flex gap={8} alignItems="center">
                                            <IconFont
                                                size={24}
                                                type={searchEngineIconMap.get(PageSubmissionSearchEngineEnum.BAIDU) ?? ''}
                                                color="var(--color-theme-7)"
                                            />
                                            <Text weight={400}>百度搜索</Text>
                                        </Flex>
                                    }
                                    styles={{
                                        wrapper: {
                                            height: 'auto!important',
                                            padding: '0 12px!important'
                                        },
                                        root: {
                                            padding: '12px 0 !important'
                                        },
                                        collapse: {
                                            marginTop: 12
                                        }
                                    }}
                                    renderRightIcon={open => (
                                        <Flex alignItems="center" gap={4}>
                                            {field.value && <IconFont size={16} type="Tick" color="var(--color-green-500)" />}

                                            <Text size={12} color={field.value ? 'var(--color-green-500)' : 'var(--color-gray-400)'}>
                                                {field.value ? '已' : '未'}添加
                                            </Text>
                                            <IconFont
                                                size={16}
                                                type={open ? 'ArrowDownSmall' : 'ArrowRightSmall'}
                                                color="var(--color-gray-400)"
                                            />
                                        </Flex>
                                    )}
                                >
                                    <Input value={field.value} size="lg" placeholder="请粘贴网站验证代码" onChange={field.onChange} />
                                </Group>
                            )}
                        />
                    </Flex>
                    <Flex direction="column" style={{ border: '1px solid var(--color-gray-200)', borderRadius: 8 }}>
                        <Controller
                            name="bing.verificationInfo"
                            control={control}
                            render={({ field }) => (
                                <Group
                                    defaultOpen
                                    label={
                                        <Flex gap={8} alignItems="center">
                                            <IconFont
                                                size={24}
                                                type={searchEngineIconMap.get(PageSubmissionSearchEngineEnum.BING) ?? ''}
                                                color="var(--color-theme-7)"
                                            />
                                            <Text weight={400}>必应搜索</Text>
                                        </Flex>
                                    }
                                    styles={{
                                        wrapper: {
                                            height: 'auto!important',
                                            padding: '0 12px!important'
                                        },
                                        root: {
                                            padding: '12px 0 !important'
                                        },
                                        collapse: {
                                            marginTop: 12
                                        }
                                    }}
                                    renderRightIcon={open => (
                                        <Flex alignItems="center" gap={4}>
                                            {field.value && <IconFont size={16} type="Tick" color="var(--color-green-500)" />}

                                            <Text size={12} color={field.value ? 'var(--color-green-500)' : 'var(--color-gray-400)'}>
                                                {field.value ? '已' : '未'}添加
                                            </Text>
                                            <IconFont
                                                size={16}
                                                type={open ? 'ArrowDownSmall' : 'ArrowRightSmall'}
                                                color="var(--color-gray-400)"
                                            />
                                        </Flex>
                                    )}
                                >
                                    <Input value={field.value} size="lg" placeholder="请粘贴网站验证代码" onChange={field.onChange} />
                                </Group>
                            )}
                        />
                    </Flex>
                    <Flex direction="column" style={{ border: '1px solid var(--color-gray-200)', borderRadius: 8 }}>
                        <Controller
                            name="google.verificationInfo"
                            control={control}
                            render={({ field }) => (
                                <Group
                                    defaultOpen
                                    label={
                                        <Flex gap={8} alignItems="center">
                                            <IconFont
                                                size={24}
                                                type={searchEngineIconMap.get(PageSubmissionSearchEngineEnum.CHROME) ?? ''}
                                                color="var(--color-theme-7)"
                                            />
                                            <Text weight={400}>谷歌搜索</Text>
                                        </Flex>
                                    }
                                    styles={{
                                        wrapper: {
                                            height: 'auto!important',
                                            padding: '0 12px!important'
                                        },
                                        root: {
                                            padding: '12px 0 !important'
                                        },
                                        collapse: {
                                            marginTop: 12
                                        }
                                    }}
                                    renderRightIcon={open => (
                                        <Flex alignItems="center" gap={4}>
                                            {field.value && <IconFont size={16} type="Tick" color="var(--color-green-500)" />}

                                            <Text size={12} color={field.value ? 'var(--color-green-500)' : 'var(--color-gray-400)'}>
                                                {field.value ? '已' : '未'}添加
                                            </Text>
                                            <IconFont
                                                size={16}
                                                type={open ? 'ArrowDownSmall' : 'ArrowRightSmall'}
                                                color="var(--color-gray-400)"
                                            />
                                        </Flex>
                                    )}
                                >
                                    <Input value={field.value} size="lg" placeholder="请粘贴网站验证代码" onChange={field.onChange} />
                                </Group>
                            )}
                        />
                    </Flex>
                </Flex>

                {/* <SearchEngineQueueTable style={{ padding: 0, marginTop: 16 }} /> */}
            </SettingCard>
            <SettingCard title="网站地图 Sitemap">
                <Text color="var(--color-gray-400)" size={14} style={{ marginTop: 16 }}>
                    敬请期待
                </Text>
            </SettingCard>
        </SCxContainer>
    )
}

export default SettingSEO
