import { getAssetUrl } from '@lighthouse/assets'

export const guideTaskList: {
    id: string
    image: string
    title: string
    steps: { id: string; title: string; previewList: { id: string; source?: string; describe: string }[] }[]
}[] = [
    {
        id: 'creatGallery',
        title: '如何使用画廊视图搭建一个团队名片？',
        image: getAssetUrl('guide', 'task_create_gallery.jpg'),
        steps: [
            {
                id: 'createDatSource',
                title: '数据源：创建数据表',
                previewList: [
                    {
                        id: 'createDatSource1',
                        source: getAssetUrl('guide', 'task_create_dataSource.mp4'),
                        describe:
                            '创建团队名片前，我们先将名片中的必须存在的数据填入到数据源中，新建数据表，在数据表中新建字段姓名（文本）、电话（电话）、职位（文本）、头像（附件）等等，创建完成后上传相关数据，就像使用excel。'
                    }
                ]
            },
            {
                id: 'createTeamCard',
                title: '创建团队名片首页',
                previewList: [
                    {
                        id: 'createTeamCard1',
                        source: getAssetUrl('guide', 'task_create_team.mp4'),
                        describe:
                            '选择需要使用的数据视图组件，拖入画廊组件。在右侧栏中选择绑定数据源，这里我们绑定上一步创建好的数据表。更改首页名片样式，在右侧栏设计中，选择卡片内容排版切换卡片中的信息内容与设计。'
                    }
                ]
            },
            {
                id: 'configViewDetailPage',
                title: '配置视图打开详情页的动作',
                previewList: [
                    {
                        id: 'configViewDetailPage1',
                        source: getAssetUrl('guide', 'task_config_page.mp4'),
                        describe:
                            '在用户操作当中，选择点击卡片后下的展开记录，点击【选择一个详情页】然后去创建一个详情页，这样我们就配置好了打开详情页配置。'
                    }
                ]
            },
            {
                id: 'designDetailPage',
                title: '设计详情页排版',
                previewList: [
                    {
                        id: 'designDetailPage1',
                        source: getAssetUrl('guide', 'task_design_page.mp4'),
                        describe:
                            '返回到画廊视图，点击卡片进入详情页，在详情页中设计你的页面排版。我们可以拖入一个行容器，在容器内拖入图片组件当作头像，拖入文本组件存放姓名职位等信息，再拖入按钮，帮助名片跳转外部链接。最后详细配置具体的样式就可以完成配置。如果还需要配置其他企业信息，我们可以直接拖入组件内容去创建。'
                    }
                ]
            },
            {
                id: 'settingVariable',
                title: '设置变量',
                previewList: [
                    {
                        id: 'settingVariable1',
                        source: getAssetUrl('guide', 'task_design_variable.mp4'),
                        describe:
                            '选中图片组件，将图片来源设置为变量，并选择变量为当前页面的头像字段，同理，姓名电话等部分都可以选择插入变量。团队名片当中主体是团队，那么团队信息不变你就可以直接拖入组件去填写内容。'
                    }
                ]
            },
            {
                id: 'preview',
                title: '预览',
                previewList: [
                    {
                        id: 'preview1',
                        source: getAssetUrl('guide', 'task_preview.jpg'),
                        describe: '点击预览可以直接查看你制作好的样式，任何编辑操作，在预览中点击刷新就可以查看。'
                    }
                ]
            },
            {
                id: 'publish',
                title: '发布分享',
                previewList: [
                    {
                        id: 'publish1',
                        source: getAssetUrl('guide', 'task_publish.jpg'),
                        describe: '制作完成后，我们可以选择发布应用，这样我们的应用就成功上线，分享给你的好友吧。'
                    }
                ]
            }
        ]
    },
    {
        id: 'creatContainer',
        title: '如何使用容器组件设计网站？',
        image: getAssetUrl('guide', 'task_create_container.jpg'),
        steps: [
            {
                id: 'websiteContent',
                title: '区分网站板块内容',
                previewList: [
                    {
                        id: 'websiteContent1',
                        source: getAssetUrl('guide', 'task_website.jpg'),
                        describe:
                            '将整个网站页面区分为一个个板块内容，去构思板块组成结构。ByeCode官网为例，我们将每一个组件都标注了出来，这样我们就可以快速理解一个网站如何用ByeCode组件去搭建出来。'
                    }
                ]
            },
            {
                id: 'makeWebsite',
                title: '开始制作',
                previewList: [
                    {
                        id: 'makeWebsite1',
                        source: getAssetUrl('guide', 'task_make_website.mp4'),
                        describe:
                            'ByeCode官网首屏页面配置，拆解下来是一个容器组件、两个文本组件、一个按钮组件、一个视频组件组成，我们查看视频进行详细配置。'
                    },
                    {
                        id: 'makeWebsite2',
                        source: getAssetUrl('guide', 'task_make_layout.mp4'),
                        describe: '利用容器组件的特性，实现不同布局的搭建'
                    }
                ]
            }
        ]
    }
]
