import { singleTextEllipsis } from '@byecode/ui'
import { IconFont } from '@byecode/ui/components/IconFont'
import { usePopoverContext } from '@byecode/ui/components/Popover'
import type { ApplicationSettingThemeColor, BackgroundProtocol, VariableADTvalue } from '@lighthouse/core'
import { BACKGROUND_TYPE, VariableType } from '@lighthouse/core'
import { stopPropagation } from '@lighthouse/tools'
import chroma from 'chroma-js'
import React, { forwardRef, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { APP_MAIN_COLOR_VARIABLE } from '../../constants'
import { useApplicationContext } from '../../contexts'
import { getFileNameByUrl, getImageFullUrlInApplication } from '../../utils'
import { useFillPickerContext } from '../FillPicker'
import { applyFillPickerValue4CssColorValue, getGradientCssText, isHexColor } from '../FillPicker/utils'
import type { VariableOptions, VariableTree } from '../Variable'
import { VariableTag } from '../Variable'

function color2label(color: string, palettes: ApplicationSettingThemeColor[]) {
    if (color === APP_MAIN_COLOR_VARIABLE) {
        return '跟随主题'
    }

    if (isHexColor(color)) {
        return chroma(color).alpha(1).hex()
    }

    const paletteColor = palettes.find(item => item.id === color)
    if (paletteColor) {
        return paletteColor.label
    }
}

type PreviewerProps = {
    value?: BackgroundProtocol
    palettes: ApplicationSettingThemeColor[]
}
const Previewer = styled.div.withConfig<PreviewerProps>({ shouldForwardProp: p => !['value', 'palettes'].includes(p) })`
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--color-gray-200);

    ${({ value, palettes }) =>
        value && [
            value.type === BACKGROUND_TYPE.color &&
                !!value.color &&
                css`
                    &::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        border-radius: inherit;
                        background-color: ${value.color === APP_MAIN_COLOR_VARIABLE
                            ? 'unset'
                            : applyFillPickerValue4CssColorValue(value.color, palettes)};
                    }
                `,
            value.type === BACKGROUND_TYPE.gradient &&
                !!value.gradient &&
                css`
                    background: ${getGradientCssText(value.gradient, palettes)};
                `
        ]}
`

const Input = styled.input`
    all: unset;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    height: 32px;
    padding: 6px 30px;
    background-color: var(--color-gray-100);
    border-radius: 5px;
    ${singleTextEllipsis()}
    cursor: pointer;
`

const VariableWrapper = styled.div`
    position: absolute;
    inset: 0;
    left: 6px;
    display: flex;
    align-items: center;
    padding-right: 32px;
`

const ClearIcon = styled(IconFont)`
    cursor: pointer;
    font-size: 16px;
    color: var(--color-gray-400);
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
`

const Wrapper = styled.div<{ isEmpty: boolean }>`
    position: relative;
    iconpark-icon[name='Add'] {
        fill: var(--color-gray-400);
    }
    &:hover {
        ${({ isEmpty }) => [
            isEmpty &&
                css`
                    ${Previewer} {
                        color: var(--color-gray-700);
                        background-color: var(--color-gray-300);
                        iconpark-icon[name='Add'] {
                            fill: var(--color-gray-700);
                        }
                    }
                `,
            css`
                ${Input} {
                    background-color: var(--color-gray-200);
                }
            `
        ]}
    }
`

export interface FilledInputProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    value?: BackgroundProtocol
    onChange?: (v: BackgroundProtocol) => void
    onClear?: () => void

    getImageVariableUrl?: (value: VariableADTvalue) => string

    options?: VariableOptions
    userOption?: VariableTree
    viewOption?: VariableTree
}

export const FilledInput = forwardRef<HTMLDivElement, FilledInputProps>((props, ref) => {
    const { value, onChange, onClear, getImageVariableUrl, options, userOption, viewOption, ...rest } = props
    const { appId } = useApplicationContext()
    const { palettes } = useFillPickerContext()

    const isImageVariableValue =
        value?.type === BACKGROUND_TYPE.image &&
        !!value.image?.resource &&
        (value.image.resource.type === VariableType.PAGE ||
            value.image.resource.type === VariableType.USER ||
            value.image.resource.type === VariableType.VIEW)

    const valueString = useMemo(() => {
        if (!value) {
            return '添加'
        }
        switch (value.type) {
            case BACKGROUND_TYPE.color: {
                if (!value.color) {
                    return '添加'
                }
                return color2label(value.color, palettes)
            }

            case BACKGROUND_TYPE.gradient: {
                if (!value.gradient) {
                    return '添加'
                }
                return '渐变色'
            }

            case BACKGROUND_TYPE.image: {
                if (!getImageVariableUrl || !value.image) {
                    return '添加'
                }

                if (isImageVariableValue) {
                    return ''
                }

                const url = getFileNameByUrl(getImageVariableUrl(value.image.resource))
                if (!url) {
                    return '添加'
                }
                return url
            }

            default: {
                break
            }
        }
    }, [value, palettes, getImageVariableUrl, isImageVariableValue])

    const previewDom = useMemo(() => {
        if (!value) {
            return <IconFont key="Add" type="Add" color="var(--color-gray-400)" />
        }

        if (value.type === BACKGROUND_TYPE.color) {
            if (!value.color) {
                return <IconFont key="Add" type="Add" color="var(--color-gray-400)" />
            }
            if (value.color === APP_MAIN_COLOR_VARIABLE) {
                return <IconFont key="ThemeColor" size={20} type="ThemeColor" />
            }

            let hex = '#ffffff'
            if (isHexColor(value.color)) {
                hex = value.color
            } else {
                const paletteColor = palettes.find(item => item.id === value.color)
                if (paletteColor) {
                    hex = paletteColor.value
                }
            }

            const alpha = chroma(hex).alpha()
            if (alpha !== 1) {
                return <IconFont key="Opacity" style={{ opacity: 0.5 }} size={20} type="Opacity" />
            }
        }

        if (value.type === BACKGROUND_TYPE.image) {
            const imgSrc = value.image?.resource && getImageVariableUrl?.(value.image.resource)
            if (!imgSrc) {
                return <IconFont key="Add" type="Add" color="var(--color-gray-400)" />
            }
            return <img src={getImageFullUrlInApplication(appId, imgSrc)} width={20} height={20} alt="" />
        }

        return null
    }, [appId, getImageVariableUrl, palettes, value])

    const { onClose } = usePopoverContext()

    const isEmpty = useMemo(() => {
        if (!value) {
            return true
        }

        switch (value.type) {
            case BACKGROUND_TYPE.color: {
                return !value.color
            }
            case BACKGROUND_TYPE.gradient: {
                return !value.gradient
            }
            case BACKGROUND_TYPE.image: {
                return !value.image?.resource
            }

            default: {
                return true
            }
        }
    }, [value])

    return (
        <Wrapper ref={ref} {...rest} isEmpty={isEmpty}>
            <Input readOnly value={valueString || ''} style={isEmpty ? { color: 'var(--color-gray-400)' } : undefined} />

            {isImageVariableValue ? (
                <VariableWrapper>
                    <VariableTag
                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        value={value.image?.resource}
                        options={options}
                        userDsOption={userOption}
                        viewOption={viewOption}
                    />
                </VariableWrapper>
            ) : (
                <Previewer value={value} palettes={palettes}>
                    {previewDom}
                </Previewer>
            )}
            {!isEmpty && (
                <ClearIcon
                    type="ErrorCircle"
                    onPointerDown={stopPropagation}
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                    onClick={e => {
                        e.stopPropagation()
                        onClose?.()
                        onClear?.()
                        onChange?.({ type: value?.type || BACKGROUND_TYPE.color })
                    }}
                />
            )}
        </Wrapper>
    )
})
