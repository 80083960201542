import {
    hideScrollBar,
    IconFont,
    singleTextEllipsis
} from '@byecode/ui'
import styled from 'styled-components'

export const ChunkContainer = styled.div`
    height: 100%;
    width: 100%;
    /* padding: 0 14px; */
    margin-top: 12px;
    overflow: auto;
    flex: 1;
    padding-bottom: 120px;
    ${hideScrollBar}
`
export const Container = styled.div`
    width: 264px;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
`

export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? 16};
`

export const Text = styled.div<{ color?: string }>`
    font-size: var(--font-size-sm);
    color: ${({ color }) => color ?? 'var(--color-black)'};
    line-height: 16px;
    ${singleTextEllipsis}
`
