import type { IconDecorationProtocol } from '@lighthouse/core'
import { Text } from '@mantine/core'
import * as React from 'react'
import styled from 'styled-components'

import { IconControlV2 } from '@/components/BlockSettings/Common/IconControl'
import { uploadManagerInAppParams } from '@/utils/auth'

const SCxLogoContain = styled.div`
    /* width: 68px; */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const SCxRightFill = styled.div``

interface LogoProps {
    disabled?: boolean
    value?: IconDecorationProtocol
    onGetFullUrl?: (url: string) => string
    onChange?: (url: IconDecorationProtocol | undefined) => void
}

const Logo = React.forwardRef<HTMLDivElement, LogoProps>(({ disabled, value, onGetFullUrl, onChange }, ref) => {
    return (
        <SCxLogoContain ref={ref}>
            <Text>logo</Text>
            <IconControlV2
                value={value}
                disabled={disabled}
                clearable
                uploadParams={uploadManagerInAppParams()}
                onGetFullUrl={onGetFullUrl}
                onChange={v => onChange?.(v ?? { type: 'icon' })}
            />
        </SCxLogoContain>
    )
})

export default Logo
