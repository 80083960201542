import { Toast } from '@byecode/ui'
import { ErrorFallback } from '@lighthouse/block'
import type { AppendParams } from '@lighthouse/shared'
import { DataSourceProvider, useAtomAction } from '@lighthouse/shared'
import { getDefaultStore } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, useNavigate } from 'react-router-dom'

import { fetchDataSourceAtom, fetchDataSourceUserAtom, setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { filterCacheAtomFamily, sortsCacheAtomFamily } from '@/atoms/storage/state'
import DataSourceList from '@/components/DataSourceList'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useCurrentDataSourceId, useDataSourceDepartments, useDataSourceEnvId , useDataSourceRoles, useDataSourceUsers } from '@/hooks/useDataSource'
import * as srv from '@/services'

import * as SC from './styles'

const DataSource: React.FC = () => {
    const store = getDefaultStore()
    const currentAppId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const users = useDataSourceUsers()
    const roles = useDataSourceRoles()
    const departments = useDataSourceDepartments()
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    const navigate = useNavigate()
    const [visibleJoinConfigure, setVisibleJoinConfigure] = useState(false)
    const activeId = useCurrentDataSourceId()

    const { run: fetchDataSource, loading } = useAtomAction(fetchDataSourceAtom)

    const handleNavigateToEr = useCallback(() => {
        setCurrentDataSourceId({ dsId: '' })
        navigate({ pathname: `/${currentAppId}/dataSource/er` })
    }, [currentAppId, navigate, setCurrentDataSourceId])

    const handleSelectDataSource = useCallback(
        (dsId: string) => {
            if (dsId) {
                setCurrentDataSourceId({ dsId })
                navigate({ pathname: `/${currentAppId}/dataSource/${dsId}` })
                return
            }
            navigate({ pathname: `/${currentAppId}/dataSource` })
        },
        [currentAppId, navigate, setCurrentDataSourceId]
    )

    const handleAppendData = useCallback(
        async (params: AppendParams) => {
            const { dsId } = params.sheetDto
            const isAppend = await srv.appendDataToDataSource(params)
            isAppend && Toast.success('成功追加导入到数据源')
            if (dsId === activeId && isAppend) {
                const filterCache = store.get(filterCacheAtomFamily({ appId: currentAppId, envId, id: dsId }))
                const sortsCache = store.get(sortsCacheAtomFamily({ appId: currentAppId, envId, id: dsId }))
                fetchDataSource({
                    envId,
                    dsId: activeId,
                    pagination: { currentPage: 1, pageSize: 100 },
                    filter: filterCache,
                    sorts: sortsCache
                })
            }
        },
        [activeId, currentAppId, envId, fetchDataSource, store]
    )

    useEffect(() => {
        fetchDataSourceUser()
    }, [fetchDataSourceUser, envId])

    const handleVisibleJoinConfigureChange = useCallback((visible: boolean) => {
        setVisibleJoinConfigure(visible)
    }, [])

    const dataSourceContextValue = useMemo(
        () => ({
            personOptions: users,
            roleOptions: roles,
            departmentOptions: departments,
            visibleJoinConfigure,
            onVisibleJoinConfigureChange: handleVisibleJoinConfigureChange
        }),
        [departments, handleVisibleJoinConfigureChange, roles, users, visibleJoinConfigure]
    )

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DataSourceProvider value={dataSourceContextValue}>
                <SC.Container>
                    <SC.Content>
                        {activeId && (
                            <DataSourceList
                                activeId={activeId}
                                onNavigateToEr={handleNavigateToEr}
                                onSelectDataSource={handleSelectDataSource}
                                onDataAppended={handleAppendData}
                            />
                        )}
                        {activeId !== undefined && <Outlet />}
                    </SC.Content>
                </SC.Container>
            </DataSourceProvider>
        </ErrorBoundary>
    )
}

export default DataSource
