import { COLOR_BOARD_HEIGHT, COLOR_BOARD_WIDTH, HSL_MAX, SLIDER_WIDTH } from './constants'

const angleUnits: Record<string, number> = {
    grad: 360 / 400,
    turn: 360,
    rad: 360 / (Math.PI * 2)
}

export interface RgbColor {
    r: number
    g: number
    b: number
}

export interface RgbaColor extends RgbColor {
    a: number
}

export interface HslColor {
    h: number
    s: number
    l: number
}

export interface HslaColor extends HslColor {
    a: number
}

export interface HsvColor {
    h: number
    s: number
    v: number
}

export interface HsvaColor extends HsvColor {
    a: number
}

export const round = (number: number, digits = 0, base = 10 ** digits): number => {
    return Math.round(base * number) / base
}

export const parseHue = (value: string, unit = 'deg'): number => {
    return Number(value) * (angleUnits[unit] || 1)
}

export const getHsvColorH = (offsetX: number) => {
    return round((offsetX / SLIDER_WIDTH) * HSL_MAX)
}

export const getHsvColorS = (offsetX: number) => {
    return round((offsetX / COLOR_BOARD_WIDTH) * 100)
}

export const getHsvColorV = (offsetY: number) => {
    return round((1 - offsetY / COLOR_BOARD_HEIGHT) * 100)
}

export const getHsvColorA = (offsetX: number) => {
    return round(offsetX / SLIDER_WIDTH, 2)
}

export const convertHsvaColorToHOffset = (colorH: number) => {
    return (colorH / HSL_MAX) * SLIDER_WIDTH
}
export const convertHsvaColorToSOffset = (colorS: number) => {
    return (colorS / 100) * COLOR_BOARD_WIDTH
}
export const convertHsvaColorToVOffset = (colorV: number) => {
    return (1 - colorV / 100) * COLOR_BOARD_HEIGHT
}
export const convertHsvaColorToAOffset = (colorA: number) => {
    return colorA * SLIDER_WIDTH
}

export const hsvaToHsla = ({ h, s, v, a }: HsvaColor): HslaColor => {
    const hh = ((200 - s) * v) / 100

    return {
        h: round(h),
        s: round(hh > 0 && hh < 200 ? ((s * v) / 100 / (hh <= 100 ? hh : 200 - hh)) * 100 : 0),
        l: round(hh / 2),
        a: round(a, 2)
    }
}

export const hsvaToHslString = (hsva: HsvaColor): string => {
    const { h, s, l } = hsvaToHsla(hsva)
    return `hsl(${h}, ${s}%, ${l}%)`
}

export const hsvaToHslaString = (hsva: HsvaColor): string => {
    const { h, s, l, a } = hsvaToHsla(hsva)
    return `hsla(${h}, ${s}%, ${l}%, ${a})`
}

export const hsvaToRgba = ({ h, s, v, a }: HsvaColor): RgbaColor => {
    h = (h / 360) * 6
    s /= 100
    v /= 100

    const hh = Math.floor(h)
    const b = v * (1 - s)
    const c = v * (1 - (h - hh) * s)
    const d = v * (1 - (1 - h + hh) * s)
    const module = hh % 6

    return {
        r: round([v, c, b, b, d, v][module] * 255),
        g: round([d, v, v, c, b, b][module] * 255),
        b: round([b, b, d, v, v, c][module] * 255),
        a: round(a, 2)
    }
}

export const hsvaToRgbString = (hsva: HsvaColor): string => {
    const { r, g, b } = hsvaToRgba(hsva)
    return `rgb(${r}, ${g}, ${b})`
}

export const hsvaToRgbaString = (hsva: HsvaColor): string => {
    const { r, g, b, a } = hsvaToRgba(hsva)
    return `rgba(${r}, ${g}, ${b}, ${a})`
}

export const hsvaStringToHsva = (hsvString: string): HsvaColor => {
    const matcher =
        // eslint-disable-next-line unicorn/no-unsafe-regex
        /hsva?\(?\s*(-?\d*\.?\d+)(deg|rad|grad|turn)?[\s,]+(-?\d*\.?\d+)%?[\s,]+(-?\d*\.?\d+)%?,?\s*[\s/]*(-?\d*\.?\d+)?(%)?\s*\)?/i
    const match = matcher.exec(hsvString)

    if (!match) {
        return { h: 0, s: 0, v: 0, a: 1 }
    }

    return roundHsva({
        h: parseHue(match[1], match[2]),
        s: Number(match[3]),
        v: Number(match[4]),
        a: match[5] === undefined ? 1 : Number(match[5]) / (match[6] ? 100 : 1)
    })
}

export const hsvStringToHsva = hsvaStringToHsva

export const rgbaStringToHsva = (rgbaString: string): HsvaColor => {
    // eslint-disable-next-line unicorn/no-unsafe-regex
    const matcher = /rgba?\(?\s*(-?\d*\.?\d+)(%)?[\s,]+(-?\d*\.?\d+)(%)?[\s,]+(-?\d*\.?\d+)(%)?,?\s*[\s/]*(-?\d*\.?\d+)?(%)?\s*\)?/i
    const match = matcher.exec(rgbaString)

    if (!match) {
        return { h: 0, s: 0, v: 0, a: 1 }
    }

    return rgbaToHsva({
        r: Number(match[1]) / (match[2] ? 100 / 255 : 1),
        g: Number(match[3]) / (match[4] ? 100 / 255 : 1),
        b: Number(match[5]) / (match[6] ? 100 / 255 : 1),
        a: match[7] === undefined ? 1 : Number(match[7]) / (match[8] ? 100 : 1)
    })
}

const format = (number: number) => {
    const hex = number.toString(16)
    return hex.length < 2 ? `0${hex}` : hex
}

export const rgbaToHex = ({ r, g, b, a }: RgbaColor, withPrefix?: boolean): string => {
    const alphaHex = a < 1 ? format(round(a * 255)) : ''
    return `${withPrefix ? '#' : ''}${format(r)}${format(g)}${format(b)}${alphaHex}`
}

// export const rgbaToHsva = ({ r, g, b, a }: RgbaColor): HsvaColor => {
//     const max = Math.max(r, g, b)
//     const delta = max - Math.min(r, g, b)

//     // prettier-ignore
//     const hh = delta
//       ? max === r
//         ? (g - b) / delta
//         : max === g
//           ? 2 + (b - r) / delta
//           : 4 + (r - g) / delta
//       : 0;

//     return {
//         h: round(60 * (hh < 0 ? hh + 6 : hh)),
//         s: round(max ? (delta / max) * 100 : 0),
//         v: round((max / 255) * 100),
//         a
//     }
// }

export const rgbaToHsva = ({ r, g, b, a }: RgbaColor): HsvaColor => {
    // R, G, B values are divided by 255
    // to change the range from 0..255 to 0..1
    r /= 255
    g /= 255
    b /= 255

    // h, s, v = hue, saturation, value
    const cMax = Math.max(r, Math.max(g, b)) // maximum of r, g, b
    const cMin = Math.min(r, Math.min(g, b)) // minimum of r, g, b
    const diff = cMax - cMin
    let h = -1
    let s = -1

    // if cMax and cMax are equal then h = 0
    switch (cMax) {
        case cMin: {
            h = 0
            break
        }
        case r: {
            h = (60 * ((g - b) / diff) + 360) % 360
            break
        }
        case g: {
            h = (60 * ((b - r) / diff) + 120) % 360
            break
        }
        case b: {
            h = (60 * ((r - g) / diff) + 240) % 360
            break
        }
        // No default
    }

    // if cMax equal zero
    if (cMax === 0) {
        s = 0
    } else {
        s = (diff / cMax) * 100
    }

    // compute v
    const v = cMax * 100

    return {
        h: round(h),
        s: round(s),
        v: round(v),
        a: round(a, 2)
    }
}

export const roundHsva = (hsva: HsvaColor): HsvaColor => ({
    h: round(hsva.h),
    s: round(hsva.s),
    v: round(hsva.v),
    a: round(hsva.a, 2)
})

export const rgbaToRgb = ({ r, g, b }: RgbaColor): RgbColor => ({ r, g, b })

export const hslaToHsl = ({ h, s, l }: HslaColor): HslColor => ({ h, s, l })

export const hsvaToHsv = (hsva: HsvaColor): HsvColor => {
    const { h, s, v } = roundHsva(hsva)
    return { h, s, v }
}

export const hsvaToHex = (hsva: HsvaColor, withPrefix = true): string => rgbaToHex(hsvaToRgba(hsva), withPrefix)

export const hexToRgba = (hex: string): RgbaColor => {
    if (hex.startsWith('#')) {
        hex = hex.slice(1)
    }

    if (hex.length < 6) {
        return {
            r: Number.parseInt(hex[0] + hex[0], 16),
            g: Number.parseInt(hex[1] + hex[1], 16),
            b: Number.parseInt(hex[2] + hex[2], 16),
            a: hex.length === 4 ? round(Number.parseInt(hex[3] + hex[3], 16) / 255, 2) : 1
        }
    }

    return {
        r: Number.parseInt(hex.slice(0, 2), 16),
        g: Number.parseInt(hex.slice(2, 4), 16),
        b: Number.parseInt(hex.slice(4, 6), 16),
        a: hex.length === 8 ? round(Number.parseInt(hex.slice(6, 8), 16) / 255, 2) : 1
    }
}

export const hexToHsva = (hex: string): HsvaColor => rgbaToHsva(hexToRgba(hex))

/**
 * save recent color to local storage
 * @param color
 * @returns
 */
export const saveRecentColorsToStorage = (color: string) => {
    const recentColors = getRecentColorsFromStorage()
    if (recentColors.includes(color)) {
        recentColors.splice(recentColors.indexOf(color), 1)
    }
    const newColors = [color, ...recentColors].slice(0, 10)

    localStorage.setItem('color-picker-recent-colors', JSON.stringify(newColors))
    return newColors
}

/**
 * get recent color from local storage
 * @returns
 */
export const getRecentColorsFromStorage = (): string[] => {
    return JSON.parse(localStorage.getItem('color-picker-recent-colors') || '[]')
}
