import { flex, IconFont, Input, SegmentedControl, Select, singleTextEllipsis } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FilterWay } from '@lighthouse/core'
import { dateRangeVariableSystemNameMap, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Collapse, Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { CascadeFilter } from './CascadeFilter'
import { filterWayOptions, selectWayOptions } from './constants'
import { DateFilter } from './DateFilter'
import { NumberFilter } from './NumberFilter'
import { PersonFilter } from './PersonFilter'
import { SelectFilter } from './SelectFilter'
import { TextFilter } from './TextFilter'
import { TitleInput } from './TitleInput'

export const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
`

export const SCxContent = styled.div`
    margin: 8px;
`

export const SCxButtonContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--color-gray-200);
`
export const SCxHeader = styled.div`
    ${flex}
    padding: 10px 8px;
    justify-content: space-between;
    background-color: var(--color-gray-100);
`

export const SCxLeftFill = styled.div`
    /* width: 126px; */
    ${flex}
    align-items: center;
    overflow: hidden;
    flex: 1;
`

export const SCxHandle = styled.div`
    /* padding: 4px; */
    ${flex}
    align-items: center;
`

export const SCxTitle = styled.span`
    padding-left: 4px;
    line-height: 16px;
    color: var(--color-black);
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
    font-size: var(--font-size-normal);
    flex: 1;
    ${singleTextEllipsis}
`

interface FilterItemProps {
    appId: string
    pageId: string
    currentPageRecordId?: string
    blockId: string
    id: string
    opened: boolean
    prefixName: string
    onDelete: (id: string) => void
    onChangeOpen: (val: boolean) => void
}

const notFilterWayType = new Set([FilterWay.cascadeFilter, FilterWay.boolFilter])

export const FilterItem: React.FC<FilterItemProps> = ({ appId, pageId, currentPageRecordId, blockId, id, prefixName, opened, onDelete, onChangeOpen }) => {
    const { control, setValue, watch } = useFormContext()
    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id
    })
    const filterWay = useWatch({ control, name: `${prefixName}.filterWay` })
    const title = useWatch({ control, name: `${prefixName}.title` })



    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: active?.id === id ? 1 : 0
        }),
        [active?.id, id, transform, transition]
    )

    const configure = useMemo(() => {
        switch (filterWay) {
            case FilterWay.textFilter: {
                return <TextFilter prefixName={prefixName} />
            }
            case FilterWay.numberFilter: {
                return <NumberFilter prefixName={prefixName} />
            }
            case FilterWay.dateFilter: {
                return <DateFilter prefixName={prefixName} />
            }
            case FilterWay.personFilter: {
                return <PersonFilter prefixName={prefixName} />
            }
            case FilterWay.selectFilter: {
                return <SelectFilter prefixName={prefixName} />
            }
            case FilterWay.boolFilter: {
                return null
            }
            default: {
                return <CascadeFilter prefixName={prefixName} />
            }
        }
    }, [filterWay, prefixName])

    return (
        <SCxButtonContainer {...attributes} style={style} ref={setNodeRef}>
            <SCxHeader>
                <SCxLeftFill>
                    <SCxHandle {...listeners}>
                        <IconFont size={16} type="DotsSix" color="var(--color-gray-500)" style={{ cursor: 'grab' }} />
                    </SCxHandle>
                    <SCxTitle> {title}</SCxTitle>
                </SCxLeftFill>
                <SCxRightFill>
                    <IconFont size={16} color="var(--color-gray-500)" type="Trash" onClick={() => onDelete?.(id)} />
                    <IconFont
                        size={16}
                        color="var(--color-gray-500)"
                        type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                        onClick={() => onChangeOpen(!opened)}
                    />
                </SCxRightFill>
            </SCxHeader>
            <Collapse in={opened}>
                <SCxContent>
                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                        <Text>标题</Text>
                        <Controller
                            name={`${prefixName}.title`}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TitleInput style={{ width: 180 }} defaultValue={field.value} onBlur={val => field.onChange(val)} />
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                        <Text>筛选方式</Text>
                        <Controller
                            control={control}
                            name={`${prefixName}.filterWay`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Select
                                        style={{ width: 180 }}
                                        size="lg"
                                        value={value}
                                        onChange={v => {
                                            onChange(v)
                                            if (v === FilterWay.dateFilter) {
                                                setValue(`${prefixName}.dateVariables`, Object.keys(dateRangeVariableSystemNameMap))
                                            }
                                        }}
                                        options={filterWayOptions}
                                    />
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                    {!notFilterWayType.has(filterWay) && (
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <Text>选择方式</Text>
                            <Controller
                                control={control}
                                name={`${prefixName}.selectWay`}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <SegmentedControl
                                            fullWidth
                                            style={{ width: 180 }}
                                            size="md"
                                            value={value}
                                            onChange={onChange}
                                            data={selectWayOptions}
                                        />
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                    )}

                    {configure}
                </SCxContent>
            </Collapse>
        </SCxButtonContainer>
    )
}
