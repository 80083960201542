import type { LoginProtocol, SpaceUser } from '@lighthouse/core'
import { atom } from 'jotai'

import * as srv from '@/services'

import { INITIAL_USER_DATA } from './constants'
import { refreshTokenAtom, userDataAtom } from './state'
import type { ConsummateInfoAtomPayload } from './types'

/** 登录 */
export const loginAtom = atom(null, (_, set, payload: LoginProtocol) => {
    return srv.login(payload)
})

/** 完善信息 */
export const consummateInfoAtom = atom(null, async (_, set, payload: ConsummateInfoAtomPayload) => {
    const { username, password, spaceName, spaceIcon } = payload
    const isUpdate = await srv.consummateInfo(username, password, spaceName, spaceIcon)
    if (isUpdate) {
        set(userDataAtom, draft => {
            draft.username = username
        })
    }
    return isUpdate
})

/** 获取用户信息 */
export const fetchUserAtom = atom(null, async (_, set) => {
    const userData = await srv.getUser()
    if (userData) {
        set(userDataAtom, userData)
        return userData
    }
})

/** 更新用户信息 */
export const updateUserAtom = atom(null, (_, set, payload: Partial<SpaceUser>) => {
    set(userDataAtom, draft => {
        if (draft) {
            Object.entries(payload).forEach(([k, v]) => {
                Reflect.set(draft, k, v)
            })
        }
    })
})

/** 登出 */
export const logoutAtom = atom(null, async (_, set) => {
    await srv.logout()
    set(userDataAtom, INITIAL_USER_DATA)
})
