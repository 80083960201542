import { Box } from '@byecode/ui/components/Box'
import { DatePicker } from '@byecode/ui/components/DatePicker'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Select } from '@byecode/ui/components/Select'
import type { ColumnType } from '@byecode/ui/components/Table'
import { Table } from '@byecode/ui/components/Table'
import { Text } from '@byecode/ui/components/Text'
import type { TreeNode } from '@byecode/ui/components/Tree'
import { DataSourceType } from '@lighthouse/core'
import { spaceVersionEnum } from '@lighthouse/shared'
import { lightFormat, subDays } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { useAsyncRetry, useDebounce } from 'react-use'
import styled from 'styled-components'

import { VersionOverlay } from '@/components/SpaceGrade'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { usePageList } from '@/hooks/usePage'
import type { AnalysisPageVisitsRes } from '@/services'
import * as srv from '@/services'

import { Card } from '../components'
import type { DateType } from '../components/Condition'
import { DATE_COMPARE_OPTIONS, DATE_TYPE, IS_DATE_PICKER, resetTime2Last, resetTime2Zero } from '../components/Condition'
import { TreeSelect } from './TreeSelect'

const PlaceHolder = styled.div`
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    border-radius: 3px;
    background-color: var(--color-gray-100);
`
const Image = styled.img`
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    border-radius: 3px;
`

const Title = styled.div`
    font-size: 14px;
    line-height: 22px;
`
const Description = styled.div`
    font-size: 12px;
    color: var(--color-gray-600);
`

const Link = styled.a`
    color: var(--color-main);
    line-height: 1;
`

type ItemType = AnalysisPageVisitsRes['list'][number]

const COLUMNS: ColumnType<ItemType>[] = [
    {
        title: '页面',
        key: 'pageUrl',
        formatter(_, record) {
            return (
                <Flex alignItems="center" gap="8px">
                    {record.image ? <Image src={record.image} alt="" /> : <PlaceHolder />}
                    <Box>
                        <Flex alignItems="center" gap="4px">
                            <Title>{record.title}</Title>
                            <Link href={record.pageUrl} target="_blank">
                                <IconFont type="ArrowSquareIn" />
                            </Link>
                        </Flex>
                        <Description>{record.description}</Description>
                    </Box>
                </Flex>
            )
        }
    },
    {
        title: '平均访问时长',
        key: 'avgDuration',
        align: 'right',
        sorter: (a, b) => a.avgDuration - b.avgDuration
    },
    {
        title: '平均访问深度',
        key: 'avgDepth',
        align: 'right',
        sorter: (a, b) => a.avgDepth - b.avgDepth
    },
    {
        title: '独立访客',
        key: 'uv',
        align: 'right',
        sorter: (a, b) => a.uv - b.uv
    },
    {
        title: '来访次数',
        key: 'pv',
        align: 'right',
        sorter: (a, b) => a.pv - b.pv
    },
    {
        title: '跳出率',
        key: 'bounceRate',
        align: 'right',
        sorter: (a, b) => a.bounceRate - b.bounceRate,
        formatter(v) {
            return `${Number(v) * 100}%`
        }
    }
]

const PAGE_SIZE = 10
const FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const PageVisits = () => {
    const [searchWords, setSearchWords] = useState<string>()
    const [debounceSearchWords, setDebounceSearchWords] = useState<string>()

    const [dateType, setDateType] = useState<DateType>(DATE_TYPE.last7)

    const [queryParams, setQueryParams] = useState(() => {
        const start = subDays(new Date(), 7)
        resetTime2Zero(start)
        const end = subDays(new Date(), 1)
        resetTime2Last(end)

        return { pageNum: 1, start, end, pageIds: [] as string[] }
    })

    const onDateTypeChange = useCallback(
        (v: DateType) => {
            switch (v) {
                case DATE_TYPE.yesterday: {
                    const start = subDays(new Date(), 1)
                    resetTime2Zero(start)
                    const end = subDays(new Date(), 1)
                    resetTime2Last(end)
                    setQueryParams(s => ({
                        ...s,
                        start,
                        end,
                        pageNum: 1
                    }))

                    break
                }

                case DATE_TYPE.last7: {
                    const start = subDays(new Date(), 7)
                    resetTime2Zero(start)
                    const end = subDays(new Date(), 1)
                    resetTime2Last(end)
                    setQueryParams(s => ({ ...s, start, end, pageNum: 1 }))

                    break
                }

                case DATE_TYPE.last30: {
                    const start = subDays(new Date(), 30)
                    resetTime2Zero(start)
                    const end = subDays(new Date(), 1)
                    resetTime2Last(end)
                    setQueryParams(s => ({ ...s, start, end, pageNum: 1 }))

                    break
                }

                case DATE_TYPE.day: {
                    const end = new Date(queryParams.start)
                    resetTime2Last(end)
                    setQueryParams(s => ({ ...s, end, pageNum: 1 }))

                    break
                }

                default: {
                    break
                }
            }

            setDateType(v)
        },
        [queryParams.start]
    )

    useDebounce(
        () => {
            setDebounceSearchWords(searchWords)
        },
        300,
        [searchWords]
    )

    const res = useAsyncRetry(
        () =>
            srv.fetchAnalysisVisit({
                pageNum: queryParams.pageNum,
                pageSize: PAGE_SIZE,
                startTime: lightFormat(queryParams.start, FORMAT),
                endTime: lightFormat(queryParams.end, FORMAT),
                pageIds: queryParams.pageIds
            }),
        [queryParams.pageNum, queryParams.pageIds, queryParams.start, queryParams.end]
    )

    const filterData = useMemo(() => {
        if (!res.value) {
            return
        }

        if (!debounceSearchWords) {
            return res.value.list
        }

        const hasWords = (r: ItemType) => r.title?.toLocaleLowerCase().includes(debounceSearchWords.toLocaleLowerCase().trim())

        return res.value.list.filter(hasWords)
    }, [res.value, debounceSearchWords])

    return (
        <VersionOverlay spaceVersion={spaceVersionEnum.STANDARD}>
            <Card title="页面访问" loading={res.loading && !res.value}>
                <Table
                    data={filterData}
                    columns={COLUMNS}
                    rowKey="pageUrl"
                    loading={res.loading && !!res.value}
                    filter={
                        <Flex gap={40}>
                            <Flex alignItems="center">
                                <Text color="var(--color-gray-400)" size={14} style={{ width: 80 }}>
                                    页面筛选
                                </Text>
                                <TreeSelect
                                    value={queryParams.pageIds}
                                    onChange={v => setQueryParams(s => ({ ...s, pageIds: v, pageNum: 1 }))}
                                />
                            </Flex>
                            <Flex alignItems="center">
                                <Text color="var(--color-gray-400)" size={14} style={{ width: 80 }}>
                                    数据时间
                                </Text>
                                <Flex gap="12px">
                                    <Select
                                        style={{ width: 116 }}
                                        options={DATE_COMPARE_OPTIONS}
                                        value={dateType}
                                        onChange={v => onDateTypeChange(v as DateType)}
                                    />
                                    {IS_DATE_PICKER.has(dateType) ? (
                                        <>
                                            <DatePicker
                                                showTime={false}
                                                disableInput
                                                prefix={null}
                                                style={{ width: 116 }}
                                                format="yyyy-MM-dd"
                                                value={queryParams.start}
                                                clearable={false}
                                                onChange={v => {
                                                    if (!v) {
                                                        return
                                                    }

                                                    const start = new Date(v)
                                                    resetTime2Zero(start)
                                                    const end = dateType === DATE_TYPE.dayRange ? queryParams.end : new Date(v)
                                                    resetTime2Last(end)
                                                    setQueryParams(s => ({ ...s, start, end, pageNum: 1 }))
                                                }}
                                            />
                                            {dateType === DATE_TYPE.dayRange ? (
                                                <DatePicker
                                                    showTime={false}
                                                    disableInput
                                                    prefix={null}
                                                    style={{ width: 116 }}
                                                    format="yyyy-MM-dd"
                                                    value={queryParams.end}
                                                    onChange={v => {
                                                        if (!v) {
                                                            return
                                                        }
                                                        setQueryParams(s => ({ ...s, end: v, pageNum: 1 }))
                                                    }}
                                                />
                                            ) : null}
                                        </>
                                    ) : null}
                                </Flex>
                            </Flex>
                        </Flex>
                    }
                    search
                    onSearchValueChange={e => setSearchWords(e.target.value)}
                    pagination={{
                        pageNum: queryParams.pageNum,
                        pageSize: PAGE_SIZE,
                        total: res.value?.total,
                        onChange: v => setQueryParams(s => ({ ...s, pageNum: v }))
                    }}
                />
            </Card>
        </VersionOverlay>
    )
}
