import { Box, SegmentedControl, usePopoverContext } from '@byecode/ui'
import { mergeRefs } from '@byecode/ui/hooks/useMergedRef'
import { useContextPopoverHeight } from '@byecode/ui/hooks/usePopoverHeight'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import type { UploadyProps } from '@rpldy/uploady'
import Uploady from '@rpldy/uploady'
import React, { useMemo, useState } from 'react'

import { IconPickerList } from '../IconList'
import { useStyles } from '../IconPicker.styles'
import type { BaseChange, IconPickerStylesNames, IconType } from '../IconPicker.types'
import { IconUpload } from '../IconUpload'

interface IconDropDownProps extends StyleComponentProps<IconPickerStylesNames>, BaseChange {
    uploadParams?: UploadyProps
    disableTagToggle?: boolean
    searchable?: boolean
    allowIconTypes?: IconType[]
    onClose: () => void
}
const options: { label: string; value: IconType }[] = [
    { label: '图标', value: 'icon' },
    { label: '图片', value: 'upload' }
]

const DEFAULT_ALLOW_ICON_TYPES: IconType[] = ['icon', 'upload']

export const IconDropDown = React.forwardRef<HTMLDivElement, IconDropDownProps>(
    ({ searchable, value, uploadParams, allowIconTypes = DEFAULT_ALLOW_ICON_TYPES, disableTagToggle, styles, onChange, onGetFullUrl, onClose }, ref) => {
        const { context } = usePopoverContext()
        const [maxHeight, internalRef] = useContextPopoverHeight({ context, initMaxHeight: 600 })

        const [tab, setTab] = useState(allowIconTypes[0])

        const { classes } = useStyles({}, { name: 'IconPicker', styles })

        const dropDownEle = useMemo(() => {
            switch (tab) {
                case 'icon': {
                    return <IconPickerList searchable={searchable} onClose={onClose} onChange={onChange} value={value} />
                }
                case 'upload': {
                    return (
                        <Uploady accept="image/*" {...uploadParams}>
                            <IconUpload onClose={onClose} uploadParams={uploadParams} value={value} onChange={onChange} onGetFullUrl={onGetFullUrl} />
                        </Uploady>
                    )
                }
                default: {
                    return null
                }
            }
        }, [onChange, onClose, onGetFullUrl, searchable, tab, uploadParams, value])

        return (
            <Box className={classes.dropDown} ref={mergeRefs(internalRef, ref)} style={{ maxHeight }}>
                {!disableTagToggle && allowIconTypes.length !== 1 && (
                    <SegmentedControl data={options} fullWidth value={tab} size="lg" onChange={t => setTab(t as IconType)} />
                )}
                {dropDownEle}
            </Box>
        )
    }
)
