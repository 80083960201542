import { Toast } from '@byecode/ui'
import type { ErrorCode } from '@lighthouse/shared'
import { errorCodeMap, limitExceededCodeList } from '@lighthouse/shared'
import type { IsSuccessfulCall } from '@rpldy/shared'
import type { UploadyProps } from '@rpldy/shared-ui'

import { openSpaceGrade } from '@/components/SpaceGrade'
import { QuotaMessage } from '@/components/SpaceQuota/QuotaMessage'
import { ERROR_CODE_QUOTA_KEY_MAP } from '@/components/SpaceQuota/QuotaMessage/constants'
import http from '@/http'

/** 维格表 -> 用户反馈表接口地址 */
const VIKA_API_BASE = 'https://api.vika.cn'
const VIKA_DATA_SHEET_FEEDBACK = 'dsttKm4eFMrnNdHSrz'
export const VIKA_API_PREFIX = `${VIKA_API_BASE}/fusion/v1/datasheets/${VIKA_DATA_SHEET_FEEDBACK}` as const
export const VIKA_API_TOKEN = 'uskmB6oRi3LBj4zEvizfOAo'

export const isSuccessfulCallToToast: IsSuccessfulCall = xhr => {
    if (xhr.status === 200) {
        const res = JSON.parse(xhr.response)
        const { code } = res
        if (code === '0030000') {
            return false
        }

        if (Object.hasOwn(ERROR_CODE_QUOTA_KEY_MAP, code)) {
            QuotaMessage({
                type: ERROR_CODE_QUOTA_KEY_MAP[code]
            })
            return false
        }

        if (limitExceededCodeList.includes(code)) {
            Toast.error(`${errorCodeMap[code as ErrorCode]}，`, {
                linkText: '请升级版本',
                onLinkClick: () => openSpaceGrade('version_upgrade')
            })
            return false
        }
    }
    return true
}

export const uploadAuth = (): Pick<UploadyProps, 'params' | 'headers'> => {
    return {
        headers: http.defaults.headers.common
    }
}

/** 上传管理器使用 */
export const uploadManagerInAppParams = (): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadInApp', ...uploadAuth() },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}


// /** 上传视频管理器使用 在数据源中 */
// export const uploadManagerInAppParams = (): UploadyProps => {
//     return {
//         destination: { url: '/lighthouse/api/v1/oss/uploadVod', ...uploadAuth() },
//         isSuccessfulCall: isSuccessfulCallToToast
//     }
// }

export const uploadVikaParams = (params?: Record<string, unknown>): UploadyProps => {
    return {
        isSuccessfulCall: xhr => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const res = JSON.parse(xhr.responseText)
                return res.success
            }
            return false
        },
        destination: {
            url: `${VIKA_API_PREFIX}/attachments`,
            headers: {
                Authorization: `Bearer ${VIKA_API_TOKEN}`
            }
        }
    }
}

export const uploadInitApplication = (params?: Record<string, unknown>): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/app/createAppByExcel', method: 'PUT', headers: http.defaults.headers.common, params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

// 上传到公共资源
export const uploadPublicParams = (params?: Record<string, unknown>): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadPublicResource', ...uploadAuth(), params }
    }
}
