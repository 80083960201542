import { Breadcrumbs, Divider, Flex, Group, IconFont } from '@byecode/ui'
import type { DataSourceAbstract, ExportProtocol, ViewField } from '@lighthouse/core'
import { FieldSettingConfigure, ListItem4ByecodeUi } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'

import { InnerDrawer } from '../../InnerDrawer'
import { listCommonStyles } from './constants'
import * as SC from './styles'

interface AllowExportConfiguratorProps {
    value: ExportProtocol
    dsId: string
    columns: ViewField[]
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    onChange?: (v: ExportProtocol) => void
    onBack?: () => void
}
interface AllowExportSettingProps {
    title: string
    type: string
    value: ExportProtocol
    dsId: string
    columns: ViewField[]
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    onChange?: (v: ExportProtocol) => void
}

const AllowExportConfigurator: React.FC<AllowExportConfiguratorProps> = ({
    value,
    dsId,
    columns,
    dataSource,
    dataSourceList,
    onChange,
    onBack
}) => {
    const { canExport = false } = value
    const { control } = useFormContext()
    const canExportColumns = columns

    const handleChange = useCallback(
        (params: Partial<ExportProtocol>) => {
            onChange?.({ ...value, ...params })
        },
        [onChange, value]
    )

    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '导出', onClick: onBack }]} />
            <Divider color="var(--color-gray-200)" />
            <Group
                mode="switch"
                label={canExport ? '已开启' : '已关闭'}
                opened={canExport}
                onCollapseChange={v => handleChange({ canExport: v })}
                collapseProps={{ style: { padding: 0 } }}
            >
                <Controller
                    name="exportFields"
                    control={control}
                    render={({ field }) => (
                        <FieldSettingConfigure
                            title="允许用户导出的字段："
                            columns={canExportColumns}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </Group>
        </InnerDrawer>
    )
}

export const AllowExportSetting: FC<AllowExportSettingProps> = ({ title, type, value, dsId, onChange, ...rest }) => {
    const { canExport } = value
    const [open, toggle] = useToggle(false)
    return (
        <>
            <ListItem4ByecodeUi
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <SC.StyledIconWrapper active={canExport}>
                        <IconFont type={type} size={18} fill={canExport ? 'var(--color-main)' : 'var(--color-gray-400)'} />
                    </SC.StyledIconWrapper>
                    <div>{title}</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{canExport ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>
            {open && <AllowExportConfigurator value={value} dsId={dsId} {...rest} onChange={onChange} onBack={() => toggle(false)} />}
        </>
    )
}
