import { IconFont } from '@byecode/ui'
import { type UsedWorkflow, nodeTypeIconMap, nodeTypeOptions } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { workflowLabelMap } from '@/constants/flow'

const SCxContainer = styled.div`
    width: 100%;
    height: 64px;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
`

const SCxInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const SCxTitle = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

const SCxDescription = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

const SCxIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxIconNode = styled.div`
    height: 32px;
    width: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

const SCxLinkIconWrapper = styled.div<{ radius: number }>`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ radius }) => `${radius}px`};
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    cursor: pointer;
    color: var(--color-gray-400);

    &:hover {
        background-color: var(--color-gray-100);
        color: var(--color-black);
    }
`

const SCxLinkIcon = styled(IconFont)``

const SCxIcon = styled(IconFont)``

interface LocationUsedItemProps {
    data: UsedWorkflow
    onLink?: (flowId: string) => void
}

export const LocationUsedItem: React.FC<LocationUsedItemProps> = ({ data, onLink }) => {
    const { workflowId, workflowType, workflowName, nodeName, nodeType } = data
    const nodeTypeIcon = nodeTypeIconMap[nodeType]
    const flowTypeText = workflowLabelMap[workflowType].label
    const description = `${flowTypeText}：${workflowName}`
    const nodeOption = find(option => option.value === nodeType, nodeTypeOptions)

    const iconContent = useMemo(() => {
        return (
            <SCxIconNode>
                <SCxIcon type={nodeTypeIcon} size={18} fill={nodeOption?.color} />
            </SCxIconNode>
        )
    }, [nodeOption?.color, nodeTypeIcon])

    return (
        <SCxContainer>
            <SCxContent>
                <SCxIconWrapper>{iconContent}</SCxIconWrapper>
                <SCxInfo>
                    <SCxTitle>{nodeName}</SCxTitle>
                    {description && <SCxDescription>{description}</SCxDescription>}
                </SCxInfo>
            </SCxContent>
            <SCxLinkIconWrapper radius={14} onClick={() => onLink?.(workflowId)}>
                <SCxLinkIcon type="BlockLinkToPage" size={16} />
            </SCxLinkIconWrapper>
        </SCxContainer>
    )
}
