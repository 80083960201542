import { Divider, Radio, Select } from '@byecode/ui'
import type { NodeTypes } from '@lighthouse/shared'
import { type FlowNode, CollapseBox, LoopErrorHandlerEnum, LoopTypeEnum, loopTypeMap } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import * as SC from '../../styles'
import { RecordsConfig } from './RecordsConfig'
import { SelectRecord } from './SelectRecord'
import { TimesConfig } from './TimesConfig'

interface LoopNodeActionConfigureProps {
    allParentNodes: FlowNode[]
}

export const LoopNodeActionConfigure: React.FC<LoopNodeActionConfigureProps> = ({ allParentNodes }) => {
    const { control } = useFormContext()
    const loopType = useWatch({ control, name: 'config.loopType' })
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const dataSourceList = useDataSourceList(appId, envId)

    return (
        <SC.Container>
            <CollapseBox label="循环方式">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>选择循环方式</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.loopType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        value={field.value}
                                        options={loopTypeMap}
                                        onChange={val => {
                                            field.onChange?.(val as NodeTypes)
                                        }}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {loopType === LoopTypeEnum.BASE_ON_RECORDS && <SelectRecord allParentNodes={allParentNodes} />}
                </SC.Content>
            </CollapseBox>
            {loopType === LoopTypeEnum.BASE_ON_RECORDS ? (
                <RecordsConfig allParentNodes={allParentNodes} />
            ) : (
                <TimesConfig dataSourceList={dataSourceList} allParentNodes={allParentNodes} />
            )}
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="循环中的子流程出现错误时">
                <SC.Content>
                    <Controller
                        name="config.loopErrorHandler"
                        control={control}
                        render={({ field }) => (
                            <Radio.Group
                                name="config.loopErrorHandler"
                                value={field.value}
                                style={{ flexDirection: 'column', gap: 0 }}
                                onChange={v => {
                                    field.onChange(v)
                                }}
                            >
                                <Radio size="sm" label="跳过并进入下一次循环" value={LoopErrorHandlerEnum.CONTINUE} />
                                <Radio size="sm" label="结束循环，继续后面的流程" value={LoopErrorHandlerEnum.BREAK} />
                                <Radio size="sm" label="结束循环，不继续后面的流程" value={LoopErrorHandlerEnum.RETURN} />
                            </Radio.Group>
                        )}
                    />
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
