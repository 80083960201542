import { Button, Flex, IconFont, Popover, Text } from '@byecode/ui'
import {
    DEFAULT_SPACE_VERSION,
    defaultZhDateFormat,
    getBrand,
    spaceGradeIconMap,
    spaceGradeNameMap,
    spaceVersionEnum
} from '@lighthouse/shared'
import { add, isAfter, lightFormat } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { openSaleSpaceGrade, openSpaceGrade } from '@/components/SpaceGrade'
import { spaceThemeMap } from '@/constants/common'
import { useSpaceQuota } from '@/shared/reusable'

import { dosageList, spaceGradeButtonBackgroundMap, spaceGradeFontColorMap } from '../constants'
import { SpaceQuotaSlider } from '../Slider'
import { SliderLabel } from '../Slider/SliderLabel'

interface PopoverQuotaProps {}

const SCxContainer = styled.div`
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
`

const SCxContent = styled.div`
    width: 100%;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const SCxHeader = styled.div`
    width: 100%;
    height: 104px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: var(--color-gray-200);
    border-bottom: 1px solid var(--color-theme-3);
`

export const PopoverQuota: React.FunctionComponent<PopoverQuotaProps> = props => {
    const { data, update } = useSpaceQuota()

    const [opened, setOpened] = useState(false)

    const theme = spaceThemeMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]
    const { color, background } = theme

    useEffect(() => {
        if (opened) {
            update()
        }
    }, [opened, update])

    const isExpiringSoon = useMemo(() => {
        if (!data || data.currentVersionCode === 0) {
            return
        }

        return isAfter(add(Date.now(), { days: 7 }), data.expiredAt)
    }, [data])
    const isExceededLimit = useMemo(
        () =>
            dosageList.some(({ id }) => {
                if (id === 'numberOfPublishedApps') {
                    return false
                }
                const { usage, quota } = data?.[`${id}`] ?? { usage: 0, quota: 0 }
                // quota 为 -1 表示无限制，为 0 表示无配额
                if (quota === -1 || quota === 0) {
                    return false
                }
                return usage >= quota
            }),
        [data]
    )

    const borderColor = useMemo(() => {
        switch (data?.currentVersionCode) {
            case 0: {
                return 'var(--color-gray-200)'
            }
            case 1:
            case 2:
            case 3: {
                return 'var(--color-theme-4)'
            }

            case 4: {
                return 'var(--color-blue-200)'
            }
            case 5: {
                return '#FEDF89'
            }
            default: {
                return 'var(--color-gray-200)'
            }
        }
    }, [data?.currentVersionCode])

    const dateEle = useMemo(() => {
        if (!data) {
            return
        }
        if (data.expiredAt === -1) {
            return '永久'
        }
        const currentDate = Date.now()
        if (data.expiredAt < currentDate) {
            const expiredDate = Math.round((currentDate - data.expiredAt) / 1000 / 3600 / 24)
            return (
                <Text size={12}>
                    {lightFormat(data.expiredAt, defaultZhDateFormat)} （已过期 {expiredDate} 天）
                </Text>
            )
        }
        return (
            <Text size={12}>
                {lightFormat(data.expiredAt, defaultZhDateFormat)} （剩余 {Math.round((data.expiredAt - currentDate) / 1000 / 3600 / 24)}{' '}
                天）
            </Text>
        )
    }, [data])

    return (
        <Popover width={360} opened={opened} position="bottom-start" trigger="hover" zIndex={200} withinPortal onChange={setOpened}>
            <Popover.Target>
                {isExceededLimit ? (
                    <Button
                        size="sm"
                        style={{
                            backgroundColor: 'var(--color-yellow-100)',
                            color: 'var(--color-yellow-600)',
                            borderStyle: 'none',
                            height: 28
                        }}
                        icon={<IconFont type="Warning" size={16} color="var(--color-yellow-400)" />}
                    >
                        {isExpiringSoon ? '即将到期' : '达到用量限制'}
                    </Button>
                ) : (
                    <Button
                        icon={
                            <IconFont
                                type={spaceGradeIconMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]}
                                color={spaceGradeFontColorMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]}
                                size={16}
                            />
                        }
                        type={opened ? 'default' : 'text'}
                        style={{
                            borderStyle: 'none',
                            background: spaceGradeButtonBackgroundMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION],
                            color: spaceGradeFontColorMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]
                        }}
                    >
                        {isExpiringSoon ? '即将到期' : spaceGradeNameMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]}
                    </Button>
                )}
            </Popover.Target>
            <Popover.Dropdown compact>
                <SCxContainer>
                    <SCxHeader style={{ background, backgroundSize: '100% 100%', borderColor }}>
                        {/* <SCxBackgroundImage
                            src={image}
                            bgOpacity={data?.currentVersionCode === 0 ? '70%' : '14%'}
                        > */}
                        <Flex direction="column" gap={12} style={{ padding: 20 }}>
                            <Flex alignItems="center" justifyContent="space-between">
                                <Button
                                    type={opened ? 'default' : 'text'}
                                    style={{
                                        borderStyle: 'none',
                                        backgroundImage:
                                            (data?.currentVersionCode ?? 0) < spaceVersionEnum.ENTERPRISE
                                                ? 'linear-gradient(274.6deg, #FFE3BA 4.82%, #FDEAD7 95.05%)'
                                                : 'linear-gradient(274.6deg, #1E1C17 4.82%, #342D25 95.05%)',
                                        color: (data?.currentVersionCode ?? 0) < spaceVersionEnum.ENTERPRISE ? '#6A3119' : '#FFE7B7'
                                    }}
                                    icon={
                                        <IconFont
                                            type="CrownStar"
                                            size={16}
                                            color={(data?.currentVersionCode ?? 0) < spaceVersionEnum.ENTERPRISE ? '#6A3119' : '#FFE7B7'}
                                        />
                                    }
                                    onClick={() => {
                                        const brand = getBrand()
                                        brand === 'I_CLICK' ? openSaleSpaceGrade() : openSpaceGrade('version_upgrade')
                                    }}
                                >
                                    {(data?.currentVersionCode ?? 0) < spaceVersionEnum.ENTERPRISE ? '升级版本' : '版本续费'}
                                </Button>
                                <Text
                                    size={16}
                                    style={{ lineHeight: '24px' }}
                                    color={spaceGradeFontColorMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]}
                                >
                                    {spaceGradeNameMap[data?.currentVersion ?? DEFAULT_SPACE_VERSION]}
                                </Text>
                            </Flex>

                            <Flex style={{ gap: 4, lineHeight: '20px', fontSize: 12 }}>
                                <Text size={12} color="var(--color-gray-500)">
                                    到期时间:
                                </Text>
                                <Text color="var(--color-black)">{dateEle}</Text>
                            </Flex>
                        </Flex>
                        {/* </SCxBackgroundImage> */}
                    </SCxHeader>
                    <SCxContent>
                        {dosageList.map(({ icon, label, toolTipLabel, id, warningColor, renderQuotaLabel, renderUsageLabel }) => {
                            const { usage, quota } = data?.[`${id}`] ?? { usage: 0, quota: 0 }
                            if (quota <= 0) {
                                return null
                            }
                            return (
                                <SpaceQuotaSlider
                                    key={id}
                                    data={{
                                        icon,
                                        label: (
                                            <SliderLabel
                                                label={label}
                                                toolTipLabel={toolTipLabel}
                                                usageLabel={renderUsageLabel(usage)}
                                                primaryColor={usage < quota ? 'var(--color-gray-500)' : 'var(--color-red-500)'}
                                                quotaLabel={renderQuotaLabel(usage, quota)}
                                            />
                                        ),
                                        usage,
                                        quota,
                                        primaryColor: 'var(--color-gray-600)'
                                    }}
                                />
                            )
                        })}
                    </SCxContent>
                </SCxContainer>
            </Popover.Dropdown>
        </Popover>
    )
}
