import React, { useEffect, useMemo, useRef, useState } from 'react'

import * as SC from './styles'
import { getHsvColorH, hsvaToHslaString } from './utils'

export interface ColorBoardCanvasProps {
    h: number
    s: number
    v: number
    onColorChange: (x: number, y: number, confirmed?: boolean) => void
}

export const ColorBoardCanvas: React.FC<ColorBoardCanvasProps> = ({ h, s, v, onColorChange }) => {
    const [isMoving, setIsMoving] = useState(false)
    const newXy = useRef({ x: 0, y: 0 })

    const colorBoardTranslateStyle = useMemo(() => ({ transform: `translate(${s - 2}px, ${v - 2}px)` }), [v, s])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onColorChange(e.nativeEvent.offsetX, e.nativeEvent.offsetY, true)
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsMoving(true)
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isMoving) {
            onColorChange(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
            newXy.current = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        }
    }

    useEffect(() => {
        const mouseUpHandler = () => {
            if (isMoving) {
                setIsMoving(false)
                onColorChange(newXy.current.x, newXy.current.y, true)
            }
        }
        document.addEventListener('mouseup', mouseUpHandler)

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler)
        }
    }, [isMoving, onColorChange])

    return (
        <SC.ColorPickerColorBoard>
            <SC.ColorPickerColorBoardCanvas
                style={{ backgroundColor: hsvaToHslaString({ h: getHsvColorH(h), s: 100, v: 100, a: 1 }) }}
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
            />
            <SC.ColorPickerColorBoardBar style={colorBoardTranslateStyle} />
        </SC.ColorPickerColorBoard>
    )
}
