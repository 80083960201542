import { Breadcrumbs } from '@mantine/core'
import * as React from 'react'
import { useMemo } from 'react'
import { Link, useLocation, useMatches, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import * as CM from '../styles'
import type { Route } from '../types'

interface TemplateBreadcrumbsProps {
    routes: Route[]

    onBack: () => void
}

const SCXContainer = styled.div`
    width: 100%;
`

const SCXLink = styled.div<{ color?: string }>`
    font-weight: 400;
    font-size: var(--font-size-normal);
    cursor: pointer;
    color: ${({ color }) => color ?? 'var(--color-black)'};
`

const TemplateBreadcrumbs: React.FunctionComponent<TemplateBreadcrumbsProps> = ({ routes, onBack }) => {
    return (
        <SCXContainer>
            <Breadcrumbs
                styles={{
                    separator: {
                        margin: '0 4px'
                    }
                }}
                separator={<CM.Icon type="ArrowRightSmall" color='var(--color-gray-400)' />}
            >
                {routes.map(({ id, name }, index) => (
                    // onClick={() => }
                    <SCXLink
                        key={id}
                        onClick={() => index === 0 && onBack()}
                        color={index === routes.length - 1 ? 'var(--color-black)' : 'var(--color-gray-400)'}
                    >
                        {name}
                    </SCXLink>
                ))}
            </Breadcrumbs>
        </SCXContainer>
    )
}

export default TemplateBreadcrumbs
