import { Message, Toast } from '@byecode/ui'
import { useCustomViewBlockContext } from '@lighthouse/block'
import type { ButtonAction, ButtonItem, RecordLikeProtocol } from '@lighthouse/core'
import type { IClickActionConfig, UpdateRecordActionConfig } from '@lighthouse/shared'
import {
    ANONYMOUS,
    blockListenerSystem,
    commonPages,
    downloadFile,
    dummyTriggerNodeId,
    generateAdtValue,
    generateVariableValue,
    getActiveAddIClickUserVariableValue,
    getCreateRecordVariableValue,
    getMainTableRecordId,
    getUpdateRecordVariableValue,
    openNewBrowserPage,
    PAGE_CONTAINER_HOST,
    pageStackPubSub,
    resolveDownloadFileUrls,
    resolveFilter,
    resolveVariable,
    scroll2FlowNode,
    SYSTEM_ROLE,
    useAtomData,
    validateFilterFieldData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import copyToClipboard from 'copy-to-clipboard'
import { useAtomCallback } from 'jotai/utils'
import { find } from 'rambda'
import { useCallback } from 'react'

import { actionWorker } from '@/actionsEngine'
import { appRoleAtom } from '@/atoms/application/state'
import { recordAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { workflowListAtom } from '@/atoms/workflow/state'
import { useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'

import { useActionRuntimeParams } from './useActionRuntimeParams'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from './useApplication'
import { useDataSourceList, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'
import { usePageURI } from './usePageURI'
import { useUserRecord } from './useUserRecord'

export const useActionTrigger = (list?: ButtonItem[]) => {
    const workflows = useAtomData(workflowListAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const userRecord = useUserRecord()
    const { record: customViewRecord } = useCustomViewBlockContext()
    const previewType = usePreviewType()
    const { shareUrl: currentPageLink } = usePageURI({ isCurrentPage: true })

    // const renderLabel = useVariableValueRender()

    const actionRuntimeParams = useActionRuntimeParams()
    const { rootPageId, refreshRootPage } = useRootPageContext()
    const currentStackId = useCurrentStackIdContext()
    const pageStackAtom = pageStackAtomFamily({ stackId: currentStackId, rootPageId })
    const { pageStackFormState, currentPageId } = useAtomData(
        pageStackAtom,
        useCallback(s => ({ pageStackFormState: s?.formState, currentPageId: s?.pageId ?? '' }), [])
    )
    const {
        curr: { recordId: currentRecordId, datasource: currentDataSource },
        prev: { recordId: prevRecordId, datasource: prevDataSource }
    } = usePageDataSourceForVariableSelector({ pageId: currentPageId, stackId: currentStackId })
    const pageRecord = useRecord(appId, envId, currentDataSource?.id ?? '', currentRecordId ?? '')
    const prevRecord = useRecord(appId, envId, prevDataSource?.id ?? '', prevRecordId ?? '')

    const sequenceSingleWorker = useCallback(
        (action: ButtonAction) => {
            const { type, data } = action
            if (type === 'flow') {
                const flowData = workflows.find(w => w.id === data.flow.flowId)
                if (flowData) {
                    actionWorker.sequenceWorker(flowData)
                }
            }
        },
        [workflows]
    )

    const getClickTriggerNodeParams = useAtomCallback(
        useCallback(
            (get, set, params?: { record?: RecordLikeProtocol; prevRecord?: RecordLikeProtocol; appId: string; dsId: string }) => {
                const record = params?.record
                // 实现当前页面所在记录，也就是触发节点逻辑的记录
                const currentPageDep = get(lastPageOfStackAtom)

                const appId = currentPageDep?.appId ?? params?.appId ?? ''
                const dsId = currentPageDep?.dsId ?? params?.dsId ?? ''
                const recordId = currentPageDep?.recordId ?? ''

                const currentRecord = record ?? get(recordAtomFamily({ appId, envId, dsId, recordId }))

                // 当前用户
                const appRole = get(appRoleAtom) || ANONYMOUS
                const currentUserId = SYSTEM_ROLE.has(appRole) ? ANONYMOUS : appRole

                return {
                    clickTriggerNodeParams: {
                        record: currentRecord,
                        prevRecord: params?.prevRecord,
                        currentAppId: appId,
                        currentEnvId: envId,
                        currentUserId
                    }
                }
            },
            [envId]
        )
    )

    // 导航栏点击回调
    const handleActionTrigger = useCallback(
        async (
            action: ButtonAction,
            options?: {
                formRecord?: RecordLikeProtocol
                viewRecord?: RecordLikeProtocol
            }
        ): Promise<boolean | undefined> => {
            const invokeId = nanoid()
            const isFlowAction = action.type === 'flow'
            const isAutomationAction = action.type === 'automation'

            const { formRecord, viewRecord } = options ?? {}

            const initialRecord = formRecord || customViewRecord || viewRecord
            const usedViewRecord = customViewRecord || viewRecord

            const clickTriggerNodeDepsParams = initialRecord
                ? { record: initialRecord, prevRecord, appId: initialRecord.appId, envId: initialRecord.envId, dsId: initialRecord.dsId }
                : { prevRecord, appId, envId: prevRecord?.envId ?? '', dsId: prevRecord?.dsId ?? '' }
            const clickTriggerNodeParams = getClickTriggerNodeParams(clickTriggerNodeDepsParams)

            const { record } = clickTriggerNodeParams.clickTriggerNodeParams

            if (isFlowAction) {
                const { data } = action
                const flowData = workflows.find(w => w.id === data.flow.flowId)

                if (!flowData) {
                    return
                }

                // 必须要启用才可以执行
                if (!flowData?.enabled) {
                    return
                }

                sequenceSingleWorker(action)

                actionWorker.injectRuntimeParams(invokeId, flowData, {
                    dataSourceList,
                    userRecord,
                    formRecord,
                    viewRecord: usedViewRecord,
                    pageRecord,
                    getCurrentPageLink: () => currentPageLink,
                    pageStackFormState,
                    nodes: flowData.content?.nodes ?? [],
                    ...actionRuntimeParams,
                    ...clickTriggerNodeParams
                })
                actionWorker.invoke(flowData.id, invokeId)
                return
            }
            if (isAutomationAction) {
                const { data } = action
                const flowData = workflows.find(w => w.id === data.automation.flowId)

                if (!flowData) {
                    return
                }

                // 必须要启用才可以执行
                if (!flowData?.enabled) {
                    Toast.error('执行失败，因为工作流未启用')
                    return
                }
                // 执行
                try {
                    const success = await srv.triggerAutomationAction({
                        flowId: flowData.id,
                        recordId: record?.id ?? ''
                    })
                    if (success) {
                        Toast.success('执行成功')
                    } else {
                        Toast.error('执行失败')
                    }
                    return success
                } catch {
                    Toast.error('执行失败')
                }
                return
            }
            const { type, /* params, */ data } = action
            const dummyTriggerNodeParams = {
                pageStackFormState,
                ...clickTriggerNodeParams,
                [dummyTriggerNodeId]: {
                    record
                },
                dataSourceList,
                userRecord,
                formRecord,
                viewRecord: usedViewRecord,
                pageRecord,
                getCurrentPageLink: () => currentPageLink
            }

            switch (type) {
                case 'openPage': {
                    const params = data[type]
                    const isHaveNoneParam = !params.openPageUrl
                    if (isHaveNoneParam) {
                        return
                    }
                    const pageId = params.openPageUrl.replace('/page/', '')

                    if (commonPages.includes(pageId)) {
                        break
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId,
                        openType: params.pageOpenType,
                        appId,
                        dsId: record?.dsId,
                        recordId: record?.id,
                        onSaveCallback() {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })
                    break
                }
                case 'openLink': {
                    const params = data[type]
                    const linkUrl = params.link
                    if (!linkUrl) {
                        return
                    }
                    const variableValue = generateVariableValue({
                        innerType: 'TEXT',
                        jsonContent: linkUrl,
                        extraParams: dummyTriggerNodeParams
                    })
                    const url = Array.isArray(variableValue) ? '' : variableValue

                    if (!url) {
                        break
                    }

                    const isWithProtocol = url.startsWith('http://') || url.startsWith('https://')
                    const withProtocolUrl = isWithProtocol ? url : `https://${url}`
                    // window.open(withProtocolUrl, '_blank')
                    openNewBrowserPage(withProtocolUrl)
                    break
                }
                case 'notification': {
                    const params = data[type]
                    if (!params.notificationContent || !params.notificationStatus) {
                        return
                    }
                    const { notificationStatus, notificationContent } = params
                    const toastType = notificationStatus.toLocaleLowerCase() as 'info' | 'success' | 'warning' | 'error'
                    const variableValue = generateVariableValue({
                        innerType: 'TEXT',
                        jsonContent: notificationContent,
                        extraParams: dummyTriggerNodeParams
                    })
                    Message[toastType]({
                        // containerId: PAGE_CONTAINER_HOST,
                        title: variableValue,
                        device: previewType
                    })
                    break
                }
                case 'goBack': {
                    actionRuntimeParams.actionEvents.closePage()
                    break
                }
                case 'copy': {
                    const params = data[type]
                    const { copyText } = params
                    const text = generateVariableValue({ innerType: 'TEXT', jsonContent: copyText, extraParams: dummyTriggerNodeParams })
                    if (text) {
                        copyToClipboard(Array.isArray(text) ? '' : text)
                        Toast.success('复制成功')
                    }
                    break
                }
                case 'createRecord': {
                    const params = data[type]
                    const { dataSourceId, fields } = params
                    const fieldsValue = getCreateRecordVariableValue({ dataSourceId, nodeId: '', fields }, dummyTriggerNodeParams)

                    const newRecord = await srv.createRecordWithFields({
                        appId,
                        envId,
                        dsId: dataSourceId,
                        fields: fieldsValue
                    })

                    // 更新行以后，需要通知对应订阅更新数据
                    pageStackPubSub.emit(`${dataSourceId}-ADD`, newRecord)

                    Toast.success('数据创建成功')
                    break
                }
                case 'updateRecord': {
                    const params = data[type]
                    const updateRecordParams = params as UpdateRecordActionConfig
                    const { selectType } = updateRecordParams

                    if (selectType !== 'CURRENT_RECORD') {
                        break
                    }
                    const dsId = record?.dsId
                    const recordId = record?.id ?? ''
                    if (!dsId) {
                        break
                    }
                    const { fields } = getUpdateRecordVariableValue(dsId, params as UpdateRecordActionConfig, dummyTriggerNodeParams)
                    const newRecord = await actionRuntimeParams.actionEvents.updateRecord({
                        appId,
                        envId,
                        dsId,
                        recordId,
                        fields
                    })

                    // 更新行以后，需要通知对应订阅更新数据
                    pageStackPubSub.emit(`${dsId}-ADD`, newRecord)

                    actionRuntimeParams.actionEvents.refreshPage?.()
                    Toast.success('数据更新成功')
                    break
                }
                case 'openFormPage': {
                    const params = data[type]
                    const { formPageId, pageOpenType } = params
                    if (!formPageId) {
                        break
                    }
                    actionRuntimeParams.actionEvents.openPage({
                        pageId: formPageId,
                        openType: pageOpenType,
                        appId,
                        dsId: record?.dsId,
                        recordId: record?.id,
                        onSaveCallback: () => {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })
                    // }
                    break
                }
                case 'openRecordPage': {
                    const params = data[type]
                    const { recordPageId, dataSourceId = '', filter, pageOpenType, selectedRecordType } = params
                    let depRecord = { ...record, id: getMainTableRecordId(record?.id) }

                    if (!recordPageId) {
                        return
                    }

                    if (selectedRecordType === 'MATCHED_RECORD' /*  && filter?.expression?.conditions?.length */) {
                        const dataSource = find(item => item.id === dataSourceId, dataSourceList)
                        if (!dataSource) {
                            return
                        }
                        const validatedFilter = validateFilterFieldData(filter, dataSource)
                        const resolvedFilter = resolveFilter({
                            filter: validatedFilter,
                            shouldUseEmptyPlaceholder: true,
                            extraParams: dummyTriggerNodeParams
                        })
                        // if (resolvedFilter?.expression?.conditions?.length !== 0) {
                        // 兜底数据
                        const dsId = selectedRecordType === 'MATCHED_RECORD' ? dataSourceId : '' // 动作不会有 UPSTREAM_RECORD
                        depRecord = { ...record, id: getMainTableRecordId(record?.id), dsId }

                        const data = await srv.getDs({
                            appId,
                            envId,
                            dsId,
                            pagination: { currentPage: 1, pageSize: 1 },
                            filter: resolvedFilter
                        })
                        if (!data) {
                            return
                        }
                        const { records } = data
                        if (records[0]) {
                            depRecord = records[0]
                        }
                        // }
                    }
                    actionRuntimeParams.actionEvents.openPage({
                        pageId: recordPageId,
                        openType: pageOpenType,
                        appId,
                        dsId: depRecord?.dsId,
                        recordId: depRecord?.id,
                        onSaveCallback() {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'openRecordEditPage': {
                    const params = data[type]
                    const { recordEditPageId, dataSourceId = '', filter, pageOpenType, selectedRecordType } = params
                    let depRecord = { ...record, id: getMainTableRecordId(record?.id) }

                    if (!recordEditPageId) {
                        return
                    }

                    if (selectedRecordType === 'MATCHED_RECORD' /*  && filter?.expression?.conditions?.length */) {
                        const resolvedFilter = resolveFilter({
                            filter,
                            extraParams: dummyTriggerNodeParams,
                            shouldUseEmptyPlaceholder: true
                        })
                        // 兜底数据
                        const dsId = selectedRecordType === 'MATCHED_RECORD' ? dataSourceId : '' // 动作不会有 UPSTREAM_RECORD
                        depRecord = { ...record, id: getMainTableRecordId(record?.id), dsId }

                        // if (resolvedFilter?.expression?.conditions?.length !== 0) {
                        const data = await srv.getDs({
                            appId,
                            envId,
                            dsId,
                            pagination: { currentPage: 1, pageSize: 1 },
                            filter: resolvedFilter
                        })
                        if (!data) {
                            return
                        }
                        const { records } = data
                        if (records[0]) {
                            depRecord = records[0]
                        }
                        // }
                    }

                    actionRuntimeParams.actionEvents.openPage({
                        pageId: recordEditPageId,
                        openType: pageOpenType,
                        appId,
                        dsId: depRecord?.dsId,
                        recordId: depRecord?.id,
                        onSaveCallback() {
                            actionRuntimeParams.actionEvents.refreshPage?.()
                        }
                    })

                    break
                }
                case 'deleteRecord': {
                    const manualDeps = record ? { dsId: record.dsId, recordId: record.id } : undefined

                    const isDeleted = await actionRuntimeParams.actionEvents.deleteRecord(manualDeps)

                    if (!isDeleted) {
                        return
                    }

                    const currentPageStack = actionRuntimeParams.actionEvents.peekCurrentPageStack()
                    if (currentPageStack?.stackDisplayType === 'drawer' || currentPageStack?.stackDisplayType === 'modal') {
                        actionRuntimeParams.actionEvents.closePage()
                    }

                    break
                }
                case 'iClick': {
                    const params = data[type]
                    const addIClickUserParams = getActiveAddIClickUserVariableValue(params as IClickActionConfig, dummyTriggerNodeParams)

                    if (!addIClickUserParams) {
                        break
                    }

                    srv.activeAddIClickUser(addIClickUserParams)

                    break
                }
                case 'downloadFile': {
                    const params = data[type]
                    const downloadFileParams = resolveDownloadFileUrls(params, dummyTriggerNodeParams)

                    if (downloadFileParams) {
                        downloadFile(downloadFileParams)
                    }

                    break
                }
                case 'call': {
                    const params = data[type]
                    const phone = resolveVariable(params.phone, dummyTriggerNodeParams)
                    if (typeof phone === 'string') {
                        const a = document.createElement('a')
                        a.href = `tel:${phone}`
                        a.click()
                        a.remove()
                    }

                    break
                }
                case 'wechatTemplateMsg': {
                    const params = data[type]
                    const { templateId, personList, values, url } = params
                    const personListValues = personList.map(person => generateAdtValue('ARRAY', person, dummyTriggerNodeParams))
                    const userIds = [...new Set(personListValues)]
                    const valuesValues = values.map(value => ({
                        variableName: value.variableName,
                        value: generateVariableValue({
                            innerType: 'TEXT',
                            jsonContent: value.value,
                            extraParams: dummyTriggerNodeParams,
                            isFormat: true
                        }) as string
                    }))
                    const urlValue = generateVariableValue({
                        innerType: 'TEXT',
                        jsonContent: url,
                        extraParams: dummyTriggerNodeParams
                    }) as string
                    await srv.wxTemplateMessageAction({
                        userIds,
                        templateId,
                        url: urlValue,
                        values: valuesValues
                    })
                    break
                }

                case 'refreshPage': {
                    refreshRootPage?.()
                    break
                }
                case 'aliyunMessage': {
                    const params = data[type]
                    const { id, mobileList, signatureId, templateId, values } = params
                    const mobileListValue = (resolveVariable(mobileList, dummyTriggerNodeParams) ?? '') as string
                    const valuesListValue = values.map(value => ({
                        variableName: value.variableName,
                        value: generateVariableValue({
                            innerType: 'TEXT',
                            jsonContent: value.value,
                            extraParams: dummyTriggerNodeParams
                        }) as string
                    }))

                    const payload = {
                        id,
                        mobileList: mobileListValue,
                        signatureId,
                        templateId,
                        values: valuesListValue
                    }

                    await srv.activeAliyunMessageAction(payload)
                    break
                }
                case 'scrollTo': {
                    const params = data[type]
                    const { scrollToElementId } = params
                    scroll2FlowNode(scrollToElementId)
                    break
                }
                case 'control': {
                    const params = data[type]
                    const { controlElementId, controlElementType, controlAction } = params

                    controlAction?.type &&
                        blockListenerSystem.emit(controlElementId, controlElementType, {
                            event: controlAction.type,
                            params: 'params' in controlAction ? controlAction.params : undefined
                        })

                    break
                }
                case 'stripe': {
                    Toast.warning('不能在搭建端使用stripe支付，请在发布后或预览版中使用。')
                    break
                }
                case 'wechatPay': {
                    Toast.warning('不能在搭建端使用微信支付，请在发布后或预览版中使用。')
                    break
                }

                default: {
                    break
                }
            }
        },
        [
            customViewRecord,
            prevRecord,
            appId,
            getClickTriggerNodeParams,
            pageStackFormState,
            dataSourceList,
            userRecord,
            pageRecord,
            workflows,
            sequenceSingleWorker,
            actionRuntimeParams,
            currentPageLink,
            previewType,
            envId,
            refreshRootPage
        ]
    )

    return {
        handleActionTrigger
    }
}
