import type { PageAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { useCallback } from 'react'

import { pageAtomFamily } from '@/atoms/page/state'


export const usePageGroups = (pageId: string) => useAtomData(pageAtomFamily(pageId), useCallback((page: PageAbstract | null) => page?.roleIds ?? [], []))

export const usePageDepartments = (pageId: string) => useAtomData(pageAtomFamily(pageId), useCallback((page: PageAbstract | null) => page?.departmentIds ?? [], []))
