import type { FieldInputConfigProtocol } from '@lighthouse/core'
import { findParentFormBlock, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useVariableCustomDataSource } from '@/hooks/useVariableCustomViewOption'

import { FieldInputSetting } from '../Common/FieldInputSetting'

export interface BlockFieldSettingsProps {
    id: string
    pointer: string
}

const BlockFieldSettings: React.FunctionComponent<BlockFieldSettingsProps> = ({ id: blockId, pointer: pageDsId }) => {
    const pageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId || '', [])
    )

    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const parentForm = useMemo(() => findParentFormBlock(blockId, blocks)(nodes), [blockId, blocks, nodes])

    const mode = useMemo(() => (parentForm ? 'form' : 'field'), [parentForm])

    const { customViewDsId } = useVariableCustomDataSource()

    const pointer = (mode === 'form' ? parentForm?.config.pointer : customViewDsId || pageDsId) ?? ''

    const dataSourceList = useDataSourceList(currentAppId, envId)

    return <FieldInputSetting blockId={blockId} dataSourceList={dataSourceList} mode={mode} pointer={pointer} />
}

export default BlockFieldSettings
