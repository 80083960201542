import { getAssetUrl } from '@lighthouse/assets'
import React from 'react'

import { InvitePhone } from '../InvitePhone'
import * as SC from './styles'

interface InviteProps {
    children?: React.ReactNode
    spaceId: string
    onClose: () => void
}

export const Invite: React.FC<InviteProps> = ({ spaceId, onClose }) => {
    return (
        <SC.InviteWrapper>
            <SC.InviteTitle>邀请成员一起协作</SC.InviteTitle>
            <SC.InviteImg src={getAssetUrl('common', 'invite.webp')} alt="invite" />
            <SC.InviteContent>
                <SC.InvitePhone>
                    <InvitePhone spaceId={spaceId} onClose={onClose} />
                </SC.InvitePhone>
            </SC.InviteContent>
        </SC.InviteWrapper>
    )
}
