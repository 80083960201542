import { activeState } from '@lighthouse/shared'

export const activeRecordId = (viewId: string, recordId: string, cellDataKey: string) => {
    activeState.pushActiveViewRecord(viewId, recordId)
    const currentRecordKey = `[${cellDataKey}='${viewId}&${recordId}']`
    const recordBlockDom = document.querySelector(currentRecordKey)
    if (recordBlockDom) {
        recordBlockDom.classList.add('record-active')
    }
}

export const clearActiveRecordId = (cellDataKey: string) => {
    const { viewId, recordId } = activeState.clearActiveRecord() ?? {}
    if (!recordId || !viewId) {
        return
    }
    const currentRecordKey = `[${cellDataKey}='${viewId}&${recordId}']`
    const recordBlockDom = document.querySelector(currentRecordKey)

    if (recordBlockDom) {
        recordBlockDom.classList.remove('record-active')
    }
}
