import { Button, Popover, SelectDropdown } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { FieldTypeTag, getFieldIcon, SYSTEM_FIELD, SYSTEM_USER_FIELD, USER_DATASOURCE } from '@lighthouse/shared'
import { Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { filter, map } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import * as CM from '../../styles'
import { selectTypeList, selectUserSystemFieldIds } from '../constant'
import PerfectFieldBlock from '../PerfectFieldBlock'
import * as SC from './styles'

interface PerfectFieldProps {
    prefix: 'perfect.commonSetting' | `perfect.roleSetting.${number}`
}

const PerfectField: React.FC<PerfectFieldProps> = ({ prefix }) => {
    const { control } = useFormContext<ApplicationSettingAuthentication>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const [opened, { toggle, close }] = useDisclosure(false)
    const dataSourceData = useDataSource(appId, envId, USER_DATASOURCE)

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const { fields, append, remove, move } = useFieldArray({
        control,
        name: `${prefix}.fields`
    })

    const [fieldOptions, allFieldOptions] = useMemo(() => {
        const allFieldList = Object.entries(dataSourceData?.schema ?? {}) ?? []
        const fieldList = allFieldList.filter(([id, { type }]) => {
            const list = id.split('__')
            const reallyId = list.at(-1) ?? ''
            if (SYSTEM_USER_FIELD.has(reallyId)) {
                return selectUserSystemFieldIds.includes(id)
            }
            if (SYSTEM_FIELD.has(reallyId)) {
                return false
            }
            return selectTypeList.includes(type)
        })

        return [fieldList, allFieldList].map(item =>
            map(
                ([id, { name, type, innerType }]) => ({
                    value: id,
                    label: name,
                    icon: getFieldIcon(id, type, innerType),
                    type,
                    innerType
                }),
                item
            )
        )
    }, [dataSourceData?.schema])

    const addFieldOptions = useMemo(() => {
        const useFields = map(item => item.fieldId, fields)
        return filter(({ value }) => !useFields.includes(value), fieldOptions).map(item => ({
            ...item,
            icon: <CM.Icon type={item.icon} />,
            extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
        }))
    }, [fieldOptions, fields])

    const handleAddField = useCallback(
        (fieldId: string | number) => {
            const createFieldTitle = dataSourceData?.schema?.[fieldId]?.name ?? ''
            if (fieldId) {
                append({ fieldId: fieldId.toString(), title: createFieldTitle, placeHolder: `请输入${createFieldTitle}` })
                close()
            }
        },
        [append, close, dataSourceData?.schema]
    )

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.fieldId === active.id)
                const newIndex = fields.findIndex(item => item.fieldId === over?.id)
                move?.(oldIndex, newIndex)
                close()
            }
        },
        [close, fields, move]
    )

    return (
        <SC.Contain>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={fields.map(d => d.fieldId)} strategy={verticalListSortingStrategy}>
                    {fields.length > 0 && (
                        <SC.Content>
                            {fields.map((item, fieldIndex) => {
                                const fieldSchema = dataSourceData?.schema?.[item.fieldId]
                                if (!fieldSchema) {
                                    return null
                                }
                                return (
                                    <Controller
                                        key={item.fieldId}
                                        name={`${prefix}.fields.${fieldIndex}`}
                                        control={control}
                                        render={({ field: { value, onChange } }) => {
                                            const { placeHolder, title } = value
                                            // const newValue = {...value, name: groups.find(group => group.groupId ===item.gr )}
                                            return (
                                                <PerfectFieldBlock
                                                    key={item.fieldId}
                                                    field={fieldSchema}
                                                    options={allFieldOptions}
                                                    value={value}
                                                    onChange={onChange}
                                                    onDelete={() => remove(fieldIndex)}
                                                />
                                            )
                                        }}
                                    />
                                )
                            })}
                        </SC.Content>
                    )}
                </SortableContext>
            </DndContext>

            {addFieldOptions.length > 0 && (
                <Popover opened={opened} onChange={toggle} width={200} position="bottom-start">
                    <Popover.Target>
                        <Flex justify="space-between" align="center">
                            <Button radius="100px" icon={<CM.Icon type="Add" />}>
                                添加字段
                            </Button>
                            <Text size={12} color="var(--color-gray-500)">
                                仅支持添加「用户表」的字段
                            </Text>
                        </Flex>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <SelectDropdown searchable onSelect={handleAddField} options={addFieldOptions} />
                    </Popover.Dropdown>
                </Popover>
            )}
        </SC.Contain>
    )
}

export default PerfectField
