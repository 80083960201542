import { Text } from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import type { BaseFlowNode, FlowNode, SubProcessArg, VariableTriggerConfig } from '@lighthouse/shared'
import { innerTypeNameMap } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import type { WorkflowResult } from '@/services/types'

import { SettingSubProcessParam } from './SettingSubProcessParam'

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const SCxItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
`

const SCxLabel = styled.div``

const SCxValue = styled.div`
    width: 100%;
`

interface SettingSubProcessContentProps {
    data: SubProcessArg[] | undefined
    allParentNodes: FlowNode[]
    parentNodes: FlowNode[]
    onChange?: (val: SubProcessArg[]) => void
}

export const SettingSubProcessContent: React.FC<SettingSubProcessContentProps> = ({
    data,
    allParentNodes,
    parentNodes,
    onChange
}) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    // const dataRef = useRef(data)
    const dataSourceList = useDataSourceList(appId, envId)
    const handleChange = useCallback(
        (val: VariableADTvalue, index: number) => {
            if (!data) {
                return
            }
            const newData = data.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        value: val
                    }
                }
                return item
            })
            onChange?.(newData)
        },
        [onChange, data]
    )

    return (
        <SCxContainer>
            {data?.map((item, index) => (
                <SCxItem key={item.id}>
                    <SCxLabel>
                        <Text inline size={14} color="var(--color-black)">
                            {item.name}
                        </Text>
                        <Text inline size={14} color="var(--color-gray-400)">
                            [{innerTypeNameMap[item.innerType]}]
                        </Text>
                    </SCxLabel>
                    <SCxValue>
                        <SettingSubProcessParam
                            innerType={item.innerType}
                            value={item.value}
                            dataSourceList={dataSourceList}
                            allParentNodes={allParentNodes}
                            parentNodes={parentNodes}
                            onChange={val => handleChange(val, index)}
                        />
                    </SCxValue>
                </SCxItem>
            ))}
        </SCxContainer>
    )
}
