import type { FieldInputType, FieldType } from '@lighthouse/core'
import { datasourceFieldTypes, editableUserFieldId, fieldSettingMap, getIsAppointField, SYSTEM_FIELD } from '@lighthouse/shared'
import { find } from 'rambda'

import { DISABLED_SYSTEM_FIELD } from './constants'
import type { FieldOptions } from './types'

export const getSaveFieldOptions = function (
    inputType: FieldInputType,
    fieldOptions: FieldOptions,
): [FieldOptions, FieldType[]] {
    const inputTypeMap = fieldSettingMap[inputType]
    return [
        fieldOptions.filter(item => {
            if (getIsAppointField(item.value, DISABLED_SYSTEM_FIELD)) {
                return false
            }
            return inputTypeMap.includes(item.type)
        }),
        datasourceFieldTypes.filter(item => !inputTypeMap.includes(item))
    ]
}

export const isDisabledFieldInput = function (fieldPointer: string, fieldOptions: FieldOptions) {
    const fieldItem = find(item => item.value === fieldPointer, fieldOptions)
    if (getIsAppointField(fieldItem?.value ?? '', SYSTEM_FIELD)) {
        return !editableUserFieldId.has(fieldPointer)
    }
    return fieldItem?.type === 'formula'
}
