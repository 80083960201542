import { IconFont } from '@byecode/ui/components/IconFont'
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react'
import { ContainerBlock } from '@lighthouse/block'
import { type ContainerBlockAbstract, NavigationShowMode } from '@lighthouse/core'
import type { FlowLayoutContainerNode } from '@lighthouse/shared'
import {
    actionTypeInfos,
    DEFAULT_ACTION,
    PAGE_CONTAINER_HOST_ID,
    PAGE_LAYOUT_HOST,
    useActionRunningLoadings,
    useAppContainerContext,
    useAtomData,
    useRegisterBlockListener
} from '@lighthouse/shared'
import { findParentByClassName, findParentById, stopPropagation } from '@lighthouse/tools'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'

const ActionSection = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    /* position: absolute; */
    z-index: 10;
    /* right: 0;
    top: -1px;
    transform: translateY(-100%); */
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    padding: 2px 8px;
    color: var(--color-purple-700);
    font-size: 12px;
    line-height: 20px;
    background-color: #5551ff33;
    &:hover {
        background-color: #5551ff4d;
    }
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.8;
    }
`

function getBoundary(currentElement: null | HTMLElement, id: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentById(currentElement, id)
}

interface ContainerControllerProps {
    blockData: ContainerBlockAbstract
    node: FlowLayoutContainerNode
    disabled?: boolean
    children?: React.ReactNode
    onBlockChange?: (values: ContainerBlockAbstract, origin: ContainerBlockAbstract) => Promise<void> | void
}

const ContainerController = ({ blockData, children, ...props }: ContainerControllerProps) => {
    const { config, id } = blockData
    const { action = DEFAULT_ACTION, shown } = config
    const { handleActionTrigger } = useActionTrigger()
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const selectedNode = useAtomData(
        pageStackAtomFamily({ stackId, rootPageId }),
        useCallback(s => s?.state.selectedNode, [])
    )

    const handleViewChange = useAtomCallback<void, string[]>((_, set, view) => {
        set(pageStackAtom, draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.blockRuntimeState.container = {
                    ...stack.blockRuntimeState.container,
                    [id]: {
                        ...stack.blockRuntimeState.container?.[id],
                        currentView: view
                    }
                }
            }
        })
    })

    useRegisterBlockListener(id, 'container', {
        switchPanel: payload => {
            payload && handleViewChange(payload.switchPanelId)
        },
        open: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: true } }
            props.onBlockChange?.(newBlockData, blockData)
        },
        close: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: false } }
            props.onBlockChange?.(newBlockData, blockData)
        }
    })

    const onClickAction = (ev: React.MouseEvent<HTMLButtonElement>) => {
        if (action.type === 'none') {
            return
        }

        ev.stopPropagation()
        // handleActionTrigger(action)

        // eslint-disable-next-line no-return-await
        handleActionTriggerWithLoading({ type: 'click', action, id, trigger: async () => await handleActionTrigger(action) })
    }

    const actionInfo = useMemo(() => {
        if (action.type === 'none') {
            return
        }
        const info = actionTypeInfos.find(item => item.value === action.type)
        if (!info) {
            return
        }

        return info
    }, [action.type])

    const { showMode, height, isSticky } = useAppContainerContext()

    const { floatingStyles, refs } = useFloating<HTMLElement>({
        open: selectedNode === id && !!actionInfo,
        strategy: 'fixed',
        placement: 'top-end',
        middleware: [
            offset(4),
            shift(
                ({ elements }) => {
                    const el = getBoundary(elements.reference as HTMLElement, PAGE_CONTAINER_HOST_ID)
                    if (!el) {
                        return {}
                    }

                    const rect = el.getBoundingClientRect()
                    // const parentScroller = findParentScroller(elements.reference as HTMLElement)
                    // const boundaryBox = document.querySelector(`#${PAGE_SCROLL_PARENT_CONTENT}`)
                    // let boundary: Boundary | undefined = boundaryBox || undefined
                    // if (parentScroller && boundaryBox) {
                    //     const rectP = parentScroller.getBoundingClientRect()
                    //     const rectB = boundaryBox.getBoundingClientRect()
                    //     boundary = {
                    //         x: Math.max(rectP.x, rectB.x),
                    //         y: rectP.y,
                    //         width: Math.min(rectP.width, rectB.width),
                    //         height: Math.min(rectP.height, rectB.height)
                    //     }
                    // }
                    const navbarHeight = showMode === NavigationShowMode.horizontal ? (isSticky ? height * 16 : 0) : 0
                    return {
                        // padding: { top: 60 },
                        boundary: {
                            x: rect.x,
                            y: rect.y + navbarHeight,
                            width: rect.width,
                            height: rect.height - navbarHeight
                        },
                        crossAxis: true
                    }
                },
                [isSticky, height, showMode]
            )
        ],
        whileElementsMounted: autoUpdate
    })

    return (
        <ContainerBlock ref={refs.setReference} shown={shown} {...props}>
            {children}

            {selectedNode === id && actionInfo && (
                <ActionSection
                    ref={refs.setFloating}
                    data-ignore-click-away
                    disabled={loadings[id]}
                    onPointerDown={stopPropagation}
                    onClick={onClickAction}
                    style={{ ...floatingStyles }}
                >
                    <text>{actionInfo.label}</text>
                    <IconFont type="ArrowUpRight" size={16} />
                </ActionSection>
            )}
        </ContainerBlock>
    )
}

export default ContainerController
