import { CollapseBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { CollapseBlockAbstract } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import { useApplicationContext } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

interface CollapseBlockControllerProps {
    blockData: CollapseBlockAbstract
}

const CollapseBlockController: FC<CollapseBlockControllerProps> = ({ blockData }) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(parentRecordId, recordId)
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const { record } = useCustomViewBlockContext()

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            variable: {
                renderLabel: v => renderLabel(v.attrs.value, { personOptions, roleOptions, departmentOptions, viewRecord: record })
            }
        }),
        [departmentOptions, personOptions, record, renderLabel, roleOptions]
    )

    return <CollapseBlock blockData={blockData} config={config} />
}

export default CollapseBlockController
