import { pointer, singleTextEllipsis, Toast, Tooltip } from '@byecode/ui'
import { getBrandInfo, getUrlNameByOpenType, TagIcon, useAtomAction, useAtomData } from '@lighthouse/shared'
import copyToClipboard from 'copy-to-clipboard'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { rollbackPageStackHistoryAtom } from '@/atoms/page/action'
import { pageStackBreadcrumbAtom } from '@/atoms/page/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDefaultPageList } from '@/hooks/usePage'
import { usePageURI } from '@/hooks/usePageURI'

import { RedoUndo } from './RedoUndo'
import * as CM from './styles'

interface PageOperationProps {
    style?: React.CSSProperties
    hoverColor?: string
}

const SCxContainer = styled.div`
    position: relative;
    width: 40%;
    display: flex;
    align-items: center;
    gap: 8px;
`

const PageOperation: React.FunctionComponent<PageOperationProps> = ({ style, hoverColor }) => {
    const { run: popStack } = useAtomAction(rollbackPageStackHistoryAtom)

    return (
        <SCxContainer style={style}>
            <Tooltip title="返回">
                <TagIcon
                    size={32}
                    radius={6}
                    color="var(--color-gray-400)"
                    background="var(--color-gray-100)"
                    icon="ArrowLeftSmall"
                    hoverBackground='rgba(50, 75, 99, 0.12)'
                    hoverColor='var(--color-black)'
                    enableHover
                    onClick={() => {
                        popStack()
                    }}
                />
            </Tooltip>
            <RedoUndo />
        </SCxContainer>
    )
}

export default PageOperation
