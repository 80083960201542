import { FilterBlock } from '@lighthouse/block'
import type { FilterBlockAbstract, FilterBlockItemValue } from '@lighthouse/core'
import { useAtomAction } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import React, { useCallback } from 'react'

import { handleFetchFilterCascadeOptionsAtom, handleFetchFilterOptionsAtom } from '@/atoms/blockRecordsDict/action'
import type { HandleFetchFilterCascadeOptionsPayload, HandleFetchFilterOptionsPayload } from '@/atoms/blockRecordsDict/types'
import { filterBlockCacheAtomFamily } from '@/atoms/storage/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

interface FilterBlockControllerProps {
    blockData: FilterBlockAbstract
}

const FilterBlockController: React.FC<FilterBlockControllerProps> = ({ blockData }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const { run: handleFetchFilterOptions } = useAtomAction(handleFetchFilterOptionsAtom)
    const { run: handleFetchFilterCascadeOptions } = useAtomAction(handleFetchFilterCascadeOptionsAtom)
    const previewType = usePreviewType()
    const { pageId } = useCurrentPageContext()
    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const dataSourceList = useDataSourceList(appId, envId)
    const filterData = useAtomValue(filterBlockCacheAtomFamily({ appId, envId, pageId, recordId: curr.recordId, blockId: blockData.id }))

    const { run: setFilterData } = useAtomAction(
        filterBlockCacheAtomFamily({ appId, envId, pageId, recordId: curr.recordId, blockId: blockData.id })
    )

    const handleFetchTextOptions = useCallback(
        (params: Omit<HandleFetchFilterOptionsPayload, 'envId' | 'blockId' | 'pageSize' | 'currentRecordId' | 'parentRecordId'>) =>
            handleFetchFilterOptions({
                ...params,
                envId,
                blockId: blockData.id,
                pageSize: 200,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId
            }),
        [blockData.id, curr.recordId, envId, handleFetchFilterOptions, prev.recordId]
    )

    const handleFetchCascadeOptions = useCallback(
        (params: Omit<HandleFetchFilterCascadeOptionsPayload, 'appId' | 'envId' | 'blockId' | 'pageSize'>) => {
            return handleFetchFilterCascadeOptions({ ...params, blockId: blockData.id, pageSize: 1000 })
        },
        [blockData.id, handleFetchFilterCascadeOptions]
    )

    const handleChange = useCallback(
        (itemId: string, data: FilterBlockItemValue) => {
            setFilterData({ itemId, data })
        },
        [setFilterData]
    )

    return (
        <FilterBlock
            blockData={blockData}
            filterData={filterData || {}}
            dataSourceList={dataSourceList}
            onChange={handleChange}
            previewType={previewType}
            onFetchTextOptions={handleFetchTextOptions}
            onFetchCascadeOptions={handleFetchCascadeOptions}
        />
    )
}

export default FilterBlockController
