import { Divider, Text } from '@byecode/ui'
import type { DataSourceAbstract, Field } from '@lighthouse/core'
import type { BaseFlowNode, FlowNode, SubProcessArg, VariableSource, VariableTriggerConfig } from '@lighthouse/shared'
import {
    canAsUpstreamSingleRecordNodeTypes,
    canAsUpstreamSingleRecordNodeTypesInLoopParams,
    CollapseBox,
    getDefaultValueOptionsByInnerType,
    getUserDatasourceOptionsByInnerType,
    USER_DATASOURCE,
    VariableSelect,
    VariableSourceType
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSWR from 'swr'

import * as srv from '@/services'

import * as SC from '../../styles'
import { SettingSubProcessContent } from './SettingSubProcessContent'
import { SubProcessSelector } from './SubProcessSelector'

interface TimesConfigProps {
    dataSourceList: DataSourceAbstract[]
    allParentNodes: FlowNode[]
}

export const TimesConfig: React.FC<TimesConfigProps> = ({ dataSourceList, allParentNodes }) => {
    const { control, getValues, setValue } = useFormContext()
    const { data: workflows = [], mutate } = useSWR(`allFlowNodes`, () => srv.listFlowNodes(), {
        revalidateOnFocus: false
    })

    const numberField: Field = { id: '', name: '', dsId: '', type: 'number', innerType: 'NUMBER', number: {} }

    const subProcessId = useWatch({ control, name: 'config.timesConfig.subProcessId' })

    const userDsOption = useMemo(() => {
        const ds = find(ds => ds.id === USER_DATASOURCE, dataSourceList)
        if (!ds) {
            return
        }

        return getUserDatasourceOptionsByInnerType({
            dataSource: ds,
            validateFieldType: innerType => innerType === 'NUMBER'
        })
    }, [dataSourceList])

    const handleFetchWorkflows = useCallback(() => {
        return mutate()
    }, [mutate])

    const variableOptions = useMemo(() => {
        return getDefaultValueOptionsByInnerType({
            sources: [
                {
                    sourceType: VariableSourceType.parentNode,
                    dataSourceList,
                    parentNodes: allParentNodes.filter(node => canAsUpstreamSingleRecordNodeTypes.has(node.data.nodeType))
                }
            ] as VariableSource[],
            validateInnerType: innerType => innerType === 'NUMBER'
        })
    }, [dataSourceList, allParentNodes])

    const allSingeParentNode = useMemo(
        () => allParentNodes.filter(node => canAsUpstreamSingleRecordNodeTypesInLoopParams.has(node.data.nodeType)),
        [allParentNodes]
    )

    useEffect(() => {
        const workflow = find(item => item.id === subProcessId && item.type === 'subProcess', workflows)
        if (workflow) {
            const nodeList = workflow.content?.nodes || []
            const variableNode = find(item => item.data.nodeType === 'ARG_TRIGGER', nodeList) as BaseFlowNode<VariableTriggerConfig>
            if (!variableNode) {
                return
            }
            const { config } = variableNode.data
            if (!config || !config?.args) {
                return
            }
            const data: SubProcessArg[] = getValues('config.timesConfig.args')
            const newParameters = config.args.map(item => {
                const dataItem = find(d => d.id === item.id, data || [])
                return {
                    ...item,
                    value: dataItem?.value
                }
            })
            setValue('config.timesConfig.args', newParameters)
        }
    }, [getValues, setValue, subProcessId, workflows])

    return (
        <>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="循环参数">
                <SC.Content>
                    <SC.Tip>
                        “起始值”参数每次循环自动增加“步长”的值，当“起始值”参数大于“结束值”时，退出循环。最大循环次数为1000次，超出会自动停止循环。
                    </SC.Tip>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>
                                <Text inline>起始值</Text>
                                <Text inline color="var(--color-gray-400)">
                                    [数值]
                                </Text>
                            </SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.timesConfig.start"
                                control={control}
                                render={({ field }) => (
                                    <VariableSelect
                                        field={numberField}
                                        value={field.value}
                                        options={variableOptions}
                                        userOption={userDsOption}
                                        onChange={val => {
                                            field.onChange?.(val)
                                        }}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>
                                <Text inline>结束值</Text>
                                <Text inline color="var(--color-gray-400)">
                                    [数值]
                                </Text>
                            </SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.timesConfig.end"
                                control={control}
                                render={({ field }) => (
                                    <VariableSelect
                                        field={numberField}
                                        value={field.value}
                                        options={variableOptions}
                                        userOption={userDsOption}
                                        onChange={val => {
                                            field.onChange?.(val)
                                        }}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>
                                <Text inline>步长</Text>
                                <Text inline color="var(--color-gray-400)">
                                    [数值]
                                </Text>
                            </SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.timesConfig.step"
                                control={control}
                                render={({ field }) => (
                                    <VariableSelect
                                        field={numberField}
                                        value={field.value}
                                        options={variableOptions}
                                        userOption={userDsOption}
                                        onChange={val => {
                                            field.onChange?.(val)
                                        }}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="循环的子流程">
                <SC.Content>
                    <Controller
                        name="config.timesConfig.subProcessId"
                        control={control}
                        render={({ field }) => (
                            <SubProcessSelector
                                workflows={workflows}
                                value={field.value}
                                onFetchWorkflows={handleFetchWorkflows}
                                onChange={val => {
                                    field.onChange?.(val)
                                }}
                            />
                        )}
                    />
                </SC.Content>
            </CollapseBox>
            {subProcessId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="子流程参数值设置">
                        <SC.Content>
                            <SC.Tip>设置子流程的参数值，参数值将会传递到子流程中。</SC.Tip>
                            <Controller
                                name="config.timesConfig.args"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <SettingSubProcessContent
                                            data={field.value}
                                            parentNodes={allSingeParentNode}
                                            allParentNodes={allParentNodes}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.Content>
                    </CollapseBox>
                </>
            )}
        </>
    )
}
