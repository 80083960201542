import { Button, Input } from '@byecode/ui'
import type { ConformableInputProtocol, IconicProtocol, LoadingProtocol } from '@lighthouse/core'
import { ErrorMessage, getImageFullUrlInCommon } from '@lighthouse/shared'
import Uploady from '@rpldy/uploady'
import type { FormEvent } from 'react'
import React, { useCallback } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import { uploadPublicParams } from '@/utils/auth'

import { UploadSpaceAvatar } from '../UploadSpaceAvatar'

export type CardCreatorProps = ConformableInputProtocol<IconicProtocol> &
    LoadingProtocol & {
        title: string
    }

const SCxContainer = styled.div`
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const SCxForm = styled.form``

const SCxTitle = styled.div`
    height: 32px;
    line-height: 32px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--color-black);
    margin-bottom: 27px;
`

const SCxImgAlt = styled.div`
    margin: 8px 0 26px 0;
    height: 16px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`
// const SCxIcon = styled.img`
//     display: block;
//     width: 62px;
//     height: 62px;
//     border-radius: 50%;
// `

export const SCxInput = styled(Input)<{ error?: boolean }>`
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    border: none !important;

    & input {
        border: ${({ error }) => `1px solid ${error ? '#d88987' : 'var(--color-gray-200)'}`};
        border-radius: 8px;
        height: 100%;
        padding-left: 8px;
        margin: 0;
    }

    & input:focus {
        border-color: var(--color-main);
        box-shadow: 0px 0px 0px 2px rgba(42, 192, 126, 0.15);
    }

    background-color: var(--color-white);
`

const SCxButton: typeof Button = styled(Button)`
    margin-top: 38px;
    height: 40px;
    border-radius: 8px;
`

export interface CardCreatorForm {
    name: string
    icon: string
}

export const CardCreator: React.FC<CardCreatorProps> = ({ title, value, loading = false, onConfirm }) => {
    const methods = useForm<CardCreatorForm>({ mode: 'onBlur', shouldFocusError: false, defaultValues: value })
    const {
        control,
        formState: { errors },
        register,
        setValue,
        clearErrors,
        handleSubmit
    } = methods

    const spaceName = useWatch({
        control,
        name: 'name'
    })

    const spaceIcon = useWatch({
        control,
        name: 'icon'
    })

    const handleFormSubmit = useCallback(
        (ev?: FormEvent) => {
            ev?.preventDefault()
            handleSubmit(onConfirm)()
        },
        [handleSubmit, onConfirm]
    )

    const clearFormError = useCallback(
        (name: 'name') => {
            clearErrors(name)
        },
        [clearErrors]
    )

    useHotkeys('enter', ev => {
        handleFormSubmit()
    })

    const handleUpdateImg = useCallback(
        (img: string) => {
            setValue('icon', img)
        },
        [setValue]
    )

    return (
        <SCxForm onSubmit={handleFormSubmit}>
            <SCxContainer>
                <SCxTitle>创建空间</SCxTitle>
                <Uploady accept="image/*" {...uploadPublicParams()}>
                    <UploadSpaceAvatar
                        name={spaceName.slice(0, 2) || 'KZ'}
                        fontSize={22}
                        size="xLarge"
                        img={getImageFullUrlInCommon(spaceIcon)}
                        onUpdateImg={handleUpdateImg}
                    />
                </Uploady>
                <SCxImgAlt>空间logo</SCxImgAlt>
                <ErrorMessage name="name" errors={errors} style={{ width: '100%' }}>
                    <SCxInput
                        placeholder="空间名称"
                        error={!!errors.name}
                        onFocus={() => {
                            clearFormError('name')
                        }}
                        {...register('name', {
                            required: '请输入空间名称'
                        })}
                    />
                </ErrorMessage>

                <SCxButton htmlType="submit" type="primary" loading={loading}>
                    {title}
                </SCxButton>
            </SCxContainer>
        </SCxForm>
    )
}
