import { Divider, IconFont, Text } from '@byecode/ui'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { SharedOtherPlatforms } from '@/components/ApplicationPublish/PublishWebsite/ShareOtherPlatforms'
import { SharePoster } from '@/components/ApplicationPublish/PublishWebsite/SharePoster'
import { SharePrivateLink } from '@/components/ApplicationPublish/PublishWebsite/SharePrivateLink'
import { ShareQrCode } from '@/components/ApplicationPublish/PublishWebsite/ShareQrCode'

const List = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 16px -12px;
`
const ListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
        background-color: var(--color-gray-100);
    }
`
const ListItemIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    color: var(--color-white);
`

interface Props {
    isPreview?: boolean
    header?: React.ReactNode
    footer?: React.ReactNode
    showShareContent?: boolean
    onClose?: () => void
}

export const PublishedContent = ({ isPreview, header, footer, showShareContent, onClose }: Props) => {
    const [panel, setPanel] = useState<'DEFAULT' | 'POSTER' | 'QRCODE' | 'SHARE' | 'PRIVATE'>('DEFAULT')

    return useMemo(() => {
        switch (panel) {
            // case 'domain': {
            //     return <PublishDomain data={data} onOpenBind={handleOpenBind} onChangeMode={setMode} />
            // }
            case 'POSTER': {
                return <SharePoster isPreview={isPreview} onBack={() => setPanel('DEFAULT')} onClose={onClose} />
            }
            case 'QRCODE': {
                return <ShareQrCode isPreview={isPreview} onBack={() => setPanel('DEFAULT')} onClose={onClose} />
            }
            case 'SHARE': {
                return <SharedOtherPlatforms isPreview={isPreview} onBack={() => setPanel('DEFAULT')} onClose={onClose} />
            }
            case 'PRIVATE': {
                return <SharePrivateLink onBack={() => setPanel('DEFAULT')} onClose={onClose} />
            }
            default: {
                return (
                    <>
                        {/* {hideHeader ? null : (
                            <Flex direction="column" px={24} py={16} gap={8}>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <LeftHeader>
                                        {icon}
                                        <Flex direction="column" gap={2}>
                                            <Text size={14} weight={500} lineHeight="22px">
                                                {title}
                                            </Text>
                                            <Text size={12} color="var(--color-gray-500)">
                                                {description}
                                            </Text>
                                        </Flex>
                                    </LeftHeader>
                                    {extra}
                                </Flex>

                                {isEditing && (
                                    <Tips>
                                        <Text size={14} color="var(--color-yellow-500)">
                                            该应用链接仅用于预览和测试，请勿用于实际业务
                                        </Text>
                                    </Tips>
                                )}
                            </Flex>
                        )} */}

                        {header}

                        {header ? <Divider mt={16} /> : null}

                        {showShareContent && (
                            <List>
                                <ListItem onClick={() => setPanel('SHARE')}>
                                    <ListItemIcon style={{ backgroundColor: '#54C5EB' }}>
                                        <IconFont type="ChatRoundLikeIconLibrary" size={20} />
                                    </ListItemIcon>
                                    <Text size={14}>{isPreview ? '通过社交媒体预览' : '分享到社交媒体'}</Text>
                                    <IconFont style={{ marginLeft: 'auto' }} type="ArrowRightSmall" color="var(--color-gray-400)" />
                                </ListItem>
                                <ListItem onClick={() => setPanel('POSTER')}>
                                    <ListItemIcon style={{ backgroundColor: 'var(--color-main)' }}>
                                        <IconFont type="Image" size={20} />
                                    </ListItemIcon>
                                    <Text size={14}>{isPreview ? '分享预览海报' : '分享海报'}</Text>
                                    <IconFont style={{ marginLeft: 'auto' }} type="ArrowRightSmall" color="var(--color-gray-400)" />
                                </ListItem>
                                <ListItem onClick={() => setPanel('QRCODE')}>
                                    <ListItemIcon style={{ backgroundColor: '#54C5EB' }}>
                                        <IconFont type="QRCodeIconLibrary" size={20} />
                                    </ListItemIcon>
                                    <Text size={14}>{isPreview ? '分享预览二维码' : '分享成二维码'}</Text>
                                    <IconFont style={{ marginLeft: 'auto' }} type="ArrowRightSmall" color="var(--color-gray-400)" />
                                </ListItem>
                                {!isPreview && (
                                    <ListItem onClick={() => setPanel('PRIVATE')}>
                                        <ListItemIcon style={{ background: 'linear-gradient(180deg, #667085 0%, #8B98B5 100%)' }}>
                                            <IconFont type="HandShake" size={20} fill="var(--color-white)" />
                                        </ListItemIcon>
                                        <Text size={14}>分享为私人模板</Text>
                                        <IconFont style={{ marginLeft: 'auto' }} type="ArrowRightSmall" color="var(--color-gray-400)" />
                                    </ListItem>
                                )}
                            </List>
                        )}

                        {footer}

                        {/* <Main style={style}> */}
                        {/* {headerDom}
                        {shareContentDom}
                        {actionsDom} */}
                        {/* </Main> */}
                    </>
                )
            }
        }
    }, [footer, header, isPreview, onClose, panel, showShareContent])
}
