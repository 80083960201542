import { Input } from '@byecode/ui'
import type { DataSourceAbstract, Field, FieldBlockWithDsId, FilterBlockAbstract, TypeInstanceMap, ViewType } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { USER_DATASOURCE } from '../../constants'
import { filterModeIndex, InnerTypeToFilterType } from '../../constants/filter'
import type { VariableSource } from '../../types'
import { getInputVariableField, getIsMatchInnerTypeByOperator } from '../../utils'
import {
    AggregationInnerTypeToFieldType,
    DEFAULT_FILTER_VALUE_VARIABLE,
    getDefaultValueOptionsByInnerType,
    getFilterOptions,
    getFormDatasourceOptions,
    getInputOption,
    getSystemOption,
    getUserDatasourceOptionsByInnerType,
    getViewOptions,
    VariableSelect
} from '../'
import { FILTER_OPERATOR, needNumberParamSystemVariableList, systemMapList } from './constants'
import * as SC from './styles'

interface ParamsVariableProps {
    field?: Field
    formDsId?: string
    sources?: VariableSource[]
    viewType?: ViewType
    viewDsId?: string
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    dataSourceList: DataSourceAbstract[]
    prefixName: string
    width?: string
    filterBlocks?: FilterBlockAbstract[]
    disabled?: boolean
    onMouseEnter?: (blockId: string) => void
    onMouseLeave?: () => void
}

const ParamsBox = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    white-space: nowrap;
    gap: 8px;
`
const SCxInput = styled(Input)`
    input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            appearance: none !important;
            margin: 0;
        }
    }
`

export const ParamsVariable: React.FC<ParamsVariableProps> = ({
    field,
    formDsId,
    sources,
    fieldBlocksWithDsId = [],
    dataSourceList,
    prefixName,
    width,
    viewType,
    viewDsId,
    filterBlocks,
    disabled,
    onMouseEnter,
    onMouseLeave
}) => {
    const { control } = useFormContext()
    const operator: string = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const realField = useMemo(() => {
        if (!field) {
            return
        }
        if ((field.type === 'formula' || field.type === 'aggregation') && field.innerType) {
            return {
                ...field,
                type: AggregationInnerTypeToFieldType[field.innerType]
            } as Field
        }
        return field
    }, [field])

    const viewOption = useMemo(() => {
        const viewDataSource = dataSourceList.find(item => item.id === viewDsId)
        return getViewOptions({
            dataSource: viewDataSource,
            viewType,
            validateFieldType: (f: Field) => {
                return getIsMatchInnerTypeByOperator(field?.innerType, operator, f.innerType)
            }
        })
    }, [dataSourceList, field, operator, viewDsId, viewType])

    const inputOption = useMemo(() => {
        if (fieldBlocksWithDsId.length === 0) {
            return
        }
        return getInputOption(fieldBlocksWithDsId, {
            validateFieldType: fieldBlock => {
                const blockId = fieldBlock.id
                const f = getInputVariableField(blockId, dataSourceList, fieldBlocksWithDsId)
                if (!f) {
                    return false
                }
                return getIsMatchInnerTypeByOperator(realField?.innerType, operator, f.innerType)
            },
            onMouseEnter,
            onMouseLeave
        })
    }, [dataSourceList, fieldBlocksWithDsId, onMouseEnter, onMouseLeave, operator, realField?.innerType])

    const formOption = useMemo(() => {
        const formDataSource = dataSourceList.find(item => item.id === formDsId)
        return getFormDatasourceOptions({
            dataSource: formDataSource,
            validateFieldType: f => {
                return getIsMatchInnerTypeByOperator(field?.innerType, operator, f.innerType)
            }
        })
    }, [dataSourceList, field, formDsId, operator])

    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptionsByInnerType({
            dataSource: userDataSource,
            validateFieldType: (innerType: TypeInstanceMap) => {
                return getIsMatchInnerTypeByOperator(field?.innerType, operator, innerType)
            }
        })
    }, [dataSourceList, field, operator])

    const mode = useMemo(() => {
        return filterModeIndex[operator]
    }, [operator])

    const options = useMemo(() => {
        return getDefaultValueOptionsByInnerType({
            sources: sources || [],
            validateInnerType: (innerType: TypeInstanceMap) => {
                return getIsMatchInnerTypeByOperator(field?.innerType, operator, innerType)
            }
        })
    }, [field, operator, sources])

    const filterOptions = useMemo(() => {
        return getFilterOptions(filterBlocks || [], type =>
            field?.innerType ? InnerTypeToFilterType[field.innerType].includes(type) : false
        )
    }, [field, filterBlocks])

    const renderParams = useCallback(
        (ctlField: ControllerRenderProps<FieldValues, `${string}.paramList`>) => {
            const [firstParam = DEFAULT_FILTER_VALUE_VARIABLE, secondParam = DEFAULT_FILTER_VALUE_VARIABLE] = ctlField?.value || [
                DEFAULT_FILTER_VALUE_VARIABLE,
                DEFAULT_FILTER_VALUE_VARIABLE
            ]

            if (operator === FILTER_OPERATOR) {
                return (
                    <VariableSelect
                        field={realField}
                        disableInput
                        value={firstParam}
                        disabled={!realField || !filterOptions || filterOptions.length === 0 || disabled}
                        filterOptions={filterOptions}
                        style={{ width, whiteSpace: 'nowrap' }}
                        placeholder="请选择"
                        width={width}
                        onChange={val => {
                            ctlField.onChange([val])
                            onMouseLeave?.()
                        }}
                        disabledPexels
                    />
                )
            }
            const systemOption = realField?.type === 'date' ? getSystemOption(systemMapList[mode ?? 0]) : undefined
            if (mode === 0 && realField?.innerType === 'DATE') {
                return (
                    <ParamsBox>
                        <VariableSelect
                            key={`${prefixName}.paramList`}
                            field={realField}
                            isMultiple={false}
                            options={options}
                            viewOption={viewOption}
                            value={firstParam}
                            inputOption={inputOption}
                            systemOption={systemOption}
                            formOption={formOption}
                            userOption={userOption}
                            disabled={disabled}
                            width={width}
                            disabledPexels
                            style={{ flex: 1 }}
                            // style={{ flex: 1, minWidth: 160 }}
                            onChange={val => {
                                if (
                                    val &&
                                    val.type === VariableType.SYSTEM &&
                                    needNumberParamSystemVariableList.includes(val.systemVariable?.value as string)
                                ) {
                                    ctlField.onChange([val, secondParam])
                                    return
                                }
                                ctlField.onChange([val])
                            }}
                        />
                        {needNumberParamSystemVariableList.includes(firstParam?.systemVariable?.value) && (
                            <SCxInput
                                style={{ minWidth: 135 }}
                                key={`${prefixName}.paramList2`}
                                {...ctlField}
                                disabled={!realField || disabled}
                                placeholder="输入天数，例如“1”"
                                value={secondParam?.valueVariable?.value}
                                type="number"
                                min={1}
                                onChange={ev => {
                                    const { value: newValue } = ev.target
                                    ctlField.onChange([
                                        firstParam,
                                        {
                                            type: VariableType.VALUE,
                                            valueVariable: { value: newValue }
                                        }
                                    ])
                                }}
                            />
                        )}
                    </ParamsBox>
                )
            }
            if (mode === 2) {
                return (
                    <ParamsBox>
                        <VariableSelect
                            key={`${prefixName}.paramList`}
                            field={realField}
                            isMultiple={false}
                            options={options}
                            viewOption={viewOption}
                            disabled={!realField || disabled}
                            value={firstParam}
                            inputOption={inputOption}
                            formOption={formOption}
                            systemOption={systemOption}
                            userOption={userOption}
                            placeholder="开始时间"
                            width={width}
                            style={{ flex: 1 }}
                            disabledPexels
                            onChange={val => ctlField.onChange([val, secondParam])}
                        />
                        <VariableSelect
                            key={`${prefixName}.paramList2`}
                            field={realField}
                            isMultiple={false}
                            options={options}
                            viewOption={viewOption}
                            disabled={!realField || disabled}
                            inputOption={inputOption}
                            formOption={formOption}
                            systemOption={systemOption}
                            userOption={userOption}
                            value={secondParam}
                            placeholder="结束时间"
                            width={width}
                            style={{ flex: 1 }}
                            disabledPexels
                            onChange={val => ctlField.onChange([firstParam, val])}
                        />
                    </ParamsBox>
                )
            }

            return (
                <VariableSelect
                    field={realField}
                    options={options}
                    viewOption={viewOption}
                    value={firstParam}
                    inputOption={inputOption}
                    systemOption={systemOption}
                    userOption={userOption}
                    formOption={formOption}
                    isMultiple={mode === 1}
                    disabled={!realField || disabled}
                    style={{ width, whiteSpace: 'nowrap', minWidth: 120 }}
                    width={width}
                    onChange={val => {
                        ctlField.onChange([val])
                        onMouseLeave?.()
                    }}
                    disabledPexels
                />
            )
        },
        [
            operator,
            realField,
            mode,
            options,
            viewOption,
            inputOption,
            userOption,
            formOption,
            width,
            disabled,
            filterOptions,
            onMouseLeave,
            prefixName
        ]
    )

    return (
        <Controller
            name={`${prefixName}.paramList`}
            control={control}
            render={({ field: ctlField }) => {
                if (operator === 'isEmpty' || operator === 'isNotEmpty') {
                    return <></>
                }
                return <SC.Box key={`${prefixName}.paramList`}>{renderParams(ctlField)}</SC.Box>
            }}
        />
    )
}
