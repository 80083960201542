import { IconFont, Loading, pointer } from '@byecode/ui'
import { useItemFinishListener, useItemStartListener, useUploadyContext } from '@rpldy/shared-ui'
import UploadDropZone from '@rpldy/upload-drop-zone'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import type { AvatarSize } from '../../types'
import { UserAvatar } from '../UserAvatar'

const SCxContainer = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    ${pointer}
`

const SCxImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid var(--color-gray-200);
    overflow: hidden;
`

const SCxClear = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    ${pointer}
`

interface UploadUserAvatarProps {
    children?: React.ReactNode
    id?: string
    size?: AvatarSize
    onUpdateImg: (imgSrc: string) => void
    name?: string
    img?: string
    fontSize?: number
    disable?: boolean
}

export const UploadUserAvatar: React.FC<UploadUserAvatarProps> = ({
    id = '',
    img,
    size,
    onUpdateImg,
    name = '',
    disable = false,
    fontSize
}) => {
    const uploady = useUploadyContext()
    const [loading, setLoading] = useState(false)

    useItemStartListener(item => {
        setLoading(true)
    })

    useItemFinishListener(item => {
        const { uploadResponse } = item
        setLoading(false)
        onUpdateImg(uploadResponse.data.content.url)
    })

    const handleClear = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
        onUpdateImg('')
    }, [onUpdateImg])

    const handleUpload = useCallback(() => {
        if (disable) {
            return
        }
        uploady.showFileUpload()
    }, [disable, uploady])

    const content = useMemo(() => {
        if (loading) {
            return <Loading outlined />
        }
        if (img) {
            return (
                <>
                    <SCxImageWrapper>
                        <UserAvatar borderColor='' id={id} avatar={img} name={name} size={size} fontSize={fontSize} />
                    </SCxImageWrapper>
                    <SCxClear onClick={handleClear}>
                        <IconFont type="Close" size={16} fill="var(--color-gray-500)" />
                    </SCxClear>
                </>
            )
        }
        return <IconFont type="Camera" size={24} fill="var(--color-gray-400)" />
    }, [fontSize, handleClear, id, img, loading, name, size])

    return (
        <UploadDropZone>
            <SCxContainer onClick={handleUpload}>{content}</SCxContainer>
        </UploadDropZone>
    )
}
