import { Flex, IconFont, Slider, Text, tinyButtons } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { TagIcon, useAtomAction, useAtomData } from '@lighthouse/shared'
import { ScrollArea } from '@mantine/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { appGuideAtom } from '@/atoms/application/state'

import { guideTaskList } from './constants'

interface TaskListProps {
    taskId: string
    stepId: string
    onBack: () => void
    onClose: () => void
    onChangeStep: (id: string) => void
}

const SCxContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 0;
    overflow: hidden;
`

const SCxHeader = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 8px;
    padding: 0 20px;
`

const SCxItem = styled(Flex)`
    height: 54px;
    align-items: center;
    box-shadow: 0px 0px 6px 0px #3838381f;
    border: 1px solid var(--color-gray-200);
    border-radius: 10px;
    gap: 8px;
    padding: 0 16px;
    flex-shrink: 0;
    cursor: pointer;

    &.active {
        border-color: var(--color-main);
    }
`

const SCxContent = styled(ScrollArea)`
    flex: 1;
    overflow: hidden auto;
    ${tinyButtons}
`

const SCxList = styled.div`
    padding: 24px 20px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const StepList: React.FunctionComponent<TaskListProps> = ({ taskId, stepId, onBack, onClose, onChangeStep }) => {
    const appGuide = useAtomData(appGuideAtom)
    const task = find(item => item.id === taskId, guideTaskList)
    const currentTaskProgress = useMemo(() => task?.steps.filter(v => appGuide.includes(v.id)).length ?? 0, [appGuide, task?.steps])
    return (
        <SCxContainer>
            <SCxHeader>
                <TagIcon
                    iconSize={20}
                    iconColor="var(--color-black)"
                    icon="ArrowLeftSmall"
                    size={20}
                    radius={100}
                    onClick={onBack}
                    enableHover
                />
                <Text size={14} style={{ flex: 1 }}>
                    {task?.title}
                </Text>
                <TagIcon
                    iconSize={20}
                    iconColor="var(--color-black)"
                    icon="Close"
                    size={20}
                    radius={100}
                    hoverBackground="transparent"
                    onClick={onClose}
                    enableHover
                />
            </SCxHeader>
            <Flex alignItems="center" style={{ padding: '0 20px', marginTop: 16, flexShrink: 0 }} gap={6}>
                <Slider
                    style={{ flex: 1 }}
                    styles={{ track: { backgroundColor: 'var(--color-gray-200)!important' } }}
                    max={task?.steps.length ?? 1}
                    min={0}
                    step={1}
                    hideInput
                    value={currentTaskProgress}
                    readonly
                />
                <Text size={12} color="var(--color-gray-400)">
                    {currentTaskProgress} /{task?.steps.length ?? 1}
                </Text>
            </Flex>
            <SCxContent>
                <SCxList>
                    {task?.steps.map((step, index) => (
                        <SCxItem key={step.id} onClick={() => onChangeStep(step.id)} className={step.id === stepId ? 'active' : ''}>
                            <IconFont
                                type="TickCircle"
                                size={16}
                                color={appGuide.includes(step.id) ? 'var(--color-main)' : 'var(--color-gray-400)'}
                            />
                            <Text size={14}>{step.title}</Text>
                        </SCxItem>
                    ))}
                </SCxList>
            </SCxContent>
        </SCxContainer>
    )
}
