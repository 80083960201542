import { Button, flex, Input as OriginalInput, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
`

export const Close = styled.div`
    position: absolute;
    top: 10px;
    right: 12px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    color: var(--color-gray-500);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;

    &:hover {
        background-color: var(--color-gray-200);
        color: var(--color-gray-600);
    }
`

export const Form = styled.div`
    padding: 81px 72px;
    color: var(--color-black);
    height: 100%;
    width: 483px;
    overflow: auto;
    flex-shrink: 0;
`

export const Title = styled.div`
    width: 100%;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    margin: 0 auto 24px auto;
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const Label = styled.div`
    width: 100%;
    font-style: normal;
    font-size: 14px;
`

export const ThemeColorWrapper = styled.div`
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    padding-left: 10px;
    gap: 5px;
    height: 34px;
    width: 100%;
    background-color: var(--color-gray-100);
    border-radius: 8px;
`

export const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`

export const Input = styled(OriginalInput)``

export const SuffixIconWrapper = styled.div``

export const Row = styled.div`
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;
`

export const Card = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const CardImgWrapper = styled.div<{ active?: boolean }>`
    width: 100%;
    height: 88px;
    border-radius: 8px;
    border: ${({ active }) => (active ? '2px solid var(--color-main)' : '1px solid var(--color-gray-200)')};
    background-color: var(--color-gray-50);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const CardImg = styled.img`
    width: 126px;
`

export const CardText = styled.div<{ active?: boolean; color?: string }>`
    font-size: 12px;
    text-align: center;
    color: ${({ active }) => (active ? 'var(--color-main)' : 'var(--color-gray-400)')};
`

export const CreateContain = styled.div<{ justifyContent?: string }>`
    margin-top: 24px;
    ${flex}
    justify-content:  ${({ justifyContent }) => justifyContent ?? 'space-between'};
    align-items: center;
`

export const CreateButton = styled(Button)`
    padding: 12px 24px !important;
`

export const Layout = styled.div`
    flex: 1;
    background: linear-gradient(180deg, #f6f8fa 0%, #f3f5fc 46.61%, #eff8ff 100%);
    padding-top: 81px;
    padding-left: 81px;
    position: relative;
    height: 100%;
    overflow: hidden;
`
