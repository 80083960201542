import type { BlockAbstract, PageNode } from '@lighthouse/core'
import { BlockType, RecordOpenType } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useCallback, useEffect } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'

import { equalPageStack } from '../utils/equalPageStack'
import { pageBlocksAtom, pageNodesAtom, pageStackAtom, pageStackAtomFamily } from './state'
import type { PageMetaData, PageStackTree } from './types'
import { AsideType } from './types'

export function addNode(parentId: string, data: PageMetaData) {
    function recursion(id: string, tree?: PageStackTree[]) {
        if (!tree) {
            return
        }

        for (const stack of tree) {
            if (stack.stackId === id) {
                if (!stack.children) {
                    stack.children = []
                }
                stack.children.push(data)
                break
            }

            recursion(id, stack.children)
        }
    }

    return (tree: PageStackTree[]) => {
        recursion(parentId, tree)
    }
}

export function removeNode(id: string) {
    function recursion(tree?: PageStackTree[]) {
        if (!tree) {
            return
        }

        for (let i = 0; i < tree.length; i++) {
            if (tree[i].stackId === id) {
                tree.splice(i, 1)
                break
            }

            recursion(tree[i].children)
        }
    }

    return (tree: PageStackTree[]) => {
        recursion(tree)
    }
}

export function getNode(id: string) {
    function recursion(tree?: PageStackTree[]): PageMetaData | undefined {
        if (!tree) {
            return
        }

        for (const stack of tree) {
            if (stack.stackId === id) {
                return stack
            }

            const res = recursion(stack.children)
            if (res) {
                return res
            }
        }
    }

    return (tree: PageStackTree) => {
        if (tree.stackId === id) {
            return tree
        }
        return recursion(tree.children)
    }
}

type StackFactory = Partial<PageStackTree> & {
    appId: string
    pageId: string
}
export function stackFactory({
    appId,
    pageId,
    stackId = nanoid(),
    rootPageId = pageId,
    stackDisplayType = RecordOpenType.page,
    state,
    ...rest
}: StackFactory): PageStackTree {
    return {
        appId,
        stackId,
        pageId,
        rootPageId,
        stackDisplayType,
        state: {
            asideType: AsideType.PAGE,
            ...state
        },
        blockRuntimeState: {},
        ...rest
    }
}

/** 选中block时，检查block是否在当前的容器视图中，否则切换到block所在的视图 */
export const useSelectedBlockEffect = () => {
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const selectedBlock = useAtomData(
        pageStackAtomFamily({ stackId, rootPageId }),
        useCallback(s => s?.state.selectedNode, [])
    )

    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] || [], [pageId])
    )

    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] || [], [pageId])
    )

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    useEffect(() => {
        if (!selectedBlock) {
            return
        }

        function findNode(node: PageNode): boolean {
            if (node.id === selectedBlock) {
                return true
            }

            if (node.children) {
                for (const child of node.children) {
                    const res = findNode(child)
                    if (res) {
                        const block = pageBlocks.find(item => item.id === node.id)
                        if (!block) {
                            return true
                        }

                        if (block.type === BlockType.container) {
                            setPageStack(s => {
                                const stack = equalPageStack({ rootPageId, stackId })(s)
                                if (!stack) {
                                    return
                                }
                                const currentView = stack.blockRuntimeState?.container?.[block.id].currentView
                                if (currentView === child.id) {
                                    return
                                }
                                stack.blockRuntimeState.container = {
                                    ...stack.blockRuntimeState.container,
                                    [block.id]: {
                                        currentView: child.id
                                    }
                                }
                            })
                        }

                        return true
                    }
                }
            }

            return false
        }

        findNode({ id: 'root', width: 0, children: pageNodes })
    }, [pageBlocks, pageNodes, rootPageId, selectedBlock, setPageStack, stackId])
}
