import { Box, Button, Flex, IconFont, Select, Text, Tooltip } from '@byecode/ui'
import { type DataSourceAbstract, type SelectDataSourceVariable } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { SORT_OPTIONS } from '../../../../constants'
import { useSharedConfigDisabledWithVersion } from '../../../../contexts'
import { getFieldOptions } from '../../../../utils'
import { FieldTypeTag } from '../../../FieldTypeTag'

interface SortRuleProps {
    dataSource: DataSourceAbstract
}

const SCxContainer = styled.div`
    width: 100%;
`

const SCxItem = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
`

const SCxTitle = styled(Flex)`
    height: 40px;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    gap: 4px;
`
const SCxRemove = styled.div`
    padding: 2px;
    cursor: pointer;
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`
const SCxIcon = styled(IconFont)`
    color: var(--color-gray-400);
    cursor: pointer;
`

export const SortRule: React.FunctionComponent<SortRuleProps> = ({ dataSource }) => {
    const { control } = useFormContext<SelectDataSourceVariable>()
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'selectDataSourceVariable.sorts',
    })
    const disabledWithVersion = useSharedConfigDisabledWithVersion()

    const fieldOptions = useMemo(
        () => getFieldOptions(dataSource).map(item => ({ ...item, extra: <FieldTypeTag type={item.type} innerType={item.innerType} /> })),
        [dataSource]
    )

    const addFieldOptions = useMemo(
        () => fieldOptions.filter(item => !fields.map(item => item.id).includes(item.value)),
        [fieldOptions, fields]
    )

    return (
        <SCxContainer>
            <SCxTitle>
                <Text>排序规则</Text>
                <Tooltip
                    title={
                        <Box style={{ width: 193 }}>
                            当查找到多个记录时，将按照以下排序规则获得第一条记录。如果未设置规则，返回最近更新的一条记录
                        </Box>
                    }
                >
                    <SCxIcon size={16} type="Question" />
                </Tooltip>
            </SCxTitle>
            <Flex style={{ padding: '8px 0' }} gap={8} direction="column">
                {fields.map(({ id }, index) => (
                    <SCxItem key={id}>
                        <Controller
                            control={control}
                            name={`selectDataSourceVariable.sorts.${index}.fieldId`}
                            render={({ field }) => (
                                <Select
                                    styles={{
                                        root: {
                                            flex: 1
                                        }
                                    }}
                                    searchable
                                    options={fieldOptions}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={`selectDataSourceVariable.sorts.${index}.order`}
                            render={({ field }) => (
                                <Select
                                    styles={{
                                        root: {
                                            flex: 1
                                        }
                                    }}
                                    options={SORT_OPTIONS}
                                    value={field.value.toString()}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                        <SCxRemove onClick={() => remove(index)}>
                            <IconFont type="Close" size={16} />
                        </SCxRemove>
                    </SCxItem>
                ))}
                {fieldOptions.length > 0 && (
                    <Button
                        type="default"
                        icon={<SCxIcon size={16} type="Add" />}
                        radius={100}
                        style={{ width: 74 }}
                        onClick={() => append({ id: nanoid(), fieldId: addFieldOptions?.[0].value, order: 'ASC' })}
                    >
                        添加
                    </Button>
                )}
            </Flex>
        </SCxContainer>
    )
}
