import type { PageAbstract } from '@lighthouse/core'
import { BlockType, PageType, RecordOpenType } from '@lighthouse/core'
import { initBlockRuntimeState } from '@lighthouse/shared'
import { arrayMove } from '@lighthouse/tools'
import { atom } from 'jotai'
import { filter, find, findIndex, omit, reduce } from 'rambda'

import * as srv from '@/services'

import { languageAtom } from '../application/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'
import { hasBlockNegativeUpdate } from '../utils/block'
import { equalPageStack } from '../utils/equalPageStack'
import {
    blockCreatingListAtom,
    defaultPageListAtom,
    pageAtomFamily,
    pageBlocksAtom,
    pageListAtom,
    pageNodesAtom,
    pageStackAtom,
    pageStackBreadcrumbAtom,
    pageStackIndexHistoriesAtom
} from './state'
import type { FetchPageAtomPayload, MovePageAtomPayload, PageMetaData, PageStackTree } from './types'
import {
    type CreateBlockAtomPayload,
    type CreatePagePayload,
    type RemoveBlockAtomPayload,
    type SetPageAtomPayload,
    type UpdateBlockAtomPayload,
    type UpdateLocalBlockAtom,
    AsideType
} from './types'
import { addNode, getNode, stackFactory } from './utils'

/**
 * 获取页面列表
 */
export const fetchPageListAtom = atom(null, async (get, set) => {
    const data = await srv.getPages()
    set(pageListAtom, data)
    return data
})

/**
 * 获取页面信息
 */
export const fetchPageAtom = atom(null, async (_, set, { pageId, stackId, rootPageId }: FetchPageAtomPayload) => {
    const res = await srv.getPageContent({ params: { id: pageId } })
    if (!res) {
        return
    }
    const { nodes, blocks, recordPage, ...rest } = res

    set(setPageAtom, { ...rest, stackId, rootPageId, pageId, nodes, blocks })
    return res
})

/**
 * 设置页面信息
 */
export const setPageAtom = atom(null, (_, set, payload: SetPageAtomPayload) => {
    const { stackId, rootPageId, pageId, nodes, blocks, ...rest } = payload

    const pageAtom = pageAtomFamily(pageId)
    set(pageAtom, draft => {
        applyDraftPayload(draft, rest)
    })
    set(pageNodesAtom, draft => void (draft[pageId] = nodes))
    set(pageBlocksAtom, draft => void (draft[pageId] = blocks))
    set(pageStackAtom, draft => {
        const stack = equalPageStack({ rootPageId, stackId })(draft)
        if (!stack) {
            return
        }
        initBlockRuntimeState(stack, blocks)
    })
})

/** 创建页面 */
export const createPageAtom = atom(null, async (get, set, payload: CreatePagePayload) => {
    const lang = get(languageAtom)
    const pageId = await srv.addPage({ ...payload, lang: payload?.lang ?? lang })
    await set(fetchPageListAtom)
    return pageId
})

export const clonePageAtom = atom(null, async (_, set, payload: { lang: string; id: string }) => {
    const { lang, id } = payload
    const pageId = await srv.clonePage(id, lang)
    await set(fetchPageListAtom)
    return pageId
})

export const updateLocalPageAtom = atom(null, (_, set, payload: Partial<PageAbstract> & { id: string }) => {
    set(pageListAtom, draft => {
        const page = draft.find(i => i.id === payload.id)
        if (!page) {
            return
        }

        for (const [k, v] of Object.entries(payload)) {
            Reflect.set(page, k, v)
        }
    })
})
export const updateRemotePageAtom = atom(null, (_, set, payload: Partial<PageAbstract> & { id: string }) => {
    return srv.updatePage(payload)
})
/** 更新页面 */
export const updatePageAtom = atom(null, (_, set, payload: Partial<PageAbstract> & { id: string }) => {
    set(pageListAtom, draft => {
        const page = draft.find(i => i.id === payload.id)
        if (!page) {
            return
        }

        for (const [k, v] of Object.entries(payload)) {
            Reflect.set(page, k, v)
        }
    })

    return srv.updatePage(payload)
})

/** 设置页面主页 */
export const updateHomePageAtom = atom(null, async (_, set, payload: Pick<PageAbstract, 'id' | 'isHome'>) => {
    const isSuccess = await srv.updatePage(payload)
    if (isSuccess) {
        set(pageListAtom, draft => {
            const page = draft?.find(i => i.id === payload.id)
            const currentHomePageIndex = draft?.findIndex(i => i.isHome)
            if (!page) {
                return
            }
            if (currentHomePageIndex !== -1) {
                draft[currentHomePageIndex].isHome = false
            }
            for (const [k, v] of Object.entries(payload)) {
                Reflect.set(page, k, v)
            }
        })
    }
    return isSuccess
})

/** 移除页面 */
export const removePageAtom = atom(null, async (get, set, id: string) => {
    const isSuccess = await srv.deletePage(id)
    const pageList = get(pageListAtom)
    const language = get(languageAtom)
    const newPageList = pageList.filter(item => item.id !== id)
    const defaultPageList = filter(item => item.type === PageType.default && language === item.language, newPageList)
    const firstPageId = defaultPageList?.[0]?.id ?? ''
    if (isSuccess) {
        const deletePage = find(page => page.id === id, pageList)
        set(pageListAtom, newPageList)
        if (deletePage?.isHome && firstPageId) {
            const pageAtom = pageAtomFamily(firstPageId)
            set(pageAtom, draft => {
                if (draft) {
                    draft.isHome = true
                }
            })
        }
    }
    return firstPageId
})

/** 移动页面 */
export const movePageAtom = atom(null, async (get, set, payload: MovePageAtomPayload) => {
    const { id, oldId, newId } = payload
    const pageList = get(pageListAtom)
    const [oldIndex, newIndex] = reduce<PageAbstract, number[]>(
        (preVal, curVal, i: number) => {
            if (curVal.id === oldId) {
                preVal[0] = i
                return preVal
            }
            if (curVal.id === newId) {
                preVal[1] = i
                return preVal
            }
            return preVal
        },
        [-1, -1],
        pageList
    )
    const list = arrayMove(pageList ?? [], oldIndex, newIndex)
    const after = list[newIndex - 1]?.id ?? ''
    const before = list[newIndex + 1]?.id ?? ''
    set(pageListAtom, list)
    const isSuccess = await srv.movePage({ id, before, after })
    if (!isSuccess) {
        set(pageListAtom, pageList)
    }
    return isSuccess
})

/** 删除根页面时， 清除访问记录 */
export const deleteRootPageStackAtom = atom(null, (get, set, payload: string) => {
    const stackTree = get(pageStackAtom)
    const index = stackTree.findIndex(item => item.pageId === payload)
    if (index === -1) {
        return
    }

    const removedPageStack = stackTree[index]
    const removedPage = get(pageAtomFamily(removedPageStack.pageId))
    if (!removedPage) {
        return
    }

    // 如果删除的页面和上一个历史页面的语言版本不一样，则增加当前语言版本的第一个页面到历史栈中
    let prevPageEqualLang = false
    const prevPageStackHistory = get(pageStackIndexHistoriesAtom).at(-2)
    if (prevPageStackHistory) {
        const [prevIndex] = prevPageStackHistory
        const prevPageStack = stackTree[prevIndex]
        const prevPage = get(pageAtomFamily(prevPageStack.pageId))
        if (prevPage) {
            prevPageEqualLang = prevPage.language === removedPage.language
        }
    }

    // 如果当前是最后一个页面，直接清空历史栈
    const firstPage = get(defaultPageListAtom).find(item => item.id !== removedPage.id)
    if (!firstPage) {
        set(pageStackAtom, [])
        set(pageStackIndexHistoriesAtom, [])
        return
    }
    const newStack = stackFactory({ appId: firstPage.appId, pageId: firstPage.id })

    // 清除stack page tree
    set(pageStackAtom, draft => {
        draft.splice(index, 1)
        if (!prevPageEqualLang) {
            draft.push(newStack)
        }
    })

    // 清除被删除的根页面的访问记录
    set(pageStackIndexHistoriesAtom, draft => {
        const newPageStackTree = get(pageStackAtom)
        const filteredList = draft
            .filter(item => {
                const [i] = item
                return i !== index
            })
            .map(item => {
                const [, stackId] = item
                const newIndex = newPageStackTree.findIndex(item => item.stackId === stackId)
                return [newIndex, stackId]
            })

        if (!prevPageEqualLang) {
            filteredList.push([newPageStackTree.length - 1, newStack.stackId])
        }
        return filteredList
    })
})

/** 打开页面：更新stack以及操作记录的索引  */
export const openPageStackAtom = atom(null, (get, set, payload: PageMetaData) => {
    const stackTree = get(pageStackAtom)
    const stackIndexes = get(pageStackIndexHistoriesAtom)

    if (stackIndexes.length === 0 && stackTree.length === 0) {
        set(pageStackAtom, draft => {
            draft.push(payload)
        })

        // 更新索引
        set(pageStackIndexHistoriesAtom, draft => {
            draft.push([0, payload.stackId])
        })
        return
    }

    const [rootIndex, stackId] = stackIndexes[stackIndexes.length - 1]

    const root = stackTree[rootIndex]
    const current = getNode(stackId)(root)
    if (!current) {
        return
    }

    // 打开的页面是一个根页面
    if (payload.pageId === payload.rootPageId) {
        // 如果当前既不是顶级页面，和要打开的页面也同属一个顶级页面，则更新索引位置
        if (current.pageId !== current.rootPageId && current.rootPageId === payload.rootPageId) {
            set(pageStackIndexHistoriesAtom, draft => {
                // 不能用payload的stackId, 必须使用之前的stackId, 要不然查找node会有问题
                draft.push([rootIndex, root.stackId])
            })
            return
        }

        // 和当前页面的根页面id不同，则查找要打开的顶级页面最近的弹窗打开记录
        if (current.rootPageId !== payload.rootPageId) {
            const lastIndexes = stackIndexes.findLast(item => {
                const [r, s] = item
                // billy说去掉回到子级页面的逻辑 12.18
                return stackTree[r]?.pageId === payload.pageId && stackTree[r]?.stackId === s
                // if (stackTree[r].pageId === payload.pageId) {
                //     return !!getNode(s)(stackTree[r])
                // }
                // return false
            })

            if (lastIndexes) {
                set(pageStackIndexHistoriesAtom, draft => {
                    draft.push(lastIndexes)
                })
            } else {
                // 否则新增根节点
                const { length } = stackTree

                set(pageStackAtom, draft => {
                    draft.push(payload)
                })

                // 更新索引
                set(pageStackIndexHistoriesAtom, draft => {
                    draft.push([length, payload.stackId])
                })
            }
        }

        return
    }

    // 新增子节点
    set(pageStackAtom, draft => {
        // 如果已经有该节点了，则不插入
        if (getNode(payload.stackId)(draft[rootIndex])) {
            return
        }

        const parent = getNode(stackId)(draft[rootIndex])
        if (!parent) {
            return
        }
        addNode(parent.stackId, payload)(draft)
    })

    // 更新索引
    set(pageStackIndexHistoriesAtom, draft => {
        draft.push([rootIndex, payload.stackId])
    })
})

// /** 关闭当前以 layer 形式呈现的栈 */
export const closeCurrentPageLayerStackAtom = atom(null, (get, set) => {
    const pageStackBreadcrumb = get(pageStackBreadcrumbAtom)

    set(pageStackIndexHistoriesAtom, draft => {
        const current = draft[draft.length - 1]
        const prev = pageStackBreadcrumb[pageStackBreadcrumb.length - 2]

        const pageStack = get(pageStackAtom)

        const [rootPageIndex] = current

        const rootPageStackId = pageStack[rootPageIndex].stackId

        if (current) {
            draft.push([current[0], prev ? prev.stackId : rootPageStackId])
        }
    })
})

/** 关闭所有弹窗形式的栈 */
export const closeAllPageLayerAtom = atom(null, (get, set) => {
    const stackTree = get(pageStackAtom)
    set(pageStackIndexHistoriesAtom, draft => {
        const current = draft[draft.length - 1]
        const [rootIndex, stackId] = current
        const rootStack = stackTree[rootIndex]

        let recentPageTypeParentStack: PageMetaData | undefined
        function getParentPageTypeStack(tree: PageStackTree): boolean {
            if (!tree.children) {
                return false
            }

            for (const node of tree.children) {
                if (node.stackId === stackId) {
                    return true
                }

                const res = getParentPageTypeStack(node)
                if (res) {
                    if (node.stackDisplayType === RecordOpenType.page && !recentPageTypeParentStack) {
                        recentPageTypeParentStack = omit('children', node)
                    }
                    return res
                }
            }

            return false
        }

        getParentPageTypeStack(rootStack)

        draft.push([rootIndex, recentPageTypeParentStack ? recentPageTypeParentStack.stackId : rootStack.stackId])
    })
})

/** 回退页面栈历史记录 */
export const rollbackPageStackHistoryAtom = atom(null, (_, set) => {
    set(pageStackIndexHistoriesAtom, draft => {
        if (draft.length === 1) {
            return
        }
        draft.pop()
    })
})

/** 创建页面block */
export const createBlockAtom = atom(null, async (_, set, payload: CreateBlockAtomPayload) => {
    const { blocks, rootPageId, pageId, stackId, autoSelect = true } = payload
    set(blockCreatingListAtom, s => [...s, ...blocks.map(item => item.id)])

    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (currentBlocks) {
            currentBlocks.push(...blocks)
        } else {
            draft[pageId] = blocks
        }
    })
    set(pageStackAtom, draft => {
        const stack = equalPageStack({ rootPageId, stackId })(draft)
        if (stack && stack.state) {
            initBlockRuntimeState(stack, blocks)

            if (autoSelect) {
                stack.state.asideType = AsideType.BLOCK
                stack.state.selectedNode = blocks[0].id
            }
        }
    })

    const res = await srv.createBlocks({
        blocks,
        pageId
    })

    set(blockCreatingListAtom, s => s.filter(item => !blocks.some(block => block.id === item)))

    if (!res) {
        return false
    }

    // 创建视图block时，需要获取视图的详情和表单页面
    if (blocks.some(item => item.type === BlockType.view && !!item.config.pointer)) {
        await set(fetchPageListAtom)
    }
    return res
})

export const updateRemoteBlockAtom = atom(null, async (_, set, payload: UpdateBlockAtomPayload) => {
    const { pageId, rootPageId, stackId, block, origin, appId } = payload
    const res = await srv.updateBlock(block, pageId)

    if (res && hasBlockNegativeUpdate(appId, block, origin)) {
        await set(fetchPageAtom, { pageId, rootPageId, stackId })

        // set(updateLocalBlockAtom, { pageId, block })
        // 更换视图block的数据源时，需要重新获取视图的详情和表单页面
        // await set(fetchPageListAtom)
    }
})
export const updateLocalBlockAtom = atom(null, (_, set, payload: UpdateLocalBlockAtom) => {
    const { pageId, block } = payload
    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }
        const draftBlock = currentBlocks.find(item => item.id === block.id)
        if (!draftBlock) {
            return
        }
        Object.entries(block).forEach(([k, v]) => {
            Reflect.set(draftBlock, k, v)
        })
    })
})

/** 更新页面block */
export const updateBlockAtom = atom(null, async (_, set, payload: UpdateBlockAtomPayload) => {
    const { block, origin, appId } = payload

    if (!hasBlockNegativeUpdate(appId, block, origin)) {
        set(updateLocalBlockAtom, payload)
    }

    await set(updateRemoteBlockAtom, payload)
})

/** 移除页面block */
export const removeBlockAtom = atom(null, (_, set, payload: RemoveBlockAtomPayload) => {
    const { rootPageId, stackId, pageId, blockIds } = payload

    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        draft[pageId] = currentBlocks.filter(item => !blockIds.includes(item.id))
    })

    set(pageStackAtom, draft => {
        const stack = equalPageStack({ rootPageId, stackId })(draft)
        if (stack) {
            stack.state.selectedNode = undefined
            stack.state.asideType = AsideType.PAGE
        }
    })

    return srv.deleteBlock({ ids: blockIds, pageId })
})

export const addPageRolesAtom = atom(null, async (_, set, params: { roleIds: string[]; departmentIds: string[]; pageId: string }) => {
    const { pageId, roleIds, departmentIds } = params
    const isSuccess = await srv.addRoleToPage(pageId, roleIds, departmentIds)
    if (isSuccess) {
        set(pageListAtom, draft => {
            const index = findIndex(page => page.id === pageId, draft ?? [])
            if (index !== -1 && draft) {
                draft[index].roleIds = roleIds
                draft[index].departmentIds = departmentIds
            }
            return draft
        })
    }
})

export const removePageRolesAtom = atom(null, (_, set, params: { id: string; pageId: string; type: 'role' | 'department' }) => {
    const { pageId, id, type } = params
    set(pageListAtom, draft => {
        const index = findIndex(page => page.id === pageId, draft ?? [])
        if (index !== -1 && draft) {
            const { roleIds = [], departmentIds } = draft[index]
            if (type === 'role') {
                draft[index].roleIds = filter(roleId => roleId !== id, roleIds)
            } else {
                draft[index].departmentIds = filter(departmentId => departmentId !== id, departmentIds)
            }
        }
        return draft
    })
    return srv.deleteRole(pageId, id, type)
})
