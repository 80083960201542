import type { InputProps } from '@byecode/ui'
import { IconFont, Input, Loading, Popover } from '@byecode/ui'
import { useDebounce } from '@lighthouse/tools'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { type FieldErrors } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import styled, { css } from 'styled-components'
import { useImmer } from 'use-immer'

import * as srv from '@/services'
import type { CreateApplicationPayload } from '@/services/types'

const SCxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
`

const SCxInputWrapper = styled.div`
    flex: 1;
    overflow: hidden;
`

const SCxInput = styled(Input)<{ isError?: boolean }>`
    &.byecode-Input-wrapper:focus,
    &.byecode-Input-wrapper:focus-within {
        background-color: var(--color-white) !important;
        border-color: ${({ isError }) => (isError ? 'var(--color-red-500)' : 'var(--color-main)')};
    }
    border-color: ${({ isError }) => (isError ? 'var(--color-red-500)' : 'transparent')};
`

const SCxValidateContainer = styled.div`
    padding: 0 8px;
    width: 100%;
`

const SCxValidateList = styled.div`
    width: 311px;
`

const SCxValidateListItem = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: ${({ active }) => (active ? 'var(--color-red-500)' : 'var(--color-gray-500)')};
`

const SCxValidateIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxValidateDot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 8px;
    background-color: var(--color-gray-500);
`

const SCxLoadingWrapper = styled.div`
    padding: 4px 0;
`

const SCxValidateText = styled.div`
    white-space: nowrap;
    font-size: 12px;
`

const SCxTickWrapper = styled.div`
    width: 16px;
    height: 16px;
`

enum ValidateMode {
    success = 'success',
    validate = 'validate',
    loading = 'loading'
}

// type ValidateListItem = {
//     type: string
//     message: string
// }
export enum UsedMode {
    used = 'used',
    unused = 'unused',
    notValidate = 'notValidate'
}

interface ValidateInputState {
    open: boolean
    mode: ValidateMode
}

export interface ValidateInputProps extends InputProps {
    // validateList: ValidateListItem[]
    name: string
    value: string
    usedMode: UsedMode
    errors: FieldErrors
    onUsedMode: (val: UsedMode) => void
    onChangeUsed?: (v?: boolean) => void
    onCheck?: (v: string) => Promise<boolean>
}

const validateList = [
    {
        type: 'minLength',
        message: '最短长度为8个字符...'
    },
    {
        type: 'checkDomainStartAndEnd',
        message: '不能以连字符开头或结尾'
    },
    {
        type: 'checkDomainChars',
        message: '使用英文字母（a-z）、数字（0-9）和连字符（-）'
    },
    {
        type: 'maxLength',
        message: '最长长度为32个字符...'
    }

]

export const ValidateInput: React.FC<ValidateInputProps> = ({
    errors,
    usedMode,
    value,
    name,
    onChange,
    onUsedMode,
    onCheck,
    onChangeUsed,
    ...rest
}) => {
    const inputRef = useRef<HTMLDivElement>(null)
    const [state, setState] = useImmer<ValidateInputState>({
        mode: ValidateMode.validate,
        open: false
        // usedMode: UsedMode.notValidate
    })
    const { mode, open } = state
    const isHasError = !!value && !!errors?.[name]
    const domain = useDebounce(value, 500)

    const checkDomain = useCallback(
        async (domain: string) => {
            setState(draft => {
                draft.mode = ValidateMode.loading
            })
            const isUsed = await onCheck?.(domain)
            onChangeUsed?.(isUsed)
            if (isUsed) {
                setState(draft => {
                    draft.open = false
                    draft.mode = ValidateMode.success
                })
                onUsedMode?.(UsedMode.used)
                return
            }
            setState(draft => {
                draft.mode = ValidateMode.validate
            })
            onUsedMode?.(UsedMode.unused)
        },
        [onChangeUsed, onCheck, onUsedMode, setState]
    )
    // }, [])
    useEffect(() => {
        if (domain && !errors?.[name]) {
            checkDomain(domain)
        }
    }, [checkDomain, domain, errors, name])

    const handleOpenChange = useCallback(
        (val: boolean) => {
            if (val === open) {
                return
            }
            setState(draft => {
                draft.open = val
            })
        },
        [open, setState]
    )

    const handleFocus = useCallback(() => {
        if (usedMode === UsedMode.used) {
            return
        }
        setState(draft => {
            draft.open = true
        })
    }, [setState, usedMode])

    const handleBlur = useCallback(() => {
        if (!value && open) {
            handleOpenChange(false)
        }
    }, [handleOpenChange, open, value])

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.open = true
                draft.mode = ValidateMode.validate
            })
            onUsedMode?.(UsedMode.notValidate)
            onChange?.(ev)
        },
        [onChange, onUsedMode, setState]
    )

    const validateContent = useMemo(() => {
        if (mode === ValidateMode.loading || mode === ValidateMode.success) {
            return (
                <SCxLoadingWrapper>
                    <Loading
                        outlined
                        size={16}
                        iconColor="var(--color-main)"
                        description={<span style={{ color: 'var(--color-main)', whiteSpace: 'nowrap', fontSize: 12 }}>校验中...</span>}
                    />
                </SCxLoadingWrapper>
            )
        }

        if (usedMode === UsedMode.unused) {
            return (
                <SCxValidateListItem>
                    <SCxValidateIcon>
                        <IconFont fill="var(--color-red-500)" type="CloseCircle" />
                    </SCxValidateIcon>
                    <SCxValidateText>该域名已被占用</SCxValidateText>
                </SCxValidateListItem>
            )
        }

        if (value && isHasError) {
            return (
                <SCxValidateList>
                    {validateList.map(item => (
                        <SCxValidateListItem key={item.type}>
                            <SCxValidateIcon>
                                {item.type === errors?.[name]?.type ? (
                                    <IconFont fill="var(--color-red-500)" type="CloseCircle" />
                                ) : (
                                    <IconFont fill="var(--color-green-500)" type="Tick" />
                                )}
                            </SCxValidateIcon>
                            <SCxValidateText>{item.message}</SCxValidateText>
                        </SCxValidateListItem>
                    ))}
                </SCxValidateList>
            )
        }

        return (
            <SCxValidateList>
                {validateList.map(item => (
                    <SCxValidateListItem key={item.type}>
                        <SCxValidateIcon>
                            <SCxValidateDot />
                        </SCxValidateIcon>
                        <SCxValidateText>{item.message}</SCxValidateText>
                    </SCxValidateListItem>
                ))}
            </SCxValidateList>
        )
    }, [errors, isHasError, mode, name, usedMode, value])

    return (
        <SCxContainer>
            <SCxInputWrapper ref={inputRef}>
                <SCxInput
                    isError={isHasError || usedMode === UsedMode.unused}
                    focusOutLine={!isHasError || usedMode === UsedMode.unused}
                    onClick={handleFocus}
                    onBlur={handleBlur}
                    value={value}
                    onChange={handleChange}
                    {...rest}
                />
            </SCxInputWrapper>
            {usedMode === UsedMode.used && (
                <SCxTickWrapper>
                    <IconFont type="TickCircle" fill="var(--color-green-500)" size={16} />
                </SCxTickWrapper>
            )}
            <Popover
                trapFocus={false}
                width="auto"
                withinOverlay={false}
                withinPortal={false}
                opened={open}
                withArrow
                position="top"
                offsetOptions={25}
            >
                <Popover.AnchorEl anchorEl={inputRef.current} />
                <Popover.Dropdown style={{ border: 'none' }}>
                    <SCxValidateContainer>{validateContent}</SCxValidateContainer>
                </Popover.Dropdown>
            </Popover>
        </SCxContainer>
    )
}
