import type { PageStackTree } from '../page/types'
import { getNode } from '../page/utils'

/**
 * 是否符合给定的page stack
 * @date 9/20/2023 - 6:42:52 PM
 *
 * @returns {(stack: PageMetaData) => boolean}
 */
export const equalPageStack =
    ({ rootPageId, stackId }: { rootPageId: string; stackId: string }) =>
    (tree: PageStackTree[]) => {
        const stack = tree.findLast(item => item.pageId === rootPageId)
        if (!stack) {
            return
        }
        return getNode(stackId)(stack)
    }
