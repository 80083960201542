import {
    Flex,
    Image,
    Text
} from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

interface BlockCardProps {
    data: BlockTreeItem
    onOpenQuestion?: (v: BlockTreeItem) => void
    onCreate?: (e: React.MouseEvent<HTMLDivElement>, v: BlockTreeItem) => void
}

const SCxItem = styled(Flex)`
    position: relative;
    display: flex;
    width: 72px;
    height: 72px;
    align-items: flex-end;
    justify-content: center;
    background-color: var(--color-gray-100);
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;

    img {
        -webkit-user-drag: none;
    }

    &:hover {
        background-color: var(--color-gray-200);

        .tooltipIcon {
            display: block;
        }
    }

    .tooltipIcon {
        display: none;
    }
`

const SCxBlockImage = styled(Image)`
    position: absolute;
    inset: 0;
`
const BlockCard: React.FunctionComponent<BlockCardProps> = ({ data, onCreate, onOpenQuestion }) => {
    const { describeImage, name, color } = data

    return (
        <SCxItem onMouseDown={e => onCreate?.(e, data)}>
            <SCxBlockImage src={describeImage} />
            {/* <TagIcon
                iconSize={16}
                size={16}
                icon="Question"
                className="tooltipIcon"
                color="var(--color-gray-400)"
                hoverColor='var(--color-gray-900)'
                enableHover
                onClick={() => onOpenQuestion?.(data)}
                style={{ position: 'absolute', right: 4, top: 4 }}
            /> */}
            <Text size={12} lineHeight="20px" algin="center" style={{ marginBottom: 4 }} color="var(--color-gray-700)">
                {name}
            </Text>
        </SCxItem>
    )
}

export default BlockCard
