import type { BaseModalProps } from '@byecode/ui'
import { Flex, IconFont, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { SpaceVersion } from '@lighthouse/core'
import { getBrand, spaceGradeNameMap } from '@lighthouse/shared'
import { getFileSizeAccordanceBaseUnit } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import type { QuotaPromptData } from '../QuotaPromptModal'
import { QuotaPromptModal } from '../QuotaPromptModal'
import { ALL_SPACE_VERSION } from './constants'
import { openSaleSpaceGrade, openSpaceGrade } from './event'

const Button = styled.button<{ styleTheme?: 'dark' | 'light' | 'pro'; bgColor?: string }>`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;

    flex: 1;
    border-radius: 8px;
    padding: 5px;
    line-height: 22px;
    font-size: 14px;
    text-align: center;

    &:hover {
        opacity: 0.8;
    }

    ${({ styleTheme, bgColor }) => {
        if (styleTheme === 'pro') {
            return css`
                color: #f0dfb7;
                background-color: var(--color-black);
            `
        }

        if (styleTheme === 'dark') {
            return css`
                background-color: var(--color-gray-100);
            `
        }

        return css`
            color: var(--color-white);
            background-color: ${bgColor};
        `
    }}
`

const COLOR_MAP: Record<SpaceVersion, string> = {
    BASIC: '#3670FF',
    STANDARD: '#5551FF',
    ADVANCED: '#3081EA',
    PROFESSIONAL: 'var(--color-black)',
    FREE: '',
    ENTERPRISE: '',
    ULTIMATE: '',
    I_CLICK_FREE: '',
    I_CLICK_BASIC: '',
    I_CLICK_STANDARD: '',
    I_CLICK_ADVANCED: '',
    I_CLICK_PROFESSIONAL: '',
    I_CLICK_ENTERPRISE: '',
    I_CLICK_ULTIMATE: ''
}

const BACKGROUND_ASSETS: Record<SpaceVersion, string> = {
    BASIC: getAssetUrl('common', 'space_quota_basic.png'),
    STANDARD: getAssetUrl('common', 'space_quota_standard.png'),
    ADVANCED: getAssetUrl('common', 'space_quota_advanced.png'),
    PROFESSIONAL: getAssetUrl('common', 'space_quota_pro.png'),
    FREE: '',
    ENTERPRISE: '',
    ULTIMATE: '',
    I_CLICK_FREE: '',
    I_CLICK_BASIC: '',
    I_CLICK_STANDARD: '',
    I_CLICK_ADVANCED: '',
    I_CLICK_PROFESSIONAL: '',
    I_CLICK_ENTERPRISE: '',
    I_CLICK_ULTIMATE: ''
}

interface VersionConfirmProps extends BaseModalProps {
    version: SpaceVersion
    onOpenPayModal: () => void
}

export const VersionConfirm: React.FunctionComponent<VersionConfirmProps> = ({ version, onOpenPayModal, onClose, ...modalProps }) => {
    const versionInfo = useMemo(() => ALL_SPACE_VERSION.find(item => item.version === version), [version])

    const color = COLOR_MAP[version]

    const data = useMemo(() => {
        if (!versionInfo) {
            return
        }

        return [
            {
                key: 'publishedAppNumLimit',
                icon: <IconFont type="CheckboxTick" size={20} fill={color} />,
                name: '可发布应用数量',
                value: versionInfo.publishedAppNumLimit
            },
            {
                key: 'externalUserLimit',
                icon: <IconFont type="CheckboxTick" size={20} fill={color} />,
                name: '登录用户数量',
                value: versionInfo.externalUserLimit === -1 ? '不限' : versionInfo.externalUserLimit
            },
            {
                key: 'accessFlow',
                icon: <IconFont type="CheckboxTick" size={20} fill={color} />,
                name: '访问流量',
                value: `${getFileSizeAccordanceBaseUnit(versionInfo.accessFlow, 'MB')}/年`
            },
            {
                key: 'storageLimit',
                icon: <IconFont type="CheckboxTick" size={20} fill={color} />,
                name: '附件存储',
                value: getFileSizeAccordanceBaseUnit(versionInfo.storageLimit, 'MB')
            },
            {
                key: 'recordsLimit',
                icon: <IconFont type="CheckboxTick" size={20} fill={color} />,
                name: '数据表总记录数',
                value: versionInfo.recordsLimit.toLocaleString()
            }
        ] satisfies QuotaPromptData[]
    }, [color, versionInfo])

    const isHighLevel = version === 'PROFESSIONAL' || version === 'ADVANCED'

    if (!data) {
        return null
    }

    return (
        <QuotaPromptModal
            {...modalProps}
            onClose={onClose}
            data={data}
            leftPanelProps={{
                style: { background: `url(${BACKGROUND_ASSETS[version]}) center center / 100% 100%` },
                children: (
                    <Flex direction="column" gap={8}>
                        <Text weight={600} size={15} color={isHighLevel ? 'var(--color-white)' : color}>
                            升级到{spaceGradeNameMap[version]}
                        </Text>
                        <Text size={12} color={isHighLevel ? '#FFFFFF99' : 'var(--color-gray-500)'} ellipsis={false} whiteSpace="unset">
                            {versionInfo?.describe}
                        </Text>
                        <Flex alignItems="center" gap={8}>
                            <Text weight={700} size={28} fontFamily="DIN Alternate" color={version === 'PROFESSIONAL' ? '#D7BD84' : color}>
                                ￥{versionInfo?.price}
                            </Text>
                            <Text size={12} color={isHighLevel ? '#FFFFFF99' : 'var(--color-gray-600)'}>
                                /每年
                            </Text>
                        </Flex>
                    </Flex>
                )
            }}
            rightTitle="您将会获得:"
            rightActions={
                <Flex gap={12} justifyContent="flex-end">
                    <Button
                        styleTheme="dark"
                        onClick={() => {
                            openSpaceGrade('version_upgrade')
                            onClose?.()
                        }}
                    >
                        了解更多
                    </Button>
                    <Button
                        styleTheme={version === 'PROFESSIONAL' ? 'pro' : 'light'}
                        bgColor={color}
                        onClick={() => {
                            const brand = getBrand()
                            brand === 'I_CLICK' ? openSaleSpaceGrade() : onOpenPayModal()
                            onClose?.()
                        }}
                    >
                        立即购买
                    </Button>
                </Flex>
            }
        />
    )
}
