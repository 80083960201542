import { ButtonBlock } from '@lighthouse/block'
import type { ButtonBlockAbstract } from '@lighthouse/core'
import React from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'

interface ButtonBlockProps {
    blockData: ButtonBlockAbstract
}

const ButtonBlockController: React.FC<ButtonBlockProps> = ({ blockData }) => {
    const {
        config: { group: list }
    } = blockData

    const { handleActionTrigger } = useActionTrigger(list)

    const { handleRenderTitle } = useRichTextToTitle()

    return <ButtonBlock onActionTrigger={handleActionTrigger} onRenderTitle={handleRenderTitle} blockData={blockData} />
}

export default ButtonBlockController
