import { Button, Divider, Empty, IconFont, Input, Popover, tinyButtons } from '@byecode/ui'
import { getDepartmentTree } from '@lighthouse/shared'
import { filter, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useDataSourceDepartments, useDataSourceRoles } from '@/hooks/useDataSource'

import { DepartmentItem } from './DepartmentItem'
import GroupItem from './GroupItem'

export interface TagData {
    type: 'department' | 'role'
    name: string
    id: string
}

export const SCxDropDownContainer = styled.div`
    padding: 12px;
    width: 100%;
    display: flex;
    max-height: 600px;
    flex-direction: column;
    gap: 8px;
`
export const SCxDropDownList = styled.div`
    flex: 1;
    width: 100%;
    overflow: hidden auto;
    ${tinyButtons}/* max-height: 100%; */
`
export const SCxDropDownFooter = styled.div`
    padding: 8px 4px 4px 4px;
    display: flex;
    gap: 12px;
`

export const SCxTitle = styled.div`
    height: 32px;
    line-height: 32px;
    color: var(--color-gray-500);

    font-size: var(--font-size-sm);
`

export const SCxDropDown = styled.div``

export type TagProps = {
    value?: TagData[]
    name?: string
    color?: string
    disabled?: boolean
    withinPortal?: boolean

    onAddUsers: (users: TagData[]) => Promise<void>
}

// function getNewDepartment(
//     departmentList: (AppDepartmentWidthChild & { nameList?: string[] })[],
//     department?: AppDepartment & { nameList?: string[] }
// ): (AppDepartment & { nameList?: string[] })[] {
//     const { nameList = [], departmentId: parentId } = department ?? {}
//     return departmentList.map(childDepartment => {
//         const { children = [], name = '', departmentId = '', managerUserId } = childDepartment
//         const newDepartment = {
//             ...childDepartment,
//             nameList: [...nameList, name]
//         }
//         return {
//             ...newDepartment,
//             departmentChild: getNewDepartment(children, newDepartment)
//         }
//     })
// }

export const AddMember: React.FC<TagProps> = ({
    name = '添加成员',
    color = '#fff',
    value = [],
    disabled = false,
    withinPortal,
    onAddUsers
}) => {
    const roles = useDataSourceRoles()
    const dataSourceDepartments = useDataSourceDepartments()

    const departments = useMemo(() => getDepartmentTree(dataSourceDepartments), [dataSourceDepartments])

    const [keyword, setKeyword] = useState('')
    const [opened, setOpened] = useState(false)
    const [data, setData] = useState<TagData[]>([])

    const dropDownData = useMemo(() => [...value, ...data], [data, value])

    const [roleList, departmentList] = useMemo(() => {
        const roleList = filter(item => item.name.includes(keyword), roles)
        const searchDepartmentList = filter(item => item.name.includes(keyword), departments)
        return [roleList, keyword ? searchDepartmentList : departments]
    }, [departments, keyword, roles])

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }, [])

    const handleChange = useCallback(
        (id: string, type: 'department' | 'role') => {
            setData(draft => {
                const isExit = find(item => item.id === id && item.type === type, draft)
                if (isExit) {
                    return draft.filter(item => item.id !== id && item.type !== type)
                }
                const name =
                    type === 'role' ? find(item => item.id === id, roleList)?.name : find(item => item.id === id, departmentList)?.name
                return [...draft, { id, type, name: name ?? '' }]
            })
        },
        [departmentList, roleList]
    )

    useEffect(() => {
        if (!opened) {
            setKeyword('')
            setData([])
        }
    }, [opened])

    return (
        <>
            <Popover
                width={346}
                withinPortal={withinPortal}
                position="bottom-start"
                opened={opened}
                onChange={setOpened}
                onClose={() => setOpened(false)}
            >
                <Popover.Target>
                    <Button
                        disabled={disabled}
                        style={{ marginTop: 6 }}
                        radius={100}
                        icon={<IconFont size={16} fill="var(--color-gray-500)" type="Add" />}
                    >
                        {name}
                    </Button>
                </Popover.Target>
                <Popover.Dropdown compact>
                    <SCxDropDownContainer>
                        <Input
                            placeholder="搜索"
                            style={{ flexShrink: 0 }}
                            prefix={<IconFont size={16} type="Search" />}
                            value={keyword}
                            onChange={handleSearch}
                        />
                        <SCxDropDownList>
                            <SCxTitle>角色</SCxTitle>
                            {roleList.map(item => (
                                <GroupItem
                                    data={item}
                                    disabled={value.some(v => v.type === 'role' && v.id === item.id)}
                                    checked={dropDownData.some(v => v.id === item.id && v.type === 'role')}
                                    key={item.id}
                                    onClick={() => handleChange(item.id, 'role')}
                                />
                            ))}
                            {roleList.length === 0 && <Empty icon="Nodata-8i554976" style={{ height: 86 }} description="没有数据" />}
                            <Divider style={{ margin: '12px 0' }} />
                            <SCxTitle>部门</SCxTitle>
                            {departmentList.map(item => (
                                <DepartmentItem
                                    key={item.id}
                                    data={item}
                                    disableItemList={value}
                                    level={0}
                                    value={data}
                                    type={keyword ? 'breadcrumbs' : 'collapse'}
                                    onChange={handleChange}
                                />
                            ))}
                            {departmentList.length === 0 && <Empty icon="Nodata-8i554976" style={{ height: 86 }} description="没有数据" />}
                        </SCxDropDownList>
                        <SCxDropDownFooter>
                            <Button block size="lg" onClick={() => setOpened(false)}>
                                取消
                            </Button>
                            <Button
                                block
                                type="primary"
                                size="lg"
                                onClick={() => {
                                    onAddUsers(dropDownData)
                                    setOpened(false)
                                }}
                            >
                                确定
                            </Button>
                        </SCxDropDownFooter>
                    </SCxDropDownContainer>
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
