import { IconFont } from '@byecode/ui'
import type { FilterBlockAbstract } from '@lighthouse/core'
import { type DataSourceAbstract, type ViewOptions, DataSourceType, FilterMode } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    CAN_SELECT_OPTIONS,
    DataGroupConfigure,
    FieldTypeTag,
    FindUseType,
    getAppointField,
    getFieldIcon,
    getIsFindUse,
    getIsFindUseInFilter,
    getIsFindUseInSort,
    getViewColumns,
    PageSizeList,
    useApplicationContext,
    useAtomData,
    useFindUseObjectContext,
    VariableSourceType,
    ViewFieldFilterConfigure,
    viewNotFilterSettingFieldTypes,
    ViewSortConfigure
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { FilterControl } from '@/components/BlockSettings/Common/FilterControl'
import { ListItemMenu } from '@/components/BlockSettings/Common/ListItemMenu'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

interface DataSettingProps {
    dataSource: DataSourceAbstract
    hideFilter?: boolean
    hideSorter?: boolean
    hideGroup?: boolean
    hidePaging?: boolean
    hideFilterController?: boolean
}

/** 数据设置 */
export const DataSetting: React.FC<DataSettingProps> = ({
    dataSource,
    hideFilter,
    hideGroup,
    hidePaging,
    hideSorter,
    hideFilterController
}) => {
    const { control, watch } = useFormContext<ViewOptions>()
    const viewType = watch('viewType')
    const viewFieldSettings = watch('viewFieldSettings')
    const findUseObject = useFindUseObjectContext()
    const { personOptions } = useApplicationContext()

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId], [pageId])
    )
    const {
        id: dsId,
        schema,
        viewOptions: { tableProps }
    } = dataSource
    const columns = getViewColumns({
        tableProps,
        value: viewFieldSettings,
        schema
    })
    const disabledWithVersion = useIsDisabledWithVersion()
    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()
    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    const options = useMemo(
        () =>
            columns
                .filter(item => CAN_SELECT_OPTIONS.has(item.type))
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: <IconFont fill="var(--color-gray-400)" type={getFieldIcon(item.fieldId, item.type, item.innerType)} size={16} />,
                    extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
                })),
        [columns]
    )

    // 筛选器blocks
    const filterBlocks = useMemo(() => {
        if (!pageBlocks) {
            return []
        }
        return pageBlocks.filter(block => block.type === 'filter') as FilterBlockAbstract[]
    }, [pageBlocks])

    const primaryField = useMemo(() => {
        if (!dataSource) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    // const isTableView = viewType === 'table' || viewType === 'advancedTable'
    const isKanbanView = viewType === 'kanban'

    return (
        <>
            {dataSource && !hideFilter && (
                <Controller
                    name="filter"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        const filter = value
                        const filtersLength = filter?.expression?.conditions?.length || 0
                        const isHighLight = getIsFindUseInFilter({
                            findUseObject,
                            data: filter,
                            currentDsId: dsId
                        })
                        return (
                            <ListItemMenu
                                width="auto"
                                highlighting={isHighLight}
                                compact
                                icon={<IconFont size={16} type="Filter" />}
                                label="筛选数据"
                                positionDependencies={[filter]}
                                value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                                popupContent={
                                    <ViewFieldFilterConfigure
                                        dataSource={dataSource}
                                        dataSourceList={dataSourceList}
                                        sources={sources}
                                        columns={columns}
                                        primaryField={primaryField}
                                        filter={filter}
                                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                                        paramsMode={FilterMode.CUSTOM}
                                        noSettingFields={viewNotFilterSettingFieldTypes}
                                        filterBlocks={filterBlocks}
                                        disabled={disabledWithVersion}
                                        // enableFilterVariable
                                        onFilter={onChange}
                                    />
                                }
                            />
                        )
                    }}
                />
            )}
            {!hideFilterController && (
                <FilterControl
                    compact
                    schema={schema}
                    columns={columns}
                    enablePadding
                    enableHover
                    filterBlocks={filterBlocks}
                    // value={field.value}
                    // onChange={field.onChange}
                />
            )}

            {!hideSorter && (
                <Controller
                    name="sorts"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        const isHighLight = getIsFindUseInSort({
                            findUseObject,
                            data: value,
                            currentDsId: dsId
                        })
                        return (
                            <ListItemMenu
                                width="auto"
                                compact
                                highlighting={isHighLight}
                                icon={<IconFont size={16} type="ArrowsDownUp" />}
                                label="排序数据"
                                positionDependencies={[value]}
                                value={Boolean(value?.length) && `${String(value?.length)} 条排序`}
                                popupContent={
                                    <ViewSortConfigure
                                        noSettingFields={viewNotFilterSettingFieldTypes}
                                        columns={columns}
                                        primaryField={primaryField}
                                        sorters={value}
                                        disabled={disabledWithVersion}
                                        isShowDsName={dataSource.type === DataSourceType.joinDataSource}
                                        onChangeSorter={onChange}
                                    />
                                }
                            />
                        )
                    }}
                />
            )}

            {!hideGroup && (
                <Controller
                    name="groupConfigure"
                    control={control}
                    render={({ field: { value: groupField, onChange: onGroupFieldChange, ...groupFieldField } }) => {
                        const isHighLight = getIsFindUse({
                            findUseObject,
                            type: FindUseType.FIELD,
                            dsId,
                            fieldId: groupField?.groupByFieldId
                        })
                        return (
                            <ListItemMenu
                                label="分组"
                                width={278}
                                compact
                                highlighting={isHighLight}
                                icon={<IconFont size={16} type="GroupBy" />}
                                value={
                                    groupField?.groupByFieldId &&
                                    schema?.[groupField.groupByFieldId] &&
                                    `按 ${schema?.[groupField.groupByFieldId]?.name} 分组`
                                }
                                dropdownProps={{ style: { borderRadius: 8, boxShadow: 'var(--box-shadow)' } }}
                                positionDependencies={[groupField?.groupConfig]}
                                popupContent={
                                    <DataGroupConfigure
                                        schema={schema}
                                        options={options}
                                        value={groupField}
                                        clearable
                                        enableHiddenTab
                                        enableAllGroup
                                        personOptions={personOptions}
                                        onChange={onGroupFieldChange}
                                        selectProps={{ ...groupFieldField, clearable: true }}
                                    />
                                }
                            />
                        )
                    }}
                />
            )}

            {isKanbanView || hidePaging ? null : (
                <Controller
                    name="pagination"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <ListItemMenu
                            width="auto"
                            label="每页显示"
                            compact
                            icon={<IconFont size={16} type="StackSimple" />}
                            value={Boolean(value?.pageSize) && `${String(value.pageSize)} 条数据`}
                            popupContent={
                                <PageSizeList
                                    size={value?.pageSize ?? 0}
                                    custom={value?.custom}
                                    disabled={disabledWithVersion}
                                    onChangePageSize={(size: number, custom = false) => {
                                        onChange({
                                            ...value,
                                            pageSize: size,
                                            custom
                                        })
                                    }}
                                />
                            }
                        />
                    )}
                />
            )}
        </>
    )
}
