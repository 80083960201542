import type { ButtonHandleEvent, ButtonPattern, ButtonProtocols, ButtonShowType, DataSourceBase, PageAbstract } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { clone, filter, find, map } from 'rambda'
import React, {
    useEffect, useMemo, useState
} from 'react'

import { commonPageOptions } from '../../constants'
import { IconPicker } from '../IconPicker'
import { BasicListItem } from './BasicListItem'
import { btnStyleList, btnTypeList, buttonActions, buttonEventsMap, data } from './constant'
import Select from './Select'
import * as SC from './styles'
import type { AllViews, ButtonConfig, SelectData } from './type'

type ConfigItemProps = ButtonConfig & {
    data: SelectData
    value: string
    onChange: (value: string) => void
}

const ConfigItem: React.FC<ConfigItemProps> = React.memo(({ value, placeHolder, label, type, name, data, onChange }) => {
    return (
        <BasicListItem position="apart" noWrap>
            <SC.Text>{label}</SC.Text>
            <SC.RightFill>
                {type === 'input' ? (
                    <SC.BlockSettingsInput
                        // width={144}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        placeholder={placeHolder}
                    />
                ) : (
                    <SC.BlockSettingsSelect
                        styles={{
                            root: {
                                width: '100%'
                            }
                        }}
                        clearable
                        searchable
                        name="pointer"
                        value={value}
                        options={data}
                        onChange={val => {
                            onChange(val ?? '')
                        }}
                        placeholder={data.length === 0 ? `暂无可选${label}` : placeHolder}
                    />
                )}
            </SC.RightFill>
        </BasicListItem>
    )
})

interface ButtonSettingsProps {
    disabled?: boolean
    allPages: PageAbstract[]
    value: ButtonProtocols
    style?: React.CSSProperties
    disableHandleEvent?: boolean
    allViews?: { title: string; id: string; dsId: string }[]
    dataSourceList: DataSourceBase[]
    onChange: (params: ButtonProtocols) => void
    onFetchAllViews?: () => Promise<{ title: string; id: string; dsId: string }[]>
}

const recordActions = new Set(['createRecord', 'updateRecord', 'deleteRecord'])

export const ButtonSetting: React.FC<ButtonSettingsProps> = ({
    disabled,
    value,
    allPages,
    dataSourceList,
    onFetchAllViews,
    style,
    disableHandleEvent = false,
    onChange
}) => {
    const {
        events: { handleEvent, params, triggerEvent },
        icon,
        name,
        showType,
        pattern,
        events
    } = value

    const [allViews, setAllViews] = useState<AllViews>([])

    // 当按钮绑定事件类型为记录相关操作时, 请求所有视图
    useEffect(() => {
        const getAllViews = async () => {
            const data = await onFetchAllViews?.()
            if (data) {
                setAllViews(data)
            }
        }
        if (recordActions.has(handleEvent)) {
            getAllViews()
        }
    }, [handleEvent, onFetchAllViews])

    const action = useMemo(() => find(item => item.value === handleEvent, data), [handleEvent])

    const buttonAction = useMemo(() => buttonActions[action?.value ?? 'openPage'], [action?.value])

    const pageSelectList = useMemo(
        () => [
            ...commonPageOptions,
            ...map(({ id, name }) => ({ label: name || '无标题', value: `/page/${id}`, group: 'default' }), allPages)
        ],
        [allPages]
    )

    const dataSourceSelectList = useMemo(() => map(({ id, name }) => ({ label: name, value: id }), dataSourceList), [dataSourceList])

    const viewsAfterFilter = useMemo(() => {
        if (recordActions.has(handleEvent)) {
            return filter(item => item.dsId === params?.[0], allViews).map(item => ({ label: item.title, value: item.id }))
        }
        return []
    }, [allViews, handleEvent, params])

    const currentItem = useMemo(() => find(item => item.value === handleEvent, data), [handleEvent])

    const renderConfig = useMemo(
        () =>
            buttonAction.map(item => {
                let selectData: SelectData = []
                switch (item.name) {
                    case 'datasource': {
                        selectData = dataSourceSelectList
                        break
                    }
                    case 'view': {
                        selectData = viewsAfterFilter
                        break
                    }
                    case 'page': {
                        selectData = pageSelectList
                        break
                    }
                    default: {
                        break
                    }
                }
                return (
                    <ConfigItem
                        key={item.name}
                        {...item}
                        value={params?.[item.index]}
                        data={selectData}
                        onChange={val => {
                            const newParams = clone(params)
                            newParams[item.index] = val
                            onChange({ ...value, events: { ...events, params: newParams } })
                        }}
                    />
                )
            }),
        [buttonAction, dataSourceSelectList, events, onChange, pageSelectList, params, value, viewsAfterFilter]
    )

    return (
        <SC.Container style={style}>
            {/* <BasicListItem
                position="apart"
                noWrap
                styles={{
                    root: {
                        gap: 0
                    }
                }}
            >
                <SC.Text>当</SC.Text>
                <SC.RightFill>
                    <SC.Button bgColor="var(--color-main)">
                        <SC.Icon type="Click" bgColor="#8FBCFF" color="var(--color-white)" />
                        <Text color="var(--color-white)">点击时</Text>
                        <SC.Icon type="" color="var(--color-white)" />
                    </SC.Button>
                </SC.RightFill>
            </BasicListItem> */}
            <BasicListItem
                position="apart"
                styles={{
                    root: {
                        gap: 0
                    }
                }}
            >
                <SC.Text>文本</SC.Text>
                <SC.RightFill>
                    <SC.BlockSettingsInput
                        width="calc(100% - 40px)"
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...value, name: e.target.value })}
                        placeholder="输入按钮名称"
                    />
                    <IconPicker
                        disabled={disabled}
                        value={{ type: 'icon', value: icon }}
                        onChange={icon => onChange({ ...value, icon: icon?.value ?? '' })}
                    />
                </SC.RightFill>
            </BasicListItem>
            <BasicListItem
                position="apart"
                styles={{
                    root: {
                        gap: 0
                    }
                }}
            >
                <SC.Text>按钮类型</SC.Text>
                <SC.RightFill>
                    <SC.BlockSettingsSelect
                        styles={{
                            root: {
                                width: '100%'
                            }
                        }}
                        name="pointer"
                        value={showType}
                        options={btnTypeList}
                        onChange={val => onChange({ ...value, showType: val as ButtonShowType })}
                    />
                </SC.RightFill>
            </BasicListItem>
            {!disableHandleEvent && (
                <>
                    {pattern !== undefined && (
                        <BasicListItem
                            position="apart"
                            styles={{
                                root: {
                                    gap: 0
                                }
                            }}
                        >
                            <SC.Text>按钮样式</SC.Text>
                            <SC.RightFill>
                                <SC.BlockSettingsSelect
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    name="pointer"
                                    value={pattern?.toString()}
                                    options={btnStyleList}
                                    onChange={val => onChange({ ...value, pattern: Number(val) as ButtonPattern })}
                                />
                            </SC.RightFill>
                        </BasicListItem>
                    )}
                    <Select
                        label="就"
                        value={handleEvent}
                        disabled={disabled}
                        onChange={val => {
                            onChange({
                                ...value,
                                events: {
                                    ...(buttonEventsMap.get(val as ButtonHandleEvent) ?? {
                                        handleEvent: 'openPage',
                                        params: [''],
                                        triggerEvent: 'click'
                                    })
                                }
                            })
                        }}
                        data={data}
                        target={
                            <SC.RightFill>
                                <SC.Button bgColor="var(--color-black)">
                                    <SC.Icon
                                        type={currentItem?.icon ?? 'BlockLinkToPage'}
                                        bgColor="var(--color-gray-500)"
                                        color="var(--color-white)"
                                    />
                                    <Text color="var(--color-white)">{currentItem?.label}</Text>
                                    <SC.Icon type="CaretDown" color="var(--color-white)" />
                                </SC.Button>
                            </SC.RightFill>
                        }
                    />
                    {renderConfig}
                </>
            )}
        </SC.Container>
    )
}
