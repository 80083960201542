import { Divider, pointer } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import type { SchemaProtocol, TableColumns, ViewField, ViewType } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import { getViewColumns, List } from '@lighthouse/shared'
import { arrayMove } from '@lighthouse/tools'
import isDeepEqual from 'fast-deep-equal'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { DetailSetting } from '../DetailSetting'
import { ViewFieldSettingItem } from '../ViewFieldSettingItem'

const SCxGroup = styled.div``

const SCxHeader = styled.div`
    padding: 8px;
    display: flex;
    justify-content: space-between;
`

const SCxTitle = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

const SCxToggle = styled.div<{ color?: string }>`
    color: var(--color-main);
    font-size: var(--font-size-sm);
    ${pointer}
`

const SCxDivider = styled(Divider)`
    margin: 16px;
`

export interface ViewFieldSettingState {
    mode: 'main' | 'detail'
    index: number
}

export interface ViewFieldSettingProps {
    value: ViewField[]
    type: DataSourceType
    schema: SchemaProtocol['schema']
    tableProps: TableColumns
    viewType: ViewType
    onChange: (value: ViewField[]) => void
}

export const ViewFieldSetting: React.FC<ViewFieldSettingProps> = ({
    value: viewFieldSettings,
    type,
    schema,
    tableProps,
    viewType,
    onChange
}) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const [state, setState] = useImmer<ViewFieldSettingState>({
        mode: 'main',
        index: -1
    })
    const isJoinDataSource = type === DataSourceType.joinDataSource
    const { mode, index } = state
    const [columns, setColumns] = useState<ViewField[]>(
        getViewColumns(
            {
                tableProps,
                value: viewFieldSettings,
                schema,
                disabledFieldName: true
            }
        )
    )

    const showColumns = useMemo(
        () =>
            columns.reduce<{ value: string }[]>((list, item) => {
                if (item.visible) {
                    list.push({ value: item.fieldId })
                }
                return list
            }, []),
        [columns]
    )
    const hideColumns = useMemo(
        () =>
            columns.reduce<{ value: string }[]>((list, item) => {
                if (!item.visible) {
                    list.push({ value: item.fieldId })
                }
                return list
            }, []),
        [columns]
    )

    const isClass = ['advancedTable', 'table', 'list', 'kanban', 'calendar'].includes(viewType)

    useUpdateEffect(() => {
        if (!isDeepEqual(viewFieldSettings, columns)) {
            setColumns(
                getViewColumns({
                    tableProps,
                    value: viewFieldSettings,
                    schema,
                    disabledFieldName: true
                })
            )
        }
    }, [viewFieldSettings])

    const handleChangeMode = useCallback(
        (val?: ViewFieldSettingState) => {
            setState(val || { mode: 'main', index: -1 })
        },
        [setState]
    )

    const toggleAllColumns = useCallback(
        (visible = false) => {
            if (!visible) {
                const firstShowColumns = columns.find(item => item.visible)
                if (!firstShowColumns) {
                    return
                }

                const [showTableProps, hideTableProps] = columns.reduce<[ViewField[], ViewField[]]>(
                    (list, cur) => {
                        //   return  item.visible
                        if (cur.visible && cur.fieldId !== firstShowColumns?.fieldId) {
                            list[0].push({
                                ...cur,
                                visible: false
                            })
                        }
                        if (!cur.visible) {
                            list[1].push({
                                ...cur,
                                visible: cur.visible
                            })
                        }
                        return list
                    },
                    [[], []]
                )
                const list = [firstShowColumns, ...hideTableProps, ...showTableProps]
                setColumns(list)
                onChange(list)
                return
            }

            const list = [...showColumns, ...hideColumns]
            const newTableProps = list.reduce<ViewField[]>((list, cur) => {
                const column = find(c => c.fieldId === cur.value, columns)
                if (column) {
                    list.push({
                        ...column,
                        visible: true
                    })
                }
                return list
            }, [])
            onChange?.(newTableProps)
        },
        [columns, hideColumns, onChange, showColumns]
    )

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const sourceIndex = findIndex(col => col.fieldId === activeId, columns)
            const targetIndex = findIndex(col => col.fieldId === overId, columns)
            const newList = arrayMove(columns, sourceIndex, targetIndex)
            setColumns(newList)
            onChange(newList)
        },
        [columns, onChange]
    )

    const handleRemove = useCallback(
        (id: string) => {
            const newColumns = columns.filter(item => item.fieldId !== id)
            setColumns(newColumns)
            onChange(newColumns)
        },
        [columns, onChange]
    )

    const handleChangeSwitch = useCallback(
        (val: ViewField) => {
            const filterTableProps = columns.filter(item => item.fieldId !== val.fieldId)
            const t = [...filterTableProps, val]
            setColumns(t)
            onChange(t)
        },
        [columns, onChange]
    )

    const handleChange = useCallback(
        (val: ViewField) => {
            const filterTableProps = columns.map(item => {
                if (item.fieldId === val.fieldId) {
                    return val
                }
                return item
            })
            setColumns(filterTableProps)
            onChange(filterTableProps)
        },
        [columns, onChange]
    )

    const itemRenderer = useCallback(
        (item: { value: string }, length?: number) => {
            const { value } = item
            const index = findIndex(TableItem => TableItem.fieldId === value, columns)
            const viewField = columns[index]
            if (!viewField) {
                return null
            }
            return (
                <ViewFieldSettingItem
                    index={index}
                    key={value}
                    disabled={disabledWithVersion}
                    disableSwitch={length === 1}
                    viewType={viewType}
                    schema={schema}
                    config={viewField}
                    isShowDsName={isJoinDataSource}
                    onChangeMode={handleChangeMode}
                    onChangeSwitch={handleChangeSwitch}
                    onChange={handleChange}
                    onRemove={handleRemove}
                />
            )
        },
        [columns, disabledWithVersion, handleChange, handleChangeMode, handleChangeSwitch, handleRemove, isJoinDataSource, schema, viewType]
    )

    const content = useMemo(() => {
        if (isClass) {
            return (
                <>
                    <SCxGroup>
                        <SCxHeader>
                            <SCxTitle>显示字段</SCxTitle>
                            <SCxToggle
                                onClick={() => {
                                    if (disabledWithVersion) {
                                        return
                                    }
                                    toggleAllColumns()
                                }}
                            >
                                全部隐藏
                            </SCxToggle>
                        </SCxHeader>
                        <List
                            sortable
                            disabled={disabledWithVersion}
                            style={{ maxHeight: 'unset' }}
                            data={showColumns}
                            itemDraggerStyle={{ height: isJoinDataSource ? 20 : 24 }}
                            itemStyle={{ borderRadius: 8, alignItems: isJoinDataSource ? 'flex-start' : 'center' }}
                            onSortEnd={handleSortEnd}
                            itemRender={rest => itemRenderer(rest, showColumns.length)}
                        />
                    </SCxGroup>
                    {/* <AddExtraViewField schema={schema} value={columns} onChange={handleAddField} /> */}
                    <SCxDivider />
                    <SCxGroup>
                        <SCxHeader>
                            <SCxTitle>未显示字段</SCxTitle>
                            <SCxToggle
                                onClick={() => {
                                    if (disabledWithVersion) {
                                        return
                                    }
                                    toggleAllColumns(true)
                                }}
                            >
                                全部显示
                            </SCxToggle>
                        </SCxHeader>
                        <List
                            sortable
                            disabled={disabledWithVersion}
                            style={{ maxHeight: 'unset' }}
                            data={hideColumns}
                            itemDraggerStyle={{ height: isJoinDataSource ? 20 : 24 }}
                            itemStyle={{ borderRadius: 8, alignItems: isJoinDataSource ? 'flex-start' : 'center' }}
                            onSortEnd={handleSortEnd}
                            itemRender={rest => itemRenderer(rest)}
                        />
                    </SCxGroup>
                </>
            )
        }

        return (
            <>
                <SCxGroup>
                    <List
                        sortable
                        disabled={disabledWithVersion}
                        style={{ maxHeight: 'unset', paddingTop: 8 }}
                        data={columns.map(item => ({ value: item.fieldId }))}
                        itemStyle={{ borderRadius: 8 }}
                        onSortEnd={handleSortEnd}
                        itemRender={rest => itemRenderer(rest)}
                    />
                </SCxGroup>
                {/* <AddExtraViewField schema={schema} value={columns} onChange={handleAddField} /> */}
            </>
        )
    }, [columns, disabledWithVersion, handleSortEnd, hideColumns, isClass, isJoinDataSource, itemRenderer, showColumns, toggleAllColumns])
    return (
        <>
            {content}
            {mode === 'detail' && columns[index] && (
                <DetailSetting
                    prefixName={`viewFieldSettings.${index}`}
                    schema={schema}
                    columns={columns}
                    config={columns[index]}
                    onBack={() => handleChangeMode()}
                    onChange={handleChange}
                />
            )}
        </>
    )
}
