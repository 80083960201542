import { flex, Group, IconFont, Text } from '@byecode/ui'
import type { Field, SubFormRelativeFieldItem } from '@lighthouse/core'
import { getCanEditFieldIds, getFieldOptions, getIsAppointField, getPrimaryDataSourceEnableFieldIds, getPrimaryDsSystemFieldId } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { FieldSelect } from '@/components/FieldSelect'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'

interface RelativeFieldItemProps {
    index: number
    dsId: string
    bindDsId: string
    value: SubFormRelativeFieldItem
    onChange: (params: SubFormRelativeFieldItem) => void
    onDelete?: (i: number) => void
}

const SCxContain = styled.div`
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    background-color: var(--color-white);
`

const SCxFill = styled.div`
    height: 40px;
    ${flex}
    justify-content: space-between;
    align-items: center;
`

const SCxContent = styled.div`
    padding: 12px 16px;
`

const SCxLeftFill = styled.div<{ required?: boolean }>`
    min-width: 84px;
    flex-shrink: 0;
    ${flex}
    align-items: center;
    flex-wrap: nowrap;

    ${({ required }) =>
        required &&
        css`
            &::after {
                content: '*';
                color: var(--color-red-500);
                margin-left: 4px;
            }
        `}
`
const SCxRightFill = styled.div`
    width: 180px;
    padding-left: 12px;
    flex-shrink: 1;
    ${flex}
    justify-content: flex-end;
    gap: 16px;
`
const SCxIcon = styled(IconFont)``

export const RelativeFieldItem: React.FC<RelativeFieldItemProps> = ({ value, index, dsId, bindDsId, onChange, onDelete }) => {
    const [open, setOpen] = useState(true)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dsId)
    const bindDataSource = useDataSource(appId, envId, bindDsId)
    const dataSourceList = useDataSourceList(appId, envId)

    const options = useMemo(() => {
        if (!dataSource) {
            return []
        }
        const canEditFieldIds = getCanEditFieldIds(dataSource, dataSourceList)
        const primaryIds = getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)

        const filter = (field: Field) => {
            const isMainIdField = primaryIds.has(field.id) && getIsAppointField(field.id, new Set(['ID']))
            const isFormulaField = primaryIds.has(field.id) && field.type === 'formula'
            return (
                field.innerType === 'TEXT' &&
                (canEditFieldIds.includes(field.id) || isMainIdField || isFormulaField)
            )
        }
        return getFieldOptions(dataSource, filter)
    }, [dataSource, dataSourceList])

    const bindOptions = useMemo(() => {
        if (!bindDataSource) {
            return []
        }
        const canEditFieldIds = getCanEditFieldIds(bindDataSource, dataSourceList)
        const filter = (field: Field) => field.innerType === 'TEXT' && canEditFieldIds.includes(field.id)
        return getFieldOptions(bindDataSource, filter)
    }, [bindDataSource, dataSourceList])

    const handleChange = useCallback(
        (v: Partial<SubFormRelativeFieldItem>) => {
            onChange?.({ ...value, ...v })
        },
        [onChange, value]
    )

    return (
        <SCxContain>
            <Group
                styles={{
                    root: { padding: '0px!important' },
                    wrapper: {
                        background: 'var(--color-gray-100)',
                        padding: '0px 8px!important'
                    },
                    collapse: {
                        padding: '0px!important'
                    }
                }}
                opened={open}
                label={<Text weight={400}>关联字段 {index + 1}</Text>}
                onCollapseChange={setOpen}
                extra={<SCxIcon onClick={() => onDelete?.(index)} type="Trash" />}
            >
                <SCxContent>
                    <SCxFill>
                        <SCxLeftFill required>主表字段</SCxLeftFill>
                        <SCxRightFill>
                            <FieldSelect
                                dsId={dsId}
                                value={value.fieldPointer}
                                width="100%"
                                onChange={v => handleChange({ fieldPointer: v })}
                                options={options}
                            />
                        </SCxRightFill>
                    </SCxFill>
                    <SCxFill>
                        <SCxLeftFill required>绑定子表字段</SCxLeftFill>
                        <SCxRightFill>
                            <FieldSelect
                                dsId={bindDsId}
                                value={value.bindFieldPointer}
                                width="100%"
                                onChange={v => handleChange({ bindFieldPointer: v })}
                                options={bindOptions}
                            />
                        </SCxRightFill>
                    </SCxFill>
                </SCxContent>
            </Group>
        </SCxContain>
    )
}
