import { CollapseBox, useApplicationContext } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import PersonSelect from '@/components/PersonSelect'
import { useCurrentAppID } from '@/hooks/useApplication'

import * as SC from '../../styles'

interface CarbonCopyActionConfigureProps {}

export const CarbonCopyActionConfigure: React.FC<CarbonCopyActionConfigureProps> = () => {
    const { control, setValue } = useFormContext()
    const appId = useCurrentAppID()

    const { roleOptions: roles, personOptions: users } = useApplicationContext()

    return (
        <SC.Container>
            <CollapseBox label="抄送人设置" required>
                <SC.Content>
                    <Controller
                        name="config.users"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <>
                                    <SC.Description style={{ marginBottom: 14 }}>添加抄送人（不能超过 100 人）</SC.Description>
                                    <PersonSelect value={field.value} onChange={field.onChange} userOptions={users} roleOptions={roles} />
                                </>
                            )
                        }}
                    />
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
