import type { QuotaProtocols } from '@lighthouse/core'
import { getFileSizeAccordanceBaseUnit } from '@lighthouse/tools'

import type { SpaceQuota } from '@/services/types'

export const QUOTA_MESSAGE_CONTAINER_ID = 'QUOTA_MESSAGE_CONTAINER_ID'

type ExtractQuotaProtocolsKey = {
    [K in keyof SpaceQuota]-?: SpaceQuota[K] extends QuotaProtocols ? K : never
}[keyof SpaceQuota]

export const QUOTA_MESSAGES_MAP = {
    fileStorage: {
        icon: 'Folder',
        title: '附件储存达到限制',
        description: '应用中已无法上传附件，请升级版本。',
        renderUsage: v => getFileSizeAccordanceBaseUnit(v, 'MB'),
        renderQuota: v => getFileSizeAccordanceBaseUnit(v, 'MB')
    },
    numberOfDomain: {
        icon: 'ActiveNode-Website',
        title: '绑定自有域名数量达到限制',
        description: '自有域名数量已达限制，请升级版本。',
        renderUsage: v => v,
        renderQuota: v => v
    },
    numberOfPublishedApps: {
        icon: 'Publish',
        title: '可发布应用数量达到限制',
        description: '无法发布更多应用，请升级版本。',
        renderUsage: v => v,
        renderQuota: v => v
    },
    numberOfRecords: {
        icon: 'BlockTable',
        title: '数据表总记录数达到限制',
        description: '数据表无法录入更多数据，请升级版本。',
        renderUsage: v => v.toLocaleString(),
        renderQuota: v => v.toLocaleString()
    },
    numberOfUsers: {
        icon: 'SpaceTeam',
        title: '登录用户数量达到限制',
        description: '新用户已不可注册登录。',
        renderUsage: v => v,
        renderQuota: v => v
    },
    numberOfUv: {
        icon: 'PropertyPerson',
        title: '本月访客数量（UV）达到限制',
        description: '用户已不可正常使用应用，请升级版本。',
        renderUsage: v => v.toLocaleString(),
        renderQuota: v => v.toLocaleString()
    },
    traffic: {
        icon: 'ClickButton',
        title: '访问流量达到限制',
        description: '访问流量达到上限，请升级版本。',
        renderUsage: v => getFileSizeAccordanceBaseUnit(v, 'MB'),
        renderQuota: v => getFileSizeAccordanceBaseUnit(v, 'MB')
    }
} satisfies Partial<
    Record<
        ExtractQuotaProtocolsKey,
        {
            icon: string
            title: string
            description: string
            renderQuota: (value: number) => string | number
            renderUsage: (value: number) => string | number
        }
    >
>

/** 错误码对应的 quota key */
export const ERROR_CODE_QUOTA_KEY_MAP: Record<string, keyof typeof QUOTA_MESSAGES_MAP> = {
    '0011024': 'numberOfPublishedApps',
    '0011025': 'numberOfRecords',
    '0011027': 'fileStorage',
    '0011032': 'numberOfUsers',
    '0011033': 'numberOfDomain',
    '0011047': 'traffic',
    '0011068': 'numberOfUv'
}
