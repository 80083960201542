import type { BlockAbstract, PageNode } from '@lighthouse/core'
import { BlockType, DIRECTION, HEIGHT_RULE } from '@lighthouse/core'
import { FLOW_LAYOUT_NODE_HEIGHT, FLOW_LAYOUT_NODE_ROWS, useAtomAction, useAtomData } from '@lighthouse/shared'
import { current } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import { viewRecordListAtomFamily } from '@/atoms/blockRecordsDict/state'
import { updateLocalBlockAtom, updateRemoteBlockAtom } from '@/atoms/page/action'
import { pageNodesAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { hasBlockNegativeUpdate } from '@/atoms/utils/block'
import type { BlockSettingsValue } from '@/components/BlockSettings'
import { findPageNodeById } from '@/containers/PageDetail/PageContentV2/utils'
import { useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'

import { useCurrentAppID } from './useApplication'

function resetChildrenSize(pageNodes: PageNode[]) {
    if (pageNodes.length === 0) {
        return
    }
    function initWidth() {
        pageNodes.forEach(node => {
            node.width = node.minWidth ?? 1
        })
    }
    function computeWidth() {
        return pageNodes.reduce((total, current) => total + current.width, 0)
    }

    initWidth()
    let totalWidth = computeWidth()

    while (totalWidth < FLOW_LAYOUT_NODE_ROWS) {
        if (pageNodes.every(node => node.width === (node.maxWidth ?? FLOW_LAYOUT_NODE_ROWS))) {
            return
        }
        for (const pageNode of pageNodes) {
            if (pageNode.width >= (pageNode.maxWidth ?? FLOW_LAYOUT_NODE_ROWS)) {
                continue
            }

            pageNode.width++
            if (++totalWidth >= FLOW_LAYOUT_NODE_ROWS) {
                return
            }
        }
    }
}

export const useBlockActions = (pageId: string, stackId: string) => {
    const appId = useCurrentAppID()

    const { run: updateRemoteBlock } = useAtomAction(updateRemoteBlockAtom)
    const { run: updateLocalBlock } = useAtomAction(updateLocalBlockAtom)
    const { rootPageId } = useRootPageContext()

    /** 更新远程block */
    const onUpdateRemoteBlock = useMemo(
        () =>
            debounce(500, async (block: BlockAbstract, origin: BlockAbstract) => {
                await updateRemoteBlock({ appId, block, origin, pageId, rootPageId, stackId })
            }),
        [updateRemoteBlock, appId, pageId, rootPageId, stackId]
    )

    /** 更新block */
    const onUpdateBlock = useAtomCallback(
        useCallback(
            (get, set, block: BlockSettingsValue, origin: BlockSettingsValue) => {
                if (!hasBlockNegativeUpdate(appId, block, origin)) {
                    updateLocalBlock({ block, pageId })
                }
                onUpdateRemoteBlock(block, origin)

                // 容器的高度规则改变了，需要初始化node的height
                if (
                    BlockType.container === block.type &&
                    block.type === origin.type &&
                    block.config.design.heightRule !== origin.config.design.heightRule
                ) {
                    console.log('hight rules change')
                    let finalNodes: PageNode[] = []
                    set(pageNodesAtom, draft => {
                        const pageNodes = draft[pageId]
                        if (!pageNodes) {
                            return
                        }

                        const draftNode = findPageNodeById(block.id)(pageNodes)
                        if (!draftNode) {
                            return
                        }

                        if (block.config.design.heightRule === HEIGHT_RULE.FIXED) {
                            const nodeElement = document.querySelector(`div[data-node-id="${block.id}"]`)
                            const height = nodeElement?.getBoundingClientRect().height
                            if (height) {
                                draftNode.height = height / FLOW_LAYOUT_NODE_HEIGHT
                            }
                        } else {
                            delete draftNode.height
                        }

                        finalNodes = current(pageNodes)
                    })
                    srv.updateNodes({ id: pageId, nodes: finalNodes })
                    return
                }

                // // 更改右侧的容器高度，需要更改nodes的高度配置
                // if (BlockType.container === block.type && block.type === origin.type && block.height !== origin.height) {
                //     console.log('settings container height change', block.height)
                //     let finalNodes: PageNode[] = []
                //     set(pageNodesAtom, draft => {
                //         const pageNodes = draft[pageId]
                //         if (!pageNodes) {
                //             return
                //         }

                //         const draftNode = findPageNodeById(block.id)(pageNodes)
                //         if (!draftNode) {
                //             return
                //         }

                //         draftNode.height = block.height
                //         finalNodes = current(pageNodes)
                //     })
                //     srv.updateNodes({ id: pageId, nodes: finalNodes })
                //     return
                // }

                // 如果更改了容器的排列方向，则需重新设置子节点的宽度，避免宽度超出
                if (
                    (BlockType.container === block.type || BlockType.formContainer === block.type) &&
                    block.type === origin.type &&
                    block.config.design.direction !== origin.config.design.direction
                ) {
                    const blockRuntimeState = get(pageStackAtomFamily({ rootPageId, stackId }))?.blockRuntimeState

                    let finalNodes: PageNode[] = []
                    set(pageNodesAtom, draft => {
                        const pageNodes = draft[pageId]
                        if (!pageNodes) {
                            return
                        }

                        const draftNode = findPageNodeById(block.id)(pageNodes)

                        if (draftNode && draftNode.children) {
                            // 改为横向布局，需要均分宽度
                            if (DIRECTION.horizontal === block.config.design.direction) {
                                if (block.type === BlockType.container) {
                                    // 普通容器
                                    const currentView = draftNode.children.find(
                                        item => item.id === blockRuntimeState?.container?.[block.id].currentView
                                    )
                                    if (!currentView || !currentView.children) {
                                        return
                                    }
                                    resetChildrenSize(currentView.children)
                                } else {
                                    // 表单容器
                                    resetChildrenSize(draftNode.children)
                                }
                            }

                            // 改为纵向布局， 需要铺满
                            if (DIRECTION.vertical === block.config.design.direction) {
                                if (block.type === BlockType.container) {
                                    // 普通容器
                                    const currentView = draftNode.children.find(
                                        item => item.id === blockRuntimeState?.container?.[block.id].currentView
                                    )
                                    if (!currentView || !currentView.children) {
                                        return
                                    }
                                    currentView.children.forEach(node => {
                                        node.width = FLOW_LAYOUT_NODE_ROWS
                                    })
                                } else {
                                    // 表单容器
                                    draftNode.children.forEach(node => {
                                        node.width = FLOW_LAYOUT_NODE_ROWS
                                    })
                                }
                            }

                            finalNodes = current(pageNodes)
                        }
                    })

                    srv.updateNodes({ id: pageId, nodes: finalNodes })
                }

                // 更改了自定义视图的排列方向
                if (
                    BlockType.view === block.type &&
                    block.config.viewType === 'custom' &&
                    block.type === origin.type &&
                    block.config.design?.direction !== origin.config.design?.direction
                ) {
                    let finalNodes: PageNode[] = []
                    set(pageNodesAtom, draft => {
                        const pageNodes = draft[pageId]
                        if (!pageNodes) {
                            return
                        }

                        const draftNode = findPageNodeById(block.id)(pageNodes)

                        if (draftNode && draftNode.children) {
                            // 改为横向布局，需要均分宽度
                            if (DIRECTION.horizontal === block.config.design?.direction) {
                                resetChildrenSize(draftNode.children)
                            }

                            // 改为纵向布局， 需要铺满
                            if (DIRECTION.vertical === block.config.design?.direction) {
                                draftNode.children.forEach(node => {
                                    node.width = FLOW_LAYOUT_NODE_ROWS
                                })
                            }

                            finalNodes = current(pageNodes)
                        }
                    })

                    srv.updateNodes({ id: pageId, nodes: finalNodes })
                }
            },
            [appId, onUpdateRemoteBlock, pageId, rootPageId, stackId, updateLocalBlock]
        )
    )

    return useMemo(
        () => ({
            onUpdateBlock
        }),
        [onUpdateBlock]
    )
}

export const useViewRecords = (blockId: string) => {
    return useAtomData(viewRecordListAtomFamily(blockId))
}
