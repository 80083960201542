import { DataSourceType, FilterWay } from '@lighthouse/core'
import { ListItem4ByecodeUi, SelectDataSource } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { FieldSelect } from '@/components/FieldSelect'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDsListAndFieldOptions } from '@/hooks/useDataSource'

import { getFieldOptions } from './help'

interface CascadeFilterProps {
    prefixName: string
}

export const CascadeFilter: React.FC<CascadeFilterProps> = ({ prefixName }) => {
    const { control } = useFormContext()
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const pointer = useWatch({ control, name: `${prefixName}.pointer` })
    const { dataSourceList, fieldOptions, dataSource } = useDsListAndFieldOptions(currentAppId, envId, pointer)
    const selectFieldOptions = getFieldOptions(fieldOptions, dataSource, FilterWay.textFilter)
    const excludeDataSourceList = useMemo(
        () => dataSourceList.filter(ds => ds.type !== DataSourceType.aggregateDataSource),
        [dataSourceList]
    )
    return (
        <>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>选项来源</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.pointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <SelectDataSource
                                withinPortal
                                size="lg"
                                value={value}
                                onChange={onChange}
                                dataSourceList={excludeDataSourceList}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>选项值</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.fieldPointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FieldSelect
                                dsId={pointer}
                                style={{
                                    width: 180
                                }}
                                options={selectFieldOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>显示为</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.showFieldPointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FieldSelect
                                dsId={pointer}
                                style={{
                                    width: 180
                                }}
                                options={selectFieldOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>父层级</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.parentFieldPointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FieldSelect
                                dsId={pointer}
                                style={{
                                    width: 180
                                }}
                                options={selectFieldOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>排序字段</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.sortFieldPointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FieldSelect
                                dsId={pointer}
                                style={{
                                    width: 180
                                }}
                                options={selectFieldOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
        </>
    )
}
