import type { BlockAbstract, DataSourceAbstract, DataSourceBase, PageAbstract, RecordLikeProtocol, TextBlockConfig } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import {
    ANONYMOUS,
    EMPTY_COLUMN_GROUP,
    FindUseLocationType,
    FindUseObjectContextProvider,
    PERSON_ID,
    systemPersonFieldId,
    useApplicationContext
} from '@lighthouse/shared'
import { deepOmitNil } from '@lighthouse/tools'
import fastEqual from 'fast-deep-equal/es6'
import { useSetAtom } from 'jotai'
import { clone } from 'rambda'
import React, { useEffect, useMemo } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useLatest } from 'react-use'

import { BlockSettingProvider } from '@/contexts/BlockSettingContext'
import { useFindUse } from '@/hooks/useFindUse'

import { AppNavSetting } from './AppNav'
import { applicationAtom, dataSourceAtom, dataSourceListIdsAtom, recordsAtom } from './atoms'
import { BreadcrumbSetting } from './Breadcrumb'
import { ButtonSettings } from './ButtonGroup'
import { CardBlockSetting } from './Card'
import { ChartBlockSettings } from './Chart'
import { CollapseSetting } from './Collapse'
import { DEFAULT_CARD_CONFIG, DEFAULT_FIELD_CONFIG, DEFAULT_VIEW_CONFIG } from './constants'
import { ContainerSetting } from './Container'
import { CustomViewSetting } from './CustomView'
import { DividerBlockSetting } from './Divider'
import BlockFieldSettings from './Field'
import { FieldGroupSetting } from './FieldGroup'
import FileBlockSetting from './File'
import { FilterSetting } from './Filter'
import { IframeSetting } from './Iframe'
import { BlockImageSettings } from './Image'
import { QrBarcodeSetting } from './QrBarcode'
import * as SC from './styles'
import { SubFormSetting } from './SubForm'
import { TabsSettings } from './Tabs'
import { TextBlockSetting } from './TextV2'
import { VideoBlockSetting } from './Video'
import BlockViewSettings from './View'

// appId 用于解决试图选择数据源问题修复, height 用于容器的动态高度配置
export type BlockSettingsFormValue = BlockAbstract['config'] & Pick<BlockAbstract, 'title' | 'type'> & { appId?: string; height?: number }

export type BlockSettingsValue = BlockAbstract & { height?: number }

export interface BlockSettingsProps {
    loading?: boolean
    appId: string
    value: BlockSettingsValue
    onChange?: (v: BlockAbstract) => void
    dataSourceList: DataSourceBase[]
    dataSource?: DataSourceAbstract
    records?: RecordLikeProtocol[]
    allDataSources: DataSourceAbstract[]
    pointer: string
    allPages: PageAbstract[]
    allViews?: { title: string; dsId: string; id: string }[]

    onFetchAllViews?: () => Promise<{ title: string; id: string; dsId: string }[]>
}

function getDefaultSettingValue(blockData: BlockAbstract) {
    switch (blockData.type) {
        case BlockType.view: {
            return DEFAULT_VIEW_CONFIG
        }
        case BlockType.field: {
            return DEFAULT_FIELD_CONFIG
        }
        case BlockType.card: {
            return DEFAULT_CARD_CONFIG
        }
        default: {
            return {}
        }
    }
}

const getBlockConfigFromFormValue = (values: DeepPartial<BlockSettingsFormValue>): BlockAbstract['config'] => {
    const { title, type, height, ...config } = values

    if (type === BlockType.text || type === BlockType.qrBarcode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const { content, ...rest } = config
        return { content: { value: content }, ...rest } as TextBlockConfig
    }

    return (type === BlockType.container ? config : { height, ...config }) as BlockAbstract['config']
}

export const BlockSettings = React.forwardRef<HTMLDivElement, BlockSettingsProps>(
    (
        {
            loading,
            appId,
            value,
            pointer,
            onChange,
            dataSourceList,
            dataSource,
            records = [],
            allDataSources,
            allPages,
            allViews,
            onFetchAllViews
        },
        ref
    ) => {
        // 获取外部package传入的数据源数据，在组件内部存储atom
        const updateDataSource = useSetAtom(dataSourceAtom)
        const updateDataSourceList = useSetAtom(dataSourceListIdsAtom)
        const updateRecords = useSetAtom(recordsAtom)

        const updateApplicationInfo = useSetAtom(applicationAtom)
        const findUseObject = useFindUse(FindUseLocationType.BLOCK, { id: value.id })
        const mergeValue = useMemo(() => {
            return {
                ...getDefaultSettingValue(value),
                height: value.height,
                ...value.config,
                content: value.type === BlockType.text || value.type === BlockType.qrBarcode ? value.config.content.value : undefined,
                title: value.title,
                type: value.type
            }
        }, [value])

        const methods = useForm<BlockSettingsFormValue>({
            values: mergeValue
        })

        const { personOptions } = useApplicationContext()

        const latest = useLatest({ value, mergeValue, onChange, personOptions })

        useEffect(() => {
            updateDataSource(dataSource)
            return () => {
                updateDataSource(undefined)
            }
        }, [updateDataSource, dataSource])

        useEffect(() => {
            updateDataSourceList(dataSourceList)
        }, [updateDataSourceList, dataSourceList, allDataSources, appId])

        useEffect(() => {
            updateRecords(records)
        }, [updateRecords, records])

        useEffect(() => {
            updateApplicationInfo(draft => {
                draft.pageList = allPages
                draft.dataSourceList = allDataSources.filter(item => item.appId === appId)
                draft.viewList = allViews ?? []
            })
        }, [updateApplicationInfo, allPages, allDataSources, allViews, appId])

        const extraSetting = useMemo(() => {
            switch (value.type) {
                case BlockType.view: {
                    return value.config.viewType === 'custom' ? <CustomViewSetting id={value.id} /> : <BlockViewSettings id={value.id} />
                }
                case BlockType.chart: {
                    return <ChartBlockSettings />
                }
                case BlockType.iframe: {
                    return <IframeSetting />
                }
                case BlockType.field: {
                    return <BlockFieldSettings pointer={pointer} id={value.id} />
                }
                case BlockType.image: {
                    return <BlockImageSettings blockId={value.id} allPages={allPages} />
                }
                case BlockType.buttonGroup: {
                    return <ButtonSettings allPages={allPages} />
                }
                case BlockType.card: {
                    return <CardBlockSetting blockId={value.id} allPages={allPages} />
                }
                case BlockType.divider: {
                    return <DividerBlockSetting />
                }

                case BlockType.appNav: {
                    return <AppNavSetting />
                }
                case BlockType.video: {
                    return <VideoBlockSetting id={value.id} />
                }
                case BlockType.fieldGroup: {
                    return <FieldGroupSetting pointer={pointer} id={value.id} />
                }
                case BlockType.breadcrumb: {
                    return <BreadcrumbSetting />
                }
                case BlockType.collapse: {
                    return <CollapseSetting blockId={value.id} />
                }
                case BlockType.file: {
                    return <FileBlockSetting blockId={value.id} />
                }
                case BlockType.container:
                case BlockType.formContainer: {
                    return <ContainerSetting containerId={value.id} type={value.type} />
                }
                case BlockType.tabs: {
                    return <TabsSettings blockId={value.id} />
                }
                case BlockType.text: {
                    return <TextBlockSetting blockId={value.id} />
                }
                case BlockType.filter: {
                    return <FilterSetting blockId={value.id} />
                }
                case BlockType.qrBarcode: {
                    return <QrBarcodeSetting />
                }
                case BlockType.subForm: {
                    return <SubFormSetting blockId={value.id} />
                }
                default: {
                    return null
                }
            }
        }, [allPages, pointer, value.config, value.id, value.type])

        useEffect(() => {
            const { unsubscribe } = methods.watch((formValue, info) => {
                if (fastEqual(deepOmitNil(latest.current.mergeValue), deepOmitNil(formValue))) {
                    return
                }

                const { title = '', type, height } = formValue
                const blockConfig = { ...latest.current.value.config, ...getBlockConfigFromFormValue(formValue) }

                const newBlockAbstract = {
                    title,
                    config: blockConfig,
                    height,
                    id: latest.current.value.id,
                    type
                } as BlockSettingsValue

                latest.current.onChange?.(clone(newBlockAbstract))
            })
            return unsubscribe
        }, [methods, latest])

        // 检查配置有效性
        useEffect(() => {
            if (!dataSource) {
                return
            }
            // 看板视图分组配置检验
            const isKanbanView = value.type === 'view' && value.config.viewType === 'kanban'
            if (isKanbanView) {
                const { kanbanGroupConfigure } = value.config
                const { groupByFieldId, groupConfig } = kanbanGroupConfigure || {}
                const hasKanbanConfig = !!groupByFieldId && !!groupConfig
                if (hasKanbanConfig) {
                    const field = dataSource.schema[groupByFieldId]
                    if (!field) {
                        methods.setValue('kanbanGroupConfigure', {
                            groupByFieldId: '',
                            groupConfig: []
                        })
                        return
                    }
                    if (field.type === 'select') {
                        const options = new Set([...field.select.options.map(item => item.label), EMPTY_COLUMN_GROUP])
                        // 删除过期的选项
                        const newConfig = groupConfig.filter(item => options.has(item.value))

                        if (!fastEqual(groupConfig, newConfig)) {
                            methods.setValue('kanbanGroupConfigure', {
                                groupByFieldId,
                                groupConfig: newConfig
                            })
                        }
                    }
                    if (field.type === 'person') {
                        const isSystemPerson = systemPersonFieldId.has(field.id)
                        const staticAnonymous = isSystemPerson ? [...PERSON_ID] : [ANONYMOUS]

                        const options = new Set([
                            ...latest.current.personOptions.map(item => item.userId),
                            ...staticAnonymous,
                            EMPTY_COLUMN_GROUP
                        ])
                        const newConfig = groupConfig.filter(item => options.has(item.value))
                        if (!fastEqual(groupConfig, newConfig)) {
                            methods.setValue('kanbanGroupConfigure', {
                                groupByFieldId,
                                groupConfig: newConfig
                            })
                        }
                    }
                }
            }
        }, [dataSource, latest, methods, value.config, value.type])

        return (
            <SC.BlockSettingContainer ref={ref}>
                <FindUseObjectContextProvider value={findUseObject}>
                    <BlockSettingProvider blockId={value.id}>
                        <SC.BlockSettingContentContainer>
                            <FormProvider {...methods}>
                                {/* <BlockSettingHeader blockData={value} dataSource={dataSource} onDeleteBlock={onDeleteBlock} />
                        <Divider color="var(--color-gray-200)" /> */}
                                {extraSetting}
                            </FormProvider>
                        </SC.BlockSettingContentContainer>
                    </BlockSettingProvider>
                </FindUseObjectContextProvider>
            </SC.BlockSettingContainer>
        )
    }
)
