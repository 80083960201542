import {
    Button,
    Flex,
    Popover,
    SelectDropdown,
    Text,
    Tooltip,
    useDisclosure
} from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import {
    closestCenter,
    DndContext,
    MouseSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {
    restrictToFirstScrollableAncestor,
    restrictToVerticalAxis
} from '@dnd-kit/modifiers'
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type {
    FieldBlockAbstract,
    FieldInputType,
    SubFormBlockConfig,
    SubFormColumn
} from '@lighthouse/core'
import {
    FieldInputIconTypeMap,
    FieldInputNameMap
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { omit } from 'rambda'
import * as React from 'react'
import {
    useMemo,
    useState
} from 'react'
import {
    Controller,
    useFieldArray,
    useFormContext
} from 'react-hook-form'
import { useToggle } from 'react-use'
import styled from 'styled-components'

import { allField } from '@/constants/Block/constant'
import { getBlockInitConfig } from '@/constants/Block/help'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { FieldInputSetting } from '../Common/FieldInputSetting'
import { BlockSettingIcon } from '../styles'

interface FieldListConfigureProps {
    blockId: string
}

interface FieldItemConfigureProps {
    id: string
    data: SubFormColumn
    onCopy: () => void
    onDelete: () => void
    onLookDetail: () => void
}
const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
`
const SCxList = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`
const SCxItem = styled(Flex)`
    align-items: center;
`

const SCxTitle = styled(Flex)`
    padding: 0 8px;
    height: 28px;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-100);
    border-radius: 5px;
`

const SCxHandle = styled.div`
    cursor: pointer;
    margin-right: 6px;
`

const addFieldInputOptions = allField.items
    .map(item => ({ label: item.name, value: item.subType, icon: FieldInputIconTypeMap[item.subType] }))
    .filter(v => v.value !== 'slider')

const FieldItemConfigure: React.FC<FieldItemConfigureProps> = ({ id, data, onCopy, onDelete, onLookDetail }) => {
    const inputType = data.config?.inputType ?? 'text'
    const disabledWithVersion = useIsDisabledWithVersion()

    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id,
        disabled: disabledWithVersion
    })

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: active?.id === id ? 1 : 0
        }),
        [active?.id, id, transform, transition]
    )

    return (
        <SCxItem {...attributes} style={style} ref={setNodeRef}>
            <SCxHandle {...listeners}>
                <BlockSettingIcon color="var(--color-gray-400)" type="DotsSix" />
            </SCxHandle>
            <Tooltip title="进入字段配置">
                <SCxTitle onClick={onLookDetail}>
                    <Flex gap={4}>
                        <BlockSettingIcon color="var(--color-gray-400)" type={FieldInputIconTypeMap[inputType]} />
                        <Text size={12}>{data.config.title || FieldInputNameMap[inputType]} </Text>
                    </Flex>
                    <BlockSettingIcon color="var(--color-gray-400)" type="ArrowRightSmall" />
                </SCxTitle>
            </Tooltip>
            <Flex gap={8} style={{ marginLeft: 4 }}>
                <BlockSettingIcon color="var(--color-gray-400)" type="Duplicate" onClick={onCopy} />
                <BlockSettingIcon color="var(--color-gray-400)" type="Trash" onClick={onDelete} />
            </Flex>
        </SCxItem>
    )
}

export const TableColumnConfigure: React.FunctionComponent<FieldListConfigureProps> = ({ blockId }) => {
    const [opened, { toggle, close }] = useDisclosure(false)

    const [openFieldInput, toggleFieldInput] = useToggle(false)

    const { control, watch } = useFormContext<SubFormBlockConfig>()
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const [activeIndex, setActiveIndex] = useState(-1)
    const { fields, remove, append, move, insert } = useFieldArray({
        control,
        name: 'columns',
        keyName: 'tid'
    })

    const pointer = watch('pointer') ?? ''

    const handleDragEnd = React.useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.tid === active.id)
                const newIndex = fields.findIndex(item => item.tid === over?.id)
                move?.(oldIndex, newIndex)
            }
        },
        [fields, move]
    )

    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={fields.map(d => d.tid)} strategy={verticalListSortingStrategy}>
                    {fields.length > 0 && (
                        <SCxList>
                            {fields.map((item, index) => {
                                return (
                                    <Controller
                                        control={control}
                                        key={item.tid}
                                        name={`columns.${index}`}
                                        render={({ field }) => {
                                            return (
                                                <FieldItemConfigure
                                                    id={item.tid}
                                                    data={field.value}
                                                    onCopy={() => insert(index, { id: nanoid(10), width: 270, config: field.value.config })}
                                                    onDelete={() => remove(index)}
                                                    onLookDetail={() => {
                                                        setActiveIndex(index)
                                                        toggleFieldInput(true)
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                )
                            })}
                        </SCxList>
                    )}
                </SortableContext>
            </DndContext>
            <Popover opened={opened} withinPortal onChange={toggle} width={200} position="bottom-start">
                <Popover.Target>
                    <Button radius="100px" style={{ width: 74 }} icon={<BlockSettingIcon color="var(--color-gray-500)" type="Add" />}>
                        添加
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <SelectDropdown
                        searchable
                        onSelect={v => {
                            const fieldBlock = getBlockInitConfig({ type: 'field', subType: v as FieldInputType }) as FieldBlockAbstract
                            if (!fieldBlock) {
                                return
                            }
                            append({
                                id: nanoid(10),
                                width: 270,
                                config: omit(['visibilityDevice'], fieldBlock.config)
                            })
                            close()
                        }}
                        options={addFieldInputOptions}
                    />
                </Popover.Dropdown>
            </Popover>
            {activeIndex !== -1 && openFieldInput && (
                <FieldInputSetting
                    style={{
                        position: 'absolute',
                        inset: 0,
                        zIndex: 1,
                        background: '#fff'
                    }}
                    mode="subForm"
                    pointer={pointer}
                    prefix={`columns.${activeIndex}.config.`}
                    onBack={() => toggleFieldInput(false)}
                />
            )}
        </SCxContainer>
    )
}
