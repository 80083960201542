import { type Field, type FieldADTValue, type ValueVariableConfig, type VariableADTvalue } from '@lighthouse/core'
import { useElementSize } from '@mantine/hooks'
import React, { useMemo } from 'react'

import { isEmptyCellValue } from '../../../../utils'
import FieldValuePreviewer from '../../../FieldValuePreviewer'
import { Placeholder } from '../../../PlaceHolder'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../../constant'
import * as SC from './styles'

interface SelectInputProps {
    data?: ValueVariableConfig
    field?: Field
    placeholder?: string
    disabled?: boolean
    onChange: (value: VariableADTvalue) => void
    onChangeOpen: () => void
}

export const SelectInput: React.FunctionComponent<SelectInputProps> = ({
    placeholder = '请选择',
    disabled,
    data,
    field,
    onChangeOpen,
    onChange
}) => {
    const { ref, width } = useElementSize<HTMLDivElement>()

    const isEmpty = useMemo(
        () =>
            isEmptyCellValue({
                value: data?.valueVariable?.value,
                type: field?.type ?? 'text',
                id: field?.id ?? '',
                name: field?.name ?? ''
            } as FieldADTValue),
            [data?.valueVariable?.value, field?.id, field?.name, field?.type]
        )

    return (
        <SC.ItemContainer style={{ paddingLeft: 0 }} onClick={() => !disabled && onChangeOpen?.()}>
            <div ref={ref} style={{ flex: 1, overflow: 'hidden' }}>
                {isEmpty ? (
                    <Placeholder value={placeholder} style={{ padding: '0 8px', color: 'var(--color-gray-400)', fontSize: 14 }} />
                ) : (
                    field &&
                    width > 0 && (
                            <FieldValuePreviewer
                            isHasCurrentUser
                            width={width}
                            field={field}
                            data={data?.valueVariable?.value}
                            isWrap={false}
                            isShowDepartmentId
                            visiblePersonDescribe={false}
                        />

                    )
                )}
            </div>
            <SC.IconWrapper>
                {!isEmpty && (
                    <SC.Icon
                        type="CloseCircle"
                        size={16}
                        isAction
                        onClick={e => {
                            e.stopPropagation()
                            !disabled && onChange(DEFAULT_FILTER_VALUE_VARIABLE)
                        }}
                    />
                )}
                <SC.Icon type="ArrowDownSmall" size={16} />
            </SC.IconWrapper>
        </SC.ItemContainer>
    )
}
