import { Flex, IconFont, pointer, Tag, Text } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import { getEnvColor } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled(Flex)`
    padding: 4px 16px;
    margin: 4px 0;
    ${pointer}
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxTag = styled(Tag)`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 4px;
    border-radius: 4px;
`

interface EnvMenuItemProps {
    isActive?: boolean
    data: Environment & { exist: boolean }
    onSelect?: (ev: React.MouseEvent<HTMLDivElement>) => void
}

export const EnvMenuItem: React.FC<EnvMenuItemProps> = ({ data, isActive, onSelect }) => {
    return (
        <SCxContainer direction="column" gap={6} onClick={onSelect}>
            <Flex justifyContent="space-between">
                <SCxTag style={{ backgroundColor: getEnvColor(data).tinyColor }}>
                    <IconFont size={16} type="DataSource" fill={getEnvColor(data).color} />
                    <Text color={getEnvColor(data).color} size={14} style={{ maxWidth: 90 }}>
                        {data?.envName}
                    </Text>
                </SCxTag>
                {isActive && <IconFont size={16} type="Tick" color="var(--color-main)" />}
            </Flex>
            {!data.exist && (
                <Text color="var(--color-gray-400)" size={12}>
                    该环境未找到当前表，点击后自动创建
                </Text>
            )}
        </SCxContainer>
    )
}
