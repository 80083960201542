import { Divider } from '@byecode/ui'
import { CollapseBox } from '@lighthouse/shared'
import React from 'react'

import * as SC from '../../styles'
import { LocationUsedList } from './LocationUsedList'
import { VariableList } from './VariableList'

export const ArgTriggerConfigure: React.FC = () => {
    return (
        <SC.Container>
            <CollapseBox label="子流程参数">
                <SC.Content>
                    <SC.Tip>定义子流程中使用的参数，参数值来自主流程（即用了当前子流程的流程）。</SC.Tip>
                    <VariableList />
                </SC.Content>
            </CollapseBox>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="使用了当前子流程的流程">
                <SC.Content style={{ paddingTop: 8 }}>
                    <LocationUsedList />
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
