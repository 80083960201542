import { Popover } from '@byecode/ui'
import { VariableType } from '@lighthouse/core'
import { useDisclosure } from '@mantine/hooks'
import { max, min } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled, { css } from 'styled-components'

import { ignoreEleId } from '../constant'
import { type VariableSelectDropDownProps, VariableSelectDropDown } from '../VariableSelectDropDown'
import { VariableInput } from './Input'
import SelectDataSourceModal from './SelectDataSource'

const SCxContainer = styled.div<{ highlighting?: boolean }>`
    ${({ highlighting }) =>
        highlighting &&
        css`
            border: 1px solid var(--color-main);
            border-radius: 6px;
            box-shadow: 0 0 4px 0 var(--color-main);
            overflow: hidden;
        `}
`

interface VariableSelectProps extends Omit<VariableSelectDropDownProps, 'onClose' | 'onOpenSelectDataSourceModal'> {
    placeholder?: string
    // sourceDataSourceList: SourceDsItem[]
    style?: React.CSSProperties
    width?: string
    isMultiple?: boolean
    withinPortal?: boolean
    disabled?: boolean
    highlighting?: boolean
    // currentUserInputType?: FieldType
    // disableUserInputType?: boolean
}

export const VariableSelect: React.FC<VariableSelectProps> = ({
    placeholder,
    style,
    width,
    isMultiple,
    // disableUserInputType,
    withinPortal = true,
    disabled,
    highlighting,
    // currentUserInputType,
    ...dropDownProps
}) => {
    const {
        value,
        field,
        options,
        dataSourceOption,
        inputOption,
        userOption,
        viewOption,
        formOption,
        filterOptions,
        disableInput,
        enablePageLink,
        dataSourceList,
        selectDataSourceProps,
        onChange
    } = dropDownProps
    const { enable, validateFieldType, fieldBlocksWithDsId } = selectDataSourceProps ?? {}
    const [opened, { close, open, toggle }] = useDisclosure(false)
    const [modalOpened, setModalOpened] = useState(false)
    const dropDownRef = useRef<HTMLDivElement>(null)
    // const tableHeight = useMemo(() => {
    //     if (!ref || !ref?.current || !opened) {
    //         return `${popoverMaxHeight}px`
    //     }
    //     const containRect = ref.current.getBoundingClientRect()
    //     const top = containRect.y
    //     const bottom = window.innerHeight - containRect.y - containRect.height
    //     const maxHeight = max(top, bottom)
    //     const usedHeight = max(50, maxHeight - 150)
    //     return `${min(popoverMaxHeight, usedHeight)}px`
    // }, [opened])

    const handleToggleOpen = useCallback(() => {
        const emptyOption = options && options.flatMap(option => option.list).length === 0
        const emptyUserOption = userOption && userOption.children.length === 0
        const emptyDataSourceOption = dataSourceOption && dataSourceOption.children.length === 0
        const emptyInputOption = inputOption && inputOption.children.length === 0
        const emptyViewOption = viewOption && viewOption.children.length === 0
        const emptyFilterOption = filterOptions && filterOptions.length === 0
        const isDisableOpen =
            emptyInputOption && emptyDataSourceOption && emptyUserOption && emptyOption && emptyViewOption && emptyFilterOption
        if (!opened && isDisableOpen && disableInput && !enablePageLink) {
            return
        }
        toggle()
    }, [dataSourceOption, disableInput, enablePageLink, filterOptions, inputOption, opened, options, toggle, userOption, viewOption])

    useClickAway(dropDownRef, e => {
        const { target } = e
        if (target instanceof Element) {
            if (document.querySelector(`#${ignoreEleId}`)?.contains(target)) {
                return
            }
            close()
        }
    })

    return (
        <Popover
            opened={opened}
            disabled={disabled}
            withinPortal={withinPortal}
            width={248}
            position="bottom-end"
            onClose={close}
        >
            <Popover.Target>
                <SCxContainer highlighting={highlighting} style={style}>
                    <div style={{ width: '100%' }}>
                        <VariableInput
                            disabled={disabled}
                            value={value}
                            field={field}
                            width={width}
                            opened={opened}
                            options={options}
                            userDsOption={userOption}
                            inputOption={inputOption}
                            viewOption={viewOption}
                            formOption={formOption}
                            filterOptions={filterOptions}
                            dataSourceOption={dataSourceOption}
                            placeholder={placeholder}
                            dataSourceList={dataSourceList}
                            onChangeOpen={handleToggleOpen}
                            onChange={onChange}
                            onOpenSelectDataSourceModal={() => setModalOpened(true)}
                        />
                        {enable && (
                            <SelectDataSourceModal
                                opened={modalOpened}
                                variable={value?.type === VariableType.SELECT_DATASOURCE ? value : undefined}
                                dataSourceList={dataSourceList}
                                validateFieldType={validateFieldType}
                                fieldBlocksWithDsId={fieldBlocksWithDsId}
                                onClose={() => setModalOpened(false)}
                                onConfirm={onChange}
                            />
                        )}
                    </div>
                </SCxContainer>
            </Popover.Target>
            <Popover.Dropdown>
                <VariableSelectDropDown
                    {...dropDownProps}
                    ref={dropDownRef}
                    onOpenSelectDataSourceModal={() => setModalOpened(true)}
                    onClose={close}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
