import type { FieldValue, TableColumns } from '@lighthouse/core'
import type { InsertDirection } from '@lighthouse/shared'
import { getMainTableRecordId } from '@lighthouse/shared'
import type { Getter, Setter } from 'jotai'
import { clone, findIndex, subtract } from 'rambda'

import { currentAppIdAtom } from '../application/state'
import { addRecordAtom, setDataSourceAtom, setRecordsAtom } from './action'
import type { CreateRecordActionPayload, DeleteRecordAtomPayload } from './types'

export const createRecordAction = (params: CreateRecordActionPayload, get: Getter, set: Setter) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, id, schema } = params
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                draft.records = [id, ...(draft.records ?? [])]
                if (draft.viewOptions.pagination?.rowTotal || draft.viewOptions.pagination?.rowTotal === 0) {
                    draft.viewOptions.pagination.rowTotal += 1
                }
            }
        }
    })
    const content: Record<string, FieldValue> = Object.fromEntries(
        Object.entries(schema).map(([fieldId, config]) => {
            return [
                fieldId,
                {
                    value: ''
                }
            ]
        })
    )
    const newRecord = { content, appId, envId, dsId, id }
    set(addRecordAtom, {
        records: [newRecord]
    })
}

export const deleteRecordAction = (params: DeleteRecordAtomPayload, get: Getter, set: Setter) => {
    const appId = get(currentAppIdAtom)
    // 解决连接表问题
    const { recordIds, envId, dsId, mode } = params
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                if (draft?.records) {
                    const mainRecordIds = new Set(recordIds.map(id => getMainTableRecordId(id)))
                    const records = mode === 'ALL' ? [] : draft.records.filter(id => !mainRecordIds.has(getMainTableRecordId(id)))
                    draft.records = records
                }
                if (draft.viewOptions.pagination?.rowTotal) {
                    const rowTotal = mode === 'ALL' ? 0 : subtract(draft.viewOptions.pagination.rowTotal, recordIds.length)
                    draft.viewOptions.pagination.rowTotal = rowTotal
                    draft.viewOptions.pagination.currentPage = 1
                }
            }
        }
    })

    set(setRecordsAtom, {
        records: draft => {
            if (mode === 'ALL') {
                for (let i = 0; i < draft.length; i++) {
                    if (draft[i].appId === appId && draft[i].dsId === dsId) {
                        draft.splice(i, 1)
                        i--
                    }
                }
                return
            }

            for (let i = 0; i < draft.length; i++) {
                const isEqualRecordId = recordIds.some(id => getMainTableRecordId(id) === getMainTableRecordId(draft[i].id))
                if (draft[i].appId === appId && isEqualRecordId) {
                    draft.splice(i, 1)
                    i--
                }
            }
        }
    })
}

export const getFieldBeforeId = (tableProps: TableColumns, sourceId: string | undefined, direction: InsertDirection | undefined) => {
    if (!sourceId || !direction) {
        return ''
    }
    const index = findIndex(item => item.id === sourceId, tableProps)
    return direction === 'left' ? sourceId : tableProps[index + 1]?.id
}
