import { useCustomViewBlockContext } from '@lighthouse/block'
import type { DataSourceAbstract, SystemVariableValue } from '@lighthouse/core'
import { type RecordLikeProtocol, type VariableADTvalue, matchFieldPI, VariableType } from '@lighthouse/core'
import type { FieldConvertValueOption } from '@lighthouse/shared'
import {
    parseField2TextValue,
    parseVariableDate,
    useAtomAction,
    useAtomData,
    USER_DATASOURCE,
    variableFieldConvertValue
} from '@lighthouse/shared'
import { addDays, endOfWeek, getMonth, getYear, lightFormat, startOfWeek, subDays, subMonths, subWeeks } from 'date-fns'
import { use } from 'echarts'
import { useAtomCallback } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import useSWRMutation from 'swr/mutation'

import { addRecordAtom, setDataSourceAtom } from '@/atoms/dataSource/action'
import { dataSourceAtomFamily, dataSourcePoolAtom, recordPoolAtom } from '@/atoms/dataSource/state'
import * as srv from '@/services'

import { useApplication, useCurrentAppID, useCurrentEnvId } from './useApplication'
import { usePageURI } from './usePageURI'
import { useUserRecord } from './useUserRecord'

type ParseVariableOptions = FieldConvertValueOption & { viewRecord?: RecordLikeProtocol }

/** 记录相应的数据源请求记录 */
const reqRecord: Record<string, Record<string, boolean>> = {}

/**
 * 解析变量选择器的值
 * @date 7/14/2023 - 3:55:16 PM
 * @param {string} [recordId] - 变量依赖的行id
 * @returns {Function}
 */
export const useVariableValueRender = (parentRecordId?: string, recordId?: string) => {
    // const currentAppId = useCurrentAppID()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { shareUrl } = usePageURI({ isCurrentPage: true })

    const { run: addRecord } = useAtomAction(addRecordAtom)
    const { run: setDataSource } = useAtomAction(setDataSourceAtom)

    const records = useAtomData(recordPoolAtom)
    const dataSourceList = useAtomData(dataSourcePoolAtom)

    const { trigger, isMutating } = useSWRMutation<RecordLikeProtocol | undefined, unknown, string, { dsId: string }>(
        `first-record`,
        async (_, { arg }) => {
            const { dsId } = arg
            const data = await srv.getDs({
                appId,
                envId,
                dsId,
                pagination: {
                    currentPage: 1,
                    pageSize: 1
                }
            })
            if (!data) {
                return
            }
            const { records } = data
            // setDataSource({ appId, dsId, dataSource: { ...datasource, records: records.map(i => i.id) } })
            addRecord({ records })

            return records[0]
        }
    )

    /** 渲染页面变量值 */
    const getPageValue = useAtomCallback<string, ['parentPage' | 'page', string, string, FieldConvertValueOption]>(
        useCallback(
            (get, _, type, dsId, fieldId, option) => {
                if (isMutating) {
                    return ''
                }
                const ds = get(dataSourceAtomFamily({ appId, envId, dsId }))

                const rId = type === 'page' ? recordId : parentRecordId
                if (!ds || !rId) {
                    return ''
                }

                const record = records.find(item => item.appId === appId && item.dsId === dsId && item.id === rId)

                if (!record) {
                    if (!reqRecord[appId]?.[dsId]) {
                        Reflect.set(reqRecord, appId, { ...reqRecord[appId], [dsId]: true })
                        trigger({ dsId })
                    }
                    return ''
                }

                return parseField2TextValue(ds, fieldId, record, option)
            },
            [isMutating, appId, envId, recordId, parentRecordId, records, trigger]
        )
    )

    const { record } = useUserRecord()

    /** 获取用户值 */
    const getUserValue = useCallback(
        (fieldId: string, dataSourceList: DataSourceAbstract[], option?: FieldConvertValueOption) => {
            if (!record) {
                return ''
            }

            const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
            if (!userDataSource) {
                return ''
            }

            return parseField2TextValue(userDataSource, fieldId, record, option)
        },
        [record]
    )

    /** 获取自定义视图的值 */
    const getCustomViewValue = useCallback(
        (fieldId: string, dataSourceList: DataSourceAbstract[], option?: ParseVariableOptions) => {
            const viewRecord = option?.viewRecord
            if (!viewRecord) {
                return ''
            }
            const ds = dataSourceList.find(item => item.id === viewRecord.dsId)
            if (!ds) {
                return ''
            }

            return parseField2TextValue(ds, fieldId, viewRecord, option)
        },
        []
    )

    return useCallback(
        (value: VariableADTvalue | undefined, option: ParseVariableOptions) => {
            if (!value) {
                return ''
            }
            return matchFieldPI(value)(
                {
                    [VariableType.SYSTEM]: v => {
                        if (!v.systemVariable?.value) {
                            return ''
                        }
                        return getSystemVariableSingleValue(v.systemVariable.value, v.systemVariable.format)
                    },
                    [VariableType.USER]: v => {
                        const fieldId = v.userVariable?.fieldId
                        if (!fieldId) {
                            return ''
                        }
                        return getUserValue(fieldId, dataSourceList, { ...option, format: v.userVariable?.format })
                    },
                    [VariableType.PAGE]: v => {
                        const { type, dsId, fieldId } = v.pageVariable ?? {}
                        if (!type || !dsId || !fieldId) {
                            return ''
                        }
                        return getPageValue(type, dsId, fieldId, { ...option, format: v.pageVariable?.format })
                    },
                    [VariableType.VALUE]: v => {
                        if (v.valueVariable) {
                            return variableFieldConvertValue(v.valueVariable, option)
                        }
                        return ''
                    },
                    [VariableType.VIEW]: v => {
                        const fieldId = v.viewVariable?.fieldId
                        if (!fieldId) {
                            return ''
                        }
                        return getCustomViewValue(fieldId, dataSourceList, { ...option, format: v.viewVariable?.format })
                    },
                    [VariableType.PAGE_LINK]: v => {
                        const value = v.pageLinkVariable?.value
                        if (!value) {
                            return ''
                        }
                        return shareUrl
                    }
                },
                () => ''
            )
        },
        [dataSourceList, getCustomViewValue, getPageValue, getUserValue, shareUrl]
    )
}

function getSystemVariableSingleValue(key: SystemVariableValue, format?: string): string {
    // eslint-disable-next-line unicorn/prefer-default-parameters
    const mergeFormat = format || 'yyyy-MM-dd'
    switch (key) {
        case 'NOW':
        case 'TODAY': {
            return parseVariableDate(new Date(), mergeFormat)
        }

        case 'YESTERDAY': {
            return parseVariableDate(subDays(new Date(), 1), mergeFormat)
        }

        case 'TOMORROW': {
            return parseVariableDate(addDays(new Date(), 1), mergeFormat)
        }

        // case 'LAST_WEEK': {
        //     return `${lightFormat(startOfWeek(subWeeks(new Date(), 1)), format)} ~ ${lightFormat(
        //         endOfWeek(subWeeks(new Date(), 1)),
        //         format
        //     )}`
        // }

        // case 'LAST_MONTH': {
        //     return getMonth(subMonths(new Date(), 1)).toString()
        // }

        // case 'THIS_WEEK': {
        //     return `${lightFormat(startOfWeek(new Date()), format)} ~ ${lightFormat(endOfWeek(new Date()), format)}`
        // }

        // case 'THIS_MONTH': {
        //     return getMonth(new Date()).toString()
        // }

        // case 'THIS_YEAR': {
        //     return getYear(new Date()).toString()
        // }

        default: {
            return ''
        }
    }
}
