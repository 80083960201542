import { backgroundTransitionOnClick, pointer } from '@byecode/ui'
import type { WorkSpaceAbstract } from '@lighthouse/core'
import { getImageFullUrlInCommon, spaceGradeNameMap } from '@lighthouse/shared'
import React from 'react'
import styled, { css } from 'styled-components'

export interface SpaceItemProps {
    children?: React.ReactNode
    data: WorkSpaceAbstract
    onClick?: (id: string) => void
}

const Item = styled.div<{ selected?: boolean }>`
    display: flex;
    height: 58px;
    width: 100%;
    padding: 0 16px;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    font-size: var(--font-size-normal);
    margin-bottom: 4px;
    ${pointer}
    ${backgroundTransitionOnClick};
    background-color: ${properties => properties.selected && 'var(--color-gray-1)'};
`

const Avatar = styled.div`
    position: relative;
    width: 36px;
    height: 36px;
    margin-right: 10px;
`

const Dot = styled.div`
    position: absolute;
    top: -2px;
    right: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-white);

    ::after {
        content: ' ';
        display: block;
        position: relative;
        top: 2px;
        left: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #d88987;
    }
`
const SpaceItemBackground = styled.div<{ background?: string }>`
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 5px;

    ${({ background }) =>
        background
            ? css`
                  background: url('${background}');
              `
            : css`
                  background: var(--color-gray-2);
              `}

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const SpaceItemAbbrText = styled.div`
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: var(--color-gray-100);
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    color: var(--color-black);

    font-size: 14px;
`

const SpaceItemInfo = styled.div`
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 2px;
    /* padding-top: 4px; */
`

const SpaceItemText = styled.div`
    width: 100%;
    height: 22px;
    line-height: 1.5;
    font-weight: 400;
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SpaceItemDes = styled.div`
    padding-top: 2px;
    color: var(--color-gray-500);
    font-size: 12px;
    height: 20px;
    line-height: 1.5;
    white-space: nowrap;
`

const SpaceItem: React.FC<SpaceItemProps> = ({ data, onClick }) => {
    const { id, name, icon, members, browseStart, version } = data
    return (
        <Item onClick={() => onClick?.(id)}>
            <Avatar>
                {icon ? <SpaceItemBackground background={getImageFullUrlInCommon(icon)} /> : <SpaceItemAbbrText>{name.slice(0, 1)}</SpaceItemAbbrText>}
                {!!browseStart && <Dot />}
            </Avatar>
            <SpaceItemInfo>
                <SpaceItemText>{name}</SpaceItemText>
                <SpaceItemDes>
                    {version ? `${spaceGradeNameMap[version]} · ` : ''}
                    {members}个成员
                </SpaceItemDes>
            </SpaceItemInfo>
        </Item>
    )
}

export default SpaceItem
