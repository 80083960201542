import type { ApplicationSettingAuthentication, FieldType} from '@lighthouse/core';
import { ApplicationSettingPerfectMode,SMS_TYPE  } from '@lighthouse/core'

export const registerWayMap = new Map<number, string>([
    [0, '用户直接注册，无需审核'],
    [1, '用户提交注册信息，需要管理员审核']
])

export const registerModeList: {
    label: string
    value: ApplicationSettingPerfectMode
}[] = [
    {
        label: '自定义',
        value: ApplicationSettingPerfectMode.COMMON
    },
    {
        label: '按角色定义',
        value: ApplicationSettingPerfectMode.ROLE
    }
]

export const selectTypeList: FieldType[] = ['text', 'email', 'phoneNumber', 'url', 'number', 'select', 'date', 'checkbox']

export const selectUserSystemFieldIds = ['USERNAME', 'DEPARTMENT', 'ROLE']

export const register: ApplicationSettingAuthentication = {
    logo: {
        type: 'icon',
        value: 'xxx'
    },
    name: '',
    describe: '',
    login: {
        phone: {
            isOpened: false,
        },
        email: {
            isOpened: false
        },
        weChat: {
            // isOpenedH5Auth: false,
            isOpenedH5QrcodeLogin: false,
            isOpened: false,
            isBindPhone: true
        },
        google: {
            isOpened: false,
            accountId: 'xxx'
        },
        landPageId: 'xxx'
    },
    register: {
        allowRegister: true,
        isReviewed: false
    },
    perfect: {
        title: '',
        describe: '',
        selectRoleTitle: '',
        selectRoleDescribe: '',
        isOpened: false,
        mode: ApplicationSettingPerfectMode.COMMON,
        roleSetting: [
            {
                roleId: 'xx',
                isShown: true,
                fields: [
                    {
                        fieldId: 'xxx',
                        title: 'xxx',
                        placeHolder: 'xxx',
                        required: false
                    }
                ]
            }
        ],
        commonSetting: {
            fields: [
                {
                    fieldId: 'xxx',
                    title: 'xxx',
                    placeHolder: 'xxx',
                    required: false
                }
            ]
        }
    }
}
