import { IconFont, pointer, Popover } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { Divider } from '@mantine/core'
import React from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import * as srv from '@/services'

import { EnvMenuItem } from './EnvMenuItem'

const SCxListContain = styled.div`
    width: 100%;
    /* padding: 8px 0; */
    outline: none;
`

const SCxListGroup = styled.div`
    width: 100%;
    /* padding: 8px 0; */
`

const SCxListGroupTitle = styled.div`
    padding: 4px 12px 1.5px 16px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

const SCxMenuItem = styled.div`
    width: 100%;
    min-width: 200px;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxMenuItemWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
`

const SCxName = styled.span`
    margin-left: 7.5px;
    line-height: 16px;
    color: var(--color-gray-900);
    /* font-size: var(--font-size-base); */
`

export interface MenuItemChildrenItems<T> {
    name: string
    icon: string
    hotkey?: string
    action: T
    divider?: boolean
    extra?: React.ReactNode
}
export interface MenuItem<T extends string> {
    section?: string
    name?: string
    items: MenuItemChildrenItems<T>[]
}

type OperationMenuProps<T extends string> = {
    data: DataSourceAbstract
    envId: string
    menuItems?: MenuItem<T>[]
    canSwitchEnv?: boolean
    extra?: React.ReactNode
    handlers?: (dsId: string) => Record<string, (sender: React.MouseEvent<HTMLDivElement>, params?: { envId: string }) => void>
}

export const OperationMenuItem: React.FunctionComponent<OperationMenuProps<string>> = ({
    data,
    envId,
    menuItems = [],
    canSwitchEnv,
    extra,
    handlers
}) => {
    const { id: dsId, syncEnvId } = data
    const { value = [] } = useAsyncRetry(() => srv.getListSyncEnvDs({ envId, dsId }), [])

    return (
        <SCxListContain onClick={e => e.stopPropagation()}>
            {menuItems.map(({ section, items }) => (
                <SCxListGroup key={section}>
                    {section && <SCxListGroupTitle>{section}</SCxListGroupTitle>}
                    {items.map(({ name, icon, hotkey, action, divider, extra }) => {
                        if (divider) {
                            return <Divider key={name} />
                        }
                        if (action === 'SWITCH_ENV') {
                            return (
                                <Popover width={240} key={name} trigger="hover" position="right">
                                    <Popover.Target>
                                        <SCxMenuItem key={name}>
                                            <SCxMenuItemWrapper>
                                                <IconFont type={icon} size={16} color="var(--color-gray-400)" />
                                                <SCxName>{name}</SCxName>
                                            </SCxMenuItemWrapper>
                                            {extra}
                                        </SCxMenuItem>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        {value.map(item => (
                                            <EnvMenuItem
                                                key={item.envId}
                                                data={item}
                                                isActive={item.envId === syncEnvId}
                                                onSelect={sender => handlers?.(dsId)?.[action]?.(sender, { envId: item.envId })}
                                            />
                                        ))}
                                    </Popover.Dropdown>
                                </Popover>
                            )
                        }

                        return (
                            <SCxMenuItem key={name} onClick={sender => handlers?.(dsId)?.[action]?.(sender)}>
                                <SCxMenuItemWrapper>
                                    <IconFont type={icon} size={16} color="var(--color-gray-400)" />
                                    <SCxName>{name}</SCxName>
                                </SCxMenuItemWrapper>
                                {extra}
                            </SCxMenuItem>
                        )
                    })}
                </SCxListGroup>
            ))}
            {extra}
        </SCxListContain>
    )
}
