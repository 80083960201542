import { Toast } from '@byecode/ui'
import type { BlockAbstract, PageNode } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { findNodeById, findNodeParentById, FLOW_LAYOUT_NODE_ROWS, useAtomAction, useAtomData } from '@lighthouse/shared'
import { getBrowser } from '@lighthouse/tools'
import { current, original } from 'immer'
import { useAtom } from 'jotai'
import { clone } from 'rambda'
import { useCallback, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { pageNodesAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { boxSelectionNodeIdsAtom, copiedBoxSelectionNodesSnapshotAtom } from '@/atoms/workSpace/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'
import { deleteBlock } from '@/services'
import { BLOCK_NODE_CREATE_ACTION, BLOCK_NODE_REMOVE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import {
    collectRemovedBlockIds,
    copyBlocksAndNodes,
    findPageNodeById,
    getRealParentNodeId,
    insertNodeByOverId,
    insertNodeByParentId,
    isLeaf,
    removeNodeById
} from './utils'

type Configure = {
    ref: React.RefObject<HTMLDivElement | null>
    enabled?: boolean
    flowNodes: FlowLayoutNode[]
    blocks: BlockAbstract[]
    pageNodes: PageNode[]
}

/** 多选/框选节点hooks */
export const useBoxSelectionNodes = ({ ref, enabled, flowNodes, pageNodes, blocks }: Configure) => {
    const [boxSelectionIds, setBoxSelectionIds] = useAtom(boxSelectionNodeIdsAtom)
    const [copiedBoxSelectionNodesSnapshot, setCopiedBoxSelectionNodesSnapshot] = useAtom(copiedBoxSelectionNodesSnapshotAtom)

    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )
    const stackState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.state, [])
    )
    const { selectedNode: selectedNodeId, asideType } = stackState ?? {}

    const { run: createBlock } = useAtomAction(createBlockAtom)
    const { run: setPageNodes } = useAtomAction(pageNodesAtom)
    const { run: removeBlock } = useAtomAction(removeBlockAtom)

    const handleCopy = useCallback(
        (copyIds: string[]) => {
            setCopiedBoxSelectionNodesSnapshot({ copiedIds: copyIds, blocks: clone(blocks), nodes: clone(pageNodes) })
            // setBoxSelectionIds([])
            Toast.success(`已复制${copyIds.length}个组件`)
        },
        [blocks, pageNodes, setCopiedBoxSelectionNodesSnapshot]
    )

    const handlePaste = useCallback(
        async (snapshot: typeof copiedBoxSelectionNodesSnapshot) => {
            let targetNode: FlowLayoutNode | undefined
            let needCheckSize = false

            const pastePageNodes = snapshot.copiedIds.map(id => findPageNodeById(id)(snapshot.nodes)).filter(Boolean) as PageNode[]
            const { newBlocks, newNodes } = copyBlocksAndNodes(pastePageNodes, snapshot.blocks)

            function computedSize(children: FlowLayoutNode[]) {
                const pasteSize = newNodes.reduce((total, current) => total + (current.minWidth ?? 1), 0)
                const oldSize = children.reduce((total, current) => total + (current.minWidth ?? 1), 0)

                return pasteSize + oldSize
            }

            if (asideType === AsideType.BLOCK) {
                if (!selectedNodeId) {
                    return
                }

                targetNode = findNodeById(selectedNodeId)(flowNodes)
                if (!targetNode) {
                    return
                }

                if (targetNode.type === 'block') {
                    const parentNode = findNodeParentById(targetNode.id)(flowNodes)
                    const parentBlock = parentNode && blocks.find(item => item.id === parentNode.id)
                    const isContainerParent =
                        parentBlock?.type === BlockType.container ||
                        parentBlock?.type === BlockType.formContainer ||
                        (parentBlock?.type === BlockType.view && parentBlock.config.viewType === 'custom')
                    const parentDirection = isContainerParent ? parentBlock.config.design?.direction : DIRECTION.vertical
                    needCheckSize = parentDirection === DIRECTION.horizontal
                    if (parentNode && parentNode.children && needCheckSize) {
                        const totalSize = computedSize(parentNode.children)
                        if (totalSize > FLOW_LAYOUT_NODE_ROWS) {
                            Toast.error('超过最大内容限制，无法插入')
                            return
                        }
                    }
                } else {
                    const { direction } = targetNode.data
                    needCheckSize = direction === DIRECTION.horizontal
                    if (targetNode.children && needCheckSize) {
                        const totalSize = computedSize(targetNode.children)
                        if (totalSize > FLOW_LAYOUT_NODE_ROWS) {
                            Toast.error('超过最大内容限制，无法插入')
                            return
                        }
                    }
                }
            }

            let originNodes: PageNode[] | undefined
            let finalNodes: PageNode[] = []
            setPageNodes(draft => {
                const pageNodes = draft[pageId]
                originNodes = original(pageNodes)
                if (!pageNodes) {
                    return
                }

                if (asideType === AsideType.PAGE) {
                    pageNodes.push(...newNodes)
                } else if (asideType === AsideType.BLOCK && targetNode) {
                    if (targetNode.type === 'block') {
                        insertNodeByOverId(newNodes, targetNode.id, false, needCheckSize)(pageNodes)
                    } else {
                        const parentId = getRealParentNodeId(targetNode.id, blocks, blockRuntimeState)

                        if (!parentId) {
                            return
                        }
                        insertNodeByParentId(newNodes, parentId, false, needCheckSize)(pageNodes)
                    }
                }

                finalNodes = current(pageNodes)
            })

            const nodeRes = await srv.updateNodes({ id: pageId, nodes: finalNodes })
            if (!nodeRes) {
                return
            }

            const blockRes = await createBlock({ rootPageId, pageId, stackId, blocks: newBlocks })
            if (!blockRes) {
                return
            }

            pageUndoRedoController.add({
                action: BLOCK_NODE_CREATE_ACTION,
                payload: {
                    blocks: newBlocks,
                    nodes: { prev: originNodes, next: finalNodes }
                }
            })
        },
        [asideType, blockRuntimeState, blocks, createBlock, flowNodes, pageId, rootPageId, selectedNodeId, setPageNodes, stackId]
    )

    // // 检查是否有父级节点存在
    // const checkInclude = useCallback(
    //     (ids: string[]) => {
    //         const willRemoveIndexes: string[] = []
    //         const { length } = ids
    //         for (let i = 0; i < length; i++) {
    //             for (let j = 0; j < length; j++) {
    //                 if (i === j) {
    //                     continue
    //                 }
    //                 if (isLeaf(ids[i], ids[j], flowNodes)) {
    //                     willRemoveIndexes.push(ids[j])
    //                 }
    //             }
    //         }

    //         return ids.filter(item => !willRemoveIndexes.includes(item))
    //     },
    //     [flowNodes]
    // )

    const { run: setPageStack } = useAtomAction(pageStackAtom)
    useEffect(() => {
        if (boxSelectionIds.length === 1 && !selectedNodeId) {
            const [id] = boxSelectionIds
            setBoxSelectionIds([])
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.selectedNode = id
                    stack.state.asideType = AsideType.BLOCK
                }
            })
        }

        if (boxSelectionIds.length !== 0 && selectedNodeId) {
            setBoxSelectionIds(draft => [...new Set([selectedNodeId, ...draft])])
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.selectedNode = undefined
                    stack.state.asideType = AsideType.PAGE
                }
            })
        }
    }, [boxSelectionIds, rootPageId, selectedNodeId, setBoxSelectionIds, setPageStack, stackId])

    useHotkeys(
        'ctrl+c, ⌘+c, ctrl+v, ⌘+v',
        (event, hotkeysEvent) => {
            const isMac = getBrowser().getBrowserInfo().platform === 'Macintosh'

            if ((boxSelectionIds.length > 0 || !!selectedNodeId) && (isMac ? hotkeysEvent.key === '⌘+c' : hotkeysEvent.key === 'ctrl+c')) {
                handleCopy(selectedNodeId ? [selectedNodeId, ...boxSelectionIds] : boxSelectionIds)
            }

            if (
                copiedBoxSelectionNodesSnapshot.copiedIds.length > 0 &&
                (isMac ? hotkeysEvent.key === '⌘+v' : hotkeysEvent.key === 'ctrl+v')
            ) {
                if (!ref.current?.contains(event.target as Node)) {
                    return
                }
                handlePaste(copiedBoxSelectionNodesSnapshot)
            }
        },
        { enabled },
        [enabled, selectedNodeId, boxSelectionIds, copiedBoxSelectionNodesSnapshot, handleCopy, handlePaste]
    )

    // 删除block
    const handleRemove = useCallback(
        async (ids: string[]) => {
            let originNodes: PageNode[] | undefined
            let finalNodes: PageNode[] = []
            let removedNodeIds: string[] = []
            setPageNodes(draft => {
                const pageNodes = draft[pageId]
                originNodes = original(pageNodes)
                if (!pageNodes) {
                    return
                }
                ids.forEach(id => {
                    const removed = removeNodeById(id)(pageNodes)
                    if (removed) {
                        removedNodeIds = [...removedNodeIds, ...collectRemovedBlockIds(removed, blocks)]
                    }
                })

                finalNodes = current(pageNodes)
            })

            const nodeRes = await srv.updateNodes({ id: pageId, nodes: finalNodes })

            if (!nodeRes) {
                return
            }

            const blockRes = await removeBlock({ rootPageId, pageId, stackId, blockIds: removedNodeIds })
            if (!blockRes) {
                return
            }

            pageUndoRedoController.add({
                action: BLOCK_NODE_REMOVE_ACTION,
                payload: {
                    blocks: removedNodeIds.map(item => blocks.find(block => block.id === item)).filter(Boolean) as BlockAbstract[],
                    nodes: { prev: originNodes, next: finalNodes }
                }
            })
        },
        [blocks, pageId, removeBlock, rootPageId, setPageNodes, stackId]
    )

    useHotkeys(
        'Delete, Backspace',
        (event, hotkeysEvent) => {
            const ids = selectedNodeId ? [selectedNodeId, ...boxSelectionIds] : boxSelectionIds
            if (ids.length > 0) {
                handleRemove(ids)
            }
        },
        { enabled },
        [enabled, boxSelectionIds, selectedNodeId, handleRemove]
    )

    const onBoxSelectionIdsChange = useCallback(
        (ids: string[]) => {
            setBoxSelectionIds(draft => {
                if (ids.length === 0) {
                    return []
                }
                const totalArr = [...new Set([...(selectedNodeId ? [selectedNodeId] : []), ...draft])]
                const newLength = ids.length
                const oldLength = totalArr.length
                const willAppendArr: string[] = [...ids]
                const willRemoveArr: string[] = []
                for (let i = 0; i < newLength; i++) {
                    for (let j = 0; j < oldLength; j++) {
                        if (ids[i] === totalArr[j]) {
                            willRemoveArr.push(totalArr[j])
                            willAppendArr.splice(i, 1)
                            continue
                        }
                        if (isLeaf(ids[i], totalArr[j], flowNodes) || isLeaf(totalArr[j], ids[i], flowNodes)) {
                            willRemoveArr.push(totalArr[j])
                        }
                    }
                }

                // 如果选中的节点也被取消了，需要重置状态
                if (selectedNodeId && willRemoveArr.includes(selectedNodeId)) {
                    setPageStack(draft => {
                        const stack = equalPageStack({ rootPageId, stackId })(draft)
                        if (stack) {
                            stack.state.selectedNode = undefined
                            stack.state.asideType = AsideType.PAGE
                        }
                    })
                }

                return [...totalArr.filter(item => !willRemoveArr.includes(item)), ...willAppendArr]
            })
        },
        [flowNodes, rootPageId, selectedNodeId, setBoxSelectionIds, setPageStack, stackId]
    )

    return {
        boxSelectionIds,
        onBoxSelectionIdsChange
    }
}
