import { Input } from '@byecode/ui'
import React, { useCallback, useState } from 'react'

export interface TitleInputProps {
    defaultValue: string
    style?: React.CSSProperties
    onBlur?: (val: string) => void
}

export const TitleInput: React.FC<TitleInputProps> = ({ defaultValue, style, onBlur }) => {
    const [value, setValue] = useState(defaultValue)

    const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value
        setValue(val)
    }, [])

    const handleBlur = useCallback(
        (ev: React.FocusEvent<HTMLInputElement>) => {
            const val = value.trim() ? value : defaultValue
            setValue(val)
            onBlur?.(val)
        },
        [defaultValue, onBlur, value]
    )

    return <Input value={value} style={style} onChange={handleChange} onBlur={handleBlur} />
}
