import { Button, IconFont, Image, Select, Text } from '@byecode/ui'
import { ErrorMessage, TitleInContentModal } from '@lighthouse/shared'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useAsyncFn, useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import * as srv from '@/services'

const SCxContainer = styled.form`
    outline: none;
    padding: 0 32px 48px 32px;
`

const SCxContent = styled.div`
    margin-bottom: 36px;
`

const SCxItem = styled.div`
    margin-bottom: 20px;
`

const SCxLabel = styled.div`
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
    margin-bottom: 10px;
`

const SCxSelect = styled(Select)``

const SCxSubmit = styled(Button)`
    height: 40px;
`

const SCxLogoWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: var(--color-gray-100);
    border-radius: 8px;
    padding: 12px;
`

const SCxLogo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxImage = styled(Image)`
    width: 16px;
    height: 16px;
    border-radius: 2px;
    overflow: hidden;
`

const SCxTitleWrapper = styled.div``

type CopyPrivateTemplateForm = {
    spaceId: string
}

interface CopyPrivateTemplateProps {
    open: boolean
    appId: string
    appName: string
    appIcon: string
    appColor?: string
    createdBy?: string
    onClose?: () => void
}

export const CopyPrivateTemplate: React.FC<CopyPrivateTemplateProps> = ({ open, appId, appName, appIcon, appColor = 'var(--color-main)', createdBy, onClose }) => {
    // const { id: appId, name, createdBy, icon, config } = data
    const {
        formState: { errors },
        control,
        handleSubmit
    } = useForm<CopyPrivateTemplateForm>({
        mode: 'onSubmit',
        shouldFocusError: false
    })


    const navigate = useNavigate()
    const { value } = useAsyncRetry(() => srv.getWorkSpaceList(), [])
    const [{ loading: copyLoading }, copyApp] = useAsyncFn(srv.copyApp, [])

    const handleCopy = useCallback(
        async (payload: { spaceId: string }) => {
            if (!appId) {
                return
            }
            const { spaceId } = payload
            const copyAppId = await copyApp({ targetSpaceId: spaceId, appId })
            if (copyAppId) {
                navigate({ pathname: `/${copyAppId}/page` })
            }
        },
        [appId, copyApp, navigate]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(handleCopy)()
        },
        [handleCopy, handleSubmit]
    )

    const spaceOptions = useMemo(
        () =>
            value?.map(item => ({
                label: item.name,
                value: item.id
            })) || [],
        [value]
    )

    return (
        <TitleInContentModal open={open} width={520} title="您想将模板添加到那个空间？" onClose={onClose}>
            <SCxContainer onSubmit={handleFormSubmit}>
                <SCxContent>
                    <SCxItem>
                        <SCxLabel>复制模板</SCxLabel>
                        <SCxLogoWrapper>
                            <SCxLogo style={{ backgroundColor: appColor }}>
                                {appIcon && <IconFont type={appIcon} size={28} fill="var(--color-white)" />}
                            </SCxLogo>
                            <SCxTitleWrapper>
                                <Text size={14} color="var(--color-black)" weight={600}>
                                    {appName}
                                </Text>
                                <Text size={12} color="var(--color-gray-500)">
                                    分享者 {createdBy}
                                </Text>
                            </SCxTitleWrapper>
                        </SCxLogoWrapper>
                    </SCxItem>
                    <SCxItem>
                        <SCxLabel>选择空间</SCxLabel>
                        <Controller
                            control={control}
                            name="spaceId"
                            rules={{ required: '请选择空间' }}
                            render={({ field }) => (
                                <ErrorMessage name="spaceId" errors={errors}>
                                    <SCxSelect size="lg" searchable options={spaceOptions} value={field.value} onChange={field.onChange} />
                                </ErrorMessage>
                            )}
                        />
                    </SCxItem>
                </SCxContent>
                <SCxSubmit block loading={copyLoading} htmlType="submit" type="primary">
                    确认复制
                </SCxSubmit>
            </SCxContainer>
        </TitleInContentModal>
    )
}
