import type { HandleSubProcessActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, getHandleSubProcessVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleSubProcess = async (node: ActionFlowNode<HandleSubProcessActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    // 查询单条记录暂时只能使用给定的数据源
    const { subProcessId, args } = config

    if (!subProcessId) {
        return excParams
    }

    const params = getHandleSubProcessVariableValue(config, excParams)

    const isSuccess = await srv.activeSubProcess({
        workflowId: subProcessId,
        args: params
    })

    if (!isSuccess) {
        throw new Error('执行子流程异常')
    }


    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: isSuccess
    })
}
