import { Button, Divider, Flex, IconFont, Image, Modal, Text, useDisclosure } from '@byecode/ui'
import { Empty } from '@byecode/ui/components/Empty'
import { getAssetUrl } from '@lighthouse/assets'
import { LANGUAGE_LIST, SelectLanguage, spaceVersionEnum, TitleInContentModal, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { languageAtom, websiteApplicationSettingAtom } from '@/atoms/application/state'
import SettingLanguage from '@/components/ApplicationSetting/SettingLanguage'
import { openSpaceGradeConfirm, SpaceGradeTag } from '@/components/SpaceGrade'
import { useToggleLang } from '@/hooks/useToggleLang'
import { useSpaceQuota } from '@/shared/reusable'

interface ToggleLanguageProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--color-gray-200);
`

const SCxItem = styled(Flex)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    gap: 8px;
    height: 36px;
    cursor: pointer;
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const ToggleLanguage: React.FunctionComponent<ToggleLanguageProps> = props => {
    const language = useAtomData(languageAtom)
    const [opened, { close, open }] = useDisclosure(false)
    const { data: quota } = useSpaceQuota()

    const options = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(
            s =>
                s?.language?.list?.map(item => {
                    const LANG = LANGUAGE_LIST.find(lang => lang.lang === item.lang)
                    return {
                        value: item.lang,
                        label: item.title,
                        icon: LANG?.flag,
                        extra: item.lang === language ? <IconFont color="var(--color-main)" size={16} type="Tick" /> : undefined
                    }
                }) ?? [],
            [language]
        )
    )

    const option = useMemo(() => options.find(option => option.value === language), [language, options])

    const { handleToggleLang } = useToggleLang()

    return (
        <SCxContainer>
            <SelectLanguage
                options={options}
                onClick={handleToggleLang}
                searchable
                width={263}
                empty={
                    <Empty
                        styles={{
                            root: {
                                padding: '28px 24px',
                                color: 'var(--color-gray-500)'
                            },
                            wrapper: {
                                gap: 20
                            }
                        }}
                        icon={<Image width={123} height={123} src={getAssetUrl('empty', 'empty_language_setting.jpg')} />}
                        description={
                            <Text whiteSpace="pre-wrap">
                                如果您想让页面支持切换多语言版本，请到“<b>应用设置</b> {'>'} <b>语言版本</b>”添加语言版本
                            </Text>
                        }
                    />
                }
                targetComponent={
                    <Button
                        type="text"
                        block
                        style={{ justifyContent: 'space-between', padding: '0 12px', height: '100%', borderRadius: 0 }}
                    >
                        <Text color="var(--color-black)" size={14}>
                            语言环境
                        </Text>
                        <Flex gap={4}>
                            <IconFont color="var(--color-gray-400)" size={16} type="Earth" />
                            <Text>{option?.label}</Text>
                            <IconFont color="var(--color-gray-400)" size={16} type="ArrowOpen" />
                        </Flex>
                    </Button>
                }
                extra={
                    <>
                        <Divider style={{ margin: '8px 0' }} />
                        <SCxItem
                            onClick={() => {
                                if (quota && quota?.currentVersionCode < spaceVersionEnum.STANDARD) {
                                    openSpaceGradeConfirm('STANDARD')
                                    return
                                }
                                open()
                            }}
                        >
                            <Flex gap={8} alignItems="center">
                                <IconFont color="var(--color-gray-400)" size={16} type="Setting" />
                                <Text size={14} color="var(--color-black)">
                                    管理语言版本
                                </Text>
                            </Flex>

                            <SpaceGradeTag value={spaceVersionEnum.STANDARD} />
                        </SCxItem>
                    </>
                }
            />
            <TitleInContentModal
                styles={{
                    desktop: {
                        modal: {
                            height: '80%'
                        }
                    }
                }}
                width={800}
                title="语言版本"
                description="让你的页面可切换多个语言版本"
                open={opened}
                onClose={close}
            >
                <SettingLanguage />
            </TitleInContentModal>
        </SCxContainer>
    )
}

export default ToggleLanguage
