import { Button, IconFont } from '@byecode/ui'
import type { DataSourceAbstract, JoinSetting } from '@lighthouse/core'
import {
    CollapseBox,
    FindUseType,
    getIsFindUseInJoinSetting,
    useApplicationContext,
    useAtomData,
    useFindUseObjectContext
} from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { JoinConfigureModal } from '../JoinConfigureModal'
import { JoinConfigureItem } from './JoinConfigureItem'

const SCxContainer = styled.div`
    padding: 12px 0;
`

const SCxContent = styled.div`
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const SCxButtonWrapper = styled.div`
    margin: 4px 0;
`

interface JoinConfigureProps {
    dataSourceList: DataSourceAbstract[]
}

export const JoinConfigure: React.FC<JoinConfigureProps> = ({ dataSourceList }) => {
    const { control } = useFormContext()
    const findUseObject = useFindUseObjectContext()
    const { personOptions } = useApplicationContext()
    const [open, setOpen] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'conditions'
    })

    const primaryDsId = useWatch({
        control,
        name: 'primaryDsId'
    })

    const conditions = useWatch({
        control,
        name: 'conditions'
    })

    const dsIds: string[][] = useMemo(() => {
        if (!conditions) {
            return []
        }
        return [...conditions.map((item: JoinSetting) => [item.joinedDsId])]
    }, [conditions])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleCreate = useCallback(
        (val: JoinSetting) => {
            append(val)
        },
        [append]
    )

    const handleAdd = useCallback(() => {
        setOpen(true)
    }, [])

    const handleDelete = useCallback(
        (index: number) => {
            remove(index)
        },
        [remove]
    )

    return (
        <SCxContainer>
            <CollapseBox label="关联表">
                <SCxContent>
                    {fields.map((item, index) => (
                        <Controller
                            key={index}
                            name={`conditions.${index}`}
                            control={control}
                            render={({ field }) => {
                                const joinSetting = clone(field.value)
                                const isHighLight = getIsFindUseInJoinSetting({
                                    findUseObject,
                                    type: FindUseType.DATASHEET,
                                    data: joinSetting
                                })
                                return (
                                    <JoinConfigureItem
                                        index={index}
                                        useDsIds={dsIds}
                                        highlighting={isHighLight}
                                        primaryDsId={primaryDsId}
                                        dataSourceList={dataSourceList}
                                        value={joinSetting}
                                        personOptions={personOptions}
                                        onChange={field.onChange}
                                        onDelete={() => handleDelete(index)}
                                    />
                                )
                            }}
                        />
                    ))}
                    {/* JoinConfigureItem */}
                    <SCxButtonWrapper>
                        <Button radius={100} icon={<IconFont type="Add" fill="var(--color-gray-500)" size={16} />} onClick={handleAdd}>
                            添加
                        </Button>
                    </SCxButtonWrapper>
                </SCxContent>
            </CollapseBox>
            <JoinConfigureModal
                index={fields.length}
                useDsIds={dsIds}
                onConfirm={handleCreate}
                dataSourceList={dataSourceList}
                primaryDsId={primaryDsId}
                open={open}
                onClose={handleClose}
            />
        </SCxContainer>
    )
}
