import { type FieldType, VariableType } from '@lighthouse/core'

import type { ActionNodeConfig, ActionNodeType, FlowNode, NodeOption, NodeTypes, OtherPlatformOption } from '../types'
import { FlowSelectRecordType, FlowSelectSourceType, LoopErrorHandlerEnum, LoopTypeEnum, OpenDetailPageSelectedRecordType } from '../types'

export const NODE_WIDTH = 248
export const NODE_HEIGHT = 60
export const NODE_HORIZONTAL_GAP = 160
export const NODE_VERTICAL_GAP = 200

export const IGNORE_WATCH_NODE_FIELD_TYPES = new Set<FieldType>(['notes'])

export const triggerNodeTypeOptions: NodeOption[] = [
    {
        label: '当记录被创建时',
        value: 'CREATE_RECORD_TRIGGER',
        icon: 'PencilSimple',
        color: 'var(--color-gray-900)',
        describe: '当以上指定的其中一个字段更新时将触发流程，如未指定则表示任何字段更新时都会触发'
    },
    {
        label: '当记录满足指定条件时',
        value: 'MATCH_CONDITION_TRIGGER',
        icon: 'Workflow',
        color: 'var(--color-gray-900)',
        describe: '当以上指定的其中一个字段更新时将触发流程，如未指定则表示任何字段更新时都会触发'
    },
    {
        label: '当记录被更新时',
        value: 'UPDATE_RECORD_TRIGGER',
        icon: 'FlowArrow',
        color: 'var(--color-gray-900)',
        describe: '当以上指定的其中一个字段更新时将触发流程，如未指定则表示任何字段更新时都会触发'
    },
    {
        label: '定时触发',
        value: 'SCHEDULED_TRIGGER',
        icon: 'FlowArrow',
        color: 'var(--color-gray-900)',
        describe: '当以上指定的其中一个字段更新时将触发流程，如未指定则表示任何字段更新时都会触发'
    },
    // 子流程出发节点
    {
        label: '开始',
        value: 'ARG_TRIGGER',
        icon: 'Reorder',
        color: 'var(--color-gray-900)',
        describe: '循环节点使用时，会触发子流程'
    },
    // 审批触发节点
    {
        label: '提交审批申请',
        value: 'INITIATE_APPROVE_TRIGGER',
        icon: 'Approval',
        color: 'var(--color-gray-900)',
        describe: '当提交表单时，会触发审批申请'
    },
    // 动作流触发节点
    {
        label: '当点击时',
        value: 'CLICK_TRIGGER',
        icon: 'MouseClick',
        color: 'var(--color-gray-900)',
        describe: '当点击按钮时，会触发流程'
    }
]


export const actActionNodeTypeOptions: NodeOption[] = [
    {
        label: '创建单条记录',
        value: 'CREATE_SINGLE_RECORD_ACTION',
        icon: 'Add',
        color: 'var(--color-green-500)',
        describe: ''
    },
    {
        label: '更新记录',
        value: 'UPDATE_RECORD_ACTION',
        icon: 'ArrowCounterClockwise',
        color: 'var(--color-green-500)',
        describe: ''
    },
    {
        label: '查询单条数据',
        value: 'FIND_SINGLE_RECORD_ACTION',
        icon: 'SearchLine',
        color: 'var(--color-green-500)',
        describe: ''
    }
]

export const autoActionNodeTypeOptions: NodeOption[] = [
    {
        label: '创建单条记录',
        value: 'CREATE_SINGLE_RECORD_ACTION',
        icon: 'Add',
        color: 'var(--color-green-500)',
        describe: ''
    },
    {
        label: '创建多条记录',
        value: 'CREATE_RECORD_ACTION',
        icon: 'AddList',
        color: 'var(--color-green-500)',
        describe: ''
    },
    {
        label: '更新记录',
        value: 'UPDATE_RECORD_ACTION',
        icon: 'ArrowCounterClockwise',
        color: 'var(--color-green-500)',
        describe: ''
    },
    {
        label: '查询单条数据',
        value: 'FIND_SINGLE_RECORD_ACTION',
        icon: 'SearchLine',
        color: 'var(--color-green-500)',
        describe: ''
    },
    {
        label: '查询多条数据',
        value: 'FIND_RECORD_ACTION',
        icon: 'QueryData',
        color: 'var(--color-green-500)',
        describe: ''
    }
]

export const actionNodeTypeOptions: NodeOption[] = [...autoActionNodeTypeOptions]

export const operatorNodeTypeOptions: NodeOption[] = [
    {
        label: '打开页面',
        value: 'OPEN_PAGE',
        icon: 'BlockLinkToPage',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '打开链接',
        value: 'OPEN_LINK',
        icon: 'Link',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '打开表单页',
        value: 'OPEN_FORM_PAGE',
        icon: 'BlockLinkToPage',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '打开详情页',
        value: 'OPEN_RECORD_PAGE',
        icon: 'BlockLinkToPage',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '打开编辑页',
        value: 'OPEN_RECORD_EDIT_PAGE',
        icon: 'BlockLinkToPage',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '返回上一步',
        value: 'GO_BACK',
        icon: 'Undo',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '显示提示',
        value: 'NOTIFICATION',
        icon: 'Bell',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '拷贝',
        value: 'COPY',
        icon: 'Duplicate',
        color: '#4E5BA6',
        describe: ''
    },
    // {
    //     label: '分享到微信',
    //     value: 'SHARE_TO_WECHAT',
    //     icon: 'BrandLogoqiyeweixin',
    //     color: '#4E5BA6',
    //     describe: ''
    // },
    {
        label: '删除当前记录',
        value: 'DELETE_RECORD',
        icon: 'ReduceCircle',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '下载',
        value: 'DOWNLOAD_FILE',
        icon: 'Import',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '拨打电话',
        value: 'CALL',
        icon: 'PhoneIconLibrary',
        color: '#4E5BA6',
        describe: ''
    },
    {
        label: '刷新页面数据',
        value: 'REFRESH_PAGE',
        icon: 'ArrowCounterClockwise',
        color: '#4E5BA6',
        describe: ''
    }
]

export const noticeNodeTypeOptions: NodeOption[] = [
    {
        label: '发送邮件',
        value: 'SEND_EMAIL_ACTION',
        icon: 'PropertyEmail',
        color: 'var(--color-yellow-500)',
        describe: ''
    },
    {
        label: '发送站内信',
        value: 'STATION_MESSAGE_ACTION',
        icon: 'StationMessage',
        color: 'var(--color-yellow-500)',
        describe: ''
    }
]

export const conditionNodeTypeOptions: NodeOption[] = [
    {
        label: '条件分支',
        value: 'CONDITION',
        icon: 'ActiveNodeIF',
        color: 'var(--color-blue-500)',
        describe: ''
    }
]

export const loopNodeTypeOptions:NodeOption[] = [
    {
        label: '循环',
        value: 'LOOP_ACTION',
        icon: 'Repeat',
        color: 'var(--color-blue-500)',
        describe: ''
    }
]

export const handleSubProcessTypeOptions:NodeOption[] = [
    {
        label: '执行子流程',
        value: 'HANDLE_SUB_PROCESS',
        icon: 'Reorder',
        color: 'var(--color-blue-500)',
        describe: ''
    }
]

// export const flowActionNodeTypeOptions: NodeOption[] = [
//     {
//         label: '发起审批流程',
//         value: 'INITIATE_APPROVE_ACTION',
//         icon: 'Approval',
//         color: 'var(--color-blue-500)',
//         describe: ''
//     }
// ]

export const approveNodeTypeOptions: NodeOption[] = [
    {
        label: '审批',
        value: 'APPROVE_ACTION',
        icon: 'Approval',
        color: 'var(--color-blue-500)',
        describe: '当提交表单时，会触发审批申请。如果多个表单使用同一个数据表，则提交上述任一表单，都会触发审批流程'
    },
    {
        label: '抄送',
        value: 'CARBON_COPY_ACTION',
        icon: 'PencilSimple',
        color: 'var(--color-blue-500)',
        describe: ''
    }
]

export const DINGTALK_TYPE_OPTIONS: NodeOption[] = [
    {
        label: '自定义群机器人',
        value: 'DINGTALK_ROBOT_ACTION',
        icon: 'BrandLogoDingding',
        describe: ''
    }
    // {
    //     label: '企业内部应用机器人',
    //     value: 'companyRobot',
    //     icon: 'BrandLogoDingding',
    //     disabled: true
    // },
    // {
    //     label: '工作通知',
    //     value: 'notions',
    //     icon: 'BrandLogoDingding',
    //     disabled: true
    // }
]
export const ICLICK_TYPE_OPTIONS: NodeOption[] = [
    {
        label: '添加联系人',
        value: 'I_CLICK_CONTACT_ACTION',
        icon: 'BrandLogoIClick',
        color: 'var(--color-orange-500)',
        describe: ''
    }
]

export const WX_ROBOT_TYPE_OPTIONS: NodeOption[] = [
    {
        label: '企业微信机器人',
        value: 'WX_ROBOT_ACTION',
        icon: 'BrandLogoqiyeweixin',
        color: 'var(--color-orange-500)',
        describe: ''
    }
]

export const ALIYUN_MESSAGE_TYPE_OPTIONS: NodeOption[] = [
    {
        label: '阿里云短信',
        value: 'ALIYUN_MESSAGE_ACTION',
        icon: 'aliyun',
        color: 'var(--color-orange-500)',
        describe: ''
    }
]

export const WECHAT_TYPE_OPTIONS: NodeOption[] = [
    {
        label: '微信支付',
        value: 'WECHAT_PAY',
        icon: 'WechatPay',
        color: 'var(--color-green-500)',
        describe: ''

    },
    {
        label: '微信模板消息',
        value: 'WECHAT_TEMPLATE_MSG_ACTION',
        icon: 'WechatPay',
        color: 'var(--color-green-500)',
        describe: ''
    }
]

export const STRIPE_OPTIONS: NodeOption[] = [
    {
        label: 'Stripe',
        value: 'STRIPE',
        icon: 'Stripe',
        color: '',
        describe: ''
    }
]

export const otherPlatformsTypeOptions: OtherPlatformOption[] = [
    {
        label: '企业微信',
        value: 'WECHAT_COMPANY',
        icon: 'BrandLogoqiyeweixin',
        color: '',
        describe: '',
        children: WX_ROBOT_TYPE_OPTIONS
    },
    {
        label: '钉钉',
        value: 'DINGTALK',
        icon: 'BrandLogoDingding',
        color: '',
        describe: '',
        children: DINGTALK_TYPE_OPTIONS
    },
    {
        label: 'iClick',
        value: 'I_CLICK',
        icon: 'BrandLogoIClick',
        color: 'var(--color-orange-500)',
        describe: '',
        children: ICLICK_TYPE_OPTIONS
    },
    {
        label: '阿里云',
        value: 'ALIYUN',
        icon: 'aliyun',
        color: 'var(--color-orange-500)',
        describe: '',
        children: ALIYUN_MESSAGE_TYPE_OPTIONS
    },
    // {
    //     label: '微信支付',
    //     value: 'WECHAT_PAY',
    //     icon: 'WechatPay',
    //     color: 'var(--color-green-500)',
    //     describe: ''
    // }
    {
        label: '微信',
        value: 'WECHAT',
        icon: 'WeChatLogo',
        color: 'var(--color-green-500)',
        describe: '',
        children: WECHAT_TYPE_OPTIONS
    },
    {
        label: 'Stripe',
        value: 'STRIPE',
        icon: 'Stripe',
        color: '',
        describe: '',
        children: STRIPE_OPTIONS
    }
]

export const endNodeTypeOptions: NodeOption[] = [
    {
        label: '结束节点',
        value: 'END',
        icon: 'TickCircle',
        color: '#3f65ed',
        describe: ''
    }
]

export const otherPlatformsNodeTypeOptions: NodeOption[] = [
    ...DINGTALK_TYPE_OPTIONS,
    ...ICLICK_TYPE_OPTIONS,
    ...WX_ROBOT_TYPE_OPTIONS,
    ...ALIYUN_MESSAGE_TYPE_OPTIONS,
    ...WECHAT_TYPE_OPTIONS,
    ...STRIPE_OPTIONS
]

// 合并 options
export const nodeTypeOptions = [
    ...triggerNodeTypeOptions,
    ...actionNodeTypeOptions,
    ...noticeNodeTypeOptions,
    ...conditionNodeTypeOptions,
    ...loopNodeTypeOptions,
    // ...flowActionNodeTypeOptions,
    ...endNodeTypeOptions,
    ...approveNodeTypeOptions,
    ...operatorNodeTypeOptions,
    ...otherPlatformsNodeTypeOptions,
    ...handleSubProcessTypeOptions
]

// 打开记录详情、记录详情编辑页时选择记录的类型
export const selectedRecordTypeOptions = [
    { label: '当前记录', value: OpenDetailPageSelectedRecordType.CURRENT_RECORD },
    { label: '从上游节点选择', value: OpenDetailPageSelectedRecordType.UPSTREAM_RECORD },
    { label: '符合指定条件的记录', value: OpenDetailPageSelectedRecordType.MATCHED_RECORD }
]

export const fromUpstreamOptions = [
    {
        label: '当前记录',
        value: FlowSelectRecordType.CURRENT_RECORD
    },
    {
        label: '从上游节点选择',
        value: FlowSelectRecordType.UPSTREAM
    }
]

export const triggerNodeInfoMap = Object.fromEntries(triggerNodeTypeOptions.map(item => [item.value, item]))

export const actionNodeInfoMap = Object.fromEntries(
    [
        ...actionNodeTypeOptions,
        ...noticeNodeTypeOptions,
        // ...flowActionNodeTypeOptions,
        ...otherPlatformsNodeTypeOptions,
        ...approveNodeTypeOptions,
        ...operatorNodeTypeOptions,
        ...loopNodeTypeOptions,
        ...handleSubProcessTypeOptions
    ].map(item => [item.value, item])
)

export const actionDefaultConfigMap: Record<ActionNodeType, Partial<ActionNodeConfig>> = {
    CREATE_SINGLE_RECORD_ACTION: {},
    CREATE_RECORD_ACTION: {},
    UPDATE_RECORD_ACTION: {
        selectType: FlowSelectRecordType.UPSTREAM
    },
    FIND_SINGLE_RECORD_ACTION: {},
    FIND_RECORD_ACTION: {},
    WX_ROBOT_ACTION: {},
    DINGTALK_ROBOT_ACTION: {},
    APPROVE_ACTION: {
        type: 'MANUALLY',
        approverSettingType: 'SELECTED',
        approvers: [],
        signType: 'PARALLEL_SIGN',
        fieldPermissions: undefined,
        emptyApprover: {
            type: 'AGREE',
            approvers: []
        },
        requireComment: true
    },
    INITIATE_APPROVE_ACTION: {},
    SEND_EMAIL_ACTION: {},
    STATION_MESSAGE_ACTION: {},
    CARBON_COPY_ACTION: {},
    // 动作流相关
    OPEN_PAGE: {
        pageOpenType: 'modal'
    },
    OPEN_LINK: {},
    OPEN_FORM_PAGE: {
        pageOpenType: 'modal'
    },
    OPEN_RECORD_PAGE: {
        pageOpenType: 'modal'
    },
    OPEN_RECORD_EDIT_PAGE: {
        pageOpenType: 'modal'
    },
    GO_BACK: {},
    NOTIFICATION: {},
    COPY: {},
    CALL: {},
    DELETE_RECORD: {},
    SHARE_TO_WECHAT: {
        title: '',
        desc: '',
        imgUrl: ''
    },
    DOWNLOAD_FILE: {},
    I_CLICK_CONTACT_ACTION: {},
    ALIYUN_MESSAGE_ACTION: {},
    WECHAT_PAY: {
        operationType: 'PAY',
        payConfig: {
            descFieldId: '',
            autoRedirectAfterPayment: true,
            /** 订单金额 */
            amountFieldId: '',
            succeedFieldSettings: [],
            failedFieldSettings: []
        }
    },
    WECHAT_TEMPLATE_MSG_ACTION: {},
    STRIPE: {
        operationType: 'PAY',
        payConfig: {
            descFieldId: '',
            autoRedirectAfterPayment: true,
            /** 订单金额 */
            amountFieldId: '',
            succeedFieldSettings: [],
            failedFieldSettings: []
        }
    },
    REFRESH_PAGE: {},
    LOOP_ACTION: {
        loopType: LoopTypeEnum.BASE_ON_TIMES,
        timesConfig: {
            start: {
                type: VariableType.VALUE,
                valueVariable: {
                    type: 'number',
                    value: 1
                }
            },
            end: {
                type: VariableType.VALUE,
                valueVariable: {
                    type: 'number',
                    value: 10
                }
            },
            step: {
                type: VariableType.VALUE,
                valueVariable: {
                    type: 'number',
                    value: 1
                }
            }
        },
        loopErrorHandler: LoopErrorHandlerEnum.CONTINUE
    },
    HANDLE_SUB_PROCESS: {}
}

export const otherPlatformsNodeInfoMap = Object.fromEntries(otherPlatformsNodeTypeOptions.map(item => [item.value, item]))

export const conditionNodeInfoMap = Object.fromEntries(conditionNodeTypeOptions.map(item => [item.value, item]))

export const endNodeInfoMap = Object.fromEntries(endNodeTypeOptions.map(item => [item.value, item]))

export const nodeTypeMap = Object.fromEntries(nodeTypeOptions.map(item => [item.value, item]))

export const getNodeInfo = (nodeType: NodeTypes) => {
    return nodeTypeMap[nodeType]
}

// export const getNodeInfoOptions = (type: FlowNode['type']) => {
//     switch (type) {
//         case 'TRIGGER': {
//             return triggerNodeTypeOptions
//         }
//         case 'ACTION': {
//             return autoActionNodeTypeOptions
//         }
//         case 'CONDITION': {
//             return conditionNodeTypeOptions
//         }
//         case 'END': {
//             return endNodeTypeOptions
//         }
//         default: {
//             return []
//         }
//     }
// }

export const automationNodeCreatorCards = [
    { groupId: 'ACTION', title: '数据处理', options: autoActionNodeTypeOptions },
    { groupId: 'NOTICE', title: '通知', options: noticeNodeTypeOptions },
    { groupId: 'CONDITION', title: '流程', options: [...conditionNodeTypeOptions, ...loopNodeTypeOptions /* , ...flowActionNodeTypeOptions */] }
    // { groupId: 'END', title: '动作', options: endNodeTypeOptions }
]

export const subProcessNodeCreatorCards = [
    { groupId: 'ACTION', title: '数据处理', options: autoActionNodeTypeOptions },
    { groupId: 'NOTICE', title: '通知', options: noticeNodeTypeOptions },
    { groupId: 'CONDITION', title: '流程', options: conditionNodeTypeOptions }
    // { groupId: 'END', title: '动作', options: endNodeTypeOptions }
]

export const approveNodeCreatorCards = [
    { groupId: 'CONDITION', title: '流程', options: [...conditionNodeTypeOptions, ...approveNodeTypeOptions] }
]

// const actionFlowOmittedNodeTypes = new Set(['FIND_RECORD_ACTION', 'CREATE_RECORD_ACTION'])

export const actionNodeCreatorCards = [
    { groupId: 'ACTION', title: '数据', options: actActionNodeTypeOptions },
    { groupId: 'NOTICE', title: '通知', options: noticeNodeTypeOptions },
    { groupId: 'CONDITION', title: '流程', options: [...conditionNodeTypeOptions, ...handleSubProcessTypeOptions] },
    { groupId: 'OPERATOR', title: '操作', options: operatorNodeTypeOptions }
]

export const extraNodeCreatorCard = { groupId: 'OTHER_PLATFORM', title: '应用集成', options: otherPlatformsTypeOptions }

export const nodeTypeIconMap: Record<NodeTypes, string> = {
    CREATE_RECORD_TRIGGER: 'Wrench',
    UPDATE_RECORD_TRIGGER: 'Recycle',
    INITIATE_APPROVE_TRIGGER: 'Submit',
    MATCH_CONDITION_TRIGGER: 'Workflow',
    SCHEDULED_TRIGGER: 'AddList',
    CLICK_TRIGGER: 'MouseClick',
    MANUALLY_TRIGGER: 'AddList',
    ARG_TRIGGER: 'Reorder',
    CREATE_SINGLE_RECORD_ACTION: 'Add',
    CREATE_RECORD_ACTION: 'AddList',
    UPDATE_RECORD_ACTION: 'ArrowCounterClockwise',
    FIND_SINGLE_RECORD_ACTION: 'SearchLine',
    FIND_RECORD_ACTION: 'QueryData',
    CONDITION: 'ActiveNodeIF',
    END: 'Flag',
    OPEN_LINK: 'Link',
    OPEN_PAGE: 'BlockLinkToPage',
    OPEN_FORM_PAGE: 'BlockLinkToPage',
    OPEN_RECORD_PAGE: 'BlockLinkToPage',
    OPEN_RECORD_EDIT_PAGE: 'BlockLinkToPage',
    GO_BACK: 'Undo',
    NOTIFICATION: 'Bell',
    COPY: 'Duplicate',
    DELETE_RECORD: 'ReduceCircle',
    SHARE_TO_WECHAT: 'BrandLogoqiyeweixin',
    DOWNLOAD_FILE: 'Import',
    WX_ROBOT_ACTION: 'BrandLogoqiyeweixin',
    DINGTALK_ROBOT_ACTION: 'BrandLogoDingding',
    APPROVE_ACTION: 'Approval',
    CARBON_COPY_ACTION: 'PencilSimple',
    SEND_EMAIL_ACTION: 'PropertyEmail',
    STATION_MESSAGE_ACTION: 'StationMessage',
    LOOP_ACTION: 'Repeat',
    // 自动化发起审批动作节点
    INITIATE_APPROVE_ACTION: 'Approval',
    I_CLICK_CONTACT_ACTION: 'BrandLogoIClick',
    ALIYUN_MESSAGE_ACTION: 'aliyun',
    CALL: 'PhoneIconLibrary',
    WECHAT_PAY: 'WechatPay',
    WECHAT_TEMPLATE_MSG_ACTION: 'WechatPay',
    STRIPE: 'Stripe',
    REFRESH_PAGE: 'ArrowCounterClockwise',
    HANDLE_SUB_PROCESS: 'Reorder'
}
// export const defaultFiledValue:Record<FieldType>

export const singleRecordNodeType: Set<NodeTypes> = new Set<NodeTypes>([
    'CREATE_RECORD_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'CLICK_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION',
    'FIND_SINGLE_RECORD_ACTION',
    'WECHAT_PAY',
    'ARG_TRIGGER'
])

export const triggerNodeType: Set<NodeTypes> = new Set<NodeTypes>([
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'CREATE_RECORD_TRIGGER',
    'CLICK_TRIGGER',
    'SCHEDULED_TRIGGER',
    'INITIATE_APPROVE_TRIGGER'
])
export const multiRecordNodeType: Set<NodeTypes> = new Set<NodeTypes>(['FIND_RECORD_ACTION', 'CREATE_RECORD_ACTION'])

export const otherPlatformNodeTypeList: NodeTypes[] = [
    'WX_ROBOT_ACTION',
    'DINGTALK_ROBOT_ACTION',
    'I_CLICK_CONTACT_ACTION',
    'WX_ROBOT_ACTION',
    'ALIYUN_MESSAGE_ACTION'
]

export const triggerDataSourceNodeTypeList = [
    'CREATE_RECORD_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'SCHEDULED_TRIGGER',
    'MANUALLY_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION'
]

export const copyTypeOptions = [
    { label: '自定义', value: 'CUSTOM' }
    // { label: '当前记录链接', value: 'CURRENT_RECORD' },
    // { label: '当前页面链接', value: 'CURRENT_PAGE' }
]

export const flowSelectSourceTypeMap: { label: string; value: FlowSelectSourceType }[] = [
    { label: '数据表', value: FlowSelectSourceType.DATASOURCE },
    { label: '上游节点', value: FlowSelectSourceType.UPSTREAM }
]

export const notificationStatusOptions = [
    { label: '中立', value: 'INFO' },
    { label: '成功', value: 'SUCCESS' },
    { label: '失败', value: 'ERROR' }
]

export const canAsUpstreamNodeTypes = [
    'CREATE_RECORD_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION',
    'CLICK_TRIGGER',
    'CREATE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION',
    'FIND_SINGLE_RECORD_ACTION',
    'FIND_RECORD_ACTION'
]

export const canAsUpstreamSingleRecordNodeTypes = new Set([
    'CREATE_RECORD_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION',
    'CLICK_TRIGGER',
    'FIND_SINGLE_RECORD_ACTION'
])

export const canAsUpstreamSingleRecordNodeTypesInLoopParams = new Set([
    'CREATE_RECORD_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION',
    'CLICK_TRIGGER',
    'FIND_SINGLE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION'
])

export const canAsUpstreamMultipleRecordNodeTypes = new Set(['CREATE_RECORD_ACTION', 'UPDATE_RECORD_ACTION', 'FIND_RECORD_ACTION'])

export const canAsOpenRecordPageUpstreamRecordNodeTypes = new Set([
    'FIND_SINGLE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION',
    'CLICK_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION'
])

// export const actionFlowNodeTypes = [
//     'OPEN_PAGE',
//     'OPEN_LINK',
//     'OPEN_FORM_PAGE',
//     'OPEN_RECORD_PAGE',
//     'OPEN_RECORD_EDIT_PAGE',
//     'GO_BACK',
//     'NOTIFICATION',
//     'COPY',
//     'DELETE_RECORD'
// ]

export const generatedDataNodeTypes = new Set([
    'CREATE_RECORD_TRIGGER',
    'UPDATE_RECORD_TRIGGER',
    'MATCH_CONDITION_TRIGGER',
    'CREATE_SINGLE_RECORD_ACTION',
    'FIND_SINGLE_RECORD_ACTION',
    'CLICK_TRIGGER'
])

export const canProvidedRecordInFlowNodeTypes: NodeTypes[] = [
    'CLICK_TRIGGER',
    'CREATE_RECORD_ACTION',
    'UPDATE_RECORD_ACTION',
    'CREATE_SINGLE_RECORD_ACTION',
    'FIND_SINGLE_RECORD_ACTION'
]

export const loopTypeMap = [
    {
        label: '按指定循环次数',
        value: LoopTypeEnum.BASE_ON_TIMES
    },
    {
        label: '按查询到的记录循环',
        value: LoopTypeEnum.BASE_ON_RECORDS
    }
]
