import type { DataSourceAbstract, ViewOptions } from '@lighthouse/core'
import { Group4ByecodeUi } from '@lighthouse/shared'
import { clone } from 'rambda'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ViewFieldSetting } from './ViewFieldSetting'

interface ViewFieldProps {
    dataSource: DataSourceAbstract
}

export const ViewField: React.FC<ViewFieldProps> = ({ dataSource }) => {
    const { control, watch } = useFormContext<ViewOptions>()
    const {
        schema,
        viewOptions: { tableProps },
        type
    } = dataSource

    const viewType = watch('viewType')

    return (
        <Group4ByecodeUi label="字段设置">
            <Controller
                name="viewFieldSettings"
                control={control}
                render={({ field }) => (
                    <ViewFieldSetting
                        type={type}
                        schema={schema}
                        tableProps={tableProps}
                        value={clone(field.value)}
                        viewType={viewType}
                        onChange={field.onChange}
                    />
                )}
            />
        </Group4ByecodeUi>
    )
}
