import { Button, IconFont, Input, pointer, Select } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const LinkForm = styled.form``

export const InviteBox = styled.div`
    position: relative;
`

export const InviteItem = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 24px;
    border-radius: 6px;
    border: 1px solid #dfdfdd;
    overflow: hidden;
`

export const InviteAvatar = styled.div`
    width: 38px;
    height: 36px;
    border-right: 1px solid #dfdfdd;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const UserIconBox = styled.div`
    width: 16px;
    height: 16px;
    line-height: 15px;
    text-align: center;
    border-radius: 6px;
    box-sizing: content-box;
    border: 1px solid var(--color-black);
`

export const UserIcon = styled(IconFont)`
    font-size: 12px;
`

export const UserImg = styled.div<{ background?: string }>`
    width: 16px;
    height: 16px;
    ${props =>
        props.background &&
        css`
            background: url('${props.background}');
        `};
    background-size: contain;
    background-position: center;
`

export const InviteForm = styled.div`
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    background: var(--color-gray-50);

`

export const InvitePhone = styled(Input)`
    border-radius: 0;
    border: none;
    flex: 1;
    input {
        padding: 2px 8px;
        margin: 0;
    }
    input:focus {
        background: var(--color-gray-50);
    }
`

export const InviteRole = styled(Select)`
    border: none;
    width: auto;
`

export const InviteClose = styled.div`
    position: absolute;
    top: 0;
    right: -20px;
    width: 16px;
    height: 36px;
    line-height: 36px;
    color: var(--color-gray-500);

    ${pointer}
`

export const InviteIcon = styled(IconFont)``

export const InviteFooter = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`

export const HideSubmit = styled.input`
    display: none;
`

export const InviterAdder = styled(Button)`
    width: 76px;
    white-space: nowrap;
    height: 36px;
    font-size: 14px;
    justify-content: center;
    margin-right: 8px;
`

export const InviteBtn = styled(Button)`
    flex: 1;
    height: 36px;
    justify-content: center;
    font-size: 14px;
`

export const UserItemAbbrText = styled.div<{ backgroundColor?: string }>`
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 5px;
    color: var(--color-black);
    font-size: 12px;

    ${({ backgroundColor }) =>
        backgroundColor
            ? css`
                  background: backgroundColor;
              `
            : css`
                  background: var(--color-main-light);
              `}
`
