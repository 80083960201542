import { hideScrollBar } from '@byecode/ui/styles/mixins'
import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import React, { forwardRef, useMemo, useState } from 'react'
import styled from 'styled-components'

import type { PopoverDropdownWithCloseProps } from '../../PopoverDropdownWithClose'
import { PopoverDropdownWithClose } from '../../PopoverDropdownWithClose'
import { ColorLibraryEditor } from '../ColorLibraryEditor'
import type { FillPickerProps } from '../FillPicker'
import { FillPicker } from '../FillPicker'
import { useFillPickerContext } from '../Provider'

const StyledDropdown = styled(PopoverDropdownWithClose)`
    max-height: 600px;
    display: flex;
    flex-direction: column;
`

export interface FillPickerPopoverDropdownProps extends Omit<PopoverDropdownWithCloseProps, 'onChange' | 'defaultValue'>, FillPickerProps {
    popoverDropdownProps?: PopoverDropdownWithCloseProps

    onCreateColor?: (value: ApplicationSettingThemeColor) => void
    onUpdateColor?: (value: ApplicationSettingThemeColor) => void
}

export const FillPickerPopoverDropdown = forwardRef<HTMLDivElement, FillPickerPopoverDropdownProps>(
    ({ popoverDropdownProps, onCreateColor, onUpdateColor, ...rest }, ref) => {
        const [contentState, setContentState] = useState<null | {
            editId?: string
            isEditMode?: boolean
            isCreateMode?: boolean
        }>(null)

        const { palettes } = useFillPickerContext()

        const title = useMemo(() => {
            if (contentState?.isCreateMode) {
                return '创建颜色'
            }

            if (contentState?.isEditMode) {
                return '编辑颜色'
            }

            return popoverDropdownProps?.title ?? '填充'
        }, [contentState?.isCreateMode, contentState?.isEditMode, popoverDropdownProps?.title])

        return (
            <StyledDropdown
                {...popoverDropdownProps}
                title={title}
                onGoBack={
                    contentState
                        ? () => {
                              setContentState(null)
                          }
                        : undefined
                }
                ref={ref}
            >
                {useMemo(() => {
                    if (contentState) {
                        const value = contentState.isEditMode ? palettes.find(item => item.id === contentState.editId) : undefined

                        return (
                            <ColorLibraryEditor
                                isCreateMode={contentState.isCreateMode}
                                onCreate={v => {
                                    onCreateColor?.(v)
                                    setContentState(null)
                                }}
                                value={value}
                                onChange={v => contentState.editId && onUpdateColor?.({ ...v, id: contentState.editId })}
                            />
                        )
                    }

                    return (
                        <FillPicker
                            onClickCreateColor={() => {
                                setContentState({ isCreateMode: true })
                            }}
                            onClickEditColor={(id: string) => {
                                setContentState({ isEditMode: true, editId: id })
                            }}
                            {...rest}
                        />
                    )
                }, [contentState, onCreateColor, onUpdateColor, palettes, rest])}
            </StyledDropdown>
        )
    }
)
