import { getAssetUrl } from '@lighthouse/assets'
import { useCustomViewBlockContext, VideoBlock } from '@lighthouse/block'
import type { VideoBlockAbstract } from '@lighthouse/core'
import { getFileTypeByUrl, getImageFullUrlInApplication, getVariableToFieldValue, isTextValue } from '@lighthouse/shared'
import * as React from 'react'
import { useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useRecord } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'

interface VideoBlockControllerProps {
    blockData: VideoBlockAbstract
}

const VideoBlockController: React.FunctionComponent<VideoBlockControllerProps> = ({ blockData }) => {
    const {
        config: { videoSource, cover }
    } = blockData
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const { curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')
    const userRecord = useUserRecord()

    const { record, pointer } = useCustomViewBlockContext()

    const viewDataSource = useDataSource(appId, envId, pointer)

    const value = useMemo(() => {
        const DEFAULT_SOURCE = getAssetUrl('blocks', 'defaultVideo.mp4')
        if (!videoSource) {
            return DEFAULT_SOURCE
        }
        const cellValue = getVariableToFieldValue({
            idVariable: videoSource,
            currentRecord: {
                datasource: curr.datasource,
                record: currentRecord
            },
            prevRecord: {
                datasource: prev.datasource,
                record: prevRecord
            },
            viewRecord: {
                record,
                datasource: viewDataSource
            },
            userRecord
        })
        if (!cellValue) {
            return DEFAULT_SOURCE
        }
        if (cellValue.type === 'video' || cellValue.type === 'file') {
            const videoValue =
                cellValue.type === 'file' ? cellValue?.value?.find(v => getFileTypeByUrl(v) === 'video') : cellValue?.value?.[0]
            return cellValue.value ? getImageFullUrlInApplication(appId, videoValue) || DEFAULT_SOURCE : DEFAULT_SOURCE
        }
        const otherValue = cellValue?.value
        if (isTextValue(otherValue)) {
            return otherValue ? otherValue || DEFAULT_SOURCE : DEFAULT_SOURCE
        }
        return DEFAULT_SOURCE
    }, [appId, curr.datasource, currentRecord, prev.datasource, prevRecord, record, userRecord, videoSource, viewDataSource])

    const coverImage = useMemo(() => {
        if (!cover) {
            return ''
        }
        const cellValue = getVariableToFieldValue({
            idVariable: cover,
            currentRecord: {
                datasource: curr.datasource,
                record: currentRecord
            },
            prevRecord: {
                datasource: prev.datasource,
                record: prevRecord
            },
            viewRecord: {
                record,
                datasource: viewDataSource
            },
            userRecord
        })
        if (!cellValue) {
            return ''
        }
        const fileUrl = cellValue.type === 'file' ? cellValue?.value?.[0] : ''
        return getFileTypeByUrl(fileUrl) === 'image' ? fileUrl : ''
    }, [cover, curr.datasource, currentRecord, prev.datasource, prevRecord, record, userRecord, viewDataSource])

    return <VideoBlock blockData={blockData} value={value} cover={coverImage} />
}

export default VideoBlockController
