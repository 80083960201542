import { Box, Group, SegmentedControl } from '@byecode/ui'
import type { ButtonBlockAbstract, PageAbstract } from '@lighthouse/core'
import { BasicListItem, FindUseLocationType } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useFindUse } from '@/hooks/useFindUse'

import FormSegmentedControl from '../Common/FormSegmentedControl'
import { VisibilityFilter } from '../Common/VisibilityFilter'
import { BUTTON_TABS, ButtonTabEnum, POSITION_ALIGN_CONTROL } from '../constants'
import { ButtonSettingList } from './ButtonSettingList'
import { BUTTON_RADIUS_OPTIONS, BUTTON_SHAPE_OPTIONS, BUTTON_SIZE_OPTIONS } from './constant'

// import { getSelectData } from './help'

interface ButtonSettingsProps {
    allPages: PageAbstract[]
    hideConfigs?: {
        fillWay?: boolean
    }
}

const SCxContainer = styled.div`
    /* padding: 0 16px; */
    height: 100%;
`

const SCxScrollContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`

export const ButtonSettings: React.FC<ButtonSettingsProps> = ({ allPages, hideConfigs }) => {
    const { control, watch, setValue, reset } = useFormContext<ButtonBlockAbstract['config']>()
    const [currentTab, setCurrentTab] = useState<string>(ButtonTabEnum.normal)
    const [shape] = watch(['shape'])

    return (
        <Box style={{ height: '100%' }}>
            <BasicListItem grow>
                <SegmentedControl disabled={false} data={BUTTON_TABS} fullWidth size="lg" value={currentTab} onChange={setCurrentTab} />
            </BasicListItem>
            <SCxContainer key={ButtonTabEnum.normal} hidden={currentTab !== ButtonTabEnum.normal}>
                <Group
                    styles={{
                        root: {
                            padding: '0 0 12px 0'
                        }
                    }}
                    label="按钮"
                >
                    <SCxScrollContainer>
                        <ButtonSettingList allPages={allPages} hideConfigs={hideConfigs} listKeyName='group' />
                    </SCxScrollContainer>
                </Group>
                <Divider color="var(--color-gray-200)" />
                <VisibilityFilter />
            </SCxContainer>
            <SCxContainer key={ButtonTabEnum.style} hidden={currentTab !== ButtonTabEnum.style}>
                <Group
                    styles={{
                        root: {
                            padding: 0
                        }
                    }}
                    label="按钮"
                >
                    <Controller
                        name="shape"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="风格"
                                size="md"
                                compact
                                data={BUTTON_SHAPE_OPTIONS}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />

                    {shape === 'rectangle' && (
                        <Controller
                            name="radius"
                            control={control}
                            render={({ field }) => (
                                <FormSegmentedControl
                                    label="圆角"
                                    size="md"
                                    compact
                                    data={BUTTON_RADIUS_OPTIONS}
                                    value={field.value}
                                    onChange={v => field.onChange(v)}
                                />
                            )}
                        />
                    )}

                    <Controller
                        name="size"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="尺寸"
                                size="md"
                                compact
                                data={BUTTON_SIZE_OPTIONS}
                                value={field.value}
                                onChange={v => field.onChange(v)}
                            />
                        )}
                    />

                    <Controller
                        name="align"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="对齐"
                                size="md"
                                compact
                                data={POSITION_ALIGN_CONTROL}
                                value={field.value}
                                onChange={v => field.onChange(v)}
                            />
                        )}
                    />
                </Group>
            </SCxContainer>
        </Box>
    )
}
