import { IconFont, Menu, MenuItem } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import { APPLICATION_ENV_PROD, ENV_ICON, ENV_PROD_ICON, getEnvColor, getEnvIcon } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { EnvTag } from './EnvTag'

const SCxIcon = styled(IconFont)``

const SCxDot = styled(IconFont)<{ open?: boolean }>`
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 10px;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
`

const SCxItem = styled.div<{ isActive?: boolean; isProd?: boolean }>`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }

    &:hover ${SCxDot} {
        visibility: visible;
    }

    ${({ isProd }) =>
        !isProd &&
        css`
            &:hover ${SCxIcon} {
                display: none;
            }
        `}
`

const SCxLabel = styled.div`
    font-size: var(--font-size-normal);
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface EnvItemProps {
    data: Environment
    isActive?: boolean
    disableDelete?: boolean
    onClick?: () => void
    onMenuClose?: () => void
    onEdit?: (val: Environment) => void
    onDelete?: (id: string) => void
}

export const EnvItem: React.FC<EnvItemProps> = ({ data, isActive, disableDelete, onMenuClose, onClick, onEdit, onDelete }) => {
    const { envId } = data
    const [settingOpen, setSettingOpen] = useState(false)
    const isProd = envId === APPLICATION_ENV_PROD
    const handleOptions = useMemo(
        () => [
            {
                label: '编辑环境',
                icon: 'edit',
                onItemClick: (e: React.MouseEvent) => {
                    e.stopPropagation()
                    onEdit?.(data)
                    onMenuClose?.()
                    setSettingOpen(false)
                }
            },
            {
                label: '删除环境',
                icon: 'Trash',
                disable: disableDelete,
                onItemClick: (e: React.MouseEvent) => {
                    e.stopPropagation()
                    if (disableDelete) {
                        return
                    }
                    onDelete?.(envId)
                    onMenuClose?.()
                    setSettingOpen(false)
                }
            }
        ],
        [disableDelete, onEdit, data, onMenuClose, onDelete, envId]
    )

    const isTick = useMemo(() => {
        if (isActive && isProd) {
            return true
        }
        return isActive && !settingOpen
    }, [isActive, isProd, settingOpen])

    return (
        <SCxItem onClick={onClick} isActive={isActive} isProd={isProd}>
            <EnvTag data={data} />
            {isTick && <SCxIcon size={16} type="Tick" fill="var(--color-gray-900)" />}
            {!isProd && (
                <Menu opened={settingOpen} onChange={setSettingOpen} width={204} position="bottom-start" closeOnItemClick>
                    <Menu.Target>
                        <SCxDot
                            onClick={e => e.stopPropagation()}
                            type="DotsThree"
                            size={16}
                            fill="var(--color-gray-400)"
                            open={settingOpen}
                        />
                    </Menu.Target>
                    <Menu.Dropdown>
                        {handleOptions.map(opt => (
                            <MenuItem
                                key={opt.label}
                                onClick={opt.onItemClick}
                                closeMenuOnClick={false}
                                style={opt.disable ? { opacity: 0.6, cursor: 'not-allowed' } : {}}
                            >
                                {opt.label}
                            </MenuItem>
                        ))}
                    </Menu.Dropdown>
                </Menu>
            )}
        </SCxItem>
    )
}
