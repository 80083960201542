import { getBrowser } from '@lighthouse/tools'
import { useEffect } from 'react'
import type { Options } from 'react-hotkeys-hook'
import { useHotkeys } from 'react-hotkeys-hook'

import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'

import { pageUndoRedoController } from './controller'
import { usePageUndoRedoHelper } from './handler'

/**
 * 页面block及node操作的撤销重做
 *
 * @param {Options} options
 */
export const usePageUndoRedo = (options: Options, deps: React.DependencyList) => {
    const { rootPageId } = useRootPageContext()
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    useEffect(() => {
        return () => {
            pageUndoRedoController.clear()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)

    const helper = usePageUndoRedoHelper({ rootPageId, pageId, stackId })

    useHotkeys(
        'ctrl+z, ⌘+z, ctrl+shift+z, ⌘+shift+z, ctrl+y, ⌘+y',
        (_, hotkeysEvent) => {
            const isMac = getBrowser().getBrowserInfo().platform === 'Macintosh'
            if (isMac) {
                switch (hotkeysEvent.key) {
                    case '⌘+z': {
                        helper.undo()

                        break
                    }

                    case '⌘+shift+z':
                    case '⌘+y': {
                        helper.redo()
                        break
                    }

                    default: {
                        break
                    }
                }
            } else {
                switch (hotkeysEvent.key) {
                    case 'ctrl+z': {
                        helper.undo()

                        break
                    }

                    case 'ctrl+shift+z':
                    case 'ctrl+y': {
                        helper.redo()
                        break
                    }

                    default: {
                        break
                    }
                }
            }
        },
        options
    )
}
