import { Toast } from '@byecode/ui/components/Toast'
import { FieldBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { FieldADTValue, FieldCellValue, InputValueItem, ViewRecordOperateProtocol } from '@lighthouse/core'
import { type BlockAbstract, type FieldBlockAbstract, type FieldInputADTValue, PageType, RecordOpenType } from '@lighthouse/core'
import {
    aliyunVideoProtocolList,
    avatarMaxFileSize,
    fileMaxUploadSize,
    fileSuffixRegex,
    getEmptyFieldInputValue,
    getFieldInputError,
    getFieldInputRules,
    getFileNameByUrl,
    getFileSizeToMB,
    getFileTypeByFileName,
    getPrimaryDataSourceEnableFieldIds,
    isEmptyCellValue,
    pageStackPubSub,
    useAtomAction,
    useAtomData,
    useFormModuleContext
} from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import { clone, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import { updateCellAtom } from '@/atoms/dataSource/action'
import { openPageStackAtom } from '@/atoms/page/action'
import { pageAtomFamily, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId, useLanguage, usePreviewType } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { useParentRecord } from '@/hooks/useParentRecord'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

interface FieldBlockControllerProps {
    blockData: FieldBlockAbstract
    onBlockChange?: (values: AnyObject, origin: BlockAbstract) => Promise<void> | void
}

const FieldBlockController: React.FC<FieldBlockControllerProps> = ({ blockData: fieldBlockData }) => {
    const { config, id: blockId, title } = fieldBlockData
    const { inputType, fieldPointer = '', canEdit, required } = config
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)

    const { t } = useTranslation()
    const formModule = useFormModuleContext()
    const { pageId } = useCurrentPageContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const language = useLanguage()
    const { handleRenderTitle } = useRichTextToTitle()
    const previewType = usePreviewType()
    const { rootPageId } = useRootPageContext()
    const stackId = useCurrentStackIdContext()
    const currentPage = useAtomData(pageAtomFamily(pageId))
    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )
    const fieldBlockValueMap = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.formState, [])
    )

    const { onChange: formOnChange, defaultValue, isValid = true, errors, pointer, type } = formModule ?? {}
    const { initInputList, inputList } = defaultValue ?? {}

    const { record, dataSource: parentDataSource, initRecord } = useParentRecord()
    const formDataSource = useDataSource(appId, envId, pointer)

    const dataSource = formDataSource || parentDataSource

    const field = useMemo(() => dataSource?.schema[fieldPointer], [dataSource?.schema, fieldPointer])

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    const joinFieldCanEdit = useMemo(() => {
        if (currentPage?.type === PageType.edit || currentPage?.type === PageType.creator) {
            return primaryDataSourceFieldIds?.has(fieldPointer)
        }
        return true
    }, [currentPage?.type, fieldPointer, primaryDataSourceFieldIds])

    const blockData = useMemo(
        () => ({ ...fieldBlockData, config: { ...config, canEdit: canEdit && joinFieldCanEdit } }),
        [fieldBlockData, config, canEdit, joinFieldCanEdit]
    )

    const fieldContent = useMemo(() => {
        if (!record) {
            return
        }
        return record.content?.[fieldPointer]?.value
    }, [fieldPointer, record])

    const [currentValue, setCurrentValue] = useState<FieldCellValue>('')
    const [isValidFieldInput, setIsValidFieldInput] = useState(false)

    const formCellValue = useMemo(() => find(item => item.id === blockId, inputList), [blockId, inputList])

    const fieldError = useMemo(
        () =>
            field && isValidFieldInput
                ? getFieldInputError({
                      value: { value: currentValue, type: inputType } as FieldInputADTValue,
                      rule: getFieldInputRules({ config, id: blockId, title }),
                      fieldType: field.type
                  })
                : undefined,
        [blockId, config, currentValue, field, inputType, isValidFieldInput, title]
    )
    const { value, initValue, error } = useMemo(() => {
        const initFormCellContent = find(item => item.id === blockId, initInputList ?? [])
        const emptyValue = getEmptyFieldInputValue(inputType, field?.type)
        const initFieldCellContent = initRecord?.content?.[fieldPointer]?.value ?? emptyValue
        return formModule?.type === 'form'
            ? {
                  value: formCellValue?.value,
                  initValue: initFormCellContent?.value ?? emptyValue,
                  error: errors?.[initFormCellContent?.id ?? '']
              }
            : {
                  value: fieldContent,
                  initValue: initFieldCellContent,
                  error: fieldError
              }
    }, [
        initInputList,
        initRecord?.content,
        fieldPointer,
        inputType,
        field?.type,
        formModule?.type,
        formCellValue?.value,
        errors,
        fieldContent,
        fieldError,
        blockId
    ])

    useEffect(() => {
        setCurrentValue(initValue)
    }, [initValue])

    const handleValueChange = useCallback(
        async (fieldValue: FieldADTValue) => {
            const { value } = fieldValue

            if (!record) {
                return
            }
            const isUpdateWillNotPass = required && isEmptyCellValue(fieldValue)
            // 如果为空，且是必填项，进行错误提示
            if (isUpdateWillNotPass) {
                Toast.error('必填项不能为空~')
            }

            const recordId = record?.id ?? ''
            const dsId = record?.dsId ?? ''

            await updateCell(
                {
                    recordId,
                    dsId,
                    envId,
                    fieldId: fieldPointer,
                    value: { value }
                },
                { local: isUpdateWillNotPass }
            )
            // 创建行以后，需要通知对应订阅更新数据
            pageStackPubSub.emit(`${dsId}-ADD`)
        },
        [envId, fieldPointer, record, required, updateCell]
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                openPageStack(stackFactory({ appId, pageId: page, rootPageId, stackDisplayType: openType }))
            }
        },
        [appId, openPageStack, rootPageId]
    )

    const handleSaveChange = useCallback(
        (v: FieldInputADTValue) => {
            formOnChange?.(blockId, v)
            setCurrentValue(v.value)
            setIsValidFieldInput(true)
            if (formModule.type === 'form') {
                setPageStack(draft => {
                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                    if (stack) {
                        if (!stack.blockRuntimeState.formContainer) {
                            stack.blockRuntimeState.formContainer = {}
                        }
                        stack.blockRuntimeState.formContainer[blockId] = {
                            changed: true
                        }
                    }
                })
            }
        },
        [formOnChange, blockId, formModule.type, setPageStack, rootPageId, stackId]
    )

    const handleChangeSmsCode = useCallback(
        (v: string) => {
            if (formModule.type === 'form') {
                formModule?.onCodeChange?.(blockId, v)
            }
        },
        [blockId, formModule]
    )

    const handleChange = useCallback(
        (v: FieldInputADTValue) => {
            setCurrentValue(v.value)
            if (formModule.type === 'field') {
                const errorNum = Object.keys(fieldError ?? {}).length
                errorNum === 0 && handleValueChange({ value: v.value, ...field } as FieldADTValue)
            }
        },
        [field, fieldError, formModule.type, handleValueChange]
    )

    /** 获取关联数据源数据 start */
    const relativeDataSourceConfig = useMemo(
        () => [
            {
                config,
                value: formModule?.type === 'form' ? initValue : initRecord?.content?.[fieldPointer]?.value
            }
        ],
        [config, formModule?.type, initValue, initRecord?.content, fieldPointer]
    )

    const { onFetchDataSource, onLoadMoreData, relativeDataSource, onFetchCascadeOptions, onFetchPersonOptions } = useFieldBlockMethods(
        relativeDataSourceConfig,
        fieldBlockValueMap
    )
    /** 获取关联数据源数据end */

    useEffect(() => {
        if (formModule.type === 'form' && formCellValue) {
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    if (!stack?.formState) {
                        stack.formState = {}
                    }
                    stack.formState[blockId] = { ...clone(formCellValue), source: 'form', dsId: dataSource?.id }
                }
            })
            return
        }
        setPageStack(draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                if (!stack?.formState) {
                    stack.formState = {}
                }

                stack.formState[blockId] = {
                    id: blockId,
                    fieldId: fieldPointer,
                    dsId: dataSource?.id,
                    type: inputType,
                    value: currentValue ?? '',
                    fieldType: field?.type,
                    source: 'field'
                } as InputValueItem
            }
        })
    }, [
        blockId,
        currentValue,
        dataSource?.id,
        field?.type,
        fieldPointer,
        formCellValue,
        formModule.type,
        inputType,
        rootPageId,
        setPageStack,
        stackId
    ])

    useEffect(() => {
        return () => {
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack?.formState) {
                    Reflect.deleteProperty(stack.formState, blockId)
                }
            })
        }
    }, [blockId, rootPageId, setPageStack, stackId])

    const uploadyOptions = React.useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(),
                fileFilter: (file: File | string, index: number) => {
                    if (field?.id === 'AVATAR') {
                        if (file instanceof File) {
                            if (file.size > avatarMaxFileSize) {
                                Toast.error(t('fileExceedsUploadAvatarLimit', { v: getFileSizeToMB(fileMaxUploadSize) }))
                                return false
                            }
                            if (getFileTypeByFileName(file.name) !== 'image') {
                                Toast.error(t('onlyPicture'))
                                return false
                            }

                            return true
                        }
                        return true
                    }

                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(t('fileExceedsUploadLimit', { v: getFileSizeToMB(fileMaxUploadSize) }))
                            return false
                        }

                        return true
                    }
                    return true
                }
            }
        }),
        [field?.id, pageId, pageName, t]
    )

    const videoUploadyOption = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(t('fileExceedsUploadLimit', { v: getFileSizeToMB(fileMaxUploadSize) }))
                            return false
                        }
                        if (field?.type === 'video') {
                            const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                            if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                                Toast.error(t('unsupportedVideoFormatPleaseUploadMP4file'))
                                return false
                            }
                        }

                        return true
                    }
                    return true
                }
            }
        }),
        [field?.type, pageId, pageName, t]
    )

    return (
        <FieldBlock
            uploadyOptions={uploadyOptions}
            videoUploadyOption={videoUploadyOption}
            blockData={blockData}
            value={value}
            previewType={previewType}
            dataSource={dataSource}
            isValid={isValid}
            blockType={formModule?.type === 'form' ? 'form' : 'field'}
            record={record}
            relativeDataSource={relativeDataSource}
            dataSourceList={dataSourceList}
            error={error}
            language={language}
            onChange={handleChange}
            onSaveChange={handleSaveChange}
            richTextUploadOptions={uploadManagerInAppParams()}
            onFetchDataSource={onFetchDataSource}
            onLoadMoreData={onLoadMoreData}
            onOpenPage={handleOpenPage}
            onFetchCascadeOptions={onFetchCascadeOptions}
            onChangeSmsCode={handleChangeSmsCode}
            onFetchSmsCode={formModule?.type === 'form' ? mobile => srv.getVerifyCode({ mobile, blockId, pageId }) : undefined}
            onFetchPersonOptions={onFetchPersonOptions}
            onRenderTitle={handleRenderTitle}
        />
    )
}

export default FieldBlockController
