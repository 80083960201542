import type { AppUser } from '@lighthouse/core'
import type { PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

import type { AppDepartment, AppRole } from '../types'

// 数据源provider
export type DataSourceContextType = {
    personOptions?: AppUser[]
    roleOptions?: AppRole[]
    departmentOptions?: AppDepartment[]
    visibleJoinConfigure: boolean
    onVisibleJoinConfigureChange?: (visible: boolean) => void
}

export const DataSourceContext = createContext<DataSourceContextType>({
    visibleJoinConfigure: false
})

type DataSourceProviderProps = PropsWithChildren<{
    value: DataSourceContextType
}>

export const DataSourceProvider: React.FC<DataSourceProviderProps> = props => <DataSourceContext.Provider {...props} />

export const useDataSourceContext = () => useContext(DataSourceContext)


// 数据源内容provider
export type DataSourceContentContextType = {
    el: HTMLDivElement | null
}

export const DataSourceContentContext = createContext<DataSourceContentContextType>({
    el: null
})

type DataSourceContentProviderProps = PropsWithChildren<{
    value: DataSourceContentContextType
}>

export const DataSourceContentProvider: React.FC<DataSourceContentProviderProps> = props => <DataSourceContentContext.Provider {...props} />

export const useDataSourceContentContext = () => useContext(DataSourceContentContext)
