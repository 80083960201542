import { MessageContainer, ModalsProvider as ByecodeModalProvider, ScannerProvider, ToastContainer } from '@byecode/ui'
import { getBrandInfo, UploadManageContext, useAtomAction } from '@lighthouse/shared'
import type { MantineThemeOverride } from '@mantine/core'
import { MantineProvider } from '@mantine/core'
// import { ModalsProvider } from '@mantine/modals'
import { NotificationsProvider } from '@mantine/notifications'
import { DevTools } from 'jotai-devtools'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Outlet } from 'react-router-dom'
import { useCss, useFavicon, useTitle } from 'react-use'
import styled from 'styled-components'

import { clearExpiresCacheAtom } from '@/atoms/storage/action'
import { Feedback } from '@/components/Feedback'
import { NetworkErrorNotification } from '@/components/NetworkErrorNotification'
import { SpaceGradeProvider } from '@/components/SpaceGrade'
import { QuotaMessageContainer } from '@/components/SpaceQuota/QuotaMessage'
import { isSuccessfulCallToToast } from '@/utils/auth'

import { GlobalStyle } from './globalStyle'

const theme: MantineThemeOverride = {
    colorScheme: 'light',
    colors: {
        blue: ['#f9f9ff', '#f6f5ff', '#ededff', '#dfdeff', '#b7b5ff', '#908dff', '#5551ff', '#332eec', '#2b27c8', '#29248f'],
        green: ['#f6fef9', '#ecfdf3', '#d1fadf', '#a6f4c5', '#6ce9a6', '#32d583', '#12b76a', '#039855', '#027a48', '#05603a']
    },
    fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    primaryShade: 6,
    components: {
        Switch: {
            styles: {
                body: {
                    verticalAlign: 'middle',
                    alignItems: 'center'
                },
                track: {
                    cursor: 'pointer'
                }
            }
        },
        Input: {
            styles: {
                input: {
                    color: 'var(--color-black)'
                }
            }
        },
        Modal: {
            styles: {
                modal: {
                    color: 'var(--color-black)'
                }
            }
        },
        Text: {
            styles: {
                root: {
                    fontFamily:
                        'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji'
                }
            }
        },
        Select: {
            styles: {
                // 修复自定义rightSection的icon点击无反馈
                rightSection: {
                    pointerEvents: 'none'
                }
            }
        }
    }
}

const AppShellScreen = styled.main`
    width: 100%;
    height: 100%;
`

const MainContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const { themeColor, builderLogo, webTagTitle } = getBrandInfo()

export const DefaultLayout = () => {
    useFavicon(builderLogo)
    useTitle(webTagTitle)
    const { run: clearExpiresCache } = useAtomAction(clearExpiresCacheAtom)
    const brandTheme = useCss({
        '--color-theme-1': `var(--color-${themeColor}-25)`,
        '--color-theme-2': `var(--color-${themeColor}-50)`,
        '--color-theme-3': `var(--color-${themeColor}-100)`,
        '--color-theme-4': `var(--color-${themeColor}-200)`,
        '--color-theme-5': `var(--color-${themeColor}-300)`,
        '--color-theme-6': `var(--color-${themeColor}-400)`,
        '--color-theme-7': `var(--color-${themeColor}-500)`,
        '--color-theme-8': `var(--color-${themeColor}-600)`,
        '--color-theme-9': `var(--color-${themeColor}-700)`,
        '--color-theme-10': `var(--color-${themeColor}-800)`,
        '--color-main': `var(--color-${themeColor}-500)`
    })

    useEffect(() => {
        document.body.className = brandTheme
        document.body.style.setProperty('--byecode-colors-colorPrimary', `var(--color-${themeColor}-500)`)
        document.body.style.setProperty('--byecode-colors-colorPrimaryHover', `var(--color-${themeColor}-600)`)
    }, [brandTheme])

    useEffect(() => {
        // 清除过期的缓存
        clearExpiresCache()
    }, [clearExpiresCache])

    return (
        <MantineProvider theme={theme}>
            <GlobalStyle />
            <UploadManageContext isSuccessfulCall={isSuccessfulCallToToast}>
                <ByecodeModalProvider>
                    <NotificationsProvider position="top-right">
                        <ScannerProvider>
                            <MessageContainer />
                            <QuotaMessageContainer />
                            <AppShellScreen id="app-shell-screen">
                                {createPortal(<ToastContainer />, document.body)}
                                {createPortal(<SpaceGradeProvider />, document.body)}

                                <MainContent id="main-content">
                                    <NetworkErrorNotification />
                                    <Outlet />
                                    {/* <AliPlayer source='' /> */}
                                    {import.meta.env.DEV && <DevTools />}
                                </MainContent>

                                {createPortal(<Feedback />, document.body)}
                            </AppShellScreen>
                        </ScannerProvider>
                    </NotificationsProvider>
                </ByecodeModalProvider>
            </UploadManageContext>
        </MantineProvider>
    )
}
