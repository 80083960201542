import { Button, Flex, flex, tinyButtons } from '@byecode/ui'
import { omit } from 'rambda'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useUser } from '@/hooks/useUser'

import TemplateBreadcrumbs from '../TemplateBreadcrumbs'
import TemplateDetail from '../TemplateDetail'
import TemplateTitle from '../TemplateTitle'
import type { Route, Template } from '../types'

interface TemplatePreviewProps {
    data: Template
    routes: Route[]
    loading: boolean

    onBack: () => void
    onCreate: (value: Template) => void
}

const SCXContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px 32px;

    overflow-y: auto;
    ${tinyButtons}
`

const SCXHeader = styled.div``

const SCXTemplateTitle = styled.div`
    margin-top: 32px;
    ${flex}
    /* align-items: center; */
    justify-content: space-between;
    gap: 32px;
`

const SCXWrapper = styled.div`
    width: 100%;
    height: calc(100% - 60px);
    margin: 0 auto;
    max-width: 1000px;
`
const TemplatePreview: React.FunctionComponent<TemplatePreviewProps> = ({ data, routes, loading, onBack, onCreate }) => {
    const userData = useUser()
    const navigate = useNavigate()

    return (
        <SCXContainer>
            <SCXWrapper>
                <SCXHeader>
                    <TemplateBreadcrumbs onBack={onBack} routes={routes} />
                    <SCXTemplateTitle>
                        <TemplateTitle
                            data={omit(['labels'], data)}
                            titleSize={24}
                            describeWrap="pre-wrap"
                            gap={12}
                            describeSize={14}
                            iconSize={27}
                            logoSize={51}
                            disableTooltip
                        />
                        <Flex gap={8}>
                            <Button
                                type="default"
                                size="lg"
                                style={{ flexShrink: 0 }}
                                radius={8}
                                // loading={loading}
                                onClick={() => navigate({ pathname: `/template/${data.appId}` })}
                            >
                                预览
                            </Button>
                            <Button
                                type="primary"
                                size="lg"
                                style={{ flexShrink: 0 }}
                                radius={8}
                                loading={loading}
                                onClick={() => (userData.userId ? onCreate(data) : navigate({ pathname: '/account/login' }))}
                            >
                                立即使用
                            </Button>
                        </Flex>
                    </SCXTemplateTitle>
                </SCXHeader>
                <TemplateDetail data={data} />
            </SCXWrapper>
        </SCXContainer>
    )
}

export default TemplatePreview
