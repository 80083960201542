import { RIGHT_ASIDE_CLASS, useAtomAction, useAtomData } from '@lighthouse/shared'
import { useCallback } from 'react'
import { useClickAway } from 'react-use'

import { lastPageOfStackAtom, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { boxSelectionNodeIdsAtom } from '@/atoms/workSpace/state'
import { useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'

type Configure = {
    ref: React.RefObject<HTMLElement | null>
    enabled?: boolean
}

/** 监听点击栅格外的事件 */
export const useClickAwayOutside = ({ ref, enabled }: Configure) => {
    const asideType = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.state.asideType, [])
    )
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { run: setBoxSelectionIds } = useAtomAction(boxSelectionNodeIdsAtom)

    const { rootPageId } = useRootPageContext()
    const stackId = useCurrentStackIdContext()

    useClickAway(ref, e => {
        if (!enabled) {
            return
        }
        const { target } = e
        if (target instanceof Element) {
            const IGNORE_TYPE = 'data-ignore-click-away'
            const rootNodeID = 'root'

            if (e.composedPath().some(e => e instanceof Element && e.hasAttribute(IGNORE_TYPE))) {
                return
            }

            // 点击右侧栏内容
            if (document.querySelector(`.${RIGHT_ASIDE_CLASS}`)?.contains(target)) {
                return
            }

            // 点击导航栏
            if (document.querySelector('#NAVBAR_HEADER')?.contains(target)) {
                return
            }

            // 点击root外的节点，例如弹窗，忽略该点击事件
            if (!document.querySelector(`#${rootNodeID}`)?.contains(target)) {
                return
            }
        }

        setBoxSelectionIds([])

        console.log('click away')
        if (asideType === AsideType.PAGE) {
            return
        }
        setPageStack(draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.state.asideType = AsideType.PAGE
                stack.state.selectedNode = undefined
            }
        })
    })
}
