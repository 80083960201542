import { Checkbox, IconFont } from '@byecode/ui'
import type { AppDepartmentWidthChild } from '@lighthouse/shared'
import { Breadcrumbs, Collapse, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import cls from 'classnames'
import React, { useMemo } from 'react'

import type { TagData } from '.'
import * as SC from './styles'

interface DepartmentItemProps {
    data: AppDepartmentWidthChild & { nameList?: [] }
    value: TagData[]
    disableItemList: TagData[]
    level: number
    defaultOpened?: boolean
    style?: React.CSSProperties
    type?: 'collapse' | 'breadcrumbs'
    onChange?: (id: string, type: 'role' | 'department') => void
}

export const DepartmentItem: React.FunctionComponent<DepartmentItemProps> = ({
    data,
    value,
    level,
    defaultOpened = false,
    disableItemList,
    type,
    style,
    onChange
}) => {
    const { id, name, children = [], nameList } = data
    const [opened, { toggle }] = useDisclosure(defaultOpened)

    const active = useMemo(
        () => [...value, ...disableItemList].some(item => item.type === 'department' && item.id === id),
        [disableItemList, id, value]
    )

    const disabled = useMemo(() => disableItemList.some(item => item.type === 'department' && item.id === id), [disableItemList, id])

    const newNameList = useMemo(() => (type === 'breadcrumbs' ? nameList : [name]), [name, nameList, type])
    return (
        <>
            <SC.DropDownItem style={{ paddingLeft: 20 * level + 8 }} className={cls({ active })}>
                <SC.ItemWrapper style={style} onClick={toggle}>
                    <SC.ItemLeftFill>
                        <Checkbox
                            disabled={disabled}
                            checked={active}
                            onClick={e => {
                                e.stopPropagation()
                                onChange?.(id, 'department')
                            }}
                        />
                        <Breadcrumbs
                            separator={<IconFont size={16} type="ArrowRightSmall" color="var(--color-gray-400)" />}
                            style={{ marginLeft: 8 }}
                        >
                            {newNameList?.map(item => (
                                <Text size={14} key={item}>
                                    {item}
                                </Text>
                            ))}
                        </Breadcrumbs>
                    </SC.ItemLeftFill>
                    <SC.ItemRightFill>
                        <SC.IconWrapper style={{ width: 16 }}>
                            {children.length > 0 && (
                                <IconFont
                                    type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                                    color={active ? 'var(--color-theme-7)' : 'var(--color-gray-300)'}
                                    size={16}
                                />
                            )}
                        </SC.IconWrapper>
                    </SC.ItemRightFill>
                </SC.ItemWrapper>
            </SC.DropDownItem>
            {type === 'collapse' && children?.length > 0 && (
                <Collapse in={opened}>
                    {children?.map(item => (
                        <DepartmentItem
                            key={item.id}
                            data={item}
                            value={value}
                            level={level + 1}
                            type={type}
                            disableItemList={disableItemList}
                            defaultOpened={defaultOpened}
                            style={style}
                            onChange={onChange}
                        />
                    ))}
                </Collapse>
            )}
        </>
    )
}
