import { Flex, IconFont, Text } from '@byecode/ui'
import React from 'react'
import { Link } from 'react-router-dom'
import type { CloseButtonProps, ToastOptions } from 'react-toastify'
import { toast, ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import { openSpaceGrade } from '@/components/SpaceGrade/event'
import { useSpaceQuota } from '@/shared/reusable'

import { QUOTA_MESSAGE_CONTAINER_ID, QUOTA_MESSAGES_MAP } from './constants'
import * as SC from './styles'

const CloseButton = ({ closeToast }: CloseButtonProps) => {
    return (
        <SC.CloseButton onClick={closeToast}>
            <IconFont type="Close" />
        </SC.CloseButton>
    )
}

const StyledToastContainer = styled(ToastContainer)`
    width: unset;
    padding: 0;
    /* 多1是因为盖不住Popover之类的弹出层 */
    z-index: 201;
    .Toastify__toast {
        width: 375px;
        border-radius: 22px;
        padding: 14px;
        box-shadow: 0px 4px 12px 0px #1018281a;
    }
    .Toastify__toast-body {
        overflow: hidden;
        & > div:last-child {
            overflow: hidden;
        }
    }
`
export const QuotaMessageContainer = () => {
    return (
        <StyledToastContainer
            containerId={QUOTA_MESSAGE_CONTAINER_ID}
            newestOnTop
            hideProgressBar
            autoClose={false}
            closeOnClick={false}
            draggable={false}
            closeButton={CloseButton}
            enableMultiContainer
        />
    )
}

interface ToastContentProps {
    type: keyof typeof QUOTA_MESSAGES_MAP
}
const ToastContent = ({ type }: ToastContentProps) => {
    const { data } = useSpaceQuota()

    const { title, description, icon, renderQuota, renderUsage } = QUOTA_MESSAGES_MAP[type]
    const value = data?.[type]

    return (
        <>
            <Flex gap={16} alignItems="center">
                <SC.IconWrapper>
                    <IconFont type={icon} size={28} fill="var(--color-gray-400)" />
                </SC.IconWrapper>
                <Flex direction="column" styles={{ root: { flex: 1, overflow: 'hidden' } }}>
                    <Text weight={500} size={16} lineHeight="24px" color="var(--color-black)" mb={8}>
                        {title}
                    </Text>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text size={12} lineHeight="20px" color="var(--color-gray-700)" mb={4}>
                            {description}
                        </Text>
                        {value && (
                            <Text size={12} lineHeight="20px" color="var(--color-gray-700)" mb={4} ellipsis={false}>
                                {renderUsage(value.usage)}/{renderQuota(value.quota)}
                            </Text>
                        )}
                    </Flex>

                    <SC.Progress>
                        <SC.ProgressBar />
                    </SC.Progress>
                </Flex>
            </Flex>
            <Flex justifyContent="flex-end" gap={12} mt={16}>
                <SC.Button as={Link} to="/workbench/quota">
                    查看配额
                </SC.Button>
                <SC.Button
                    theme="dark"
                    onClick={() => {
                        openSpaceGrade('version_upgrade')
                    }}
                >
                    升级版本
                </SC.Button>
            </Flex>
        </>
    )
}

interface QuotaMessageProps extends ToastContentProps {}

export const QuotaMessage = (props: QuotaMessageProps, options?: ToastOptions) => {
    return toast(<ToastContent {...props} />, { toastId: props.type, containerId: QUOTA_MESSAGE_CONTAINER_ID, ...options })
}
