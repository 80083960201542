import styled, { css } from 'styled-components'

export const Website = styled.div`
    border-top: 1px solid #c5c5c5;
    border-left: 1px solid #c5c5c5;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 6px 0px #3838381f;
    border-radius: 10px 0px 0px 0px;
    background: linear-gradient(137.8deg, #f7f9fb 4.58%, #fbf7fb 27.14%, #f8fcfc 75.88%);
`

export const Header = styled.div`
    width: 100%;
    height: 50px;
    background-color: #e8eaed;
    border-radius: 10px 0px 0px 0px;
    padding-top: 14px;
`

export const HeaderContent = styled.div`
    position: relative;
    height: 36px;
    width: 275px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: row;
    padding: 0 7px;
`

export const HeaderHalf = styled.div`
    width: 100%;
    height: 18px;
    position: absolute;
    top: 0px;
    background-color: #e8eaed;
    z-index: 1;
`

export const Prefix = styled.div`
    position: absolute;
    left: 0;
    width: 7px;
    height: 100%;
    background-color: #e8eaed;
    border-radius: 0px 0px 10px 0px;
`

export const Suffix = styled.div`
    position: absolute;
    right: -1px;
    width: 8px;
    height: 100%;
    background-color: #e8eaed;
    border-radius: 0 0px 0px 10px;
`

export const WebTab = styled.div`
    height: 36px;
    width: 275px;
    border-radius: 10px 10px 0 0;
    background-color: var(--color-white);
    z-index: 2;
    padding: 0 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

export const Title = styled.div`
    color: #161616;
    margin-left: 8px;
`

export const ToolBar = styled.div`
    padding-left: 20px;
    height: 43px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid #c5c5c5;
    background-color: var(--color-white);
`

export const Tool = styled.div`
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    width: 82px;
`

export const Address = styled.div`
    flex: 1;
    height: 30px;
    border-radius: 15px 0 0 15px;
    background-color: #f1f3f4;
    padding-left: 16px;
    display: flex;
    align-items: center;
    gap: 14px;
`

export const Url = styled.div`
    font-size: 12px;
    white-space: nowrap;
    color: #161616;
`

export const Content = styled.div<{ isHorizontal?: boolean; background?: string }>`
    background-color: ${({ background }) => background || 'var(--color-white)'};
    ${({ isHorizontal }) =>
        isHorizontal
            ? css`
                  width: 100%;
                  height: 60px;
                  padding-left: 16px;
                  display: flex;
                  align-items: center;
              `
            : css`
                  width: 240px;
                  height: 100%;
                  padding: 23px 40px;
              `}
`

export const AppHeader = styled.div<{ isHorizontal?: boolean }>`
    ${({ isHorizontal }) =>
        isHorizontal
            ? css`
                  display: flex;
                  align-items: center;
                  gap: 40px;
              `
            : css`
                  display: flex;
                  flex-direction: column;
                  width: 240px;
                  height: 100%;
              `}
`

export const AppInfo = styled.div<{ isHorizontal?: boolean }>`
    ${({ isHorizontal }) =>
        isHorizontal
            ? css`
                  display: flex;
                  align-items: center;
                  gap: 12px;
              `
            : css`
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                  margin-bottom: 20px;
              `}
`

export const AppIcon = styled.div`
    width: 32px;
    height: 32px;
`

export const AppTitle = styled.div<{ color?: string; isHorizontal?: boolean }>`
    width: ${({ isHorizontal }) => (isHorizontal ? '120px' : '160px')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    color: ${({ color }) => color || 'var(--color-black)'};
`

export const CrossBand = styled.div<{ width: number; height: number; borderRadius: number; background?: string; opacity?: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    background-color: ${({ background }) => background || 'var(--color-white)'};
    opacity: ${({ opacity }) => opacity || 1};
`

export const AppNavBar = styled.div<{ isHorizontal?: boolean }>`
    ${({ isHorizontal }) =>
        isHorizontal
            ? css`
                  flex: 1;
                  display: flex;
                  align-items: center;
                  gap: 32px;
              `
            : css`
                  display: flex;
                  flex-direction: column;
                  padding: 8px 0;
                  gap: 32px;
              `}
`

export const AppNav = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`
