import { IconFont, Menu, MenuItem } from '@byecode/ui'
import { SelectedMode } from '@lighthouse/core'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

export interface TableHeaderCheckerProps {
    index?: number
    checked?: boolean
    className?: string
    disableSelect?: boolean
    disableSelectMode?: boolean
    onChange?: (checked: boolean) => void
    onSelectModeChange?: (mode?: SelectedMode) => void
}

const TableRecordIndex = styled.p`
    color: var(--color-gray-400);
    display: block;
`

const TableRecordChecker = styled(Checkbox)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const TableRecordCheckerWrapper = styled.div<{ open?: boolean }>`
    position: absolute;
    display: flex;
    gap: 2px;
    align-items: center;
    inset: unset;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
`

const SCxArrowWrapper = styled.div`
    width: 12px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #26415a0f;
    }
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-gray-400);
`

export const SCxIconWrapper = styled.div<{ open?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    visibility: ${({ open }) => (open ? 'hidden' : 'visible')};;
`

const TableHeaderCheckerWrapper = styled.div<{ open?: boolean }>`
    position: sticky;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-height: 40px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    /* background: var(--color-white); */
    border-right: 1px solid var(--color-gray-200);
    z-index: 200;

    &.checked,
    &:not(.table-disable-checked):hover {
        ${TableRecordCheckerWrapper} {
            visibility: visible;
        }
        ${SCxIconWrapper} {
            visibility: hidden;
        }
    }
`

export const TableHeaderChecker: React.FC<TableHeaderCheckerProps> = ({
    index,
    checked,
    className = '',
    disableSelect,
    disableSelectMode,
    onChange,
    onSelectModeChange
}) => {
    const [open, setOpen] = useState(false)
    const handleChange = useCallback(() => {
        if (disableSelect) {
            return
        }
        onChange?.(!checked)
    }, [checked, disableSelect, onChange])
    return (
        <TableHeaderCheckerWrapper className={cls({ checked }, { 'table-disable-checked': disableSelect }, className)}>
            <SCxIconWrapper open={open}>
                {index === undefined ? <SCxIcon type="PropertyNumber" /> : <TableRecordIndex>{index + 1}</TableRecordIndex>}
            </SCxIconWrapper>
            <TableRecordCheckerWrapper open={open} style={{ paddingLeft: disableSelectMode ? 0 : '14px' }}>
                <TableRecordChecker size="xs" checked={checked} onChange={handleChange} />
                {!disableSelectMode && (
                    <Menu width={204} opened={open} position="bottom-start" closeOnItemClick onChange={setOpen}>
                        <Menu.Target>
                            <SCxArrowWrapper>
                                <IconFont type="CaretDown" />
                            </SCxArrowWrapper>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <MenuItem onClick={() => onSelectModeChange?.(SelectedMode.CURRENT_PAGE)}>当前页</MenuItem>
                            <MenuItem onClick={() => onSelectModeChange?.(SelectedMode.ALL)}>所有记录</MenuItem>
                            <MenuItem onClick={() => onSelectModeChange?.()}>取消选择</MenuItem>
                        </Menu.Dropdown>
                    </Menu>
                )}
            </TableRecordCheckerWrapper>
        </TableHeaderCheckerWrapper>
    )
}
