import { type ActionFlowNode, type SendMsgWxRootConfig, generateVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleWxRobot = async (node: ActionFlowNode<SendMsgWxRootConfig>, extraParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return
    }

    const { text } = config
    const { editorContent, ...restText } = text

    const editorContentValue = generateVariableValue({  innerType: 'TEXT', jsonContent: editorContent, extraParams })

    const resolvedText = {
        ...restText,
        content: Array.isArray(editorContentValue) ? editorContentValue.join('') : editorContentValue
    }

    await srv.activeWxRobotAction({ config: { ...config, text: resolvedText } })

    return {
        ...config,
        text: resolvedText
    }
}
