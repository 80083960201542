import { ClickAwayListener } from '@byecode/ui'
import type { DataSourceAbstract, Field, FieldADTValue, RecordLikeProtocol } from '@lighthouse/core'
import { isSafaris } from '@lighthouse/tools'
import { Modal } from '@mantine/core'
import type { UploadyProps } from '@rpldy/shared-ui'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { NO_EDIT_SYSTEM_FIELD, notEditableFieldTypes } from '../../constants'
import { useDataSourceContentContext } from '../../contexts'
import { useAtomData, useCellEditorPosition } from '../../hooks'
import { getIsAppointField } from '../../utils'
import { FieldValueEditor } from '../FieldValueEditor'
import type { UseUploadFileSParameter } from '../UploadManage'

interface TablePopoverProps {
    field: Field
    popperAnchorElement?: HTMLDivElement
    dataSourceInfo: DataSourceAbstract
    recordPoolAtom: ReturnType<typeof atomWithImmer<RecordLikeProtocol[]>>
    recordId: string
    width?: number
    rootRef: React.RefObject<HTMLDivElement>
    scrollElement: HTMLDivElement | null
    // getUploadOptions: (fieldId: string, recordId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    // getRichTextUploadOptions: (fieldId: string, recordId: string) => UploadyProps
    // getVideoUploadOptions: (fieldId: string, recordId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    richTextUploadOptions: UploadyProps
    videoUploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onClose?: () => void
}

export const SCxDiv = styled.div``

export const SCxWindowShapePopperAnchor = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
`

export const SCxWindowShapeEditorPopover = styled.div`
    position: absolute;
    min-width: 100px;
    min-height: 36px;
    overflow: hidden;
    display: inline-table;
    opacity: 0;
    box-shadow: var(--box-shadow);
    background-color: var(--color-white);
    margin-left: 2px !important;
    margin-top: 2px !important;
    z-index: 200;
    /* left: 2px!important;
    top: 2px!important; */
    /* &[data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    } */
`
export const SCxWindowShapeEditorModal = styled(Modal)``

export const SCxWindowShapeEditorModalOverlay = styled.div`
    outline: none;
    overflow: hidden;
`

export const SCxWindowShapeContent = styled.div`
    width: 100%;
    height: 100%;
`

export const TablePopover: React.FC<TablePopoverProps> = ({
    field,
    popperAnchorElement,
    dataSourceInfo,
    recordPoolAtom,
    width = 160,
    recordId,
    rootRef,
    scrollElement,
    uploadOptions,
    richTextUploadOptions,
    videoUploadOptions,
    onCellChange,
    onCellUpdate,
    onClose
}) => {
    const [open, setOpen] = useState(false)
    const { el: containerElement } = useDataSourceContentContext()
    const { appId, id: dsId } = dataSourceInfo
    const { id: fieldId } = field
    const currentRecord = useAtomData(
        recordPoolAtom,
        useCallback(
            draft => find(item => item.appId === appId && item.dsId === dsId && item.id === recordId, draft),
            [appId, dsId, recordId]
        )
    )
    const fieldValue = useMemo(() => currentRecord?.content?.[fieldId]?.value, [currentRecord?.content, fieldId])
    const [currentData, setCurrentData] = useState(fieldValue)
    const isSystemField = getIsAppointField(fieldId, NO_EDIT_SYSTEM_FIELD)
    const isNoneShape = notEditableFieldTypes.has(field?.type || '')
    const isInput = ['text', 'url', 'phoneNumber', 'email', 'number'].includes(field?.type || '')
    const isPopperShape = !isNoneShape && !['notes'].includes(field?.type || '') && !isSystemField
    const isModalShape = useMemo(() => {
        return !(isPopperShape || isSystemField)
    }, [isPopperShape, isSystemField])

    const { popoverRef, handleClearAnchorPosition, position } = useCellEditorPosition({
        rootElement: containerElement,
        anchorElement: popperAnchorElement,
        open,
        onOpenChange: setOpen
    })

    useEffect(() => {
        if (popperAnchorElement && !open) {
            setOpen(true)
        }
        if (!popperAnchorElement) {
            setOpen(false)
        }
    }, [open, popperAnchorElement])

    const handleClickOutside = useCallback(
        (isActive?: boolean) => {
            setTimeout(() => {
                onClose?.()
            }, 0)
        },
        [onClose]
    )

    const handleCellChange = useCallback(
        async (recordId: string, fieldValue: FieldADTValue) => {
            setCurrentData(fieldValue.value)
            const isUpdate = await onCellChange?.(recordId, fieldValue)
            return !!isUpdate
        },
        [onCellChange]
    )

    const handleClose = useCallback(() => {
        setOpen(false)
        onClose?.()
        handleClearAnchorPosition()
    }, [handleClearAnchorPosition, onClose])

    return useMemo(() => {
        if (isPopperShape) {
            const extraStyles: React.CSSProperties = isInput
                ? {
                      border: '2px solid var(--color-main)',
                      ...position
                  }
                : {
                      border: '1px solid var(--color-gray-200)',
                      ...position
                  }
            const mouseEvent = isSafaris() ? 'onMouseUp' : 'onMouseDown'
            return (
                <SCxDiv>
                    {open &&
                        containerElement &&
                        popperAnchorElement &&
                        createPortal(
                            <ClickAwayListener data-ignore-click-away mouseEvent={mouseEvent} onClickAway={handleClose}>
                                <SCxWindowShapeEditorPopover
                                    onClick={e => e.stopPropagation()}
                                    style={{ width: width - 5, ...extraStyles }}
                                    data-ignore-click-away
                                    ref={popoverRef}
                                >
                                    {field && (
                                        <FieldValueEditor
                                            uploadOptions={uploadOptions}
                                            videoUploadOptions={videoUploadOptions}
                                            dataSourceInfo={dataSourceInfo}
                                            data={currentData}
                                            currentRecord={currentRecord}
                                            width={`${width - 5}px`}
                                            field={field}
                                            enableFileDownload
                                            recordId={recordId}
                                            autoFocus
                                            rootRef={rootRef}
                                            scrollElement={scrollElement}
                                            onCellChange={handleCellChange}
                                            onCellUpdate={onCellUpdate}
                                            onClose={handleClickOutside}
                                            richTextUploadOptions={richTextUploadOptions}
                                        />
                                    )}
                                </SCxWindowShapeEditorPopover>
                            </ClickAwayListener>,
                            containerElement
                        )}
                </SCxDiv>
            )
        }

        if (isModalShape) {
            return (
                <SCxWindowShapeEditorModal
                    centered
                    closeOnClickOutside={false}
                    padding={0}
                    radius={12}
                    size={720}
                    withinPortal
                    overlayColor="var(--color-black)"
                    overlayOpacity={0.75}
                    withCloseButton={false}
                    onKeyDown={e => e.stopPropagation()}
                    // opened={popperState.isOpen}
                    opened={open}
                    onClose={handleClickOutside}
                    closeOnEscape={false}
                    trapFocus={false}
                >
                    <SCxWindowShapeEditorModalOverlay style={{ height: 'calc(100vh - 344px)' }}>
                        {field && (
                            <FieldValueEditor
                                uploadOptions={uploadOptions}
                                videoUploadOptions={videoUploadOptions}
                                dataSourceInfo={dataSourceInfo}
                                data={currentData}
                                currentRecord={currentRecord}
                                field={field}
                                recordId={recordId}
                                rootRef={rootRef}
                                scrollElement={scrollElement}
                                onCellChange={onCellChange}
                                onCellUpdate={onCellUpdate}
                                onClose={handleClickOutside}
                                richTextUploadOptions={richTextUploadOptions}
                            />
                        )}
                    </SCxWindowShapeEditorModalOverlay>
                </SCxWindowShapeEditorModal>
            )
        }
        return null
    }, [
        isPopperShape,
        isModalShape,
        isInput,
        position,
        open,
        containerElement,
        popperAnchorElement,
        handleClose,
        width,
        popoverRef,
        field,
        uploadOptions,
        videoUploadOptions,
        dataSourceInfo,
        currentData,
        currentRecord,
        recordId,
        rootRef,
        scrollElement,
        handleCellChange,
        onCellUpdate,
        handleClickOutside,
        richTextUploadOptions,
        onCellChange
    ])
}
