import { getAssetUrl } from '@lighthouse/assets'

import type {
    TooltipDocument,
    TooltipDocumentType
} from './types'

export const tooltipDocumentMap: Record<TooltipDocumentType, TooltipDocument> = {
    regular: {
        title: '自定义输入框验证用户的输入格式，比如英文和数字。',
        // image: getAssetUrl('question', 'regular.png'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gdhtnooxo22fomqd?singleDoc#',
        linkText: '自定义正则大全'
    },
    smsVerification: {
        title: '当前仅支持中国大陆（+86）验证码短信。开启短信验证后，手机未通过验证的表单无法提交。\r\n短信验证将消耗短信条数，当短信条数为0时，表单将无法正常提交，查看帮助文档。'
    },
    recipient: {
        title: '添加收件人的方式：从数据表中选择插入变量'
    },
    cc: {
        title: '打开后每个收件人都可以看到所有收件人和抄送人'
    },
    orderId: {
        title: '注意：请将「自动编号」字段中的编号类型设为「随机编号」，这样能保证订单号的安全性。'
    },
    shopDescribe: {
        title: '不能超过127个字符'
    },
    orderMoney: {
        title: '金额单位为元；货币类型为 CNY：人民币'
    },
    refoundId: {
        title: '格式只能是数字、大小写字母_-*；只能选「自动编号」字段。\r\n注意：请将作为退款单号的「自动编号」中的场景设置为「订单」， 这样能保证订单号的安全性。'
    },
    refoundMoney: {
        title: '金额单位为元；货币类型为 CNY：人民币；不能大于原订单金额'
    },
    currency: {
        title: '三个小写字母组成的ISO货币代码，必须是Stripe支持的货币类型'
    },
    shopName: {
        title: '不能超过 127 个字符；\r\n注意：不可使用特殊字符，如 /，=，& 等'
    },
    shopImg: {
        title: '附件内容只能是图片或图片类型的资源链接'
    },
    panel: {
        title: '一个容器可以添加多个面板，目前仅支持与标签栏绑定使用。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zkphot9fsluxcr2t',
        image: getAssetUrl('question', 'panel.jpg'),
        linkText: '帮助手册'
    },
    visibilityDevice: {
        title: '主要用于内容的适配影响界面展示效果时。可改变组件在不同设备的可见性，达到界面美观的效果',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/qex89ct6wpr3hzto',
        image: getAssetUrl('question', 'visibilityDevice.jpg'),
        linkText: '帮助手册'
    },
    toolOperation: {
        title: '添加的表单页可用于提交信息到数据源中；自定义的按钮可执行按钮组件的所有动作。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/apza80xfqqz1zs99',
        image: getAssetUrl('question', 'toolOperation.jpg'),
        linkText: '帮助手册'
    },
    clickRecord: {
        title: '可添加点击后展开的详情页面以及展开方式；自定义可执行任意动作。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/apza80xfqqz1zs99',
        image: getAssetUrl('question', 'clickRecord.jpg'),
        linkText: '帮助手册'
    },
    actionConfigure: {
        title: '可配置点击组件后的执行任意动作。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/wezggrax39aq5h0y',
        image: getAssetUrl('question', 'actionConfigure.jpg'),
        linkText: '帮助手册'
    },
    appInfo: {
        title: '此处配置将会影响应用在网页中打开的标签栏效果。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/igafrtd7kf2z9ad9',
        image: getAssetUrl('question', 'appInfo.jpg'),
        linkText: '帮助手册'
    },
    tabBar: {
        title: '勾选后在手机端底部显示标签栏。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pvqay2eh48gmzxsd',
        image: getAssetUrl('question', 'tabBar.jpg'),
        linkText: '帮助手册'
    },
    // formContainer: {
    //     title: '用于提交数据表单的必备容器。收集用户信息并将其直接存储在应用的数据源中，需要绑定数据源使用，可添加任意子组件且设计排版。',
    //     // image: getAssetUrl('question', 'tabBar.jpg'),
    //     link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ohbdmuerlw4ha63v',
    //           linkText: '帮助手册'
    // }
}
