import { Button, Empty, IconFont, Loading, Text } from '@byecode/ui'
import { useDebounce } from '@byecode/ui/hooks/useDebounce'
import UploadButton from '@rpldy/upload-button'
import { useAbortItem, useItemFinishListener, useItemStartListener } from '@rpldy/uploady'
import React, { useMemo, useState } from 'react'
import { useCss } from 'react-use'

import { UploadClickDropZone } from '../../UploadClickDropZone'
import EmptyDragSvg from '../EmptyDrag.svg?react'
import { useStyles } from '../IconPicker.styles'
import type { IconPickerUploadProps } from '../IconPicker.types'
import * as SC from './iconUpload.styles'

const imageMaxSize = 1024 * 1024 * 5

// 文件后缀正则
export const fileSuffixRegex = /\.([\da-z]+)(?:[#?]|$)/iu

export const fileNameRegex = /\/[0-9a-z-]*\.[a-zA-z]*$/iu

export const IconUpload: React.FunctionComponent<IconPickerUploadProps> = ({ uploadParams, value: v, onClose, onGetFullUrl, onChange }) => {
    const [loading, setLoading] = useState(false)
    const [isOnDrag, setIsOnDrag] = useState(false)
    const debouncedIsOnDrag = useDebounce(isOnDrag, 100)
    const { classes } = useStyles({}, { name: 'IconPicker' })

    const btn = useCss({
        color: 'var(--color-black)',
        border: '1px solid var(--color-gray-200)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--color-white)',
        height: '36px',
        borderRadius: '7px',
        gap: '4px',
        padding: '0 10px',
        '&:hover': {
            backgroundColor: 'var(--color-gray-100)'
        }
    })

    const abortItem = useAbortItem()

    const emptyContentClassName = useCss({
        '&:hover': {
            borderRadius: 'inherit',
            border: '1px solid var(--color-purple-500)'
        }
    })

    useItemStartListener(batchItem => {
        const {
            id,
            file: { name, size }
        } = batchItem
        if (size > imageMaxSize) {
            abortItem(id)
            return
        }
        setLoading(true)
    })

    useItemFinishListener(batchItem => {
        const {
            file: { name, size },
            uploadResponse: {
                data: { content, success }
            }
        } = batchItem
        setLoading(false)
        onChange?.({ type: 'upload', value: content?.url })
    })

    const content = useMemo(() => {
        if (loading) {
            return <Loading description="正在上传" loadingColor="var(--color-purple-500)" shape="indicator" />
        }
        if (debouncedIsOnDrag && !v?.value) {
            return (
                <Empty
                    icon={<EmptyDragSvg />}
                    styles={{
                        root: {
                            outline: 'var(--color-purple-400) solid 4px',
                            border: '2px solid var(--color-purple-500)',
                            borderRadius: '8px'
                        },
                        content: {
                            color: 'var(--color-black)'
                        }
                    }}
                    description="松开文件"
                />
            )
        }
        if (!v?.value || v?.type !== 'upload' || !v) {
            return (
                <Empty
                    icon={<EmptyDragSvg />}
                    className={emptyContentClassName}
                    styles={{
                        root: {
                            background: 'var(--color-gray-50)'
                        }
                    }}
                    description={
                        <div>
                            <Text color="var(--color-black)" algin="center" style={{ lineHeight: '18px' }} lineClamp={1} size={12}>
                                点击或拖拽图片上传
                                <SC.StressText>（5M以内）</SC.StressText>
                            </Text>
                            <Text color="var(--color-gray-500)" algin="center" style={{ lineHeight: '16px', marginTop: 2 }} size={10}>
                                图片格式：JPG/PNG
                            </Text>
                        </div>
                    }
                />
            )
        }

        const fileName = (fileNameRegex.exec(v?.value)?.[0] ?? '').replace('/', '')

        return (
            <SC.Content>
                <SC.VideoInfo>
                    <SC.Img src={onGetFullUrl ? onGetFullUrl(v?.value) : v?.value} width={24} height={32} />
                    <SC.Title>
                        <Text color="var(--color-black)">{fileName}</Text>
                    </SC.Title>
                </SC.VideoInfo>
                <SC.ButtonGroup>
                    <Button
                        size="lg"
                        style={{ color: 'var(--color-black)' }}
                        icon={<IconFont type="Trash" size={16} color="var(--color-gray-400)" />}
                        onClick={e => {
                            onChange?.({ type: 'upload', value: '' })
                        }}
                    >
                        删除文件
                    </Button>
                    <UploadButton className={btn}>
                        <IconFont type="ArrowsClockwise" size={16} color="var(--color-gray-400)" />
                        <Text color="var(--color-black)">重新上传</Text>
                    </UploadButton>
                </SC.ButtonGroup>
            </SC.Content>
        )
    }, [btn, debouncedIsOnDrag, emptyContentClassName, loading, onChange, onGetFullUrl, v])

    return (
        <UploadClickDropZone disabledOpacity={1} disabled={Boolean(v?.type === 'upload' && v.value)} className={classes.dropContent}>
            <SC.StyledDropZone
                onDragOver={e => {
                    setIsOnDrag(true)
                }}
                onDragLeave={e => setIsOnDrag(false)}
            >
                {content}
            </SC.StyledDropZone>
        </UploadClickDropZone>
    )
}
