import type { BaseModalProps } from '@byecode/ui'
import { Box, DesktopModal, Flex, Text } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const StyledModal = styled(DesktopModal)`
    .byecode-Modal-modal {
        border: none;
    }
    .byecode-Modal-body {
        padding: 0;
    }
`

const LeftPanel = styled.div`
    flex: 1;
    height: 400px;
    padding: 32px 24px;
    overflow: hidden;
`

export interface QuotaPromptData {
    key: string
    icon: React.ReactNode
    name: string
    value: React.ReactNode
}

interface QuotaPromptModalProps extends BaseModalProps {
    data: QuotaPromptData[]
    leftPanelProps?: React.ComponentPropsWithoutRef<'div'>
    rightTitle?: string
    rightActions?: React.ReactNode
}

/** 配额相关的样式弹窗 */
export const QuotaPromptModal = ({ leftPanelProps, rightTitle, rightActions, data, ...rest }: QuotaPromptModalProps) => {
    return (
        <StyledModal width={524} withCloseIcon={false} {...rest}>
            <Flex>
                <LeftPanel {...leftPanelProps} />

                <Flex styles={{ root: { flex: 1 } }} px={24} py={32} direction="column">
                    <Text weight={600} size={15} lineHeight="21px" color="#141823" mb={16}>
                        {rightTitle}
                    </Text>

                    <Flex direction="column" gap={8}>
                        {data.map(item => (
                            <Flex key={item.key} gap={4}>
                                {item.icon}
                                <Text size={12} lineHeight="20px" color="var(--color-gray-500)">
                                    {item.name}
                                </Text>
                                <Text weight={500} size={12} lineHeight="20px">
                                    {item.value}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>

                    <Box style={{ marginTop: 'auto' }}>{rightActions}</Box>
                </Flex>
            </Flex>
        </StyledModal>
    )
}
