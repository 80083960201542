import type { AppUser } from '@lighthouse/core'
import { AppUserStatus, DataSourceType } from '@lighthouse/core'
import type { AppDepartment, AppRole} from '@lighthouse/shared';
import {     DEPARTMENT_DATASOURCE,
    getFieldOptions,
    getTableIcon,
isStringArray ,
    ROLE_DATASOURCE,
    useAtomData,
    useEqualArrayValue,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

import { currentEnvIdAtom } from '@/atoms/application/state'
import {
    currentDataSourceIdAtom,
    dataSourceAtomFamily,
    // dataSourceEnvIdAtom,
    dataSourcePoolAtom,
    recordAtomFamily,
    recordListAtomFamily,
    recordPoolAtom
} from '@/atoms/dataSource/state'

import { useCurrentAppID } from './useApplication'

export const useDataSourcePool = () => {
    return useAtomValue(dataSourcePoolAtom)
}

export const useDataSourceEnvId = () => {
    // return useAtomValue(dataSourceEnvIdAtom)
    return useAtomValue(currentEnvIdAtom)
}

export const useDataSource = (appId: string, envId: string, dsId: string) => {
    return useAtomValue(dataSourceAtomFamily({ appId, envId, dsId }))
}

export const useRecord = (appId: string, envId: string, dsId: string, recordId: string) => {
    return useAtomValue(recordAtomFamily({ appId, envId, dsId, recordId }))
}

export const useRecordList = () => {
    return useAtomValue(recordPoolAtom)
}

export const useCurrentDataSourceId = () => {
    return useAtomValue(currentDataSourceIdAtom)
}

export const useDataSourceRecordList = (appId: string, dsId: string) => {
    return useAtomData(
        recordPoolAtom,
        useCallback(draft => draft.filter(item => item.appId === appId && item.dsId === dsId), [appId, dsId])
    )
}

export const useDataSourceList = (appId: string, envId: string) => {
    return useAtomData(
        useMemo(
            () =>
                selectAtom(dataSourcePoolAtom, draft =>
                    draft.filter(dataSource => dataSource.appId === appId && dataSource.envId === envId)
                ),
            [appId, envId]
        )
    )
}

export const useNotAggregateDataSourceList = (appId: string, envId: string) => {
    return useAtomData(
        useMemo(
            () =>
                selectAtom(dataSourcePoolAtom, draft =>
                    draft.filter(
                        dataSource =>
                            dataSource.appId === appId &&
                            dataSource.envId === envId &&
                            dataSource.type !== DataSourceType.aggregateDataSource
                    )
                ),
            [appId, envId]
        )
    )
}

export const useDsListAndFieldOptions = (appId: string, envId: string, dsId: string) => {
    const dataSourceList = useDataSourceList(appId, envId)
    const dataSource = useDataSource(appId, envId, dsId)

    const fieldOptions = useMemo(() => getFieldOptions(dataSource), [dataSource])

    const dataSourceOptions = useMemo(() => {
        return dataSourceList.map(item => {
            const tableIcon = getTableIcon(item)
            return {
                label: item.name,
                value: item.id,
                icon: tableIcon,
                groupValue: item.appId,
                groupLabel: item.appName
            }
        })
    }, [dataSourceList])

    return { dataSourceOptions, fieldOptions, dataSource, dataSourceList }
}

/**
 * @description 获取数据源用户列表
 * @export
 * @return {*}
 */
export function useDataSourceUsers() {
    const appId = useCurrentAppID()
    const records = useAtomValue(recordListAtomFamily({ appId, dsId: USER_DATASOURCE }))

    const value = records
        .filter(record => {
            const status = record.content['STATUS']?.value
            return !(Array.isArray(status) && status?.[0] === AppUserStatus.REVIEWED)
        })
        .map(record => {
            const data = record.content
            return {
                uniqueUserId: record.id,
                departmentIds: data['DEPARTMENT']?.value || [],
                createdTime: data['createdTime'].value,
                email: data['EMAIL']?.value || '',
                mobile: data['MOBILE']?.value || '',
                roleId: data['ROLE']?.value || [],
                status: data['STATUS']?.value,
                userId: data['ID']?.value || '',
                avatar: isStringArray(data['AVATAR']?.value) ? data['AVATAR']?.value?.[0] ?? '' : '',
                username: data['USERNAME']?.value || '未命名用户',
                wechatOpenId: data['WECHAT_OPENID']?.value || ''
            } as AppUser
        })

    return useEqualArrayValue(value)
}

/**
 * @description 获取数据源角色列表
 * @export
 * @return {*}
 */
export function useDataSourceRoles() {
    const appId = useCurrentAppID()
    const records = useAtomValue(recordListAtomFamily({ appId, dsId: ROLE_DATASOURCE }))
    const value = records.map(record => {
        const data = record.content
        return {
            id: data['ID']?.value || '',
            name: data['ROLE_NAME']?.value || '未命名角色',
            uniqueRoleId: record.id
        } as AppRole
    })
    return useEqualArrayValue(value)
}
// RoleProtocols
/**
 * @description 获取数据源部门列表
 * @export
 * @return {*}
 */

export function useDataSourceDepartments() {
    const appId = useCurrentAppID()
    const records = useAtomValue(recordListAtomFamily({ appId, dsId: DEPARTMENT_DATASOURCE }))
    const value = records.map(record => {
        const data = record.content
        return {
            id: data['ID']?.value || '',
            name: data['DEPARTMENT_NAME']?.value || '未命名部门',
            uniqueDepartmentId: record.id,
            parentId: data['PARENT_DEPARTMENT']?.value || '',
            managerUserId: data['MANAGER_USER']?.value || ''
        } as AppDepartment
    })
    return useEqualArrayValue(value)
}
