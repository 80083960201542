import {
    Divider,
    flex,
    IconFont
} from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { RichTextContentProtocol } from '@lighthouse/core'
import { type PageAbstract } from '@lighthouse/core'
import {
    generateText,
    TagIcon,
    useAtomData
} from '@lighthouse/shared'
import { Collapse } from '@mantine/core'
import React, {
    useCallback,
    useMemo
} from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

import { ButtonConfigure } from './ButtonConfigure'
import * as SC from './styles'

export const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
`

const SCxListItemAction = styled.div`
    padding: 0 8px;
`

interface ButtonSettingProps {
    opened: boolean
    index?: number
    prefixName?: string
    listKeyName?: string
    disabled?: boolean
    allPages?: PageAbstract[]
    themeColor: string
    hideConfigs?: {
        fillWay?: boolean
    }
    isViewOption?: boolean
    onChangeOpen: (opened: boolean) => void
    onDelete: (id: string) => void
}

export const ButtonSettingItem: React.FunctionComponent<ButtonSettingProps> = ({
    opened,
    allPages,
    index,
    prefixName,
    listKeyName = 'list',
    hideConfigs,
    isViewOption,
    disabled,
    onChangeOpen,
    onDelete
}) => {
    const { control, watch } = useFormContext()
    const { name, id, events, action } = watch(`${prefixName}${listKeyName}.${index}`)

    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id
    })

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: active?.id === id ? 1 : 0
        }),
        [active?.id, id, transform, transition]
    )
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )

    const {
        prev,
        curr: { recordId, variableSourceType, datasource }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const renderLabel = useVariableValueRender(prev.recordId, recordId)
    const handleRenderTitle = useCallback(
        (v: RichTextContentProtocol) => {
            return generateText(v, { variable: { renderLabel: v => renderLabel(v.attrs.value, {}) } })
        },
        [renderLabel]
    )

    return (
        <SC.ButtonContainer {...attributes} style={style} ref={setNodeRef}>
            <SC.Header>
                <SC.LeftFill>
                    <SC.Handle {...listeners} style={{ cursor: 'grab' }}>
                        <IconFont size={16} type="DotsSix" color="var(--color-gray-500)" />
                    </SC.Handle>
                    <SC.Title> {handleRenderTitle(name)}</SC.Title>
                </SC.LeftFill>
                <SCxRightFill style={{ width: 'auto' }}>
                    <TagIcon icon="Trash" iconColor="var(--color-gray-500)" onClick={() => onDelete?.(id)} />
                    <IconFont
                        size={16}
                        color="var(--color-gray-500)"
                        type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                        onClick={() => onChangeOpen(!opened)}
                    />
                </SCxRightFill>
            </SC.Header>
            <Collapse in={opened}>
                <SC.Content>
                    <ButtonConfigure hideConfigs={hideConfigs} prefixName={prefixName} listKeyName={listKeyName} index={index} />
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <SCxListItemAction>
                        <ActionAdder
                            index={`${index}`}
                            prefixName={prefixName}
                            listKeyName={listKeyName}
                            title={handleRenderTitle(name)}
                            allPages={allPages}
                            events={events}
                            action={action}
                            enableViewVariable={isViewOption}
                        />
                    </SCxListItemAction>
                </SC.Content>
            </Collapse>
        </SC.ButtonContainer>
    )
}
