import type { Brand, SpaceVersion } from '@lighthouse/core'

import { getBrand } from '../utils'

export enum spaceVersionEnum {
    FREE,
    BASIC,
    STANDARD,
    ADVANCED,
    PROFESSIONAL,
    ENTERPRISE,
    ULTIMATE
}

export const spaceGradeNameMap: Record<SpaceVersion, string> = {
    ULTIMATE: '旗舰版',
    ENTERPRISE: '企业版',
    PROFESSIONAL: '专业版',
    ADVANCED: '高级版',
    STANDARD: '标准版',
    BASIC: '基础版',
    FREE: '免费版',
    I_CLICK_ULTIMATE: '旗舰版',
    I_CLICK_ENTERPRISE: '企业版',
    I_CLICK_PROFESSIONAL: '专业版',
    I_CLICK_ADVANCED: '高级版',
    I_CLICK_STANDARD: '标准版',
    I_CLICK_BASIC: '基础版',
    I_CLICK_FREE: '免费版'
}
export const spaceGradeIconMap: Record<SpaceVersion, string> = {
    ULTIMATE: 'CrownStar',
    ENTERPRISE: 'Flag',
    PROFESSIONAL: 'Earth',
    ADVANCED: 'Publish',
    STANDARD: 'Favourite',
    BASIC: 'EmojiFunnyCircle',
    FREE: 'EmojiFunnyCircle',
    I_CLICK_ULTIMATE: 'CrownStar',
    I_CLICK_ENTERPRISE: 'Flag',
    I_CLICK_PROFESSIONAL: 'Earth',
    I_CLICK_ADVANCED: 'Publish',
    I_CLICK_STANDARD: 'Favourite',
    I_CLICK_BASIC: 'EmojiFunnyCircle',
    I_CLICK_FREE: 'EmojiFunnyCircle'
}

export const spaceGradeCodeMap: Record<SpaceVersion, spaceVersionEnum> = {
    ULTIMATE: 6,
    ENTERPRISE: 5,
    PROFESSIONAL: 4,
    ADVANCED: 3,
    STANDARD: 2,
    BASIC: 1,
    FREE: 0,

    I_CLICK_ULTIMATE: 6,
    I_CLICK_ENTERPRISE: 5,
    I_CLICK_PROFESSIONAL: 4,
    I_CLICK_ADVANCED: 3,
    I_CLICK_STANDARD: 2,
    I_CLICK_BASIC: 1,
    I_CLICK_FREE: 0
}

export const DEFAULT_BYECODE_SPACE_VERSION: SpaceVersion = 'FREE'

export const DEFAULT_I_CLICK_SPACE_VERSION: SpaceVersion = 'I_CLICK_FREE'

const defaultBrandMap: Record<Brand, SpaceVersion> = { BYE_CODE: 'FREE', I_CLICK: 'I_CLICK_FREE' }

export const DEFAULT_SPACE_VERSION: SpaceVersion = defaultBrandMap[getBrand()]
