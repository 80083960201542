import { Flex, Group, IconFont, Input, Popover, Slider, Text } from '@byecode/ui'
import type { BackgroundNoImageProtocol, Field, VariableADTvalue } from '@lighthouse/core'
import { type ContainerBlockConfig, BACKGROUND_TYPE, EDGE_MODE } from '@lighthouse/core'
import {
    FilledInput,
    FillPickerPopoverDropdown,
    getPageDataOptions,
    getUserDatasourceOptions,
    getViewOptions,
    ListItem4ByecodeUi,
    PopoverDropdownWithClose,
    ShadowController,
    useAtomData,
    USER_DATASOURCE,
    VEINS_OPTIONS,
    VeinsController
} from '@lighthouse/shared'
import chroma from 'chroma-js'
import React, { useCallback, useId, useMemo } from 'react'
import type { FieldPath } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import { applicationThemeColorAtom } from '@/atoms/application/state'
import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { uploadManagerInAppParams } from '@/utils/auth'
import { transform2Integer } from '@/utils/number'

import type { DesignSettingProps } from './types'

interface StyleSettingProps extends DesignSettingProps {
    beforeSlot?: React.ReactNode
    afterSlot?: React.ReactNode
    prefixPath?: string
    customViewDsId?: string
}

function combinePath<T extends FieldPath<ContainerBlockConfig>>(path1: string | undefined, path2: T): T {
    return (path1 ? `${path1}.${path2}` : path2) as T
}

export const StyleSetting = ({ blockId, customViewDsId, prefixPath, applyTarget, beforeSlot, afterSlot }: StyleSettingProps) => {
    const { control } = useFormContext<ContainerBlockConfig>()

    return (
        <Group label="样式">
            {beforeSlot}

            {applyTarget !== 'page' && (
                <>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <div>圆角</div>
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.radius.0')}
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<IconFont type="RadiusLeftTop" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.radius.1')}
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<IconFont type="RadiusRightTop" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="flex-end">
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.radius.2')}
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<IconFont type="RadiusLeftBottom" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.radius.3')}
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<IconFont type="RadiusRightBottom" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                </>
            )}

            <BackgroundField prefixPath={prefixPath} blockId={blockId} customViewDsId={customViewDsId} />

            <VeinsField prefixPath={prefixPath} />

            {applyTarget !== 'page' && (
                <>
                    <BorderField prefixPath={prefixPath} />
                    <ShadowField prefixPath={prefixPath} />
                </>
            )}

            {applyTarget === 'container' && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>背景模糊</Text>
                    <Controller
                        control={control}
                        name="design.blur"
                        render={({ field }) => <Slider style={{ width: 180 }} min={0} max={100} step={1} {...field} />}
                    />
                </ListItem4ByecodeUi>
            )}

            {afterSlot}
        </Group>
    )
}

const filterField = (field: Field) => field.type === 'file'
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

const BackgroundField = ({ prefixPath, blockId, customViewDsId }: { prefixPath?: string; blockId: string; customViewDsId?: string }) => {
    const { control } = useFormContext<ContainerBlockConfig>()

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { recordId: parentRecordId } = prev
    const { recordId, page, variableSourceType, datasource } = curr
    const renderLabel = useVariableValueRender(parentRecordId, recordId)

    const uploadId = useId()
    const uploadProps = useMemo(
        () => ({
            accept: 'image/*',
            multiple: false,
            uploadOptions: {
                info: { id: uploadId, label: page?.name ?? '', groupId: pageId },
                options: {
                    ...uploadManagerInAppParams()
                }
            }
        }),
        [page?.name, pageId, uploadId]
    )

    const fileOptions = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])

    const userImgOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: filterField
            }),
        [dataSourceList]
    )

    const { customViewOption: customViewParentOption } = useVariableCustomViewOption(filterField)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const customViewSelfDataSource = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: customViewDsId || '' }))

    const customViewSelfOption = useMemo(
        () =>
            customViewSelfDataSource &&
            getViewOptions({ dataSource: customViewSelfDataSource, viewType: 'custom', validateFieldType: filterField }),
        [customViewSelfDataSource]
    )
    const customViewOption = customViewSelfOption ?? customViewParentOption

    const colorSystemMethods = useColorSystemAction()

    const getImageVariableUrl = useCallback((value: VariableADTvalue) => renderLabel(value, { useFileUrl: true }), [renderLabel])

    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <Text>背景</Text>
            <Controller
                control={control}
                name={combinePath(prefixPath, 'design.background')}
                render={({ field }) => (
                    <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                        <Popover.Target>
                            <FilledInput
                                value={field.value}
                                getImageVariableUrl={getImageVariableUrl}
                                options={fileOptions}
                                userOption={userImgOption}
                                viewOption={customViewOption}
                                onClear={() => {
                                    field.onChange({ type: BACKGROUND_TYPE.color })
                                }}
                                style={{ width: 180 }}
                            />
                        </Popover.Target>
                        {/* <PopoverDropdownWithClose title="背景">
                            <FillPicker
                                value={field.value}
                                onChange={field.onChange}
                                enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                                options={fileOptions}
                                uploadProps={uploadProps}
                                userOption={userImgOption}
                            />
                        </PopoverDropdownWithClose> */}
                        <FillPickerPopoverDropdown
                            title="背景"
                            value={field.value}
                            onChange={field.onChange}
                            enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                            options={fileOptions}
                            uploadProps={uploadProps}
                            userOption={userImgOption}
                            viewOption={customViewOption}
                            {...colorSystemMethods}
                        />
                    </Popover>
                )}
            />
        </ListItem4ByecodeUi>
    )
}

const VeinsField = ({ prefixPath }: { prefixPath?: string }) => {
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()
    const themeColor = useAtomData(applicationThemeColorAtom)
    const colorSystemMethods = useColorSystemAction()

    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <Text>纹理</Text>
            <Popover
                width={300}
                position="left-start"
                withinPortal
                autoUpdate={false}
                offsetOptions={168}
                onOpen={() => {
                    const veinsValue = getValues(combinePath(prefixPath, 'design.veins.value'))
                    if (!veinsValue) {
                        setValue(combinePath(prefixPath, 'design.veins'), {
                            color: themeColor && chroma(themeColor).alpha(0.1).hex(),
                            value: VEINS_OPTIONS[0].value,
                            strokeWidth: 2,
                            size: 50
                        })
                    }
                }}
            >
                <Controller
                    control={control}
                    name={combinePath(prefixPath, 'design.veins')}
                    render={({ field }) => (
                        <Popover.Target>
                            <FilledInput
                                value={{ type: BACKGROUND_TYPE.color, color: field.value?.color }}
                                onClear={() => {
                                    setValue(combinePath(prefixPath, 'design.veins'), {
                                        color: '',
                                        value: '',
                                        strokeWidth: 1,
                                        size: 1
                                    })
                                }}
                                style={{ width: 180 }}
                            />
                        </Popover.Target>
                    )}
                />
                <PopoverDropdownWithClose title="纹理">
                    <Controller
                        control={control}
                        name={combinePath(prefixPath, 'design.veins')}
                        render={({ field }) => <VeinsController {...field} {...colorSystemMethods} />}
                    />
                </PopoverDropdownWithClose>
            </Popover>
        </ListItem4ByecodeUi>
    )
}

const BorderField = ({ prefixPath }: { prefixPath?: string }) => {
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()

    const borderColor = getValues(combinePath(prefixPath, 'design.border.color'))

    const colorSystemMethods = useColorSystemAction()

    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>描边</Text>
                <Controller
                    control={control}
                    name={combinePath(prefixPath, 'design.border')}
                    render={({ field }) => (
                        <Popover
                            width={300}
                            position="left-start"
                            withinPortal
                            autoUpdate={false}
                            offsetOptions={168}
                            onOpen={() => {
                                const borderValue = getValues(combinePath(prefixPath, 'design.border.color'))
                                if (!borderValue) {
                                    setValue(combinePath(prefixPath, 'design.border'), {
                                        type: BACKGROUND_TYPE.color,
                                        color: '#E4E7EC',
                                        mode: EDGE_MODE.each,
                                        each: [1, 1, 1, 1]
                                    })
                                }
                            }}
                        >
                            <Popover.Target>
                                <FilledInput
                                    value={field.value}
                                    onClear={() => {
                                        field.onChange({ mode: EDGE_MODE.each })
                                    }}
                                    style={{ width: 180 }}
                                />
                            </Popover.Target>

                            {/* <PopoverDropdownWithClose title="填充">
                                <FillPicker
                                    value={field.value}
                                    onChange={v => {
                                        field.onChange({
                                            ...(v as BackgroundNoImageProtocol),
                                            mode: field.value?.mode || EDGE_MODE.each,
                                            each: field.value?.each || [1, 1, 1, 1],
                                            all: field.value?.all || 1
                                        })
                                    }}
                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                />
                            </PopoverDropdownWithClose> */}
                            <FillPickerPopoverDropdown
                                title="填充"
                                value={field.value}
                                onChange={v => {
                                    field.onChange({
                                        ...(v as BackgroundNoImageProtocol),
                                        mode: field.value?.mode || EDGE_MODE.each,
                                        each: field.value?.each || [1, 1, 1, 1],
                                        all: field.value?.all || 1
                                    })
                                }}
                                enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                {...colorSystemMethods}
                            />
                        </Popover>
                    )}
                />
            </ListItem4ByecodeUi>
            {borderColor && (
                <>
                    <ListItem4ByecodeUi justifyContent="flex-end">
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.border.each.0')}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        prefix={<IconFont type="LeftStroke" />}
                                        placeholder=""
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.border.each.1')}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        prefix={<IconFont type="TopStroke" />}
                                        placeholder=""
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="flex-end">
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.border.each.2')}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        prefix={<IconFont type="RightStroke" />}
                                        placeholder=""
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name={combinePath(prefixPath, 'design.border.each.3')}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        prefix={<IconFont type="BottomStroke" />}
                                        placeholder=""
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                </>
            )}
        </>
    )
}

const ShadowField = ({ prefixPath }: { prefixPath?: string }) => {
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()
    const colorSystemMethods = useColorSystemAction()

    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <Text>阴影</Text>
            <Popover
                width={300}
                position="left-start"
                withinPortal
                autoUpdate={false}
                offsetOptions={168}
                onOpen={() => {
                    const shadowValue = getValues(combinePath(prefixPath, 'design.shadow.color'))
                    if (!shadowValue) {
                        setValue(combinePath(prefixPath, 'design.shadow'), {
                            color: '#00000040',
                            x: 0,
                            y: 4,
                            blur: 4,
                            diffusion: 4
                        })
                    }
                }}
            >
                <Controller
                    control={control}
                    name={combinePath(prefixPath, 'design.shadow')}
                    render={({ field }) => (
                        <Popover.Target>
                            <FilledInput
                                value={{ type: BACKGROUND_TYPE.color, color: field.value?.color }}
                                onClear={() => {
                                    setValue(combinePath(prefixPath, 'design.shadow'), {
                                        color: '',
                                        x: 0,
                                        y: 0,
                                        blur: 0,
                                        diffusion: 0
                                    })
                                }}
                                style={{ width: 180 }}
                            />
                        </Popover.Target>
                    )}
                />

                <PopoverDropdownWithClose title="阴影">
                    <Controller
                        control={control}
                        name={combinePath(prefixPath, 'design.shadow')}
                        render={({ field }) => <ShadowController {...field} {...colorSystemMethods} />}
                    />
                </PopoverDropdownWithClose>
            </Popover>
        </ListItem4ByecodeUi>
    )
}
