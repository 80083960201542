import { Flex, Group, IconFont, Text, useDisclosure } from '@byecode/ui'
import type { PageAbstract } from '@lighthouse/core'
import { getTableIcon, getTableIconColor } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useEffect, useMemo, useState } from 'react'

import type { CreatePagePayload, MovePageAtomPayload } from '@/atoms/page/types'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { CreatePage } from '../../CreatePage'
import { getTreePageList } from '../../helper'
import type { PageItemTree } from '../../types'
import { DragList } from './DragList'

interface PageGroupListProps {
    tree: PageItemTree
    level: number
    focusPage?: string
    activeId?: string
    onUpdatePage?: (param: Pick<PageAbstract, 'name' | 'id' | 'open' | 'isHome'>) => Promise<boolean>
    onSetHomePage?: (param: Pick<PageAbstract, 'id' | 'isHome'>) => Promise<boolean>
    onRemovePage?: (id: string) => void
    onToLink?: (page: PageAbstract) => void
    onChangeEdit: (id: string) => void
    onCopyPage: (id: string, lang: string) => Promise<string>
    onMovePage: (params: MovePageAtomPayload) => void
    onAdd: (v: CreatePagePayload) => void
}

export const PageGroupList: React.FC<PageGroupListProps> = ({ tree, level, onAdd, ...rest }) => {
    const { activeId } = rest
    const [opened, setOpened] = useState(true)
    const [isHover, setIsHover] = useState(false)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const disabledWithVersion = useIsDisabledWithVersion()
    const dataSourceList = useDataSourceList(appId, envId)

    const [dsIcon, dsIconColor] = useMemo(() => {
        const ds = find(ds => ds.id === tree.id, dataSourceList)
        return [getTableIcon(ds), getTableIconColor(ds)]
    }, [dataSourceList, tree.id])

    useEffect(() => {
        const flatPageList = [...(tree.data ?? []), ...(tree?.children ?? []).flatMap(item => item.data)]
        if (flatPageList.some(item => item?.id === activeId)) {
            setOpened(true)
        }
    }, [activeId, tree?.children, tree.data])


    const childPageList = useMemo(() => getTreePageList(tree, []) ,[tree])

    if (childPageList.length === 0) {
        return null
    }

    return (
        <Group
            opened={opened}
            onCollapseChange={setOpened}
            label={
                <Flex style={{ paddingLeft: 16 * level }} gap={6}>
                    <IconFont
                        type="ArrowDownSmall"
                        size={16}
                        color="var(--color-gray-500)"
                        style={{
                            transform: opened ? undefined : 'rotate(-90deg)',
                            transition: 'transform 0.2s'
                        }}
                    />
                    <IconFont type={dsIcon} size={16} color={dsIconColor} />
                    <Text weight={400} color="var(--color-gray-700)">
                        {tree.name}
                    </Text>
                </Flex>
            }
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            renderRightIcon={() =>
                !disabledWithVersion && isHover ? (
                    <CreatePage
                        disableSelectDataSource
                        createType="other"
                        dsId={tree.id}
                        withinPortal={false}
                        onAdd={v => onAdd({ ...v, dsId: tree.id, name: tree.name })}
                    />
                ) : null
            }
            styles={{
                root: {
                    padding: `0!important`
                },
                wrapper: {
                    padding: '0!important',
                    height: '36px!important'
                },
                collapse: {
                    padding: '0!important'
                }
            }}
        >
            <DragList list={tree.data ?? []} level={level} {...rest} />
            {tree.children &&
                tree.children.length > 0 &&
                tree.children.map(item => <PageGroupList tree={item} key={item.id} level={level + 1} onAdd={onAdd} {...rest} />)}
        </Group>
    )
}
