import { Toast } from '@byecode/ui'
import { Box } from '@byecode/ui/components/Box'
import { Button } from '@byecode/ui/components/Button'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Input } from '@byecode/ui/components/Input'
import { Modal } from '@byecode/ui/components/Modal'
import { Radio } from '@byecode/ui/components/Radio'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { BlockAbstract, ContainerBlockConfig, ContainerView, PageNode } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { current } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone } from 'rambda'
import React, { useCallback, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { createBlockAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageBlocksAtom, pageNodesAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { findPageNodeById } from '@/containers/PageDetail/PageContentV2/utils'
import * as srv from '@/services'

interface Props {
    containerId: string
}

export const ViewListSettings = ({ containerId }: Props) => {
    const { control } = useFormContext<ContainerBlockConfig>()
    const { fields, append, insert, move, remove } = useFieldArray({ control, name: 'viewList', keyName: 'key' })

    const [activeId, setActiveId] = useState<string>()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )
    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                move(activeIndex, overIndex)
            }
        }
        setActiveId(undefined)
    }

    const { pageId = '', stackId = '', rootPageId = '' } = useAtomData(lastPageOfStackAtom) ?? {}
    const componentRuntimeState = useAtomData(
        pageStackAtom,
        useCallback(
            draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    return stack.blockRuntimeState.container?.[containerId]
                }
            },
            [containerId, rootPageId, stackId]
        )
    )

    const { run: setPageNodes } = useAtomAction(pageNodesAtom)

    const countRef = useRef(fields.length)

    const onCreate = () => {
        const id = nanoid()
        const key = (++countRef.current).toString().padStart(2, '0')
        append({ id, name: `面板 ${key}` })

        setPageNodes(draft => {
            const draftPageNodes = draft[pageId]
            if (!draftPageNodes) {
                return
            }
            const currentPageNode = findPageNodeById(containerId)(draftPageNodes)
            if (!currentPageNode) {
                return
            }

            currentPageNode.children?.push({ id, width: 0, children: [] })
            srv.updateNodes({ id: pageId, nodes: current(draftPageNodes) })
        })
    }

    const onDelete = (index: number) => {
        if (fields.length === 1) {
            Toast.warning('不可删除，至少需要1个面板')
            return
        }

        const deleteId = fields[index].id

        setPageNodes(draft => {
            const draftPageNodes = draft[pageId]
            if (!draftPageNodes) {
                return
            }

            const currentPageNode = findPageNodeById(containerId)(draftPageNodes)
            if (!currentPageNode || !currentPageNode.children) {
                return
            }

            const inNodesIndex = currentPageNode.children.findIndex(item => item.id === deleteId)
            if (inNodesIndex === -1) {
                return
            }
            currentPageNode.children?.splice(inNodesIndex, 1)
            srv.updateNodes({ id: pageId, nodes: current(draftPageNodes) })
        })

        remove(index)
        if (deleteId === componentRuntimeState?.currentView) {
            const firstField = fields.find(item => item.id !== deleteId)
            handleViewChange(firstField?.id || '')
        }
    }

    const { run: createBlock } = useAtomAction(createBlockAtom)

    const onDuplicate = useAtomCallback((get, set, index: number) => {
        const blocks = get(pageBlocksAtom)[pageId] || []
        const newBlocks: BlockAbstract[] = []

        // 递归复制容器内的子元素
        const recursionCopyPanelChildren = (copiedChildren: PageNode[]): PageNode[] => {
            return copiedChildren
                .map(copiedNode => {
                    const oldBlock = blocks.find(block => block.id === copiedNode.id)
                    if (!oldBlock) {
                        return false
                    }

                    const id = `${oldBlock.type}-${nanoid(12)}`

                    if (copiedNode.children) {
                        // 如果是空白容器，需要复制view信息
                        if (oldBlock.type === BlockType.container) {
                            const viewIdMap = Object.fromEntries(oldBlock.config.viewList.map(item => [item.id, nanoid()]))
                            newBlocks.push({
                                ...clone(oldBlock),
                                id,
                                config: {
                                    ...clone(oldBlock.config),
                                    viewList: oldBlock.config.viewList.map(item => ({
                                        ...item,
                                        id: viewIdMap[item.id]
                                    }))
                                }
                            })
                            return {
                                ...copiedNode,
                                id,
                                children: copiedNode.children.map(view => {
                                    return {
                                        ...view,
                                        id: viewIdMap[view.id],
                                        children: recursionCopyPanelChildren(view.children || []).filter(Boolean)
                                    }
                                })
                            }
                        }
                        newBlocks.push({ ...clone(oldBlock), id })
                        return {
                            ...copiedNode,
                            id,
                            children: recursionCopyPanelChildren(copiedNode.children || []).filter(Boolean)
                        }
                    }

                    newBlocks.push({ ...clone(oldBlock), id })
                    return { ...copiedNode, id }
                })
                .filter(Boolean) as PageNode[]
        }

        const newId = nanoid()
        insert(index + 1, { id: newId, name: `${fields[index].name}-副本` })

        setPageNodes(draft => {
            const pageNodes = draft[pageId]
            if (!pageNodes) {
                return
            }

            const currentPageNode = findPageNodeById(containerId)(pageNodes)
            if (!currentPageNode || !currentPageNode.children) {
                return
            }

            const beCopyPanel = currentPageNode.children.find(item => item.id === fields[index].id)

            const newPanel = { id: newId, width: 0, children: recursionCopyPanelChildren(beCopyPanel?.children || []) }

            currentPageNode.children.splice(index, 0, newPanel)
            srv.updateNodes({ id: pageId, nodes: current(pageNodes) })
            createBlock({ rootPageId, pageId, stackId, blocks: newBlocks, autoSelect: false })
        })
    })

    const handleViewChange = useAtomCallback<void, string[]>((_, set, view) => {
        set(pageStackAtom, draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.blockRuntimeState.container = {
                    ...stack.blockRuntimeState.container,
                    [containerId]: {
                        ...stack.blockRuntimeState.container?.[containerId],
                        currentView: view
                    }
                }
            }
        })
    })

    return (
        <Box py={8}>
            <div style={{ overflow: 'auto' }}>
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(undefined)}
                >
                    <SortableContext items={fields.map(item => item.id)} strategy={verticalListSortingStrategy}>
                        <Radio.Group
                            name="test"
                            value={componentRuntimeState?.currentView}
                            onChange={handleViewChange}
                            style={{ flexDirection: 'column' }}
                        >
                            {fields.map((item, index) => (
                                <SortableItem
                                    key={item.id}
                                    disabled={fields.length <= 1}
                                    index={index}
                                    data={item}
                                    onDelete={async i => {
                                        const isConfirm = await Modal.confirm({
                                            title: '确认删除',
                                            content: '删除的面板内容不可恢复'
                                        })
                                        if (!isConfirm) {
                                            return
                                        }
                                        onDelete(i)
                                    }}
                                    onDuplicate={onDuplicate}
                                />
                            ))}
                        </Radio.Group>
                    </SortableContext>
                </DndContext>
            </div>
            <Button radius={100} icon={<IconFont type="Add" />} onClick={onCreate} style={{ marginTop: 12 }}>
                添加
            </Button>
        </Box>
    )
}

const ActionButton = styled(Button)`
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 4px;
    color: var(--color-gray-400);
    &:disabled {
        color: var(--color-gray-300);
    }
    &:not(:disabled):hover {
        cursor: pointer;
        background-color: #26415a0f;
    }
`

interface SortableItemProps {
    index: number
    data: ContainerView
    onDelete: (index: number) => void
    onDuplicate: (index: number) => void
    disabled?: boolean
}
const SortableItem = ({ index, data, onDelete, onDuplicate, disabled }: SortableItemProps) => {
    const { setNodeRef, setActivatorNodeRef, listeners, attributes, transform, transition } = useSortable({
        id: data.id,
        attributes: { role: 'li' }
    })

    const { register } = useFormContext<ContainerBlockConfig>()

    const style: React.CSSProperties = {
        flex: 1,
        transform: CSS.Translate.toString(transform),
        transition
    }

    return (
        <Flex alignItems="center" gap="6px" ref={setNodeRef} style={style}>
            <ActionButton {...listeners} {...attributes} ref={setActivatorNodeRef}>
                <IconFont type="DotsSix" />
            </ActionButton>
            <Radio size="xs" value={data.id} />
            <Input {...register(`viewList.${index}.name`)} size="sm" styles={{ wrapper: { flex: 1 } }} />
            <ActionButton onClick={() => onDuplicate(index)}>
                <IconFont type="Duplicate" />
            </ActionButton>
            <ActionButton disabled={disabled} onClick={() => !disabled && onDelete(index)}>
                <IconFont type="Trash" />
            </ActionButton>
        </Flex>
    )
}
