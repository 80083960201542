import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IconFont } from '../IconFont'
import { Popover } from '../Popover'
import { ColorBoard } from './ColorBoard'
import { PREFERRED_COLORS, SYSTEM_COLORS } from './constants'
import * as SC from './styles'
import { getRecentColorsFromStorage, saveRecentColorsToStorage } from './utils'

export interface ColorPickerProps {
    noColorMode?: 'default' | 'none'
    showSystemColors?: boolean
    value?: string
    onChange?: (color: string) => void

    style?: React.CSSProperties
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ style, noColorMode = 'none', showSystemColors = true, value, onChange }) => {
    const [open, setOpen] = useState(false)
    const [recentColors, setRecentColors] = useState<string[]>([])
    const isDefaultChecked = value === '#383838'

    const noColorLabel =
        noColorMode === 'default' ? (
            <SC.DefaultColorCheckerWrapper>
                <SC.DefaultColorChecker>{isDefaultChecked && <IconFont type="Tick" size={16} />}</SC.DefaultColorChecker>
                默认
            </SC.DefaultColorCheckerWrapper>
        ) : (
            <>
                <SC.ColorPickerNoColor />
                无颜色
            </>
        )
    const noColorValue = noColorMode === 'default' ? '#383838' : ''

    const handleColorChange = useCallback(
        (color: string) => {
            const recentColors = saveRecentColorsToStorage(color)
            setRecentColors(recentColors)
            onChange?.(color)
        },
        [onChange]
    )

    useEffect(() => {
        const recentColors = getRecentColorsFromStorage()
        if (recentColors) {
            setRecentColors(recentColors)
        }
    }, [])

    const colorBoard = useMemo(() => <ColorBoard value={value} onChange={handleColorChange} />, [handleColorChange, value])

    return (
        <SC.ColorPickerContainer style={style}>
            <SC.ColorPickerHeader>
                <SC.ColorPickerNoColorWrapper onClick={() => onChange?.(noColorValue)}>{noColorLabel}</SC.ColorPickerNoColorWrapper>
            </SC.ColorPickerHeader>
            <SC.ColorPickerPreferredColorsWrapper>
                {PREFERRED_COLORS.map((preferredColors, row) => (
                    <SC.ColorPickerPreferredColorsRowWrapper key={preferredColors[0]} style={{ marginBottom: row === 0 ? 8 : 0 }}>
                        {preferredColors.map((preferredColor, col) => (
                            <SC.ColorPickerColorCard
                                key={preferredColor}
                                style={{
                                    backgroundColor: preferredColor,
                                    border: row === 1 && col === 0 ? '2px solid var(--color-gray-4)' : 'none'
                                }}
                                onClick={() => {
                                    handleColorChange(preferredColor)
                                }}
                            />
                        ))}
                    </SC.ColorPickerPreferredColorsRowWrapper>
                ))}
            </SC.ColorPickerPreferredColorsWrapper>
            <SC.Divider />
            {showSystemColors && (
                <>
                    <SC.ColorPickerRecentColorsWrapper>
                        <SC.ColorPickerRecentColorsTitle>主题单色</SC.ColorPickerRecentColorsTitle>
                        <SC.ColorPickerRecentColorsContent>
                            {SYSTEM_COLORS.map((preferredColor, col) => (
                                <SC.ColorPickerColorCard
                                    key={preferredColor}
                                    style={{
                                        backgroundColor: preferredColor
                                    }}
                                    onClick={() => {
                                        handleColorChange(preferredColor)
                                    }}
                                />
                            ))}
                        </SC.ColorPickerRecentColorsContent>
                    </SC.ColorPickerRecentColorsWrapper>
                    <SC.Divider />
                </>
            )}
            <SC.ColorPickerRecentColorsWrapper>
                <SC.ColorPickerRecentColorsTitle>最近使用</SC.ColorPickerRecentColorsTitle>
                <SC.ColorPickerRecentColorsContent>
                    {recentColors.map(recentColor => (
                        <SC.ColorPickerColorCard
                            key={recentColor}
                            style={{ backgroundColor: recentColor }}
                            onClick={() => {
                                onChange?.(recentColor)
                            }}
                        />
                    ))}
                </SC.ColorPickerRecentColorsContent>
            </SC.ColorPickerRecentColorsWrapper>

            <SC.Divider />

            <SC.ColorPickerPopover width={280} position="right-end" opened={open} onChange={setOpen}>
                <Popover.Target>
                    <SC.ColorPickerMoreWrapper>
                        <SC.ColorPickerPalette src="https://byecode-libs.oss-cn-shanghai.aliyuncs.com/libs/common/color_palette.png" />
                        <SC.ColorPickerMore>更多颜色</SC.ColorPickerMore>
                        <IconFont type="ArrowRightSmall" size={12} />
                    </SC.ColorPickerMoreWrapper>
                </Popover.Target>
                <SC.ColorPickerPopoverContent style={{ padding: 4 }} unstyled>
                    {colorBoard}
                </SC.ColorPickerPopoverContent>
            </SC.ColorPickerPopover>
        </SC.ColorPickerContainer>
    )
}
