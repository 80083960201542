import type { CascadeOption } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import {
    type Conditions,
    type DataSourceAbstract,
    type FieldCellValue,
    type FieldInputConfigProtocol,
    type FilterFormType,
    type InputValueItem,
    type PaginationProtocol,
    type RecordLikeProtocol,
    type RelativeSelectConfig,
    type SortableProtocol
} from '@lighthouse/core'
import type { RelativeOption } from '@lighthouse/shared'
import {
    CURRENT_USER,
    getCascadeOption,
    getFilterOfDeepBlockIds,
    isNumberValue,
    isStringArray,
    pageStackPubSub,
    transformFilterNormalFilter,
    useHandleAbortPrevious,
    USER_DATASOURCE
} from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import type { AnyObject } from 'immer/dist/internal'
import { clone, find, findIndex, pick, reduce } from 'rambda'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import useSWRMutation from 'swr/mutation'
import { debounce } from 'throttle-debounce'
import { useImmer } from 'use-immer'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import * as srv from '@/services'
import type { DataSourceAndRecordsResult } from '@/services/types'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSourceList } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'

type DataSourcePayload = {
    pagination: PaginationProtocol['pagination']
    search?: string
    // appId: string
    // envId: string
    dsId: string
    filter?: FilterFormType
    disabledFilter?: FilterFormType
    currentRecordId?: string
    parentRecordId?: string
    abort?: AbortController
} & SortableProtocol

type DataSourceOptionPayload = {
    dsId: string
    filter?: FilterFormType
    fieldId: string
    showFieldId: string
    currentRecordId?: string
    parentRecordId?: string
    disabledFilter?: FilterFormType
    isDebounce?: boolean
} & SortableProtocol

type RelativeDataSourceConfigItem = { config: RelativeSelectConfig; value?: FieldCellValue }

type FieldInputConfigItem = { config: FieldInputConfigProtocol; value?: FieldCellValue }

/** 获取关联数据源数据 */
// TODO: 后端不返回分页数据 请从参数中拿取
export function useFieldBlockMethods(fieldBlocks: FieldInputConfigItem[], fieldBlockValueMap?: Record<string, InputValueItem>) {
    const prevFieldBlockValueRef = useRef({})
    const initLoadRef = useRef(false)

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    // const { record: userRecord } = useUserRecord()
    const dataSourceList = useDataSourceList(appId, envId)
    const {
        curr: { recordId: currentRecordId },
        prev: { recordId: parentRecordId }
    } = usePageDataSourceForVariableSelector({ stackId, pageId })

    const { configList: newConfigList, blockIds } = useMemo(() => {
        return reduce<FieldInputConfigItem, { configList: RelativeDataSourceConfigItem[]; blockIds: string[] }>(
            ({ configList, blockIds }, item) => {
                const { config, value } = item
                if (config.inputType === 'relativeSelect') {
                    const { relativeSelect } = config
                    const newFilterBlockIds = getFilterOfDeepBlockIds(relativeSelect?.filter)
                    const newDisabledFilterBlockIds = getFilterOfDeepBlockIds(relativeSelect?.disabledFilter)
                    const newBlockIds = new Set([...blockIds, ...newFilterBlockIds, ...newDisabledFilterBlockIds])
                    const newConfig: RelativeDataSourceConfigItem = { config, value }
                    return { configList: [...configList, newConfig], blockIds: [...newBlockIds].map(String) }
                }
                return { configList, blockIds }
            },
            { configList: [], blockIds: [] },
            fieldBlocks ?? []
        )
    }, [fieldBlocks])

    const usedFieldInputValueMap = useMemo(() => {
        const newFieldInputValueMap = pick(blockIds, fieldBlockValueMap)
        if (equal(prevFieldBlockValueRef.current, newFieldInputValueMap)) {
            return prevFieldBlockValueRef.current
        }
        prevFieldBlockValueRef.current = newFieldInputValueMap
        return newFieldInputValueMap
    }, [blockIds, fieldBlockValueMap])

    const [relativeDataSource, setRelativeDataSource] = useImmer<{
        dataSourceList: DataSourceAbstract[]
        records: RecordLikeProtocol[]
        disabledRecords: RecordLikeProtocol[]
        userRecord?: RecordLikeProtocol
        options: RelativeOption[]
    }>({
        records: [],
        dataSourceList: [],
        disabledRecords: [],
        options: []
    })

    // useEffect(() => {
    //     setRelativeDataSource(draft => {
    //         draft.userRecord = userRecord
    //     })
    // }, [setRelativeDataSource, userRecord])

    const relativeSelectList = useMemo(() => newConfigList.map(item => item.config.relativeSelect), [newConfigList])

    const handleChange = useCallback(
        (params: DataSourcePayload, dataSource: DataSourceAndRecordsResult, count?: number) => {
            const { pagination, search, dsId, filter, sorts } = params
            const { datasource, records = [] } = dataSource ?? {}
            if (!datasource) {
                return
            }
            setRelativeDataSource(draft => {
                const newDataSource = clone(datasource)
                newDataSource.viewOptions.pagination = {
                    ...pagination,
                    rowTotal: count ?? 1
                }
                const isDataSourceExit = find(item => item.id === dsId && item.appId === appId, draft.dataSourceList)
                const newRecordIds = new Set(records.map(item => item.id))
                const newRecords = draft.records.filter(item => !newRecordIds.has(item.id))
                const mergedRecords = [...newRecords, ...records]
                // draft.records = userRecord ? [userRecord, ...mergedRecords] : mergedRecords
                draft.records = mergedRecords
                if (!isDataSourceExit) {
                    draft.dataSourceList.push(newDataSource)
                }
                const dataSourceIndex = findIndex(item => item.id === newDataSource.id, draft.dataSourceList)
                if (dataSourceIndex !== -1) {
                    draft.dataSourceList[dataSourceIndex].viewOptions.pagination = pagination
                }
            })
        },
        [appId, setRelativeDataSource]
    )

    const { trigger: getInitRecords } = useSWRMutation(`getDs-init-records`, async (_, { arg }: { arg: DataSourcePayload }) => {
        const { pagination, search, dsId, filter, sorts, currentRecordId, parentRecordId } = arg
        const data = await srv.getDs({
            appId,
            envId,
            dsId,
            pagination,
            search,
            filter,
            sorts,
            currentRecordId,
            parentRecordId
        })
        if (!data) {
            return
        }
        handleChange(arg, data)
        return data
    })

    const { trigger: getDsSwr } = useSWRMutation(`getDs-fetch`, async (_, { arg }: { arg: DataSourcePayload }) => {
        const { pagination, search, dsId, filter, sorts, currentRecordId, parentRecordId, abort, disabledFilter } = arg
        const data = await srv.getDs({
            appId,
            envId,
            dsId,
            pagination,
            search,
            filter,
            sorts,
            currentRecordId,
            parentRecordId,
            disabledFilter,
            abort
        })
        if (!data) {
            return
        }
        handleChange(arg, data)
        return data
    })

    const { trigger: getDsOption } = useSWRMutation(`getDs-options`, async (_, { arg }: { arg: DataSourceOptionPayload }) => {
        const { fieldId, dsId, filter, sorts, currentRecordId, parentRecordId, showFieldId, disabledFilter } = arg
        const data = await srv.getRelativeOptions({
            envId,
            dsId,
            fieldId,
            filter,
            currentRecordId,
            parentRecordId,
            sorts,
            disabledFilter,
            showFieldId
        })
        setRelativeDataSource(draft => {
            draft.options = data
        })
        return data
    })

    const { mutation: updateDataSource } = useHandleAbortPrevious(getDsSwr)

    const debounceGetDsOption = useMemo(() => debounce(1000, getDsOption), [getDsOption])

    const { trigger: onLoadMoreData } = useSWRMutation(`getDs-loadMore`, async (_, { arg }: { arg: DataSourcePayload }) => {
        const { pagination, search, dsId, filter, sorts, currentRecordId, parentRecordId } = arg
        const dataSource = await srv.getDs({
            appId,
            envId,
            dsId,
            pagination,
            search,
            filter,
            sorts,
            currentRecordId,
            parentRecordId
        })
        if (!dataSource) {
            return
        }
        setRelativeDataSource(draft => {
            const index = findIndex(dataSource => dataSource.id === dsId && dataSource.appId === appId, draft.dataSourceList)
            if (index !== -1) {
                draft.dataSourceList[index].viewOptions.pagination.currentPage = pagination.currentPage
            }
            dataSource.records.forEach(record => {
                draft.records.push(record)
            })
        })
        return dataSource.records
    })

    const handleFetchDataDataSource = useCallback(
        (params: Omit<DataSourcePayload, 'appId'>) => {
            const { dsId } = params
            const relativeSelect = find(item => item?.relativePointer === dsId, relativeSelectList)
            if (!dsId || !relativeSelect) {
                return Promise.resolve(undefined)
            }
            const { filter, sorts, disabledFilter } = relativeSelect
            return updateDataSource({
                sorts,
                currentRecordId,
                parentRecordId,
                ...params,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap)
            })
        },
        [relativeSelectList, updateDataSource, currentRecordId, parentRecordId, usedFieldInputValueMap]
    )

    const handleLoadMoreData = useCallback(
        (params: Omit<DataSourcePayload, 'appId'>) => {
            const { dsId } = params
            const relativeSelect = find(item => item.config.relativeSelect?.relativePointer === dsId, newConfigList)?.config.relativeSelect
            if (!dsId || !relativeSelect) {
                return Promise.resolve(undefined)
            }
            const { filter, sorts, disabledFilter } = relativeSelect
            return onLoadMoreData({
                sorts,
                currentRecordId,
                parentRecordId,
                ...params,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap)
            })
        },
        [newConfigList, onLoadMoreData, currentRecordId, parentRecordId, usedFieldInputValueMap]
    )

    const handleFetchDataSourceOptions = useCallback(
        (params: Omit<DataSourceOptionPayload, 'sorts'>) => {
            const { dsId, isDebounce } = params
            const relativeSelect = find(item => item.config.relativeSelect?.relativePointer === dsId, newConfigList)?.config.relativeSelect
            if (!dsId || !relativeSelect) {
                return Promise.resolve(undefined)
            }
            const { sorts, relativePointer } = relativeSelect
            setRelativeDataSource(draft => {
                const dataSource = find(ds => ds.id === relativePointer, dataSourceList)
                const isExit = find(ds => ds.id === relativePointer, draft.dataSourceList)
                if (!isExit && dataSource) {
                    draft.dataSourceList.push(dataSource)
                }
            })
            const reqParams = {
                sorts,
                currentRecordId,
                parentRecordId,
                ...params
            }
            initLoadRef.current = true
            return isDebounce ? debounceGetDsOption(reqParams) : getDsOption(reqParams)
        },
        [dataSourceList, debounceGetDsOption, getDsOption, newConfigList, currentRecordId, parentRecordId, setRelativeDataSource]
    )
    const handleFetchCascadeOptions = useCallback(
        async (params: {
            dsId: string
            sortFieldPointer: string
            fieldPointer: string
            showFieldPointer: string
            filter?: FilterFormType | undefined
            parentFieldPointer: string
            disableId?: string
        }): Promise<CascadeOption[] | undefined> => {
            const { dsId, parentFieldPointer, fieldPointer, sortFieldPointer, filter, showFieldPointer, disableId } = params
            const isEmptyConfig = !dsId || !parentFieldPointer || !fieldPointer
            if (isEmptyConfig) {
                return
            }
            const res = await srv.getDs({
                appId,
                envId,
                dsId,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                pagination: { currentPage: 1, pageSize: 3000 },
                currentRecordId,
                parentRecordId,
                sorts: [{ id: 'cascadeSortId', order: 'ASC', fieldId: sortFieldPointer }]
            })
            const { records = [] } = res ?? {}
            const usedRecords = records?.filter(record => record?.content?.[fieldPointer]?.value)

            return (
                usedRecords
                    ?.filter(record => {
                        const parentId = record?.content?.[parentFieldPointer]?.value
                        return isStringArray(parentId) ? !parentId?.[0] : !parentId
                    })
                    .map(record => getCascadeOption({ parentFieldPointer, fieldPointer, showFieldPointer, record, records, disableId }))
                    .filter(item => item.value) ?? []
            )
        },
        [appId, currentRecordId, envId, parentRecordId, usedFieldInputValueMap]
    )

    const handleFetchPersonOptions = useCallback(
        async (filter: FilterFormType): Promise<AppUser[]> => {
            const res = await srv.getDs({
                appId,
                envId,
                dsId: USER_DATASOURCE,
                filter,
                pagination: { currentPage: 1, pageSize: 20000 },
                currentRecordId,
                parentRecordId
            })
            if (res) {
                const { records } = res
                return records.map(r => {
                    const { content, id } = r
                    return {
                        userId: content['ID']?.value?.toString() ?? '',
                        username: content['USERNAME']?.value?.toString() ?? '',
                        uniqueUserId: id,
                        avatar: content['AVATAR']?.value?.toString() ?? '',
                        mobile: content['MOBILE']?.value?.toString() ?? ''
                    }
                })
            }
            return []
        },
        [appId, currentRecordId, envId, parentRecordId]
    )

    useEffect(() => {
        newConfigList.forEach(({ config: { relativeSelect, title }, value }) => {
            const { relativePointer, relativeFieldPointer } = relativeSelect ?? {}
            if (value === '' || value === undefined) {
                return
            }
            const initExpression: Conditions = reduce<string, AnyObject[]>(
                (preVal, curVal) => {
                    if (curVal === '') {
                        return preVal
                    }
                    const condition = {
                        idVariable: {
                            type: 'FIELD_ID',
                            fieldIdVariable: {
                                fieldId: relativeFieldPointer
                            }
                        },
                        operator: '=',
                        paramList: [
                            {
                                type: 'VALUE',
                                valueVariable: {
                                    value: curVal
                                }
                            }
                        ]
                    }
                    return [...preVal, condition]
                },
                [],
                (isNumberValue(value) || isStringArray(value) ? value.toString() : '')
                    ?.split(',')
                    .filter(item => item !== CURRENT_USER.userId) ?? []
            )
            if (initExpression.length === 0) {
                return
            }
            const initFilter: FilterFormType = {
                expression: {
                    conditions: initExpression,
                    where: 'OR'
                }
            }
            if (relativePointer) {
                getInitRecords({
                    dsId: relativePointer,
                    pagination: { currentPage: 1, pageSize: 50 },
                    filter: initFilter,
                    currentRecordId,
                    parentRecordId
                })
            }
        })
    }, [getInitRecords, newConfigList, currentRecordId, parentRecordId, appId])

    // 修改输入框配置，重新调用接口刷新
    useEffect(() => {
        newConfigList.forEach(({ config: { relativeSelect } }) => {
            const { relativePointer, relativeFieldPointer, relativeShowFieldPointer, filter, sorts, showMode, disabledFilter } =
                relativeSelect ?? {}
            if (relativeShowFieldPointer && relativePointer && showMode !== 'input' && relativeFieldPointer) {
                handleFetchDataSourceOptions({
                    dsId: relativePointer,
                    fieldId: relativeFieldPointer,
                    showFieldId: relativeShowFieldPointer,
                    filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                    disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap),
                    isDebounce: initLoadRef.current
                })
            }
        })
    }, [handleFetchDataSourceOptions, newConfigList, setRelativeDataSource, usedFieldInputValueMap])

    useEffect(() => {
        const { relativeSelect } = newConfigList[0]?.config ?? {}
        const { relativePointer, relativeFieldPointer, relativeShowFieldPointer, filter, disabledFilter } = relativeSelect ?? {}
        if (!relativePointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${relativePointer}-ADD`, (record?: RecordLikeProtocol) => {
            if (!relativePointer || !relativeFieldPointer || !relativeShowFieldPointer) {
                return
            }
            handleFetchDataSourceOptions({
                dsId: relativePointer,
                fieldId: relativeFieldPointer,
                showFieldId: relativeShowFieldPointer,
                filter: transformFilterNormalFilter(filter, usedFieldInputValueMap),
                disabledFilter: transformFilterNormalFilter(disabledFilter, usedFieldInputValueMap)
            })
        })
        return () => unSubscribe(subscribeId)
    }, [usedFieldInputValueMap, handleFetchDataSourceOptions, newConfigList])

    return useMemo(
        () => ({
            relativeDataSource,
            onFetchDataSource: handleFetchDataDataSource,
            onLoadMoreData: handleLoadMoreData,
            onFetchCascadeOptions: handleFetchCascadeOptions,
            onFetchPersonOptions: handleFetchPersonOptions
        }),
        [handleFetchCascadeOptions, handleFetchDataDataSource, handleFetchPersonOptions, handleLoadMoreData, relativeDataSource]
    )
}
