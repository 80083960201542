import { Button, IconFont, SegmentedControl, Select, Tag } from '@byecode/ui'
import type { Field, FieldBlockWithDsId, ViewType } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import type { ClickTriggerConfig, FlowNode, TriggerFlowNode, VariableSource } from '@lighthouse/shared'
import {
    canProvidedRecordInFlowNodeTypes,
    FieldIcon,
    getIdVariableData,
    getRealField,
    ParamsVariable,
    propertyFilter,
    useAtomData,
    VariableSourceType
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { AddFieldButton } from '../SettingFieldValue/AddFieldButton'

export interface RecordMatchFilterConfiguratorProps {
    prefixName?: string
    clickTriggerNode: TriggerFlowNode<ClickTriggerConfig>
    allParentNodes?: FlowNode[]
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    formDsId?: string
    viewType?: ViewType
    viewDsId?: string
}

const SCxRecordMatchFilterConfiguratorWrapper = styled.div`
    font-size: var(--font-size-normal);
`

const SCxRecordMatchFilterConfiguratorHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`

const SCxRecordMatchFilterConfiguratorContent = styled.div``

const SCxRecordMatchFilterConfiguratorCard = styled.div`
    border: 1px solid var(--color-gray-100);
    border-radius: 8px;
    padding: 8px 0;
    margin-bottom: 12px;
`

const SCxRecordMatchFilterConfiguratorCardHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 8px;
    font-size: var(--font-size-normal);
`

const SCxRecordMatchFilterConfiguratorCardContent = styled.div`
    padding: 0 12px;
`

const SCxRecordMatchFilterConfiguratorCardHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

const SCxRemoveButton = styled(Button)`
    opacity: 0;

    ${SCxRecordMatchFilterConfiguratorCardHeader}:hover & {
        opacity: 1;
    }
`

const SCxFieldText = styled.div`
    margin: 0 8px;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

function getFieldWithVariable(variableField: Field | undefined): Field | undefined {
    if (!variableField) {
        return
    }
    const field = getRealField(variableField)
    if (field?.type === 'notes') {
        return { ...field, type: 'text' }
    }
    return field
}

export const RecordMatchFilterConfigurator: React.FC<RecordMatchFilterConfiguratorProps> = ({
    prefixName,
    formDsId,
    clickTriggerNode,
    allParentNodes,
    fieldBlocksWithDsId,
    viewType,
    viewDsId
}) => {
    const { control } = useFormContext()
    const disabledWithVersion = useIsDisabledWithVersion()

    const { fields, append, remove } = useFieldArray({
        control,
        name: `${prefixName}.filter.expression.conditions`
    })

    const dsId = useWatch({
        control,
        name: `${prefixName}.dataSourceId`
    })

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dsId)
    const dataSourceList = useDataSourceList(appId, envId)
    const { type } = useFlow()

    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const paramsVariableSources = useMemo<VariableSource[]>(() => {
        if (!dataSource) {
            return []
        }

        const canProvidedRecordInFlowNodes = allParentNodes?.filter(node => canProvidedRecordInFlowNodeTypes.includes(node.data.nodeType))

        return [
            isInFlow && {
                sourceType: VariableSourceType.parentNode,
                parentNodes: canProvidedRecordInFlowNodes,
                dataSourceList
            },
            isNotAutomation && {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            isNotAutomation && {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ].filter(Boolean) as VariableSource[]
    }, [allParentNodes, curr.datasource, curr.page, dataSource, dataSourceList, isInFlow, isNotAutomation, prev.datasource, prev.page])

    return (
        <SCxRecordMatchFilterConfiguratorWrapper>
            <SCxRecordMatchFilterConfiguratorHeader>
                满足以下
                <Controller
                    name={`${prefixName}.filter.expression.where`}
                    defaultValue="OR"
                    render={({ field }) => (
                        <SegmentedControl
                            {...field}
                            data={[
                                { value: 'AND', label: '所有条件' },
                                { value: 'OR', label: '任一条件' }
                            ]}
                        />
                    )}
                />
            </SCxRecordMatchFilterConfiguratorHeader>

            <SCxRecordMatchFilterConfiguratorContent>
                {fields.map(({ id }, index) => (
                    <Controller
                        key={index}
                        name={`${prefixName}.filter.expression.conditions.${index}`}
                        render={({ field }) => {
                            const fieldValue = field.value.idVariable
                            const fieldId = fieldValue.fieldIdVariable?.fieldId || ''
                            const dataSourceField = dataSource?.schema[fieldId]

                            if (!dataSourceField) {
                                return (
                                    <SCxRecordMatchFilterConfiguratorCard>
                                        <SCxRecordMatchFilterConfiguratorCardHeader style={{ margin: 0}}>
                                            <SCxRecordMatchFilterConfiguratorCardHeaderLeft>
                                                <Tag background="var(--color-red-100)" color="var(--color-red-500)" radius={8}>
                                                    找不到字段
                                                </Tag>
                                            </SCxRecordMatchFilterConfiguratorCardHeaderLeft>
                                            <SCxRemoveButton
                                                type="text"
                                                size="xs"
                                                icon={<IconFont type="Close" />}
                                                onClick={() => remove(index)}
                                            />
                                        </SCxRecordMatchFilterConfiguratorCardHeader>
                                    </SCxRecordMatchFilterConfiguratorCard>
                                )
                            }

                            const { type, name, innerType } = dataSourceField
                            const operatorOptions = innerType ? propertyFilter[innerType] : []
                            const variableField = getIdVariableData({
                                idVariable: fieldValue,
                                dataSourceList,
                                sourceFlowNode: [clickTriggerNode],
                                dataSource
                            })
                            const realField = getFieldWithVariable(variableField)
                            return (
                                <SCxRecordMatchFilterConfiguratorCard>
                                    <SCxRecordMatchFilterConfiguratorCardHeader>
                                        <SCxRecordMatchFilterConfiguratorCardHeaderLeft>
                                            <FieldIcon id={id} type={type} />
                                            <SCxFieldText>{name}</SCxFieldText>
                                            <Controller
                                                name={`${prefixName}.filter.expression.conditions.${index}.operator`}
                                                render={({ field }) => (
                                                    <Select
                                                        size="sm"
                                                        options={operatorOptions}
                                                        styles={{ root: { width: 80 } }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </SCxRecordMatchFilterConfiguratorCardHeaderLeft>

                                        <SCxRemoveButton
                                            type="text"
                                            size="xs"
                                            icon={<IconFont type="Close" />}
                                            onClick={() => remove(index)}
                                        />
                                    </SCxRecordMatchFilterConfiguratorCardHeader>
                                    {realField && (
                                        <SCxRecordMatchFilterConfiguratorCardContent>
                                            <ParamsVariable
                                                disabled={disabledWithVersion}
                                                width="100%"
                                                field={realField}
                                                fieldBlocksWithDsId={fieldBlocksWithDsId}
                                                formDsId={formDsId}
                                                sources={paramsVariableSources}
                                                dataSourceList={dataSourceList}
                                                viewDsId={viewDsId}
                                                viewType={viewType}
                                                prefixName={`${prefixName}.filter.expression.conditions.${index}`}
                                            />
                                        </SCxRecordMatchFilterConfiguratorCardContent>
                                    )}
                                </SCxRecordMatchFilterConfiguratorCard>
                            )
                        }}
                    />
                ))}
                <AddFieldButton
                    isFilteredCannotCreatedField={false}
                    buttonText="查询表中的字段"
                    dataSource={dataSource}
                    dataSourceList={dataSourceList}
                    fieldIds={fields.map(
                        // @ts-expect-error to fix
                        item => (item.idVariable.type === VariableType.FIELD_ID ? item.idVariable.fieldIdVariable?.fieldId : '') ?? ''
                    )}
                    onItemClick={item => {
                        append({
                            idVariable: {
                                type: VariableType.FIELD_ID,
                                fieldIdVariable: {
                                    fieldId: item
                                }
                            },
                            operator: dataSource?.schema?.[item]?.type === 'file' ? 'isEmpty' : '=',
                            paramList: []
                        })
                    }}
                />
            </SCxRecordMatchFilterConfiguratorContent>
        </SCxRecordMatchFilterConfiguratorWrapper>
    )
}
