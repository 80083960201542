import type { DataSourceAbstract, Field, TypeInstanceMap, VariableADTvalue } from '@lighthouse/core'
import type { FlowNode } from '@lighthouse/shared'
import {
    defaultFormat,
    getSystemOption,
    getUpstreamNodeOptionByInnerType,
    getUserDatasourceOptionsByInnerType,
    innerTypeNameMap,
    innerTypeToFieldType,
    USER_DATASOURCE,
    VariableSelect
} from '@lighthouse/shared'
import React, { useMemo } from 'react'

interface SettingSubProcessParamProps {
    value?: VariableADTvalue
    innerType: TypeInstanceMap
    allParentNodes: FlowNode[]
    parentNodes: FlowNode[]
    dataSourceList: DataSourceAbstract[]
    onChange: (val: VariableADTvalue) => void
}

export const SettingSubProcessParam: React.FC<SettingSubProcessParamProps> = ({
    value,
    innerType: paramInnerType,
    allParentNodes,
    parentNodes,
    dataSourceList,
    onChange
}) => {
    const virtualField: Field | undefined = useMemo(() => {
        if (paramInnerType === 'NULL') {
            return
        }
        const extraConfig =
            paramInnerType === 'DATE'
                ? {
                      date: {
                          format: defaultFormat
                      }
                  }
                : {}
        return {
            id: '',
            name: innerTypeNameMap[paramInnerType],
            type: innerTypeToFieldType[paramInnerType],
            dsId: '',
            innerType: paramInnerType,
            ...extraConfig
        } as Field
    }, [paramInnerType])

    const disableInput = paramInnerType === 'ARRAY'

    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptionsByInnerType({
            dataSource: userDataSource,
            validateFieldType: (innerType: TypeInstanceMap) => {
                return innerType === paramInnerType
            }
        })
    }, [dataSourceList, paramInnerType])

    const systemOption = useMemo(
        () => (paramInnerType === 'DATE' ? getSystemOption(['TODAY', 'TOMORROW', 'YESTERDAY', 'NOW']) : undefined),
        [paramInnerType]
    )

    const variableOptions = useMemo(() => {
        return [
            getUpstreamNodeOptionByInnerType({
                dataSourceList,
                parentNodes,
                allParentNodes,
                validateInnerType: innerType => innerType === paramInnerType
            })
        ]
    }, [allParentNodes, dataSourceList, paramInnerType, parentNodes])

    return (
        <VariableSelect
            width="100%"
            disableInput={disableInput}
            field={virtualField}
            options={variableOptions}
            userOption={userOption}
            systemOption={systemOption}
            value={value}
            onChange={onChange}
        />
    )
}
