import { Modal, Toast } from '@byecode/ui'
import type { FilterFormType, JoinSetting, TableColumnWidth } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type Field,
    type FieldADTValue,
    type FilterableProtocol,
    type JoinConfig,
    type Pagination,
    type Sorter,
    type TableColumn,
    type TableColumns,
    DataSourceType,
    SelectedMode
} from '@lighthouse/core'
import type {
    AppendParams,
    ColumnEvent,
    CreateFieldParams,
    DsExportParams,
    ImportParams,
    InsertDirection,
    RowEvent
} from '@lighthouse/shared'
import {
    aliyunVideoProtocolList,
    createFieldNeedFetchData,
    DataSourceContentProvider,
    fileMaxUploadSize,
    fileSuffixRegex,
    FindUseLocationType,
    FindUseType,
    getFileSizeToMB,
    getMainTableRecordId,
    SuspendPagination,
    Table,
    updateFieldNeedFetchData,
    useAtomAction,
    useAtomAsyncAction,
    useAtomData,
    useDataSourceContext,
    useHandleAbortPrevious,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { arrayDifference, nanoid } from '@lighthouse/tools'
import { getDefaultStore, useAtomValue } from 'jotai'
import { clone, find } from 'rambda'
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { setFindUseAtom } from '@/atoms/application/action'
import { findUseAtom } from '@/atoms/application/state'
import {
    addAiFieldStatusAtom,
    createFieldAtom,
    createJoinDataSourceAtom,
    createRecordAtom,
    deleteFieldAtom,
    deleteRecordAtom,
    fetchDataSourceAtom,
    importDataSourceAtom,
    loadDataSourceAtom,
    refreshDataSourceAtom,
    setCurrentDataSourceIdAtom,
    updateCellAtom,
    updateDataSourceAtom,
    updateFieldAtom
} from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom, recordPoolAtom } from '@/atoms/dataSource/state'
import { filterCacheAtomFamily, sortsCacheAtomFamily, tableColumnCacheAtomFamily } from '@/atoms/storage/state'
import { useFindUseContext } from '@/contexts/PageContext'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

import { JoinDataSourceSetting } from '../JoinDataSourceSetting'
import { JoinConfigureModal } from '../JoinDataSourceSetting/JoinConfigureModal'
import { RefreshButton } from '../RefreshButton'
import { DataSourceSettingToolBar } from './DataSourceSettingToolBar'
import * as SC from './styles'

export interface DataSourceContentProps {
    appId: string
    envId: string
    id: string
    data: DataSourceAbstract
    filter?: FilterFormType
    sorts?: Sorter[]
    loading?: boolean
    onDataAppended?: (params: AppendParams) => Promise<void>
    onEnableApprovalState?: (enabled: boolean) => Promise<void>
}

export interface DataSourceContentState {
    pageNum: number
    pageSize: number
    searchVal: string
    selectedRecords: string[]
    selectedMode?: SelectedMode
}

export const DataSourceContent: React.FC<DataSourceContentProps> = ({
    appId,
    envId,
    id,
    data,
    filter,
    sorts,
    loading = true,
    onDataAppended,
    onEnableApprovalState
}) => {
    const store = getDefaultStore()
    const rootRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const findUseData = useAtomData(findUseAtom)
    const { run: setFindUse } = useAtomAction(setFindUseAtom)
    // const [path, setPath] = useState<string[]>([])
    const { onChange: findUseChange } = useFindUseContext()
    const createFieldParamsRef = useRef<CreateFieldParams>({
        sourceId: '',
        direction: '',
        fieldId: ''
    })
    const uploadId = useId()
    const selectedModeRef = useRef<SelectedMode>()
    const { viewOptions, type, name, records, schema } = data
    const { pagination, joinConfig, tableProps } = viewOptions
    const [visibleJoinConfig, setVisibleJoinConfig] = useState(false)
    // const [visibleRelated, setVisibleRelated] = useState(false)
    const { visibleJoinConfigure, onVisibleJoinConfigureChange } = useDataSourceContext()
    // const a = recordPoolAtom(id)
    // const [recordList, { createRecord, deleteRecord, updateCell }] = useRecords(appId, id)
    const [state, setState] = useImmer<DataSourceContentState>({
        pageNum: pagination?.currentPage || 1,
        pageSize: 100,
        searchVal: '',
        selectedRecords: [],
        selectedMode: undefined
    })

    const isAggregateDataSource = type === DataSourceType.aggregateDataSource
    const isUserDataSource = id === USER_DATASOURCE
    const tableColumnCache = useAtomValue(tableColumnCacheAtomFamily({ appId, envId, id }))
    const { selectedRecords, pageSize, searchVal, selectedMode } = state
    const { run: fetchDataSource } = useAtomAction(fetchDataSourceAtom)
    const { run: refreshDataSource } = useAtomAsyncAction(refreshDataSourceAtom)
    const { run: loadDataSource } = useAtomAction(loadDataSourceAtom)
    const { mutation } = useHandleAbortPrevious(fetchDataSource)
    const { run: createJoinDataSource, loading: createJoinLoading } = useAtomAction(createJoinDataSourceAtom)
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    const { run: createField } = useAtomAction(createFieldAtom)
    const { run: updateField } = useAtomAction(updateFieldAtom)
    const { run: deleteField } = useAtomAction(deleteFieldAtom)
    const { run: createRecord } = useAtomAction(createRecordAtom)
    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: updateDataSource } = useAtomAction(updateDataSourceAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const { run: importDataSource } = useAtomAction(importDataSourceAtom)
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const { run: setFilterCache } = useAtomAction(filterCacheAtomFamily({ appId, envId, id }))
    const { run: setSortsCache } = useAtomAction(sortsCacheAtomFamily({ appId, envId, id }))
    const { run: setTableColumnCache } = useAtomAction(tableColumnCacheAtomFamily({ appId, envId, id }))
    const dataSourceList = useDataSourceList(appId, envId)

    // 重置状态
    useEffect(() => {
        selectedModeRef.current = undefined
        setState({
            pageNum: 1,
            pageSize: 100,
            searchVal: '',
            selectedRecords: [],
            selectedMode: undefined
        })
    }, [setState, id])

    useEffect(() => {
        if (!findUseData) {
            return
        }
        const { location } = findUseData
        if (location.type !== FindUseLocationType.DATASHEET) {
            return
        }
        const ds = find(item => item.id === location.dsId, dataSourceList)
        if (!ds) {
            return
        }
        const { type } = ds
        if (type === DataSourceType.joinDataSource) {
            onVisibleJoinConfigureChange?.(true)
        }
    }, [dataSourceList, findUseData, onVisibleJoinConfigureChange])

    useUpdateEffect(() => {
        if (selectedMode === 'ALL' && selectedModeRef.current === 'ALL') {
            setState(draft => {
                draft.selectedRecords = records
            })
        }
    }, [records])

    const findUseLocation = useMemo(() => {
        if (!findUseData || findUseData.location.type !== FindUseLocationType.FIELD) {
            return undefined
        }
        return findUseData.location
    }, [findUseData])

    const handleCreateDataSource = useCallback(
        async (config: JoinSetting) => {
            const { dsId: newDataSourceId } = await createJoinDataSource({
                envId,
                primaryDsId: id,
                config
            })
            if (newDataSourceId) {
                setCurrentDataSourceId?.({ dsId: newDataSourceId })
                onVisibleJoinConfigureChange?.(true)
                navigate({ pathname: `/${appId}/dataSource/${newDataSourceId}` })
            }
            return !!newDataSourceId
        },
        [appId, createJoinDataSource, envId, id, navigate, onVisibleJoinConfigureChange, setCurrentDataSourceId]
    )

    const handleLoadDataSource = useCallback(
        (pagination: Pagination) => {
            return loadDataSource({
                envId,
                dsId: id,
                pagination,
                filter,
                sorts,
                search: searchVal
            })
        },
        [envId, filter, id, loadDataSource, searchVal, sorts]
    )

    const handleCellChange = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            updateCell({ envId, dsId: id, recordId, fieldId: fieldValue.id, value: fieldValue }),
        [envId, id, updateCell]
    )

    const handleCellUpdate = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            srv.updateCell({
                envId,
                dsId: id,
                id: recordId,
                fieldId: fieldValue.id,
                content: fieldValue
            }),
        [envId, id]
    )

    const handleSelectedRecords = useCallback(
        (selectedRecords: string[]) => {
            setState(draft => {
                draft.selectedRecords = selectedRecords
                draft.selectedMode = SelectedMode.CURRENT_PAGE
            })
            selectedModeRef.current = SelectedMode.CURRENT_PAGE
        },
        [setState]
    )

    const handleInitDataSource = useCallback(() => {
        setState(draft => {
            draft.pageNum = 1
        })

        return mutation({
            envId,
            dsId: id,
            pagination: { currentPage: 1, pageSize: 100 },
            search: searchVal,
            filter,
            sorts
        })
    }, [setState, mutation, envId, id, searchVal, filter, sorts])

    const handleToggleJoinSetting = useCallback(
        (visible: boolean) => {
            onVisibleJoinConfigureChange?.(visible)
        },
        [onVisibleJoinConfigureChange]
    )

    const handleEditAggregator = useCallback(() => {
        navigate(`/${appId}/aggregate/${id}`)
    }, [appId, id, navigate])

    const handleImportDataSource = useCallback(
        async (params: ImportParams) => {
            const dsId = await importDataSource(params)
            if (dsId) {
                Toast.success('导入数据成功！')
                mutation({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100 },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
            return !!dsId
        },
        [importDataSource, mutation, envId, id, searchVal, filter, sorts]
    )

    const handleCreateRecord = useCallback(
        (beforeId?: string) => {
            return createRecord({ envId, dsId: id, beforeId })
        },
        [createRecord, envId, id]
    )

    const handleDeleteRecord = useCallback(
        async (recordIds: string[]) => {
            const res = await deleteRecord({ envId, dsId: id, recordIds, mode: selectedMode, search: searchVal, filter })
            if (selectedMode === SelectedMode.CURRENT_PAGE) {
                mutation({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100 },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
            return res
        },
        [deleteRecord, envId, filter, id, mutation, searchVal, selectedMode, sorts]
    )

    const handleDeleteMultipleRecord = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认删除',
            content: '删除后无法恢复',
            okStatus: 'error',
            okText: '删除'
        })
        if (isConfirm) {
            const isDelete = await handleDeleteRecord(selectedRecords)
            if (isDelete) {
                setState(draft => {
                    draft.selectedRecords = []
                    draft.selectedMode = SelectedMode.CURRENT_PAGE
                })
                selectedModeRef.current = SelectedMode.CURRENT_PAGE
            }
        }
    }, [handleDeleteRecord, selectedRecords, setState])

    const handleAiQuery = useCallback(
        async (dsId: string, data: Partial<DataSourceAbstract>) => {
            const isUpdate = await updateDataSource({ envId, dsId, params: data })
            if (isUpdate) {
                mutation({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100 },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
            return isUpdate
        },
        [updateDataSource, envId, mutation, id, searchVal, filter, sorts]
    )

    const handleChangeSorter = useCallback(
        (data: Sorter[] = []) => {
            setSortsCache(data)
        },
        [setSortsCache]
    )

    const handleChangeDisplay = useCallback(
        (data: TableColumns = []) => {
            return updateDataSource?.({
                envId,
                dsId: id,
                params: {
                    viewOptions: {
                        ...viewOptions,
                        tableProps: data
                    }
                }
            })
        },
        [envId, id, updateDataSource, viewOptions]
    )

    const getMultiDataSourceTableProps = useCallback(
        (ids: string[]) => {
            return ids.reduce<Set<TableColumn>>((prev, cur) => {
                const dataSource = find(item => item.id === cur, dataSourceList)
                if (!dataSource) {
                    return prev
                }
                const { schema, type } = dataSource
                prev = new Set<TableColumn>([
                    ...prev,
                    ...dataSource.viewOptions.tableProps.reduce<TableColumn[]>((list, cur) => {
                        const field = schema[cur.id]
                        if (field) {
                            list.push({
                                ...cur,
                                id: type === DataSourceType.aggregateDataSource ? field.id : `${field.dsId}__${field.id}`
                            })
                        }
                        return list
                    }, [])
                ])
                return prev
            }, new Set<TableColumn>())
        },
        [dataSourceList]
    )

    const getMultiDataSourceTablePropsIds = useCallback(
        (ids: string[]) => {
            return ids.reduce<Set<string>>((prev, cur) => {
                const dataSource = find(item => item.id === cur, dataSourceList)
                if (!dataSource) {
                    return prev
                }
                const { schema, type } = dataSource
                prev = new Set<string>([
                    ...prev,
                    ...dataSource.viewOptions.tableProps.reduce<string[]>((list, cur) => {
                        const field = schema[cur.id]
                        if (field) {
                            list.push(type === DataSourceType.aggregateDataSource ? field.id : `${field.dsId}__${field.id}`)
                        }
                        return list
                    }, [])
                ])
                return prev
            }, new Set<string>())
        },
        [dataSourceList]
    )

    const handleChangeTablePropsByJoinConfig = useCallback(
        (config: JoinConfig) => {
            const oldConditions = joinConfig?.conditions || []
            const oldDsIds = oldConditions.map(item => item.joinedDsId || '').filter(Boolean)
            const newDsIds = config.conditions?.map(item => item.joinedDsId || '').filter(Boolean) || []
            const { added, removed } = arrayDifference(oldDsIds, newDsIds)
            if (added.length === 0 && removed.length === 0) {
                return tableProps
            }

            const removeTablePropsIds = getMultiDataSourceTablePropsIds(removed)
            const removedTableProps = tableProps.filter(item => !removeTablePropsIds.has(item.id))
            const addTableProps = getMultiDataSourceTableProps(added)
            return [...removedTableProps, ...addTableProps]
        },
        [getMultiDataSourceTableProps, getMultiDataSourceTablePropsIds, joinConfig?.conditions, tableProps]
    )

    const handleChangeJoinConfig = useCallback(
        async (params: JoinConfig) => {
            // const newTableProps = handleChangeTablePropsByJoinConfig(params)
            const isUpdate = await updateDataSource?.({
                envId,
                dsId: id,
                params: {
                    viewOptions: {
                        ...viewOptions,
                        // tableProps: newTableProps,
                        joinConfig: params
                    }
                }
            })
            if (isUpdate) {
                mutation({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100 },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
        },
        [envId, filter, id, mutation, searchVal, sorts, updateDataSource, viewOptions]
    )

    const handleChangeFilter = useCallback(
        (data: FilterableProtocol['filter']) => {
            setFilterCache(data)
        },
        [setFilterCache]
    )

    const handleChangeSearch = useCallback(
        (val: string) => {
            if (val === searchVal) {
                return
            }
            setState(draft => {
                draft.searchVal = val
                draft.pageNum = 1
            })
            mutation({
                envId,
                dsId: id,
                pagination: { currentPage: 1, pageSize: 100 },
                search: val,
                filter,
                sorts
            })
        },
        [searchVal, setState, mutation, envId, id, filter, sorts]
    )

    const handleCreateField = useCallback(
        async (field: Field, sourceId: string, direction: InsertDirection) => {
            createFieldParamsRef.current = {
                sourceId,
                direction,
                fieldId: field.id
            }
            const innerType = await createField({
                envId,
                dsId: id,
                config: field,
                sourceId,
                direction
            })

            if (id !== field.dsId || createFieldNeedFetchData.has(field.type)) {
                await refreshDataSource({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100, rowTotal: pagination?.rowTotal },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
            return !!innerType
        },
        [createField, envId, filter, id, pagination?.rowTotal, refreshDataSource, searchVal, sorts]
    )

    const handleUpdateField = useCallback(
        async (fieldId: string, config: Field) => {
            const isUpdate = await updateField({
                envId,
                dsId: id,
                fieldId,
                config
            })
            const field = schema[fieldId]
            if ((field && field.type !== config.type) || updateFieldNeedFetchData.has(config.type)) {
                await refreshDataSource({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100, rowTotal: pagination?.rowTotal },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
            return !!isUpdate
        },
        [envId, filter, id, pagination?.rowTotal, refreshDataSource, schema, searchVal, sorts, updateField]
    )

    const handleDeleteField = useCallback(
        async (field: Field) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除列「${field.name ?? '未命名列'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteField({
                    envId,
                    dsId: id,
                    fieldId: field.id
                })
                return !!isDelete
            }
            return false
        },
        [deleteField, envId, id]
    )

    const handleFindUse = useCallback(
        (fieldId: string) => {
            if (findUseChange) {
                findUseChange({
                    open: true,
                    config: {
                        type: FindUseType.FIELD,
                        field: {
                            dsId: id,
                            fieldId
                        }
                    }
                })
            }
        },
        [findUseChange, id]
    )

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const statusId = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: id, recordId, fieldId })
            if (isSuccess) {
                addAiFieldStatus({
                    id: statusId,
                    dataSourceId: id,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, id]
    )

    const handleSelectModeChange = useCallback(
        (val?: SelectedMode) => {
            if (val === SelectedMode.ALL) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.ALL
                    draft.selectedRecords = records
                })
                selectedModeRef.current = SelectedMode.ALL
                return
            }
            if (val === SelectedMode.CURRENT_PAGE) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.CURRENT_PAGE
                    draft.selectedRecords = records
                })
                selectedModeRef.current = SelectedMode.CURRENT_PAGE
                return
            }
            setState(draft => {
                draft.selectedMode = undefined
                draft.selectedRecords = []
            })
            selectedModeRef.current = undefined
        },
        [records, setState]
    )

    const handleTableColumnWidthChange = useCallback(
        (val: TableColumnWidth) => {
            const column = {
                ...tableColumnCache,
                ...val
            }
            setTableColumnCache(column)
        },
        [setTableColumnCache, tableColumnCache]
    )

    const handleExport = useCallback(async () => {
        Toast.warning('正在导出, 请勿重复点击')
        const recordIds = [...new Set(selectedRecords.map(id => getMainTableRecordId(id)))]
        const params: DsExportParams = {
            envId,
            // search: searchVal,
            dsId: id,
            mode: selectedMode,
            recordIds,
            fileType: 'xlsx',
            search: searchVal,
            filter,
            sorts
        }
        const res = await srv.dsExport(params)
        const { data, headers } = res
        const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${name}.xlsx`
        const dom = document.createElement('a')
        const url = window.URL.createObjectURL(data)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.append(dom)
        dom.click()
        dom.remove()
        window.URL.revokeObjectURL(url)
    }, [envId, filter, id, name, searchVal, selectedMode, selectedRecords, sorts])

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.selectedMode = undefined
            draft.selectedRecords = []
        })
    }, [setState])

    const getUploadOptions = useCallback(
        (fieldId: string, recordId: string) => ({
            info: { id: uploadId, label: name, groupId: data.id },
            options: uploadManagerInAppParams()
        }),
        [data.id, name, uploadId]
    )

    const getRichTextUploadOptions = useCallback((fieldId: string, recordId: string) => uploadManagerInAppParams(), [])

    const videoUploadOptions = useMemo(() => {
        return {
            info: { id: uploadId, label: name, groupId: data.id },
            options: {
                ...uploadManagerInAppParams(),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }
                        const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                        if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                            Toast.error('视频格式错误，请上传 MP4 格式的视频')
                            return false
                        }
                        return true
                    }
                    return true
                }
            }
        }
    }, [data.id, name, uploadId])

    const columnEvent: ColumnEvent = useMemo(
        () => ({
            onCreateField: handleCreateField,
            onUpdateField: handleUpdateField,
            onDeleteField: handleDeleteField,
            onFindUse: handleFindUse,
            clearPath: () => setFindUse(undefined),
            onSelectModeChange: handleSelectModeChange,
            onTableColumnWidthChange: handleTableColumnWidthChange
        }),
        [
            handleCreateField,
            handleUpdateField,
            handleDeleteField,
            handleFindUse,
            handleSelectModeChange,
            handleTableColumnWidthChange,
            setFindUse
        ]
    )

    const rowEvent: RowEvent = useMemo(
        () => ({
            onRecordSelect: handleSelectedRecords,
            onCellChange: handleCellChange,
            onCellUpdate: handleCellUpdate,
            onLoadMoreData: handleLoadDataSource,
            onCreateRecord: handleCreateRecord,
            onAiGeneration: handleAiGeneration
        }),
        [handleSelectedRecords, handleCellChange, handleCellUpdate, handleLoadDataSource, handleCreateRecord, handleAiGeneration]
    )

    return (
        <DataSourceContentProvider value={{ el: rootRef.current }}>
            <SC.DataSourceContainer ref={rootRef}>
                <SC.DataSourceWrapper>
                    <SC.DataSourceContent>
                        <DataSourceSettingToolBar
                            searchVal={searchVal}
                            envId={envId}
                            dataSource={data}
                            dataSourceList={dataSourceList}
                            filter={filter}
                            sorts={sorts}
                            // onSelectModeChange={handleSelectModeChange}
                            // onDeleteMultipleRecord={handleDeleteMultipleRecord}
                            onChangeSorter={handleChangeSorter}
                            onChangeDisplay={handleChangeDisplay}
                            onChangeFilter={handleChangeFilter}
                            onChangeSearch={handleChangeSearch}
                            onUpdateField={handleUpdateField}
                            onDeleteField={handleDeleteField}
                            onCreateField={handleCreateField}
                            onDataAppended={onDataAppended}
                            onDataCreate={handleImportDataSource}
                            onEnableApprovalState={onEnableApprovalState}
                            onVisibleJoinConfig={() => setVisibleJoinConfig(true)}
                            onEditAggregator={handleEditAggregator}
                        />
                        <SC.DataSourceBody>
                            <Table
                                appId={appId}
                                id={data.id}
                                headerFixed
                                contentLoading={loading}
                                disableFieldCreatable={isAggregateDataSource}
                                data={data}
                                dataSourceList={dataSourceList}
                                createFieldParams={createFieldParamsRef.current}
                                recordPoolAtom={recordPoolAtom}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                selectedRecords={selectedRecords}
                                pageSize={pageSize}
                                findUseLocation={findUseLocation}
                                columnEvent={columnEvent}
                                uploadOptions={{
                                    info: { id: uploadId, label: name, groupId: data.id },
                                    options: uploadManagerInAppParams()
                                }}
                                richTextUploadOptions={uploadManagerInAppParams()}
                                videoUploadOptions={videoUploadOptions}
                                rowEvent={rowEvent}
                                tableColumnCache={tableColumnCache}
                            />
                        </SC.DataSourceBody>
                    </SC.DataSourceContent>
                    <SC.DataSourceFooter>
                        <SC.TablePageCount>
                            {pagination?.rowTotal || pagination?.rowTotal === 0 ? `共${String(pagination.rowTotal)}行` : ''}
                        </SC.TablePageCount>
                        <RefreshButton onRefresh={handleInitDataSource} />
                    </SC.DataSourceFooter>
                    {selectedRecords.length > 0 && (
                        <SC.SuspendWrapper>
                            <SuspendPagination
                                mode={selectedMode}
                                total={pagination?.rowTotal}
                                selectIds={selectedRecords}
                                enableDelete={!isAggregateDataSource}
                                enableExport
                                onDelete={handleDeleteMultipleRecord}
                                onExport={handleExport}
                                onClose={handleClose}
                            />
                        </SC.SuspendWrapper>
                    )}
                </SC.DataSourceWrapper>
                {visibleJoinConfigure && (
                    <JoinDataSourceSetting
                        id={id}
                        value={clone(joinConfig)}
                        onChange={handleChangeJoinConfig}
                        dataSourceList={dataSourceList}
                        onClose={() => handleToggleJoinSetting(false)}
                    />
                )}
                <JoinConfigureModal
                    title="创建连接"
                    loading={createJoinLoading}
                    index={0}
                    useDsIds={[]}
                    onConfirm={handleCreateDataSource}
                    dataSourceList={dataSourceList}
                    primaryDsId={id}
                    open={visibleJoinConfig}
                    onClose={() => setVisibleJoinConfig(false)}
                />
            </SC.DataSourceContainer>
        </DataSourceContentProvider>
    )
}
