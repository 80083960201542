import type {
    AiFieldStatus,
    DataSourceAbstract,
    Field,
    FieldADTValue,
    Pagination,
    RecordLikeProtocol,
    TableColumns
} from '@lighthouse/core'
import { useMounted } from '@lighthouse/tools'
import type { UploadyProps } from '@rpldy/shared-ui'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { DATASOURCE_CELL_DATA_KEY, NO_EDIT_SYSTEM_FIELD, notEditableFieldTypes, universalHotKeys } from '../../constants'
import { getIdByCellDataKey, getIsAppointField, selectionState } from '../../utils'
import type { UseUploadFileSParameter } from '../UploadManage'
import TableBody from './TableBody'
import { TablePopover } from './TablePopover'
import { TableTooltip } from './TableTooltip'

export interface TableContentProps {
    dataSourceInfo: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    tableProps: TableColumns
    records?: string[]
    recordPoolAtom: ReturnType<typeof atomWithImmer<RecordLikeProtocol[]>>
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords?: string[]
    scrollRef: React.RefObject<HTMLDivElement>
    pagination: Pagination
    primaryDataSourceFieldIds?: Set<string>
    disableEdit?: boolean
    disableSelect?: boolean
    disableCreateRecord?: boolean
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    richTextUploadOptions: UploadyProps
    videoUploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onRecordSelect?: (recordIds: string[]) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onLoadMoreData?: (pagination: Pagination, search?: string) => Promise<RecordLikeProtocol[]>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
}

export interface TablePopoverState {
    currentRecordId: string
    currentWidth?: number
    currentField?: Field
    popperAnchorElement?: HTMLDivElement
    editorElement?: HTMLDivElement
}

export interface TableTooltipState {
    toolTipAnchorElement?: HTMLDivElement
    message: string
}

const SCxTableContent = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    /* padding-bottom: 2px; */
`

export const TableContent: React.FC<TableContentProps> = ({
    dataSourceInfo,
    dataSourceList,
    tableProps,
    recordPoolAtom,
    aiFieldStatusListAtom,
    selectedRecords,
    scrollRef,
    pagination,
    primaryDataSourceFieldIds,
    disableEdit,
    disableSelect,
    disableCreateRecord,
    uploadOptions,
    richTextUploadOptions,
    videoUploadOptions,
    onCellChange,
    onCellUpdate,
    onRecordSelect,
    onLoadMoreData,
    onAiGeneration
}) => {
    const [scrollElementState, setScrollElementState] = useState(scrollRef.current)
    const rootRef = useRef<HTMLDivElement>(null)
    const { schema, id } = dataSourceInfo
    // const recordPool = useAtomValue(recordPoolAtom)
    const [state, setState] = useImmer<TablePopoverState>({
        currentRecordId: '',
        currentWidth: 160,
        currentField: undefined,
        popperAnchorElement: undefined,
        editorElement: undefined
    })

    const [tooltipState, setTooltipState] = useImmer<TableTooltipState>({
        toolTipAnchorElement: undefined,
        message: ''
    })

    useMounted(() => {
        if (!scrollElementState) {
            setScrollElementState(scrollRef.current)
        }
    })

    useEffect(() => {
        if (tooltipState.toolTipAnchorElement) {
            const timer = setTimeout(() => {
                setTooltipState({
                    toolTipAnchorElement: undefined,
                    message: ''
                })
            }, 1500)
            return () => clearTimeout(timer)
        }
    }, [setTooltipState, tooltipState.message, tooltipState.toolTipAnchorElement])

    const { currentRecordId, currentWidth, currentField, popperAnchorElement } = state

    const handleOpenEditor = useCallback(
        (data: TablePopoverState) => {
            setState(data)
        },
        [setState]
    )

    const handleOpenTooltip = useCallback(
        (data: TableTooltipState) => {
            setTooltipState(data)
        },
        [setTooltipState]
    )

    const handleClose = useCallback(() => {
        setState({
            currentRecordId: '',
            currentWidth: 160,
            currentField: undefined,
            popperAnchorElement: undefined,
            editorElement: undefined
        })
    }, [setState])

    useHotkeys<HTMLDivElement>(
        universalHotKeys,
        ev => {
            // const recordPool = useAtomValue(recordPoolAtom)
            const { cellCheckedId } = selectionState.state
            const cDom = document.querySelector(`[${DATASOURCE_CELL_DATA_KEY}='${cellCheckedId}']`) as HTMLDivElement
            if (!cellCheckedId || !cDom) {
                return
            }
            const disable = cDom.getAttribute('data-disable')
            if (disable) {
                return
            }
            const anchorDom = cDom.querySelector(`[data-type='anchor']`) as HTMLDivElement
            const [_, recordId, fieldId] = getIdByCellDataKey(cellCheckedId)
            const field = schema[fieldId]
            const tableProp = find(item => item.id === fieldId, tableProps)
            // const record = find(item => item.id === recordId && item.appId === appId && item.dsId === dsId, recordPool || [])
            if (notEditableFieldTypes.has(field?.type) || getIsAppointField(fieldId, NO_EDIT_SYSTEM_FIELD) || !anchorDom) {
                return
            }

            ev.preventDefault()
            setState({
                popperAnchorElement: anchorDom,
                editorElement: cDom,
                currentRecordId: recordId,
                currentWidth: tableProp?.width || 160,
                currentField: field
            })
        },
        { enableOnContentEditable: true, enableOnTags: ['INPUT'] },
        [tableProps]
    )

    const tableTooltip = useMemo(() => {
        if (!tooltipState.toolTipAnchorElement) {
            return null
        }
        return <TableTooltip label={tooltipState.message} target={tooltipState.toolTipAnchorElement} />
    }, [tooltipState.message, tooltipState.toolTipAnchorElement])

    const tablePopover = useMemo(() => {
        if (!currentField) {
            return null
        }
        return (
            <TablePopover
                popperAnchorElement={popperAnchorElement}
                dataSourceInfo={dataSourceInfo}
                recordId={currentRecordId}
                width={currentWidth}
                recordPoolAtom={recordPoolAtom}
                rootRef={rootRef}
                scrollElement={scrollRef.current}
                field={currentField}
                uploadOptions={uploadOptions}
                richTextUploadOptions={richTextUploadOptions}
                videoUploadOptions={videoUploadOptions}
                onCellChange={onCellChange}
                onCellUpdate={onCellUpdate}
                onClose={handleClose}
            />
        )
    }, [
        currentField,
        currentRecordId,
        currentWidth,
        dataSourceInfo,
        handleClose,
        onCellChange,
        onCellUpdate,
        popperAnchorElement,
        recordPoolAtom,
        richTextUploadOptions,
        scrollRef,
        uploadOptions,
        videoUploadOptions
    ])

    const tableBody = useMemo(() => {
        if (!scrollElementState) {
            return
        }
        return (
            <TableBody
                dataSourceInfo={dataSourceInfo}
                dataSourceList={dataSourceList}
                recordPoolAtom={recordPoolAtom}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                selectedRecords={selectedRecords}
                tableProps={tableProps}
                scrollElement={scrollElementState}
                pagination={pagination}
                primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                disableEdit={disableEdit}
                disableSelect={disableSelect}
                disableCreateRecord={disableCreateRecord}
                onOpenTooltip={handleOpenTooltip}
                onRecordSelect={onRecordSelect}
                onOpenEditor={handleOpenEditor}
                onLoadMoreData={onLoadMoreData}
                onCellChange={onCellChange}
                onAiGeneration={onAiGeneration}
            />
        )
    }, [
        scrollElementState,
        dataSourceInfo,
        dataSourceList,
        recordPoolAtom,
        aiFieldStatusListAtom,
        selectedRecords,
        tableProps,
        pagination,
        primaryDataSourceFieldIds,
        disableEdit,
        disableSelect,
        disableCreateRecord,
        handleOpenTooltip,
        onRecordSelect,
        handleOpenEditor,
        onLoadMoreData,
        onCellChange,
        onAiGeneration
    ])

    return (
        <SCxTableContent ref={rootRef}>
            {tableBody}
            {tablePopover}
            {tableTooltip}
        </SCxTableContent>
    )
}
