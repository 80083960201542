import React from 'react'
import styled from 'styled-components'

import PageOperation from '../PageOperation'
import { PreviewIcon } from '../PreviewIcon'
import { RedoUndo } from '../RedoUndo'
import { SelectRole } from '../SelectRole'

interface PageToolMobileProps {}

const SCxContainer = styled.div`
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    align-items: center;
    z-index: 1;
`

export const PageToolMobile: React.FunctionComponent<PageToolMobileProps> = props => {
    return (
        <SCxContainer>
            <PageOperation />
            <PreviewIcon  />
            <SelectRole />
            {/* <RedoUndo /> */}
        </SCxContainer>
    )
}
