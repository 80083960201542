import { Box, Group, IconFont, singleTextEllipsis, Text } from '@byecode/ui'
import { useDndMonitor } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { CollapseBlockAbstract } from '@lighthouse/core'
import type { RichTextEditorProps, VariableOptions, VariableTree } from '@lighthouse/shared'
import { generateText, getIsFindUseInRichText, ListItem4ByecodeUi, RichTextEditor, useFindUseObjectContext } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

const SCxCollapse = styled(Group)`
    padding: 0;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    overflow: hidden;
    & + & {
        margin-top: 12px;
    }
    .byecode-group-wrapper {
        padding: 0 8px;
        background-color: var(--color-gray-100);
    }
    .byecode-group-collapse {
        background-color: #fff;
    }
`

const SCxTitle = styled.span`
    font-weight: normal;
    ${singleTextEllipsis()}
`

const StyledRichTextEditor = styled(RichTextEditor)<{ highlighting?: boolean }>`
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
    .ProseMirror {
        min-height: 120px;
    }

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
            overflow: hidden;
        `}
`

const SCxContent = styled.div`
    padding: 12px 0;
`

interface SortableConfigureProps {
    id: string
    dsId?: string
    index: number
    disabled?: boolean
    options?: VariableOptions
    systemOption?: VariableTree
    userOption?: VariableTree
    viewOption?: VariableTree
    onRemove: (index: number | number[]) => void
}

export const SortableConfigure: FC<SortableConfigureProps> = ({
    index,
    disabled,
    id,
    dsId,
    options,
    systemOption,
    userOption,
    viewOption,
    onRemove
}) => {
    const { control } = useFormContext<CollapseBlockAbstract['config']>()
    const { setNodeRef, listeners, isDragging, attributes, setActivatorNodeRef, transform, transition } = useSortable({
        id,
        disabled
    })
    const findUseObject = useFindUseObjectContext()
    const { title } = useWatch({
        control,
        name: `list.${index}`
    })

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            variable: { options, userOption, systemOption, viewOption },
            taskList: false,
            bulletList: false,
            orderedList: false,
            codeBlock: false,
            image: false,
            quote: false,
            line: false
        }),
        [options, systemOption, userOption, viewOption]
    )

    const [open, setOpen] = useState(index === 0)

    const prevOpenRef = useRef(open)

    const onCollapseChange = useCallback((f: boolean) => {
        setOpen(f)
        prevOpenRef.current = f
    }, [])

    useDndMonitor({
        onDragStart: e => {
            if (e.active.id === id && prevOpenRef.current) {
                setOpen(false)
            }
        },
        onDragEnd: e => {
            if (e.active.id === id && prevOpenRef.current) {
                setOpen(true)
            }
        }
    })

    const style: React.CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        position: isDragging ? 'relative' : undefined,
        zIndex: isDragging ? 1 : undefined
    }

    return (
        <SCxCollapse
            ref={setNodeRef}
            style={style}
            opened={open}
            onCollapseChange={onCollapseChange}
            label={
                <Box style={{ display: 'flex', gap: 6 }}>
                    <IconFont fill="var(--color-gray-400)" type="DotsSix" ref={setActivatorNodeRef} {...attributes} {...listeners} />
                    <SCxTitle>{`面板 ${index + 1}`}</SCxTitle>
                </Box>
            }
            extra={
                disabled ? null : (
                    <IconFont type="Trash" onClick={() => onRemove(index)} size={16} style={{ color: 'var(--color-gray-500)' }} />
                )
            }
        >
            <SCxContent>
                {/* 未打开时卸载富文本，节省cpu计算 */}
                {open && (
                    <>
                        <ListItem4ByecodeUi direction="column" gap={6}>
                            <Text>标题</Text>
                            <Controller
                                control={control}
                                name={`list.${index}.title`}
                                render={({ field }) => {
                                    const isHighLight = getIsFindUseInRichText({
                                        doc: field.value,
                                        findUseObject,
                                        currentDsId: dsId
                                    })
                                    return (
                                        <StyledRichTextEditor
                                            disabled={disabled}
                                            highlighting={isHighLight}
                                            config={config}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi direction="column" gap={6}>
                            <Text>副标题</Text>
                            <Controller
                                control={control}
                                name={`list.${index}.subTitle`}
                                render={({ field }) => {
                                    const isHighLight = getIsFindUseInRichText({
                                        doc: field.value,
                                        findUseObject,
                                        currentDsId: dsId
                                    })
                                    return (
                                        <StyledRichTextEditor
                                            disabled={disabled}
                                            highlighting={isHighLight}
                                            config={config}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi direction="column" gap={6}>
                            <Text>描述</Text>
                            <Controller
                                control={control}
                                name={`list.${index}.introduction`}
                                render={({ field }) => {
                                    const isHighLight = getIsFindUseInRichText({
                                        doc: field.value,
                                        findUseObject,
                                        currentDsId: dsId
                                    })
                                    return (
                                        <StyledRichTextEditor
                                            disabled={disabled}
                                            highlighting={isHighLight}
                                            config={config}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                    </>
                )}
            </SCxContent>
        </SCxCollapse>
    )
}
