import { IconFont, pointer, Select } from '@byecode/ui'
import type { DataSourceAbstract, FieldBlockWithDsId, FieldType, SelectDataSourceVariable } from '@lighthouse/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import {
    innerTypeJoinOperatorMap,
    USER_DATASOURCE
} from '../../../../constants'
import { getFieldOptions, getInputVariableField, getIsMatchInnerTypeByOperator } from '../../../../utils'
import { FieldTypeTag } from '../../../FieldTypeTag'
import { getInputOption, getSystemOption, getUserDatasourceOptions } from '../../help'
import { VariableSelect } from '../index'

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

const SCxRemove = styled.div`
    ${pointer}
    padding: 2px;
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`

const SelectStyle = {
    width: '289px',
    flexShrink: 0,
    overflow: 'hidden'
}
const disabledFields = new Set<FieldType>(['notes', 'video', 'file'])

export interface MatchConditionProps {
    prefixName: `selectDataSourceVariable.filter.expression.conditions.${number}`
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    onRemove?: () => void
}

export const MatchCondition: React.FC<MatchConditionProps> = ({
    prefixName,
    dataSource,
    dataSourceList,
    fieldBlocksWithDsId,
    onRemove
}) => {

    const { control, setValue, watch } = useFormContext<SelectDataSourceVariable>()
    const leftFieldVariable = useWatch({
        control,
        name: `${prefixName}.idVariable`
    })

    const operator = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const leftField = useMemo(() => {
        const { schema } = dataSource
        return schema?.[leftFieldVariable?.fieldIdVariable?.fieldId]
    }, [dataSource, leftFieldVariable?.fieldIdVariable?.fieldId])

    const operatorOptions = useMemo(() => {
        if (!leftField) {
            return []
        }
        const { innerType } = leftField
        if (!innerType) {
            return []
        }
        return innerTypeJoinOperatorMap[innerType]
    }, [leftField])

    const leftFieldOptions = useMemo(
        () =>
            getFieldOptions(dataSource)
                .map(item => ({ ...item, extra: <FieldTypeTag type={item.type} innerType={item.innerType} /> }))
                .filter(item => !disabledFields.has(item.type)),
        [dataSource]
    )

    const systemOption = useMemo(
        () => (leftField?.type === 'date' ? getSystemOption(['TODAY', 'TOMORROW', 'YESTERDAY']) : undefined),
        [leftField?.type]
    )

    const inputOption = useMemo(
        () =>
            getInputOption(fieldBlocksWithDsId ?? [], {
                validateFieldType: fieldBlock => {
                    const blockId = fieldBlock.id
                    const inputField = getInputVariableField(blockId, dataSourceList, fieldBlocksWithDsId)
                    if (!inputField || disabledFields.has(inputField.type)) {
                        return false
                    }
                    return !!leftField && getIsMatchInnerTypeByOperator(leftField.innerType, operator, inputField.innerType)
                }
            }),
        [dataSourceList, fieldBlocksWithDsId, leftField, operator]
    )

    const userOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(ds => ds.id === USER_DATASOURCE),
                validateFieldType: field => !!leftField && getIsMatchInnerTypeByOperator(leftField.innerType, operator, field.innerType)
            }),
        [dataSourceList, leftField, operator]
    )

    return (
        <SCxContainer>
            <Controller
                control={control}
                name={`${prefixName}.idVariable.fieldIdVariable.fieldId`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: SelectStyle
                        }}
                        searchable
                        dropdownWidth={282}
                        position="bottom-start"
                        value={field.value}
                        options={leftFieldOptions}
                        onChange={val => {
                            field.onChange(val)
                            // setValue(`${prefixName}.rightFieldId`, '')
                            setValue(`${prefixName}.right`, undefined)
                            setValue(`${prefixName}.operator`, '=')
                        }}
                    />
                )}
            />
            <Controller
                control={control}
                name={`${prefixName}.operator`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: {
                                flex: 1,
                                flexShrink: 0
                            }
                        }}
                        disabled={!leftField}
                        value={field.value}
                        options={operatorOptions}
                        onChange={val => {
                            field.onChange(val)
                        }}
                    />
                )}
            />
            {!['isEmpty', 'isNotEmpty'].includes(operator) && (
                <Controller
                    control={control}
                    name={`${prefixName}.paramList.${0}`}
                    render={({ field }) => (
                        <VariableSelect
                            disabled={!leftField}
                            withinPortal
                            field={leftField}
                            value={field.value}
                            style={SelectStyle}
                            systemOption={systemOption}
                            userOption={userOption}
                            inputOption={inputOption}
                            width="100%"
                            onChange={field.onChange}
                        />
                    )}
                />
            )}
            <SCxRemove onClick={onRemove}>
                <IconFont type="Close" size={16} />
            </SCxRemove>
        </SCxContainer>
    )
}
