import {
    BlockThumbImageMap,
    BlockTypeIconMap
} from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type {
    BlockTree,
    BlockTreeItem
} from '../type'
import { BlockKind } from '../type'

export const blockIframe: BlockTreeItem = {
    name: '嵌入网页',
    type: BlockType.iframe,
    icon: BlockTypeIconMap['iframe'],
    describeImage: BlockThumbImageMap['iframe'],
    color: '#00A389',
    backGroundColor: 'rgba(0, 163, 137, 0.10)'
}

export const blockFieldGroup: BlockTreeItem = {
    name: '字段组',
    type: BlockType.fieldGroup,
    icon: BlockTypeIconMap['fieldGroup'],
    describeImage: BlockThumbImageMap['fieldGroup'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)'
}

export const blockCard: BlockTreeItem = {
    name: '图文导航',
    icon: BlockTypeIconMap.card,
    describeImage: BlockThumbImageMap['card'],
    color: '#F63D68',
    backGroundColor: 'rgba(246, 61, 104, 0.10)',
    type: BlockType.card
}

export const blockDriver: BlockTreeItem = {
    name: '分割线',
    icon: BlockTypeIconMap.divider,
    describeImage: BlockThumbImageMap['divider'],
    color: '#2E90FA',
    backGroundColor: 'rgba(46, 144, 250, 0.10)',
    type: BlockType.divider
}

export const blockBreadcrumb: BlockTreeItem = {
    name: '面包屑',
    icon: BlockTypeIconMap.breadcrumb,
    describeImage: BlockThumbImageMap['breadcrumb'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    type: BlockType.breadcrumb
}

export const blockCollapse: BlockTreeItem = {
    name: '折叠面板',
    icon: BlockTypeIconMap.collapse,
    describeImage: BlockThumbImageMap['collapse'],
    color: '#2E90FA',
    backGroundColor: 'rgba(46, 144, 250, 0.10)',
    type: BlockType.collapse
}

export const blockQrBarcode: BlockTreeItem = {
    name: '条码',
    icon: BlockTypeIconMap.qrBarcode,
    describeImage: BlockThumbImageMap['qrBarcode'],
    color: '#2E90FA',
    backGroundColor: 'rgba(46, 144, 250, 0.10)',
    type: BlockType.qrBarcode
}

export const allLayout: BlockTree = {
    id: BlockKind.layout,
    name: '排版',
    // icon: 'Input-1',
    color: '',
    items: [blockCard, blockDriver, blockBreadcrumb]
}
