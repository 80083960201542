import { Button, IconFont, Popover, SelectDropdown, singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { GroupFieldValue, ViewField } from '@lighthouse/core'
import { FieldTypeTag, getFieldIcon } from '@lighthouse/shared'
import { arrayMove } from '@lighthouse/tools'
import produce from 'immer'
import type { FC } from 'react'
import React, {
    useMemo, useState
} from 'react'
import styled, { css } from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

const Root = styled.div``

interface FieldListConfigureProps {
    columns: ViewField[]
    value?: GroupFieldValue[]
    onChange?: (value: GroupFieldValue[]) => void
}

export const FieldListConfigure: FC<FieldListConfigureProps> = ({ columns, value, onChange }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const fields = useMemo(() => value ?? [], [value])

    const [activeId, setActiveId] = useState<string | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                const newValue = arrayMove(fields, activeIndex, overIndex)
                onChange?.(newValue)
            }
        }
    }

    const onAppend = (data: GroupFieldValue) => {
        onChange?.([...fields, { ...data }])
    }

    const onToggleWrap = (index: number) => {
        const newValue = produce(fields, draft => {
            draft[index].wrap = !draft[index].wrap
        })
        onChange?.(newValue)
    }

    const onDelete = (index: number) => {
        const newValue = produce(fields, draft => {
            draft.splice(index, 1)
        })
        onChange?.(newValue)
    }

    const restOptions = useMemo(() => {
        return columns.filter(item => !fields.some(v => v.id === item.fieldId))
    }, [fields, columns])

    return (
        <>
            <Root>
                <DndContext
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor]}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext
                        disabled={disabledWithVersion}
                        items={fields.map(item => item.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        {fields.map((item, index) => (
                            <SortItem
                                key={item.id}
                                data={item}
                                viewField={columns.find(c => c.fieldId === item.id)}
                                onToggleWrap={() => onToggleWrap(index)}
                                onDelete={() => onDelete(index)}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </Root>

            <Popover width={160} withinPortal>
                <Popover.Target>
                    <Button style={{ margin: '4px 0' }} radius="100px" icon={<IconFont type="Add" />}>
                        添加字段
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <SelectDropdown
                        searchable
                        onSelect={v => onAppend({ id: v, wrap: true })}
                        options={restOptions.map(item => ({
                            icon: <IconFont type={getFieldIcon(item.fieldId, item.type, item.innerType)} />,
                            label: item.title,
                            value: item.fieldId,
                            extra: <FieldTypeTag type={item.type} />
                        }))}
                    />
                </Popover.Dropdown>
            </Popover>
        </>
    )
}

const Item = styled.div`
    padding: 6px 0;
`
const Content = styled.div`
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    outline: 1px solid var(--color-gray-200);
`
const Handler = styled.div`
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-100);
`
const Main = styled.div`
    flex: 1;
    padding: 8px;
    display: flex;
    align-items: center;
    line-height: 28px;
    background-color: var(--color-white);
`
const MainLeft = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;
`
const MainLabel = styled.div`
    flex: 1;
    font-size: 14px;
    ${singleTextEllipsis()}
`
const MainRight = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`
const ButtonWithoutBorder = styled(Button).withConfig<{ active?: boolean }>({ shouldForwardProp: p => p !== 'active' })`
    border: none;
    ${({ active }) =>
        active &&
        css`
            background-color: var(--color-theme-100);
            color: var(--color-main);
        `}
`

interface SortItemProps {
    data: GroupFieldValue
    viewField?: ViewField
    onToggleWrap: () => void
    onDelete: () => void
}

export const SortItem = ({ data, viewField, onToggleWrap, onDelete }: SortItemProps) => {
    const { setNodeRef, setActivatorNodeRef, listeners, attributes, transform, transition } = useSortable({ id: data.id })

    const icon = useMemo(() => {
        if (!viewField) {
            return 'PropertyMultipleSelect'
        }
        return getFieldIcon(viewField.fieldId, viewField.type, viewField.innerType)
    }, [viewField])

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <Item ref={setNodeRef} {...attributes} style={style}>
            <Content>
                <Handler ref={setActivatorNodeRef} {...listeners}>
                    <IconFont type="DotsSix" />
                </Handler>
                <Main>
                    <MainLeft>
                        <IconFont type={icon} />
                        <MainLabel>{viewField?.title}</MainLabel>
                    </MainLeft>
                    <MainRight>
                        <Tooltip title="换行">
                            <ButtonWithoutBorder
                                active={data.wrap}
                                onClick={onToggleWrap}
                                size="xs"
                                icon={<IconFont size={16} type="ParagraphBreakTwo" />}
                            />
                        </Tooltip>
                        <Tooltip title="删除">
                            <ButtonWithoutBorder onClick={onDelete} size="xs" icon={<IconFont size={16} type="Trash" />} />
                        </Tooltip>
                    </MainRight>
                </Main>
            </Content>
        </Item>
    )
}
