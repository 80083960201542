import { CollapseBox, useApplicationContext } from '@lighthouse/shared'
import { Divider, Group, Radio, Switch, Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSWR from 'swr'

import PersonSelect from '@/components/PersonSelect'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'

import * as SC from '../../styles'

const radioBodyFlex = {
    root: {
        width: '100%'
    }
}

export const ApproveSetting: React.FC = () => {
    const { control } = useFormContext()
    const { roleOptions: roles, personOptions: users } = useApplicationContext()

    const type: string = useWatch({
        control,
        name: 'config.type'
    })

    const approverSettingType: string = useWatch({
        control,
        name: 'config.approverSettingType'
    })

    const emptyApproverType: string = useWatch({
        control,
        name: 'config.emptyApprover.type'
    })

    return (
        <SC.Container>
            <CollapseBox label="审批类型">
                <SC.Content>
                    <Controller
                        name="config.type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <Radio.Group value={field.value} onChange={field.onChange}>
                                    <Group mt="xs">
                                        <Radio value="MANUALLY" label="人工审核" />
                                        <Radio value="AUTO" label="自动通过" />
                                    </Group>
                                </Radio.Group>
                            )
                        }}
                    />
                </SC.Content>
            </CollapseBox>
            {type === 'MANUALLY' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <CollapseBox label="审批人设置">
                        <SC.Content>
                            <Controller
                                name="config.approverSettingType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <Radio.Group value={field.value} onChange={field.onChange}>
                                            <Group mt="xs">
                                                <Radio value="SELECTED" label="指定审批人" />
                                            </Group>
                                        </Radio.Group>
                                    )
                                }}
                            />
                            {approverSettingType === 'SELECTED' && (
                                <Controller
                                    name="config.approvers"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <SC.Description style={{ marginBottom: 14 }}>添加审批人（不能超过 25 人）</SC.Description>
                                                <PersonSelect
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    userOptions={users}
                                                    roleOptions={roles}
                                                />
                                            </>
                                        )
                                    }}
                                />
                            )}
                        </SC.Content>
                    </CollapseBox>
                    <Divider color="var(--color-gray-200)" />
                    <CollapseBox label="多名审批人时采用的审批方式">
                        <SC.Content>
                            <Controller
                                name="config.signType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <Radio.Group value={field.value} onChange={field.onChange}>
                                            <Radio
                                                styles={radioBodyFlex}
                                                value="COUNTER_SIGN"
                                                label="会签"
                                                description="需所有审批人同意"
                                            />
                                            <Radio
                                                styles={radioBodyFlex}
                                                value="PARALLEL_SIGN"
                                                label="或签"
                                                description="一名审批人同意即可"
                                            />
                                            {/* <Radio value="AUTO" label="依次审批" description="按顺序同意或拒绝" /> */}
                                        </Radio.Group>
                                    )
                                }}
                            />
                        </SC.Content>
                    </CollapseBox>
                    <Divider color="var(--color-gray-200)" />
                    <CollapseBox label="审批人为空时" required>
                        <SC.Content>
                            <Controller
                                name="config.emptyApprover.type"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <Radio.Group value={field.value} onChange={field.onChange}>
                                            <Radio styles={radioBodyFlex} value="AGREE" label="自动通过" />
                                            <Radio styles={radioBodyFlex} value="REJECT" label="自动拒绝" />
                                            <Radio styles={radioBodyFlex} value="SELECTED" label="指定人员审批" />
                                        </Radio.Group>
                                    )
                                }}
                            />
                            {emptyApproverType === 'SELECTED' && (
                                <Controller
                                    name="config.emptyApprover.approvers"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <SC.Description style={{ marginBottom: 14 }}>添加审批人（不能超过 25 人）</SC.Description>
                                                <PersonSelect
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    userOptions={users}
                                                    roleOptions={roles}
                                                />
                                            </>
                                        )
                                    }}
                                />
                            )}
                        </SC.Content>
                    </CollapseBox>
                    <CollapseBox label="操作设置" required>
                        <Controller
                            name="config.requireComment"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <SC.BasicListItem position="apart">
                                        <Group spacing="xs" noWrap>
                                            <Text>审批时需填写审批意见</Text>
                                        </Group>
                                        <Switch checked={field.value} onChange={e => field.onChange?.(e.currentTarget.checked)} />
                                    </SC.BasicListItem>
                                )
                            }}
                        />
                    </CollapseBox>
                </>
            )}
        </SC.Container>
    )
}
