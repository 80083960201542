import { Divider, Flex, Group, Switch, Text } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

interface SettingCardProps {
    title?: React.ReactNode
    opened?: boolean
    describe?:  React.ReactNode
    isPadding?: boolean
    className?: string
    onChangeCollapse?: (v: boolean) => void
    rightSection?: React.ReactNode
    children?: React.ReactNode
}

const SCxContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
`
const SCxHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const SCxDescribe = styled.div`
    color: var(--color-gray-400);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
`

const SCxTitle = styled.div`
    color: var(--color-gray-900);

    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
`
const SCxContent = styled.div`
    width: 100%;
`
export const SettingCard = React.forwardRef<HTMLDivElement, SettingCardProps>(
    ({ isPadding, className, describe, title, children, rightSection, opened, onChangeCollapse }, ref) => {
        return (
            <SCxContainer ref={ref} className={className}>
                <SCxHeader>
                    <Flex alignItems="center" justifyContent="space-between">
                        <SCxTitle>{title} </SCxTitle>
                        {onChangeCollapse && <Switch checked={opened} onChange={e => onChangeCollapse(e.target.checked)} />}
                    </Flex>
                    {describe && <SCxDescribe>{describe}</SCxDescribe>}
                    {(onChangeCollapse ? opened : true) && <Divider />}
                </SCxHeader>
                {(onChangeCollapse ? opened : true) && <SCxContent>{children}</SCxContent>}
            </SCxContainer>
        )
    }
)
