import type { Option, SelectDropdownProps } from '@byecode/ui'
import { Flex, IconFont, Image, Input, Menu, Select, SelectDropdown, Text } from '@byecode/ui'
import type { ApplicationSettingLanguage, ApplicationSettingLanguageItem, PageAbstract } from '@lighthouse/core'
import { PageType } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { LANGUAGE_LIST } from '../../constants'

export type HandlePageInfo = { id: string; name?: string }

interface SelectPageProps {
    value?: string
    width?: number
    languageList?: ApplicationSettingLanguageItem[]
    options: (Option & { lang?: string })[]
    placeholder?: string
    readOnly?: boolean
    searchable?: boolean
    dropdownProps?: SelectDropdownProps
    style?: React.CSSProperties
    prefix?: React.ReactNode

    onChange?: (pageId: string) => void
}

const SCxTarget = styled.div``

const SCxDropDown = styled.div``

export const SelectPage: React.FunctionComponent<SelectPageProps> = ({
    value,
    width,
    languageList,
    options,
    searchable,
    placeholder = '请选择',
    readOnly = false,
    dropdownProps,
    prefix,
    style,
    onChange
}) => {
    const [menuOpened, setMenuOpened] = useState(false)
    const [langSearch, setLangSearch] = useState('')
    const [lang, setLang] = useState('')

    const langOptions = useMemo(() => {
        return (
            languageList
                ?.map(item => {
                    // const LANG = LANGUAGE_LIST.find(lang => lang.lang === item.lang)
                    return { value: item.lang, label: item.title }
                })
                .filter(item => item.label.toLocaleLowerCase().includes(langSearch.toLocaleLowerCase())) ?? []
        )
    }, [langSearch, languageList])

    const langPageOptions = useMemo(() => (lang ? options.filter(option => option.lang === lang) : options), [lang, options])

    const isSimpleLevel = (languageList ?? []).length < 2

    const option = useMemo(() => options?.find(item => item.value === value), [options, value])

    const inputValue = useMemo(() => {
        if (!value) {
            return
        }

        if (!option) {
            return '未找到页面'
        }

        return typeof option.label === 'string' ? option.label : option.value
    }, [option, value])

    useEffect(() => {
        if (menuOpened) {
            setLangSearch('')
            setLang('')
        }
    }, [menuOpened])

    if (isSimpleLevel) {
        return (
            <Select
                style={style}
                searchable={searchable}
                prefix={option ? prefix : undefined}
                options={langPageOptions}
                value={value}
                onChange={onChange}
                dropdownProps={dropdownProps}
                notFound="找不到页面"
            />
        )
    }

    return (
        <Menu
            opened={menuOpened}
            position="bottom-start"
            offsetOptions={{
                mainAxis: -2
            }}
            onChange={setMenuOpened}
            withinPortal
            width={200}
        >
            <Menu.Target>
                <SCxTarget>
                    <Input value={inputValue} readOnly prefix={option ? prefix : undefined} placeholder={placeholder} style={style} />
                </SCxTarget>
            </Menu.Target>
            <Menu.Dropdown>
                <SCxDropDown>
                    <Flex style={{ padding: '4px 12px 0 12px', marginBottom: 8 }}>
                        <Input value={langSearch} onChange={e => setLangSearch(e.target.value)} placeholder={placeholder} />
                    </Flex>
                    {langOptions.map(langOption => {
                        return (
                            <Menu
                                position="right"
                                width={200}
                                key={langOption.value}
                            >
                                <Menu.Target>
                                    <Menu.Item
                                        key={langOption.value}
                                        rightSection={<IconFont size={14} color="var(--color-gray-400)" type="ArrowRightSmall" />}
                                        // icon={<Image height={16} src={langOption.icon} fit="cover" />}
                                        onClick={() => setLang?.(langOption.value)}
                                    >
                                        <Text>{langOption.label}</Text>
                                    </Menu.Item>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <SelectDropdown
                                        {...dropdownProps}
                                        value={value}
                                        onSelect={v => {
                                            onChange?.(v)
                                            setMenuOpened(false)
                                        }}
                                        searchable={searchable}
                                        options={langPageOptions}
                                    />
                                </Menu.Dropdown>
                            </Menu>
                        )
                    })}
                </SCxDropDown>
            </Menu.Dropdown>
        </Menu>
    )
}
