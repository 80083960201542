import { DesktopModal, Empty, IconFont, Tag } from '@byecode/ui'
import type { FlowNode, WorkflowType } from '@lighthouse/shared'
import { APPLICATION_VERSION_PROD, CenteredWrapper } from '@lighthouse/shared'
import { Table } from '@mantine/core'
import { lightFormat } from 'date-fns'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import { FlowEditor } from '@/containers/FlowDetail/components/FlowEditor'
import { FlowProvider } from '@/contexts/FlowContext'
import * as srv from '@/services'
import type { WorkflowRunLogs } from '@/services/types'

import { RunLogDetailDrawer } from '../RunLogDetailDrawer'

export interface StatusTableProps {
    showNameField?: boolean
    data: WorkflowRunLogs
}

const SCxStatusTableWrapper = styled.div`
    height: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;
`

const SCxStatusTableContent = styled(Table)`
    background-color: var(--color-white);
`

const SCxStatusTableTr = styled.tr``

const SCxStatusTableTh = styled.th`
    font-weight: var(--font-weight-normal) !important;
    border: none !important;
    background-color: var(--color-gray-100);
`

const SCxStatusTableTd = styled.td`
    border: none !important;
    color: var(--color-gray-900);
    height: 50px;
`

const SCxIconCellWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SCxStatusTableHeader = styled.thead`
    position: sticky;
    top: 0;
`

const SCxLink = styled.a`
    color: var(--color-main);
    cursor: pointer;
`

const SCxFlowDetailModalWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const workFlowInfoMap: Record<WorkflowType, { icon: string; color: string }> = {
    automation: { icon: 'FlowArrow', color: 'var(--color-green-500)' },
    approval: { icon: 'Approval', color: 'var(--color-purple-500)' },
    action: { icon: 'MouseClick', color: 'var(--color-blue-500)' },
    subProcess: { icon: 'Reorder', color: 'var(--color-gray-500)' },
}

const statusIconMap = {
    all: '',
    RUNNING: 'Spinner',
    SUCCESS: 'TickCircle',
    FAILED: 'WarningCircleFilled'
}

const statusIconColorMap = {
    all: '',
    RUNNING: 'var(--color-blue-500)',
    SUCCESS: 'var(--color-green-500)',
    FAILED: 'var(--color-red-500)'
}

const statusLabelMap = {
    all: '全部',
    RUNNING: '运行中',
    SUCCESS: '已完成',
    FAILED: '已失败'
}

export const StatusTable: React.FC<StatusTableProps> = ({ showNameField, data }) => {
    const [currentFlowState, setCurrentFlowState] = useState<{ id: string; name: string } | null>(null)
    const [currentNode, setCurrentNode] = useState<FlowNode | null>(null)
    const { data: flowDetail } = useSWR(
        currentFlowState?.id,
        async () => {
            if (!currentFlowState) {
                return null
            }
            // eslint-disable-next-line no-return-await
            return await srv.getFlowLog(currentFlowState.id)
        },
        {
            revalidateOnFocus: false
        }
    )

    const handleOpenLogDetailModal = useCallback(
        (flowInstanceId: string, flowName: string) => {
            setCurrentFlowState({ id: flowInstanceId, name: flowName })
        },
        [setCurrentFlowState]
    )

    const rows = data.map(element => {
        const workflowInfo = workFlowInfoMap[element.workflowType ?? 'automation']
        const isProdVersion = element.versionId === APPLICATION_VERSION_PROD
        return (
            <SCxStatusTableTr key={element.id}>
                {showNameField && (
                    <SCxStatusTableTd>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tag
                                style={{
                                    fontSize: 12,
                                    padding: '3px 4px',
                                    marginRight: 8
                                }}
                                color={isProdVersion ? 'var(--color-green-500)' : 'var(--color-gray-600)'}
                                background={isProdVersion ? 'var(--color-green-100)' : 'var(--color-gray-100)'}
                                radius={4}
                            >
                                {isProdVersion ? '线上' : '测试'}
                            </Tag>
                            <IconFont
                                size={20}
                                style={{
                                    marginRight: 6,
                                    color: 'var(--color-white)',
                                    backgroundColor: workflowInfo.color,
                                    padding: 4,
                                    borderRadius: 4
                                }}
                                type={workflowInfo.icon}
                            />
                            {element.name}
                        </div>
                    </SCxStatusTableTd>
                )}
                <SCxStatusTableTd>
                    <SCxIconCellWrapper>
                        <IconFont
                            style={{ marginRight: 8 }}
                            size={18}
                            color={statusIconColorMap[element.status]}
                            type={statusIconMap[element.status]}
                        />
                        {statusLabelMap[element.status]}
                    </SCxIconCellWrapper>
                </SCxStatusTableTd>
                <SCxStatusTableTd>{element.operator}</SCxStatusTableTd>
                <SCxStatusTableTd>{lightFormat(element.executionTime, 'yyyy-MM-dd HH:mm:ss')}</SCxStatusTableTd>
                <SCxStatusTableTd>
                    <SCxLink onClick={() => handleOpenLogDetailModal(element.workflowInstanceId, element.name)}>查看</SCxLink>
                </SCxStatusTableTd>
            </SCxStatusTableTr>
        )
    })

    return (
        <SCxStatusTableWrapper>
            <SCxStatusTableContent>
                <SCxStatusTableHeader>
                    <SCxStatusTableTr>
                        {showNameField && <SCxStatusTableTh>流程名称</SCxStatusTableTh>}
                        <SCxStatusTableTh>执行状态</SCxStatusTableTh>
                        <SCxStatusTableTh>操作人</SCxStatusTableTh>
                        <SCxStatusTableTh>运行时间</SCxStatusTableTh>
                        <SCxStatusTableTh>操作</SCxStatusTableTh>
                    </SCxStatusTableTr>
                </SCxStatusTableHeader>
                <tbody>{rows}</tbody>
            </SCxStatusTableContent>
            {data.length === 0 && (
                <CenteredWrapper style={{ width: '100%', height: 'calc(100% - 36px)', padding: '60px 0' }}>
                    <Empty icon="Nodata-8i554976" description="暂无数据" />
                </CenteredWrapper>
            )}
            <DesktopModal
                open={!!currentFlowState}
                title={`${currentFlowState?.name}运行日志详情`}
                width={window.innerWidth}
                styles={{
                    modal: {
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        height: '100%',
                        borderRadius: 0
                    },
                    body: {
                        flex: 1,
                        padding: 0,
                        position: 'relative',
                        overflow: 'hidden'
                    }
                }}
                onClose={() => setCurrentFlowState(null)}
            >
                <SCxFlowDetailModalWrapper>
                    {flowDetail && (
                        <FlowProvider readonly type={flowDetail.type}>
                            <FlowEditor
                                styles={{ wrapper: {} }}
                                type={flowDetail.type}
                                nodes={flowDetail.content?.nodes || []}
                                edges={flowDetail.content?.edges || []}
                                onNodeClick={
                                    // handleNodeClick
                                    (node: FlowNode) => {
                                        setCurrentNode(node)
                                    }
                                }
                                onSelectChange={(selected: boolean) => {
                                    if (!selected) {
                                        setCurrentNode(null)
                                    }
                                }}
                            />
                            {currentNode && <RunLogDetailDrawer node={currentNode} />}
                        </FlowProvider>
                    )}
                </SCxFlowDetailModalWrapper>
            </DesktopModal>
        </SCxStatusTableWrapper>
    )
}
