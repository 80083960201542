import { Breadcrumbs, IconFont, SegmentedControl } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol } from '@lighthouse/core'
import { FieldInputNameMap, getFieldOptions, getPrimaryDataSourceEnableFieldIds } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React, {
    useMemo, useState
} from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { BUTTON_TABS, TabEnum } from '../../constants'
import { VisibilityFilter } from '../VisibilityFilter'
import { Data } from './Data'
import { Style } from './Style'
import type { FieldInputSettingProps } from './types'

const SCxContainer = styled.div``
const SCxItem = styled.div``

export const FieldInputSetting: React.FunctionComponent<FieldInputSettingProps> = ({
    blockId,
    mode,
    dataSourceList,
    prefix = '',
    pointer,
    style,
    onBack
}) => {
    const { watch } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const inputType = watch(`${prefix}inputType`)
    const [currentTab, setCurrentTab] = useState<string>('normal')

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const dataSource = useDataSource(appId, envId, pointer)

    const fieldOptions = useMemo(() => getFieldOptions(dataSource), [dataSource])

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList || [])
    }, [dataSource, dataSourceList])

    const filterFieldOptions = useMemo(
        () =>
            fieldOptions.filter(item => {
                return primaryDataSourceFieldIds?.has(item.value)
            }),
        [fieldOptions, primaryDataSourceFieldIds]
    )

    return (
        <SCxContainer style={style}>
            {mode === 'subForm' && (
                <>
                    <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: FieldInputNameMap[inputType], onClick: onBack }]} />
                    <Divider />
                </>
            )}
            <div style={{ padding: '12px 16px 0 16px' }}>
                <SegmentedControl disabled={false} size="lg" data={BUTTON_TABS} fullWidth value={currentTab} onChange={setCurrentTab} />
            </div>

            {/* 数据 */}
            <SCxItem key={TabEnum.data} hidden={currentTab !== 'normal'}>
                <Data pointer={pointer} prefix={prefix} mode={mode} fieldOptions={filterFieldOptions} />
                <Divider color="var(--color-gray-200)" />
                {mode !== 'subForm' && <VisibilityFilter />}
            </SCxItem>
            {/* 用户 */}
            {/* <SCxItem key={TabEnum.operation} hidden={currentTab !== TabEnum.operation} style={{ marginTop: 12 }}>

            </SCxItem> */}
            {/* 设计 */}
            <SCxItem key={TabEnum.style} hidden={currentTab !== 'style'}>
                <Style blockId={blockId} prefix={prefix} pointer={pointer} mode={mode} />
            </SCxItem>
        </SCxContainer>
    )
}
