import React, { useCallback } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'

import { LocationUsedItem } from './LocationUsedItem'

const SCxLocationUsedListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

interface LocationUsedListProps {}

export const LocationUsedList: React.FC<LocationUsedListProps> = () => {
    const { id: flowId } = useFlow()
    const appId = useCurrentAppID()
    const { value = [] } = useAsyncRetry(async () => {
        if (!flowId) {
            return
        }
        const list = await srv.getBeUsedSubProcess({ workflowId: flowId })
        if (!list) {
            return
        }
        return list
    }, [flowId])

    const handleLink = useCallback((flowId: string) => {
        const { host } = window.location
        const url = `https://${host}/${appId}/flow/${flowId}`
        window.open(url, '_blank')
    }, [appId])

    return (
        <SCxLocationUsedListWrapper>
            {value.map(item => {

                return <LocationUsedItem key={item.nodeId} data={item} onLink={handleLink} />
            })}
        </SCxLocationUsedListWrapper>
    )
}
