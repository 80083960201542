import { Select } from '@byecode/ui'
import type { FlowNode, LoopTypeEnum } from '@lighthouse/shared'
import {
    canAsUpstreamMultipleRecordNodeTypes,
    getUpstreamNodeOption
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import * as SC from '../../styles'

interface SelectRecordProps {
    allParentNodes: FlowNode[]
}

export const SelectRecord: React.FC<SelectRecordProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const { control } = useFormContext()

    const nodeOptions = useMemo(() => {
        return getUpstreamNodeOption(allParentNodes, dataSourceList, node => canAsUpstreamMultipleRecordNodeTypes.has(node.data.nodeType))
    }, [allParentNodes, dataSourceList])

    return (
        <SC.FormItem>
            <SC.FormItemLabelWrapper>
                <SC.FormItemLabel>选择上游节点</SC.FormItemLabel>
            </SC.FormItemLabelWrapper>
            <SC.FormItemContent>
                <Controller
                    name="config.recordsConfig.nodeId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            options={nodeOptions}
                            onChange={val => {
                                if (field.value === val) {
                                    return
                                }
                                field.onChange(val)
                            }}
                        />
                    )}
                />
            </SC.FormItemContent>
        </SC.FormItem>
    )
}
