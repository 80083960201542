import { IconFont, Tooltip } from '@byecode/ui'
import type { TriggerFlowNode } from '@lighthouse/shared'
import { getNodeInfo } from '@lighthouse/shared'
import cls from 'classnames'
import { lightFormat } from 'date-fns'
import React, { memo, useMemo } from 'react'
import { Handle, Position } from 'reactflow'
import styled from 'styled-components'

import { workflowLabelMap } from '@/constants/flow'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { validateNode } from '../../utils/nodeValidator'

export interface TriggerNodeProps extends TriggerFlowNode {
    isConnectable?: boolean
}

const SCxTriggerNodeWrapper = styled.div`
    position: relative;
    display: flex;
    width: 268px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
`
const SCxErrorInfoIconButton = styled.div`
    width: 18px;
    height: 18px;
    position: absolute;
    left: -8px;
    top: -8px;
    z-index: 1;
    background-color: var(--color-white);
`

const SCxErrorInfoIcon = styled(IconFont)`
    font-size: 24px;
    color: var(--color-red-500);
`

const SCxActionIconWrapper = styled.div`
    position: relative;
    display: flex;
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    flex-shrink: 0;

    &.flow-node-selected {
        border-color: var(--color-main);
    }
`

const SCxActionIcon = styled(IconFont)``

const SCxActionStatusIconWrapper = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid var(--color-white);

    &.flow-icon-success {
        background-color: var(--color-green-500);
    }
    &.flow-icon-failed {
        background-color: var(--color-red-500);
    }
    &.flow-icon-running {
        background-color: var(--color-blue-500);
    }
    &.flow-icon-not-start {
        background-color: var(--color-gray-500);
    }
`

const SCxActionSettingButton = styled(IconFont)`
    cursor: pointer;
    color: var(--color-white);
`

const SCxActionContentWrapper = styled.div`
    position: absolute;
    left: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    font-weight: 400;
`

const SCxActionTitle = styled.div`
    margin-bottom: 4px;
    font-size: 14px;
    color: #383838;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxActionDescription = styled.div`
    display: -webkit-box;
    font-size: 12px;
    color: #7c8492;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    margin-bottom: 4px;
`

const SCxActionErrorInfo = styled.div`
    font-size: 12px;
    color: var(--color-red-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
`

const TriggerNodeDescription: React.FC<TriggerNodeProps> = ({ data: { nodeType, config, status } }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dsId = config && 'dataSourceId' in config ? config.dataSourceId || '' : ''
    const dataSource = useDataSource(appId, envId, dsId)
    if (nodeType === 'ARG_TRIGGER') {
        return null
    }
    return <SCxActionDescription>{dataSource?.name || '请选择数据表'}</SCxActionDescription>
}

const TriggerNode: React.FC<TriggerNodeProps> = props => {
    const {
        id,
        selected,
        data: { nodeType, name, config, status },
        isConnectable
    } = props
    const nodeInfo = useMemo(() => getNodeInfo(nodeType), [nodeType])
    const { type = 'automation', errorMap } = useFlow()

    const errorMessage = useMemo(() => !validateNode(props) || errorMap?.[id], [errorMap, id, props])

    const logStatus = useMemo(() => {
        if (status) {
            const errorMessage = status.status === 'FAILED' ? <SCxActionErrorInfo>异常原因：{status.log}</SCxActionErrorInfo> : null
            return (
                <>
                    {status.executeTime && (
                        <SCxActionDescription>执行时间：{lightFormat(status.executeTime, 'yyyy/MM/dd HH:mm:ss')}</SCxActionDescription>
                    )}
                    {errorMessage}
                </>
            )
        }
        return null
    }, [status])

    const nodeContentStyles = useMemo<React.CSSProperties>(() => {
        if (status) {
            return {
                position: 'absolute',
                left: 72
            }
        }
        return {
            position: 'initial',
            padding: '8px 12px'
        }
    }, [status])

    const iconClassNames = useMemo(() => {
        if (status) {
            return cls({
                'flow-icon-success': status.status === 'SUCCESS',
                'flow-icon-failed': status.status === 'FAILED',
                'flow-icon-running': status.status === 'RUNNING'
                // 'flow-icon-not-start': status.status === 'NOT_START'
            })
        }
    }, [status])

    const statusIconType = useMemo(() => {
        switch (status?.status) {
            case 'SUCCESS': {
                return 'Tick'
            }
            case 'FAILED': {
                return 'Close'
            }
            case 'RUNNING': {
                return 'Repeat'
            }
            // case 'NOT_START': {
            //     return 'Clock'
            // }
            default: {
                return ''
            }
        }
    }, [status])

    const bgColor = useMemo(() => {
        switch (nodeType) {
            case 'CLICK_TRIGGER': {
                return workflowLabelMap[type].color
            }
            case 'INITIATE_APPROVE_TRIGGER': {
                return workflowLabelMap['approval'].color
            }
            case 'ARG_TRIGGER': {
                return workflowLabelMap['subProcess'].color
            }
            default: {
                return workflowLabelMap['automation'].color
            }
        }
    }, [nodeType, type])

    return (
        <SCxTriggerNodeWrapper>
            {errorMessage && (
                <Tooltip title="配置未完善" placement="top">
                    <SCxErrorInfoIconButton>
                        <SCxErrorInfoIcon type="WarningCircle" />
                    </SCxErrorInfoIconButton>
                </Tooltip>
            )}
            <SCxActionIconWrapper
                className={cls({
                    'flow-node-selected': selected
                })}
                style={{ backgroundColor: bgColor }}
            >
                <SCxActionIcon size={36} type={nodeInfo.icon} />
                {status && (
                    <SCxActionStatusIconWrapper className={iconClassNames}>
                        <SCxActionSettingButton size={12} type={statusIconType} />
                    </SCxActionStatusIconWrapper>
                )}
            </SCxActionIconWrapper>
            <SCxActionContentWrapper style={nodeContentStyles}>
                <SCxActionTitle>{name}</SCxActionTitle>
                {!['SCHEDULED_TRIGGER'].includes(nodeType) && <TriggerNodeDescription {...props} />}
                {logStatus}
            </SCxActionContentWrapper>
            <Handle
                type="source"
                position={Position.Bottom}
                id="handle"
                style={{ bottom: -4, top: 'auto', left: 30, opacity: 0 }}
                isConnectable={isConnectable}
            />
        </SCxTriggerNodeWrapper>
    )
}

export default memo(TriggerNode)
