import React, { useEffect, useMemo, useRef, useState } from 'react'

import * as SC from './styles'

export interface ColorHueBarCanvasProps {
    h: number
    onHueChange: (x: number, confirmed?: boolean) => void
}

export const ColorHueBarCanvas: React.FC<ColorHueBarCanvasProps> = ({ h, onHueChange }) => {
    const [isMoving, setIsMoving] = useState(false)
    const newXy = useRef({ x: 0, y: 0 })

    const hueSliderTranslateXStyle = useMemo(() => ({ transform: `translate(${h - 2}px)` }), [h])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onHueChange(e.nativeEvent.offsetX, true)
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsMoving(true)
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isMoving) {
            onHueChange(e.nativeEvent.offsetX)
            newXy.current = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
        }
    }

    useEffect(() => {
        const mouseUpHandler = () => {
            if (isMoving) {
                setIsMoving(false)
                onHueChange(newXy.current.x, true)
            }
        }
        document.addEventListener('mouseup', mouseUpHandler)

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler)
        }
    }, [isMoving, onHueChange])

    return (
        <SC.ColorPickerHueSlider>
            <SC.ColorPickerHueCanvas onClick={handleClick} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} />
            <SC.ColorPickerHueBar style={hueSliderTranslateXStyle} />
        </SC.ColorPickerHueSlider>
    )
}
