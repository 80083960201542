import { Button, Empty, Flex, Image, Input, Modal, Text } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationSettingLanguage, ApplicationSettingLanguageItem } from '@lighthouse/core'
import { LANGUAGE_LIST, SelectLanguage, TagIcon, useAtomAction, useAtomData } from '@lighthouse/shared'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { setLanguageAtom } from '@/atoms/application/action'
import { languageAtom } from '@/atoms/application/state'
import { createPageAtom } from '@/atoms/page/action'
import { useSpaceQuota } from '@/shared/reusable'

import * as CM from '../styles'

interface LanguageListProps {}

const SCxContainer = styled.div`
    width: 100%;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
`
const SCxItemContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
`

const SCxItem = styled(Flex)`
    display: flex;
    align-items: center;
`

const SCxList = styled(Flex)`
    width: 100%;
    flex-direction: column;
    > * {
        border-bottom: 1px solid var(--color-gray-200);
    }
`
interface LanguageItemProps {
    value: ApplicationSettingLanguageItem & { id: string }
    prefix: `list.${number}`
    disableRemove?: boolean
    onDelete: () => void
    onMove?: (direction: -1 | 1) => void
}
const options = LANGUAGE_LIST.map(item => ({ label: item.name, value: item.lang, describe: item.langDemo, icon: item.flag }))

const LanguageItem: React.FunctionComponent<LanguageItemProps> = ({ value, prefix, disableRemove, onDelete, onMove }) => {
    const { control, register } = useFormContext<ApplicationSettingLanguage>()

    const { id, lang } = value
    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id
    })
    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition
        }),
        [transform, transition]
    )

    const option = options.find(option => option.value === lang)

    return (
        <SCxItemContainer style={style}>
            <SCxItem style={{ width: '25%' }}>
                <Text>{option?.label}</Text>
            </SCxItem>
            <SCxItem style={{ width: '25%' }}>
                <Input style={{ width: 180 }} {...register(`${prefix}.title`)} />
            </SCxItem>
            <SCxItem style={{ width: '50%' }} justifyContent="flex-end" gap={4}>
                <TagIcon size={32} icon="ArrowUp" onClick={() => onMove?.(-1)} color="var(--color-gray-400)" />
                <TagIcon size={32} icon="ArrowDown" onClick={() => onMove?.(1)} color="var(--color-gray-400)" />
                <TagIcon
                    size={32}
                    icon="Trash"
                    iconColor={disableRemove ? 'var(--color-gray-300)' : 'var(--color-gray-400)'}
                    style={{ cursor: disableRemove ? 'not-allowed' : 'pointer' }}
                    onClick={disableRemove ? undefined : onDelete}
                />
            </SCxItem>
        </SCxItemContainer>
    )
}

export const LanguageList: React.FunctionComponent<LanguageListProps> = props => {
    const { control } = useFormContext<ApplicationSettingLanguage>()
    const { run: createPage } = useAtomAction(createPageAtom)
    const { data: quota } = useSpaceQuota()

    const [opened, setOpened] = useState(false)

    const { fields, remove, append, move } = useFieldArray({
        name: 'list',
        control
    })

    const language = useAtomData(languageAtom)
    const { run: setLanguage } = useAtomAction(setLanguageAtom)

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const noUsedOptions = useMemo(
        () =>
            options.filter(
                option =>
                    !fields
                        .map(item => item.lang)
                        .toString()
                        .includes(option.value)
            ),
        [fields]
    )

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.id === active.id)
                const newIndex = fields.findIndex(item => item.id === over?.id)
                move?.(oldIndex, newIndex)
            }
        },
        [fields, move]
    )

    const handleDelete = useCallback(
        async (v: ApplicationSettingLanguageItem, i: number) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除？',
                content: `删除后「${v.title}」的所有页面、导航栏配置、登录配置都将被清空，且不可恢复，请谨慎操作！`,
                okText: '删除'
            })
            if (isConfirm) {
                if (v.lang === language) {
                    const lang = fields.find(item => item.lang !== v.lang)?.lang
                    lang && setLanguage(lang)
                }
                remove(i)
            }
        },
        [fields, language, remove, setLanguage]
    )

    return (
        <SCxContainer>
            {fields.length > 0 ? (
                <>
                    <SCxList>
                        <SCxItemContainer>
                            <SCxItem style={{ width: '25%' }}>
                                <Text color="var(--color-gray-500)">语言</Text>
                            </SCxItem>
                            <SCxItem style={{ width: '25%' }}>
                                <Text color="var(--color-gray-500)">选项标题</Text>
                            </SCxItem>
                        </SCxItemContainer>
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={fields.map(d => d.id ?? '')} strategy={verticalListSortingStrategy}>
                                {fields.map((item, index) => (
                                    <LanguageItem
                                        key={item.lang}
                                        prefix={`list.${index}`}
                                        value={item}
                                        disableRemove={fields.length < 2}
                                        onDelete={() => handleDelete(item, index)}
                                        onMove={direction => move(index, index + direction)}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </SCxList>
                    {noUsedOptions.length > 0 && (
                        <SelectLanguage
                            targetComponent={
                                <Button radius={100} icon={<CM.Icon type="Add" />}>
                                    添加
                                </Button>
                            }
                            closeOnItemClick
                            searchable
                            opened={opened}
                            onChange={setOpened}
                            options={noUsedOptions}
                            onClick={v => {
                                append({ lang: v, title: options.find(option => option.value === v)?.describe ?? '' })
                                createPage({ lang: v, name: '主页' })
                            }}
                        />
                    )}
                </>
            ) : (
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" gap={20} style={{ width: '100%' }}>
                        <Empty
                            styles={{
                                root: {
                                    width: 270,
                                    color: 'var(--color-gray-500)'
                                },
                                wrapper: {
                                    gap: 20
                                }
                            }}
                            icon={<Image width={123} height={123} src={getAssetUrl('empty', 'empty_language_setting.jpg')} />}
                            description="当前只有“中文 (简体)”的语言版本，如果您想切换多语言版本，请先添加语言"
                        />
                        <SelectLanguage
                            targetComponent={<Button icon={<CM.Icon type="Add" />}>添加</Button>}
                            closeOnItemClick
                            searchable
                            opened={opened}
                            onChange={setOpened}
                            options={noUsedOptions}
                            position="bottom-start"
                            onClick={v => {
                                append({ lang: v, title: options.find(option => option.value === v)?.describe ?? '' })
                                createPage({ lang: v, name: '主页' })
                            }}
                        />
                    </Flex>
                </>
            )}
        </SCxContainer>
    )
}
