import { Breadcrumbs, Button, Divider, Empty, Flex, Group, IconFont, Modal, Toast } from '@byecode/ui'
import type { PrintProtocol } from '@lighthouse/core'
import { FilePreviewer, getImageFullUrlInApplication, ListItem4ByecodeUi, useApplicationContext } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { useToggle } from 'react-use'
import useSWR from 'swr'

import { useCurrentAppVersionId } from '@/hooks/useApplication'
import * as srv from '@/services'

import { InnerDrawer } from '../../InnerDrawer'
import { listCommonStyles } from './constants'
import type { ExportTemplateFormValues } from './ExportTemplateClickDropZone'
import ExportTemplateClickDropZone from './ExportTemplateClickDropZone'
import * as SC from './styles'

interface AllowPrintConfiguratorProps {
    value: PrintProtocol
    dsId: string
    onChange?: (v: PrintProtocol) => void
    onBack?: () => void
}
interface AllowPrintSettingProps {
    title: string
    type: string
    value: PrintProtocol
    dsId: string
    onChange?: (v: PrintProtocol) => void
}

const AllowPrintConfigurator: React.FC<AllowPrintConfiguratorProps> = ({ value, dsId, onChange, onBack }) => {
    const { appId } = useApplicationContext()
    // eslint-disable-next-line no-return-await
    const { data, mutate } = useSWR(['template-list', dsId], async () => await srv.getPrintTemplateList(dsId), {
        revalidateOnFocus: false
    })
    const { canPrint = false } = value
    const [adderOpened, toggleAdder] = useToggle(false)
    const [filePreviewerOpened, toggleFilePreviewer] = useToggle(false)

    const templates = useMemo(() => {
        if (!data) {
            return []
        }
        return data.map(item => ({ ...item, url: getImageFullUrlInApplication(appId, item.url), type: 'xls' }))
    }, [appId, data])

    const handleChange = useCallback(
        (params: Partial<PrintProtocol>) => {
            onChange?.({ ...value, ...params })
        },
        [onChange, value]
    )

    const handleSubmit = useCallback(
        async (values: ExportTemplateFormValues) => {
            const { templateName, file } = values
            if (!file) {
                return
            }
            await srv.uploadPrintTemplate({ dsId, name: templateName }, file)

            Toast.success('上传成功')
            mutate()

            toggleAdder()
        },
        [dsId, mutate, toggleAdder]
    )

    const handleTemplateDelete = useCallback(
        async (templateId: string) => {
            const isAccepted = await Modal.confirm({
                title: '确认删除该模板吗？',
                content: '删除后，该模板将无法恢复'
            })
            if (isAccepted) {
                await srv.deletePrintTemplate({ dsId, templateId })
                Toast.success('删除成功')
                mutate()
            }
        },
        [dsId, mutate]
    )

    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '打印', onClick: onBack }]} />
            <Divider color="var(--color-gray-200)" />
            <Group
                mode="switch"
                label={canPrint ? '已开启' : '已关闭'}
                opened={canPrint}
                onCollapseChange={v => handleChange({ canPrint: v })}
                collapseProps={{ style: { padding: 0 } }}
            />
            <Divider color="var(--color-gray-200)" />
            {canPrint && (
                <Group label="打印模板">
                    <SC.ExportListCardWrapper>
                        {templates?.length ? (
                            templates.map(template => (
                                <SC.ExportListCard key={template.templateId}>
                                    <SC.ExportListCardInfo>
                                        <SC.ExportListCardTitle>{template.name}</SC.ExportListCardTitle>
                                        <SC.ExportListCardDesc>
                                            <div>创建方式</div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <IconFont type="FileXsl" size={18} style={{ marginRight: 4 }} /> Excel 模板
                                            </div>
                                        </SC.ExportListCardDesc>
                                    </SC.ExportListCardInfo>
                                    <SC.ExportListCardActionWrapper>
                                        <Button type="text" onClick={toggleFilePreviewer} icon={<IconFont type="Setting" />}>
                                            预览
                                        </Button>
                                        <Button
                                            type="text"
                                            onClick={() => handleTemplateDelete(template.templateId)}
                                            icon={<IconFont type="Trash" />}
                                        >
                                            删除
                                        </Button>
                                    </SC.ExportListCardActionWrapper>
                                </SC.ExportListCard>
                            ))
                        ) : (
                            <Empty description="暂无打印模板" styles={{ root: { padding: '40px 0' } }} />
                        )}
                    </SC.ExportListCardWrapper>
                    <Button style={{ color: 'var(--color-gray-900)' }} radius={100} icon={<IconFont type="Add" />} onClick={toggleAdder}>
                        通过 Excel 模板创建
                    </Button>
                    {/* upload template modal */}
                    <Modal
                        open={adderOpened}
                        title="通过 Excel 模板创建"
                        styles={{
                            desktop: {
                                modal: { overflow: 'hidden' },
                                body: { padding: '24px 24px 0 24px' }
                            }
                        }}
                        onClose={toggleAdder}
                    >
                        <ExportTemplateClickDropZone dsId={dsId} onCancel={toggleAdder} onSubmit={handleSubmit} />
                    </Modal>
                    {/* file previewer */}
                    <FilePreviewer
                        fileList={templates}
                        defaultIndex={0}
                        opened={filePreviewerOpened}
                        // target={document.body}
                        onClose={toggleFilePreviewer}
                    />
                </Group>
            )}
        </InnerDrawer>
    )
}

export const AllowPrintSetting: FC<AllowPrintSettingProps> = ({ title, type, value, dsId, onChange, ...rest }) => {
    const { canPrint } = value
    const [open, toggle] = useToggle(false)
    return (
        <>
            <ListItem4ByecodeUi
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <SC.StyledIconWrapper active={canPrint}>
                        <IconFont type={type} size={18} fill={canPrint ? 'var(--color-main)' : 'var(--color-gray-400)'} />
                    </SC.StyledIconWrapper>
                    <div>{title}</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{canPrint ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>
            {open && <AllowPrintConfigurator value={value} dsId={dsId} onChange={onChange} onBack={() => toggle(false)} />}
        </>
    )
}
