import { Toast } from '@byecode/ui/components/Toast'
import type { BlockAbstract, PageNode } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import { FLOW_LAYOUT_NODE_ROWS, useAtomAction, useAtomData } from '@lighthouse/shared'
import { current, original } from 'immer'
import { useCallback } from 'react'

import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { pageBlocksAtom, pageNodesAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'
import {
    BLOCK_NODE_CREATE_ACTION,
    BLOCK_NODE_REMOVE_ACTION,
    NODE_UPDATE_ACTION,
    pageUndoRedoController
} from '@/utils/undoRedo/page/controller'

import { collectRemovedBlockIds, copyBlocksAndNodes, findPageNodeById, findParentPageNodeById, insertNodeByOverId, removeNodeById } from './utils'

interface Params {
    pageId: string
    rootPageId: string
    stackId: string
}

/**
 * 一些操作nodes的方法
 * @returns actions functions
 */
export const useNodeToolbarActions = ({ stackId, rootPageId, pageId }: Params) => {
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const blockRuntimeState = useAtomData(
        pageStackAtom,
        useCallback(s => equalPageStack({ rootPageId, stackId })(s)?.blockRuntimeState, [rootPageId, stackId])
    )

    const { run: setPageNodes } = useAtomAction(pageNodesAtom)
    const { run: createBlock } = useAtomAction(createBlockAtom)
    const { run: removeBlock } = useAtomAction(removeBlockAtom)

    const currentAppId = useCurrentAppID()

    // 复制block
    const onDuplicate = useCallback(
        async (id: string) => {
            const copiedNode = findPageNodeById(id)(nodes)
            if (!copiedNode) {
                return
            }

            const parentPageNode = findParentPageNodeById(copiedNode.id)(nodes, blocks)
            const parentBlock = parentPageNode && blocks.find(item => item.id === parentPageNode.id)
            const isContainerParent =
                parentBlock?.type === BlockType.container ||
                parentBlock?.type === BlockType.formContainer ||
                (parentBlock?.type === BlockType.view && parentBlock.config.viewType === 'custom')
            const parentDirection = isContainerParent ? parentBlock.config.design?.direction : DIRECTION.vertical

            if (parentPageNode && parentDirection === DIRECTION.horizontal) {
                const children =
                    parentBlock?.type === BlockType.container
                        ? parentPageNode.children?.find(view => view.id === blockRuntimeState?.container?.[parentPageNode.id].currentView)
                              ?.children
                        : parentPageNode.children
                const oldSize = children ? children.reduce((prev, curr) => prev + (curr.minWidth ?? 1), 0) : 0
                const totalSize = oldSize + (copiedNode.minWidth ?? 1)
                if (totalSize > FLOW_LAYOUT_NODE_ROWS) {
                    Toast.error('超过最大内容限制，无法插入')
                    return
                }
            }

            let finalNodes: PageNode[] = []
            let newBlocks: BlockAbstract[] = []

            let originNodes: PageNode[] | undefined
            setPageNodes(draft => {
                const pageNodes = draft[pageId]
                originNodes = original(pageNodes)
                if (!pageNodes) {
                    return
                }

                // 如果复制的容器，则递归复制容器内的子元素
                const { newNodes, newBlocks: copiedBlocks } = copyBlocksAndNodes([copiedNode], blocks)
                newBlocks = copiedBlocks
                if (newNodes.length === 0) {
                    return
                }

                insertNodeByOverId(newNodes, copiedNode.id, undefined, parentDirection === DIRECTION.horizontal)(pageNodes)
                finalNodes = current(pageNodes)
            })

            const nodeRes = await srv.updateNodes({ id: pageId, nodes: finalNodes })
            if (!nodeRes) {
                return
            }

            const blockRes = await createBlock({ rootPageId, pageId, stackId, blocks: newBlocks })
            if (!blockRes) {
                return
            }

            pageUndoRedoController.add({
                action: BLOCK_NODE_CREATE_ACTION,
                payload: {
                    blocks: newBlocks,
                    nodes: { prev: originNodes, next: finalNodes }
                }
            })
        },
        [blockRuntimeState?.container, blocks, createBlock, nodes, pageId, rootPageId, setPageNodes, stackId]
    )

    // 删除block
    const onRemove = useCallback(
        async (id: string) => {
            let originNodes: PageNode[] | undefined
            let finalNodes: PageNode[] = []
            let removedNodeIds: string[] = []
            setPageNodes(draft => {
                const pageNodes = draft[pageId]
                originNodes = original(pageNodes)
                if (!pageNodes) {
                    return
                }
                const removed = removeNodeById(id)(pageNodes)
                if (removed) {
                    removedNodeIds = collectRemovedBlockIds(removed, blocks)
                }
                finalNodes = current(pageNodes)
            })

            const nodeRes = await srv.updateNodes({ id: pageId, nodes: finalNodes })

            if (!nodeRes) {
                return
            }

            const blockRes = await removeBlock({ rootPageId, pageId, stackId, blockIds: removedNodeIds })
            if (!blockRes) {
                return
            }

            pageUndoRedoController.add({
                action: BLOCK_NODE_REMOVE_ACTION,
                payload: {
                    blocks: removedNodeIds.map(item => blocks.find(block => block.id === item)).filter(Boolean) as BlockAbstract[],
                    nodes: { prev: originNodes, next: finalNodes }
                }
            })
        },
        [blocks, pageId, removeBlock, rootPageId, setPageNodes, stackId]
    )

    // 交换顺序
    const onSwap = useCallback(
        (from: string, to: string) => {
            if (from === to) {
                return
            }

            function recursionSwap(nodes: PageNode[]) {
                let fromIndex: number | undefined
                let toIndex: number | undefined
                for (const [index, node] of nodes.entries()) {
                    if (node.id === from) {
                        fromIndex = index
                    }

                    if (node.id === to) {
                        toIndex = index
                    }
                    if (fromIndex !== undefined && toIndex !== undefined && fromIndex !== toIndex) {
                        ;[nodes[fromIndex], nodes[toIndex]] = [nodes[toIndex], nodes[fromIndex]]
                        return true
                    }

                    if (node.children) {
                        const isSwapped = recursionSwap(node.children)
                        if (isSwapped) {
                            return true
                        }
                    }
                }

                return false
            }

            let originNodes: PageNode[] | undefined
            let finalNodes: PageNode[] = []
            setPageNodes(draft => {
                const pageNodes = draft[pageId]
                originNodes = original(pageNodes)
                if (!pageNodes) {
                    return
                }

                recursionSwap(pageNodes)
                finalNodes = current(pageNodes)
            })

            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: {
                    prev: originNodes,
                    next: finalNodes
                }
            })
            srv.updateNodes({ id: pageId, nodes: finalNodes })
        },
        [pageId, setPageNodes]
    )

    return {
        onDuplicate,
        onRemove,
        onSwap
    }
}

