import { Popover } from '@byecode/ui'
import { getImageFullUrlInCommon, useAtomAction } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import { useAsyncAction } from '@/atoms/helper'
import { createWorkSpaceAtom, fetchWorkSpaceAtom, fetchWorkSpaceListAtom, setWorkSpaceIdAtom } from '@/atoms/workSpace/action'
// import { useAsyncAction, useBoundStore } from '@/stores'
import { CardCreator } from '@/components/CardCreator'
import { useWorkSpace, useWorkSpaceList } from '@/hooks/useWorkSpace'

import { SpaceCreatorModal } from '../components/SpaceCreatorModal'
import { SpaceMenu } from '../components/SpaceMenu'
import { SpaceAvatar } from '../SpaceAvatar'

interface SpaceConfigureState {
    isSpaceSettingOpen: boolean
    isCreateSpace: boolean
}

export const SpaceConfigure: React.FC = () => {
    const workspace = useWorkSpace()
    const [open, setOpen] = useState(false)
    const { id, name, icon, members } = workspace
    const workSpaceList = useWorkSpaceList()
    const { run: setWorkSpaceId } = useAtomAction(setWorkSpaceIdAtom)
    const [state, setState] = useImmer<SpaceConfigureState>({
        isSpaceSettingOpen: false,
        isCreateSpace: false
    })

    const isBrowseStart = useMemo(() => workSpaceList.some(item => item.browseStart), [workSpaceList])

    // 跳转空间
    const handleChangeSpace = useCallback(
        (spaceId: string) => {
            if (id === spaceId) {
                return
            }
            setOpen(false)
            setWorkSpaceId(spaceId)
        },
        [id, setWorkSpaceId]
    )

    const handleCreateSpace = useCallback(
        (isOpen: boolean) => {
            setOpen(false)
            setState(draft => {
                draft.isCreateSpace = isOpen
            })
        },
        [setState]
    )

    return (
        <>
            <Popover width="target" opened={open} withinPortal zIndex={200} onChange={setOpen}>
                <Popover.Target>
                    <SpaceAvatar name={name} icon={getImageFullUrlInCommon(icon)} dot={isBrowseStart} active={open} members={members} />
                </Popover.Target>
                <Popover.Dropdown>
                    <SpaceMenu
                        space={workspace}
                        workspaces={workSpaceList}
                        onChangeSpace={handleChangeSpace}
                        // onSpaceSet={handleSpaceSet}
                        onCreateSpace={handleCreateSpace}
                    />
                </Popover.Dropdown>
            </Popover>

            <SpaceCreatorModal
                title="创建空间"
                open={state.isCreateSpace}
                onClose={() => {
                    handleCreateSpace(false)
                }}
            />
        </>
    )
}
