import type { InputProps } from '@byecode/ui'
import { Button, Flex, Input, Text, Tooltip } from '@byecode/ui'
import type { FieldInputError } from '@lighthouse/shared'
import { type FieldInputRules, ErrorMessage, FieldInputErrorInfo, getFieldInputError } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import * as srv from '@/services'

interface DomainInputProps {
    domain: string
    placeholder?: string
    showEdit?: boolean
    validateInputProps: {
        defaultValue?: string
        rules: FieldInputRules
    } & InputProps
    rightSection?: React.ReactNode
    leftSection?: React.ReactNode
    onSubmit: (v: string) => void
    onCancel: () => void
}

const SCxPreview = styled.div`
    padding: 0 8px;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-gray-100);
    border-radius: 8px;
`

const SCxEdit = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxDomain = styled(Text)`
    flex: 1;
`
const SCxBtnGroup = styled(Flex)``
const DomainInput: React.FunctionComponent<DomainInputProps> = ({
    validateInputProps,
    showEdit,
    domain,
    leftSection,
    rightSection,
    placeholder,
    onSubmit,
    onCancel
}) => {
    const { rules, defaultValue } = validateInputProps

    const [value, setValue] = useState<string>(defaultValue ?? '')

    const [errors, setErrors] = useState<FieldInputError>({})

    const handleSubmit = useCallback(async () => {
        const fieldError = getFieldInputError({ fieldType: 'text', value: { type: 'text', value }, rule: rules })
        if (Object.entries(fieldError).length > 0) {
            setErrors(fieldError)
            return
        }
        const isRepeat = await srv.checkDomain(value)
        if (!isRepeat) {
            setErrors({
                validate: {
                    type: 'validate',
                    message: '域名已重复'
                }
            })
            return
        }
        onSubmit(value)
    }, [onSubmit, rules, value])

    const error = Object.entries(errors).find(([key, error]) => error.message)?.[1]

    useUpdateEffect(() => {
        setValue(defaultValue ?? '')
    }, [defaultValue])

    return showEdit ? (
        <SCxEdit>
            <Tooltip
                styles={{ tooltip: { backgroundColor: 'var(--color-red-500)!important', display: error?.message ? 'block' : 'none' } }}
                placement="top-start"
                open
                title={error?.message}
            >
                <Input size="lg" value={value} onChange={e => {
                    setValue(e.target.value)
                    setErrors({})
                }} />
            </Tooltip>
            <SCxBtnGroup gap={8}>
                <Button
                    block
                    disabled={false}
                    onClick={() => {
                        setValue(defaultValue ?? '')
                        onCancel()
                    }}
                >
                    取消
                </Button>
                <Button block type="primary" onClick={handleSubmit}>
                    保存
                </Button>
            </SCxBtnGroup>
        </SCxEdit>
    ) : (
        <SCxPreview>
            {leftSection}
            {domain ? (
                <SCxDomain size={14} color="var(--color-main)">
                    {domain}
                </SCxDomain>
            ) : (
                <SCxDomain size={14} color="var(--color-gray-300)">
                    {placeholder}
                </SCxDomain>
            )}
            {rightSection}
        </SCxPreview>
    )
}

export default DomainInput
