import { IconFont, Input, Tooltip } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ImageSwiperItem, PageAbstract } from '@lighthouse/core'
import { getFileSizeWithUnit } from '@lighthouse/tools'
import { Popover } from '@mantine/core'
import React, { useId, useMemo, useState } from 'react'

import { useApplicationContext } from '../../contexts'
import { getFileTypeByUrl, getImageFullUrlInApplication } from '../../utils'
import { LinkSelect } from '../LinkSelect'
import { useUploadItemFinished } from '../UploadManage'
import type { UseUploadFileSParameter } from '../UploadManage/hooks'
import * as SC from './styles'

interface LinkCardProps {
    pageList: PageAbstract[]
    value: ImageSwiperItem
    id: string
    disabled?: boolean
    disableJump?: boolean
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>

    onSourceChange?: (source: string) => void
    onChange?(data: ImageSwiperItem): void
    onDeleteClick?(): void
}

export const LinkCard: React.FC<LinkCardProps> = ({
    value,
    pageList,
    id,
    uploadOptions,
    disabled,
    disableJump,
    onChange,
    onDeleteClick
}) => {
    const [opened, setOpened] = useState(false)
    const uploadButtonId = useId()
    const { appId } = useApplicationContext()
    const { name, type, link, title, url, size } = value

    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
        id,
        disabled
    })

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition
        }),
        [transform, transition]
    )

    useUploadItemFinished(uploadButtonId, item => {
        const {
            file: { name, size },
            uploadResponse: {
                data: {
                    content: { url }
                }
            }
        } = item

        if (url) {
            onChange?.({ ...value, type: getFileTypeByUrl(name), url })
        }
    })

    return (
        <SC.Container disabled={disabled} ref={setNodeRef} style={style}>
            <SC.Handle {...listeners} {...attributes} ref={setActivatorNodeRef}>
                <IconFont type="DotsSix" color="var(--color-gray-500)" size={16} />
            </SC.Handle>
            {url ? (
                <Tooltip disabled={disabled} title="更换图片" withArrow>
                    <SC.ImageWrapper imgUrl={getImageFullUrlInApplication(appId, url)} style={{ display: 'flex', alignItems: 'center' }}>
                        <SC.CardUploadButton
                            disabled={disabled}
                            accept="image/*"
                            uploadOptions={{ ...uploadOptions, info: { ...uploadOptions.info, id: uploadButtonId } }}
                        >
                            {/* {type === 'image' && type && <IconFont size={28} type={FILE_ICON_MAP[type]} />} */}
                            <SC.FileSize>{getFileSizeWithUnit(size)}</SC.FileSize>
                        </SC.CardUploadButton>
                    </SC.ImageWrapper>
                </Tooltip>
            ) : null}
            <SC.Content>
                <SC.Wrapper>
                    <SC.TextField>
                        <div style={{ flex: 0, whiteSpace: 'nowrap' }}>标题</div>
                        <Input defaultValue={title} size="xs" onBlur={event => onChange?.({ ...value, title: event.target.value })} />
                    </SC.TextField>
                    {!disableJump && (
                        <SC.TextField>
                            <span>链接</span>
                            <Popover
                                width={260}
                                position="bottom"
                                withArrow
                                shadow="md"
                                opened={opened}
                                onChange={setOpened}
                                zIndex="10000"
                            >
                                <Popover.Target>
                                    <SC.LinkButton onClick={() => setOpened(o => !o)}>
                                        {(() => {
                                            if (!link) {
                                                return '请选择'
                                            }
                                            if (link.startsWith('http')) {
                                                return '外部链接'
                                            }
                                            return pageList.find(item => item.id === link)?.name?.slice(0, 5)
                                        })()}

                                        <iconpark-icon name="ArrowDownSmall" color="var(--color-main)" />
                                    </SC.LinkButton>
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <LinkSelect
                                        pageList={pageList}
                                        value={link}
                                        onChange={link => onChange?.({ ...value, link })}
                                        onConformClick={() => setOpened(false)}
                                    />
                                </Popover.Dropdown>
                            </Popover>
                        </SC.TextField>
                    )}
                </SC.Wrapper>
            </SC.Content>
            <SC.DeleteButton onClick={onDeleteClick}>
                <iconpark-icon name="Close" color="#fff" />
            </SC.DeleteButton>
        </SC.Container>
    )
}
