import { getAssetUrl } from '@lighthouse/assets'
import { CONTAINER_ICON_MAP } from '@lighthouse/block'
import {
    BlockType,
    DIRECTION
} from '@lighthouse/core'

import {
    allButton,
    allChart,
    allField,
    allFile,
    allText,
    allView,
    blockBreadcrumb,
    blockCard,
    blockCollapse,
    blockDriver,
    blockFieldGroup,
    blockFilter,
    blockFormContainer,
    blockIframe,
    blockQrBarcode,
    blockSubForm,
    blockTabs
} from './constant'
import type {
    BlockTree,
    BlockTreeItem
} from './type'
import { BlockKind } from './type'

export const ROW_CONTAINER = {
    type: BlockType.container,
    subType: DIRECTION.vertical,
    name: '行容器',
    icon: CONTAINER_ICON_MAP.vertical,
    describeImage: getAssetUrl('blocks', 'row_container.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
} satisfies BlockTreeItem

export const COL_CONTAINER = {
    type: BlockType.container,
    subType: DIRECTION.horizontal,
    name: '列容器',
    icon: CONTAINER_ICON_MAP.horizontal,
    describeImage: getAssetUrl('blocks', 'column_container.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
} satisfies BlockTreeItem

export const ALL_BLOCK_LIST: BlockTree[] = [
    allText,
    allButton,
    {
        id: BlockKind.layout,
        name: '布局',
        items: [ROW_CONTAINER, COL_CONTAINER, blockFieldGroup, blockDriver, blockCollapse]
    },
    allFile,
    {
        id: BlockKind.navigation,
        name: '导航',
        items: [blockTabs, blockBreadcrumb, blockCard]
    },
    allView,
    allChart,
    {
        ...allField,
        items: [blockFormContainer, blockSubForm, ...allField.items]
    },
    {
        id: BlockKind.other,
        name: '高级',
        items: [blockFilter, blockIframe, blockQrBarcode]
    }
]
