import type { BlockAbstract, BlockRuntimeState, PageNode } from '@lighthouse/core';
import { BlockType } from '@lighthouse/core'
import { type Coordinates, type DraggableNodes, type FlowLayoutNode, findNodeById, FLOW_LAYOUT_NODE_ROWS } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools';
import { current } from 'immer'
import { clone } from 'rambda';

/**
 * 根据id和tree查找prev node节点
 * @date 2024/1/16 - 14:04:49
 *
 * @export
 * @param {string} id
 * @returns {(tree: FlowLayoutNode[]) => FlowLayoutNode | undefined}
 */
export function findPrevNodeById(id: string) {
    const recursion = (tree: FlowLayoutNode[]): undefined | FlowLayoutNode => {
        for (const [index, node] of tree.entries()) {
            if (node.id === id) {
                return tree[index - 1]
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion
}

/**
 * 根据id和tree查找next node节点
 * @date 2024/1/15 - 10:55:16
 *
 * @export
 * @param {string} id
 * @returns {(tree: FlowLayoutNode[]) => FlowLayoutNode | undefined}
 */
export function findNextNodeById(id: string) {
    const recursion = (tree: FlowLayoutNode[]): undefined | FlowLayoutNode => {
        for (const [index, node] of tree.entries()) {
            if (node.id === id) {
                return tree[index + 1]
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion
}

/**
 * 根据id和tree删除节点
 * @date 2024/1/8 - 13:42:38
 *
 * @export
 * @param {string} id
 * @returns {(tree: PageNode[]) => PageNode[]}
 */
export function removeNodeById(id: string) {
    const recursion = (tree: PageNode[]): undefined | PageNode[] => {
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i]
            if (node.id === id) {
                return tree.splice(i, 1)
            }

            if (node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }
    }
    return recursion
}

/**
 * 根据over id，插入节点
 * @date 2024/1/8 - 13:53:44
 *
 * @export
 * @param {PageNode} data
 * @param {string} parentId
 * @param {boolean} begin 插到当前node的前面
 * @param {boolean} checkSize 是否检查宽度超出
 * @returns {(tree: PageNode[]) => void}
 */
export function insertNodeByOverId(data: PageNode[], overId: string, begin?: boolean, checkSize?: boolean) {
    const recursion = (tree: PageNode[]) => {
        if (overId === 'root') {
            tree.splice(tree.length, 0, ...data)
            if (checkSize) {
                resizeParentChildrenWidth(tree)
            }

            return
        }

        for (const [index, node] of tree.entries()) {
            if (node.id === overId) {
                tree.splice(begin ? index : index + 1, 0, ...data)
                if (checkSize) {
                    resizeParentChildrenWidth(tree)
                }
                return
            }

            if (node.children) {
                recursion(node.children)
            }
        }
    }

    return recursion
}

/**
 * 根据父节点插入子节点
 * @date 2024/1/10 - 16:43:30
 *
 * @export
 * @param {PageNode[]} data
 * @param {string} parentId
 * @param {boolean} begin 从前插
 * @param {boolean} checkSize 是否检查宽度超出
 * @returns {(tree: {}) => void}
 */
export function insertNodeByParentId(data: PageNode[], parentId: string, begin?: boolean, checkSize = true) {
    const recursion = (tree: PageNode[]) => {
        for (const node of tree) {
            if (node.id === parentId) {
                if (!node.children) {
                    node.children = []
                }

                if (begin) {
                    node.children.unshift(...data)
                } else {
                    node.children.push(...data)
                }
                if (checkSize) {
                    resizeParentChildrenWidth(node.children)
                }

                return
            }

            if (node.children) {
                recursion(node.children)
            }
        }
    }

    return recursion
}

/**
 * over的是否是active的子元素
 * @date 2024/1/16 - 19:09:12
 *
 * @export
 * @param {string} activeId
 * @param {string} overId
 * @returns {(tree: {}) => boolean}
 */
export function isLeaf(activeId: string, overId: string, tree: FlowLayoutNode[]) {
    const activeNode = findNodeById(activeId)(tree)
    if (!activeNode || activeNode.type === 'block' || !activeNode.children || activeNode.children.length === 0) {
        return false
    }

    const recursion = (tree: FlowLayoutNode[]): boolean => {
        for (const node of tree) {
            if (node.id === overId) {
                return true
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }

        return false
    }

    return recursion(activeNode.children)
}

/**
 * 根据鼠标坐标计算出over rect
 * @date 2024/1/16 - 18:18:43
 *
 * @export
 * @param {string} activeId
 * @param {Coordinates} coordinates
 * @param {DraggableNodes} draggableNodes
 * @returns {*}
 */
export function getOverNode(activeId: string, coordinates: Coordinates, draggableNodes: DraggableNodes) {
    return (tree: FlowLayoutNode[]) => {
        for (const [id, item] of draggableNodes.entries()) {
            if (activeId === id || item.data.type === 'container' || item.data.type === 'custom' || isLeaf(activeId, id, tree)) {
                continue
            }

            const rect = item.node.getBoundingClientRect()
            if (
                coordinates.x > rect.left &&
                coordinates.x < rect.left + rect.width &&
                coordinates.y > rect.top &&
                coordinates.y < rect.top + rect.height
            ) {
                return { id, rect, data: item.data }
            }
        }
    }
}

/**
 * over的是否是active本身或者后代，禁止自己插入到自己或者自己的后代
 * @date 2024/3/26 - 15:01:24
 *
 * @export
 * @param {string} activeId
 * @param {string} overId
 * @returns {(tree: {}, parentIsActiveId?: boolean) => boolean}
 */
export function isActiveInOver(activeId: string, overId: string) {
    function findOver(id: string, tree: FlowLayoutNode[]): boolean {
        for (const node of tree) {
            if (node.id === overId) {
                return true
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = findOver(id, node.children)
                if (res) {
                    return res
                }
            }
        }

        return false
    }

    const recursion = (tree: FlowLayoutNode[]): boolean => {
        for (const node of tree) {
            if (node.id === activeId) {
                if ((node.type === 'container' || node.type === 'custom') && node.children) {
                    const res = findOver(overId, node.children)
                    if (res) {
                        return true
                    }
                }
                return false
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = recursion(node.children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return recursion
}

/**
 * 计算某个容器内部已使用的宽度
 * @date 2024/1/25 - 15:26:38
 *
 * @export
 * @param {FlowLayoutNode} active
 * @returns {(tree: {}) => any}
 */
export function computedUsedWidth(active: FlowLayoutNode) {
    return (tree: FlowLayoutNode[]) => {
        return tree.filter(item => item.id !== active.id).reduce((total, current) => total + current.width, 0)
    }
}

/**
 * 计算容器内已使用的最小宽度
 * @date 2024/4/1 - 16:46:46
 *
 * @export
 */
export function computedUsedMinWidth(active: FlowLayoutNode) {
    return (tree: FlowLayoutNode[]) => {
        return tree.filter(item => item.id !== active.id).reduce((total, current) => total + (current.minWidth ?? 1), 0)
    }
}

/** 递归寻找页面节点 */
export function findPageNodeById(id: string) {
    const recursion = (tree: PageNode[]): undefined | PageNode => {
        for (const node of tree) {
            if (node.id === id) {
                return node
            }

            if (node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion
}
export function findParentPageNodeById(id: string) {
    let parent: PageNode | undefined
    // let grandParent: PageNode | undefined
    const recursion = (tree: PageNode[], blocks: BlockAbstract[]) => {
        for (const node of tree) {
            if (node.id === id) {
                return true
            }

            if (node.children) {
                const res = recursion(node.children, blocks)
                if (res) {
                    // if (parent && !grandParent) {
                    //     grandParent = node
                    // }

                    if (!parent) {
                        const block = blocks.find(item => item.id === node.id)
                        if (block) {
                            parent = node
                        }
                    }

                    return true
                }
            }
        }
    }

    return (tree: PageNode[], blocks: BlockAbstract[]) => {
        recursion(tree, blocks)
        return parent
    }
}

/**
 * 检查是否是自定义视图的后代
 *
 * @export
 * @param {PageNode} target
 * @param {PageNode[]} nodes
 * @returns {boolean}
 */
export function isCustomChildren(targetId: string, nodes: FlowLayoutNode[]) {
    function findTarget(id: string, tree: FlowLayoutNode[]) {
        for (const child of tree) {
            if (child.id === id) {
                return true
            }

            if ((child.type === 'container' || child.type === 'custom') && child.children) {
                const res = findTarget(id, child.children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    function find(tree: FlowLayoutNode[]): boolean {
        for (const child of tree) {
            if (child.type === 'custom' && child.children) {
                const res = findTarget(targetId, child.children)
                if (res) {
                    return true
                }
            }

            if (child.type === 'container' && child.children) {
                const res = find(child.children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return find(nodes)
}

/**
 * 递归检查是否有禁止的node
 *
 * @export
 * @param {FlowLayoutNode} target
 * @param {BlockType[]} notAllowedList
 * @param {BlockAbstract[]} blocks
 * @returns {boolean}
 */
export function includeNotAllowedBlock(target: FlowLayoutNode, notAllowedList: BlockType[], blocks: BlockAbstract[]): boolean {
    const block = blocks.find(item => item.id === target.id)
    if (!block) {
        return true
    }
    if (notAllowedList.includes(block.type)) {
        return true
    }

    if ((target.type === 'container' || target.type === 'custom') && target.children) {
        for (const child of target.children) {
            const res = includeNotAllowedBlock(child, notAllowedList, blocks)
            if (res) {
                return true
            }
        }
    }
    return false
}

/**
 * 循环调整children下的节点宽度，直到达到规定的宽度为止
 * @date 2024/4/1 - 17:21:48
 *
 * @export
 * @param {PageNode[]} children
 */
export function resizeParentChildrenWidth(children: PageNode[]) {
    let currentWidth = children.reduce((total, current) => total + current.width, 0)
    const childrenIsMinSize = Array.from<boolean>({ length: children.length }).fill(false)

    while (currentWidth > FLOW_LAYOUT_NODE_ROWS) {
        for (const [index, node] of children.entries()) {
            if (node.width > (node.minWidth ?? 1)) {
                node.width--
                currentWidth--
                if (currentWidth <= FLOW_LAYOUT_NODE_ROWS) {
                    break
                }
                continue
            }

            childrenIsMinSize[index] = true
        }
        if (childrenIsMinSize.every(item => !!item)) {
            break
        }
    }
}

export function getContainerViewNodes(node: PageNode, blockRuntimeState?: BlockRuntimeState) {
    const currentView = blockRuntimeState?.container?.[node.id].currentView
    if (!currentView) {
        return []
    }

    const view = node.children?.find(item => item.id === currentView)
    if (!view) {
        return []
    }

    return view.children || []
}

export function getNodePath(nodeId: string) {
    function rec(tree: FlowLayoutNode[]): FlowLayoutNode[] {
        for (const node of tree) {
            if (node.id === nodeId) {
                return [node]
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = rec(node.children)
                if (res.length > 0) {
                    return [node, ...res]
                }
            }
        }

        return []
    }

    return rec
}

/**
 * 如果是空白容器，则根据runtime state找到对应的view
 * 否则直接返回容器id
 */
export function getRealParentNodeId(containerId: string, blocks: BlockAbstract[], blockRuntimeState?: BlockRuntimeState) {
    const block = blocks.find(item => item.id === containerId)
    if (block?.type === BlockType.container) {
        return blockRuntimeState?.container?.[containerId].currentView
    }

    return containerId
}


/**
 * 复制nodes节点及blocks
 *
 * @export
 * @param {PageNode[]} copiedNodes
 * @param {BlockAbstract[]} blocks
 * @returns {{ newBlocks: BlockAbstract[]; newNodes: PageNode[]; }}
 */
export function copyBlocksAndNodes(copiedNodes: PageNode[], blocks: BlockAbstract[]) {
    const newBlocks: BlockAbstract[] = []

    // 递归复制容器内的子元素
    const recursionCopy = (copiedNode: PageNode): undefined | PageNode => {
        const oldBlock = blocks.find(block => block.id === copiedNode.id)
        if (!oldBlock) {
            return
        }

        const id = `${oldBlock.type}-${nanoid(12)}`

        if (copiedNode.children) {
            // 如果是空白容器，需要复制view信息
            if (oldBlock.type === BlockType.container) {
                const viewIdMap = Object.fromEntries(oldBlock.config.viewList.map(item => [item.id, nanoid()]))
                newBlocks.push({
                    ...clone(oldBlock),
                    id,
                    config: {
                        ...clone(oldBlock.config),
                        viewList: oldBlock.config.viewList.map(item => ({
                            ...item,
                            id: viewIdMap[item.id]
                        }))
                    }
                })
                return {
                    ...copiedNode,
                    id,
                    children: copiedNode.children.map(view => {
                        return {
                            ...view,
                            id: viewIdMap[view.id],
                            children: view.children?.map(recursionCopy).filter(Boolean) as PageNode[]
                        }
                    })
                }
            }
            newBlocks.push({ ...clone(oldBlock), id })
            return { ...copiedNode, id, children: copiedNode.children.map(recursionCopy).filter(Boolean) as PageNode[] }
        }

        newBlocks.push({ ...clone(oldBlock), id })
        return { ...copiedNode, id }
    }

    const newNodes = copiedNodes.reduce<PageNode[]>((prev, curr) => {
        const newNode = recursionCopy(curr)
        if (!newNode) {
            return prev
        }

        return [...prev, newNode]
    }, [])

    return { newBlocks, newNodes }
}


/** 收集删除的block id */
export function collectRemovedBlockIds(removedNode: PageNode[], blocks: BlockAbstract[]) {
    function getContainerViewsChildren(views: PageNode[]) {
        return views.reduce<PageNode[]>((total, current) => [...total, ...(current.children || [])], [])
    }

    const recursion = (node: PageNode[]): string[] => {
        return node.reduce<string[]>((total, current) => {
            const block = blocks.find(item => item.id === current.id)
            if (!block) {
                return total
            }

            const children = current.children
                ? block.type === BlockType.container
                    ? recursion(getContainerViewsChildren(current.children))
                    : recursion(current.children)
                : []

            return [...total, current.id, ...children]
        }, [])
    }

    return recursion(removedNode)
}
