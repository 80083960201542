import { ImageBlock } from '@lighthouse/block'
import type { ImageBlockAbstract, ImageSwiperItem } from '@lighthouse/core'
import { RichTextEditor } from '@lighthouse/shared'
import { Carousel } from '@mantine/carousel'
import { format } from 'date-fns'
import { mergeDeepRight } from 'rambda'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import * as CM from '../styles'
import TemplateImage from '../TemplateImage'
import { TempleLabels } from '../TempleLabels'
import type { Template } from '../types'

interface TemplateDetailProps {
    data?: Template
}
const SCXContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    padding-bottom: 100px;
`

const SCXDescribe = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-top: 12px;
    color: var(--color-black);
`

const SCXFooter = styled.div`
    width: 100%;
    margin-top: 32px;

    > *:nth-child(odd):not(:first-child) {
        margin-left: 26px;
    }
`

const templateImageBlock: ImageBlockAbstract = {
    title: '图片',
    id: 'image-lBb6582bb833',
    type: 'image',
    config: {
        action: {
            data: {
                none: {
                    //
                }
            },
            trigger: 'click',
            type: 'none'
        },
        align: 'center',
        fitType: 'cover',
        imageClickEvent: 'none',
        isPreview: true,
        radius: '12',
        ratio: '6',
        shape: 'rectangle',
        showTitle: true,
        sourceType: 'default',
        variant: 'swiper',
        visibilityDevice: ['mobile', 'desktop'],
        width: '100%',
        autoLoop: true,
        swipeSpeed: 4
    }
}

const TemplateDetail: React.FunctionComponent<TemplateDetailProps> = ({ data }) => {
    const { describeImageList = [], describeText, createdBy, iconBackgroundColor, createdTime = '', labels } = data ?? {}

    const sources: ImageSwiperItem[] = describeImageList.map(v => ({
        name: '1351-jpg-wh300.jpg',
        url: v,
        size: 0,
        type: 'image',
        title: '',
        link: ''
    }))

    return (
        <SCXContainer>
            {describeImageList.length > 0 && (
                <ImageBlock
                    blockData={mergeDeepRight(templateImageBlock, {
                        config: {
                            sources
                        }
                    })}
                    disabled
                    value={sources}
                />
            )}
            <TempleLabels labels={labels} style={{ marginTop: '24px', fontSize: 16 }} />
            <SCXDescribe>
                <RichTextEditor
                    disableToolbar
                    readonly
                    styles={{ editorContent: { fontSize: 14, lineHeight: 1.7 } }}
                    value={describeText}
                />
            </SCXDescribe>
            <SCXFooter>
                <CM.Text size={14} color="var(--color-gray-400)">
                    作者
                </CM.Text>
                <CM.Text size={14} style={{ paddingLeft: 16 }}>
                    {/* {createdBy} */}
                    ByeCode
                </CM.Text>
                <CM.Text size={14} color="var(--color-gray-400)">
                    发布时间
                </CM.Text>
                <CM.Text size={14} style={{ paddingLeft: 16 }}>
                    {format(Number(createdTime), 'yyyy-MM-dd')}
                </CM.Text>
            </SCXFooter>
        </SCXContainer>
    )
}

export default TemplateDetail
