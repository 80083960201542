import { Anchor, Flex, tinyButtons } from '@byecode/ui'
import { DEFAULT_SPACE_VERSION } from '@lighthouse/shared'
import { useBreakpoint } from '@lighthouse/tools'
import { MediaQuery, Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components'

import { useSpaceQuota } from '@/shared/reusable'

import { openSpaceGrade } from '../SpaceGrade'
import { SpaceQuotaDosage } from './Dosage'
import { SpaceQuotaFlow } from './flow'
import { SpaceQuotaInfo } from './Info'

interface SpaceQuotaProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 60px 36px;
    background-color: var(--color-gray-25);
    overflow-y: auto;
    ${tinyButtons}
`
const SCxGrid = styled.div`
    margin: 19px 0 0 0;
    display: grid;
    gap: 20px;
`

const SCxItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const SpaceQuota: React.FunctionComponent<SpaceQuotaProps> = props => {
    const { data, update } = useSpaceQuota()

    useEffectOnce(() => {
        update()
    })

    const info = useMemo(
        () => ({ name: data?.spaceName, version: data?.currentVersion, expiredAt: data?.expiredAt }),
        [data?.currentVersion, data?.expiredAt, data?.spaceName]
    )

    const contentEle = useMemo(
        () => (
            <SCxGrid>
                <SCxItem>
                    <SpaceQuotaInfo data={info} />
                    <SpaceQuotaDosage />
                    {data?.traffic && <SpaceQuotaFlow data={data?.traffic} version={data?.currentVersion ?? DEFAULT_SPACE_VERSION} />}
                </SCxItem>
            </SCxGrid>
        ),
        [data?.currentVersion, data?.traffic, info]
    )

    return (
        <SCxContainer>
            <Flex justifyContent="space-between">
                <Text size={24} weight={500} color="var(--color-gray-700)" style={{ lineHeight: '32px' }}>
                    资源配额
                </Text>
                <Text size={14} color="var(--color-gray-500)" style={{ lineHeight: '32px' }}>
                    资源不够用？ <Anchor onClick={() => openSpaceGrade('version_upgrade')}>升级版本</Anchor>
                </Text>
            </Flex>

            {/* <MediaQuery
                query="(min-width: 980px)"
                styles={width > 980 ? { maxWidth: 1230, gridTemplateColumns: '2fr minmax(300px, 1fr)' } : {}}
            > */}
            {contentEle}
            {/* </MediaQuery> */}
        </SCxContainer>
    )
}
