import { Button, IconFont, Popover, SelectDropdown } from '@byecode/ui'
import { find } from 'rambda'
import React, { useMemo } from 'react'

export enum AppFilterType {
    ALL = 'ALL',
    PUBLIC = 'PUBLIC',
    NO_PUBLIC = 'NO_PUBLIC',
    PUBLIC_PRIVATE_TEMPLATE = 'PUBLIC_PRIVATE_TEMPLATE'
}
interface AppFilterProps {
    value: string
    onChange?: (val: string) => void
}

export const AppFilter: React.FC<AppFilterProps> = ({ value, onChange }) => {
    const options = useMemo(
        () => [
            {
                label: '全部应用',
                value: AppFilterType.ALL,
                extra: value === AppFilterType.ALL && <IconFont fill="var(--color-main)" type="Tick" />
            },
            {
                label: '已发布',
                value: AppFilterType.PUBLIC,
                extra: value === AppFilterType.PUBLIC && <IconFont fill="var(--color-main)" type="Tick" />
            },
            {
                label: '未发布',
                value: AppFilterType.NO_PUBLIC,
                extra: value === AppFilterType.NO_PUBLIC && <IconFont fill="var(--color-main)" type="Tick" />
            },
            {
                label: '发布私人模板',
                value: AppFilterType.PUBLIC_PRIVATE_TEMPLATE,
                extra: value === AppFilterType.PUBLIC_PRIVATE_TEMPLATE && <IconFont fill="var(--color-main)" type="Tick" />
            }
        ],
        [value]
    )
    const label = useMemo(() => find(option => option.value === value, options)?.label, [options, value])
    return (
        <Popover width={160}>
            <Popover.Target>
                <Button type="text" suffix={<IconFont size={16} fill="var(--color-gray-400)" type="ArrowDownSmall" />}>
                    {label}
                </Button>
            </Popover.Target>
            <Popover.Dropdown>
                <SelectDropdown options={options} onSelect={onChange} />
            </Popover.Dropdown>
        </Popover>
    )
}
