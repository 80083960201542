import type { ModalProps } from '@byecode/ui'
import { Anchor, Box, Button, Flex, Input, Text } from '@byecode/ui'
import { TitleInContentModal } from '@lighthouse/shared'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { type AnalysisConfigure, StatisticalPlatForm } from '@/services'

import { PLATFORM_OPTIONS } from '../constants'

const Form = styled.form`
    padding: 16px 32px;
`

const Label = styled.div<{ required?: boolean }>`
    font-size: 14px;
    ${({ required }) =>
        required &&
        css`
            &::after {
                content: ' *';
                color: var(--color-red-500);
            }
        `}
`

const Error = styled.span`
    color: var(--color-red-500);
`

const StateButton = styled(Button)<{ selected: boolean }>`
    ${({ selected }) =>
        selected &&
        css`
            color: var(--color-white) !important;
            border: 1px solid var(--color-purple-500) !important;
            background-color: var(--color-purple-500) !important;
            &:hover {
                background-color: var(--color-purple-600) !important;
            }
        `}
`

const Link = styled.a`
    font-size: 14px;
    color: var(--color-purple-500);
    &:hover {
        text-decoration: underline;
    }
`

const InjectCodeBoxBody = styled(Box<'textarea'>)`
    padding: 10px 8px;
    width: 100%;
    height: 251px;
    overflow-y: auto;
    resize: none;
    font-size: 14px;
    border-radius: 8px;
    color: var(--color-gray-400);
    background-color: var(--color-gray-100);
    &::placeholder {
        color: var(--color-gray-400);
    }
`

interface CreateModalProps extends Omit<ModalProps, 'defaultValue'> {
    value?: Omit<AnalysisConfigure, 'id'>
    onOk: (values: Omit<AnalysisConfigure, 'id'>) => void
}

export const CreateModal = ({ value, open, onOk, onClose, ...rest }: CreateModalProps) => {
    const { reset, register, formState, watch, setValue, handleSubmit } = useForm<Omit<AnalysisConfigure, 'id'>>({
        defaultValues: {
            type: StatisticalPlatForm.UMENG
        }
    })

    const platform = watch('type')

    useEffect(() => {
        if (open) {
            reset({ type: StatisticalPlatForm.UMENG, ...value })
        }
    }, [open, reset, value])

    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open, reset])

    return (
        <TitleInContentModal
            width={674}
            closeOnClickOverlay={false}
            title={`${value ? '修改' : '添加'}自有统计`}
            open={open}
            onClose={onClose}
            {...rest}
        >
            <Form onSubmit={handleSubmit(onOk)}>
                <Flex mb={24} direction="column" gap="8px">
                    <Label>统计平台</Label>
                    <Flex gap="16px" px={2} py={4}>
                        {PLATFORM_OPTIONS.map(item => (
                            <StateButton
                                key={item.value}
                                selected={item.value === platform}
                                radius={100}
                                onClick={() => setValue('type', item.value)}
                            >
                                {item.label}
                            </StateButton>
                        ))}
                    </Flex>
                </Flex>

                <Flex mb={24} direction="column" gap="8px">
                    <Label required>
                        标题 <Error>{formState.errors.title?.message}</Error>
                    </Label>
                    <Input {...register('title', { required: '请填写标题' })} placeholder="填写标题，方便查找和区分" />
                </Flex>

                <Flex mb={24} direction="column" gap="8px">
                    <Flex alignItems="center" justifyContent="space-between">
                        <Label required>
                            嵌入统计代码 <Error>{formState.errors.code?.message}</Error>
                        </Label>
                        <Anchor to="https://ipl.parllay.cn/qa/front/index.html?menu_id=224" target="_blank" rel="noreferrer">
                            <Text size={14}> 如何嵌入统计代码？</Text>
                        </Anchor>
                    </Flex>
                    <InjectCodeBoxBody
                        component="textarea"
                        {...register('code', { required: '不能为空' })}
                        // value={data?.iclick.code}
                        // onChange={e => {
                        //     // @todo Box多态类型有点问题
                        //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //     // @ts-expect-error
                        //     const code = e.target.value
                        //     const payload = {
                        //         iclick: {
                        //             enabled: !!data?.iclick.enabled,
                        //             code
                        //         }
                        //     }
                        //     debounceUpdateRemoteConfigure(payload)
                        //     mutate(
                        //         { ...data, ...payload },
                        //         {
                        //             revalidate: false
                        //         }
                        //     )
                        // }}
                        placeholder="粘贴统计代码，可以持续监测您的网站数据，应用发布后生效"
                    />
                </Flex>
                <Flex justifyContent="flex-end" gap="12px">
                    <Button
                        style={{ width: 104, height: 40 }}
                        onClick={() => {
                            reset()
                            onClose?.()
                        }}
                    >
                        取消
                    </Button>
                    <Button style={{ width: 104, height: 40 }} type="primary" htmlType="submit">
                        确认
                    </Button>
                </Flex>
            </Form>
        </TitleInContentModal>
    )
}
