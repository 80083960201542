import { Loading } from '@byecode/ui/components/Loading'
import { CenteredWrapper } from '@lighthouse/shared'
import React, { useEffect } from 'react'
import { useUnmount } from 'react-use'

import { PageProvider, PageStackProvider } from '@/contexts/PageContext'

import { PageContent } from './PageContentV2'

interface PageContainerProps {
    pageId: string
    loading?: boolean
    stackId: string
    refreshPageManually?: () => void
}

export const PageContainer = ({ pageId, stackId, loading, refreshPageManually }: PageContainerProps) => {
    return (
        <PageProvider value={{ pageId, refreshPage: refreshPageManually }}>
            <PageStackProvider value={stackId}>
                {loading ? (
                    <CenteredWrapper style={{ padding: '80px 0' }}>
                        <Loading size={32} />
                    </CenteredWrapper>
                ) : (
                    <PageContent />
                )}
            </PageStackProvider>
        </PageProvider>
    )
}
