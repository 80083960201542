import { Message } from '@byecode/ui'
import type { ActionFlowNode, NotificationActionConfig } from '@lighthouse/shared'
import { generateVariableValue, PAGE_CONTAINER_HOST_ID } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleNotification = async (node: ActionFlowNode<NotificationActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { notificationStatus = 'SUCCESS', notificationContent } = config as NotificationActionConfig

    const toastType = notificationStatus.toLocaleLowerCase() as 'info' | 'success' | 'error'

    const title = generateVariableValue({ innerType: 'TEXT', jsonContent: notificationContent, extraParams: excParams })

    Message[toastType]({
        // containerId: PAGE_CONTAINER_HOST_ID,
        title
    })

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        title,
        notificationStatus
    })
}
