import { Select } from '@byecode/ui'
import type { FieldType } from '@lighthouse/core'
import type { FlowNode, NodeTypes, VariableSource } from '@lighthouse/shared'
import {
    CollapseBox,
    fieldSettingMap,
    getDefaultValueOptions,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    InnerTypeMapByFieldType,
    nodeTypeOptions,
    useAtomData,
    USER_DATASOURCE,
    VariableSelect,
    VariableSourceType
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useId, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdderContext, useActionAdderContext } from '@/contexts/ActionAdderContext'
import { useBlockSettingContext } from '@/contexts/BlockSettingContext'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { uploadManagerInAppParams } from '@/utils/auth'

import * as SC from '../../styles'

export interface DownloadFileActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
    isAction?: boolean
}

const filterFile = (type: FieldType) => type === 'file'

export const DownloadFileActionConfigure: React.FC<DownloadFileActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    isAction,
    actionTypeSwitcher
}) => {
    const { control, watch } = useFormContext()

    const id = useId()

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { blockId } = useBlockSettingContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { dsId, isForm, viewType } = useActionAdderDepParams()
    const dataSource = useDataSource(appId, envId, dsId ?? '')
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const disabledWithVersion = useIsDisabledWithVersion()
    const { id: flowId, type } = useFlow()
    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const {
        dataSourceList,
        curr: { page }
    } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const userDsOption = useMemo(() => {
        const ds = find(ds => ds.id === USER_DATASOURCE, dataSourceList)
        if (!ds) {
            return
        }

        return getUserDatasourceOptions({
            dataSource: ds,
            validateFieldType: field => fieldSettingMap['file'].includes(field.type)
        })
    }, [dataSourceList])

    const variableOptions = useMemo(() => {
        // if (!dataSource) {
        //     return []
        // }

        return getDefaultValueOptions({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    dataSourceList,
                    parentNodes: allParentNodes
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.parentPage,
                    dataSource: prev.datasource,
                    page: prev.page
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.page,
                    dataSource: curr.datasource,
                    page: curr.page
                }
            ].filter(Boolean) as VariableSource[],
            validateFieldType: type => filterFile(type)
        })
    }, [isInFlow, dataSourceList, allParentNodes, isNotAutomation, prev.datasource, prev.page, curr.datasource, curr.page])

    const formOption = useMemo(
        () =>
            isForm && !isInFlow ? getFormDatasourceOptions({ dataSource, validateFieldType: field => filterFile(field.type) }) : undefined,
        [dataSource, isInFlow, isForm]
    )

    const { customViewOption } = useVariableCustomViewOption(field => filterFile(field.type))

    const viewOption = useMemo(() => {
        if (isInFlow) {
            return
        }
        return customViewOption || getViewOptions({ dataSource, viewType, validateFieldType: field => filterFile(field.type) })
    }, [customViewOption, viewType, isInFlow, dataSource])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="下载内容" required>
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>选择附件</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.fileUrl`}
                                control={control}
                                render={({ field }) => (
                                    <VariableSelect
                                        disabled={disabledWithVersion}
                                        field={{
                                            id: '',
                                            dsId: '',
                                            type: 'file',
                                            name: 'fileUrl',
                                            innerType: InnerTypeMapByFieldType['file']
                                        }}
                                        // uploadProps={{
                                        //     accept: 'image/*',
                                        //     multiple: false,
                                        //     uploadOptions: {
                                        //         info: { id, label: page?.name ?? '', groupId: pageId },
                                        //         options: uploadManagerInAppParams()
                                        //     }
                                        // }}
                                        options={variableOptions}
                                        formOption={formOption}
                                        userOption={userDsOption}
                                        viewOption={viewOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
