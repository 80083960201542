import { Button, IconFont } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { type ButtonBlockAbstract, type ButtonItem, type PageAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { omit, reduce } from 'rambda'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { applicationThemeColorAtom } from '@/atoms/application/state'
import { getInitButton } from '@/constants/Block/generate/button'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { ButtonSettingItem } from './ButtonSettingItem'

interface ButtonListProps {
    prefixName?: string
    listKeyName?: string
    allPages: PageAbstract[]
    isViewOption?: boolean
    hideConfigs?: {
        fillWay?: boolean
    }
}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`

export const ButtonSettingList: React.FunctionComponent<ButtonListProps> = ({
    allPages,
    prefixName = '',
    listKeyName = 'group',
    hideConfigs,
    isViewOption
}) => {
    const { control } = useFormContext<ButtonBlockAbstract['config']>()
    const primaryColor = useAtomData(applicationThemeColorAtom)
    const [activeId, setActiveId] = useState('')

    const {
        fields: f,
        remove,
        append,
        move
    } = useFieldArray({
        control,
        // @ts-expect-error TODO: fix type
        name: `${prefixName}${listKeyName}`,
        keyName: 'key'
    })

    const fields = f as unknown as ButtonItem[]

    const [openedMap, setOpenMap] = useImmer(
        reduce<ButtonItem, Record<string, boolean>>(
            (preVal, { id }) => {
                preVal[id] = true
                return preVal
            },
            {},
            fields
        )
    )

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))
    const disabledWithVersion = useIsDisabledWithVersion()

    const handleDragStart = useCallback((event: DragStartEvent) => {
        const { active } = event
        setActiveId(active?.id.toString())
    }, [])

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.id === active.id)
                const newIndex = fields.findIndex(item => item.id === over?.id)
                move?.(oldIndex, newIndex)
            }
            setActiveId('')
        },
        [fields, move]
    )

    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragMove={handDragMove}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={fields.map(d => d.id)} strategy={verticalListSortingStrategy}>
                    {fields.map((item, index) => (
                        <ButtonSettingItem
                            key={item.id}
                            index={index}
                            prefixName={prefixName}
                            listKeyName={listKeyName}
                            hideConfigs={hideConfigs}
                            opened={openedMap[item.id] && !activeId}
                            allPages={allPages}
                            themeColor={primaryColor}
                            isViewOption={isViewOption}
                            disabled={disabledWithVersion}
                            onChangeOpen={opened => {
                                setOpenMap(draft => {
                                    draft[item.id] = opened
                                })
                            }}
                            onDelete={() => {
                                remove(index)
                                setOpenMap(draft => {
                                    draft = omit([item.id], draft)
                                })
                            }}
                        />
                    ))}
                </SortableContext>
                <Button
                    type="tag"
                    style={{ justifyContent: 'flex-start', borderRadius: 100, marginTop: 8 }}
                    onClick={() => {
                        const newItem = getInitButton()
                        append(newItem)
                        // setOpenMap(draft => {
                        //     draft[newItem.id] = true
                        // })
                    }}
                    icon={<IconFont size={16} color="var(--color-gray-500)" type="Add" />}
                >
                    <Text>添加</Text>
                </Button>
            </DndContext>
        </SCxContainer>
    )
}
