import type { AiFieldStatus, DataSourceAbstract, Field, FieldADTValue, HighLightConditions, RecordLikeProtocol } from '@lighthouse/core'
import isDeepEqual from 'fast-deep-equal'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { memo, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { editableInCellFieldTypes, NO_EDIT_SYSTEM_FIELD, notEditableFieldTypes } from '../../constants'
import { useDataSourceContext } from '../../contexts'
import { useAtomData } from '../../hooks'
import { getFieldActiveCellColor, getIsAppointField } from '../../utils'
import FieldValuePreviewer from '../FieldValuePreviewer'
import type { TablePopoverState, TableTooltipState } from '../Table/TableContent'

export interface TableCellProps {
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    field: Field
    recordId: string
    width?: number
    style?: React.CSSProperties
    recordPoolAtom: ReturnType<typeof atomWithImmer<RecordLikeProtocol[]>>
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    placeholder?: string
    styleConditions?: HighLightConditions
    primaryDataSourceFieldIds?: Set<string>
    disableEdit?: boolean
    onOpenEditor?: (data: TablePopoverState) => void
    onOpenTooltip?: (data: TableTooltipState) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onAiGeneration?: (fieldId: string) => Promise<boolean>
}

const SCxWindowShape = styled.div<{ activeFieldCellColor?: string }>`
    min-height: 36px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    border-right: 1px solid var(--color-gray-200);

    &:hover {
        z-index: 2;
        background: var(--color-white);
        &:before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: calc(100% - 1px);
            left: 0;
            top: 0;
            border: 2px solid var(--color-theme-4);
            background-color: transparent;
            /* z-index: 1; */
        }
    }

    &.field-active {
        background: ${({ activeFieldCellColor }) => activeFieldCellColor || 'var(--color-blue-50)'};
    }

    &.cell-active {
        z-index: 2;
        background-color: var(--color-white);
        &:before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: calc(100% - 1px);
            left: 0;
            top: 0;
            border: 2px solid var(--color-main);
            background-color: transparent;
            /* z-index: 1; */
        }
    }
`

const SCxWindowShapeContent = styled.div<{ background?: string }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({ background }) => background || 'transparent'};
`

const SCxWindowShapePopperAnchor = styled.div`
    position: absolute;
    left: -2px;
    top: -2px;
`

const TableCell: React.FC<TableCellProps> = ({
    dataSource,
    dataSourceList,
    field,
    recordPoolAtom,
    aiFieldStatusListAtom,
    recordId,
    width,
    style,
    placeholder,
    styleConditions,
    primaryDataSourceFieldIds,
    disableEdit,
    onOpenEditor,
    onOpenTooltip,
    onCellChange,
    onAiGeneration
}) => {
    const { appId, id: dsId } = dataSource
    const { personOptions } = useDataSourceContext()
    const { id: fieldId, type } = field
    const data = useAtomData(
        recordPoolAtom,
        useCallback(
            draft => find(item => item.appId === appId && item.dsId === dsId && item.id === recordId, draft)?.content?.[fieldId]?.value,
            [appId, dsId, fieldId, recordId]
        )
    )
    const aiFieldStatus = useAtomData(
        aiFieldStatusListAtom,
        useCallback(
            draft => find(item => item.dataSourceId === dsId && item.recordId === recordId && item.fieldId === fieldId, draft)?.state,
            [dsId, fieldId, recordId]
        )
    )

    const editorRef = useRef<HTMLDivElement>(null)
    const isNoneShape = notEditableFieldTypes.has(type)
    const isNoEditSystemField = getIsAppointField(fieldId, NO_EDIT_SYSTEM_FIELD)
    const isEnableFieldInJoinDataSource = useMemo(() => primaryDataSourceFieldIds?.has(fieldId), [fieldId, primaryDataSourceFieldIds])
    const isEditorInCell = editableInCellFieldTypes.has(type)

    const popperAnchorRef = useRef<HTMLDivElement>(null)

    const dataKey = useMemo(() => `${dsId}&${recordId}&${fieldId}`, [dsId, recordId, fieldId])
    const dataField = useMemo(() => `${dsId}&${fieldId}`, [dsId, fieldId])

    const handleCellChange = useCallback(
        (value: FieldADTValue) => {
            if (!onCellChange) {
                return Promise.resolve(false)
            }
            return onCellChange(recordId, value)
        },
        [onCellChange, recordId]
    )

    const activeFieldCellColor = useMemo(
        () => getFieldActiveCellColor({ field, dataSource, dataSourceList }),
        [dataSource, dataSourceList, field]
    )

    const handleOpen = useCallback(() => {
        if (disableEdit) {
            return
        }

        const cellElement = editorRef.current
        if (!cellElement?.className.includes('cell-active')) {
            return
        }

        // 复选框在单元格内编辑
        if (isEditorInCell) {
            return
        }

        if (!isEnableFieldInJoinDataSource) {
            onOpenTooltip?.({
                toolTipAnchorElement: cellElement,
                message: '该副表字段不允许编辑单元格内容'
            })
            return
        }

        if (isNoneShape || isNoEditSystemField) {
            // setOpened(true)
            onOpenTooltip?.({
                toolTipAnchorElement: cellElement,
                message: '该字段类型不允许编辑单元格内容'
            })
            return
        }
        if (popperAnchorRef.current && cellElement) {
            onOpenEditor?.({
                popperAnchorElement: popperAnchorRef.current,
                editorElement: cellElement,
                currentRecordId: recordId,
                currentWidth: width,
                currentField: field
            })
        }
    }, [
        disableEdit,
        field,
        isEditorInCell,
        isEnableFieldInJoinDataSource,
        isNoneShape,
        isNoEditSystemField,
        onOpenEditor,
        onOpenTooltip,
        recordId,
        width
    ])

    return (
        <SCxWindowShape
            activeFieldCellColor={activeFieldCellColor}
            ref={editorRef}
            style={style}
            onPointerDown={handleOpen}
            data-key={dataKey}
            data-cell-field={dataField}
        >
            <SCxWindowShapeContent>
                <FieldValuePreviewer
                    data={data}
                    field={field}
                    isWrap={false}
                    disabled={!isEnableFieldInJoinDataSource}
                    width={width}
                    styleConditions={styleConditions}
                    placeholder={placeholder}
                    aiFieldStatus={aiFieldStatus}
                    isShowDepartmentId
                    isShowRoleId
                    onCellChange={handleCellChange}
                    onAiGeneration={onAiGeneration}
                />
                <SCxWindowShapePopperAnchor data-type="anchor" ref={popperAnchorRef} />
            </SCxWindowShapeContent>
        </SCxWindowShape>
    )
}

export default memo(TableCell, isDeepEqual)
