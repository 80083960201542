import { Divider } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import React from 'react'

import { StyleSetting } from '@/components/DesignSetting/StyleSetting'

import { CardLayout } from './CardLayout'
import { DefaultDataSource } from './DataSource'
import { StyleSwitcher } from './StyleSwitcher'
import { View } from './View'

interface BasicProps {
    id: string
    dataSource?: DataSourceAbstract
}

export const Basic: React.FC<BasicProps> = ({ id, dataSource }) => {
    return (
        <>
            <StyleSwitcher />
            <Divider color="var(--color-gray-200)" />
            <DefaultDataSource />
            <Divider color="var(--color-gray-200)" />
            {dataSource && (
                <>
                    <View id={id} />
                    <Divider color="var(--color-gray-200)" />
                    <CardLayout />
                    <Divider color="var(--color-gray-200)" />
                    <StyleSetting applyTarget="customView" blockId={id} customViewDsId={dataSource.id} />
                </>
            )}
        </>
    )
}
