import { Toast } from '@byecode/ui'
import { ErrorMessage, useHandleAbortPrevious } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useSWRConfig } from 'swr'

import * as srv from '@/services'

import { InvitePhoneItem } from './InvitePhoneItem'
// import { useBoundStore } from '@/stores'
import * as SC from './styles'

export interface InvitePhoneProps {
    children?: React.ReactNode
    spaceId: string
    onClose: () => void
}

export interface InviteFormOption {
    avatar?: string
    userId: string
    roleId: string
}

// 表单验证错误回调
const onError = () => undefined

export const InvitePhone: React.FC<InvitePhoneProps> = ({ spaceId, onClose }) => {
    const { mutate } = useSWRConfig()
    // const { name, members } = useBoundStore(s => pick(['name', 'members', 'updateWorkSpace'], s.workSpaceSlice))
    const {
        control,
        handleSubmit,
        clearErrors,
        getValues,
        formState: { errors }
    } = useForm<{ options: InviteFormOption[] }>({
        mode: 'onSubmit',
        defaultValues: {
            options: [
                {
                    avatar: '',
                    userId: '',
                    roleId: 'SPACE_MEMBER'
                }
            ]
        }
    })
    const { fields, remove, append, update } = useFieldArray({ control, name: 'options' })

    const { mutation: getUserAvatar } = useHandleAbortPrevious(srv.getUserAvatar)

    const handleAdd = useCallback(() => {
        const inviteSort: InviteFormOption = {
            avatar: '',
            userId: '',
            roleId: 'SPACE_MEMBER'
        }

        append(inviteSort)
    }, [append])

    const handleDelete = useCallback(
        (index: number) => {
            remove(index)
        },
        [remove]
    )

    const inviteSubmit = useCallback(
        async (data: { options: InviteFormOption[] }) => {
            const isAdd = await srv.addUsersToSpace(spaceId, data.options)
            if (isAdd) {
                Toast.success('邀请成功')
                // updateWorkSpace({ id: spaceId, name, members: members + 1 })
                mutate(`/spaceGetUsers/get-${spaceId}`)
                onClose()
            }
        },
        [mutate, onClose, spaceId]
    )

    const handleFocus = useCallback(
        (index: number) => {
            clearErrors(`options.${index}`)
        },
        [clearErrors]
    )

    // 监测是否为真实用户
    const checkUser = (index: number) => {
        const value = getValues(`options.${index}`)
        if (value.userId) {
            return true
        }
        if (value.avatar === 'error') {
            return '不存在的用户'
        }
        return '请输入正确的手机号'
    }

    return (
        <SC.LinkForm
            onSubmit={(ev: React.FormEvent) => {
                ev.preventDefault()
                handleSubmit(inviteSubmit, onError)()
            }}
        >
            {fields.map((field, index) => (
                <ErrorMessage
                    zIndex={1}
                    key={field.id}
                    name={`options.${index}`}
                    isRelative={false}
                    errors={errors}
                    left={40}
                    style={{ flex: 1 }}
                >
                    <Controller
                        name={`options.${index}`}
                        control={control}
                        rules={{
                            validate: {
                                checkUser: () => checkUser(index)
                            }
                        }}
                        render={({ field }) => (
                            <InvitePhoneItem
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                onFocus={() => handleFocus(index)}
                                enableDelete={fields.length - 1 === index && fields.length > 1}
                                onDelete={() => handleDelete(index)}
                            />
                        )}
                    />
                </ErrorMessage>
            ))}
            <SC.InviteFooter>
                <SC.InviterAdder onClick={handleAdd} icon={<SC.InviteIcon type="Add" />}>
                    添加
                </SC.InviterAdder>
                <SC.InviteBtn type='primary' htmlType="submit">邀请</SC.InviteBtn>
            </SC.InviteFooter>
        </SC.LinkForm>
    )
}
