import { ErrorFallback } from '@lighthouse/block'
import {
    animated,
    easings,
    useSpring
} from '@react-spring/web'
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'

import SettingTheme from '@/components/ApplicationSetting/SettingTheme'
import { QuestionDropDown } from '@/components/PopoverQuestion'
import type { TooltipDocumentType } from '@/components/PopoverQuestion/types'
import type { BlockTreeItem } from '@/constants/Block/type'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { BlockList } from './BlockList'
import LayersCenter from './LayersCenter'
import type { TabType } from './Navbar'
import { NavBar } from './Navbar'
import PageCenter from './PageCenter'
import * as CM from './style'

const AnimateDiv = styled(animated.div)`
    height: 100%;
    border-style: solid;
    border-color: var(--color-gray-200);
    border-width: 0 1px 0 1px;
    /* overflow: hidden; */
`

const SCxQuestion = styled.div`
    position: absolute;
    left: 280px;
    top: 16px;
    width: 320px;
    background-color: var(--color-black);
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
    border-radius: 12px;
    z-index: 5;
`

const drawerFromStyle = { x: -240 }

export const PageLeftPanel: React.FC = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const [tab, setTab] = useState<TabType>(disabledWithVersion ? 'page' : 'component')

    const [questionType, setQuestionType] = useState<TooltipDocumentType | null>(null)

    const drawerToStyle = useMemo(() => ({ x: tab ? 0 : -240 }), [tab])

    const [drawerStyle, drawerApi] = useSpring(() => ({
        from: { x: 0 },
        to: drawerToStyle,
        config: {
            easing: easings.easeOutCirc,
            duration: 300
        }
    }))

    const handleTabChang = useCallback((newTab: TabType) => {
        setTab(newTab)
    }, [])

    const handleOpenQuestion = useCallback((v: BlockTreeItem) => {
        // setQuestionType()
    }, [])

    useEffect(() => {
        drawerApi.start(tab ? { x: 0 } : drawerFromStyle)
    }, [drawerApi, tab])

    const PanelEle = useMemo(() => {
        switch (tab) {
            case 'page': {
                return <PageCenter />
            }

            case 'layers': {
                return <LayersCenter />
            }

            case 'component': {
                return <BlockList onBack={() => handleTabChang(null)} onOpenQuestion={handleOpenQuestion} />
            }
            case 'theme': {
                return <SettingTheme />
            }
            default: {
                return null
            }
        }
    }, [handleOpenQuestion, handleTabChang, tab])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <CM.ComponentPanelContainer>
                <NavBar value={tab} onTabChange={handleTabChang} />
                <CM.Chunk style={{ width: tab ? 264 : 0 }}>
                    <AnimateDiv style={{ ...drawerStyle, translateX: drawerStyle.x }}>{PanelEle}</AnimateDiv>
                </CM.Chunk>
                {questionType && (
                    <SCxQuestion>
                        <QuestionDropDown type={questionType} />
                    </SCxQuestion>
                )}
            </CM.ComponentPanelContainer>
        </ErrorBoundary>
    )
}
