import { Flex, IconFont, Tooltip } from '@byecode/ui'
import { useAtomData } from '@lighthouse/shared'
import { isIosPlatform } from '@lighthouse/tools'
import { divide } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { pageUndoRedoController } from '@/utils/undoRedo/page/controller'
import { usePageUndoRedoHelper } from '@/utils/undoRedo/page/handler'

const Button = styled.button`
    all: unset;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    width: 32px;
    height: 32px;
    color: var(--color-gray-400);

    &:disabled {
        color: var(--color-gray-200);
        cursor: not-allowed;
    }

    &:not(:disabled):hover,
    &:not(:disabled).active {
        color: var(--color-gray-900);
        background-color: rgba(50, 75, 99, 0.12);
    }
`

export const RedoUndo = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const queryParams = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => ({ rootPageId: s?.rootPageId ?? '', pageId: s?.pageId ?? '', stackId: s?.stackId ?? '' }), [])
    )
    const helper = usePageUndoRedoHelper(queryParams)
    const isIos = isIosPlatform()

    return (
        <Flex styles={{ root: { borderRadius: 6, backgroundColor: 'var(--color-gray-100)', overflow: 'hidden' } }} alignItems="center">
            <Tooltip
                title={
                    <div style={{ textAlign: 'center', lineHeight: '20px' }}>
                        <p>撤回</p>
                        <p style={{ color: 'var(--color-gray-400)' }}>{isIos ? '⌘ + Z' : 'ctrl + Z'}</p>
                    </div>
                }
                disabled={disabledWithVersion || pageUndoRedoController.undoStack.length === 0}
            >
                <Button disabled={disabledWithVersion || pageUndoRedoController.undoStack.length === 0} onClick={() => helper.undo()}>
                    <IconFont type="Undo" size={16} />
                </Button>
            </Tooltip>
            <div style={{ width: 1, height: 32, backgroundColor: 'var(--color-gray-200)' }} />
            <Tooltip
                title={
                    <div style={{ textAlign: 'center', lineHeight: '20px' }}>
                        <p>重做</p>
                        <p style={{ color: 'var(--color-gray-400)' }}> {isIos ? '⌘ + Y' : 'ctrl + Y'}</p>
                    </div>
                }
                disabled={disabledWithVersion || pageUndoRedoController.redoStack.length === 0}
            >
                <Button disabled={disabledWithVersion || pageUndoRedoController.redoStack.length === 0} onClick={() => helper.redo()}>
                    <IconFont type="Redo" size={16} />
                </Button>
            </Tooltip>
        </Flex>
    )
}
