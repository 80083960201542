import type { FilterBlockItemConfig } from '@lighthouse/core'
import { type FilterBlockConfig, FilterBlockMode, FilterSelectWay, FilterWay, NumberRangeMode } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'

/**
 * 生成筛选器block的配置
 *
 * @returns {FilterBlockConfig}
 */
export const generateFilterBlock = (): FilterBlockConfig => {
    return {
        mode: FilterBlockMode.normal,
        filterItems: [],
        showLabel: true
        // selectWay: FilterSelectWay.singleSelect,
        // filterWay: FilterWay.textFilter
    }
}

export const getFilterBlockItem = (type?: FilterWay): FilterBlockItemConfig => {
    const getConfig = (type?: FilterWay) => {
        if (type === FilterWay.numberFilter) {
            return {
                numberRangeMode: NumberRangeMode.disable
            }
        }
        if(type === FilterWay.cascadeFilter) {
            return {
                fieldPointer: 'ID'
            }
        }
        return {}
    }

    return {
        id: nanoid(),
        title: '筛选项',
        filterWay: type || FilterWay.textFilter,
        selectWay: FilterSelectWay.singleSelect,
        ...getConfig(type)
    }
}
