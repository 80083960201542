export const COLOR_BOARD_WIDTH = 262
export const COLOR_BOARD_HEIGHT = 150
export const SLIDER_WIDTH = 232
export const HSL_MAX = 360

export const PREFERRED_COLORS = [
    ['#B12419', '#E93421', '#E93423', '#FEFF54', '#9FCE62', '#4FAE59', '#4FADEB', '#306FBB', '#081F58', '#66349E'],
    ['#FFFFFF', '#000000', '#E8E6E7', '#475369', '#4F71C0', '#DE8345', '#A5A5A5', '#F5C342', '#6798D0', '#7EAB56'],
    ['#F3F3F3', '#7F7F7F', '#CFCECF', '#D8DCE4', '#DBE2F4', '#F6E6D9', '#ECECEC', '#FDF3D2', '#E1EBF5', '#E6EDDC'],
    ['#D8D8D8', '#595959', '#AFABAC', '#B0B9CA', '#B6C6E3', '#EFCEB0', '#DBDBDB', '#FAE6A2', '#C2D7EC', '#CADEB8'],
    ['#BFBFBF', '#3F3F3F', '#75716F', '#8794AF', '#95A8D8', '#ECB387', '#CACACA', '#F9DA78', '#A5C2E4', '#B1CF94'],
    ['#A3A4A4', '#262626', '#3A3839', '#353E4D', '#385194', '#B9602A', '#7B7B7B', '#B89232', '#4374B1', '#5E7F40'],
    ['#7E7F7F', '#0C0C0C', '#191718', '#242A34', '#24365F', '#7E3C19', '#525252', '#7A621D', '#2D4D78', '#3E5528']
]

export const SYSTEM_COLORS = [
    '#DB3553',
    '#FF537F',
    '#F0B4B6',
    '#EF8C0B',
    '#EEBB17',
    '#DAA76B',
    '#96B25A',
    '#6EC395',
    '#00AA93',
    '#6492B7',
    '#0D19FC',
    '#7A12FF',
    '#08324E'
]
