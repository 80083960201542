import { BlockThumbImageMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type {
    BlockTree,
    BlockTreeItem
} from '../type'
import { BlockKind } from '../type'

const blockCustomView: BlockTreeItem = {
    name: '自定义视图',
    icon: '',
    describeImage: BlockThumbImageMap['custom'],
    color: '#F63D68',
    backGroundColor: 'rgba(246, 61, 104, 0.10)',
    type: BlockType.view,
    subType: 'custom'
}

const blockTable: BlockTreeItem = {
    name: '表格',
    icon: 'BlockTable',
    describeImage: BlockThumbImageMap['table'],
    color: '#12AF94',
    backGroundColor: 'rgba(18, 175, 148, 0.10)',
    type: BlockType.view,
    subType: 'table'
}

const blockAdvancedTable: BlockTreeItem = {
    name: '高级表格',
    icon: 'BlockTable',
    describeImage: BlockThumbImageMap['advancedTable'],
    color: '#2E90FA',
    backGroundColor: 'rgba(46, 144, 250, 0.10)',
    type: BlockType.view,
    subType: 'advancedTable'
}

const blockGallery: BlockTreeItem = {
    name: '画廊',
    icon: 'BlockSimpleTable',
    describeImage: BlockThumbImageMap['gallery'],
    color: '#F63D68',
    backGroundColor: 'rgba(246, 61, 104, 0.10)',
    type: BlockType.view,
    subType: 'gallery'
}

const blockList: BlockTreeItem = {
    name: '列表',
    icon: 'BlockList',
    describeImage: BlockThumbImageMap['list'],
    color: '#FFB74A',
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    type: BlockType.view,
    subType: 'list'
}

const blockKanban: BlockTreeItem = {
    name: '看板',
    icon: 'Kanban',
    describeImage: BlockThumbImageMap['kanban'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    type: BlockType.view,
    subType: 'kanban'
}

const blockCalendar: BlockTreeItem = {
    name: '日历',
    icon: 'Calendar',
    describeImage: BlockThumbImageMap['calendar'],
    color: '#00A389',
    backGroundColor: 'rgba(0, 163, 137, 0.10)',
    type: BlockType.view,
    subType: 'calendar'
}

export const allView: BlockTree = {
    id: BlockKind.view,
    name: '视图',
    // icon: 'View',
    color: '',
    items: [blockCustomView, blockTable, blockList, blockGallery, blockKanban,  blockCalendar, blockAdvancedTable ]
}
