import { Button, IconFont, Input, pointer } from '@byecode/ui'
import { useDebounce } from '@lighthouse/tools'
import cls from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SearchIcon = styled(IconFont)`
    margin-right: 4px;
    color: var(--color-gray-500);
`

const KeywordsInput = styled(Input)`
    width: 0;
    border: none;

    &.active {
        width: 180px;
        transition: width 0.2s ease;
    }
`

const CloseBtn = styled(IconFont)`
    cursor: pointer;
    color: var(--color-gray-400);
    opacity: 0;

    &.show {
        opacity: 1;
    }
`

export interface FlexSearchProps {
    children?: React.ReactNode
    value?: string
    placeholder?: string
    onDebouncedChange?: (val: string) => void
    onChange?: (val: string) => void
}

export const FlexSearch: React.FC<FlexSearchProps> = ({ value = '', placeholder = '搜索', onDebouncedChange, onChange }) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [editing, setEditing] = useState(false)
    const [searchVal, setSearchVal] = useState(value)

    const handleOpen = useCallback(() => {
        setEditing(true)
        inputRef.current?.focus()
    }, [])

    const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchVal(ev.target.value)
    }, [])

    const handleClose = useCallback(() => {
        if (!searchVal) {
            setEditing(false)
        }
    }, [searchVal])

    const handleClear = useCallback(() => {
        setSearchVal('')
        setEditing(false)
    }, [])

    const debouncedSearchVal = useDebounce(searchVal, 500)

    useUpdateEffect(() => {
        onDebouncedChange?.(debouncedSearchVal)
    }, [debouncedSearchVal])

    useUpdateEffect(() => {
        onChange?.(searchVal)
    }, [searchVal])

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Escape') {
                handleClear()
            }
        },
        [handleClear]
    )

    return (
        <SearchWrapper>
            {!editing && (
                <Button type="text" size="md" icon={<SearchIcon size={16} type="SearchLine" />} onClick={handleOpen}>
                    搜索
                </Button>
            )}

            <KeywordsInput
                ref={inputRef}
                value={searchVal}
                prefix={editing && <SearchIcon size={16} type="SearchLine" />}
                className={cls({ active: editing })}
                placeholder={placeholder}
                suffix={editing && <CloseBtn className={cls({ show: searchVal })} type="Close" size={14} onClick={handleClear} />}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleClose}
            />
        </SearchWrapper>
    )
}
