import type { ApplicationSettingLanguage } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { updateAppVersionConfigAtom } from '@/atoms/application/action'
import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { fetchPageListAtom } from '@/atoms/page/action'

import { LanguageList } from './LanguageList'
import * as SC from './styles'

interface SettingLanguageProps {}

const SettingLanguage: React.FunctionComponent<SettingLanguageProps> = props => {
    const language = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.language, [])
    )
    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)
    const { run: fetchPageList } = useAtomAction(fetchPageListAtom)

    const methods = useForm<ApplicationSettingLanguage>({
        mode: 'onChange',
        defaultValues: language
    })

    const { watch, control, setValue } = methods

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingLanguage) => {
                const isSuccess = await updateAppVersionConfig({
                    config: {
                        language: value
                    }
                })
            }),
        [updateAppVersionConfig]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, prev) => {
            handleSubmit(clone(value) as ApplicationSettingLanguage)
        })
        return unsubscribe
    }, [handleSubmit, watch])

    useEffect(() => {
        return () => {
            fetchPageList()
        }
    }, [fetchPageList])

    return (
        <FormProvider {...methods}>
            <SC.Container>
                <LanguageList />
            </SC.Container>
        </FormProvider>
    )
}

export default SettingLanguage
