import { Button, Divider, Input } from '@byecode/ui'
import styled from 'styled-components'

export const StyledDivider = styled(Divider)``

export const ModalAlert = styled.div`
    width: 100%;
    background: var(--color-gray-100);
    padding: 9px 11px;
    font-size: var(--font-size-normal);
    line-height: 22px;
    color: #d88987;
    margin-bottom: 16px;
    word-break: break-word;
`

export const ModalTips = styled.div`
    font-size: var(--font-size-normal);
    line-height: 22px;
    color: var(--color-gray-900);
    margin-bottom: 16px;
`

export const ModalInput = styled(Input)`

`

export const ModalFooter = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
`

export const DelBtn = styled(Button)`

`

export const CancelBtn = styled(Button)`
    margin-right: 12px;
`
