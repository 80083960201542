import { Flex } from '@byecode/ui'
import { FlexSearch } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import type { Updater } from 'use-immer'

import { AppFilter } from './AppFilter'
import { AppSort } from './AppSort'

const SCxContainer = styled(Flex)``

export interface ApplicationFilterAndSortParams {
    search: string
    filter: string
    sort: string
    order: string
}

interface ApplicationToolBarProps {
    data: ApplicationFilterAndSortParams
    setData: Updater<ApplicationFilterAndSortParams>
}

export const ApplicationToolBar: React.FC<ApplicationToolBarProps> = ({ data, setData }) => {
    const { search, filter, sort, order } = data

    const handleSearch = useCallback(
        (val: string) => {
            setData(draft => {
                draft.search = val
            })
        },
        [setData]
    )

    const handleFilter = useCallback(
        (val: string) => {
            setData(draft => {
                draft.filter = val
            })
        },
        [setData]
    )

    const handleSortChange = useCallback(
        (val: string) => {
            setData(draft => {
                draft.sort = val
            })
        },
        [setData]
    )

    const handleOrderChange = useCallback(
        (val: string) => {
            setData(draft => {
                draft.order = val
            })
        },
        [setData]
    )

    return (
        <SCxContainer gap={6}>
            <FlexSearch value={search} onChange={handleSearch} />
            <AppFilter value={filter} onChange={handleFilter} />
            <AppSort sort={sort} order={order} onSortChange={handleSortChange} onOrderChange={handleOrderChange} />
        </SCxContainer>
    )
}
