import { BaseModal } from '@byecode/ui/components/Modal'
import { Toast } from '@lighthouse/bui'
import type { SpaceVersion } from '@lighthouse/core'
import { spaceVersionEnum } from '@lighthouse/shared'
import { useDisclosure } from '@mantine/hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import * as srv from '@/services'
import { useSpaceQuota } from '@/shared/reusable'

import { modalStyles, versionModalStyles } from './constants'
import { ContactSalesModal } from './ContactSalesModal'
import { emitter } from './event'
import UserGrade from './SpaceGrade'
import { VersionConfirm } from './VersionConfirm'
import { WeChatPayModal } from './WeChatPay'

export const SpaceGradeProvider = () => {
    const [opened, { close, open }] = useDisclosure(false)
    const [saleOpened, { close: saleClose, open: saleOpen }] = useDisclosure(false)
    const [versionOpened, { close: versionClose, open: versionOpen }] = useDisclosure(false)

    const [versionConfirmParams, setVersionConfirmParams] = useState<SpaceVersion | null>(null)

    const { data: spaceQuota } = useSpaceQuota()
    const handleOpenVersionConfirm = useCallback(
        (version: SpaceVersion) => {
            setVersionConfirmParams(version)
            versionOpen()
        },
        [versionOpen]
    )

    const handleCloseVersionConfirm = useCallback(() => {
        setVersionConfirmParams(null)
        versionClose()
    }, [versionClose])

    const [productTab, setProductTab] = useState<'version_upgrade' | 'purchase'>('version_upgrade')

    useEffect(() => {
        function openHandle(tab?: 'version_upgrade' | 'purchase') {
            tab && setProductTab(tab)
            open()
        }
        emitter.on('openSpaceGrade', openHandle)
        emitter.on('closeSpaceGrade', close)
        emitter.on('openSaleSpaceGrade', saleOpen)
        emitter.on('closeSaleSpaceGrade', saleClose)
        emitter.on('openVersionConfirm', handleOpenVersionConfirm)
        emitter.on('closeVersionConfirm', handleCloseVersionConfirm)

        return () => {
            emitter.off('openSpaceGrade', openHandle)
            emitter.off('closeSpaceGrade', close)
            emitter.off('openSaleSpaceGrade', saleOpen)
            emitter.off('closeSaleSpaceGrade', saleClose)
            emitter.off('openVersionConfirm', handleOpenVersionConfirm)
            emitter.off('closeVersionConfirm', handleCloseVersionConfirm)
        }
    }, [close, handleCloseVersionConfirm, handleOpenVersionConfirm, open, saleClose, saleOpen, versionClose, versionOpen])

    const [payInfo, setPayInfo] = useImmer<
        | {
              type: 'spaceVersion'
              value: SpaceVersion
          }
        | { type: 'sms'; value: 'SMS_1000' | 'SMS_5000' | 'SMS_15000' }
        |  {
            type: 'traffic',
            value:  'GB_1000'
        } | null
    >(null)

    const spaceId = useCurrentSpaceID()

    const getPayInfo = useMemo(() => {
        if (!payInfo) {
            return
        }

        if (payInfo.type === 'spaceVersion') {
            return () => srv.upgradeSpacePlan(spaceId, payInfo.value)
        }

        if (payInfo.type === 'sms') {
            return () => srv.purchaseSms(spaceId, payInfo.value)
        }
        if (payInfo.type === 'traffic') {
            return () => srv.purchaseTraffic(spaceId, payInfo.value)
        }
    }, [payInfo, spaceId])

    const { update } = useSpaceQuota()

    return (
        <>
            <BaseModal styles={versionModalStyles} open={opened} onClose={close}>
                <UserGrade
                    productTab={productTab}
                    onChangeTab={setProductTab}
                    onWechatPaySpaceUpgrade={v => {
                        setPayInfo({
                            type: 'spaceVersion',
                            value: v
                        })
                    }}
                    onWechatPaySms={v => {

                        setPayInfo({
                            type: 'sms',
                            value: v
                        })
                    }}
                    onWechatPayTraffic={v => {
                        if (spaceQuota?.currentVersionCode === spaceVersionEnum.FREE) {
                            Toast.warning('只有基础版以上才能增购流量包')
                            setVersionConfirmParams('BASIC')
                            versionOpen()
                            return
                        }

                        setPayInfo({
                            type: 'traffic',
                            value: v
                        })
                    }}
                />
            </BaseModal>

            <ContactSalesModal centered padding={0} styles={modalStyles} radius={20} size={1024} opened={saleOpened} onClose={saleClose} />

            <WeChatPayModal
                getPayInfo={getPayInfo}
                onPaySuccess={update}
                open={!!payInfo}
                onClose={() => {
                    setPayInfo(null)
                }}
            />

            {versionConfirmParams && (
                <VersionConfirm
                    open={versionOpened}
                    onClose={versionClose}
                    version={versionConfirmParams}
                    onOpenPayModal={() => {
                        setPayInfo({
                            type: 'spaceVersion',
                            value: versionConfirmParams
                        })
                    }}
                />
            )}
        </>
    )
}
