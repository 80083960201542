import type {
    BlockAbstract,
    BlockType,
    ChartType,
    Direction,
    FieldInputType,
    ViewType
} from '@lighthouse/core'

export enum BlockKind {
    text = 'text',
    view = 'view',
    layout = 'layout',
    button = 'button',
    chart = 'chart',
    file = 'file',
    field = 'field',
    other = 'other',
    navigation = 'navigation'
}

export type DefaultBlockType = 'combinationTitle' | 'highlight' | 'capsuleButton' | 'iconButton'

export type BlockTreeItem =
    | {
          type: Exclude<BlockType, 'container' | 'field' | 'view' | 'chart'> | DefaultBlockType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'container'
          subType: Direction
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'field'
          subType: FieldInputType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'view'
          subType: ViewType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'chart'
          subType: ChartType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }

export type DragNode = BlockTreeItem & { data: BlockAbstract[] }

export interface BlockTree {
    id: string
    name: string
    // icon: string
    iconColor?: string
    color?: string
    items: BlockTreeItem[]
}
