import { Button, Divider, Flex, IconFont, Popover, Text, Tooltip } from '@byecode/ui'
import { APPLICATION_ENV_PROD, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { useLocalStorageValue } from '@react-hookz/web'
import React, { useCallback, useMemo, useState } from 'react'
import { useMatch, useNavigate } from 'react-router'
import styled from 'styled-components'

import { switchAppVersionPreviewAtom } from '@/atoms/application/action'
import { appEnvListAtom, applicationVersionAtom } from '@/atoms/application/state'
import { UrlPreview } from '@/components/ApplicationPublish/PublishWebsite/UrlPreview'
import { useCurrentEnvId } from '@/hooks/useApplication'

import { PublishedContent } from '../../ApplicationHeader/PublishedContent'

const StyledButton = styled(Button)`
    border: none;
    background-color: var(--color-purple-100);
    &:hover {
        background-color: var(--color-purple-200) !important;
    }
`

const StyledTips = styled.div`
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    color: var(--color-yellow-500);
    background-color: var(--color-yellow-100);
`

const StyledEnv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--color-gray-100);
    border-radius: 7px;
    padding: 8px;
    font-size: 14px;
`
const StyledEnvTag = styled.div<{ isProd?: boolean }>`
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    padding: 0 4px;
    height: 22px;
    color: ${({ isProd }) => (isProd ? 'var(--color-main)' : 'var(--color-gray-500)')};
    background-color: ${({ isProd }) => (isProd ? 'var(--color-purple-200)' : 'var(--color-gray-200)')};
`

export const PreviewButton = () => {
    const [open, setOpen] = useState(false)

    const previewEnabled = useAtomData(
        applicationVersionAtom,
        useCallback(s => s.preview, [])
    )

    const action = useAtomAsyncAction(switchAppVersionPreviewAtom)

    const envList = useAtomData(appEnvListAtom)
    const envId = useCurrentEnvId()
    const currentEnv = useMemo(() => envList.find(item => item.envId === envId), [envId, envList])

    const navigate = useNavigate()
    const isDsRoute = useMatch('/:appId/dataSource/:dsId')
    const { value: switchDsEnvTips, set: setSwitchDsEnvTips } = useLocalStorageValue<{ disabled?: boolean; show?: boolean }>(
        'SWITCH_DS_ENV_TIPS'
    )
    const handleToDs = () => {
        setOpen(false)

        if (!switchDsEnvTips?.disabled) {
            setSwitchDsEnvTips({ ...switchDsEnvTips, show: true })
        }

        if (isDsRoute) {
            return
        }

        navigate('./dataSource')
    }

    return (
        <Popover opened={open} onChange={setOpen} width={390} position="bottom-end" withinPortal returnFocus={false}>
            <Tooltip title="测试">
                <Popover.Target>
                    <StyledButton icon={<IconFont type="Play" size={16} color="var(--color-main)" />} />
                </Popover.Target>
            </Tooltip>

            <Popover.Dropdown style={{ padding: 24, borderRadius: 12 }}>
                <PublishedContent
                    header={
                        <Flex direction="column" gap={16}>
                            <Text weight={500} lineHeight="24px">
                                测试应用
                            </Text>
                            <StyledTips>该应用链接仅用于预览和测试，请勿用于实际业务</StyledTips>
                            <UrlPreview hideSettingsButton onLink={() => setOpen(false)} />
                            <StyledEnv>
                                {currentEnv?.envId === APPLICATION_ENV_PROD && (
                                    <>
                                        <Text color="var(--color-gray-500)" ellipsis={false} whiteSpace="unset">
                                            您在使用正式环境数据调试预览，请谨慎操作！以免影响线上应用使用。
                                        </Text>
                                        <Divider color="var(--color-gray-200)" />
                                    </>
                                )}
                                <Flex alignItems="center" gap={4}>
                                    <Text color="var(--color-gray-500)">连接的数据环境：</Text>
                                    <StyledEnvTag isProd={currentEnv?.envId === APPLICATION_ENV_PROD}>
                                        <IconFont type="BlockDatabase" size={16} />
                                        <Text>{currentEnv?.envName}</Text>
                                    </StyledEnvTag>
                                    <Flex
                                        gap={4}
                                        alignItems="center"
                                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                        onClick={handleToDs}
                                    >
                                        <Text color="var(--color-gray-400)">前去设置</Text>
                                        <IconFont type="ArrowRightSmall" size={16} fill="var(--color-gray-400)" />
                                    </Flex>
                                </Flex>
                            </StyledEnv>
                        </Flex>
                    }
                    showShareContent={previewEnabled}
                    footer={
                        previewEnabled ? (
                            <Button
                                block
                                size="lg"
                                loading={action.loading}
                                onClick={() => {
                                    action.run(!previewEnabled)
                                }}
                                style={{ color: 'var(--color-gray-500)' }}
                            >
                                停用测试
                            </Button>
                        ) : (
                            <Button
                                block
                                size="lg"
                                loading={action.loading}
                                onClick={() => {
                                    action.run(!previewEnabled)
                                }}
                                type="primary"
                            >
                                启用测试
                            </Button>
                        )
                    }
                    isPreview
                    onClose={() => setOpen(false)}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
