import type { LoginProtocol, SpaceUser, WorkSpaceAbstract } from '@lighthouse/core'
import type {
    AddUsersPayload,
    BrowsePath,
    CreateTeamPayload,
    LoginUser,
    ScanStatus,
    Team,
    TeamTree,
    UpdateTeamPayload
} from '@lighthouse/shared'

import type { HttpResp } from '@/http'
import http from '@/http'

import { isCsrf, JsonContentType } from './constant'
import type { PromoInviteResult, updatePasswordPayload, WechatResult } from './types'

/**
 * 登录
 * @param payload
 * @returns
 */
export async function login(payload: LoginProtocol): Promise<boolean | undefined> {
    const res = await http.post<unknown, HttpResp<LoginUser>>('api/auth/v1/login', payload, {
        headers: isCsrf
    })

    return res?.success
    // if (res?.success) {
    //     const { refreshToken } = res.content
    //     return refreshToken
    // }
}

/**
 * 退出登录
 * @param
 * @returns
 */
export async function logout(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<LoginUser>>('api/v1/user/logout')
    return res.success
}

/**
 * 验证码登录
 * @param {LoginProtocol} params
 * @return
 */
export async function codeLogin(payload: LoginProtocol): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<LoginUser>>('api/auth/v1/login', payload, {
        headers: isCsrf
    })

    return res.success
}

/**
 *
 *  完善用户信息
 * @param {string} username
 * @param {string} password
 * @return {*}
 */
export async function consummateInfo(username: string, password: string, spaceName: string, spaceIcon?: string): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/user/consummate', { username, password, spaceName, spaceIcon })
    return res.success
}

/**
 *
 * 修改密码
 * @param {ResetPasswordProtocol} params
 * @return {*}
 */
export async function updatePassword(payload: updatePasswordPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/user/updatePassword', payload)
    return res.success
}

/**
 * 用户信息
 * @param
 * @returns
 */
export async function getUser(): Promise<SpaceUser> {
    const res = await http.get<unknown, HttpResp<SpaceUser>>('api/v1/user/getCurrentUser')
    return res.content
}

/**
 * 更新用户信息
 * @param {SpaceUser} payload
 * @returns
 */
export async function updateUser(payload: SpaceUser): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>(
        'api/v1/user/updateUser',
        { ...payload },
        {
            headers: { 'Content-Type': 'application/json' }
        }
    )
    return res.content
}

export async function saveBrowse(browseParams: BrowsePath) {
    const res = await http.put<unknown, HttpResp<WorkSpaceAbstract[]>>(
        'api/v1/user/browse',
        { ...browseParams },
        { headers: { 'Content-Type': 'application/json' } }
    )
    return res.content
}

export async function getBrowse() {
    const res = await http.get<unknown, HttpResp<BrowsePath>>('api/v1/user/getBrowse')
    return res?.content || {}
}

// export async function profileComplete() {
//     const res = await http.get<unknown, HttpResp<BrowsePath>>('api/v1/user/profileComplete')
//     return res?.content || {}
// }

/**
 *
 * 获取指定用户头像和昵称
 * @param
 * @return {*}
 */
export async function getUserAvatar(payload: { mobile: string; abort?: AbortController }): Promise<SpaceUser> {
    const { mobile, abort } = payload
    const res = await http.get<unknown, HttpResp<SpaceUser>>('api/v1/user/getUser', {
        params: { mobile },
        signal: abort?.signal
    })
    return res.content
}

/**
 * @description 新手引导流程结束, 成为老用户
 * @return {*}  {Promise<boolean>}
 */
export async function becomeRegularUser(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<string>>('api/v1/user/becomeRegularUser')
    return res.success
}

// ##################################################################### //
// ############################## 用户团队 ############################# //
// ##################################################################### //

/**
 *
 * 获取层级团队列表
 * @param spaceId
 * @return {*}
 */
export async function getTreeTeams(spaceId: string): Promise<TeamTree[]> {
    const res = await http.get<unknown, HttpResp<TeamTree[]>>('api/v1/team/getTreeTeams', {
        params: { spaceId }
    })
    return res.content
}

/**
 *
 * 获取团队列表
 * @param spaceId
 * @param keyword
 * @return {*}
 */
export async function getTeams(spaceId: string, keyword?: string): Promise<TeamTree[]> {
    const res = await http.get<unknown, HttpResp<TeamTree[]>>('api/v1/team/getTeams', {
        params: { spaceId, keyword }
    })
    return res.content
}

/**
 *
 * 创建团队
 * @param spaceId
 * @param {CreateTeamPayload} params
 * @return {*}
 */
export async function createTeam(spaceId: string, params: CreateTeamPayload): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/team/createTeam', { spaceId, ...params })
    return res.content
}

/**
 *
 * 删除团队
 * @param spaceId
 * @param teamId
 * @return {*}
 */
export async function deleteTeam(spaceId: string, teamId: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/team/deleteTeam', {
        params: { spaceId, teamId }
    })
    return res.content
}

/**
 *
 * 修改团队信息
 * @param spaceId
 * @param {UpdateTeamPayload} params
 * @return {*}
 */
export async function updateTeam(teamId: string, params: UpdateTeamPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/team/updateTeam', { teamId, ...params })
    return res.content
}

/**
 *
 * 添加用户到团队中
 * @param spaceId
 * @param {AddUsersPayload} params
 * @return {*}
 */
export async function addUsersToTeams(spaceId: string, params: AddUsersPayload): Promise<boolean> {
    const res = await http.put<unknown, HttpResp<boolean>>(
        'api/v1/team/addUsersToTeams',
        { spaceId, ...params },
        { headers: { 'Content-Type': 'application/json' } }
    )
    return res.content
}

/**
 *
 * 获取团队信息
 * @param teamId
 * @return {*}
 */
export async function getBaseTeam(teamId: string): Promise<Team> {
    const res = await http.get<unknown, HttpResp<Team>>('api/v1/team/getBaseTeam', {
        params: { teamId }
    })
    return res.content
}

/**
 *
 * 获取团队下成员列表
 * @param teamId
 * @return {*}
 */
export async function getTeamUsers(teamId: string): Promise<SpaceUser[]> {
    const res = await http.get<unknown, HttpResp<SpaceUser[]>>('api/v1/team/getTeamUsers', {
        params: { teamId }
    })
    return res.content
}

/**
 *
 * 获取子团队列表
 * @param teamId
 * @return {*}
 */
export async function getChildrenTeam(teamId: string): Promise<Team[]> {
    const res = await http.get<unknown, HttpResp<Team[]>>('api/v1/team/getChildrenTeam', {
        params: { teamId }
    })
    return res.content
}

/**
 *
 * 获取上级团队
 * @param teamId
 * @return {*}
 */
export async function getParentTeam(teamId: string): Promise<Team> {
    const res = await http.get<unknown, HttpResp<Team>>('api/v1/team/getParentTeam', {
        params: { teamId }
    })
    return res.content
}

/**
 *
 * 删除团队
 * @param spaceId
 * @param teamId
 * @param userId
 * @return {*}
 */
export async function removeUserFromTeam(spaceId: string, teamId: string, userId: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/team/removeUserFromTeam', {
        params: { spaceId, teamId, userId }
    })
    return res.content
}

/**
 *
 * 修改父级团队
 * @param spaceId
 * @param teamId
 * @param parentId
 * @return {*}
 */
export async function changeTeamToParent(spaceId: string, teamId: string, parentId: string): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/team/changeTeamToParent', {
        spaceId,
        teamId,
        parentId
    })
    return res.content
}

/**
 *
 * 新用户信息收集
 * @param spaceId
 * @param teamId
 * @param parentId
 * @return {*}
 */
export async function userInfoCollect(params: Record<string, string | string[]>): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>('api/v1/user/userInfoCollect', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 获取微信二维码
 * @param payload
 * @returns
 */
export async function getLoginQR(): Promise<WechatResult> {
    const res = await http.get<unknown, HttpResp<WechatResult>>('api/auth/v1/getLoginQr')
    return res.content
}

/**
 * 获取微信扫码结果
 * @param payload
 * @returns
 */
export async function getLoginPoll(mark: string): Promise<ScanStatus> {
    const res = await http.get<unknown, HttpResp<ScanStatus>>('api/auth/v1/poll', { params: { mark } })
    return res.content
}

/**
 *
 * 用户绑定微信
 * @param mark
 * @return {*}
 */
export async function bindWechat(mark: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp>('api/v1/user/bindWechat', {
        params: { mark }
    })
    return res.success
}

/**
 *
 * 用户解除绑定微信
 * @return {*}
 */
export async function removeBindWechat(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp>('api/v1/user/removeBindWechat')
    return res.success
}

/**
 *
 * 获取是否第一次进入应用
 * @return {*}
 */
export async function getAppGuideCompleted(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('api/v1/user/appGuideCompleted')
    return res.content
}

/**
 *
 * 咨询报价
 * @return {*}
 */
export async function getUserConsult(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('api/v1/user/consult')
    return res.success
}

/**
 * 获取API KEY
 * @return {*}
 */
export async function generateApiToken(spaceId: string): Promise<string> {
    const res = await http.get<unknown, HttpResp<string>>('/api/v1/user/generateApiToken', {
        params: { spaceId }
    })
    return res.content
}

/**
 * 获取优惠邀请人员及最高优惠用户数
 * @return {*}
 */
export async function getPromoInviteUsers(): Promise<PromoInviteResult> {
    const res = await http.get<unknown, HttpResp<PromoInviteResult>>('/api/v1/user/getInviteUsers')
    return res.content
}

/**
 * 获取邀请码是否有效
 * @return {*}
 */
export async function invitationCodeCheck(invitationCode: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('/api/auth/v1/user/invitationCodeCheck', {
        params: { invitationCode }
    })
    return res.content
}

/**
 * 用户完成任务
 * @return {*}
 */
export async function taskCompleted(userTask: string[]): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('/api/v1/user/taskCompleted', userTask, {
        headers: JsonContentType
    })
    return res.success
}
