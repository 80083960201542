import { Flex, IconFont, Input, tinyButtons } from '@byecode/ui'
import { Breadcrumbs } from '@byecode/ui/components/Breadcrumbs'
import { Divider } from '@byecode/ui/components/Divider'
import { Group } from '@byecode/ui/components/Group'
import type { FormRule } from '@lighthouse/core'
import { FilterMode } from '@lighthouse/core'
import {
    CollapseBox,
    getFieldBlockWithDsId,
    getTableIcon,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomData,
    useFindUseObjectContext
} from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { TriggerFilter } from '@/containers/FlowDetail/components/TriggerFilter'
import { TriggerSorter } from '@/containers/FlowDetail/components/TriggerSorter'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'


export interface RuleSettingProps {
    // data: FormRule
    prefix: `form.rules.${number}`
    onChange?: (data: FormRule) => void
    onGoBack: () => void
}

const SCxContainer = styled.div`
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: 3;
    padding-bottom: 100px;
    overflow-y: auto;
    ${tinyButtons()}
    background-color: #fff;
`

const SCxTitle = styled.div`
    line-height: 40px;
    font-size: var(--font-size-normal);
`
export const FormItemLabel = styled.span<{ required?: boolean }>`
    position: relative;
    ${({ required }) =>
        required &&
        css`
            &::after {
                content: '*';
                position: absolute;
                top: 0;
                right: -12px;
                color: var(--color-red-500);
            }
        `}
`

export const RuleSetting: React.FunctionComponent<RuleSettingProps> = ({ prefix, onChange, onGoBack }) => {
    const { control, watch, register, setValue } = useFormContext()
    const [title, pointer] = watch([`${prefix}.title`, `${prefix}.pointer`])

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const options = useMemo(() => dataSourceList.map(ds => ({ label: ds.name, value: ds.id, icon: getTableIcon(ds) })), [dataSourceList])

    return (
        <SCxContainer>
            <Breadcrumbs
                items={[
                    { icon: <IconFont type="ArrowLeftSmall" size={16} color="var(--color-gray-400)" />, label: title, onClick: onGoBack }
                ]}
            />
            <Divider color="var(--color-gray-200)" />
            <Group label="标题">
                <div style={{ margin: '4px 0' }}>
                    <Input autoFocus={false} autoSelect={false} {...register(`${prefix}.title`)} />
                </div>
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group label="查询数据">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <FormItemLabel required>数据表</FormItemLabel>
                    <Controller
                        name={`${prefix}.pointer`}
                        render={({ field }) => (
                            <SelectDataSource
                                dataSourceList={dataSourceList}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    // 不能设置undefined来置空，会出现无法置空的情况
                                    setValue(`${prefix}.filter`, {})
                                    setValue(`${prefix}.sort`, [])
                                    setValue(`${prefix}.checkFilter`, {})
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
                <SCxTitle>查询条件</SCxTitle>
                <TriggerFilter
                    // fieldMode={fieldMode}
                    dataSourceId={pointer}
                    paramsMode={FilterMode.CUSTOM}
                    prefix={prefix}
                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                    description="设置查询条件，查找满足条件的数据。如果未添加筛选条件则按照排序规则返回排序第一的记录"
                    style={{ padding: '0 0 8px 0' }}
                />
                <SCxTitle>排序规则</SCxTitle>
                <TriggerSorter
                    prefix={prefix}
                    dataSourceId={pointer}
                    description="当查找到多个记录时，将按照以下排序规则获得第一条记录。如果未设置规则，返回最近更新的一条记录"
                    style={{ padding: '0 0 8px 0' }}
                />
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group
                label={
                    <Flex>
                        <Text>校验查询到的数据</Text>
                        <Text span color="red" style={{ paddingLeft: 4 }}>
                            *
                        </Text>
                    </Flex>
                }
            >
                <TriggerFilter
                    // fieldMode={fieldMode}
                    filterName="checkFilter"
                    dataSourceId={pointer}
                    paramsMode={FilterMode.CUSTOM}
                    prefix={prefix}
                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                    description="设置条件以校验查询到的数据；当符合条件时，允许表单提交，反之，则禁止表单提交，并出现校验提示内容"
                    style={{ padding: '0 0 8px 0' }}
                />
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group
                label={
                    <Flex>
                        <Text>校验提示内容</Text>
                        <Text span color="red" style={{ paddingLeft: 4 }}>
                            *
                        </Text>
                    </Flex>
                }
            >
                <div style={{ margin: '4px 0' }}>
                    <Controller
                        name={`${prefix}.failureMessage`}
                        control={control}
                        render={({ field }) => <Input defaultValue={field.value} onBlur={e => field.onChange(e.target.value)} />}
                    />
                </div>
            </Group>
        </SCxContainer>
    )
}
