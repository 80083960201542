import type { CustomInputValueRenderProps, Option } from '@byecode/ui'
import { Breadcrumbs, Flex, Group, IconFont, MultiSelect, Select, Tooltip } from '@byecode/ui'
import type { DataSourceAbstract, ImportProtocol, ViewField } from '@lighthouse/core'
import {
    FieldSettingConfigure,
    FieldTypeTag,
    getFieldIcon,
    getIsAppointField,
    getPrimaryDataSourceEnableFieldIds,
    ListItem4ByecodeUi,
    NO_EDIT_SYSTEM_FIELD,
    notImportableFieldTypes
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'
import styled from 'styled-components'

import { InnerDrawer } from '../../InnerDrawer'
import { listCommonStyles } from './constants'
import * as SC from './styles'

const SCxRepeatText = styled.span`
    line-height: 32px;
`

const SCxPlaceholder = styled.span`
    color: var(--color-gray-400);
`

const SCxFieldContainer = styled.div`
    min-width: 100px;
    height: 32px;
    border: 1px solid transparent;
    padding: 0 8px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    &:focus {
        color: var(--color-gray-800);
        border-color: var(--color-main);
    }
`

const SCxRepeatLabel = styled.span`
    display: flex;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxRepeatIcon = styled(IconFont)`
    margin-left: 6px;
    color: var(--color-gray-400);
`

interface AllowImportConfiguratorProps {
    value: ImportProtocol
    columns: ViewField[]
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    onChange?: (v: ImportProtocol) => void
    onBack?: () => void
}
interface AllowImportSettingProps {
    title: string
    type: string
    value: ImportProtocol
    columns: ViewField[]
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    onChange?: (v: ImportProtocol) => void
}

const repeatModeOptions = [
    {
        label: '跳过',
        value: 'skip',
        describe: '根据“依据字段”的内容，如果在工作表中已经存在，则直接跳过这一行， 如果没有作为新数据新记录导入。'
    },
    {
        label: '覆盖',
        value: 'cover',
        describe: '将Excel的数据完全覆盖到工作表，如果识别到工作表中已经有了相同的记录，就更新这行记录，如果没有就作为新记录导入。'
    },
    {
        label: '仅更新，不新增记录',
        value: 'update',
        describe: '导入时只更新已有记录，即使Excel中有些行数据不在工作表，也不导入新的记录。'
    }
]

const RenderLabel: React.FC<CustomInputValueRenderProps> = ({ selectOptions, placeholder }) => {
    const { length } = selectOptions
    //
    return (
        <SCxFieldContainer tabIndex={0}>
            <Flex alignItems="center" justifyContent="space-between" gap="6px" style={{ height: '100%' }}>
                {length > 0 ? (
                    <SCxRepeatText>{length > 0 ? `已选择${length}个字段` : ''}</SCxRepeatText>
                ) : (
                    <SCxPlaceholder>{placeholder}</SCxPlaceholder>
                )}
                <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
            </Flex>
        </SCxFieldContainer>
    )
}

export const AllowImportConfigurator: FC<AllowImportConfiguratorProps> = ({
    value,
    columns,
    dataSource,
    dataSourceList,
    onChange,
    onBack
}) => {
    const { control } = useFormContext()
    const { canImport = false, triggerWorkflow = false, userImportSetting } = value
    const handleChange = useCallback(
        (params: Partial<ImportProtocol>) => {
            onChange?.({ ...value, ...params })
        },
        [onChange, value]
    )

    const primaryFieldIds = getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)

    const canImportColumns = useMemo(
        () =>
            columns.filter(
                field =>
                    !(
                        notImportableFieldTypes.has(field.type) ||
                        getIsAppointField(field.fieldId, NO_EDIT_SYSTEM_FIELD) ||
                        !primaryFieldIds.has(field.fieldId)
                    )
            ),
        [columns, primaryFieldIds]
    )

    const fieldOptions = useMemo(
        () =>
            canImportColumns.reduce<Option[]>((list, field) => {
                if (!field) {
                    return list
                }
                list.push({
                    label: field.title,
                    value: field.fieldId,
                    icon: getFieldIcon(field.fieldId, field.type, field.innerType),
                    extra: <FieldTypeTag type={field.type} innerType={field.innerType} />
                })
                return list
            }, []),
        [canImportColumns]
    )

    return (
        <InnerDrawer style={{ paddingBottom: 80 }}>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '批量导入', onClick: onBack }]} />
            <Divider color="var(--color-gray-200)" />
            <Group
                mode="switch"
                label="批量导入"
                opened={canImport}
                onCollapseChange={v => handleChange({ canImport: v })}
                collapseProps={{ style: { padding: 0 } }}
            >
                <FieldSettingConfigure
                    title="批量导入的字段"
                    columns={canImportColumns}
                    value={userImportSetting}
                    onChange={v => handleChange({ userImportSetting: v })}
                />
            </Group>
            {canImport && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Group
                        mode="switch"
                        label="导入可触发自动化"
                        opened={triggerWorkflow}
                        onCollapseChange={v =>
                            handleChange({
                                triggerWorkflow: v
                            })
                        }
                    >
                        <SC.ImportTriggerWorkflowTipWrapper>
                            注意！当启用该功能时，每次最多只能导入 1000
                            条记录；每一条记录触发的自动化，都将消耗相应的资源，当资源配额不足时，无法导入。
                        </SC.ImportTriggerWorkflowTipWrapper>
                    </Group>
                    <Divider color="var(--color-gray-200)" />
                    <Controller
                        name="repeat"
                        render={({ field }) => (
                            <Group mode="switch" label="识别重复记录" opened={Boolean(field.value)} onCollapseChange={field.onChange}>
                                <ListItem4ByecodeUi gap="8px" justifyContent="space-between" alignItems="center">
                                    <div>识别重复依据的字段</div>
                                    <Controller
                                        control={control}
                                        name="compareFields"
                                        render={({ field }) => (
                                            <MultiSelect
                                                dropdownWidth={220}
                                                position="bottom-start"
                                                styles={{ root: { width: 180 } }}
                                                disabledPortal
                                                placeholder="请选择"
                                                options={fieldOptions}
                                                value={field.value}
                                                customInputValueRender={RenderLabel}
                                                onChange={val => field.onChange(val)}
                                            />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                                <Controller name="importType" defaultValue="multiple" render={() => <div />} />
                                <ListItem4ByecodeUi gap="8px" justifyContent="space-between" alignItems="center">
                                    <div>当识别到重复记录时</div>
                                    <Controller
                                        control={control}
                                        name="importMode"
                                        render={({ field }) => (
                                            <Select
                                                style={{ width: 180 }}
                                                placeholder="请选择"
                                                disabledPortal
                                                options={repeatModeOptions}
                                                value={field.value}
                                                onChange={field.onChange}
                                                optionComponent={({ value, label, describe, ...rest }) => (
                                                    <SCxRepeatLabel {...rest}>
                                                        {label}
                                                        <Tooltip
                                                            withArrow
                                                            styles={{
                                                                tooltip: {
                                                                    maxWidth: '200px'
                                                                }
                                                            }}
                                                            title={describe}
                                                        >
                                                            <SCxRepeatIcon type="Question" />
                                                        </Tooltip>
                                                    </SCxRepeatLabel>
                                                )}
                                            />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                            </Group>
                        )}
                    />
                </>
            )}
        </InnerDrawer>
    )
}

export const AllowImportSetting: FC<AllowImportSettingProps> = ({
    title,
    type,
    value,
    columns,
    dataSource,
    dataSourceList,
    onChange,
    ...rest
}) => {
    const { canImport } = value
    const [open, toggle] = useToggle(false)
    return (
        <>
            <ListItem4ByecodeUi
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <SC.StyledIconWrapper active={canImport}>
                        <IconFont type={type} size={18} fill={canImport ? 'var(--color-main)' : 'var(--color-gray-400)'} />
                    </SC.StyledIconWrapper>
                    <div>{title}</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{canImport ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>
            {open && (
                <AllowImportConfigurator
                    value={value}
                    columns={columns}
                    onChange={onChange}
                    onBack={() => toggle(false)}
                    dataSource={dataSource}
                    dataSourceList={dataSourceList}
                />
            )}
        </>
    )
}
