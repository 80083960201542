import { Flex, flex, Text } from '@byecode/ui'
import { TooltipText } from '@lighthouse/shared'
import type { CSSProperties } from 'react'
import React from 'react'
import styled from 'styled-components'

import { RoundAvatar } from '@/components/RoundAvatar'

import * as CM from '../styles'
import { TempleLabels } from '../TempleLabels'
import type { Template, TemplateStyles } from '../types'

interface TemplateTitleProps {
    data?: Template
    iconSize?: number
    logoSize?: number
    titleSize?: number
    describeSize?: number
    disableTooltip?: boolean
    describeWrap?: CSSProperties['whiteSpace']
    gap?: number
    style?: React.CSSProperties
    styles?: TemplateStyles
}

const SCXFooter = styled.div`
    width: 100%;
    height: auto;
    ${flex}
    justify-content: space-between;
    overflow: hidden;
    /* flex-wrap: wrap; */
`

const SCXDescribeContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
`

const SCXDescribe = styled(Flex)`
    ${flex};
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
`

const TemplateTitle: React.FunctionComponent<TemplateTitleProps> = ({
    data,
    logoSize = 40,
    iconSize,
    describeSize,
    titleSize,
    describeWrap,
    style,
    disableTooltip,
    gap,
    styles
}) => {
    const { icon = '', name = '', describe = '', iconBackgroundColor, iconColor = '#fff', labels } = data ?? {}
    return (
        <SCXFooter style={{ ...styles?.root, ...style }}>
            {icon && (
                <RoundAvatar
                    bordered={false}
                    background={iconBackgroundColor}
                    size={logoSize}
                    iconSize={iconSize}
                    style={{
                        backgroundColor: iconBackgroundColor,
                        flexBasis: logoSize,
                        marginRight: 12,
                        WebkitFlexBasis: logoSize,
                        flexShrink: 0
                    }}
                    iconColor={iconColor}
                    type="icon"
                    radius="8px"
                    icon={icon}
                />
            )}
            <SCXDescribeContainer>
                <SCXDescribe gap={gap}>
                    <CM.Text size={titleSize} isOmit style={{ fontWeight: 600, ...styles?.title }}>
                        {name}
                    </CM.Text>
                    <TooltipText
                        title={describe}

                        disabled={disableTooltip}
                        render={ref => {
                            return (
                                <Text
                                    ref={ref}
                                    style={{ ...styles?.describe }}
                                    lineHeight="22px"
                                    whiteSpace={describeWrap}
                                    size={describeSize}
                                    color="var(--color-gray-400)"
                                >
                                    {describe}
                                </Text>
                            )
                        }}
                    />
                </SCXDescribe>
                <TempleLabels labels={labels} />
            </SCXDescribeContainer>
        </SCXFooter>
    )
}

export default React.memo(TemplateTitle)
