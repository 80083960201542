import type { DataSourceAbstract, FormContainerBlockConfig } from '@lighthouse/core'
import { ActionItemPattern, ALIGNMENT } from '@lighthouse/core'
import { generateJson, USER_DATASOURCE } from '@lighthouse/shared'

import { DEFAULT_CONTAINER_DESIGN } from '@/components/BlockSettings/constants'

import { getInitButton } from './button'
/**
 * 生成表单容器的配置
 *
 * @param {(undefined | DataSourceAbstract)} dataSource
 * @returns {FormContainerBlockConfig}
 */
export const generateFormContainerBlock = (dataSource: undefined | DataSourceAbstract): FormContainerBlockConfig => {
    return {
        pointer: dataSource  ?  dataSource.id : '',
        submitButton: {
            ...getInitButton(ActionItemPattern.primary),
            name: generateJson('提交'),
            pattern: ActionItemPattern.primary,
            showType: 'name',
            size: 'lg',
            radius: '8',
            align: ALIGNMENT.left
        },
        action: {
            type: 'notification',
            trigger: 'click',
            data: {
                notification: {
                    notificationStatus: 'SUCCESS',
                    notificationContent: {
                        type: 'doc',
                        content: [
                            {
                                type: 'paragraph',
                                content: [
                                    {
                                        type: 'text',
                                        text: '提交成功'
                                    }
                                ]
                            }
                        ]
                    }
                }
            }
        },
        rules: [],
        design: { ...DEFAULT_CONTAINER_DESIGN }
    }
}
