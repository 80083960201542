import type { ModalProps } from '@byecode/ui'
import { Button, Flex, Input, Text } from '@byecode/ui'
import type { ConformableInputProtocol, IconicProtocol, LoadingProtocol } from '@lighthouse/core'
import { TitleInContentModal, useAtomAction } from '@lighthouse/shared'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useAsyncAction } from '@/atoms/helper'
import { createWorkSpaceAtom, fetchWorkSpaceAtom, fetchWorkSpaceListAtom, setWorkSpaceIdAtom } from '@/atoms/workSpace/action'

interface SpaceCreatorModalProps extends ModalProps, LoadingProtocol {}

const SCxDescribe = styled(Text)``

const SCxContainer = styled.div`
    padding: 0 32px 32px 32px;
`

const SCxButton = styled(Button)`
    width: 100px;
`

export const SpaceCreatorModal: React.FunctionComponent<SpaceCreatorModalProps> = ({ onClose, ...rest }) => {
    const { run: createWorkSpace } = useAtomAction(createWorkSpaceAtom)
    const { run: setWorkSpaceId } = useAtomAction(setWorkSpaceIdAtom)
    const { run: fetchWorkSpaceList } = useAtomAction(fetchWorkSpaceListAtom)

    const [effect, doCreateWorkSpace] = useAsyncAction(createWorkSpace)

    const [name, setName] = useState('')

    return (
        <TitleInContentModal width={491} {...rest} onClose={onClose}>
            <SCxContainer>
                <SCxDescribe color="var(--color-gray-400)" size={14} lineHeight="20px" whiteSpace="pre-wrap">
                    创建空间后，您可以邀请其他人加入。你将默认成为空间管理员，并拥有全部管理权限。
                </SCxDescribe>
                <Input style={{ marginTop: 20 }} size="lg" placeholder="空间标题" value={name} onChange={e => setName(e.target.value)} />
                <Flex justifyContent="flex-end" style={{ marginTop: 32 }} gap={12}>
                    <SCxButton size="lg">取消</SCxButton>
                    <SCxButton
                        type="primary"
                        size="lg"
                        disabled={name === ''}
                        loading={effect.loading}
                        onClick={async () => {
                            const id = await doCreateWorkSpace({ name })
                            onClose?.()
                            setWorkSpaceId(id)
                            fetchWorkSpaceList()
                        }}
                    >
                        创建
                    </SCxButton>
                </Flex>
            </SCxContainer>
        </TitleInContentModal>
    )
}
