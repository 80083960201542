import { flex, IconFont, Input, Select, singleTextEllipsis } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { Field } from '@lighthouse/core'
import { ListItem4ByecodeUi, TagIcon } from '@lighthouse/shared'
import { Collapse, Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const SCxContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
    border: 1px solid var(--color-gray-200);
`

const SCxHeader = styled.div`
    ${flex}
    padding: 12px 8px;
    justify-content: space-between;
    background-color: var(--color-gray-100);
    gap: 8px;
`

const SCxHeaderLeft = styled.div`
    /* width: 126px; */
    ${flex}
    align-items: center;
    overflow: hidden;
    flex: 1;
`

const SCxHandle = styled.div`
    /* padding: 4px; */
    ${flex}
    align-items: center;
`

const SCxTitle = styled.span`
    padding-left: 4px;
    line-height: 16px;
    color: var(--color-black);
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
    font-size: var(--font-size-normal);
    flex: 1;
    ${singleTextEllipsis}
`

const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
`

const SCxListItemBaseInfo = styled.div`
    gap: 0;
    padding: 0 16px;
`

const SCxContent = styled.div`
    margin: 8px 0;
`

interface VariableItemProps {
    id: string
    opened: boolean
    prefixName?: string
    onChangeOpen: (opened: boolean) => void
    onDelete: (id: string) => void
}

const filterField = (field: Field) => (field.innerType === 'NUMBER' || field.innerType === 'TEXT') && field.type !== 'notes'

const innerTypeOptions = [
    {
        label: '数值',
        value: 'NUMBER'
    },
    {
        label: '文本',
        value: 'TEXT'
    },
    {
        label: '数组',
        value: 'ARRAY'
    },
    {
        label: '日期',
        value: 'DATE'
    },
    {
        label: '布尔',
        value: 'BOOL'
    }
]

export const VariableItem: React.FunctionComponent<VariableItemProps> = ({ id, opened, prefixName, onChangeOpen, onDelete }) => {
    const { control, watch } = useFormContext()

    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id
    })

    const name = watch(`${prefixName}.name`)

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: active?.id === id ? 1 : 0
        }),
        [active?.id, transform, transition, id]
    )

    return (
        <SCxContainer {...attributes} style={style} ref={setNodeRef}>
            <SCxHeader>
                <SCxHeaderLeft>
                    <SCxHandle {...listeners} style={{ cursor: 'grab' }}>
                        <IconFont size={16} type="DotsSix" color="var(--color-gray-500)" />
                    </SCxHandle>
                    <SCxTitle>{name}</SCxTitle>
                </SCxHeaderLeft>
                <SCxRightFill style={{ width: 'auto' }}>
                    <TagIcon icon="Trash" iconColor="var(--color-gray-500)" onClick={() => onDelete?.(id)} />
                    <IconFont
                        size={16}
                        color="var(--color-gray-500)"
                        type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                        onClick={() => onChangeOpen(!opened)}
                    />
                </SCxRightFill>
            </SCxHeader>
            <Collapse in={opened}>
                <SCxContent>
                    <SCxListItemBaseInfo>
                        <ListItem4ByecodeUi
                            alignItems="center"
                            justifyContent="space-between"
                            compact
                            styles={{
                                root: {
                                    gap: 0
                                }
                            }}
                        >
                            <Text>参数名称</Text>
                            <SCxRightFill>
                                <Controller
                                    control={control}
                                    name={`${prefixName}.name`}
                                    render={({ field }) => <Input style={{ width: 180 }} value={field.value} onChange={field.onChange} />}
                                />
                            </SCxRightFill>
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi
                            alignItems="center"
                            justifyContent="space-between"
                            compact
                            styles={{
                                root: {
                                    gap: 0
                                }
                            }}
                        >
                            <Text>参数类型</Text>
                            <SCxRightFill>
                                <Controller
                                    control={control}
                                    name={`${prefixName}.innerType`}
                                    render={({ field }) => (
                                        <Select
                                            value={field.value}
                                            style={{ width: 180 }}
                                            options={innerTypeOptions}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </SCxRightFill>
                        </ListItem4ByecodeUi>
                    </SCxListItemBaseInfo>
                </SCxContent>
            </Collapse>
        </SCxContainer>
    )
}
