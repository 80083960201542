import { Flex, Text, Tooltip } from '@byecode/ui'
import React from 'react'

import * as CM from '../styles'

interface SliderLabelProps {
    primaryColor?: string
    label?: string | React.ReactNode
    usageLabel?: string | React.ReactNode
    quotaLabel?: string | React.ReactNode
    toolTipLabel?: string | React.ReactNode
    extra?: React.ReactNode
}

export const SliderLabel: React.FunctionComponent<SliderLabelProps> = ({
    primaryColor = 'var(--color-gray-500)',
    label,
    usageLabel,
    quotaLabel,
    toolTipLabel,
    extra
}) => {
    return (
        <Flex justifyContent="space-between" style={{ height: 22, lineHeight: '22px' }}>
            <Flex gap={8}>
                <Text color="var(--color-gray-700)" size={14}>
                    {label}
                </Text>
                {toolTipLabel && (
                    <Tooltip title={toolTipLabel}>
                        <Flex>
                            <CM.Icon isAction type="Question" color="var(--color-gray-400)" />
                        </Flex>
                    </Tooltip>
                )}
            </Flex>
            <Flex gap={4} alignItems="center">
                <Text color={primaryColor} weight={600} size={12}>
                    {usageLabel}
                </Text>
                <Text color={primaryColor} size={12}>
                    /
                </Text>
                <Text color={primaryColor} size={12}>
                    {quotaLabel}
                </Text>
                {extra}
            </Flex>
        </Flex>
    )
}
