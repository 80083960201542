import { Tooltip } from '@byecode/ui'
import { ErrorMessage, spaceRoleOptions } from '@lighthouse/shared'
import { borderTopLeftRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius'
import React, { useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { getUserAvatar } from '@/services'

import type { InviteFormOption } from '.'
import * as SC from './styles'

export interface InviteDataItem {
    userId: string
    avatar?: string
    username?: string
    mobile: string
    roleId: string
}

interface InvitePhoneItemProps {
    value: InviteFormOption
    enableDelete?: boolean
    onChange: (val: InviteFormOption) => void
    onDelete: () => void
    onBlur: React.FocusEventHandler<HTMLInputElement>
    onFocus: React.FocusEventHandler<HTMLInputElement>
}

export const InvitePhoneItem: React.FC<InvitePhoneItemProps> = ({ value, enableDelete, onChange, onDelete, onBlur, onFocus }) => {
    const [state, setState] = useImmer<InviteDataItem>({
        ...value,
        mobile: ''
    })
    const { mobile, avatar, username, roleId, userId } = state

    const roleOptions = useMemo(() => spaceRoleOptions.map(item => ({ label: item.label, value: item.value })), [])

    const handleRoleChange = useCallback(
        (val: string) => {
            setState(draft => {
                draft.roleId = val
            })
        },
        [setState]
    )

    useUpdateEffect(() => {
        onChange({
            avatar,
            userId,
            roleId
        })
    }, [avatar, userId, roleId])

    const handleMobileChange = useCallback(
        async (val: string) => {
            const mobileVal = val.trim()
            if (mobileVal.length !== 11) {
                setState(draft => {
                    draft.mobile = mobileVal
                    draft.avatar = ''
                    draft.userId = ''
                    draft.username = undefined
                })
                return
            }
            setState(draft => {
                draft.avatar = 'loading'
                draft.userId = ''
                draft.mobile = mobileVal
            })
            const data = await getUserAvatar({ mobile: mobileVal })
            if (data) {
                const { username = '', userId } = data
                setState(draft => {
                    draft.username = username
                    draft.userId = userId
                })
                return
            }
            setState(draft => {
                draft.avatar = 'error'
                draft.userId = ''
            })
        },
        [setState]
    )

    // const errorText = () => {
    //     if (userId) {
    //         return
    //     }
    //     if (avatar === 'error') {
    //         return '不存在的用户'
    //     }
    //     return '请输入正确的手机号'
    // }

    const userIcon = useMemo(() => {
        if (username) {
            return (
                <Tooltip title={username} placement="top" withArrow>
                    <SC.UserItemAbbrText>{username.slice(0, 1)}</SC.UserItemAbbrText>
                </Tooltip>
            )
        }
        if (!avatar) {
            return <SC.UserIcon type="User" />
        }
        if (avatar === 'loading') {
            return <SC.UserIcon type="Spinner" />
        }
        if (avatar === 'error') {
            return (
                <Tooltip title="未注册账号" placement="top" withArrow>
                    <SC.UserIcon type="QuestionMark" />
                </Tooltip>
            )
        }

        return <SC.UserImg background={avatar} />
    }, [avatar, username])

    return (
        <SC.InviteBox>
            <SC.InviteItem>
                <SC.InviteAvatar>
                    <SC.UserIconBox>{userIcon}</SC.UserIconBox>
                </SC.InviteAvatar>
                <SC.InviteForm>
                    <SC.InvitePhone
                        value={mobile}
                        size="lg"
                        onBlur={onBlur}
                        onFocus={onFocus}
                        autoFocus
                        onChange={ev => {
                            // field.onChange(ev)
                            handleMobileChange(ev.target.value)
                        }}
                    />
                    <SC.InviteRole
                        value={roleId}
                        styles={{
                            root: {
                                width: 110,
                                borderRadius: '6px',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0
                            }
                        }}
                        disabledPortal
                        size="lg"
                        onChange={val => {
                            handleRoleChange(val)
                        }}
                        options={roleOptions}
                    />
                </SC.InviteForm>
            </SC.InviteItem>
            {enableDelete && (
                <SC.InviteClose onClick={onDelete}>
                    <SC.InviteIcon type="Close" />
                </SC.InviteClose>
            )}
        </SC.InviteBox>
    )
}
