import { Loading } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import {
    AppThemeContextProvider,
    NoFound,
    PageNoAccess,
    useAtomAction,
    useAtomAsyncAction,
    useAtomData,
    VIEW_CELL_DATA_KEY
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useAsyncRetry } from 'react-use'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { addRecordAtom } from '@/atoms/dataSource/action'
import { fetchPageAtom } from '@/atoms/page/action'
import { PageContainer } from '@/containers/PageDetail/PageContainer'
import { useRootPageContext } from '@/contexts/PageContext'
import { useUserPageList } from '@/hooks/useUserPageList'
import * as srv from '@/services'
import { activeRecordId, clearActiveRecordId } from '@/utils/web'

interface NestedPageWrapperProps {
    stackId: string
    pageId: string
    appId?: string
    envId?: string
    dsId?: string
    viewId?: string
    recordId?: string
}

export const NestedPageWrapper: React.FC<NestedPageWrapperProps> = ({ stackId, pageId, appId, envId, dsId, viewId, recordId }) => {
    const { run: addRecord } = useAtomAction(addRecordAtom)
    const theme = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.theme, [])
    )

    const { visitPageList } = useUserPageList()
    const isVisiblePage = useMemo(() => find(item => item.id === pageId, visitPageList), [pageId, visitPageList])

    // const viewRecords = useViewRecords(viewId ?? '')
    // const viewRecordIds = useMemo(() => (viewRecords || []).map(r => r.id), [viewRecords])
    // const viewMainRecordIds = useMemo(() => (viewRecords || []).map(r => getMainTableRecordId(r.id)), [viewRecords])
    // const pageRes = useAsyncRetry(async () => {
    //     const res = await srv.getPageContent({ params: { id: pageId } })
    //     if (!res) {
    //         return
    //     }
    //     const { blocks, nodes, ...page } = res
    //     setPage(draft => applyDraftPayload(draft, page))
    //     setPageNodes(draft => void (draft[pageId] = nodes))
    //     setPageBlocks(draft => void (draft[pageId] = blocks))

    //     return res
    // }, [pageId])

    const { value: pageRes, run: fetchPage, loading } = useAtomAsyncAction(fetchPageAtom)

    const { rootPageId } = useRootPageContext()
    useEffect(() => {
        fetchPage({ pageId, rootPageId, stackId })
    }, [fetchPage, pageId, rootPageId, stackId])

    const recordRes = useAsyncRetry(async () => {
        if (appId && envId && dsId && recordId) {
            const { datasource, record } = await srv.getRecord({ appId, envId, dsId, recordId })
            addRecord({ records: [record] })
            return { dataSource: datasource, record }
        }

        return null
    }, [pageId, appId, dsId, recordId])

    useEffect(() => {
        if (!viewId || !recordId) {
            return
        }
        clearActiveRecordId(VIEW_CELL_DATA_KEY)
        activeRecordId(viewId, recordId, VIEW_CELL_DATA_KEY)
        return () => {
            clearActiveRecordId(VIEW_CELL_DATA_KEY)
        }
    }, [viewId, recordId])

    const refreshPageManually = useCallback(() => {
        fetchPage({ pageId, rootPageId: pageId, stackId })
        recordRes.retry()
    }, [fetchPage, pageId, recordRes, stackId])

    if (loading || recordRes.loading) {
        return <Loading />
    }

    if (!pageRes) {
        return <NoFound image={getAssetUrl('empty', 'no_page.svg')} title="" desc="找不到页面，页面已被删除" btnText="" />
    }

    if (!isVisiblePage) {
        return <PageNoAccess />
    }

    return (
        <AppThemeContextProvider theme={theme}>
            <PageContainer pageId={pageId} stackId={stackId} refreshPageManually={refreshPageManually} />
        </AppThemeContextProvider>
    )
}
