import {
    type AiFieldStatus,
    type DataSourceAbstract,
    type FieldADTValue,
    type Pagination,
    type RecordLikeProtocol,
    type TableColumns,
    DataSourceType
} from '@lighthouse/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import type { atomWithImmer } from 'jotai-immer'
import { multiply } from 'rambda'
import React, { startTransition, useEffect, useMemo, useRef } from 'react'
import { useLatest, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { activeFieldAffectCell, selectionState } from '../../utils'
import type { TablePopoverState, TableTooltipState } from './TableContent'
import TableRecord from './TableRecord'

interface TableBodyProps {
    dataSourceInfo: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    recordPoolAtom: ReturnType<typeof atomWithImmer<RecordLikeProtocol[]>>
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords?: string[]
    tableProps: TableColumns
    scrollElement: HTMLDivElement | null
    pagination: Pagination
    primaryDataSourceFieldIds?: Set<string>
    disableEdit?: boolean
    disableSelect?: boolean
    disableCreateRecord?: boolean
    onRecordSelect?: (recordIds: string[]) => void
    onOpenEditor?: (data: TablePopoverState) => void
    onOpenTooltip?: (data: TableTooltipState) => void
    onLoadMoreData?: (pagination: Pagination, search?: string) => Promise<RecordLikeProtocol[]>
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
}

const SCxTableContainer = styled.div``

const SCxTableBody = styled.div`
    display: flex;
    width: max-content;
    min-width: 100%;
    border-bottom: 1px solid var(--color-gray-200);
    border-radius: 8px;
    background: var(--color-white);

    &:last-child {
        border-bottom: none;
    }
`

const TableBody: React.FC<TableBodyProps> = ({
    dataSourceInfo,
    dataSourceList,
    recordPoolAtom,
    aiFieldStatusListAtom,
    selectedRecords,
    // schema,
    tableProps,
    scrollElement,
    pagination,
    primaryDataSourceFieldIds,
    disableEdit,
    disableSelect,
    disableCreateRecord,
    onRecordSelect,
    onOpenEditor,
    onOpenTooltip,
    onLoadMoreData,
    onCellChange,
    onAiGeneration
}) => {
    const { records, type, schema } = dataSourceInfo
    const enableAddRecord = type !== DataSourceType.joinDataSource
    // const [loading, setLoading] = useState(false)
    const loadingRef = useRef(false)
    const { currentPage = 1, pageSize, rowTotal = pageSize ? pageSize + 1 : 0 } = pagination || ({} as Pagination)
    const currentPageRef = useLatest(currentPage)
    const visibleTableProps = useMemo(() => tableProps.filter(item => item.visible && schema[item.id]), [schema, tableProps])
    const rowVirtualizer = useVirtualizer({
        count: records?.length ?? 0,
        scrollPaddingStart: enableAddRecord && !disableCreateRecord ? 77 : 37,
        getScrollElement: () => scrollElement,
        estimateSize: () => 40,
        getItemKey: (index: number) => records[index],
        overscan: 30
    })
    const maxWidth = useMemo(() => {
        return visibleTableProps.reduce((prev, cur) => {
            return prev + (cur.width || 160)
        }, 60)
    }, [visibleTableProps])

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: visibleTableProps.length,
        getScrollElement: () => scrollElement,
        paddingStart: 60,
        estimateSize: (index: number) => visibleTableProps[index].width || 160,
        overscan: 14
    })

    // useMounted(() => {
    //     setScrollElement(scrollRef.current)
    // })

    useUpdateEffect(() => {
        startTransition(() => {
            columnVirtualizer.measure()
        })
    }, [visibleTableProps])

    useUpdateEffect(() => {
        void (async () => {
            if (!rowTotal || loadingRef.current || !rowVirtualizer.range) {
                return
            }
            const { endIndex } = rowVirtualizer.range
            const checkedId = selectionState.state.fieldCheckId
            activeFieldAffectCell(scrollElement, checkedId, 'data-field', 'data-cell-field')
            const currentCount = multiply(currentPageRef.current, 100)
            // tableFieldSelection.onSelectField(scrollRef.current, id, tableFieldSelection.fieldId)
            if (endIndex >= currentCount - 10 && rowTotal > currentCount && onLoadMoreData) {
                loadingRef.current = true
                const recordsData = await onLoadMoreData({ currentPage: currentPageRef.current + 1, pageSize: 100 })
                if (recordsData.length > 0) {
                    // eslint-disable-next-line require-atomic-updates
                    loadingRef.current = false
                }
            }
        })()
    }, [currentPage, onLoadMoreData, rowVirtualizer.range])

    useUpdateEffect(() => {
        rowVirtualizer.measure()
    }, [rowTotal])

    return (
        <SCxTableContainer
            style={{
                width: `${maxWidth}px`,
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: 'relative'
            }}
        >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
                const { index } = virtualRow
                const id = records[index]
                // const { id, content } = item
                return (
                    <TableRecord
                        key={id}
                        index={index}
                        id={id}
                        recordPoolAtom={recordPoolAtom}
                        aiFieldStatusListAtom={aiFieldStatusListAtom}
                        dataSourceInfo={dataSourceInfo}
                        dataSourceList={dataSourceList}
                        disableEdit={disableEdit}
                        // content={content}
                        selectedRecords={selectedRecords}
                        tableProps={visibleTableProps}
                        virtualRow={virtualRow}
                        columnVirtualizer={columnVirtualizer}
                        primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                        disableSelect={disableSelect}
                        onRecordSelect={onRecordSelect}
                        onOpenEditor={onOpenEditor}
                        onOpenTooltip={onOpenTooltip}
                        onCellChange={onCellChange}
                        onAiGeneration={onAiGeneration}
                    />
                )
            })}
        </SCxTableContainer>
    )
}

export default TableBody
