import {
    ApplicationProvider,
    AuthenticationProvider,
    CollapseManager,
    DomainProvider,
    getDepartmentListWithPath,
    LanguageProvider,
    PAGE_CONTAINER_HOST,
    useAtomData
} from '@lighthouse/shared'
import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import useSWR from 'swr'

import { AppPublishStateAtom, websiteApplicationSettingAtom } from '@/atoms/application/state'
import { ErrorFallback } from '@/components/ErrorFallback'
import { useAppLanguageList, useLanguage } from '@/hooks/useApplication'
import { useDataSourceDepartments, useDataSourceRoles, useDataSourceUsers } from '@/hooks/useDataSource'
import { checkVersionUpdated, getVideoPlayAuth } from '@/services'

import ApplicationHeader from './ApplicationHeader'
import { PublishProgress } from './ApplicationHeader/Actions/PublishProgress'
import { ApplicationMessage } from './ApplicationMessage'
import * as SC from './styles'

interface ApplicationContainerProps {
    id: string
    children?: React.ReactNode
}

/** 轮询应用是否有更新 */
const useSubscriptApplicationUpdate = () => {
    return useSWR('query-app-has-update', () => checkVersionUpdated(), { refreshInterval: 3000 })
}

export const ApplicationContainer: React.FC<ApplicationContainerProps> = ({ id, children }) => {
    const appSetting = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => ({ navbar: s?.navbar, domain: s?.domain, authentication: s?.authentication }), [])
    )
    const { navbar, domain, authentication } = appSetting
    const users = useDataSourceUsers()
    const roles = useDataSourceRoles()
    const departments = useDataSourceDepartments()
    const language = useLanguage()
    const languageList = useAppLanguageList()

    const hasUpdateSwrRes = useSubscriptApplicationUpdate()

    const departmentsWithPathLabel = useMemo(() => getDepartmentListWithPath(departments), [departments])

    const applicationValue = useMemo(
        () => ({
            appId: id,
            personOptions: users,
            roleOptions: roles,
            departmentOptions: departmentsWithPathLabel,
            isRealityMobile: false,
            isBuilder: true,
            pageTarget: PAGE_CONTAINER_HOST,
            fetchVideoPlayAuth: getVideoPlayAuth,
            hasUpdate: hasUpdateSwrRes.data,
            mutateHasUpdate: hasUpdateSwrRes.mutate
        }),
        [departmentsWithPathLabel, hasUpdateSwrRes.data, hasUpdateSwrRes.mutate, id, roles, users]
    )

    const languageValue = useMemo(
        () => ({
            language,
            languageList
        }),
        [language, languageList]
    )

    useEffect(() => {
        const list = Object.keys(navbar?.linkList?.list ?? {})
        list.forEach(id => {
            const isExit = CollapseManager.getItemCollapse(id)
            isExit === null ? CollapseManager.setItemCollapse(id, false) : CollapseManager.clearItemCollapse(id)
        })
    }, [navbar?.linkList?.list])

    const [appPublishState, setAppPublishState] = useAtom(AppPublishStateAtom)

    if (!navbar || !domain || !authentication) {
        return null
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ApplicationMessage appId={id} />
            <ApplicationProvider value={applicationValue}>
                <LanguageProvider value={languageValue}>
                    <DomainProvider value={domain}>
                        <AuthenticationProvider value={authentication}>
                            <SC.PageContainer id="appContainer">
                                <ApplicationHeader appId={id} />
                                <SC.PageContent>{children}</SC.PageContent>
                                {/* {!isDataSource && <ApplicationFooter />} */}
                            </SC.PageContainer>
                        </AuthenticationProvider>
                    </DomainProvider>
                </LanguageProvider>

                {/* 应用发布/更新的弹窗 */}
                <PublishProgress
                    key={~~appPublishState.isSuccess}
                    isPublish={appPublishState.isPublish}
                    // inProgress={appPublishState.isProgress}
                    isComplete={appPublishState.isSuccess}
                    // onProgressEnd={() =>
                    //     setAppPublishState(draft => {
                    //         draft.isProgress = false
                    //         draft.isSuccess = true
                    //     })
                    // }
                    onClose={() => {
                        setAppPublishState({
                            isPublish: false,
                            // isProgress: false,
                            isSuccess: false
                        })
                    }}
                />
            </ApplicationProvider>
        </ErrorBoundary>
    )
}
