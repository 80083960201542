import { useCustomViewBlockContext } from '@lighthouse/block'
import { useFormModuleContext } from '@lighthouse/shared'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSource, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'

export interface IUsePageCurrentDsRecord {
    pageId: string
    stackId: string
}

export interface IUsePageCurrentDsAndRecord {
    fieldPointer?: string
    required?: boolean
}

export const usePageCurrentDsRecord = ({ pageId, stackId }: IUsePageCurrentDsRecord) => {
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const pageDepsDs = curr.datasource ?? prev.datasource
    const pageDepsRecordId = curr.recordId ?? prev.recordId

    const currentPageRecord = useRecord(appId, envId, pageDepsDs?.id || '', pageDepsRecordId ?? '')

    return {
        dataSource: pageDepsDs,
        record: currentPageRecord
    }
}

export const useParentRecord = () => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const { dataSource: currentPageDataSource, record: currentPageRecord } = usePageCurrentDsRecord({ pageId, stackId })

    const { record: viewRecord, pointer: viewPointer } = useCustomViewBlockContext()

    const { record: formRecord } = useFormModuleContext()

    const record = formRecord ?? viewRecord ?? currentPageRecord

    const viewDataSource = useDataSource(appId, envId, viewPointer)

    const dataSource = viewDataSource ?? currentPageDataSource

    const [initRecord, setInitRecord] = useState(currentPageRecord)

    useUpdateEffect(() => {
        setInitRecord(v => {
            if (!v) {
                return record
            }
            return v
        })
    }, [record])

    return {
        dataSource,
        record,
        initRecord
    }
}
