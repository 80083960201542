import { Empty, IconFont, Input, useContextPopoverHeight, usePopoverContext } from '@byecode/ui'
import { min } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import SpaceItem from '../SpaceItem'
// import spaceState from '@/lib/space'
import * as SC from './styles'
import type { Action, MenuProps } from './types'

export const SpaceMenu: React.FC<MenuProps> = ({ space, workspaces, onCreateSpace, onChangeSpace }) => {
    const { context } = usePopoverContext()
    const [search, setSearch] = useState('')
    const [maxHeight, internalRef] = useContextPopoverHeight({ context, offset: 16 })
    const handle = useMemo(
        () => ({
            CREATE_BLOCK() {
                onCreateSpace(true)
            }
        }),
        [onCreateSpace]
    )

    const handleSearchChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(ev.target.value)
    }, [])

    const handleSettingClick = useCallback(
        (action: Action) => {
            handle[action]()
        },
        [handle]
    )

    const handleSpaceItemClick = useCallback(
        (id: string) => {
            onChangeSpace(id)
        },
        [onChangeSpace]
    )

    const list = useMemo(
        () => workspaces.filter(item => item.id !== space.id && (!search || item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))),
        [search, space.id, workspaces]
    )

    return (
        <SC.MenuList ref={internalRef} maxHeight={min(600, maxHeight || 600)}>
            <SC.Header>
                <Input
                    size="md"
                    placeholder="搜索"
                    value={search}
                    onChange={handleSearchChange}
                    prefix={<IconFont size={16} type="Search" fill="var(--color-gray-400)" />}
                />
            </SC.Header>
            <SC.SpaceList>
                {list.length === 0 && (
                    <Empty description="没有找到空间" py={25} icon={<IconFont size={20} type="Planet" fill="var(--color-gray-400)" />} />
                )}
                {list.map(space => (
                    <SpaceItem data={space} key={space.id} onClick={handleSpaceItemClick} />
                ))}
            </SC.SpaceList>
            <SC.ListFooter>
                <SC.StyledDivider />
                <SC.ListFooterItem
                    onClick={() => {
                        handleSettingClick('CREATE_BLOCK')
                    }}
                >
                    <SC.ItemIcon type="Add" />
                    <SC.ListItemInfo>
                        <SC.ListItemText>创建新的空间</SC.ListItemText>
                    </SC.ListItemInfo>
                </SC.ListFooterItem>
            </SC.ListFooter>
        </SC.MenuList>
    )
}
