import { Flex, IconFont, numberFontFamily, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React from 'react'
import styled, { css } from 'styled-components'

const StyledList = styled.ul`
    list-style: none;
    padding: 0 56px;
`

const recommendBackgroundDecorate = css`
    background: var(--color-white) url(${getAssetUrl('space', 'CartIllustartion.svg')}) no-repeat;
    background-position: 226px 26px;

    &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 282px;
        height: 282px;
        transform: translate(-141px, 183px);
        background: radial-gradient(46.01% 46.01% at 50% 50%, rgba(162, 89, 255, 0.4) 0%, rgba(162, 89, 255, 0) 100%);
    }
    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 226px;
        height: 226px;
        transform: translate(156px, -141px);
        background: radial-gradient(50% 50% at 50% 50%, rgba(10, 207, 131, 0.29) 0%, rgba(10, 207, 131, 0) 100%);
    }
`
const StyledItem = styled.li<{ recommend?: boolean }>`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    & + & {
        margin-top: 16px;
    }

    &:hover {
        border-color: var(--color-main);
    }

    ${p => p.recommend && recommendBackgroundDecorate}
`

const StyledPrice = styled.span`
    color: var(--color-main);
    ${numberFontFamily};
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    &::before {
        content: '￥';
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
`
const StyledButton = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    width: 114px;
    height: 48px;
    text-align: center;
    border-radius: 12px;
    color: var(--color-black);
    background-color: var(--color-gray-100);
    padding: 12px;
    font-size: 20px;
    font-weight: 500;
    /* line-height: 28px; */
`
const StyledRecommendTag = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    width: 68px;
    padding: 2px 12px;
    color: var(--color-white);
    background-color: var(--color-main);
    border-radius: 0 0 12px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
`
const StyledDiscountButton = styled(StyledButton)`
    position: relative;
    padding: 12px 10px;
    color: var(--color-white);
    background: linear-gradient(99deg, #b060ff -7.29%, #3631ff 98.45%);
`
const StyledDiscountIcon = styled(IconFont)`
    position: absolute;
    top: -22px;
    right: -13px;
`

const StyledNumberText = styled.span`
    ${numberFontFamily};
    font-weight: bold;
`

/** 短信增购包 */
export const SmsPurchase = ({ onWechatPaySms }: { onWechatPaySms: (order: 'SMS_1000' | 'SMS_5000' | 'SMS_15000') => void }) => {
    return (
        <StyledList>
            <StyledItem>
                <Text size={24} lineHeight="32px">
                    <StyledNumberText>1000</StyledNumberText> 条验证码短信
                </Text>
                <Flex alignItems="center" gap={12}>
                    <StyledPrice>75</StyledPrice>
                    <StyledButton onClick={() => onWechatPaySms('SMS_1000')}>购买</StyledButton>
                </Flex>
            </StyledItem>
            <StyledItem>
                <Text size={24} lineHeight="32px">
                    <StyledNumberText>5000</StyledNumberText> 条验证码短信
                </Text>
                <Flex alignItems="center" gap={12}>
                    <StyledPrice>375</StyledPrice>
                    <StyledButton onClick={() => onWechatPaySms('SMS_5000')}>购买</StyledButton>
                </Flex>
            </StyledItem>
            <StyledItem recommend>
                <Text size={24} lineHeight="32px">
                    <StyledNumberText>15,000</StyledNumberText> 条验证码短信
                </Text>
                <Flex alignItems="center" gap={12}>
                    <StyledRecommendTag>推荐</StyledRecommendTag>
                    <StyledPrice>1000</StyledPrice>
                    <StyledDiscountButton onClick={() => onWechatPaySms('SMS_15000')}>
                        <StyledNumberText>8.9</StyledNumberText>折购买
                        <StyledDiscountIcon type="Discount" size={41} />
                    </StyledDiscountButton>
                </Flex>
            </StyledItem>
        </StyledList>
    )
}

/** 流量增购包 */
export const TrafficPurchase = ({ onWechatPayTraffic }: { onWechatPayTraffic: (order: 'GB_1000' ) => void }) => {
    return (
        <StyledList>
            <StyledItem>
                <Text size={24} lineHeight="32px">
                    <StyledNumberText>1000 </StyledNumberText> GB/年
                </Text>
                <Flex alignItems="center" gap={12}>
                    <StyledPrice>500</StyledPrice>
                    <StyledButton onClick={() => onWechatPayTraffic('GB_1000')}>购买</StyledButton>
                </Flex>
            </StyledItem>
            {/* <StyledItem>
                <Text size={24} lineHeight="32px">
                    <StyledNumberText>10,000</StyledNumberText> G
                </Text>
                <Flex alignItems="center" gap={12}>
                    <StyledPrice>375</StyledPrice>
                    <StyledButton onClick={() => onWechatPayTraffic('TRAFFIC_5000')}>购买</StyledButton>
                </Flex>
            </StyledItem>
            <StyledItem recommend>
                <Text size={24} lineHeight="32px">
                    <StyledNumberText>15,000</StyledNumberText> G
                </Text>
                <Flex alignItems="center" gap={12}>
                    <StyledRecommendTag>推荐</StyledRecommendTag>
                    <StyledPrice>1000</StyledPrice>
                    <StyledDiscountButton onClick={() => onWechatPayTraffic('TRAFFIC_15000')}>
                        <StyledNumberText>8.9</StyledNumberText>折购买
                        <StyledDiscountIcon type="Discount" size={41} />
                    </StyledDiscountButton>
                </Flex>
            </StyledItem> */}
        </StyledList>
    )
}
