import type { Field, RecordLikeProtocol } from '@lighthouse/core'
import isDeepEqual from 'fast-deep-equal'
import React, { memo } from 'react'
import styled from 'styled-components'

import { useApplicationContext, useDataSourceContext } from '../../contexts'
import FieldValuePreviewer from '../FieldValuePreviewer'

export interface SimpleTableCellProps {
    field: Field
    record: RecordLikeProtocol
    width?: number
    style?: React.CSSProperties
    isLast: boolean
}

const SCxWindowShape = styled.div<{ isLast?: boolean }>`
    min-height: 36px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    border-right: 1px solid var(--color-gray-200);

    &:hover {
        z-index: 2;
        background: var(--color-white);
        &:before {
            content: '';
            position: absolute;
            width: calc(100%);
            height: ${({ isLast }) => `calc(100% - ${isLast ? 0 : 1}px)`};
            left: 0;
            top: 0;
            border: 2px solid var(--color-theme-4);
            background-color: transparent;
            /* z-index: 1; */
        }
    }
`

const SCxWindowShapeContent = styled.div<{ background?: string }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({ background }) => background || 'transparent'};
`

const SimpleTableCell: React.FC<SimpleTableCellProps> = ({ field, record, width, style, isLast }) => {
    const data = record.content[field.id]?.value

    return (
        <SCxWindowShape style={style} isLast={isLast}>
            <SCxWindowShapeContent>
                <FieldValuePreviewer data={data} field={field} width={width} />
            </SCxWindowShapeContent>
        </SCxWindowShape>
    )
}

export default memo(SimpleTableCell, isDeepEqual)
