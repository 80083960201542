import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { ColorAlphaBarCanvas } from './ColorAlphaBarCanvas'
import { ColorBoardCanvas } from './ColorBoardCanvas'
import { ColorHueBarCanvas } from './ColorHueBarCanvas'
import { COLOR_BOARD_WIDTH, SLIDER_WIDTH } from './constants'
import * as SC from './styles'
import type { HsvaColor } from './utils'
import {
    convertHsvaColorToAOffset,
    convertHsvaColorToHOffset,
    convertHsvaColorToSOffset,
    convertHsvaColorToVOffset,
    getHsvColorA,
    getHsvColorH,
    getHsvColorS,
    getHsvColorV,
    hexToHsva,
    hsvaToHex,
    hsvaToHslaString,
    hsvaToRgba,
    rgbaToHsva
} from './utils'

export interface ColorBoardProps {
    value?: string
    onChange?: (color: string) => void
}

export const ColorBoard: React.FC<ColorBoardProps> = ({ value, onChange }) => {
    const [hsvaOffset, setHsvaOffset] = React.useState({ h: 0, s: COLOR_BOARD_WIDTH, v: 0, a: SLIDER_WIDTH })

    const [focused, setFocused] = useState<null | 'h' | 'r' | 'g' | 'b' | 'a'>()

    const { h, s, v, a } = hsvaOffset

    const handleChange = useCallback(
        ({ h: ch, s: cs, v: cv, a: ca }: Partial<HsvaColor>) => {
            const hh = getHsvColorH(ch ?? h)
            const ss = getHsvColorS(cs ?? s)
            const vv = getHsvColorV(cv ?? v)
            const aa = getHsvColorA(ca ?? a)

            onChange?.(hsvaToHex({ h: hh, s: ss, v: vv, a: aa }))
        },
        [a, h, onChange, s, v]
    )

    const handleHueChange = useCallback(
        (x: number, confirmed?: boolean) => {
            setHsvaOffset(hslaOffset => {
                const newHsva = { ...hslaOffset, h: x, s: COLOR_BOARD_WIDTH, v: 0 }
                if (confirmed) {
                    handleChange(newHsva)
                }

                return newHsva
            })
        },
        [handleChange]
    )

    const handleAlphaChange = useCallback(
        (x: number, confirmed?: boolean) => {
            setHsvaOffset(hsvaOffset => {
                const newHsva = { ...hsvaOffset, a: x }
                if (confirmed) {
                    handleChange(newHsva)
                }

                return newHsva
            })
        },
        [handleChange]
    )

    const handleColorChange = useCallback(
        (x: number, y: number, confirmed?: boolean) => {
            setHsvaOffset(hslaOffset => {
                const newHsva = { ...hslaOffset, s: x, v: y }
                confirmed && handleChange(newHsva)

                return newHsva
            })
        },
        [handleChange]
    )

    const hexColor = useMemo(() => {
        const hh = getHsvColorH(h)
        const ss = getHsvColorS(s)
        const vv = getHsvColorV(v)
        return hsvaToHex({ h: hh, s: ss, v: vv, a: a / SLIDER_WIDTH })
    }, [a, h, s, v])

    const rgbaColorMap = useMemo(() => {
        const hh = getHsvColorH(h)
        const ss = getHsvColorS(s)
        const vv = getHsvColorV(v)
        return hsvaToRgba({ h: hh, s: ss, v: vv, a: a / SLIDER_WIDTH })
    }, [a, h, s, v])

    const hexColorValue = useMemo(() => {
        return hexColor.slice(1)
    }, [hexColor])

    const handleHexColorInput = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = ev.target
            if (value.length === 6) {
                const { h, s, v } = hexToHsva(`#${value}`)

                const hOffset = convertHsvaColorToHOffset(h)
                const sOffset = convertHsvaColorToSOffset(s)
                const vOffset = convertHsvaColorToVOffset(v)

                const newHsvaOffset = { h: hOffset, s: sOffset, v: vOffset, a: SLIDER_WIDTH }
                setHsvaOffset(newHsvaOffset)
                handleChange(newHsvaOffset)
            }
        },
        [handleChange]
    )

    const handleRgbaColorInput = useCallback(
        (partial: 'r' | 'g' | 'b' | 'a', value: string) => {
            const rgba = hsvaToRgba({ h: getHsvColorH(h), s: getHsvColorS(s), v: getHsvColorV(v), a: getHsvColorA(a) })
            rgba[partial] = Number(value)

            const { h: hh, s: ss, v: vv, a: aa } = rgbaToHsva(rgba)

            const hOffset = convertHsvaColorToHOffset(hh)
            const sOffset = convertHsvaColorToSOffset(ss)
            const vOffset = convertHsvaColorToVOffset(vv)
            const aOffset = convertHsvaColorToAOffset(aa)

            const newHsvaOffset = { h: hOffset, s: sOffset, v: vOffset, a: aOffset }
            setHsvaOffset(newHsvaOffset)
            handleChange(newHsvaOffset)
        },
        [a, h, handleChange, s, v]
    )

    useLayoutEffect(() => {
        if (value) {
            const { h, s, v, a } = hexToHsva(value)
            const hOffset = convertHsvaColorToHOffset(h)
            const sOffset = convertHsvaColorToSOffset(s)
            const vOffset = convertHsvaColorToVOffset(v)
            const aOffset = convertHsvaColorToAOffset(a)
            const newHsvaOffset = { h: hOffset, s: sOffset, v: vOffset, a: aOffset }

            setHsvaOffset(newHsvaOffset)
        }
    }, [value])

    return (
        <SC.ColorPickerColorBoardWrapper>
            <SC.ColorPickerColorBoardContent>
                <ColorBoardCanvas h={h} s={s} v={v} onColorChange={handleColorChange} />
                <SC.ColorPickerSliderWrapper>
                    <SC.ColorPickerColorPreviewer style={{ backgroundColor: hexColor }} />
                    <SC.ColorPickerSliderContent>
                        <ColorHueBarCanvas h={h} onHueChange={handleHueChange} />
                        <ColorAlphaBarCanvas h={h} s={s} v={v} a={a} onAlphaChange={handleAlphaChange} />
                    </SC.ColorPickerSliderContent>
                </SC.ColorPickerSliderWrapper>
                <SC.ColorPickerColorInputWrapper>
                    <SC.ColorPickerHexInputWrapper>
                        <SC.ColorPickerHexInput
                            value={focused === 'h' ? undefined : hexColorValue}
                            onFocus={() => setFocused('h')}
                            onBlur={() => setFocused(null)}
                            onChange={handleHexColorInput}
                        />
                        HEX
                    </SC.ColorPickerHexInputWrapper>
                    <SC.ColorPickerRgbaInputWrapper>
                        <SC.ColorPickerRgbaInputGroup>
                            <SC.ColorPickerRgbaInputItem>
                                <SC.ColorPickerRgbaInput
                                    value={focused === 'r' ? undefined : rgbaColorMap.r}
                                    onFocus={() => setFocused('r')}
                                    onBlur={() => setFocused(null)}
                                    onChange={ev => handleRgbaColorInput('r', ev.target.value)}
                                />
                                R
                            </SC.ColorPickerRgbaInputItem>
                            <SC.ColorPickerRgbaInputItem>
                                <SC.ColorPickerRgbaInput
                                    value={focused === 'g' ? undefined : rgbaColorMap.g}
                                    onFocus={() => setFocused('g')}
                                    onBlur={() => setFocused(null)}
                                    onChange={ev => handleRgbaColorInput('g', ev.target.value)}
                                />
                                G
                            </SC.ColorPickerRgbaInputItem>
                            <SC.ColorPickerRgbaInputItem>
                                <SC.ColorPickerRgbaInput
                                    value={focused === 'b' ? undefined : rgbaColorMap.b}
                                    onFocus={() => setFocused('b')}
                                    onBlur={() => setFocused(null)}
                                    onChange={ev => handleRgbaColorInput('b', ev.target.value)}
                                />
                                B
                            </SC.ColorPickerRgbaInputItem>
                            <SC.ColorPickerRgbaInputItem>
                                <SC.ColorPickerRgbaInput
                                    value={focused === 'a' ? undefined : rgbaColorMap.a}
                                    onFocus={() => setFocused('a')}
                                    onBlur={() => setFocused(null)}
                                    onChange={ev => handleRgbaColorInput('a', ev.target.value)}
                                />
                                A
                            </SC.ColorPickerRgbaInputItem>
                        </SC.ColorPickerRgbaInputGroup>
                    </SC.ColorPickerRgbaInputWrapper>
                </SC.ColorPickerColorInputWrapper>
            </SC.ColorPickerColorBoardContent>
        </SC.ColorPickerColorBoardWrapper>
    )
}
