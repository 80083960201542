import { Anchor, BaseModal, Button, Flex, Group, Image, Text, tinyButtons } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { getFileTypeByUrl, TitleInContentModal, useAtomAction, useAtomData, VideoPlayer } from '@lighthouse/shared'
import { find, findIndex } from 'rambda'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { appGuideAtom } from '@/atoms/application/state'
import { updateUserAtom } from '@/atoms/auth/action'
import * as srv from '@/services'

import { guideTaskList } from './constants'
import { StepList } from './StepList'
import { TaskList } from './TaskList'

interface IGuideTaskProps {
    onClose: () => void
}

const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    /* max-height: 600px; */
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    /* ${tinyButtons} */
    background-image: url(${getAssetUrl('guide', 'task_background.png')});
`

const SCxModalFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 21px;
    button {
        width: 90px;
        height: 40px;
    }
`

export const GuideTask: React.FunctionComponent<IGuideTaskProps> = ({ onClose }) => {
    const [taskId, setTaskId] = useState('')
    const [stepId, setStepId] = useState('')
    const [previewIndex, setPreviewIndex] = useState(0)
    const userTaskList = useAtomData(appGuideAtom)
    const { run: updateUser } = useAtomAction(updateUserAtom)

    const task = find(item => item.id === taskId, guideTaskList)

    const step = find(item => item.id === stepId, task?.steps ?? [])

    const preview = step?.previewList[previewIndex]

    const previewFileType = getFileTypeByUrl(preview?.source ?? '')

    const handleModalClose = useCallback(() => {
        // onClose()
        setPreviewIndex(0)
        setStepId('')
    }, [])

    const handleFinishedTask = useCallback(
        async (id: string) => {
            setStepId('')
            setPreviewIndex(0)
            if (userTaskList.includes(id)) {
                return
            }
            const ids = [...userTaskList, id]
            setStepId('')
            const res = await srv.taskCompleted(ids)
            if (res) {
                updateUser({ userCompletedTask: ids })
            }
        },
        [updateUser, userTaskList]
    )

    return (
        <>
            <SCxContainer>
                {taskId ? (
                    <StepList taskId={taskId} stepId={stepId} onBack={() => setTaskId('')} onChangeStep={setStepId} onClose={onClose} />
                ) : (
                    <TaskList onChangTask={setTaskId} onClose={onClose} />
                )}
                {stepId && taskId && (
                    <TitleInContentModal
                        open={!!stepId}
                        width={738}
                        onClose={handleModalClose}
                        styles={{
                            desktop: {
                                modal: { borderStyle: 'none' },
                                close: {
                                    color: 'var(--color-gray-500)',
                                    backgroundColor: 'rgba(255,255,255,0.5)!important'
                                }
                            }
                        }}
                    >
                        <div style={{ background: previewFileType === 'image' ? 'var(--color-white)' : 'var(--color-black)' }}>
                            {preview?.source ? (
                                previewFileType === 'image' ? (
                                    <Image src={preview?.source} />
                                ) : (
                                    <VideoPlayer source={preview?.source} ratio="16:10" />
                                )
                            ) : null}
                        </div>
                        <div style={{ padding: 24 }}>
                            <Text whiteSpace="pre-wrap" size={16} lineHeight="24px">
                                {previewIndex + 1}. {preview?.describe}
                            </Text>
                            <SCxModalFooter>
                                <Button
                                    type="tag"
                                    style={{ backgroundColor: 'var(--color-gray-100)', width: 'auto', height: 30 }}
                                    radius={100}
                                >
                                    {previewIndex + 1}/{step?.previewList?.length ?? 1}
                                </Button>
                                <Flex gap={12}>
                                    {previewIndex > 0 && (
                                        <Button
                                            type="tag"
                                            style={{ backgroundColor: 'var(--color-gray-100)' }}
                                            onClick={() => {
                                                setPreviewIndex(v => v - 1)
                                            }}
                                        >
                                            上一步
                                        </Button>
                                    )}
                                    {previewIndex < (step?.previewList.length ?? 0) &&
                                        previewIndex !== (step?.previewList.length ?? 0) - 1 && (
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setPreviewIndex(v => v + 1)
                                                }}
                                            >
                                                下一步
                                            </Button>
                                        )}
                                    {previewIndex === (step?.previewList.length ?? 0) - 1 && (
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                step?.id && handleFinishedTask(step.id)
                                            }}
                                        >
                                            完成
                                        </Button>
                                    )}
                                </Flex>
                            </SCxModalFooter>
                        </div>
                    </TitleInContentModal>
                )}
            </SCxContainer>
        </>
    )
}
