import { Button, DatePicker, MobileDatePicker } from '@byecode/ui'
import { Card, Collapse, Grid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { addYears, lightFormat } from 'date-fns'
import React, { useRef, useState } from 'react'

import { Unit } from '../Mics'

export const DatePickerComponentsDemo: React.FC = () => {
    const [opened, { toggle }] = useDisclosure(true)

    const [value, setValue] = useState<Date>()

    const ref = useRef<HTMLInputElement | null>(null)

    return (
        <Card withBorder>
            <Title order={2} onClick={toggle}>
                日期选择器
            </Title>
            <Collapse in={opened}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Unit title="基础">
                            <DatePicker showTime={false} />
                        </Unit>
                        <Unit title="受控">
                            <DatePicker value={value} onChange={setValue} />
                        </Unit>
                        <Unit title="移动端-day">
                            <MobileDatePicker mode="day" value={value} onChange={setValue} maxDate={addYears(new Date(), 1)} />
                        </Unit>
                        <Unit title="移动端-time">
                            <MobileDatePicker mode="time" value={value} onChange={setValue} maxDate={addYears(new Date(), 1)} />
                        </Unit>
                        <Unit title="移动端-full">
                            <MobileDatePicker mode="full" maxDate={addYears(new Date(), 1)} />
                        </Unit>
                        <Unit title="移动端-自定义Input ui">
                            <MobileDatePicker
                                mode="day"
                                maxDate={addYears(new Date(), 1)}
                                customRenderInputUi={(date, ref) => {
                                    return (
                                        <Button
                                            onClick={() => {
                                                ref.current?.showPicker()
                                            }}
                                        >
                                            {date ? lightFormat(date, 'yyyy-MM-dd') : '选择日期'}
                                        </Button>
                                    )
                                }}
                            />
                        </Unit>
                    </Grid.Col>
                </Grid>
            </Collapse>
        </Card>
    )
}
