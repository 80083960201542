import { getAssetUrl } from '@lighthouse/assets'
import { BlockThumbImageMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type {
    BlockTree,
    BlockTreeItem
} from '../type'
import { BlockKind } from '../type'

export const blockText: BlockTreeItem = {
    name: '文本',
    icon: '',
    describeImage: BlockThumbImageMap['text'],
    color: '#667085',
    backGroundColor: 'rgba(102, 112, 133, 0.10)',
    type: BlockType.text
}

export const blockHighlight: BlockTreeItem = {
    type: 'highlight',
    name: '高亮文本',
    icon: 'Widget',
    describeImage: getAssetUrl('blocks', 'highlight_text.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
}

export const blockCombination:BlockTreeItem = {
    type: 'combinationTitle',
    name: '组合标题',
    icon: 'Widget',
    describeImage: getAssetUrl('blocks', 'preset_title.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
}

export const allText:BlockTree = {
    id: BlockKind.text,
    name: '文本',
    items: [blockText, blockHighlight, blockCombination]
}
