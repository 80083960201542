import type { FieldBlockWithDsId } from '@lighthouse/core'
import type { FindUseADTObject } from '@lighthouse/shared'
import { createContext, useContext } from 'react'

/** findUse Context */
export type FindUseConfigure = { open: boolean; config?: FindUseADTObject }
export const FindUseContext = createContext<FindUseConfigure & { onChange?: (val: FindUseConfigure) => void }>({
    open: false
})
export const FindUseProvider = FindUseContext.Provider
export const useFindUseContext = () => useContext(FindUseContext)

/** 根页面id */
export const RootPageContext = createContext<{ rootPageId: string; refreshRootPage?: () => void }>({ rootPageId: '' })
export const RootPageProvider = RootPageContext.Provider
export const useRootPageContext = () => useContext(RootPageContext)

/** 页面数据抽屉 */
export const DataDrawerContext = createContext<{ visible: boolean; onVisible?: (val: boolean) => void }>({
    visible: false
})
/** 当前页面id */
export const PageContext = createContext<{ pageId: string; refreshPage?: () => void, refreshPageData?: () => void }>({
    pageId: ''
})
export const PageProvider = PageContext.Provider
export const useCurrentPageContext = () => useContext(PageContext)

/** 当前stack id */
export const PageStackContext = createContext<string>('')
export const PageStackProvider = PageStackContext.Provider
export const useCurrentStackIdContext = () => useContext(PageStackContext)

/** 页面的字段blocks */
const PageFieldBlocksContext = createContext<FieldBlockWithDsId[]>([])
export const PageFieldBlocksProvider = PageFieldBlocksContext.Provider
export const usePageFieldBlocksContext = () => useContext(PageFieldBlocksContext)
