import type { DataSourceAbstract, QuickFilterConfig } from '@lighthouse/core'
import { type FieldType, type ViewFieldProtocol } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { FieldItemAdder } from '../FieldItemAdder'
import QuickFilterList from './QuickFilterList'

export interface QuickFilterProps {
    columns: ViewFieldProtocol[]
    quickFilterRules?: QuickFilterConfig['fieldIds']
    noSettingFields?: Set<FieldType>
    disablePortal?: boolean
    onChange?: (data: QuickFilterConfig['fieldIds']) => void
}

const QuickFilterWrapper = styled.div`
`

export const QuickFilter: React.FC<QuickFilterProps> = ({
    columns,
    quickFilterRules = [],
    disablePortal = false,
    noSettingFields,
    onChange
}) => {
    const handleAdd = useCallback(
        (id: string) => {
            const fieldData = find(item => item.fieldId === id, columns)
            if (!fieldData) {
                return
            }
            const newQuickFilter: string[] = [...quickFilterRules, id]
            onChange?.(newQuickFilter)
        },
        [columns, quickFilterRules, onChange]
    )

    const handleDelete = useCallback(
        (id?: string) => {
            if (!id) {
                return
            }
            const newQuickFilter = quickFilterRules.filter(fieldId => fieldId !== id)
            onChange?.(newQuickFilter)
        },
        [quickFilterRules, onChange]
    )

    return (
        <QuickFilterWrapper>
            <QuickFilterList columns={columns} items={quickFilterRules} onDelete={handleDelete} onMove={onChange} />
            <FieldItemAdder
                text="添加字段"
                noSettingFields={noSettingFields}
                onAdd={handleAdd}
                columns={columns}
                selectedFieldIds={quickFilterRules}
                disablePortal={disablePortal}
            />
        </QuickFilterWrapper>
    )
}
