import { Toast } from '@byecode/ui'
import { Group } from '@byecode/ui/components/Group'
import type { SegmentedControlItem } from '@byecode/ui/components/SegmentedControl'
import type { Field, FileBlockAbstract } from '@lighthouse/core'
import {
    fileMaxUploadSize,
    getFileSizeToMB,
    getFileTypeByUrl,
    getIsFindUseInVariable,
    getPageDataOptions,
    getUserDatasourceOptions,
    InnerTypeMapByFieldType,
    ListItemPaddingByecodeUi,
    useAtomData,
    useFindUseObjectContext,
    USER_DATASOURCE,
    VariableSelect
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { uploadManagerInAppParams } from '@/utils/auth'

import { VisibilityFilter } from '../Common/VisibilityFilter'

interface FileBlockSettingProps {
    blockId: string
}

const PREVIEW_MODE: SegmentedControlItem[] = [
    {
        label: '摘要',
        value: 'introduce'
    },
    {
        label: '预览',
        value: 'preview'
    }
]

const noFileType = new Set(['video', 'image'])

const USED_FILE_TYPE = new Set(['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'])

const filter = (field: Field) => field.type === 'file'

const FileBlockSetting: React.FunctionComponent<FileBlockSettingProps> = ({ blockId }) => {
    const { control } = useFormContext<FileBlockAbstract['config']>()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const findUseObject = useFindUseObjectContext()

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )
    const uploadInfo = useMemo(() => ({ id: '', label: pageName, groupId: pageId }), [pageId, pageName])

    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        if (!userDataSource) {
            return
        }
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filter
        })
    }, [dataSourceList])

    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filter
            },
            curr.datasource && {
                variableSourceType: curr.variableSourceType,
                datasource: curr.datasource,
                validateFieldType: filter
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [curr.datasource, curr.variableSourceType, prev.datasource, prev.variableSourceType])

    const { customViewOption } = useVariableCustomViewOption(filter)

    return (
        <>
            <Group label="设置">
                <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                    <Text>文件来源</Text>
                    <Controller
                        name="fileSource"
                        control={control}
                        render={({ field: { value, onChange } }) => {
                            const isHighLight = getIsFindUseInVariable({
                                variable: value,
                                findUseObject,
                                currentDsId: curr.datasource?.id
                            })
                            return (
                                <VariableSelect
                                    field={{ type: 'file', id: '', dsId: '', name: '', innerType: InnerTypeMapByFieldType['file'] }}
                                    disabledPexels
                                    highlighting={isHighLight}
                                    uploadProps={{
                                        multiple: false,
                                        uploadOptions: {
                                            info: uploadInfo,
                                            options: {
                                                ...uploadManagerInAppParams(),
                                                fileFilter: (file: File | string, index: number) => {
                                                    if (file instanceof File) {
                                                        if (file.size > fileMaxUploadSize) {
                                                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                                                            return false
                                                        }
                                                        if (!USED_FILE_TYPE.has(getFileTypeByUrl(file.name))) {
                                                            Toast.error('文件格式错误')
                                                            return false
                                                        }

                                                        return true
                                                    }
                                                    return true
                                                }
                                            }
                                        }
                                    }}
                                    options={options}
                                    value={value}
                                    userOption={userOption}
                                    viewOption={customViewOption}
                                    onChange={onChange}
                                />
                            )
                        }}
                    />
                </ListItemPaddingByecodeUi>
                {/* <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>预览方式</Text>
                <div style={{ width: 180 }}>
                    <Controller
                        name="previewMode"
                        control={control}
                        render={({ field }) => (
                            <SegmentedControl fullWidth data={PREVIEW_MODE} value={field.value} onChange={v => field.onChange(v)} />
                        )}
                    />
                </div>
            </ListItemPaddingByecodeUi> */}
            </Group>
            <VisibilityFilter />
        </>
    )
}

export default FileBlockSetting
