import type { BasePopoverProps, ByecodeSize, Option } from '@byecode/ui'
import { Box, Divider, Flex, Input, Popover, Text, usePopoverHeight } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import cls from 'classnames'
import { reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { useFindUseObjectContext, useSharedConfigDisabledWithVersion } from '../../contexts'
import { FindUseType } from '../../types'
import { getIsFindUse, getTableIcon, getTableIconColor } from '../../utils'
import { TooltipText } from '../TooltipText'
import { SelectItem } from './SelectItem'
import * as SC from './styles'

const SCxContainer = styled.div<{ highlighting?: boolean }>`
    position: relative;
    ${({ highlighting }) =>
        highlighting &&
        css`
            border: 1px solid var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
        `}
    .byecode-Input-input {
        cursor: pointer;
    }
`

const SCxPlaceHolder = styled(Text)`
    font-size: 14px;
    flex: 1;
    color: var(--color-gray-400);
`

const SCxTarget = styled.div`
    display: flex;
    align-items: center;
    border: 1.5px solid transparent;
    border-radius: 8px;
    padding: 0 8px;
    gap: 6px;
    background-color: var(--color-gray-100);

    &.md {
        height: 32px;
    }

    &.lg {
        height: 36px;
    }

    &.active {
        background-color: #fff;
        border: 1.5px solid var(--color-main);
    }
`

interface SelectDataSourceProps extends Omit<BasePopoverProps, 'width'> {
    dataSourceList: DataSourceAbstract[]
    value?: string
    disabled?: boolean
    placeholder?: string
    searchable?: boolean
    width?: number | string
    withinPortal?: boolean
    size?: ByecodeSize
    renderTarget?: (opened: boolean, options?: Option) => React.ReactNode
    onChange: (v: string) => void
}

const getDatasourceGroup = function (dataSourceList: DataSourceAbstract[]) {
    return dataSourceList.reduce<[DataSourceAbstract[], DataSourceAbstract[], DataSourceAbstract[]]>(
        (prev, item) => {
            if (item.type === DataSourceType.joinDataSource) {
                prev[0].push(item)
                return prev
            }
            if (item.type === DataSourceType.aggregateDataSource) {
                prev[1].push(item)
                return prev
            }
            prev[2].push(item)
            return prev
            // item.type === 5 ? [[...prev[0], item], prev[1]] : [prev[0], [...prev[1], item]]
        },
        [[], [], []]
    )
}

const getChildrenDataSource = (ordinaryDataSource: DataSourceAbstract, joinDataSource: DataSourceAbstract[]): Option[] => {
    const { id } = ordinaryDataSource

    return reduce<DataSourceAbstract, Option[]>(
        (prev, cur) => {
            if (cur.viewOptions.joinConfig?.primaryDsId === id) {
                prev.push({
                    value: cur.id,
                    label: cur.name,
                    describe: (
                        <Flex gap={4} alignItems="center">
                            <SC.Icon type={getTableIcon(ordinaryDataSource)} color={getTableIconColor(ordinaryDataSource)} />
                            <Text>{ordinaryDataSource.name}</Text>
                            <SC.Icon type="ArrowRightSmall" />
                            <SC.Icon type={getTableIcon(cur)} color={getTableIconColor(cur)} />
                            <Text>{cur.name}</Text>
                        </Flex>
                    ),
                    icon: <SC.Icon type={getTableIcon(cur)} color={getTableIconColor(cur)} />
                })
            }
            return prev
        },
        [],
        joinDataSource
    )
}

const getDataSourceList = (dataSourceList: DataSourceAbstract[]): [Option[], Option[]] => {
    const [joinDataSources, aggregateDataSources, ordinaryDataSources] = getDatasourceGroup(dataSourceList)
    return [
        ordinaryDataSources.map(item => ({
            value: item.id,
            label: item.name,
            describe: (
                <Flex gap={4} alignItems="center">
                    <SC.Icon type={getTableIcon(item)} color={getTableIconColor(item)} />
                    <Text>{item.name}</Text>
                </Flex>
            ),
            icon: <SC.Icon type={getTableIcon(item)} color={getTableIconColor(item)} />,
            children: getChildrenDataSource(item, joinDataSources)
        })),
        aggregateDataSources.map(item => ({
            value: item.id,
            label: item.name,
            describe: (
                <Flex gap={4} alignItems="center">
                    <SC.Icon type={getTableIcon(item)} color={getTableIconColor(item)} />
                    <Text>{item.name}</Text>
                </Flex>
            ),
            icon: <SC.Icon type={getTableIcon(item)} color={getTableIconColor(item)} />
        }))
    ]
}

export const SelectDataSource: React.FunctionComponent<SelectDataSourceProps> = ({
    dataSourceList,
    value,
    placeholder = '请选择',
    disabled,
    width = 180,
    searchable = true,
    withinPortal,
    trigger,
    size = 'lg',
    renderTarget,
    onChange,
    ...rest
}) => {
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState('')
    const [dropDownWidth, setDorpDownWidth] = useState<number>()
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const _disabled = disabledWithVersion || disabled

    const findUseObject = useFindUseObjectContext()
    const isHighLight = useMemo(
        () =>
            getIsFindUse({
                findUseObject,
                type: FindUseType.DATASHEET,
                dsId: value
            }),
        [findUseObject, value]
    )

    const [dsTree, dsAggregate] = useMemo(() => getDataSourceList(dataSourceList), [dataSourceList])

    const flatOptions = useMemo(() => {
        const result: Option[] = []
        dsTree?.forEach(item => {
            item.children && result.push(...item.children)
            result.push({ ...item, children: [] })
        })
        return result
    }, [dsTree])

    const options = useMemo(() => {
        if (search === '') {
            return dsTree
        }
        return flatOptions.filter(item => (typeof item.label === 'string' ? item.label.includes(search) : item.value.includes(search)))
    }, [dsTree, flatOptions, search])

    const extra = useMemo(() => {
        return dsAggregate.filter(item => (typeof item.label === 'string' ? item.label.includes(search) : item.value.includes(search)))
    }, [dsAggregate, search])

    const currentOption = useMemo(
        () => [...flatOptions, ...dsAggregate]?.find(item => item.value === value),
        [dsAggregate, flatOptions, value]
    )

    const isEmpty = flatOptions.length === 0 && extra.length === 0

    const { ref: targetRef, height: maxHeight } = usePopoverHeight(opened, 500)

    const inputDom = useMemo(() => {
        if (currentOption) {
            return (
                <TooltipText
                    title={currentOption.label}
                    render={ref => (
                        <Text style={{ flex: 1 }} ref={ref}>
                            {currentOption.label}
                        </Text>
                    )}
                />
            )
        }

        return <SCxPlaceHolder>{placeholder}</SCxPlaceHolder>
    }, [currentOption, placeholder])

    const handleChange = useCallback(
        (v: string) => {
            if (v !== value) {
                onChange(v)
            }
            setOpened(false)
        },
        [onChange, value]
    )

    useEffect(() => {
        opened && setDorpDownWidth(targetRef.current?.getBoundingClientRect()?.width ?? 180)
    }, [opened, targetRef])

    return (
        <Popover
            opened={opened}
            trigger={trigger}
            width="auto"
            // enableFlip={false}
            withinPortal={withinPortal}
            disabled={_disabled}
            onChange={setOpened}
            {...rest}
        >
            <Popover.Target>
                <SCxContainer style={{ width, cursor: _disabled ? 'not-allowed' : 'pointer' }}>
                    <div ref={targetRef}>
                        {renderTarget ? (
                            renderTarget?.(opened, currentOption)
                        ) : (
                            <SCxTarget className={cls([size, opened ? 'active' : undefined])}>
                                {currentOption?.icon && typeof currentOption.icon === 'string' ? (
                                    <SC.Icon type={currentOption.icon} />
                                ) : (
                                    currentOption?.icon
                                )}
                                {inputDom}
                                <SC.Icon size={16} type="ArrowDownSmall" />
                            </SCxTarget>
                        )}
                    </div>
                </SCxContainer>
            </Popover.Target>
            <Popover.Dropdown compact>
                <SC.DropDownContainer style={{ minWidth: dropDownWidth, maxHeight }}>
                    {searchable && (
                        <Box
                            style={{
                                padding: '4px 12px 0',
                                marginBottom: 8,
                                position: 'sticky',
                                top: 0,
                                backgroundColor: 'var(--color-white)'
                            }}
                        >
                            <Input value={search} onChange={e => setSearch(e.target.value)} />
                        </Box>
                    )}
                    {isEmpty ? (
                        <Flex style={{ minHeight: 200 }} justifyContent="center" alignItems="center">
                            暂无数据
                        </Flex>
                    ) : (
                        <SC.DropDownScrollArea>
                            <SC.DropDown>
                                <Text style={{ padding: '0 16px', lineHeight: '32px' }} size={12} color="var(--color-gray-500)">
                                    数据表
                                </Text>
                                {options.map(item => {
                                    return <SelectItem key={item.value} isShowDesc={search !== ''} data={item} onSelect={handleChange} />
                                })}
                            </SC.DropDown>
                            {dsAggregate.length > 0 && (
                                <>
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Box>
                                        <Text style={{ padding: '0 16px', lineHeight: '32px' }} size={12} color="var(--color-gray-500)">
                                            聚合表
                                        </Text>
                                        {extra.map(item => {
                                            return <SelectItem key={item.value} data={item} onSelect={handleChange} />
                                        })}
                                    </Box>
                                </>
                            )}
                        </SC.DropDownScrollArea>
                    )}
                </SC.DropDownContainer>
            </Popover.Dropdown>
        </Popover>
    )
}
